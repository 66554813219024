import { PERIOD_GENERATED_PAYROLL, PERIOD_PAY_PAYROLL } from 'config/routes'

export default {
  [PERIOD_PAY_PAYROLL()]: {
    index: 0,
    title: 'Paso 1',
    description: 'Liquidar nómina',
  },
  [PERIOD_GENERATED_PAYROLL()]: {
    index: 1,
    title: 'Paso 2',
    description: 'Resumen de nómina',
  },
}
