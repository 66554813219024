import { useFormikContext } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import WageCategoryField from 'components/Worker/Wage/Fields/Category'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'
import TransportSubsidyField from 'components/Worker/Wage/Fields/TransportSubsidy'
import TermField from 'components/Worker/Contract/Fields/Term'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import FormField from 'components/UI/Formik/FormField/Index'
import useFormStyles from 'components/UI/Form/formStyles'

import messages from 'messages/worker'
import { isAlegraClaroDist } from 'utils/auth'
import { weekdaysOptions } from 'utils/dateTime'
import { closedQuestionOptions } from 'utils/worker'

import AreaField from '../Personal/Area/Area'
import LocationField from '../Personal/Location/Location'
import PositionField from '../Personal/Position/Position'
import CostCenterField from '../Personal/CostCenter/CostCenter'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
  },
  rowContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  alignCenterInput: {
    alignSelf: 'center',
  },
}))

const WorkerFormFieldsBasic = () => {
  const classes = useStyles()
  const classesForm = useFormStyles()
  const { values } = useFormikContext()

  return (
    <div>
      <Typography variant="h6" color="primary" gutterBottom>
        Datos principales
      </Typography>
      <div className={classes.container}>
        <div className={classesForm.columnsContainer}>
          <DocumentTypeField />
          <IdentificationNumberField />
          <FormField name="name" label="Nombres" />
          <FormField name="last_name" label="Apellidos" />
          <FormField name="email" type="email" label="Correo electrónico" />
          {!isAlegraClaroDist() && (
            <FormField
              name="active_user"
              label="¿Permitir a la persona consultar las colillas de pago?"
              variant="radio-group"
              row
              options={closedQuestionOptions}
            />
          )}
        </div>
      </div>
      <Typography
        variant="h6"
        color="primary"
        gutterBottom
        className={classes.sectionTitle}
      >
        Información laboral
      </Typography>
      <div className={classes.container}>
        <div className={classesForm.columnsContainer}>
          <ContractCategoryField name="contract_category" />
          <TermField contractCategory={values.contract_category} />
          <InitialDayField />
          <EndDayField />
          <WageCategoryField
            name="wage_category"
            contractCategory={values.contract_category}
          />
          <BaseSalaryField
            isEditing={false}
            contractCategory={values.contract_category}
          />
          <div className={classes.alignCenterInput}>
            <TransportSubsidyField
              contractCategory={values.contract_category}
            />
          </div>
          <RiskTypeField contractCategory={values.contract_category} />
        </div>
      </div>
      <Typography
        variant="h6"
        color="primary"
        gutterBottom
        className={classes.sectionTitle}
      >
        Datos del puesto de trabajo
      </Typography>
      <div className={classesForm.columnsContainer}>
        <PositionField />
        <LocationField />
        <AreaField />
        <CostCenterField />
      </div>
      {values.contract_category &&
        [
          'employee',
          'pensioner',
          'student_law_789',
          'student_decree_055',
        ].includes(values.contract_category) && (
          <>
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              className={classes.sectionTitle}
            >
              Días de descanso
            </Typography>
            <FormField
              name="rest_days"
              variant="checkbox-group"
              options={weekdaysOptions}
              row
              justifiSpaceBetween
            />
            <div className={classes.rowContainer}>
              <FormField
                name="enjoyed_days_since_hired"
                label="¿Cuántos días de vacaciones ha disfrutado desde que ingresó?"
                type="number"
                align="left"
                tooltipContent={messages.accumulated_holidays}
              />
            </div>
          </>
        )}
    </div>
  )
}

export default WorkerFormFieldsBasic
