import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'

import messages from 'messages/CompanyConfiguration'

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}))
const ElectronicPayrollAdvanced = () => {
  const classes = useStyles()
  const companyId = getCompanyId()
  const queryKey = ['getConfigurationOptions', companyId]
  const {
    electronicPayrollConfigurationQuery,
  } = useElectronicPayrollConfigurationService({
    serviceParams: {
      queryKey,
    },
  })

  return (
    <div>
      <Typography variant="h6" color="primary" gutterBottom>
        Configuración de emisión NE
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Acá puedes configurar como deseas emitir tu Nómina electrónica.
      </Typography>
      <section className={classes.section}>
        <FormField
          name="social_benefits"
          label="Forma de hacer la emisión de la nómina electrónica"
          variant="select"
          options={
            electronicPayrollConfigurationQuery.data?.social_benefits || []
          }
          tooltipContent={messages.electronic_payroll_social_benefit}
        />
        {/* <FormField
          name="holidays"
          label="Forma de reportar las vacaciones en la nómina electrónica"
          variant="select"
          options={electronicPayrollConfigurationQuery.data?.holidays || []}
          tooltipContent={messages.electronic_payroll_holidays}
        /> */}
        <FormField
          name="consecutive_number"
          label="Siguiente consecutivo de nómina electrónica"
          variant="number"
        />
      </section>
    </div>
  )
}

export default ElectronicPayrollAdvanced
