import { Redirect, useLocation } from 'react-router-dom'

import Page from 'components/UI/Page/Page'

import robotNotFoundImg from 'assets/images/errors/not_found.svg'

import {
  COMPANY_INDEX,
  SUBSCRIPTION_PAYMENTS,
  SUBSCRIPTION_STATUS,
} from 'config/routes'

import ErrorPageAtom from './Atoms/ErrorPage'

const RouteNotFound = () => {
  const { pathname } = useLocation()

  // Redirect from routes that don't exist any more
  if (pathname === '/companies') return <Redirect to={COMPANY_INDEX()} />
  if (pathname === '/subscription/status')
    return <Redirect to={SUBSCRIPTION_STATUS()} />
  if (pathname === '/subscription/payments')
    return <Redirect to={SUBSCRIPTION_PAYMENTS()} />

  return (
    <Page documentTitle="Página no encontrada">
      <ErrorPageAtom
        subtitle="Esta página ya no existe"
        description="Al parecer estás perdido, probablemente la página que estás buscando fue trasladada o eliminada."
        image={robotNotFoundImg}
      />
    </Page>
  )
}

export default RouteNotFound
