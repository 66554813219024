import { Form, Formik } from 'formik'
import { bindPopover } from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'

import usePayrollService from 'utils/hooks/payroll/payroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { PeriodContext } from '../../Payroll'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right',
    '& button + button': {
      marginLeft: theme.spacing(0.5),
    },
  },
}))

const CommentsDialog = ({ payroll, popupState }) => {
  const classes = useStyles()
  const { updatePeriodCallback } = useContext(PeriodContext)
  const { handleError } = useErrorHandler()
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = (values, form) => {
    if (
      (!payroll.comments && values.comments.trim()) ||
      (payroll.comments && payroll.comments !== values.comments.trim())
    ) {
      payrollMutation.mutate(
        {
          mutationMethod: 'PATCH',
          payrollId: payroll.id,
          data: {
            payroll: values,
          },
        },
        {
          onSuccess: ({ data }) => {
            updatePeriodCallback(data)
            popupState.close()
          },
        },
        {
          onError: (error) => handleError(error, form),
        }
      )
    } else {
      popupState.close()
    }
  }

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      elevation={2}
    >
      <Box p={2}>
        <Formik
          initialValues={{ comments: payroll.comments || '' }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <FormField
                  name="comments"
                  label={`Nota personal para ${payroll.worker_name}`}
                  variant="textarea"
                  rows="2"
                  inputProps={{
                    maxLength: 500,
                  }}
                />
                <div className={classes.footer}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={popupState.close}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" size="small" disabled={isSubmitting}>
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Popover>
  )
}

export default CommentsDialog
