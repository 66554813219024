import clsx from 'clsx'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'

import { getDocumentLabel } from 'components/Worker/SSAffiliations/Form/Beneficiaries/helpers'
import useWorker from 'components/Worker/useWorker'

import DownloadFile from '../DownloadFile'
import Item from '../Item'

const useStyles = makeStyles((theme) => ({
  emptyState: {
    padding: theme.spacing(8, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.lighter,
    width: 104,
    height: 104,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[500],
  },
  container: {
    padding: theme.spacing(3, 3, 4),
  },
  kinshipTitle: {
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(8),
  },
  personName: {
    marginBottom: theme.spacing(2),
  },
  person: {
    marginBottom: theme.spacing(3),
  },
}))

const kinshipTitle = {
  spouse: 'Cónyugue o compañero',
  progenitor: 'Padres',
  child: 'Hijos',
}

const EmptyState = () => {
  const classes = useStyles()

  return (
    <div className={classes.emptyState}>
      <div className={classes.iconContainer}>
        <PersonOutlineOutlinedIcon className={classes.icon} />
      </div>
      <Typography variant="h5">
        El empleado no tiene ningún beneficiario registrado
      </Typography>
    </div>
  )
}

const BeneficiariesContainer = () => {
  const classes = useStyles()
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  const affiliationCache = queryClient.getQueryData(affiliationQueryKey)
  const beneficiaries = affiliationCache?.data?.beneficiaries || []

  const sectionData = useMemo(() => {
    const sections = {
      spouse: [],
      child: [],
      progenitor: [],
    }

    beneficiaries.forEach((beneficiary, index) => {
      const filesItems = Object.entries(beneficiary.files).map(
        ([key, value]) => {
          return {
            label: getDocumentLabel(beneficiary.kinship, key.slice(0, -5)),
            value: <DownloadFile file={value} />,
          }
        }
      )

      sections[beneficiary.kinship] = [
        ...sections[beneficiary.kinship],
        {
          id: `beneficiary_${index + 1}`,
          name: beneficiary.name,
          itemsList: filesItems,
        },
      ]
    })

    return sections
  }, [beneficiaries])

  return (
    <div className={classes.container}>
      <Typography variant="h5">Beneficiarios</Typography>
      <Divider className={classes.divider} />
      {beneficiaries.length === 0 ? <EmptyState /> : null}
      {Object.entries(sectionData).map(([key, section], sectionIndex) => {
        if (sectionData[key].length > 0) {
          return (
            <div
              key={key}
              className={clsx({
                [classes.section]:
                  sectionIndex < Object.entries(sectionData).length - 1,
              })}
            >
              <Typography variant="h6" className={classes.kinshipTitle}>
                {kinshipTitle[key]}
              </Typography>
              {section.map((beneficiary) => (
                <div key={beneficiary.id} className={classes.person}>
                  <Typography
                    variant="subtitle1"
                    className={classes.personName}
                  >
                    {beneficiary.name}
                  </Typography>
                  <Grid container spacing={2}>
                    {beneficiary.itemsList.map((fileItem, index) => (
                      <Item
                        item={fileItem}
                        key={`${key}_file_${index.toString()}`}
                      />
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          )
        }

        return null
      })}
    </div>
  )
}

export default BeneficiariesContainer
