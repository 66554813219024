import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'

import Tooltip from '@material-ui/core/Tooltip'

import Button from 'components/UI/Button/Button'
import { useUser } from 'components/App/UserContext/useUser'

import { ElectronicActionsFlag } from 'utils/general'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import { getCompanyId } from 'utils/company'

import Resume from '../../ActionGroup/Resume'
import Payslips from '../../ActionGroup/Payslips'
import ShowSummary from '../../ActionGroup/ShowSummary'

const ToolbarActions = ({ disabledButton, tooltipTitle = '' }) => {
  const { handleSubmit } = useFormikContext()
  const { user } = useUser()
  const { electronicPayrollSummary } = useFeatureFlags({
    flags: ['electronicPayrollSummary'],
    trackingMode: 'attributes',
    attributes: {
      email: user.email,
      companyId: getCompanyId(),
    },
  })

  const { periodId } = useParams()

  return (
    <div>
      {electronicPayrollSummary?.value !== 'on' ? (
        <Resume electronicPayrollPeriodId={periodId} />
      ) : (
        <ShowSummary />
      )}
      <Tooltip title={tooltipTitle} open={tooltipTitle ? false : undefined}>
        <Button onClick={handleSubmit} disabled={disabledButton}>
          Emitir nómina electrónica
        </Button>
      </Tooltip>
      {
        // TODO: massive actions temporal with out funtionality

        ElectronicActionsFlag() ? <Payslips /> : null
      }
    </div>
  )
}

export default ToolbarActions
