import clsx from 'clsx'
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks'

import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'

import DropdownButton from 'components/UI/Button/Dropdown'

import IconButton from './IconButton'
import useUserMenuOptions from './helpers'
import { useUser } from '../UserContext/useUser'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '25px !important',
  },
  arrowIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  arrowIconExpanded: {
    transform: 'rotate(180deg)',
  },
  button: {
    color: theme.palette.grey[700],
    maxWidth: 300,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
    },
  },
  menu: {
    '& .MuiListItem-button:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

const UserButton = () => {
  const classes = useStyles()
  const { user, company } = useUser()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down(768))
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const closePopup = () => popupState.close()

  const userName = user?.name || 'Usuario'
  const companyName = company?.name ? ` - ${company?.name || 'Empresa'}` : ''
  const userHashCompanyName = `${userName}${companyName}`

  const userHashMobile = () => {
    const arrayName = userHashCompanyName.split('')

    const formatedName =
      arrayName.length > 15
        ? `${arrayName.slice(0, 15).join('')}...`
        : userHashCompanyName

    return formatedName
  }

  const options = useUserMenuOptions(closePopup, xsDown, userHashMobile)
  const openMenu = popupState?.isOpen

  return (
    <DropdownButton
      popupState={popupState}
      variant="text"
      startIcon={<AccountCircleOutlinedIcon className={classes.icon} />}
      endIcon={
        <ExpandMoreOutlinedIcon
          className={clsx(classes.arrowIcon, {
            [classes.arrowIconExpanded]: openMenu,
          })}
        />
      }
      color="default"
      className={classes.button}
      title={
        <Typography variant="subtitle1" color="inherit" noWrap>
          {userHashCompanyName}
        </Typography>
      }
      data-cy="header-user-button"
      options={options}
      menuClassName={classes.menu}
      iconButton={xsDown}
      Icon={
        xsDown ? (
          <IconButton
            Icon={AccountCircleOutlinedIcon}
            {...bindTrigger(popupState)}
          />
        ) : undefined
      }
    />
  )
}

export default UserButton
