import { makeStyles } from '@material-ui/core/styles'

import { getDistributor } from 'utils/auth'

import * as routes from 'config/routes'

export const useAuthStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 440,
    zIndex: 1,
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2, 0, 2),
      justifyContent: 'flex-start',
    },
  },
  button: {
    width: '100%',
  },
  googleButton: {
    backgroundColor: 'white',
    color: theme.palette.grey[500],
    width: '100%',
    boxShadow: '0 12px 42px -4px rgba(24, 39, 75, 12%)',
    marginLeft: 0,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '0px 0px 6px rgba(66, 133, 244, 0.5)',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  logo: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  titleContainer: {
    width: '100%',
    maxWidth: 440,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2),
    width: '100%',
    alignSelf: 'start',
  },
  subtitle: {
    textAlign: 'left',
    marginBottom: theme.spacing(5),
  },
  fieldsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  termsText: {
    textAlign: 'center',
  },
  termsContainer: {
    marginTop: theme.spacing(6),
  },
  forgotPassword: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(4, 0),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > hr': {
      width: '45%',
      border: `1px solid ${theme.palette.disabled}`,
    },
  },
}))

export function getSignUpRedirectPath() {
  const distributor = getDistributor()

  let origin

  if (distributor === 'alegra_claro') {
    origin = 'alegra_claro'
  } else if (distributor === 'alegra_nomina') {
    origin = 'alegra_nomina'
  } else {
    origin = 'landing'
  }

  return `${routes.PERIOD_PAY_PAYROLL()}?origin=${origin}`
}
