import { useState } from 'react'

import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import messages from 'messages/payroll'

import useContentStyles from '../common/commonStyles'

export default function CompensatedDays({
  compensatedDays,
  handleCompensatedDays,
}) {
  const contentClasses = useContentStyles()

  const [placeholder, setPlaceholder] = useState('0')

  return (
    <FormControl
      className={contentClasses.noveltiesItemContainer}
      margin="none"
    >
      <Typography component="label">
        Días compensados en dinero y NO disfrutados
      </Typography>
      <OutlinedInput
        type="number"
        placeholder={placeholder}
        value={(compensatedDays === 0 ? '' : compensatedDays) || ''}
        onFocus={() => setPlaceholder('')}
        onBlur={() => setPlaceholder('0')}
        onChange={handleCompensatedDays}
        margin="dense"
        style={{ width: 115, justifySelf: 'center' }}
        inputProps={{
          style: { textAlign: 'center' },
          'data-cy': 'input-compensated_days',
          min: 0,
          max: 30,
          step: 1,
        }}
      />
      <TooltipInfoIcon title={messages.compensated_days} interactive />
    </FormControl>
  )
}
