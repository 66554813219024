import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import MLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { getCompanyId } from 'utils/company'

import { COMPANY_EDIT } from 'config/routes'

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.grey[500],
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(4, 0),
    gap: theme.spacing(2),
  },
  helpCenter: {
    fontWeight: 'bold',
  },
}))

const AffiliationRequirements = ({ onClose, onRedirect }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="body1" className={classes.message}>
        Es necesario que adjuntes una documentación y credenciales de tus
        operadores en la sección Empresa para realizar las afiliaciones a
        Seguridad Social de las personas.
        <br />
        <br />
        En este{' '}
        <MLink
          target="_blank"
          href="https://ayuda.nominapp.com/help"
          className={classes.helpCenter}
        >
          artículo
        </MLink>{' '}
        podrás encontrar el paso a paso de cómo realizar el proceso.
      </Typography>
      <div className={classes.buttonsContainer}>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          component={Link}
          to={{
            pathname: COMPANY_EDIT(getCompanyId()),
            state: { initialStep: 6 },
          }}
          onClick={onRedirect}
        >
          Ir a empresa
        </Button>
      </div>
    </div>
  )
}

export default AffiliationRequirements
