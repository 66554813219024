import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import PaymentRetry from 'components/Subscription/Atoms/PaymentRetry'
import PaymentsTable from 'components/Subscription/Atoms/Payments/DataTable/Table'
import Page from 'components/UI/Page/Page'

import { isOrganizer } from 'utils/auth'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

const Payments = () => {
  const [isLoadingWithModal, setIsLoadingWithModal] = useState(false)

  const classes = useStyles()
  return (
    <Page
      documentTitle="Pagos"
      isLoadingWithModal={isLoadingWithModal}
      className={classes.page}
      header={
        <>
          <div className={classes.headerContainer}>
            <Typography variant="h5" gutterBottom>
              Historial de pagos
            </Typography>
            {isOrganizer() ? <PaymentRetry /> : null}
          </div>
        </>
      }
    >
      <PaymentsTable setIsLoading={setIsLoadingWithModal} />
    </Page>
  )
}

export default Payments
