import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

const RowActions = ({
  actions = [],
  row,
  ProvidedActionButton,
  editableTable,
  deleteRow,
  editRow,
  isDeletable = () => true,
  isEditable = () => true,
  hideEdit = false,
  hideDelete = false,
  dataCyPrefix,
}) => {
  const canDeleteRow =
    typeof isDeletable === 'boolean' ? isDeletable : isDeletable(row.original)
  const canEditRow =
    typeof isEditable === 'boolean' ? isEditable : isEditable(row.original)
  const showEdit =
    typeof hideEdit === 'boolean' ? hideEdit : hideEdit(row.original)
  const showDelete =
    typeof hideDelete === 'boolean' ? hideDelete : hideDelete(row.original)

  return (
    <div>
      {actions.map((actionItem) => {
        const completeRow = {
          ...row.original,
          ...row.values,
          rowIndex: row.index,
        }
        const action =
          typeof actionItem === 'function'
            ? actionItem(completeRow)
            : actionItem

        // Render a custom ActionButton if it is provided
        if (ProvidedActionButton)
          return (
            <ProvidedActionButton
              action={action}
              completeRow={completeRow}
              key={`action_${action.id}_${completeRow.rowIndex}`}
            />
          )

        return !action.hidden ? (
          <Tooltip
            title={action.tooltip}
            key={`action_${action.id}_${completeRow.rowIndex}`}
          >
            <span>
              <IconButton
                size="small"
                disabled={action.disabled}
                onClick={() => action.onClick(completeRow)}
                {...action.buttonProps}
              >
                <action.icon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null
      })}
      {editableTable ? (
        <>
          {!showEdit ? (
            <Tooltip title="Editar" data-cy={`${dataCyPrefix}-edit-action`}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => editRow(row.index)}
                  disabled={!canEditRow}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
          {!showDelete ? (
            <Tooltip title="Eliminar" data-cy={`${dataCyPrefix}-delete-action`}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => deleteRow(row.index)}
                  disabled={!canEditRow || !canDeleteRow}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default RowActions
