import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize * 1.5,
  },
}))

const CustomDatePickerToolbar = ({ title, ...props }) => {
  const classes = useStyles()

  return <div className={classes.root}>{title}</div>
}

export default CustomDatePickerToolbar
