import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import Anniversaries from './Anniversaries'
import Birthdays from './Birthdays'
import Provisions from './Provisions'
import Sign from './Sign'

const DashboardCommingSoon = () => {
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'comming_soon', getCompanyId()]
  const { data: commingSoonCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'comming_soon',
    },
    queryOptions: {
      enabled: !isDataCached(commingSoonCache),
    },
  })

  const { birthdays, anniversaries, provisions } =
    commingSoonCache?.comming_soon || {}

  return (
    <Box marginBottom={1} display="flex" flexDirection="column">
      <Typography variant="subtitle1" gutterBottom>
        Próximamente
      </Typography>
      {dashboardQuery?.isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {birthdays?.length === 0 && anniversaries?.length === 0 ? (
            <Sign />
          ) : (
            <>
              {birthdays?.length > 0 ? (
                <Birthdays birthdays={birthdays} />
              ) : (
                <Sign type="birthdays" />
              )}
              {anniversaries?.length > 0 ? (
                <Anniversaries anniversaries={anniversaries} />
              ) : (
                <Sign type="anniversaries" />
              )}
            </>
          )}

          {provisions ? <Provisions provisions={provisions} /> : null}
        </>
      )}
    </Box>
  )
}

export default DashboardCommingSoon
