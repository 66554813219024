import {
  ONBOARDING_CONGRATULATIONS,
  ONBOARDING_PERSONALIZE,
  ONBOARDING_ROLE,
  ONBOARDING_WELCOME,
} from 'config/routes'

import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

// OnBoarding Steps into onboarding_first_steps
// Step 0: User starts in nominapp -> account_configurated
// Step 1: User create first worker -> first_worker
// Step 2: User pay first test payroll -> first_payroll
// Step 3: User configure electronic payroll (Finish OnBoarding) -> electronic_payroll_configurated

export function setOnBoardingNextStepWorkerForm() {
  trackEvent(integrationEvent.FIRST_EMPLOYEE)
}

export function useHasPendingOnboarding(step) {
  const PATHNAME = {
    welcome: ONBOARDING_WELCOME(),
    role: ONBOARDING_ROLE(),
    data: ONBOARDING_PERSONALIZE(),
    select_action: ONBOARDING_CONGRATULATIONS(),
  }
  let value = false

  if (typeof step === 'string') {
    if (step !== 'finished') {
      value = true
    }
  }

  return { pathname: PATHNAME[step], value }
}

const numberOfEmployees = {
  '1-9': '1 - 9',
  '10-19': '10 - 19',
  '20-39': '20 - 39',
  '40-60': '40 - 60',
  over_61: 'Más de 61',
  zero: 'No tengo empleados',
}

export const numberOfEmployeesOptions = Object.keys(numberOfEmployees).map(
  (key) => ({
    label: numberOfEmployees[key],
    value: key,
  })
)
