import Accordion from '@material-ui/core/Accordion'
import Typography from '@material-ui/core/Typography'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { questions } from './helpers'

const useStyles = makeStyles((theme) => ({
  questionsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      '& > div': {
        maxWidth: '100%',
      },
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  listContainer: {
    paddingLeft: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}))

const FrequentQuestions = ({ forwardedRef, filterIndexes = [] }) => {
  const classes = useStyles()
  return (
    <div ref={forwardedRef}>
      <Typography variant="h5" className={classes.title}>
        Preguntas frecuentes
      </Typography>

      <Grid container spacing={3} className={classes.questionsContainer}>
        <Grid item xs={9}>
          {questions
            ?.filter(
              (_, questionIndex) => !filterIndexes.includes(questionIndex)
            )
            ?.map((question) => (
              <Accordion key={question.title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{question.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{question.description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default FrequentQuestions
