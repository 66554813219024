import { useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import SummaryTable from '../Summary/Index'
import ElectronicPayroll from './ActionGroup/ElectronicPayroll'
import Novelties from './ActionGroup/Novelties'
import Payments from './ActionGroup/Payments/Payments'
import Payslips from './ActionGroup/Payslips'
import Reports from './ActionGroup/Reports'
import { PeriodContext } from './Payroll'
import SocialBenefitsAlert from './Review/SocialBenefitsAlert'
import Totals from './Review/Totals'
import { getSocialBenefitLink, isSbAlertActionsShown } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  link: {
    display: 'flex',
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  content: {
    marginBottom: theme.spacing(6),
  },
  actions: {
    display: 'flex',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2.5),
    flexWrap: 'wrap',
  },
}))

const GeneratedPayroll = () => {
  const classes = useStyles()

  const {
    period: {
      id: periodId,
      payrolls,
      contracts_counter: contractsCounter,
      company_cost: companyCost,
      workers_payment: workersPayment,
    },
    options: {
      social_benefits: socialBenefits,
      electronic_payroll: electronicPayroll,
    },
    payPreviousPayroll,
  } = useContext(PeriodContext)

  const getSbAlertMessage = () => {
    const date = new Date()
    const month = date.getMonth()
    let message
    let socialBenefitLabel

    if (month === 0) {
      socialBenefitLabel = socialBenefits?.filter(
        (sb) => sb.category === 'severance_interests'
      )[0]?.label

      message = `los ${socialBenefitLabel}`
    } else if (month === 1) {
      socialBenefitLabel = socialBenefits?.filter(
        (sb) => sb.category === 'severance'
      )[0]?.label

      message = `las ${socialBenefitLabel}`
    } else if (month === 5 || month === 11) {
      socialBenefitLabel = socialBenefits?.filter(
        (sb) => sb.category === 'service_bonus'
      )[0]?.label

      message = `la ${socialBenefitLabel}`
    }
    return message
  }

  const showExclusiveFileAlert =
    payrolls.filter((item) => item.early_payment).length !== 0 ||
    contractsCounter.terminated !== 0

  const electronicPayrollUnAuthorized = !electronicPayroll?.authorized

  return (
    <div className={classes.container}>
      {showExclusiveFileAlert && (
        <Alert severity="info" closable>
          Recuerda que para las personas liquidadas o con pago anticipado de
          vacaciones (sin días trabajados despues de vacaciones) se genera un
          archivo exclusivo para pagar en el banco.
        </Alert>
      )}
      {isSbAlertActionsShown(socialBenefits) ? (
        <Alert severity="info" withBorder closable fullWidth>
          <div className={classes.alert}>
            <Typography variant="body2">
              Aún no has liquidado {getSbAlertMessage()}.{' '}
            </Typography>
            <div className={classes.link}>
              <AttachMoneyIcon fontSize="small" />
              <Link
                to={{
                  pathname: getSocialBenefitLink(
                    socialBenefits,
                    periodId,
                    undefined,
                    payPreviousPayroll
                  ),
                  state: { payPreviousPayroll: false },
                }}
              >
                <strong>Liquidar</strong>
              </Link>
            </div>
          </div>
        </Alert>
      ) : null}
      <SocialBenefitsAlert />
      <div className={classes.content}>
        <div className={classes.actions}>
          <Totals workersPayment={workersPayment} companyCost={companyCost} />
          <Payslips />
          <Reports />
          <Payments />
          <Novelties />
          <ElectronicPayroll
            electronicPayrollUnAuthorized={electronicPayrollUnAuthorized}
          />
        </div>
      </div>
      <SummaryTable />
    </div>
  )
}

export default GeneratedPayroll
