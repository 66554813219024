import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import BankField from 'components/UI/Formik/CommonFields/BankField'
import useSubscription from 'components/Subscription/Atoms/useSubscription'

import pseImg from 'assets/images/views/subscription/pse.svg'

import ContainerFields from './Fields/ContainerFields'
import ClientTypeField from './Fields/ClientType'
import PersonalFields from './Fields/PersonalFields'
import AccountFields from './Fields/Account'

const useStyles = makeStyles((theme) => ({
  captionSafetyPay: {
    marginTop: theme.spacing(3),
  },
  logoPse: {
    width: 70,
    marginTop: theme.spacing(2),
  },
}))

const SubscriptionPSEFields = () => {
  const classes = useStyles()
  const { showNewSubscription } = useSubscription()

  return (
    <>
      <ContainerFields>
        <ClientTypeField />
        <PersonalFields />
        <BankField />
        <AccountFields />
      </ContainerFields>
      {!showNewSubscription ? (
        <>
          <Typography
            variant="caption"
            display="block"
            className={classes.captionSafetyPay}
          >
            Paga seguro con el respaldo de:
          </Typography>
          <img src={pseImg} alt="PSE Logo" className={classes.logoPse} />
        </>
      ) : null}
    </>
  )
}

export default SubscriptionPSEFields
