/**
 * This is to better control Avista SAS errors
 * code 001: Estamos teniendo problemas para conectarnos con AVISTA SAS
 * code 002: Se debe modificar el número de quincenas
 * code 003: El valor ingresado supera el máximo valor a prestar de {maxCreditAmount}
 */
class AvistaError extends Error {
  constructor({
    name = 'AvistaError',
    message = 'Estamos teniendo problemas para conectarnos con AVISTA SAS',
    code = '001',
  } = {}) {
    super()

    this.name = name
    this.message = message
    this.code = code
  }
}

export const AVISTA_ERROR_MESSAGES = {
  '002': 'Se debe modificar el número de cuotas',
  '003': 'El valor ingresado supera el máximo valor a prestar',
}

export default AvistaError
