import clsx from 'clsx'
import { Fragment } from 'react'
import { Redirect, Link as RouterLink } from 'react-router-dom'

import { Card, Typography, makeStyles } from '@material-ui/core'

import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { SUBSCRIPTION } from 'config/routes'

import { getAlphaHexValue } from 'styles/colors'

import useSubscriptionPlans from '../../NewSubscription/useSubscriptionPlans'
import BillingCard from './ActionGroup/BillingCard'
import CurrentPlan from './ActionGroup/CurrentPlan'
import PaymentMethodCard from './ActionGroup/PaymentMethodCard'
import SeePaymentsCard from './ActionGroup/SeePaymentsCard'

const useStyles = makeStyles((theme) => ({
  paymentInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(5),
    marginTop: theme.spacing(5),
    [theme.breakpoints.between(960, 1100)]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up(1100)]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(5),
    [theme.breakpoints.up(1024)]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  updatePlan: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  planDetail: {
    [theme.breakpoints.up(1024)]: {
      gridColumn: 'span 2',
    },
  },
  description: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  updateButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(80)}`,
    },
  },
}))

const ShowSubscription = () => {
  const classes = useStyles()
  const { plansQuery, subscriptionQuery } = useSubscriptionPlans()
  const { subscriptionQuery: subscriptionPayments } = useSubscriptionService({
    serviceParams: { queryKey: 'getAllPayments' },
  })
  const currentPlan = plansQuery?.data?.data?.plans?.find(
    (plan) => plan.current_plan
  )
  const isActiveUser = [
    'active',
    'active_premium',
    'exonerated_payment',
  ].includes(subscriptionQuery.data?.data?.status)
  const isOldPlan =
    subscriptionQuery.data?.data?.plan_category === 'workers_range_plan'
  const Wrapper = isActiveUser || isOldPlan ? 'div' : Fragment

  if (
    [
      'free_trial',
      'canceled',
      'expired',
      'premium_canceled',
      'premium_expired',
      'premium_due',
      'basic',
      'premium_trial',
      'free_trial_terminated',
    ].includes(subscriptionQuery.data?.data?.status) &&
    subscriptionPayments.data?.length === 0
  ) {
    return <Redirect to={SUBSCRIPTION()} />
  }

  return (
    <Page
      header="Suscripción"
      isLoading={subscriptionQuery.isLoading || plansQuery.isLoading}
    >
      <div
        className={clsx({
          [classes.container]: !isOldPlan && !isActiveUser,
        })}
      >
        <div
          className={clsx({
            [classes.planDetail]:
              !(!isActiveUser && isOldPlan) || !isActiveUser,
          })}
        >
          <CurrentPlan
            subscriptionData={subscriptionQuery.data?.data}
            currentPlan={currentPlan}
            isActiveUser={isActiveUser}
          />
        </div>
        {!isActiveUser && !isOldPlan ? (
          <Card className={classes.updatePlan}>
            <Typography variant="h5" gutterBottom>
              Nominapp Premium
            </Typography>
            <Typography>
              Superpoderes que te permiten automatizar tu proceso de nómina.
            </Typography>
            <Typography className={classes.description} variant="body2">
              Usuarios administradores: Ilimitados
              <br />
              Soporte por chat en tiempo real
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                component={RouterLink}
                to={SUBSCRIPTION()}
                className={classes.updateButton}
              >
                Actualizar
              </Button>
            </div>
          </Card>
        ) : null}
        <Wrapper
          className={clsx({
            [classes.paymentInfo]:
              (!isActiveUser && isOldPlan) || isActiveUser || isOldPlan,
          })}
        >
          <PaymentMethodCard subscriptionData={subscriptionQuery.data?.data} />
          <BillingCard />
          <SeePaymentsCard />
        </Wrapper>
      </div>
    </Page>
  )
}

export default ShowSubscription
