import http, { getApiURL } from './httpService'

export const updateSubscription = () => {
  const url = getApiURL({
    pathname: '/subscription',
  })

  return http.putV2(url)
}

export default {
  updateSubscription,
}
