import { useQuery } from 'react-query'

import {
  getCitiesByDepartment,
  getDepartments,
} from 'services/departmentService'

import useErrorHandler from './useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, departmentId } = serviceParams

  if (queryKey === 'departments') return getDepartments()
  if (queryKey === 'citiesByDepartment')
    return getCitiesByDepartment(departmentId)

  return null
}

const useDepartmentsService = ({
  serviceParams = {},
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restDepartmentsResponse } = useQuery(
    [serviceParams.queryKey, ...(serviceParams.queryKeyExtras || [])],
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    departmentsQuery: {
      data,
      ...restDepartmentsResponse,
    },
  }
}

export default useDepartmentsService
