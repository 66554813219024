import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import OrganizationEmail from 'components/UI/OrganizationEmail'
import Page from 'components/UI/Page/Page'

import useIntegrationService from 'utils/hooks/settings/integrationService'

import { PERIOD_HISTORY } from 'config/routes'

import ElectronicPayrollHistory from './ElectronicPayrollHistory/ElectronicPayrollHistory'
import PayrollHistory from './PayrollHistory/PayrollHistory'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.common.white,
  },
}))

const getTabs = (electronicPayroll) => {
  return [
    { key: 'payroll_history', label: 'Nómina' },
    {
      key: 'electronic_payroll_history',
      label: 'Nómina electrónica',
      hidden: !electronicPayroll,
    },
  ]
}

const PeriodHistory = () => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = qs.parse(location.search)
  const [activeTab, setActiveTab] = useState('payroll_history')
  const [hasAlegraIntegration, setHasAlegraIntegration] = useState(null)

  const classes = useStyles()
  const { integrationsQuery } = useIntegrationService({
    serviceParams: {
      queryKey: 'integrationsCompany',
    },
    queryOptions: {
      onSuccess: ({ data }) =>
        setHasAlegraIntegration(
          data?.filter((integration) => integration.provider === 'alegra')[0]
            .active
        ),
    },
  })

  useEffect(() => {
    if (queryParams?.tab === 'electronic_payroll_history') {
      setActiveTab('electronic_payroll_history')
    }
  }, [queryParams.tab])

  const { company } = useUser()

  const electronicPayrollAuthorized = company?.electronic_payroll_authorized

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
    history.push({
      pathname: PERIOD_HISTORY(),
      search: `?tab=${newTab}`,
    })
  }

  return (
    <Page
      documentTitle="Historial de Periodos"
      header={
        <div>
          <Typography variant="h5">Historial de Periodos</Typography>
          <Typography variant="body2">
            Si quieres cargar la información de períodos anteriores escríbenos a{' '}
            <OrganizationEmail />.
          </Typography>
        </div>
      }
      isLoading={integrationsQuery.isLoading}
    >
      {electronicPayrollAuthorized ? (
        <Tabs
          value={activeTab}
          tabs={getTabs(electronicPayrollAuthorized)}
          onChange={handleChangeTab}
          className={classes.tabs}
        />
      ) : null}
      {activeTab === 'payroll_history' && hasAlegraIntegration !== null && (
        <PayrollHistory
          hasAlegraIntegration={hasAlegraIntegration}
          activeTab={activeTab}
        />
      )}
      {activeTab === 'electronic_payroll_history' && (
        <ElectronicPayrollHistory activeTab={activeTab} />
      )}
    </Page>
  )
}

export default PeriodHistory
