import { useQuery, useMutation } from 'react-query'

import {
  getAllPosition,
  putAllPosition,
} from 'services/company/positionService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams

  if (queryKey === 'companyPosition') return getAllPosition()

  return null
}

const getMutationFunction = (mutationData) => {
  const { data, mutationMethod } = mutationData

  if (mutationMethod === 'PUT') return putAllPosition(data)

  return null
}

const usePositionService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'companyPosition' },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPosition } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const positionMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    positionQuery: { data, ...restPosition },
    positionMutation,
  }
}

export default usePositionService
