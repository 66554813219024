import { useHistory, useLocation } from 'react-router-dom'

import useModals from 'components/App/ModalsManager/useModals'

import PaymentModal from './PaymentModal'
import useSubscriptionPlans from '../../NewSubscription/useSubscriptionPlans'

const usePaymentModal = () => {
  const modals = useModals()
  const history = useHistory()
  const location = useLocation()

  const handleClose = (successPayment = false) => {
    modals.closeAll()
    if (location.pathname === '/workers/new' && !successPayment) {
      history.goBack()
    }
  }

  const { plansQuery } = useSubscriptionPlans({
    subscriptionQueryOptions: {
      enabled: false,
    },
  })
  const currentPlan =
    plansQuery?.data?.data?.plans?.find((plan) => plan.current_plan) || {}
  const handlePreviousModal = () => {
    modals.closeModal('paymentMethod')
  }

  const openModal = (extraWorkers = null) => {
    modals.openModal({
      id: 'paymentMethod',
      content: (
        <PaymentModal
          handleClose={handleClose}
          currentPlan={currentPlan}
          extraWorkersEdit={extraWorkers}
          handlePreviousModal={handlePreviousModal}
        />
      ),
      modalProps: {
        header: 'Método de pago',
        okText: 'Procesar pago',
        onCloseModal: handleClose,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  return { openModal }
}

export default usePaymentModal
