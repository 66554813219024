import { parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

import Link from '@material-ui/core/Link'

export const tabsData = (tabs = {}, socialBenefitsId) => {
  const tabsArray = []
  if (tabs.payslip_detail) {
    tabsArray.push({ key: 'payslip_detail', label: 'Detalle de la colilla' })
  }
  if (tabs.social_security_and_parafiscals) {
    tabsArray.push({
      key: 'social_security_and_parafiscals',
      label: 'Seguridad Social y Parafiscales',
    })
  }
  if (tabs.social_benefits_provisions) {
    tabsArray.push({
      key: 'social_benefits_provisions',
      label: 'Provisión Prestaciones Sociales',
    })
  }
  if (tabs.payroll_retention) {
    tabsArray.push({
      key: 'payroll_retention',
      label: 'Retención en la fuente',
    })
  }
  if (tabs.social_benefits && !socialBenefitsId.includes('socialBenefitsId')) {
    tabsArray.push({
      key: 'social_benefits',
      label: 'Prestaciones Sociales',
    })
  }
  if (tabs.termination) {
    tabsArray.push({
      key: 'termination',
      label: 'Liquidación',
    })
  }
  return tabsArray
}

export const tableTitles = {
  // Detalles de la colilla
  payment_summary: 'Resumen del pago',
  worked_days: 'Días trabajados',
  base_salary: 'Salario base y subsidio',
  overtime: 'Horas extras, ordinarias y recargos',
  holidays: 'Vacaciones',
  incapacities: 'Incapacidades',
  licenses: 'Licencias',
  additional_incomes: 'Ingresos adicionales',
  retentions: 'IBC Seguridad Social',
  social_security_retentions: 'Retenciones',
  deductions: 'Deducciones',
  // Seguridad Social y parafiscales
  social_security_company: 'Cálculos seguridad social',
  parafiscals_company: 'Cálculos parafiscales',
  ibc_health: 'Cálculo IBC salud',
  ibc_pension: 'Cálculo IBC pensión',
  ibc_risks: 'Cálculo IBC riesgos',
  ibc_parafiscals: 'Cálculo IBC parafiscales',
  // Seguridad Social y parafiscales
  social_benefits: 'Resumen cálculos provisión prestaciones sociales',
  severance_base: 'Cálculo base cesantías e intereses a las cesantías',
  service_bonus_base: 'Cálculo base prima',
  holidays_base: 'Cálculo base vacaciones',
}

export const tableSubtitles = {
  // Retención en la fuente
  summary: 'Resumen de retefuente',
  income: 'Total de ingresos',
  untaxed_income: 'Total de ingresos no gravados',
  subtotal_1: 'Subtotal 1',
  deductions: 'Deducciones',
  exempt_income: 'Rentas exentas',
  subtotal_2: 'Subtotal 2',
  subtotal_3: 'Subtotal 3',
  subtotal_4: 'Subtotal 4',
  payroll_retention: 'Retención en la fuente',
  uvt_range: 'Instrucción para calcular la retención en UVTs',
  // Liquidación laboral
  termination_base: 'Salarios y días trabajados',
  compensation: 'Indemnización por días trabajados',
  termination_severance_base: 'Base cesantías y días trabajados',
  termination_service_bonus_base: 'Salarios y días trabajados',
  termination_holidays_base: 'Salarios y días trabajados',
  // Prestaciones sociales
  service_bonus: 'Salarios y días trabajados',
  severance: 'Base cesantías y días trabajados',
  severance_interests: 'Base cesantías y días trabajados',
}

export const pageDescriptions = {
  payslip_detail: {
    title: 'Detalles de la colilla',
    description: null,
    link: null,
  },
  social_security_and_parafiscals: {
    title: 'Seguridad social y parafiscales',
    description: null,
    link: null,
  },
  social_benefits_provisions: {
    title: 'Provisión prestaciones sociales',
    description: null,
    link: null,
  },
  payroll_retention: {
    title: 'Retención en la fuente',
    description:
      'En esta sección se describen los cálculos de la retención en la fuente siguiendo el procedimiento 1 (cálculo mensual). Recuerda que si modificaste manualmente los cálculos, en esta sección verás los cálculos que hace Nominapp y no los que has ingresado.',
    link: null,
  },
  termination: {
    title: 'Liquidación laboral',
    description:
      'En esta sección se describen los principales cálculos necesarios para la liquidación del contrato de un empleado. Recuerda que si modificaste manualmente alguno de los cálculos o bases, en esta sección verás los cálculos que hace Nominapp y no los que has ingresado.',
    link:
      'https://ayuda.nominapp.com/help/como-se-calculan-las-bases-de-la-liquidacion',
  },
  social_benefits: {
    title: 'Prestaciones sociales',
    description:
      'En esta sección se describen los cálculos de la base promedio para el pago de la prestaciones sociales. Recuerda que si modificaste manualmente los cálculos, en esta sección verás los cálculos que hace Nominapp y no los que has ingresado.',
    link: null,
  },
}

export const calculations = {
  // Retención en la fuente
  retention_income: {
    label: 'Ingresos',
    numerator: 'Suma de todos los ingresos',
  },
  retention_untaxed_income: {
    label: 'Ingresos no gravados',
    numerator: 'Suma de los ingresos no gravados',
  },
  retention_subtotal_1: {
    label: 'Ingresos netos',
    numerator: 'Ingresos - Ingresos no gravados',
  },
  retention_deductions: {
    label: 'Deducciones',
    numerator: 'Suma deducciones',
  },
  retention_exempt_income: {
    label: 'Rentas exentas',
    numerator: 'Suma rentas exentas',
  },
  retention_subtotal_2: {
    label: 'Subtotal 2',
    numerator: 'Subtotal 1 - Deducciones - Rentas exentas',
  },
  retention_subtotal_3: {
    label: 'Subtotal 3',
    numerator: 'Subtotal 2 - Renta exenta (Numeral 10 articulo 206 ET)',
  },
  retention_subtotal_4: {
    label: 'Subtotal 4',
    numerator:
      'Subtotal 3 + Valor sobre el limite del 40% en las deducciones y rentas exentas',
  },
  retention_payroll: {
    label: 'Retención',
    numerator: '(Subtotal 4 en UVTs * Tarifa + UVTs adicionales) * Valor UVT',
  },
  UVT_Range: {
    numerator: 'Según tabla',
  },

  // Liquidación
  termination_base: {
    label: 'Salario base',
    numerator: 'Total salarios * 30 días',
    denominator: 'Total días trabajados',
  },
  holidays_base: {
    label: 'Vacaciones',
    numerator: 'Suma de todos los salarios',
    denominator: 'Total de días trabajados',
  },
  severance_base: {
    label: 'Cesantías',
    numerator: 'Salario base * Días trabajados en el año',
    denominator: '360 días',
  },
  service_bonus_base: {
    label: 'Prima de servicios',
    numerator: 'Salario base * Días trabajados en el año',
    denominator: '360 días',
  },

  // Prestaciones sociales
  base_salary: {
    label: 'Salario base',
    numerator: 'Total salarios * 30 días',
    denominator: 'Total días trabajados',
  },
  base_salary_with_transport_subsidy: {
    label: 'Salario base con subsidio de transporte',
    numerator: 'Total salarios * 30 días',
    denominator: 'Total días trabajados',
    complement: ' + Subsidio de transporte',
  },
  service_bonus: {
    label: 'Prima de servicios',
    numerator: 'Salario base * Días trabajados en el año',
    denominator: '360 días',
  },
  severance: {
    label: 'Cesantías',
    numerator: 'Salario base * Días trabajados en el año',
    denominator: '360 días',
  },
  severance_interests: {
    label: 'Intereses a las cesantías',
    numerator: 'Cesantías * 12% * Días trabajados en el año',
    denominator: '360 días',
  },
}

export const UVTRange = {
  information: {
    title: 'Rango UVT',
    description:
      'Es el rango en el que se encuentra el ingreso laboral en UVTs que determina el cálculo que se debe hacer para hallar el valor total a retener.',
    link: null,
    formulas: ['UVT_Range'],
  },
  data: [
    {
      range: '>0 y 95',
      rate: 0,
      instruction: '$0',
    },
    {
      range: '>95 y 150',
      rate: 19,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 95 UVT) x 19%',
    },
    {
      range: '>150 y 360',
      rate: 28,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 150 UVT) x 28% más 10 UVT',
    },
    {
      range: '>360 y 640',
      rate: 33,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 360 UVT) x 33% más 69 UVT',
    },
    {
      range: '>640 y 945',
      rate: 35,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 640 UVT) x 35% más 162 UVT',
    },
    {
      range: '>945 y 2300',
      rate: 37,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 945 UVT) x 37% más 268 UVT',
    },
    {
      range: '>2300 en adelante',
      rate: 39,
      instruction:
        '(Ingreso laboral gravado expresado en UVT menos 2300 UVT) x 39% más 770 UVT',
    },
  ],
  totals: {
    formulas: [],
    total: {
      label: null,
      value: null,
    },
  },
}

export const formatComment = (comment) => {
  const splittedComment = comment.split(/[[\]]{1,2}/)
  splittedComment.length -= 1

  if (splittedComment.length === 0) return `- ${comment}`

  const linkData = splittedComment[splittedComment.length - 1].split(',')

  return (
    <>
      {`- ${splittedComment[0]}`}{' '}
      <Link href={linkData[1]} target="_blank">
        {linkData[0]}
      </Link>
    </>
  )
}

export const getDetails = (
  baseDetails = {},
  payrollRetention = {},
  termination = {},
  socialBenefits = {}
) => {
  const baseDetailsCopy = cloneDeep(baseDetails)
  const payrollRetentionData = cloneDeep(payrollRetention)
  const {
    payroll_retention: { payroll_retention: payrollRetentionInfo = {} } = {},
  } = payrollRetentionData

  if (baseDetailsCopy?.header) {
    baseDetailsCopy.header.period.period_payment.initial_day = parseISO(
      baseDetailsCopy.header.period.period_payment.initial_day
    )
    baseDetailsCopy.header.period.period_payment.end_day = parseISO(
      baseDetailsCopy.header.period.period_payment.end_day
    )
  }

  // Identifying UVT rate to make it stronger in UVT table
  // in retention_payroll tab
  let marginalFee
  payrollRetentionInfo?.data?.forEach((_, index) => {
    if (payrollRetentionInfo.data[index].name === 'Tarifa marginal (%)')
      marginalFee = payrollRetentionInfo.data[index].value
  })
  UVTRange.data.forEach((_, index) => {
    if (UVTRange.data[index].rate === marginalFee) {
      UVTRange.data[index].selected = true
    }
  })

  if (payrollRetentionData.payroll_retention) {
    payrollRetentionData.payroll_retention.uvt_range = UVTRange
  }

  return {
    ...baseDetailsCopy,
    ...payrollRetentionData,
    ...termination,
    ...socialBenefits,
  }
}
