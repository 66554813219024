import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import { getTabsData } from 'components/Subscription/OldSubscription/Paywall/Payment/Form/helpers'

import { getWordSingular } from 'utils/format'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_TRANSACTION_RESPONSE } from 'config/routes'

import { getPaymentData } from '../NewSubscription/Index/helpers'

const usePayment = (onCancel, plan = {}, extraWorkers) => {
  // Access to subscription from context for payroll and workers view
  const { subscription, refreshCompany } = useUser()
  const formRef = useRef()
  const { showInfoMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const history = useHistory()
  const subscriptionType = subscription?.type
  const paidWorkers = extraWorkers || subscription?.payrolls_size

  const { paymentMethodMutation } = usePaymentMethodService()

  const tabsData = getTabsData(subscription?.payment_category, extraWorkers)

  const handleForm = (formHandler) => {
    if (formRef.current) {
      formRef.current?.[formHandler]()
    }
  }

  const handleChangeTab = () => {
    handleForm('resetForm')
  }

  const callbackSuccess = useCallback(
    (response, activePayment) => {
      if (activePayment === 'pse') window.location.href = response.bank_url

      if (activePayment === 'automatic_debit') {
        showInfoMessage(response.message)
        onCancel()
      }

      if (activePayment === 'credit_card') {
        if (extraWorkers && response.paid_workers) {
          refreshCompany()
          onCancel(true)
          showInfoMessage(
            `La transacción fue exitosa. Ahora puedes tener hasta ${
              response?.paid_workers
            } ${getWordSingular(
              'personas',
              response?.paid_workers <= 1
            )} en tu nómina.`,
            {
              preventDuplicate: true,
            }
          )
        } else if (response.payment_status) {
          onCancel(true)
          history.push({
            pathname: SUBSCRIPTION_TRANSACTION_RESPONSE(),
            search: `?status=${response.payment_status}`,
            state: {
              payment_status: response.payment_status,
              plan_id: response.plan_id,
              value: response.value,
            },
          })
        } else {
          showInfoMessage(response.message)
          onCancel()
        }
      }
    },
    [extraWorkers, history, onCancel, refreshCompany, showInfoMessage]
  )

  const callbackError = useCallback(
    (error, currentPlan) => {
      if (
        (error.errors?.[0].code.startsWith('24') ||
          error.errors?.[0].code === '1101') &&
        !extraWorkers
      ) {
        history.push({
          pathname: SUBSCRIPTION_TRANSACTION_RESPONSE(),
          search: '?status=DECLINED',
          state: {
            payment_status: 'DECLINED',
            plan_id: currentPlan?.id,
            error: error.errors[0],
          },
        })
      } else {
        handleError(error, null, {
          notistackOptions: { preventDuplicate: true },
        })
      }
    },
    [extraWorkers, handleError, history]
  )

  const onSubmit = useCallback(
    (values, activePayment) => {
      const paymentData = getPaymentData(
        values,
        activePayment,
        plan?.id,
        subscriptionType,
        paidWorkers,
        subscription?.partner,
        extraWorkers
      )

      paymentMethodMutation.mutate(
        {
          paymentMethod: activePayment,
          paymentMethodData: paymentData,
        },
        {
          onSuccess: (response) => callbackSuccess(response, activePayment),
          onError: (error) => callbackError(error, plan),
        }
      )
    },
    [
      callbackError,
      callbackSuccess,
      extraWorkers,
      paidWorkers,
      paymentMethodMutation,
      plan,
      subscription.partner,
      subscriptionType,
    ]
  )

  const updatePaymentMethod = () => handleForm('handleSubmit')
  const isLoading = paymentMethodMutation?.isLoading

  return {
    tabsData,
    handleChangeTab,
    onSubmit,
    updatePaymentMethod,
    formRef,
    isLoading,
  }
}

export default usePayment
