import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'

import CustomField from 'components/UI/Formik/CustomField'

import { closedQuestionOptions } from 'utils/worker'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
    alignItems: 'center',
  },
  titleInput: {
    textAlign: 'left',
    maxWidth: '724px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
      textAlign: 'center',
    },
  },
  input: {
    flexDirection: 'row',
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  gutterBottom: {
    marginBottom: theme.spacing(3),
  },
}))

const CustomInputRow = ({
  children,
  title,
  name,
  component = 'radioGroup',
  gutterBottom = true,
  textfieldProps = {},
  buttonProps = {},
  dataCy,
}) => {
  const classes = useStyles()
  const { label } = textfieldProps
  const { buttonText, variant, onClick } = buttonProps

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.titleInput}>{title}</Typography>
        <div>
          {component === 'radioGroup' ? (
            <CustomField
              name={name}
              component="radio-group"
              className={classes.input}
              options={closedQuestionOptions}
              data-cy={dataCy}
            />
          ) : null}
          {component === 'textfield' ? (
            <CustomField
              name={name}
              label={label}
              component="number-format"
              data-cy={dataCy}
              startAdornment={
                <InputAdornment position="start">%</InputAdornment>
              }
            />
          ) : null}
          {component === 'button' ? (
            <Button variant={variant} onClick={onClick}>
              {buttonText}
            </Button>
          ) : null}
        </div>
      </div>
      {children}
      <Divider
        className={clsx(classes.divider, {
          [classes.gutterBottom]: gutterBottom,
        })}
      />
    </>
  )
}

export default CustomInputRow
