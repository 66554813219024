import { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import MenuList from '@material-ui/core/MenuList'

import { useUser } from 'components/App/UserContext/useUser'

import { getUserRoleSection } from 'utils/auth'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import ExpandableListItem from '../ExpandableListItem'
import NavigationBarMenuListItem from '../MenuListItem'
import useAdmin, { useAdmin2 } from './roleMenuOptions/admin'
import useOrganizer from './roleMenuOptions/organizer'
import useWorker from './roleMenuOptions/worker'

const MenuListTop = () => {
  const { pathname } = useLocation()
  const { isWorker, isOrganizer } = useUser()
  const adminOptions = useAdmin()
  const adminOptions2 = useAdmin2()
  const organizerOptions = useOrganizer()
  const workerOptions = useWorker()

  const menuOptions = useMemo(() => {
    const role = getUserRoleSection(pathname)
    if (!isOrganizer && !isWorker) return adminOptions
    if (isWorker) return workerOptions
    if (role === 'admin') return adminOptions
    if (role === 'worker') return workerOptions
    return organizerOptions
  }, [
    adminOptions,
    isOrganizer,
    isWorker,
    organizerOptions,
    pathname,
    workerOptions,
  ])

  const newMenuOptions = useMemo(() => {
    const role = getUserRoleSection(pathname)
    if (!isOrganizer && !isWorker) return adminOptions2
    if (isWorker) return workerOptions
    if (role === 'admin') return adminOptions2
    if (role === 'worker') return workerOptions
    return organizerOptions
  }, [
    adminOptions2,
    isOrganizer,
    isWorker,
    organizerOptions,
    pathname,
    workerOptions,
  ])

  const { freetimeBeta } = useFeatureFlags({
    flags: ['freetimeBeta'],
  })

  return (
    <MenuList disablePadding>
      {freetimeBeta?.value === 'on'
        ? newMenuOptions.map((item) => <NavigationBarMenuListItem {...item} />)
        : menuOptions.map((item) => {
            if (item?.options) {
              return <ExpandableListItem {...item} />
            }

            return <NavigationBarMenuListItem {...item} />
          })}
    </MenuList>
  )
}

const MenuListTopMemoized = memo(MenuListTop)

export default MenuListTopMemoized
