import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

export default {
  general_incapacity_payment:
    'Configura cómo vas a pagar la incapacidad general.',
  pay_holidays_31: (
    <>
      Puedes escoger si pagas o no el día 31 en vacaciones disfrutadas. Puedes
      leer más al respecto{' '}
      <TooltipLink href="https://goo.gl/BPjZ2x">aquí</TooltipLink>.
    </>
  ),
  proportional_deductions:
    'Las deducciones (no retenciones) de un período que tenga vacaciones quieres que se paguen al final del periodo o proporcional en cada pago.',
  proportional_recurrent_payments:
    'Los ingresos no salariales recurrentes dependerán del número de días trabajados (proporcionales) o es un valor fijo por periodo',
  transport_subsidy_per_period:
    'Escoge si el subsidio de transporte se debe pagar cada quincena o una vez al mes',
  transport_subsidy_base: (
    <>
      Escoge si se toma como base para el subsidio de transporte solo el salario
      o salario más ingresos salariales adicionales (sin incluir horas extras o
      recargos). Puedes leer más al respecto{' '}
      <TooltipLink href="https://ayuda.nominapp.com/help/por-que-se-desactiva-el-auxilio-de-transporte-de-un-empleado">
        aquí
      </TooltipLink>
      .
    </>
  ),
  worker_pension_unpaid_license:
    'El pago de pensión en licencias no remuneradas (Ausencia injustificada, suspensión o licencia no remunerada) se hace solo con el porcentaje que corresponde a la empresa o se incluye tambien el porcentaje del empleado',
  benefits_credits:
    'Se han añadido unos beneficios para los empleados con este van a lograr adquirir un crédito de libranza',
  electronic_payroll_social_benefit: (
    <>
      Elige si en la emisión de tu Nómina Electrónica quieres incluir las
      provisiones de las prestaciones sociales o no.{' '}
      <TooltipLink href="https://ayuda.nominapp.com/help/hc/es-419/articles/4415543257755-conoce-bajo-qu%C3%A9-conceptos-normativos-se-emite-la-n%C3%B3mina-electr%C3%B3nica-desde-nominapp-a-la-dian">
        Puedes leer más aquí
      </TooltipLink>
    </>
  ),
  electronic_payroll_holidays: (
    <>
      Elige si en la emisión de tu Nómina Electrónica quieres incluir las
      provisiones de las prestaciones sociales o no.{' '}
      <TooltipLink href="https://ayuda.nominapp.com/help/hc/es-419/articles/4415543257755-conoce-bajo-qu%C3%A9-conceptos-normativos-se-emite-la-n%C3%B3mina-electr%C3%B3nica-desde-nominapp-a-la-dian">
        Puedes leer más aquí
      </TooltipLink>
    </>
  ),
  pay_february_extra_days_if_hired_in_month:
    'Si la persona empieza contrato en febrero escoge si se paga el mes de febrero como si fuera de 30 días o con los días reales',
}
