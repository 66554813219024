import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'

const useStyles = makeStyles((theme) => ({
  containerPlanPremium: {
    backgroundColor: theme.palette.primary.main,
  },
  itemText: {
    color: theme.palette.common.white,
  },
  card: {
    width: '100%',
    maxWidth: 325,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    padding: theme.spacing(3, 2.75),
    borderRadius: 16,
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))

const BadgePlan = ({
  premium = false,
  title,
  userMessage,
  supportTime,
  buttonText,
  handleClick,
  description,
}) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.card, {
        [classes.containerPlanPremium]: premium,
      })}
    >
      <Typography
        variant="h5"
        color="primary"
        className={clsx({
          [classes.whiteText]: premium,
        })}
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={clsx(classes.description, {
          [classes.whiteText]: premium,
        })}
      >
        {description}
      </Typography>
      <Typography
        variant="body2"
        className={clsx({
          [classes.itemText]: premium,
        })}
      >
        <b>{userMessage}</b>
      </Typography>
      <Typography
        variant="body2"
        className={clsx({
          [classes.itemText]: premium,
        })}
      >
        {supportTime}
      </Typography>
      <Button
        size="small"
        color="primary"
        onClick={handleClick}
        variant={premium ? 'outlined' : 'contained'}
        className={classes.button}
      >
        {buttonText}
      </Button>
    </Paper>
  )
}

export default BadgePlan
