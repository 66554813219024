import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import {
  generateWageBaseSalaryValidationSchema,
  getMinimumSalary,
} from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema(
  'contract_category',
  'category'
)

export default yup.object({
  base_salary: wageBaseSalaryValidationSchema,
  category: yup.string().nullable().required(),
  initial_day: yup.date().nullable().default(null).required(),
  end_day: yup.date().nullable().default(null),
  transport_subsidy: yup
    .boolean()
    .nullable()
    .when(['base_salary', 'contract_category'], {
      is: (baseSalary, contractCategory) =>
        contractCategory !== 'contractor' &&
        Number(baseSalary) < getMinimumSalary({ multiplier: 2 }),
      then: yup.boolean().nullable().required(),
    }),
})
