import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Button from 'components/UI/Button/Button'

import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import getHistoryOptions from './helpers'

const HistoryMenu = ({ worker, workerOptions }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerHistoryMenu',
  })

  const historyOptions = getHistoryOptions(
    worker,
    workerOptions?.history_options
  )

  return (
    <>
      <Button endIcon={<ExpandMoreIcon />} {...bindTrigger(popupState)}>
        Historiales
      </Button>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {historyOptions.map(({ key, path, value }) => {
          return (
            <MenuItem
              key={key}
              component={Link}
              to={path}
              onClick={
                // async to avoid a strange delay
                async () =>
                  trackEvent(integrationEvent.EMPLOYEE_VIEW_HISTORY, key)
              }
            >
              {value}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default HistoryMenu
