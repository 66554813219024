import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import WebAssetOutlinedIcon from '@material-ui/icons/WebAssetOutlined'

import BracketsList from 'assets/icons/BracketsList'
import SupportAgent from 'assets/icons/SupportAgent'

export const featuresData = [
  {
    id: 'time',
    number: 1,
    title: 'Tiempo',
    description:
      'Ahorro de tiempo al manejar todos los clientes en un mismo lugar',
    icon: <QueryBuilderOutlinedIcon fontSize="large" />,
  },
  {
    id: 'integration',
    number: 2,
    title: 'Integración',
    description: 'Implementación personalizada para ti y tus clientes',
    icon: <BracketsList />,
  },
  {
    id: 'support',
    number: 3,
    title: 'Soporte',
    description: 'Ejecutivo de nómina y Chat en Línea',
    icon: <SupportAgent fontSize="large" />,
  },
  {
    id: 'discounts',
    number: 4,
    title: 'Descuentos',
    description: 'Descuentos y beneficios únicos para ti y tus clientesr',
    icon: <LocalOfferOutlinedIcon fontSize="large" />,
  },
  {
    id: 'vip',
    number: 5,
    title: 'VIP',
    description: 'Acceso VIP a nuevas funcionalidades del software',
    icon: <StarBorderOutlinedIcon fontSize="large" />,
  },
  {
    id: 'interfaces',
    number: 6,
    title: 'Interfaces Contables',
    description:
      'Procesos más ágiles con integración a + de 50 interfaces contables',
    icon: <WebAssetOutlinedIcon fontSize="large" />,
  },
]

export const summaryData = [
  {
    id: 'summary_item_1',
    title: 'Todo en un solo lugar',
    description:
      'Libérate de labores manuales y enfócate en crecer y ser rentable automatizando la nómina de todos tus clientes en nuestro panel multiempresa.',
    link: 'https://nominapp.com/partners',
  },
  {
    id: 'summary_item_2',
    title: 'Implementación a medida',
    description:
      'Trabaja en equipo con nuestros expertos mediante asesorías 100 % personalizadas donde aprenderás cómo crecer tu lista de clientes.',
    link: 'https://nominapp.com/partners',
  },
  {
    id: 'summary_item_3',
    title: 'Atrae nuevos clientes',
    description:
      'Piensa en grande y vende tus servicios contables con increíbles planes de ahorro. Entre más clientes, más beneficios tendrás.',
    link: 'https://nominapp.com/partners',
  },
]
