import { useHistory } from 'react-router-dom'

import Page from 'components/UI/Page/Page'

import { isAccountant, isAdmin, isOrganizer, isWorker } from 'utils/auth'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { WORKER_INDEX } from 'config/routes'

import TabsContainer from './Atoms/TabsContainer'
import { useWorkersCredits } from './Credits/Admin/helpers'
import CreditsContent from './Credits/Index'
import { useCreditApplicationStatus } from './Credits/Worker/helpers'

const benefitsTabs = [{ label: 'Créditos de libranza' }]

const BenefitsIndex = () => {
  const history = useHistory()
  const { handleError } = useErrorHandler()
  const isAllowedRole = isAdmin() || isOrganizer() || isAccountant()
  // First check id and status of credit
  const {
    creditStatusData,
    isGettingCreditStatus,
  } = useCreditApplicationStatus({
    enabled: isWorker(),
  })

  const { isLoadingWorkersCredits } = useWorkersCredits({
    enabled: isAllowedRole,
    onError: (error) => {
      handleError(error)
      history.push(WORKER_INDEX())
    },
  })

  const { companyConfigurationQuery } = useCompanyConfigurationService({
    serviceParams: { queryKey: 'companyConfiguration' },
    queryOptions: { enabled: isAllowedRole },
  })

  return (
    <Page
      documentTitle="Beneficios"
      header="Beneficios"
      isLoading={
        companyConfigurationQuery.isLoading ||
        isLoadingWorkersCredits ||
        isGettingCreditStatus
      }
    >
      <TabsContainer tabs={benefitsTabs}>
        <CreditsContent
          benefitsEnabled={companyConfigurationQuery.data?.workers_benefits}
          creditStatusData={creditStatusData}
        />
      </TabsContainer>
    </Page>
  )
}

export default BenefitsIndex
