import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import PayrollSummaryFileModal from 'components/Period/Payroll/Modals/PayrollSummaryFileModal'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Table from 'components/UI/Table/Table'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import periodService from 'services/payroll/periodService'

import { PERIOD_PAY_PAYROLL, PERIOD_PAY_PREVIOUS_PAYROLL } from 'config/routes'

import { PeriodContext } from '../Payroll/Payroll'
import getColumns from './helpers'

const SummaryTable = () => {
  const { period, payPreviousPayroll, setShowSummaryTable } = useContext(
    PeriodContext
  )
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const theme = useTheme()
  const featureState = usePremiumFeature('summaryReport')
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const { handleError } = useErrorHandler()
  const periodId = period.id
  const columns = useMemo(() => getColumns(periodId, payPreviousPayroll), [
    payPreviousPayroll,
    periodId,
  ])
  const { subscriptionMutation } = useSubscriptionService()

  const handleReturnToPayPayroll = useCallback(async () => {
    showLoadingModal()
    try {
      if (payPreviousPayroll) {
        history.replace(PERIOD_PAY_PREVIOUS_PAYROLL(periodId))
      } else {
        await periodService.updateCurrent({ payrolls_paid: false })
        history.replace(PERIOD_PAY_PAYROLL())
      }
    } catch (error) {
      handleError(error)
    }
    hideLoadingModal()

    setShowSummaryTable(true)
  }, [
    handleError,
    hideLoadingModal,
    history,
    payPreviousPayroll,
    periodId,
    setShowSummaryTable,
    showLoadingModal,
  ])

  const crmEvent = 'downloaded_payroll_summary_file'
  const handlePremiumReport = useCallback(() => {
    if (featureState.isPremiumFeature) {
      featureState.openModal('summaryReport')
      subscriptionMutation.mutate({
        mutationMethod: 'GET',
        crmEvent,
      })
    } else {
      setIsModalOpen(true)
    }
    return null
  }, [featureState, subscriptionMutation])

  const actions = useMemo(
    () => [
      {
        id: 'editPayroll',
        tooltip: payPreviousPayroll ? 'Regresar' : 'Editar nómina',
        isFreeAction: true,
        buttonVariant: 'outlined',
        onClick: handleReturnToPayPayroll,
      },
      {
        id: 'reportPayroll',
        tooltip: 'Descargar resumen',
        isFreeAction: true,
        buttonProps: {
          endIcon: (
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          ),
        },
        onClick: handlePremiumReport,
      },
    ],
    [
      featureState.isPremiumFeatureIcon,
      handlePremiumReport,
      handleReturnToPayPayroll,
      payPreviousPayroll,
    ]
  )

  const expandedRows = useMemo(
    () => ({
      0: true,
    }),
    []
  )

  const fetchSummary = (pageIndex, search) => {
    return {
      queryKey: ['getPayrollSummary', pageIndex, search],
      queryFunction: () =>
        periodService.getPayrollSummary({
          periodId,
          page: pageIndex + 1,
          search,
        }),
    }
  }

  return (
    <>
      <Table
        columns={columns}
        data={fetchSummary}
        actions={actions}
        options={{
          expandableRows: true,
          sorting: false,
          rowStyle: (_, row) => {
            const { white } = theme.palette.common
            const grey = theme.palette.grey[100]

            if (row.depth) {
              const parentIndex = parseInt(row.id, 10)
              return {
                backgroundColor: parentIndex % 2 ? grey : white,
              }
            }
            return {
              backgroundColor: row.index % 2 ? grey : white,
            }
          },
          expandedInitialState: expandedRows,
        }}
      />
      {isModalOpen && (
        <PayrollSummaryFileModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          period={period}
        />
      )}
    </>
  )
}

export default SummaryTable
