import MIconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import { getAlphaHexValue } from 'styles/colors'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[700],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}${getAlphaHexValue(4)}`,
    },
    '&:focus': {
      backgroundColor: `${theme.palette.common.black}${getAlphaHexValue(4)}`,
      color: theme.palette.grey[700],
      borderColor: 'transparent',
    },
  },
  icon: {
    fontSize: '25px !important',
  },
}))

const IconButton = ({ Icon, ...props }) => {
  const classes = useStyles()

  return (
    <MIconButton
      className={classes.button}
      size="small"
      variant="outlined"
      color="default"
      {...props}
    >
      <Icon color="inherit" className={classes.icon} />
    </MIconButton>
  )
}

export default IconButton
