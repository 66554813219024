import { useState } from 'react'
import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import CustomField from 'components/UI/Formik/CustomField'

import useNotifications from 'utils/hooks/useNotifications'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { trackEvent } from 'utils/integration'
import { getAutoCompleteElementFilteredOptions } from 'utils/form'
import usePositionService from 'utils/hooks/company/positionService'

import tooltipInfo from 'messages/worker'

import CreateDialog from './CreateDialog'

const WorkerFormFieldsPersonalPosition = ({ ...props }) => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const { showSuccessMessage } = useNotifications()
  const form = useFormikContext()
  const queryClient = useQueryClient()

  const { positionQuery, positionMutation } = usePositionService({
    serviceParams: { queryKey: 'companyPosition' },
  })

  const callbackMutatePositions = ([newPosition]) => {
    form.setFieldValue('position', newPosition)
    showSuccessMessage('El cargo se creó con éxito.')

    trackEvent(integrationEvent.COMPANY_POSITION_ADD)
  }

  const mutatePositionOption = (__, newValue) => {
    if (newValue?.id === 'create') {
      if (newValue.value) {
        positionMutation.mutate(
          {
            mutationMethod: 'PUT',
            data: [{ name: newValue.value }],
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries('companyAreas')
              callbackMutatePositions(data.data)
            },
          }
        )
      } else {
        setIsCreateDialogOpen(true)
      }
    } else {
      form.setFieldValue('position', newValue)
    }
  }

  return (
    <>
      {isCreateDialogOpen && (
        <CreateDialog
          handleClose={() => setIsCreateDialogOpen(false)}
          submitCallback={callbackMutatePositions}
        />
      )}
      <CustomField
        name="position"
        label="Cargo"
        component="autocomplete"
        placeholder="Escribe el cargo de la persona..."
        noOptionsText="No hay cargos registradas."
        loadingText="Cargando cargos..."
        options={positionQuery.data}
        tooltipContent={tooltipInfo.position}
        onChange={mutatePositionOption}
        loading={positionQuery.isLoading || positionMutation.isLoading}
        filterOptions={(opt, params) =>
          getAutoCompleteElementFilteredOptions(
            opt,
            params,
            'Crear nuevo cargo'
          )
        }
        {...props}
      />
    </>
  )
}

export default WorkerFormFieldsPersonalPosition
