import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'

import Link from 'components/UI/MaterialUI/Link'

import OnboardingCards from './OnboardingCards'
import OnboardingEmpty from './OnboardingEmpty'
import {
  AFFILIATIONS_DATA,
  FINISH_ONBOARDING,
  HISTORY,
  NEW_CONTRACTS,
  SIGN,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  containner: {
    minWidth: 264,
    height: 690,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  header: {
    width: 240,
    minHeight: 64,
    overflow: 'auto',
    display: 'grid',
    gridAutoRows: '8px auto',
  },
  colorBar: {
    backgroundColor: 'red',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  logoContainer: {
    backgroundColor: theme.palette.primary[500],
    height: '32',
    width: '32',
    borderRadius: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainerDisabled: {
    backgroundColor: theme.palette.grey[400],
    height: '32',
    width: '32',
    borderRadius: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disableLink: {
    pointerEvents: 'none',
  },
  title: {
    textDecoration: 'none',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflowY: 'overlay',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },
  },
  icon: {
    color: 'white',
  },
  [HISTORY]: {
    backgroundColor: theme.palette.complementary.blue,
  },
  [NEW_CONTRACTS]: {
    backgroundColor: theme.palette.secondary[500],
  },
  [SIGN]: {
    backgroundColor: theme.palette.complementary.strongOrange,
  },
  [AFFILIATIONS_DATA]: {
    backgroundColor: theme.palette.complementary.strongBlue,
  },
  [FINISH_ONBOARDING]: {
    backgroundColor: theme.palette.primary[500],
  },
  pending: {
    backgroundColor: theme.palette.grey[400],
  },
}))

const OnboardingCardsContainner = ({ step }) => {
  const classes = useStyles()
  const emptyState = [FINISH_ONBOARDING, HISTORY, SIGN]

  const renderTitle = (state) => {
    if (state.titleRedirect) {
      return (
        <Link to={state.titleRedirect}>
          <Typography variant="subtitle2" color="primary" noWrap>
            {state.title}
          </Typography>
        </Link>
      )
    }

    if (state.id === FINISH_ONBOARDING) {
      return <Typography variant="subtitle2">{state.title}</Typography>
    }

    return (
      <Typography
        variant="subtitle2"
        color={state.pending ? 'textSecondary' : 'primary'}
        noWrap
      >
        {state.title}
      </Typography>
    )
  }

  return (
    <Paper elevation={1} className={classes.containner}>
      <Paper elevation={2} className={classes.header}>
        <div
          className={clsx({
            [classes.pending]: step.pending,
            [classes[step.id]]: step.id,
          })}
        />
        <div className={classes.headerTitle}>
          {renderTitle(step)}

          {step.logo && (
            <Link
              className={step.pending ? classes.disableLink : ''}
              to="/workers/affiliations/new"
            >
              <div
                className={
                  step.pending
                    ? classes.logoContainerDisabled
                    : classes.logoContainer
                }
              >
                <AddCircleOutline className={classes.icon} />
              </div>
            </Link>
          )}
        </div>
      </Paper>
      <div className={classes.cards}>
        {step.workers?.map((worker, index) => (
          <OnboardingCards
            user={worker}
            key={`${worker.name}_${index.toString()}`}
          />
        ))}
        {emptyState.includes(step.id) && step.workers?.length === 0 ? (
          <OnboardingEmpty step={step} />
        ) : null}
      </div>
    </Paper>
  )
}

export default OnboardingCardsContainner
