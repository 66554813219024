import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'

import { updateBeneficiary } from 'services/affiliations/affiliations'

import addBeneficiariesLogo from 'assets/images/views/worker/add_two_persons.png'

import { getColumns, validationSchema } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
    alignItems: 'end',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    },
  },
  commentContainer: {
    display: 'grid',
    gridColumn: '1/-1',
  },
  textarea: {
    minHeight: 136,
  },
  documentsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(1, 0),
  },
  documentItem: {
    padding: theme.spacing(0, 1),
    border: 'none',
    minHeight: 'unset',
    alignItems: 'center',
    '& > button': {
      padding: theme.spacing(0.5, 1),
      height: 30,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    '& > .MuiMenu-paper': {
      width: 205,
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  loadedFileContainer: {
    width: 210,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.5, 1.5),
    borderRadius: 8,
  },
  loadedFile: {
    height: 32,
  },
}))

const BeneficiariesStep = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { values } = useFormikContext()
  const { worker } = values
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  const affiliationCache = queryClient.getQueryData(affiliationQueryKey)
  const beneficiaries = affiliationCache?.data?.beneficiaries || []
  const mutateOptions = {
    onSuccess: async () => {
      await queryClient.invalidateQueries(affiliationQueryKey)
    },
  }
  const canAddProgenitor =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'progenitor')
      ?.length < 2
  const canAddSpouse =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'spouse')
      ?.length < 1

  const onAddBeneficiary = ({ name, kinship, files = {} }) => {
    const data = new FormData()
    data.append('name', name)
    data.append('kinship', kinship)

    if (typeof files === 'object') {
      Object.entries(files).forEach(([key, value]) => {
        if (value instanceof File) {
          data.append(key.slice(0, -5), value)
        }
      })
    }

    return {
      data: {
        workerId: worker.id,
        beneficiaryData: data,
      },
      mutationFunction: updateBeneficiary,
      mutateOptions,
    }
  }

  const onUpdateBeneficiary = (oldData, newData) => {
    const dirtyValues = getDirtyValues(
      oldData,
      newData,
      validationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      const data = new FormData()
      data.append('beneficiary_id', oldData.id)

      data.append('name', dirtyValues.name || oldData.name)
      data.append('kinship', dirtyValues.kinship || oldData.kinship)

      if (dirtyValues.files && Object.keys(dirtyValues.files).length > 0) {
        Object.entries(dirtyValues.files).forEach(([key, value]) => {
          if (value instanceof File) {
            data.append(key.slice(0, -5), value)
          }
        })
      }

      return {
        data: {
          workerId: worker.id,
          beneficiaryData: data,
        },
        mutationFunction: updateBeneficiary,
        mutateOptions,
      }
    }

    return {}
  }

  const onDeleteBeneficiary = ({ id }) => {
    const data = new FormData()
    data.append('beneficiary_id', id)

    return {
      data: {
        workerId: worker.id,
        beneficiaryData: data,
      },
      mutationFunction: updateBeneficiary,
      mutateOptions,
    }
  }

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h6" color="primary">
        Beneficiarios
      </Typography>
      <Typography variant="body1">
        Los beneficiarios son personas que podrán disfrutar de los beneficios de
        seguridad social. Elige el parentezco u adjunta la documentación
        requerida.
      </Typography>
      <Table
        data={beneficiaries}
        columns={getColumns(classes, canAddProgenitor, canAddSpouse)}
        options={{
          alignActionsCell: 'center',
          pagination: false,
          search: false,
          sorting: false,
          customActionsWidth: '130px',
          minWidth: 1225,
          emptyState: {
            title: 'Aún no tienes beneficiarios asociados',
            hideDescription: true,
            logo: addBeneficiariesLogo,
            logoSize: 72,
          },
        }}
        editable={{
          validationSchema,
          onAddRow: onAddBeneficiary,
          onUpdateRow: onUpdateBeneficiary,
          onDeleteRow: onDeleteBeneficiary,
          enableAddRow: true,
          addRowActionProps: {
            tooltip: 'Agregar beneficiario',
          },
        }}
      />
    </div>
  )
}

export default BeneficiariesStep
