import { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ElectronicPayroll from '../ActionGroup/ElectronicPayroll'
import Novelties from '../ActionGroup/Novelties'
import Payments from '../ActionGroup/Payments/Payments'
import Payslips from '../ActionGroup/Payslips'
import Reports from '../ActionGroup/Reports'
import { PeriodContext } from '../Payroll'
import PayrollNovelties from './Novelties'
import PayrollPayments from './Payments'
import Totals from './Totals'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2.5),
    flexWrap: 'wrap',
  },
}))

const PayrollReviewActions = () => {
  const classes = useStyles()
  const {
    period: { workers_payment: workersPayment, company_cost: companyCost },
    options: { electronic_payroll: electronicPayroll },
    payPreviousPayroll,
  } = useContext(PeriodContext)

  const electronicPayrollUnAuthorized = electronicPayroll?.authorized === false

  return (
    <div className={classes.root}>
      <Totals workersPayment={workersPayment} companyCost={companyCost} />
      {payPreviousPayroll ? (
        <>
          <Payslips />
          <Reports />
          <Payments />
          <Novelties />
          <ElectronicPayroll
            electronicPayrollUnAuthorized={electronicPayrollUnAuthorized}
          />
        </>
      ) : (
        <>
          {workersPayment > 0 ? (
            <>
              <PayrollNovelties />
              <PayrollPayments />
            </>
          ) : null}
        </>
      )}
    </div>
  )
}

export default PayrollReviewActions
