import clsx from 'clsx'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import ConfirmEditActions from './ConfirmEditActions'

const useStyles = makeStyles((theme) => ({
  currentEditingRow: {
    borderBottom: `1px solid ${theme.palette.error.main} !important`,
  },
  actionsContainer: {
    whiteSpace: 'noWrap',
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
}))

const DeleteRow = ({
  rowIndex,
  colSpan,
  cancelEditRow,
  confirmEditRow,
  rowValues,
  customActionsWidth,
  alignActionsCell = 'left',
  ...props
}) => {
  const classes = useStyles()

  const onDeleteRow = () => confirmEditRow(rowValues)

  return (
    <TableRow {...props} className={classes.currentEditingRow}>
      <TableCell role="cell" colSpan={colSpan}>
        <Typography variant="h6">
          ¿Estás seguro de eliminar este registro?
        </Typography>
      </TableCell>
      <TableCell
        role="cell"
        colSpan={1}
        className={clsx(classes.actionsContainer, {
          [classes.alignCenter]: alignActionsCell === 'center',
          [classes.alignRight]: alignActionsCell === 'right',
        })}
        style={customActionsWidth ? { width: customActionsWidth } : undefined}
      >
        <ConfirmEditActions onCancel={cancelEditRow} onSave={onDeleteRow} />
      </TableCell>
    </TableRow>
  )
}

export default DeleteRow
