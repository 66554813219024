import SvgIcon from '@material-ui/core/SvgIcon'

const PendingDots = (props) => {
  return (
    <SvgIcon viewBox="0 0 28 28" {...props}>
      <path d="M14 0.666992C6.63999 0.666992 0.666656 6.64033 0.666656 14.0003C0.666656 21.3603 6.63999 27.3337 14 27.3337C21.36 27.3337 27.3333 21.3603 27.3333 14.0003C27.3333 6.64033 21.36 0.666992 14 0.666992ZM14 24.667C8.10666 24.667 3.33332 19.8937 3.33332 14.0003C3.33332 8.10699 8.10666 3.33366 14 3.33366C19.8933 3.33366 24.6667 8.10699 24.6667 14.0003C24.6667 19.8937 19.8933 24.667 14 24.667Z" />
      <path d="M7.33331 16C8.43788 16 9.33331 15.1046 9.33331 14C9.33331 12.8954 8.43788 12 7.33331 12C6.22874 12 5.33331 12.8954 5.33331 14C5.33331 15.1046 6.22874 16 7.33331 16Z" />
      <path d="M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z" />
      <path d="M20.6667 16C21.7712 16 22.6667 15.1046 22.6667 14C22.6667 12.8954 21.7712 12 20.6667 12C19.5621 12 18.6667 12.8954 18.6667 14C18.6667 15.1046 19.5621 16 20.6667 16Z" />
    </SvgIcon>
  )
}

export default PendingDots
