import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import { getGeneralContractCategory } from 'utils/worker'

export function getTabs(payroll) {
  // eslint-disable-next-line camelcase
  const { contract_category, connectivity_aid_option } = payroll

  const generalContractCategory = getGeneralContractCategory(contract_category)

  const isContractorApprenticeOrStudent = [
    'contractor',
    'apprentice',
    'student',
  ].includes(generalContractCategory)

  return [
    {
      key: 'salary_income',
      label: (
        <Box display="flex" alignItems="center">
          Constitutivos de salario
        </Box>
      ),
      hidden: isContractorApprenticeOrStudent,
    },
    {
      key: 'non_salary_income',
      label: isContractorApprenticeOrStudent
        ? 'Otros ingresos'
        : 'No constitutivos de salario',
    },
    {
      key: 'connectivity_aid',
      label: 'Aux. Conectividad',
      // eslint-disable-next-line camelcase
      hidden: !connectivity_aid_option,
    },
  ]
}

export const incomeDescription = {
  salary_income: (
    <>
      Agrega aquí todos los ingresos que sumarán para la base de seguridad
      social y prestaciones. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/https/ayuda.nominapp.com/help/como-puedo-crear-un-ingreso-constitutivo-de-salario-a-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  non_salary_income: (
    <>
      Agrega aquí todos los ingresos que <strong>NO</strong> sumarán para la
      base de seguridad social y prestaciones. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/como-ingreso-pagos-no-constitutivos-de-salario"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  connectivity_aid: (
    <>
      Agrega aquí el número de días que la persona laboró desde su domicilio en
      el periodo para que le sean reconocidos como Auxilio de conectividad. Esta
      es una medida de contingencia decretada a causa del COVID-19. Si tienes
      dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/como-incluir-el-auxilio-de-conectividad-digital-a-tu-empleado-en-nominapp"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
}
