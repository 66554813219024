import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  name: yup.string().trim().nullable().required(),
  email: yup.string().email().required(),
  current_password: yup
    .string()
    .trim()
    .required('Ingresa tu contraseña actual para realizar los cambios'),
  password: yup
    .string()
    .trim()
    .min(8, 'La contraseña es muy corta (8 caracteres mínimo)'),
  password_confirmation: yup.string().when('password', {
    is: (password) => password && password !== '',
    then: yup
      .string()
      .trim()
      .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
      .required(),
    otherwise: yup
      .string()
      .trim()
      .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
  }),
})

export default validationSchema
