import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import useContentStyles from './commonStyles'

export default function CategoryHeader({
  conceptTooltip,
  valueText = 'Valor',
  isWithholdingHeader = false,
}) {
  const classes = useContentStyles()

  return (
    <div
      className={
        isWithholdingHeader
          ? classes.withholdingItemContainer
          : classes.itemContainer
      }
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="subtitle1">Concepto</Typography>
        {conceptTooltip && (
          <TooltipInfoIcon title={conceptTooltip} interactive />
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="subtitle1">{valueText}</Typography>
      </Box>
    </div>
  )
}
