import { useMemo } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useFormikContext } from 'formik'

import {
  closedQuestionOptions,
  educationLevelOptions,
  genderOptions,
  getWorkerId,
  houseTypeOptions,
  kinshipOptions,
  maritalStatus,
  nationalityOptions,
  socialStratumOptions,
} from 'utils/worker'
import { accountTypesOptions } from 'utils/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { getApplication, applicationMutation } from 'services/benefits/credits'

import { formatFormData } from './helpers'

export const useCreditApplication = (options = {}) => {
  const workerId = getWorkerId()
  const { handleError } = useErrorHandler()

  const {
    data: { data: applicationData } = {},
    isLoading: isGettingApplicationData,
    ...restGetApplicationData
  } = useQuery('creditApplicationData', () => getApplication(workerId), {
    onError: (error) => {
      handleError(error)
    },
    ...options,
  })

  return {
    applicationData,
    isGettingApplicationData,
    ...restGetApplicationData,
  }
}

export const useApplicationMutation = (options = {}) => {
  const workerId = getWorkerId()
  const { handleError } = useErrorHandler()

  const {
    mutate: onMutateApplication,
    isLoading: isMutatingApplication,
    ...restApplicationMutationData
  } = useMutation(
    (newData) => applicationMutation(workerId, formatFormData(newData)),
    {
      onError: (error) => {
        handleError(error)
      },
      ...options,
    }
  )

  return {
    onMutateApplication,
    isMutatingApplication,
    ...restApplicationMutationData,
  }
}

export const useCreditFormFields = () => {
  const { values } = useFormikContext()

  const inputsPersonalData = useMemo(
    () => [
      {
        id: 'generalData',
        title: 'Datos generales',
        rows: [
          {
            id: 'generalData-row-1',
            fields: [
              {
                name: 'first_name',
                label: 'Primer nombre',
                placeholder: 'Primer nombre',
              },
              {
                name: 'second_name',
                label: 'Segundo nombre',
                placeholder: 'Segundo nombre',
              },
              {
                name: 'first_last_name',
                label: 'Primer apellido',
                placeholder: 'Primer apellido',
              },
              {
                name: 'second_last_name',
                label: 'Segundo apellido',
                placeholder: 'Segundo apellido',
              },
              {
                name: 'gender',
                label: 'Género',
                component: 'select',
                placeholder: 'Género',
                options: genderOptions,
                dataCy: 'gender-select',
              },
              {
                name: 'birthday',
                label: 'Fecha de nacimiento',
                placeholder: 'Fecha de nacimiento',
                component: 'datepicker',
                endIcon: true,
              },
              {
                name: 'birth_department',
                label: 'Departamento de nacimiento',
                placeholder: 'Departamento de nacimiento',
                departmentPath: ['birth_department'],
                cityKey: 'birth_place',
              },
              {
                name: 'birth_place',
                label: 'Ciudad de nacimiento',
                placeholder: 'Ciudad de nacimiento',
                departmentPath: ['birth_department'],
              },
              {
                name: 'nationality',
                label: 'Nacionalidad',
                component: 'select',
                placeholder: 'Nacionalidad',
                options: nationalityOptions,
                dataCy: 'nationality-select',
              },
              {
                name: 'marital_status',
                label: 'Estado civil',
                component: 'select',
                placeholder: 'Estado civil',
                options: maritalStatus,
                dataCy: 'marital-status-select',
              },
              {
                name: 'education_level',
                label: 'Nivel educativo',
                placeholder: 'Nivel educativo',
                component: 'select',
                options: educationLevelOptions,
                dataCy: 'education-level-select',
              },
            ],
          },
        ],
      },
      {
        id: 'identificationType',
        title: 'Tipo de identificación',
        rows: [
          {
            id: 'identificationType-row-1',
            fields: [
              {
                name: 'document_type',
                dataCy: 'document-type-select',
              },
              {
                name: 'id_number',
                dataCy: 'id-number',
              },
              {
                name: 'issue_department',
                label: 'Departamento de expedición',
                component: 'autocomplete',
                placeholder: 'Departamento de expedición',
                departmentPath: ['issue_department'],
                cityKey: 'issue_place',
              },
              {
                name: 'issue_place',
                label: 'Ciudad de expedición',
                placeholder: 'Ciudad de expedición',
                departmentPath: ['issue_department'],
              },
              {
                name: 'issue_date',
                label: 'Fecha de expedición',
                placeholder: 'Fecha de expedición',
                component: 'datepicker',
                endIcon: true,
              },
            ],
          },
        ],
      },
      ...(['married', 'free_union'].includes(values?.marital_status)
        ? [
            {
              id: 'spouseInformation',
              title: 'Información del cónyuge',
              rows: [
                {
                  id: 'spouseInformation-row-1',
                  fields: [
                    {
                      name: 'spouse_document_type',
                      dataCy: 'spouse-document-type',
                    },
                    {
                      name: 'spouse_id_number',
                      idNumberKey: 'spouse_id_number',
                      dataCy: 'spouse-id-number',
                    },
                    {
                      name: 'spouse_first_name',
                      label: 'Primer nombre cónyuge',
                      placeholder: 'Primer nombre cónyuge',
                    },
                    {
                      name: 'spouse_second_name',
                      label: 'Segundo nombre cónyuge',
                      placeholder: 'Segundo nombre cónyuge',
                    },
                    {
                      name: 'spouse_first_last_name',
                      label: 'Primer apellido cónyuge',
                      placeholder: 'Primer apellido cónyuge',
                    },
                    {
                      name: 'spouse_second_last_name',
                      label: 'Segundo apellido cónyuge',
                      placeholder: 'Segundo apellido cónyuge',
                    },
                  ],
                },
              ],
            },
          ]
        : []),
      {
        id: 'professionInformation',
        title: 'Información adicional',
        rows: [
          {
            id: 'professionInformation-row-1',
            component: 'full-row',
            fields: [
              {
                name: 'profession',
                label: 'Profesión',
                placeholder: 'Profesión',
              },
            ],
          },
        ],
      },
      {
        id: 'additionalInformation',
        gutterBottom: false,
        rows: [
          {
            id: 'additionalInformation-row-1',
            fields: [
              {
                name: 'email',
                label: 'Correo electrónico',
                placeholder: 'Correo electrónico',
              },
              {
                name: 'dependants',
                label: 'Personas a cargo',
                placeholder: 'Número de personas a cargo',
                component: 'number-format',
              },
            ],
          },
        ],
      },
    ],
    [values.marital_status]
  )

  const inputsResidenceData = useMemo(
    () => [
      {
        id: 'resideceData',
        title: 'Domicilio',
        rows: [
          {
            id: 'residenceData-row-1',
            fields: [
              {
                name: 'department',
                label: 'Departamento',
                component: 'autocomplete',
                placeholder: 'Departamento',
              },
              {
                name: 'city',
                label: 'Ciudad',
                component: 'autocomplete',
                placeholder: 'Ciudad',
              },
              {
                name: 'residence_address',
                label: 'Dirección de residencia',
                placeholder: 'Dirección de residencia',
              },
              {
                name: 'cell_phone',
                label: 'Teléfono celular',
                placeholder: 'Teléfono celular',
              },
              {
                name: 'social_stratum',
                label: 'Estrato',
                component: 'select',
                placeholder: 'Estrato',
                options: socialStratumOptions,
                dataCy: 'social-stratum-select',
              },
              {
                name: 'correspondence',
                label: 'Envío de correspondencia',
                component: 'select',
                placeholder: 'Envío de correspondencia',
                options: closedQuestionOptions,
                dataCy: 'correspondence-select',
              },
              {
                name: 'residence_phone',
                label: 'Teléfono de residencia',
                placeholder: 'Teléfono de residencia',
                optional: true,
              },
              {
                name: 'house_type',
                label: 'Tipo de vivienda',
                component: 'select',
                placeholder: 'Tipo de vivienda',
                options: houseTypeOptions,
                dataCy: 'house-type-select',
              },
            ],
          },
        ],
      },
    ],
    []
  )

  const inputsLaboralInformationData = useMemo(
    () => [
      {
        id: 'companyNameData',
        title: 'Empresa',
        rows: [
          {
            id: 'companyName-row-1',
            component: 'full-row',
            gutterBottom: true,
            fields: [
              {
                name: 'company_name',
                label: 'Nombre de empresa',
                placeholder: 'Nombre de empresa',
              },
            ],
          },
          {
            id: 'companyData-row-2',
            fields: [
              {
                name: 'company_id_number',
                label: 'NIT de la empresa',
                placeholder: 'NIT de la empresa',
              },
              {
                name: 'company_phone',
                label: 'Teléfono de empresa',
                placeholder: 'Teléfono de empresa',
              },
              {
                name: 'company_address',
                label: 'Dirección de empleo',
                placeholder: 'Dirección de empleo',
              },
              {
                name: 'work_department',
                label: 'Departamento dónde desempeña su trabajo',
                component: 'autocomplete',
                placeholder: 'Departamento dónde desempeña su trabajo',
                departmentPath: ['work_department'],
                cityKey: 'work_city',
              },
              {
                name: 'work_city',
                label: 'Ciudad dónde desempeña su trabajo',
                component: 'autocomplete',
                placeholder: 'Ciudad dónde desempeña su trabajo',
              },
              {
                name: 'contract_initial_day',
                label: 'Fecha de ingreso',
                component: 'datepicker',
                placeholder: 'Fecha de ingreso',
                endIcon: true,
              },
              {
                name: 'worked_months',
                label: 'Antigüedad en meses',
                placeholder: 'Antigüedad en meses',
                component: 'number-format',
              },
            ],
          },
        ],
      },
      {
        id: 'workActivity',
        title: 'Actividad laboral',
        rows: [
          ...(!values?.pension_date === null
            ? [
                {
                  id: 'workActivity-row-1',
                  status: 'pensionary',
                  gutterBottom: true,
                  fields: [
                    {
                      name: 'pension_date',
                      label: 'Pensionado desde',
                      component: 'datepicker',
                      placeholder: 'Pensionado desde',
                      endIcon: true,
                    },
                  ],
                },
              ]
            : []),
          {
            id: 'workActivity-row-2',
            component: 'full-row',
            fields: [
              {
                name: 'manage_government',
                title: '¿El solicitante maneja o administra recursos públicos?',
                type: 'radioGroup',
                component: 'custom-input-row',
              },
              {
                name: 'power_government',
                title:
                  '¿Por su cargo o actividad ejerce algún grado de poder público?',
                type: 'radioGroup',
                component: 'custom-input-row',
              },
              {
                name: 'public_recognition',
                title:
                  '¿Por su actividad u oficio, goza de reconocimiento público general?',
                type: 'radioGroup',
                component: 'custom-input-row',
                gutterBottom: false,
              },
            ],
          },
        ],
      },
    ],
    [values.pension_date]
  )

  const inputsFinancialInformationData = useMemo(
    () => [
      {
        id: 'financialInformation',
        title: 'Datos generales',
        gutterBottom: true,
        rows: [
          {
            id: 'financialInformation-row-1',
            fields: [
              {
                name: 'salary',
                label: 'Salario',
                placeholder: '0',
                component: 'currency',
                disabled: true,
              },
              {
                name: 'non_salary_income',
                label: 'Otros ingresos',
                placeholder: '0',
                component: 'currency',
                dataCy: 'non-salary-income',
                disabled: true,
              },
              {
                name: 'salary_income',
                label: 'Otros ingresos por rentas',
                placeholder: '0',
                component: 'currency',
              },
              {
                name: 'total_income',
                label: 'Total ingresos mes',
                placeholder: '0',
                component: 'currency',
              },
              {
                name: 'total_expenses',
                label: 'Egresos',
                placeholder: '0',
                component: 'currency',
                disabled: true,
              },
              {
                name: 'bank',
                label: 'Entidad bancaria desembolso',
                component: 'autocomplete',
                placeholder: 'Entidad bancaria desembolso',
                disabled: true,
              },
              {
                name: 'account_number',
                label: 'Cuenta bancaria desembolso',
                component: 'account-number',
                placeholder: 'Cuenta bancaria desembolso',
                disabled: true,
              },
              {
                name: 'account_type',
                label: 'Tipo de cuenta desembolso',
                component: 'select',
                placeholder: 'Tipo de cuenta desembolso',
                options: accountTypesOptions,
                dataCy: 'account-type-select',
                disabled: true,
              },
              {
                name: 'account_holder_name',
                label: 'Titular de desembolso',
                placeholder: 'Titular de desembolso',
                disabled: true,
              },
            ],
          },
        ],
      },
      {
        id: 'foreignCurrency',
        title: 'Avista Colombia',
        rows: [
          {
            id: 'foreignCurrency-row-1',
            component: 'full-row',
            fields: [
              {
                name: 'lender_worker',
                title:
                  '¿Es el solicitante directivo, representante legal o integrante de la junta directiva de AVISTA COLOMBIA S.A.S?',
                component: 'custom-input-row',
                type: 'radioGroup',
              },
              {
                name: 'lender_family',
                title:
                  '¿El solicitante hace parte del grupo familiar de directivos o representantes legales de AVISTA COLOMBIA S.A.S?',
                component: 'avista-lender-relative',
                type: 'radioGroup',
              },
              {
                name: 'avista_percentage.percentage',
                title:
                  'Si tiene vinculo de propiedad directa o indirecta igual o superior al 5% en participación de AVISTA S.A.S. indique cual es el porcentaje de participación',
                component: 'custom-input-row',
                type: 'textfield',
                textfieldProps: {
                  label: 'Porcentaje',
                },
              },
              {
                name: 'company_participation_percentages_attributes',
                title: (
                  <>
                    {`Si tiene vinculo de propiedad directa o indirecta o superior
                    al 10% en participación de `}
                    <b>empresas diferentes a AVISTA S.A.S.</b>
                    {` o posee 5% o más
                    del capital social de alguna de ellas, indique cual(es) y el
                    porcentaje de participación`}
                  </>
                ),
                component: 'participation-percentages',
                gutterBottom: false,
                type: 'button',
                buttonProps: {
                  buttonText: 'Agregar empresa',
                  variant: 'outlined',
                },
              },
            ],
          },
        ],
      },
    ],
    []
  )

  const inputsAvistaLenderRelativeData = useMemo(
    () => [
      {
        name: 'family_member_name',
        label: 'Nombre de familiar',
        placeholder: 'Nombre de familiar',
      },
      {
        name: 'kinship',
        label: 'Parentesco',
        placeholder: 'Parentesco',
        component: 'select',
        options: kinshipOptions,
        dataCy: 'kinship-select',
      },
    ],
    []
  )

  const inputsWorkerReferenceData = useMemo(
    () => [
      {
        id: 'reference1',
        title: 'Referencia 1',
        dataCy: 'reference-1',
        rows: [
          {
            id: 'reference1-row-1',
            gutterBottom: true,
            fields: [
              {
                name: 'personal_references_attributes[0].first_name',
                label: 'Primer nombre',
                placeholder: 'Primer nombre',
              },
              {
                name: 'personal_references_attributes[0].second_name',
                label: 'Segundo nombre',
                placeholder: 'Segundo nombre',
              },
              {
                name: 'personal_references_attributes[0].first_last_name',
                label: 'Primer apellido',
                placeholder: 'Primer apellido',
              },
              {
                name: 'personal_references_attributes[0].second_last_name',
                label: 'Segundo apellido',
                placeholder: 'Segundo apellido',
              },
              {
                name: 'personal_references_attributes[0].kinship',
                label: 'Parentesco',
                placeholder: 'Parentesco',
                component: 'select',
                options: kinshipOptions,
                dataCy: 'reference-one-kinship-select',
              },
              {
                name: 'personal_references_attributes[0].phone',
                label: 'Teléfono / Celular',
                placeholder: 'Teléfono / Celular',
              },
              {
                name: 'personal_references_attributes[0].department',
                label: 'Departamento',
                component: 'autocomplete',
                placeholder: 'Departamento',
                departmentPath: [
                  'personal_references_attributes',
                  '0',
                  'department',
                ],
                cityKey: 'personal_references_attributes[0].city',
              },
              {
                name: 'personal_references_attributes[0].city',
                label: 'Ciudad',
                component: 'autocomplete',
                placeholder: 'Ciudad',
                departmentPath: [
                  'personal_references_attributes',
                  '0',
                  'department',
                ],
              },
            ],
          },
          {
            id: 'reference1-row-2',
            component: 'full-row',
            fields: [
              {
                name: 'personal_references_attributes[0].address',
                label: 'Dirección',
                placeholder: 'Dirección',
              },
            ],
          },
        ],
      },
      {
        id: 'reference2',
        title: 'Referencia 2',
        dataCy: 'reference-2',
        rows: [
          {
            id: 'reference2-row-1',
            gutterBottom: true,
            fields: [
              {
                name: 'personal_references_attributes[1].first_name',
                label: 'Primer nombre',
                placeholder: 'Primer nombre',
              },
              {
                name: 'personal_references_attributes[1].second_name',
                label: 'Segundo nombre',
                placeholder: 'Segundo nombre',
              },
              {
                name: 'personal_references_attributes[1].first_last_name',
                label: 'Primer apellido',
                placeholder: 'Primer apellido',
              },
              {
                name: 'personal_references_attributes[1].second_last_name',
                label: 'Segundo apellido',
                placeholder: 'Segundo apellido',
              },
              {
                name: 'personal_references_attributes[1].kinship',
                label: 'Parentesco',
                placeholder: 'Parentesco',
                component: 'select',
                options: kinshipOptions,
                dataCy: 'reference-two-kinship-select',
              },
              {
                name: 'personal_references_attributes[1].phone',
                label: 'Teléfono / Celular',
                placeholder: 'Teléfono / Celular',
              },
              {
                name: 'personal_references_attributes[1].department',
                label: 'Departamento',
                component: 'autocomplete',
                placeholder: 'Departamento',
                departmentPath: [
                  'personal_references_attributes',
                  '1',
                  'department',
                ],
                cityKey: 'personal_references_attributes[1].city',
              },
              {
                name: 'personal_references_attributes[1].city',
                label: 'Ciudad',
                component: 'autocomplete',
                placeholder: 'Ciudad',
                departmentPath: [
                  'personal_references_attributes',
                  '1',
                  'department',
                ],
              },
            ],
          },
          {
            id: 'reference2-row-2',
            component: 'full-row',
            fields: [
              {
                name: 'personal_references_attributes[1].address',
                label: 'Dirección',
                placeholder: 'Dirección',
              },
            ],
          },
        ],
      },
    ],
    []
  )

  return {
    inputsPersonalData,
    inputsResidenceData,
    inputsLaboralInformationData,
    inputsFinancialInformationData,
    inputsAvistaLenderRelativeData,
    inputsWorkerReferenceData,
  }
}
