import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import { Form, Formik } from 'formik'

import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import { formatValues, getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useContractsService from 'utils/hooks/worker/contracts'
import { filterContractFields } from 'utils/worker'

import BaseSalaryField from '../../Wage/Fields/BaseSalary'
import WageCategoryField from '../../Wage/Fields/Category'
import TransportSubsidyField from '../../Wage/Fields/TransportSubsidy'
import CategoryField from '../Fields/Category'
import EndDayField from '../Fields/EndDay'
import HealthProviderField from '../Fields/HealthProvider'
import InitialDayField from '../Fields/InitialDay'
import PensionProviderField from '../Fields/PensionProvider'
import RiskTypeField from '../Fields/RiskType'
import SeveranceProviderField from '../Fields/SeveranceProvider'
import TermField from '../Fields/Term'
import getValidationSchema from './validationSchema'

const useStyles = makeStyles({
  paper: {
    width: 650,
  },
})

const ContractModal = ({ state, handleClose, submitCallback }) => {
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const { open, contract, workerId } = state
  const { contractsMutation } = useContractsService({
    queryOptions: { enabled: false },
  })
  const { handleError } = useErrorHandler()
  const modalClasses = useStyles()
  const confirm = useConfirm()

  const isEditing = contract.id

  const validationSchema = getValidationSchema(worker?.document_type)

  const submitNewContract = async (dirtyValues, form) => {
    contractsMutation.mutate(
      {
        mutationMethod: 'POST',
        workerId,
        contract: dirtyValues,
      },
      {
        onSuccess: () => {
          if (submitCallback) submitCallback()
          refreshWorker()
          handleClose()
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ object: 'worker_payment' }],
          })
        },
      }
    )
  }

  const handleSubmit = async (values, form) => {
    const dirtyValues = isEditing
      ? getDirtyValues(contract, values, validationSchema.fields)
      : formatValues(values, validationSchema.fields)

    const filteredDirtyValues = filterContractFields(
      dirtyValues,
      values.category,
      values.wage_category
    )

    if (!isObjectEmpty(filteredDirtyValues)) {
      if (isEditing) {
        filteredDirtyValues.id = values.id

        contractsMutation.mutate(
          {
            mutationMethod: 'PATCH',
            contract: filteredDirtyValues,
          },
          {
            onSuccess: () => {
              if (submitCallback) submitCallback()
              refreshWorker()
              handleClose()
            },
            onError: (error) => {
              handleError(error, form, {
                // error returned when a user update the end_date of a contract but it can't
                // be done because the deductions exceed the value to be paid to the worker
                errorsToNotificate: [{ object: 'worker_payment' }],
              })
            },
          }
        )
      } else {
        const { prevEndDay } = contract
        const initDay = parseISO(values.initial_day)

        const diff = differenceInCalendarDays(initDay, prevEndDay)

        if (diff > 0) {
          confirm({
            type: 'info',
            description:
              'Están quedando días sin contrato para este trabajador. En esos días se contará al trabajador como inactivo.',
            okText: 'Volver a editar',
            cancelText: 'Guardar y continuar',
            onOk: () => form.setSubmitting(false),
            onCancel: () => {
              submitNewContract(filteredDirtyValues, form)
            },
          })
        } else {
          submitNewContract(filteredDirtyValues, form)
        }
      }
    }
  }

  return (
    <Formik
      initialValues={contract}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { values, handleSubmit: onSubmit } = formProps

        return (
          <Modal
            header={isEditing ? 'Editar contrato' : 'Crear contrato'}
            open={open}
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={contractsMutation.isLoading}
            dialogProps={{
              maxWidth: false,
              scroll: 'body',
              classes: modalClasses,
            }}
          >
            <Form>
              <CategoryField />
              <TermField contractCategory={values.category} />
              <InitialDayField contract={contract} />
              <EndDayField />
              <WageCategoryField
                name="wage_category"
                contractCategory={values.category}
              />
              <BaseSalaryField
                contractCategory={values.category}
                isEditing={isEditing}
              />
              <TransportSubsidyField contractCategory={values.category} />
              <RiskTypeField
                contractCategory={values.category}
                isEditContract={isEditing}
              />
              <HealthProviderField contractCategory={values.category} />
              <PensionProviderField
                documentType={worker?.document_type}
                contractCategory={values.category}
              />
              <SeveranceProviderField
                wageCategory={values.wage_category}
                contractCategory={values.category}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default ContractModal

export const validateCreateContract = ({
  currentContract,
  currentPeriod,
  handleRedirectToTermination,
  handleShowModal,
  confirm,
}) => {
  if (currentContract) {
    const {
      category,
      contract_category: contractCategory,
      terminated,
      end_day: endDay,
    } = currentContract

    const currentContractCategory =
      category?.value || contractCategory?.value || category

    const isEmployeeOrPensioner = ['employee', 'pensioner'].includes(
      currentContractCategory
    )

    if (isEmployeeOrPensioner && !terminated) {
      confirm({
        title: 'Atención',
        description: (
          <>
            Esta persona tiene un contrato todavía activo. Si quieres crearle un
            contrato nuevo, primero liquida el actual. <br />
            Si quieres renovar este contrato por el mismo tipo de contrato sin
            liquidarlo, solo debes extender su fecha de terminación. Haz click{' '}
            <Link
              href="https://centro-de-ayuda-nominapp.groovehq.com/help/que-hacer-para-renovar-contrato-a-un-empleado"
              target="_blank"
            >
              aquí
            </Link>{' '}
            para saber más.
          </>
        ),
        type: 'warning',
        okText: 'Liquidar contrato actual',
        onOk: handleRedirectToTermination,
      })
    } else if (
      !isEmployeeOrPensioner &&
      (!endDay || isAfter(endDay, currentPeriod.end_day))
    ) {
      confirm({
        title: 'Atención',
        description:
          'Revisa que la fecha de terminación del contrato actual sea menor o igual a la fecha final del periodo actual.',
        type: 'warning',
        hideCancelButton: true,
      })
    } else {
      handleShowModal()
    }
  } else {
    handleShowModal()
  }
}
