import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import useWorker from 'components/Worker/useWorker'

import DownloadFile from '../DownloadFile'
import SectionContainer from '../SectionContainer'

const AttachedDocumentsContainer = () => {
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  const affiliationCache = queryClient.getQueryData(affiliationQueryKey)
  const identificationDocument =
    affiliationCache?.data?.identification_document_file

  const sectionData = useMemo(() => {
    const data = [
      {
        id: 'documents',
        title: 'Documentos adjuntos',
        buttonCyLabel: 'download_documents_button',
        containerCyLabel: 'id_number',
        hiddenEdit: true,
        itemsList: [
          {
            label: 'Cédula del empleado',
            value: <DownloadFile file={identificationDocument} />,
          },
        ],
      },
    ]
    return data
  }, [identificationDocument])

  return (
    <>
      {sectionData.map((item) => (
        <SectionContainer item={item} key={item.id} />
      ))}
    </>
  )
}

export default AttachedDocumentsContainer
