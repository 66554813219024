import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    borderRadius: 10,
    boxShadow: 'inherit',
    '& > button, a + button, span > button, a': {
      marginLeft: '0 !important',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0),
    },
    '& > h5': {
      marginBottom: theme.spacing(),
    },
    backgroundColor: theme.palette.common.white,
    width: '304px',
    height: '204px',
  },
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4.25, 2),
  },
  primaryCard: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

const CardReview = ({ children, alignCenter, onboardingStep = {} }) => {
  const classes = useStyles()

  const { electronicPayrollOnboarding, handleClick } = onboardingStep

  return (
    <Paper
      onClick={electronicPayrollOnboarding ? handleClick : null}
      className={clsx(classes.root, {
        [classes.primaryCard]: electronicPayrollOnboarding,
        [classes.alignCenter]: alignCenter,
      })}
    >
      {children}
    </Paper>
  )
}

export default CardReview
