import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import { accountingCategoryNames } from 'utils/company'
import useNotifications from 'utils/hooks/useNotifications'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'

import messages from 'messages/notification'

import CategoryAccordion from '../CategoryAccordion'

let snackbarId // to store last snackbar showed

const SettingsAccountingAccounts = ({ configuration }) => {
  const [expanded, setExpanded] = useState(false) // current expanded category expansion panel
  const [integratedCounterpartCode, setintegratedCounterpartCode] = useState(
    configuration?.integrated_counterpart_code
  )
  const queryClient = useQueryClient()
  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })
  const { showSuccessMessage } = useNotifications()
  const { closeSnackbar } = useSnackbar()

  const handleChange = (category) => (_, isExpanded) => {
    setExpanded(isExpanded ? category : false)
  }

  const handleChangeIntegratedCounterpartCode = ({
    target: { name, value },
  }) => {
    const booleanValue = value === 'true'

    setintegratedCounterpartCode(booleanValue)

    companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: { [name]: booleanValue },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('companyConfiguration')
          if (snackbarId) closeSnackbar(snackbarId)
          snackbarId = showSuccessMessage(messages.CHANGES_DONE_SUCCESS)
        },
      }
    )
  }

  const integratedCounterpartCodeOptions = [
    { value: true, label: 'Consolidados con la contrapartida de salario.' },
    { value: false, label: 'Separados, cada cuenta con su contrapartida.' },
  ]

  return (
    <div data-cy="accounting-codes-section">
      <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
        Cuentas Contables
      </Typography>
      <Typography>
        Configura tus conceptos de nómina, los cuales se usarán para generar el
        archivo para tu software contable y al momento de liquidar tu nómina.
      </Typography>
      <Alert severity="info">
        Recuerda que el prefijo de cada cuenta será el código cuenta contable de
        tus Centros de Costos PUC.
      </Alert>
      <Alert severity="info" icon={false}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
            ¿Cómo deseas configurar las contrapartidas de subsidio de
            transporte, otros ingresos, horas extras y recargos?
          </FormLabel>
          <RadioGroup
            name="integrated_counterpart_code"
            value={integratedCounterpartCode}
            onChange={handleChangeIntegratedCounterpartCode}
          >
            {integratedCounterpartCodeOptions.map((opt) => (
              <FormControlLabel
                key={opt.value}
                label={opt.label}
                value={opt.value}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Alert>
      <Paper>
        {Object.keys(accountingCategoryNames).map((category) => {
          return (
            <CategoryAccordion
              key={category}
              category={category}
              expanded={expanded}
              handleChange={handleChange}
              integratedCounterpartCode={integratedCounterpartCode}
            />
          )
        })}
      </Paper>
    </div>
  )
}

export default SettingsAccountingAccounts
