import Link from '@material-ui/core/Link'

export const questions = [
  {
    title: '¿Cómo funciona la prueba de 7 días?',
    description:
      'Durante 7 días podrás utilizar Nominapp con todas sus funciones completamente gratis, crear una cuenta solo toma minutos y transcurridos los 7 días puedes decidir si deseas continuar o no pagando.',
  },
  {
    title: '¿Debo firmar alguna cláusula de permanencia mínima?',
    description:
      '¡No! Con Nominapp puedes cancelar tu suscripción en el momento que desees.',
  },
  {
    title: '¿Requiero de alguna capacitación para comenzar?',
    description:
      'Hemos hecho de Nominapp algo tan simple, que no requieres capacitación. Desde que te registras, nuestro equipo se comunicara contigo para ayudarte en todo el proceso y en caso de cualquier inquietud o capacitación siempre podrás contar con nosotros sin ningún costo.',
  },
  {
    title: '¿Hay algún costo extra u oculto?',
    description:
      '¡No! En Nominapp no pagas por absolutamente nada más. Todas las funciones, el soporte y las actualizaciones están incluidas.',
  },
  {
    title: '¿Qué pasa si mi número de empleados cambia?',
    description:
      'El pago de tu suscripción a Nominapp dependerá del número de personas que hayas liquidado en tu nómina en el período anterior (en el mes anterior o en las 2 quincenas anteriores). En este número se tendrán en cuenta todos los empleados o contratistas activos y aquellos a quienes les hayas realizado una liquidación laboral.',
  },
  {
    title: '¿Cómo realiza Nominapp el cobro de mi suscripción?',
    description: (
      <>
        El pago de tu suscripción a Nominapp dependerá del número de personas
        que hayas liquidado en tu nómina en el mes anterior (o en las 2
        quincenas anteriores). En estas personas se tendrán en cuenta todos los
        empleados o contratistas activos y aquellos a quienes les hayas
        realizado una liquidación laboral. No te preocupes por las personas que
        se encuentren inactivas, que sobre ellas nunca se realizará ningún
        cobro. Conoce más al respecto en{' '}
        <Link
          href="https://ayuda.nominapp.com/help/cuanto-debo-pagar-por-mi-suscripcion-a-nominapp"
          target="_blank"
        >
          este artículo en nuestro centro de ayuda
        </Link>
        .
      </>
    ),
  },
]

export default questions
