import { Box, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as ElectricAleluya } from './assets/electric_aleluya.svg'
import { ReactComponent as AleluyaFirm } from './assets/firm_aleluya.svg'
import { ReactComponent as AleluyaLogo } from './assets/logo_aleluya.svg'
import { useCountdown } from './helpers'

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#70C674',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 304,
    minWidth: 304,
    width: '100%',
    height: 448,
    overflow: 'hidden',
    padding: theme.spacing(0, 1.5),
    position: 'relative',
  },
  topBar: {
    position: 'absolute',
    height: theme.spacing(2),
    backgroundColor: '#F3EDDC',
    overflow: 'hidden',
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    marginTop: theme.spacing(7.5),
    color: '#000000',
    letterSpacing: '0.1px',
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    color: '#000000',
    marginTop: theme.spacing(2),
  },
  timerTitle: {
    color: '#000000',
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  timer: {
    fontWeight: 600,
    color: '#000000',
    textAlign: 'center',
  },
  logo: {
    marginTop: theme.spacing(4.5),
  },
  bottomBar: {
    position: 'absolute',
    height: theme.spacing(2),
    backgroundColor: '#C0D4F7',
    bottom: 0,
    left: 0,
    right: 0,
  },
  dateContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content 1fr min-content 1fr min-content 1fr',
    gridTemplateRows: 'repeat(2, max-content)',
    marginTop: theme.spacing(2),
  },
}))

const Countdown = () => {
  const classes = useStyles()
  const limitDate = useCountdown('Feb 7, 2023 00:01:00')

  return (
    <Card className={classes.main}>
      <div className={classes.topBar}>
        <AleluyaFirm width="100%" />
      </div>
      <Typography variant="h5" className={classes.title}>
        Que nervios!!!!
      </Typography>
      <Box className={classes.dateContainer}>
        <Box
          style={{
            gridColumn: '1 / 2',
            gridRow: 1,
          }}
        >
          <Typography variant="body2" className={classes.timerTitle}>
            Días
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '3 / 4',
            gridRow: 1,
          }}
        >
          <Typography variant="body2" className={classes.timerTitle}>
            Horas
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '5 / 6',
            gridRow: 1,
          }}
        >
          <Typography variant="body2" className={classes.timerTitle}>
            Minutos
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '7 / 8',
            gridRow: 1,
          }}
        >
          <Typography variant="body2" className={classes.timerTitle}>
            Segundos
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '1 / 2',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {`${limitDate[0] < 10 ? '0' : ''}${limitDate[0]}`}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '2 / 3',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {limitDate[1]}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '3 / 4',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {`${limitDate[2] < 10 ? '0' : ''}${limitDate[2]}`}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '4 / 5',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {limitDate[3]}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '5 / 6',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {`${limitDate[4] < 10 ? '0' : ''}${limitDate[4]}`}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '6 / 7',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {limitDate[5]}
          </Typography>
        </Box>
        <Box
          style={{
            gridColumn: '7 / 8',
            gridRow: 2,
          }}
        >
          <Typography variant="h3" className={classes.timer}>
            {`${limitDate[6] < 10 ? '0' : ''}${limitDate[6]}`}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" className={classes.text}>
        ¿Cambiar para revolucionar la forma en la que liquidas nómina y
        gestionas personas? Sí y mil veces sí. Nosotros ya estamos listos, ¿y
        tú?
      </Typography>
      <AleluyaLogo className={classes.logo} />
      <div className={classes.bottomBar}>
        <ElectricAleluya />
      </div>
    </Card>
  )
}

export default Countdown
