import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import { useUser } from 'components/App/UserContext/useUser'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { getDirtyValues } from 'utils/form'
import { getCurrentDistributor } from 'utils/company'
import { isDataCached } from 'utils/reactQuery'
import useNotifications from 'utils/hooks/useNotifications'
import useDistributorService from 'utils/hooks/organizer/distributorService'
import useCompanyService from 'utils/hooks/organizer/company'

import notification from 'messages/notification'

import {
  getAllCompanies,
  updateCompany,
} from 'services/organizer/companyService'

import { getColumnsData, useGetTableActions } from './helpers'

const OrganizerCompanyIndex = () => {
  const confirm = useConfirm()
  const { changeCompany } = useUser()
  const queryClient = useQueryClient()
  const distributorsCache =
    queryClient.getQueryData('organizerDistributors') || []

  const { distributorsQuery } = useDistributorService({
    serviceParams: { queryKey: 'organizerDistributors' },
    queryOptions: {
      enabled: !isDataCached(distributorsCache),
    },
  })
  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const { showSuccessMessage, showInfoMessage } = useNotifications()

  const refetchCompanies = useCallback(() => {
    queryClient.invalidateQueries(['getAllOrganizerCompanies'], {
      exact: false,
    })
    showSuccessMessage(notification.COMPANY_UPDATE_SUCCESS)
  }, [queryClient, showSuccessMessage])

  const fetchCompanies = (pageIndex, search) => {
    const page = pageIndex + 1
    const query = { page, search }

    return {
      queryKey: ['getAllOrganizerCompanies', pageIndex, search],
      queryFunction: () => getAllCompanies(query),
    }
  }

  const mutationOptions = {
    onSuccess: refetchCompanies,
  }

  const handleUpdateCompany = (oldCompany, newCompany) => {
    const dirtyValues = getDirtyValues(oldCompany, newCompany)

    return {
      data: { id: newCompany.id, ...dirtyValues },
      mutationFunction: updateCompany,
    }
  }

  const deleteCompany = (id) => {
    companyMutation.mutate(
      {
        mutationMethod: 'DELETE',
        companyId: id,
      },
      {
        onSuccess: ({ message }) => {
          queryClient.invalidateQueries(['getAllOrganizerCompanies'], {
            exact: false,
          })
          showInfoMessage(message)
        },
      }
    )
  }

  const onChangeCompany = (company) => {
    const distributor = getCurrentDistributor(
      distributorsQuery?.data,
      company.distributor_id
    )
    changeCompany({
      ...company,
      distributor,
      freemiumStatus: company.freemium_status,
      premiumTrialEndDate: company.premium_trial_end_date,
    })
  }

  const columnsData = distributorsQuery?.data
    ? getColumnsData(distributorsQuery?.data, onChangeCompany)
    : []
  const actions = useGetTableActions(onChangeCompany, deleteCompany, confirm)

  return (
    <Page header="Empresas" isLoading={distributorsQuery?.isLoading}>
      <Table
        columns={columnsData}
        data={fetchCompanies}
        actions={actions}
        editable={{
          onUpdateRow: handleUpdateCompany,
          hideDelete: true,
          mutationOptions,
        }}
        options={{
          customActionsWidth: 180,
          dataCyPrefix: 'companies-table',
        }}
      />
    </Page>
  )
}

export default OrganizerCompanyIndex
