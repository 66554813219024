import { Route, Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { hasSelectedCompany } from 'utils/company'
import auth from 'utils/auth'

import * as routes from 'config/routes'

/**
 * View relacted to a company so a company needs to be selected to this route to be accessed
 */
export default ({ component: Component, render, ...rest }) => {
  const location = useLocation()
  const isWorker = auth.isWorker()

  const queryParams = queryString.parse(location.search)

  const isFromAuth =
    queryParams.password_recovered &&
    location.pathname === routes.PERIOD_PAY_PAYROLL()

  return (
    <Route
      {...rest}
      render={(props) => {
        if ((hasSelectedCompany() || isFromAuth) && !isWorker) {
          return render ? render(props) : <Component {...props} />
        }
        return (
          <Redirect
            to={
              isWorker ? routes.WORKER_PROFILE_SHOW() : routes.COMPANY_INDEX()
            }
          />
        )
      }}
    />
  )
}
