import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DataTable from './DataTable'
import { tableTitles, formatComment } from './helpers'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(6),
    '&:nth-last-of-type(1)': {
      marginBottom: theme.spacing(1),
    },
  },
  tableTitle: {
    marginBottom: theme.spacing(1),
  },
}))

const PeriodDetailsBodySection = ({ details, activeDetail }) => {
  const classes = useStyles()
  const currentTabData = details[activeDetail] || {}

  return Object.keys(currentTabData).map((tableName) => {
    const { data: tableData, comments } = currentTabData[tableName]

    return tableData.length > 0 ? (
      <div key={tableName} className={classes.tableContainer}>
        <Typography variant="h5" className={classes.tableTitle}>
          {tableTitles[tableName]}
        </Typography>
        <DataTable name={tableName} data={tableData} type="old" />
        {comments.map((comment, index) => (
          <Typography key={index} variant="body2">
            {formatComment(comment)}
          </Typography>
        ))}
      </div>
    ) : null
  })
}

export default PeriodDetailsBodySection
