export function getStatusFreemium() {
  return localStorage.getItem('freemium_status')
}

export function getDateEndFreeTrial() {
  return JSON.parse(
    JSON.stringify(localStorage.getItem('premium_trial_end_date'))
  )
}

export function setStatusFreemium(freemiumStatus) {
  localStorage.setItem('freemium_status', freemiumStatus)
}

export function setDateEndFreeTrial(dateEnd) {
  localStorage.setItem('premium_trial_end_date', dateEnd)
}

export function isFreemiumCompany(status, paymentStatus) {
  return (
    ['basic', 'premium_due', 'premium_canceled', 'premium_expired'].includes(
      status
    ) && paymentStatus !== 'pending'
  )
}

export function isPremiumCompany(status) {
  return [
    'active',
    'active_premium',
    'exonerated_payment',
    'premium_trial',
    'free_trial',
  ].includes(status)
}
