import { Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  logo: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const BenefitsCard = ({ logo, title, description }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <div className={classes.logo}>{logo}</div>
      <div className={classes.textContainer}>
        <Typography variant="subtitle2" color="textPrimary">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </div>
    </Paper>
  )
}

export default BenefitsCard
