import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Accordion from '@material-ui/core/Accordion'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  radio: {
    backgroundColor: 'transparent',
    marginRight: theme.spacing(1.5),
    width: 20,
    height: 20,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiRadio-colorPrimary.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  accordion: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 17,
    overflow: 'hidden',
    minHeight: 90,
    '&:last-child': {
      borderBottomLeftRadius: 17,
      borderBottomRightRadius: 17,
      borderTopRightRadius: 17,
      borderTopLeftRadius: 17,
    },
    '&:first-child': {
      borderBottomLeftRadius: 17,
      borderBottomRightRadius: 17,
      borderTopRightRadius: 17,
      borderTopLeftRadius: 17,
    },
    '&.MuiAccordion-root + .MuiAccordion-root': {
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
  },
  accordionSummary: {
    padding: theme.spacing(2, 3),
    minHeight: 90,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > .MuiAccordionSummary-content.Mui-expanded': {
      margin: theme.spacing(1.5, 0),
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 90,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > img': {
      marginRight: theme.spacing(2),
    },
    '& > img:last-child': {
      marginRight: 0,
    },
  },
  accordionDetails: {
    padding: theme.spacing(0, 4, 3.5, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2.5, 3, 2.5),
    },
  },
}))

const MethodAccordion = ({
  id,
  logos = [],
  children,
  title,
  expanded,
  onChange,
}) => {
  const classes = useStyles()
  const hideLogos = useMediaQuery((theme) => theme.breakpoints.down(768))
  const isExpanded = expanded === id

  return (
    <Accordion
      expanded={isExpanded}
      className={classes.accordion}
      onChange={() => onChange(id)}
    >
      <AccordionSummary className={classes.accordionSummary}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Radio
              disableRipple
              disableFocusRipple
              disableTouchRipple
              className={classes.radio}
              checked={isExpanded}
            />
            <Typography variant="body1">{title}</Typography>
          </div>
          {!hideLogos ? (
            <div className={classes.cardsContainer}>
              {logos.map((logo) => (
                <img
                  key={logo.id}
                  alt={`Logo ${logo.id}`}
                  src={logo.icon}
                  width={logo.width}
                  height={logo.height}
                />
              ))}
            </div>
          ) : null}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default MethodAccordion
