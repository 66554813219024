import PropTypes from 'prop-types'
import Cleave from 'cleave.js/react'
import { parseNumberToString } from 'utils/number'

const NumberFormatField = (props) => {
  const { inputRef, value, options, ...other } = props

  return (
    <Cleave
      htmlRef={(ref) => inputRef(ref)}
      onFocus={(e) => (e.target.placeholder = '')}
      onBlur={(e) => (e.target.placeholder = '0')}
      style={{ textAlign: 'right' }}
      options={{
        numeral: true,
        delimiter: '.',
        numeralDecimalMark: ',',
        numeralPositiveOnly: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2,
        numericOnly: true,
        ...options,
      }}
      value={parseNumberToString(value)}
      {...other}
    />
  )
}

NumberFormatField.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export default NumberFormatField
