import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import Table from 'components/UI/Table/Table'
import useRecurrentConcepts from 'components/Worker/Show/useRecurrentConcepts'
import useWorker from 'components/Worker/useWorker'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import { updateRecurrentConcept } from 'services/worker/recurrentConceptService'

import NoItems from '../NoItems'
import { getIncomesColumns, validationSchema } from './helpers'

const IncomesTable = ({ noItemsOnClick }) => {
  const { worker } = useWorker({ useCache: true })
  const { isWorker } = useUser()
  const {
    recurrentConcepts,
    isLoading,
    isError,
    refreshRecurrentConcepts,
  } = useRecurrentConcepts()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const incomesConceptsQuery = queryClient.getQueryData([
    'salary_income',
    worker.id,
  ])
  const nonIncomesConceptsQuery = queryClient.getQueryData([
    'non_salary_income',
    worker.id,
  ])

  const optionsIncomesConcepts = {
    salary_income: incomesConceptsQuery?.data || {},
    non_salary_income: nonIncomesConceptsQuery?.data || {},
  }

  const mutationOptions = {
    onSuccess: () => {
      refreshRecurrentConcepts()
    },
  }

  const applySalaryIncome =
    worker.contract_category === 'employee' ||
    worker.contract_category === 'pensioner'

  const incomesColumns = useMemo(
    () => getIncomesColumns(optionsIncomesConcepts, applySalaryIncome),
    [optionsIncomesConcepts, applySalaryIncome]
  )

  const updateItem = (oldData, newData) => {
    const dirtyValues = getDirtyValues(
      oldData,
      newData,
      validationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: {
          workerId: worker.id,
          recurrentConcepts: [{ ...dirtyValues, id: newData.id }],
        },
        mutationFunction: updateRecurrentConcept,
        mutateOptions: {
          onSuccess: () =>
            showSuccessMessage('El concepto recurrente ha sido actualizado.'),
        },
      }
    }

    return {}
  }

  const deleteItem = (oldData) => {
    return {
      data: {
        workerId: worker.id,
        recurrentConcepts: [{ id: oldData.id }],
      },
      mutationFunction: updateRecurrentConcept,
      mutateOptions: {
        onSuccess: () =>
          showSuccessMessage('El concepto recurrente ha sido eliminado.'),
      },
    }
  }

  return (
    <>
      {!isLoading && !isError && (
        <>
          {recurrentConcepts?.incomes &&
          recurrentConcepts.incomes.length > 0 ? (
            <Table
              data={recurrentConcepts.incomes}
              columns={incomesColumns}
              options={{
                pagination: false,
                search: false,
                toolbar: false,
                customActionsWidth: 110,
              }}
              editable={{
                validationSchema,
                onUpdateRow: updateItem,
                onDeleteRow: deleteItem,
                isDeletable: ({ deleteable }) => deleteable,
                isEditable: () => !isWorker,
                tableMinWidth: 600,
                mutationOptions,
                hideDelete: isWorker,
                hideEdit: isWorker,
              }}
            />
          ) : (
            <NoItems type="incomes" onClick={noItemsOnClick} />
          )}
        </>
      )}
    </>
  )
}

export default IncomesTable
