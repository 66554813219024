import React from 'react'
import ReactDOM from 'react-dom'

import AppWrapper from 'components/App/Wrapper'

import firebase from 'firebase/app'
import 'assets/stylesheets/main.css'

import firebaseConfig from './firebase-config'

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
)
