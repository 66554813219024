import Typography from '@material-ui/core/Typography'

import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { SocialBenefitsMenu, Decree376Menu } from '../ActionGroup/Novelties'

const PayrollPayments = () => {
  return (
    <CardReview>
      <Typography variant="h5">Otros</Typography>
      <SocialBenefitsMenu />
      <Decree376Menu />
    </CardReview>
  )
}

export default PayrollPayments
