import clsx from 'clsx'
import { Form, Formik } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import FormField from 'components/UI/Formik/FormField/Index'

import { smilingEmoji } from 'utils/emojis'
import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'

import ContactToSupport from '../common/ContactToSupport'
import LinkToSignup from '../common/LinkToSignup'
import AuthLogo from '../common/Logo'
import MainContainer from '../common/MainContainer'
import { useAuthStyles } from '../helpers'
import { initialValues, validationSchema } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2, 0, 2),
    },
    '& > div': {
      width: '-webkit-fill-available',
    },
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(10),
    maxWidth: 700,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  emailFieldContainer: {
    marginBottom: theme.spacing(4),
  },
}))

const PasswordForgot = () => {
  const classes = useAuthStyles()
  const classesComponent = useStyles()
  const { showSuccessMessage } = useNotifications()
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const successRestorePasswordMessage = (
    <Typography variant="body2" size={10}>
      <b>Recuperación de contraseña</b>
      <br />
      Si estás registrado en Nominapp recibirás un mensaje para
      <br /> restablecer tu contraseña, recuerda revisar tu{' '}
      <b>
        bandeja de <br />
        promociones y spam.
      </b>
    </Typography>
  )

  const handleSubmit = ({ email }, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'restorePassword',
        email,
      },
      {
        onSuccess: () => {
          showSuccessMessage(successRestorePasswordMessage)
          form.resetForm({})
        },
      }
    )
  }

  return (
    <MainContainer>
      <div className={classes.formContainer}>
        <div className={classes.titleContainer}>
          <AuthLogo width={260} height={46} className={classes.logo} />
          <Typography variant="h4" className={classes.title} align="left">
            Restaurar contraseña
          </Typography>
          <Typography className={classes.subtitle} align="left">
            ¡No te preocupes! Si olvidaste tu contraseña simplemente ingresa el
            correo electrónico y te enviaremos las instrucciones para
            restablecerla.{' '}
            <b>Recuerda revisar tu bandeja de promociones y spam.</b>
            <Emoji code={smilingEmoji} />
          </Typography>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {() => {
            return (
              <Form className={classes.form}>
                <div className={classesComponent.emailFieldContainer}>
                  <FormField
                    type="email"
                    name="email"
                    label="Tu correo electrónico"
                    placeholder="Ingresa tu correo electrónico"
                    autoComplete="email"
                    autoFocus
                  />
                </div>
                <Button
                  type="submit"
                  loading={usersMutation.isLoading}
                  className={clsx(classes.button, classesComponent.button)}
                >
                  Restaurar contraseña
                </Button>
              </Form>
            )
          }}
        </Formik>
        <LinkToSignup />
        <ContactToSupport />
      </div>
    </MainContainer>
  )
}

export default PasswordForgot
