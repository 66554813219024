import { useFormikContext } from 'formik'

import CustomField from 'components/UI/Formik/CustomField'

import useDepartmentsService from 'utils/hooks/departmentsService'

/**
 * This component is intended to be used in conjunction with the <DepartmentsList /> component, since when changing the department
 * a query is executed to bring the cities of that department.
 *
 * The prop "department" is to know to which department the cities should be consulted, by default the cities of the department that has the key department
 * in the context of Formik will be consulted.
 *
 * For an example of custom usage, see: src/components/Benefits/Credits/Form/Fields/References.jsx
 */

const CitiesList = ({
  name = '',
  label = '',
  placeholder = '',
  department,
}) => {
  const { values } = useFormikContext()

  const { departmentsQuery } = useDepartmentsService({
    serviceParams: {
      queryKey: 'citiesByDepartment',
      queryKeyExtras: [department?.name || values?.department?.name],
      departmentId: department?.id || values?.department?.id,
    },
    queryOptions: {
      staleTime: Infinity,
      enabled: Boolean(department?.id || values?.department?.id),
    },
  })

  return (
    <CustomField
      component="autocomplete"
      options={departmentsQuery.data?.cities || []}
      name={name}
      data-cy={name}
      label={label}
      placeholder={placeholder}
    />
  )
}

export default CitiesList
