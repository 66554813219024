import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { paymentTypesOptions, setCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import useCompanyService from 'utils/hooks/organizer/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import * as routes from 'config/routes'

import CompanyPaymentsComments from '../Comments/Comments'
import {
  companyPaymentValidationSchema,
  getCurrentPaymentCategory,
  getInitialValues,
  paymentCategoriesOptions,
} from '../helpers'

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: theme.spacing(0.5),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subscriptionButton: {
    marginTop: '2em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      padding: '0.7em 1.5em',
      fontSize: '1.2rem',
      borderRadius: 5,
    },
  },
}))

const valueTypeOptions = [
  { label: 'Valor', value: 'value' },
  { label: 'Porcentaje', value: 'percentage' },
]

const OrganizerCompanyPaymentForm = ({ companyId }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [payment, setPayment] = useState({})
  const { handleError } = useErrorHandler()
  const classes = useStyles()
  const confirm = useConfirm()
  const history = useHistory()
  const { companyMutation } = useCompanyService({
    serviceParams: {
      queryKey: ['getCompanyFromOrganizer', companyId],
      companyId,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const newData = {
          ...data,
        }
        newData.payment_category = getCurrentPaymentCategory(
          data.payment_category
        )
        setPayment(newData)
      },
    },
  })

  const handlePaymentsChange = async (values, form) => {
    const dirtyValues = getDirtyValues(payment, values)

    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        company: {
          id: companyId,
          ...dirtyValues,
        },
      },
      {
        onSuccess: () => {
          setCompanyId(companyId)
          history.push(routes.SUBSCRIPTION())
        },
        onError: (error) => handleError(error, form),
      }
    )
  }

  const handleSubscriptionChanges = (form) => {
    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        company: {
          id: companyId,
          active_subscription: !payment.active_subscription,
        },
      },
      {
        onSuccess: ({ data }) => {
          const newData = {
            ...data,
          }
          newData.payment_category = getCurrentPaymentCategory(
            data.payment_category
          )
          setPayment(newData)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const handleChangeDiscount = (newValue, { values, setValues }) => {
    if (values.extra_charge_type === 'value') {
      setValues({ ...values, payment_extra_charge: newValue })
      return
    }

    let fixedNewValue = newValue
    if (newValue > 100) fixedNewValue = 100
    if (newValue < -100) fixedNewValue = -100

    setValues({ ...values, payment_extra_charge: fixedNewValue })
  }

  const activeTabID = paymentCategoriesOptions[activeTab].id
  const initialValues = getInitialValues(payment, activeTabID)

  return (
    <>
      <Tabs
        value={activeTab}
        tabs={paymentCategoriesOptions}
        onChange={handleChangeTab}
        variant="fullWidth"
        className={classes.tabs}
      />

      {activeTabID !== 'comments' ? (
        <Formik
          onSubmit={
            activeTabID === 'remove_subscription'
              ? handleSubscriptionChanges
              : handlePaymentsChange
          }
          initialValues={initialValues}
          validationSchema={companyPaymentValidationSchema}
          enableReinitialize
        >
          {(form) => {
            const { handleSubmit } = form

            return (
              <Form className={classes.form}>
                {activeTabID !== 'remove_subscription' ? (
                  <div>
                    {activeTabID === 'discount' && (
                      <>
                        <FormField
                          name="extra_charge_type"
                          label="Tipo del descuento"
                          variant="select"
                          options={valueTypeOptions}
                        />
                        <FormField
                          name="payment_extra_charge"
                          label="Valor Descuento (Negativo) o Recargo (Positivo)"
                          type="number"
                          align="left"
                          onChange={({ target: { value: newValue } }) =>
                            handleChangeDiscount(newValue, form)
                          }
                        />
                        <FormField
                          name="extra_charge_limit_date"
                          label="Fecha límite"
                          variant="datepicker"
                        />
                        <FormField
                          name="comment"
                          label="Comentario (Uso interno)"
                        />
                      </>
                    )}

                    {activeTabID === 'change_date' && (
                      <FormField
                        name="next_payment_date"
                        label="Fecha límite"
                        variant="datepicker"
                      />
                    )}

                    {activeTabID === 'payment_type' && (
                      <>
                        <FormField
                          name="payment_category"
                          label="Tipo de Pago"
                          options={paymentTypesOptions}
                          variant="select"
                        />
                      </>
                    )}

                    <div className={classes.submitButton}>
                      <Button
                        color="primary"
                        loading={companyMutation.isLoading}
                        onClick={handleSubmit}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={classes.subscriptionButton}>
                    <Button
                      color={
                        payment.active_subscription ? 'default' : 'primary'
                      }
                      onClick={() =>
                        confirm({
                          okText: 'Sí',
                          cancelText: 'No',
                          type: 'warning',
                          title: '¿Estás seguro?',
                          onOk: () => handleSubscriptionChanges(form),
                        })
                      }
                      loading={companyMutation.isLoading}
                      className={classes.subscriptionButton}
                    >
                      {payment.active_subscription ? 'Cancelar' : 'Activar'}{' '}
                      Suscripción
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      ) : (
        <CompanyPaymentsComments companyId={companyId} />
      )}
    </>
  )
}

export default OrganizerCompanyPaymentForm
