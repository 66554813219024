import { FieldArray, useFormikContext } from 'formik'

import AddButton from 'components/UI/Button/AddButton'
import FormField from 'components/UI/Formik/FormField/Index'

import { generateEmptyAreaOrPosition } from 'utils/company'

import DeleteItemButton from './DeleteItemButton'
import { useArrayFieldsStyles } from './fieldsHelpers'

const CompanyFormFieldsAreas = () => {
  const form = useFormikContext()

  const { values } = form
  const classes = useArrayFieldsStyles()

  const { areas } = values
  return (
    <div className={classes.container}>
      <FieldArray name="areas">
        {(arrayHelpers) => {
          return (
            <>
              {areas.map((_, index) => {
                const key = `areas_${index}`
                return (
                  <div key={key} className={classes.item}>
                    <FormField
                      label={`Área ${index + 1}`}
                      name={`areas[${index}].name`}
                    />
                    <DeleteItemButton
                      name="areas"
                      values={values}
                      arrayHelpers={arrayHelpers}
                      index={index}
                      emptyValue={generateEmptyAreaOrPosition()}
                      style={{
                        marginLeft: 8,
                        marginBottom: 4, // to align with form control margin dense
                      }}
                    />
                  </div>
                )
              })}
              <AddButton
                onClick={() => arrayHelpers.push(generateEmptyAreaOrPosition())}
                className={classes.addBtn}
              >
                Añadir área
              </AddButton>
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default CompanyFormFieldsAreas
