import clsx from 'clsx'
import { useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

const useStyles = makeStyles((theme) => ({
  fullWidthCollapse: {
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  fullWidthAlert: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorAlertWithBorder: {
    borderLeft: `5px solid ${theme.palette.error.main}`,
  },
  infoAlertWithBorder: {
    borderLeft: `5px solid ${theme.palette.info.main}`,
  },
  successAlertWithBorder: {
    borderLeft: `5px solid ${theme.palette.success.main}`,
  },
  warningAlertWithBorder: {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
  },
  alertBig: {
    '& .MuiAlert-message': {
      fontSize: '1.2em !important',
    },
  },
}))

const MAlert = ({
  header,
  severity = 'error',
  closable = false,
  withBorder = false,
  children,
  className,
  fontSize = 'normal',
  fullWidth,
  ...rest
}) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()

  const collapseClass = fullWidth ? classes.fullWidthCollapse : ''

  const borderColor = {
    error: classes.errorAlertWithBorder,
    info: classes.infoAlertWithBorder,
    success: classes.successAlertWithBorder,
    warning: classes.warningAlertWithBorder,
  }

  return (
    <Collapse in={open} className={collapseClass}>
      <Alert
        severity={severity}
        action={
          closable ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        className={clsx(className, {
          [classes.alertBig]: fontSize === 'big',
          [classes.fullWidthAlert]: fullWidth,
          [borderColor[severity]]: withBorder,
        })}
        {...rest}
      >
        {header && <AlertTitle>{header}</AlertTitle>}
        {children}
      </Alert>
    </Collapse>
  )
}

export default MAlert
