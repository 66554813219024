import { makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CreditCard from '@material-ui/icons/CreditCard'

import EditButton from 'components/UI/Button/EditButton'

import { subscriptionPaymentCategories } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'

import usePaymentModal from '../../PaymentModal/usePaymentModal'
import { paymentMethodLogo } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2, 5),
    '& p': {
      color: theme.palette.grey[500],
    },
    '& b': {
      color: theme.palette.common.black,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  methodInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    '& img': {
      marginRight: theme.spacing(1.5),
    },
  },
  creditCardIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 40,
  },
}))

const PaymentMethodCard = ({ subscriptionData }) => {
  const classes = useStyles()
  const isAutomaticDebit = ['automatic_debit', 'pse'].includes(
    subscriptionData?.payment_category
  )
  const isCreditCard = subscriptionData?.payment_category === 'credit_card'
  const ccType = subscriptionData?.payment_method_info?.card_brand?.toLowerCase()

  const paymentModal = usePaymentModal()
  const openPaymentModal = () => paymentModal.openModal()

  return (
    <>
      <Card className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h5">Método de pago</Typography>
          <EditButton title="Actualizar" onClick={openPaymentModal} />
        </div>
        <div className={classes.methodInfo}>
          <>
            {isCreditCard ? (
              <>
                {paymentMethodLogo[ccType] ? (
                  <img
                    src={paymentMethodLogo[ccType]}
                    alt="Credit card logo"
                    width={50}
                    height={40}
                  />
                ) : (
                  <CreditCard className={classes.creditCardIcon} />
                )}
              </>
            ) : null}
          </>
          {isAutomaticDebit ? (
            <img
              src={paymentMethodLogo.pse}
              alt="PSE logo"
              width={30}
              height={30}
            />
          ) : null}
          <div>
            <Typography variant="subtitle2">
              {subscriptionPaymentCategories[subscriptionData?.payment_category]
                ? subscriptionPaymentCategories[
                    subscriptionData?.payment_category
                  ]
                : subscriptionData?.payment_category}
            </Typography>
            {subscriptionData?.account_number ? (
              <Typography variant="body2">
                Terminación: {subscriptionData?.account_number}
              </Typography>
            ) : null}
          </div>
        </div>
        <Typography variant="body2">
          <b>Próximo cobro:</b>{' '}
          {formatDisplayDateString(subscriptionData?.active_until)}
        </Typography>
      </Card>
    </>
  )
}

export default PaymentMethodCard
