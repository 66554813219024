import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import NoWrap from 'components/UI/NoWrap'
import useWorker from 'components/Worker/useWorker'

import { formatNumberWithDecimal } from 'utils/format'

import { ReactComponent as HolidaysIcon } from 'assets/images/views/common/holidays.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(3, 4, 2),
    position: 'relative',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    minWidth: theme.spacing(24.25),
  },
  img: {
    fill: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  disabledImg: {
    fill: theme.palette.grey[500],
  },
  value: {
    marginTop: 'auto !important',
  },
  disabledValue: {
    color: theme.palette.grey[400],
  },
}))

const HolidaysContent = ({ disabled }) => {
  const classes = useStyles()
  const { worker } = useWorker({ useCache: true })

  const accumulatedHolidays = worker.accumulated_holidays
    ? formatNumberWithDecimal(worker.accumulated_holidays, 3)
    : 0

  return (
    <Paper className={classes.container}>
      <HolidaysIcon
        className={clsx(classes.img, disabled && classes.disabledImg)}
      />
      <Typography variant="body2" className={classes.title}>
        Vacaciones acumuladas
      </Typography>
      <Typography
        variant="h5"
        className={clsx(classes.value, disabled && classes.disabledValue)}
      >
        <NoWrap>{disabled ? '--' : `${accumulatedHolidays} días`}</NoWrap>
      </Typography>
    </Paper>
  )
}

export default HolidaysContent
