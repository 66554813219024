import * as yup from 'yup'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { areObjectsEqual } from 'utils/general'
import { generateWageBaseSalaryValidationSchema } from 'utils/worker'

yup.setLocale(yupLocaleES)

export const stepsData = [
  {
    title: 'Paso 1',
    description: 'Datos de la persona',
    pageTitle: 'Datos principales',
  },
  {
    title: 'Paso 2',
    description: 'Datos de afiliación',
    pageTitle: 'Datos de afiliación',
  },
  {
    title: 'Paso 3',
    description: 'Adjuntar documentos',
    pageTitle: 'Adjuntar documentación',
  },
]

const getDateValue = (value) => {
  if (value && typeof value === 'string') return value

  if (value && value instanceof Date) return formatDate(value)

  return null
}

export const getInitialValues = (worker, affiliation) => {
  return {
    // Step 1
    worker: worker.name
      ? { id: worker.id, name: `${worker?.name} ${worker?.last_name}` }
      : null,
    document_type: worker.document_type || null,
    id_number: worker.id_number || null,
    birthday: getDateValue(worker.birthday),
    email: worker.email || null,
    department: worker.department_address?.id
      ? worker.department_address
      : null,
    city: worker.city_address?.id ? worker.city_address : null,
    address: worker.address || null,

    // Step 2
    position: worker.position?.id ? worker.position : null,
    base_salary: worker.base_salary || null,
    initial_day: getDateValue(worker.initial_day),
    health_provider: worker.health_provider?.id ? worker.health_provider : null,
    pension_provider: worker.pension_provider?.id
      ? worker.pension_provider
      : null,
    severance_provider: worker.severance_provider?.id
      ? worker.severance_provider
      : null,
    work_center: worker.work_center?.id ? worker.work_center : null,
    location: worker.location?.id ? worker.location : null,
    contract_category: worker.contract_category || null,
    wage_category: worker.wage_category || null,
    end_day: worker.end_day || null,

    // Step 3
    identification_document: affiliation.identification_document_file || null,
    comment: affiliation.comment || '',
  }
}

export const getDirtyWorker = (values, currentStep) => {
  if (currentStep === 0) {
    return {
      document_type: values.document_type,
      id_number: values.id_number,
      birthday:
        typeof values.birthday === 'string'
          ? values.birthday
          : formatDate(values.birthday),
      email: values.email,
      department_address_id: values.department.id,
      city_address_id: values.city.id,
      address: values.address,
    }
  }

  if (currentStep === 1) {
    return {
      position_id: values.position.id,
      base_salary: values.base_salary,
      initial_day:
        typeof values.initial_day === 'string'
          ? values.initial_day
          : formatDate(values.initial_day),
      health_provider_id: values.health_provider.id,
      pension_provider_id: values.pension_provider.id,
      severance_provider_id: values.severance_provider.id,
      work_center_id: values.work_center.id,
      location_id: values.location.id,
    }
  }

  if (currentStep === 2) {
    return {
      identification_document: values.identification_document,
      comment: values.comment,
    }
  }

  return null
}

const baseSalaryValidationSchema = generateWageBaseSalaryValidationSchema()

export const validationSchema = {
  0: yup.object({
    worker: yup.object().nullable().required(),
    document_type: yup.string().nullable().required(),
    id_number: yup.string().trim().nullable().required(),
    birthday: yup.date().nullable().required(),
    email: yup.string().nullable(),
    department: yup.object().nullable().required(),
    city: yup.object().nullable().required(),
    address: yup.string().nullable().required(),
  }),
  1: yup.object({
    position: yup.object().nullable().required(),
    base_salary: baseSalaryValidationSchema,
    initial_day: yup.date().nullable().required(),
    health_provider: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          !['contractor', 'student_decree_055', 'part_time_contract'].includes(
            contractCategory
          ),
        then: yup.object().nullable().required(),
      }),
    pension_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'document_type'], {
        is: (contractCategory, documentType) =>
          ['ce_no_pension', 'pe_no_pension'].includes(documentType)
            ? false
            : contractCategory &&
              ['employee', 'student_law_789', 'part_time_contract'].includes(
                contractCategory
              ),
        then: yup.object().nullable().required(),
      }),
    severance_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'wage_category'], {
        is: (contractCategory, wageCategory) =>
          contractCategory &&
          wageCategory &&
          ['employee', 'pensioner', 'part_time_contract'].includes(
            contractCategory
          ) &&
          wageCategory !== 'integral_salary',
        then: yup.object().nullable().required(),
      }),
    work_center: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          contractCategory !== 'contractor' &&
          contractCategory !== 'schooling_trainee',
        then: yup.object().nullable().required(),
      }),
    location: yup.object().nullable().required(),
  }),
  2: yup.object({
    identification_document: yup
      .mixed()
      .test('identification_document', null, (value) => {
        if (!(typeof value === 'string')) {
          return value instanceof File
        }

        return true
      })
      .required(),
    comment: yup.string().nullable(),
  }),
}

export const getOptions = (workers) => {
  return workers.map((worker) => ({
    id: worker.id,
    name: `${worker.name} ${worker.last_name}`,
  }))
}

export const formHasChanged = (currentValues, initialValues, currentStep) => {
  let selectedInitialValues = {}
  let selectedCurrentValues = {}

  if (currentStep === 0) {
    selectedCurrentValues = {
      document_type: currentValues.document_type,
      id_number: currentValues.id_number,
      birthday: currentValues.birthday,
      email: currentValues.email,
      department: currentValues.department,
      city: currentValues.city,
      address: currentValues.address,
    }

    selectedInitialValues = {
      document_type: initialValues.document_type,
      id_number: initialValues.id_number,
      birthday: initialValues.birthday,
      email: initialValues.email,
      department: initialValues.department,
      city: initialValues.city,
      address: initialValues.address,
    }
  }

  if (currentStep === 1) {
    selectedCurrentValues = {
      position: currentValues.position,
      base_salary: currentValues.base_salary,
      initial_day: currentValues.initial_day,
      health_provider: currentValues.health_provider,
      pension_provider: currentValues.pension_provider,
      severance_provider: currentValues.severance_provider,
      work_center: currentValues.work_center,
      location: currentValues.location,
    }

    selectedInitialValues = {
      ...selectedInitialValues,
      position: initialValues.position,
      base_salary: initialValues.base_salary,
      initial_day: initialValues.initial_day,
      health_provider: initialValues.health_provider,
      pension_provider: initialValues.pension_provider,
      severance_provider: initialValues.severance_provider,
      work_center: initialValues.work_center,
      location: initialValues.location,
    }
  }

  if (currentStep === 2) {
    selectedCurrentValues = {
      identification_document: currentValues.identification_document,
      comment: currentValues.comment,
    }

    selectedInitialValues = {
      identification_document: initialValues.identification_document,
      comment: initialValues.comment,
    }
  }

  return !areObjectsEqual(selectedCurrentValues, selectedInitialValues)
}
