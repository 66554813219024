import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'

import { formatCurrency } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const PayrollsAdvanceModal = ({ payrollAdvanceValue, onClose }) => {
  const classes = useStyles()
  const handleOk = () => {
    window.open('https://checkout.wompi.co/l/0EuVJu', '_blank')
  }

  return (
    <Modal
      open
      header="Adelanto de nómina"
      okText="Pagar"
      onOk={handleOk}
      onCancel={onClose}
      dialogProps={{
        maxWidth: 'xs',
      }}
    >
      <Typography variant="body1" className={classes.text}>
        Realiza el pago de los adelantos de nómina de tus empleados con
        cualquier medio de pago. Digita el valor total a pagar para quedar al
        día. <br />
      </Typography>
      <Typography variant="body1" className={classes.text}>
        Conoce más del proceso dando click{' '}
        <MuiLink
          href="https://ayuda.nominapp.com/help/adelantos-de-n%C3%B3mina-para-tus-empleados"
          target="_blank"
        >
          aquí.
        </MuiLink>
      </Typography>
      <Typography gutterBottom variant="h6">
        Valor total a pagar:
      </Typography>
      <Typography variant="h4" color="primary">
        {formatCurrency(payrollAdvanceValue)}
      </Typography>
    </Modal>
  )
}

export default PayrollsAdvanceModal
