import { useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import Table from 'components/UI/Table/Table'

import { getColumnsData } from './columnsData'

const PeriodDetailsBodyDataTable = ({ name, data, type }) => {
  const theme = useTheme()

  return (
    <Paper elevation={2}>
      <Table
        columns={getColumnsData(name)}
        data={data}
        options={{
          pagination: false,
          search: false,
          sorting: false,
          toolbar: false,
          rowStyle: (rowData) => {
            if (type === 'new') {
              if (rowData.symbol === '=')
                return {
                  fontWeight: 600, // semi/demi bold
                }
              if (rowData.selected)
                return {
                  backgroundColor: theme.palette.primary[200],
                  color: theme.palette.primary[700],
                  fontWeight: 600,
                }
              return {}
            }
            if (rowData === data[data.length - 1])
              return {
                backgroundColor: theme.palette.grey[300],
                fontWeight: 'bold', // semi/demi bold
              }
            return {}
          },
        }}
      />
    </Paper>
  )
}

export default PeriodDetailsBodyDataTable
