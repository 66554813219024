import { Route, Redirect } from 'react-router-dom'
import { useUser } from 'components/App/UserContext/useUser'

/**
 * View relacted to a organizer so the user needs to be admin role to access this routes
 */
export default ({ component: Component, render, ...rest }) => {
  const { isOrganizer } = useUser()

  return (
    <Route
      {...rest}
      render={(props) => {
        return isOrganizer ? <Component {...props} /> : <Redirect to="/" />
      }}
    />
  )
}
