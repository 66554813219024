import ChipInput from 'material-ui-chip-input'
import { Field, Formik, Form } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import { formatDate } from 'utils/dateTime'
import usePeriodService from 'utils/hooks/organizer/periodService'

import {
  OrganizerGridComponent,
  OrganizerButtonComponent,
} from '../ComponentGridForm'

const useStyles = makeStyles({
  inputRoot: {
    paddingTop: '0.4em!important',
    height: 'auto',
  },
  input: {
    paddingBottom: '0.1!important',
  },
  chip: {
    height: '22px',
    fontSize: '.7em',
    marginBottom: '0.3em',
    '& svg': {
      height: '14px',
    },
  },
})

const OrganizerFilesAccountants = () => {
  const { handleError } = useErrorHandler()
  const downloadURI = useDownloadURI()
  const { periodMutation } = usePeriodService()

  const classes = useStyles()

  const handleSubmit = async (values, form) => {
    if (values.emails.length) {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'generateAccountantFile',
          accountantFileData: {
            ...values,
            date: formatDate(values.date),
          },
        },
        {
          onSuccess: ({ file }) => {
            downloadURI(file)
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ emails: [], date: new Date() }}
      enableReinitialize
    >
      {({ values, setValues }) => {
        return (
          <OrganizerGridComponent>
            <Typography variant="h3" gutterBottom>
              Generar archivo de contadores
            </Typography>
            <Form>
              <Field
                name="emails"
                label="Correo de los contadores"
                variant="outlined"
                component={ChipInput}
                onChange={(chips) =>
                  setValues({
                    ...values,
                    emails: chips,
                  })
                }
                classes={classes}
                newChipKeys={[' ', ',']}
              />
              <FormField
                name="date"
                label="Fecha del periodo a crear"
                variant="datepicker"
              />

              <OrganizerButtonComponent>
                <Button
                  type="submit"
                  loading={periodMutation.isLoading}
                  disabled={values.emails.length === 0}
                >
                  Generar archivo
                </Button>
              </OrganizerButtonComponent>
            </Form>
          </OrganizerGridComponent>
        )
      }}
    </Formik>
  )
}

export default OrganizerFilesAccountants
