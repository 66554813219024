import http, { getApiURL } from '../httpService'

export const createDistributorSession = (token) => {
  const productionUrl = getApiURL({
    pathname: '/sessions',
    withoutCompany: true,
  })
  const developmentUrl = '/v1/sessions'
  const url =
    process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl

  return http.postV2(url, null, {
    credentials: 'include',
    headers: {
      Authorization: `Basic ${token}`,
    },
  })
}

export default {
  createDistributorSession,
}
