import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

/**
 *
 * @param {string} url
 * @param {string} initialText
 */
const generateSimpleMessage = (url, initialText) => {
  return (
    <>
      {initialText} Si deseas ver cómo se realiza este cálculo, haz{' '}
      <TooltipLink href={url}>click aquí</TooltipLink>.
    </>
  )
}
export default generateSimpleMessage
