import { useCallback, useContext, useMemo, useRef } from 'react'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import debounce from 'lodash/debounce'
import cardValidator from 'card-validator'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import { accountTypesOptions } from 'utils/company'

import { NewPricingContext } from './Index'

export const getInitialValues = (company) => {
  const values = {
    modality: null,
    plan: null,
    workers_number: 1,
    isPartner: false,
    payment_method: null,
    document_type: 'cc',
    client_type: 'N',
    bank: null,
    account_type: null,
    account_number: null,
    id_number: null,
    payer_name: null,
    card_number: null,
    security_code: null,
    expiration_date: null,
  }

  if (company.bank) values.bank = company.bank

  values.account_type = accountTypesOptions.find(
    (option) => option.value === (company.account_type || 'savings_account')
  )

  if (company.account_number) values.account_number = company.account_number

  return values
}

export const getValidationSchema = () =>
  yup.object({
    modality: yup.string().nullable(),
    plan: yup.string().nullable(),
    workers_number: yup.number().when(['plan', 'isPartner'], {
      is: (selectedPlan, isPartner) =>
        selectedPlan !== 'free_per_worker_plan' && !isPartner,
      then: yup
        .number()
        .nullable()
        .min(1)
        .required('Debes ingresar la cantidad de personas'),
      otherwise: yup.number().nullable(),
    }),
    payment_method: yup.string().nullable().required(),
    payer_name: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup.string().trim().required(),
        otherwise: yup.string(),
      }),
    card_number: yup
      .number()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .number()
          .test(
            'test-number',
            'Número de tarjeta de crédito inválido',
            (value) => cardValidator.number(value).isValid
          ),
        otherwise: yup.number(),
      }),
    expiration_date: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .string()
          .trim()
          .test(
            'test-expiration-year',
            'Debe ser una fecha válida',
            (value) => {
              return cardValidator.expirationDate(value).isValid
            }
          ),
        otherwise: yup.string(),
      }),
    security_code: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .string()
          .trim()
          .test(
            'test-number',
            'Código de seguridad inválido',
            // eslint-disable-next-line func-names
            function (value) {
              const type = cardValidator.number(this.parent.card_number).card
                ?.type

              return cardValidator.cvv(
                `${value}`,
                type === 'american-express' ? 4 : 3
              ).isValid
            }
          ),
        otherwise: yup.string(),
      }),
    client_type: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    document_type: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    id_number: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    bank: yup
      .object()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.object().required(),
        otherwise: yup.object(),
      }),
    account_type: yup
      .object()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.object().required(),
        otherwise: yup.object(),
      }),
    account_number: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
  })

const debouncedHandler = debounce(
  (values, callback, handler) => handler(values, callback),
  800
)

export const useCalculateSubscription = () => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateSubscriptionPrice',
    },
  })
  const { setFieldValue } = useFormikContext()
  const { subscription } = useContext(NewPricingContext)
  const isClaraPayment =
    subscription?.data?.payment_category === 'clara_payment'

  const calculateSubscription = useCallback(
    (values, callback) => {
      if (isClaraPayment && Number(values?.extraWorkers) < 20) {
        setFieldValue('workers_number', 20)
        return
      }

      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          ...values,
        },
        {
          onSuccess: ({ data }) => callback(data),
        }
      )
    },
    [isClaraPayment, setFieldValue, subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) =>
      debouncedHandler(values, callback, calculateSubscription),
    [calculateSubscription]
  )

  return {
    calculateSubscription,
    calculateSubscriptionFromInput,
  }
}

export const useCalculateExtraWorkers = () => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateExtraWorkers',
    },
  })

  const calculateSubscription = useCallback(
    (values, callback) => {
      if (!values) return
      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          extraWorkers: values,
        },
        {
          onSuccess: ({ data }) => callback(data),
        }
      )
    },
    [subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) =>
      debouncedHandler(values, callback, calculateSubscription),
    [calculateSubscription]
  )

  return {
    calculateSubscription,
    calculateSubscriptionFromInput,
  }
}

export const useSelectPlan = () => {
  const { values, setValues, resetForm, setFieldValue } = useFormikContext()
  const firstInteraction = useRef(false)
  const { selectedPlan, onSelectPlan } = useContext(NewPricingContext)
  const {
    calculateSubscriptionFromInput,
    calculateSubscription,
  } = useCalculateSubscription()

  const selectBasePlan = useCallback(
    (plan) => {
      onSelectPlan({ ...plan, payment_type: values?.modality })
    },
    [onSelectPlan, values.modality]
  )

  const updateSelectedPlan = (newPlan) => {
    resetForm()
    setValues({
      ...values,
      plan: newPlan.coded_name,
    })
    selectBasePlan(newPlan)
  }

  const triggerCalculateSubscription = (workersNumber) => {
    if (Number(workersNumber)) {
      calculateSubscriptionFromInput(
        {
          planId: selectedPlan?.id,
          extraWorkers: workersNumber,
        },
        selectBasePlan
      )
    }
  }

  const handleSelectPlan = (newPlan) => {
    if (Number(values.workers_number) && values.workers_number > 0) {
      calculateSubscription(
        {
          planId: newPlan?.id,
          extraWorkers: values.workers_number,
        },
        updateSelectedPlan
      )
    } else updateSelectedPlan(newPlan)
  }

  const onChangeWorkersNumber = (value) => {
    const { target: { value: inputValue } = {} } = value
    const currentValue = ['string', 'number'].includes(typeof value)
      ? value
      : inputValue

    if (!firstInteraction.current) firstInteraction.current = true
    const positiveValue = Math.abs(currentValue)
    setFieldValue('workers_number', positiveValue)
    triggerCalculateSubscription(positiveValue)
  }

  return {
    onChangeWorkersNumber,
    handleSelectPlan,
    firstInteraction: firstInteraction.current,
  }
}

export const getPaymentData = (
  values,
  paymentMethod,
  planId,
  subscriptionType,
  paidWorkers,
  isPartner,
  extraWorkers
) => {
  const paymentData = {}
  const { expiration_date: expirationDate } = values

  paymentData.payment_type = subscriptionType
  paymentData.paid_workers = paidWorkers

  if (paymentMethod === 'credit_card') {
    paymentData.card_number = values.card_number
    paymentData.security_code = values.security_code
    paymentData.payer_name = values.payer_name
    paymentData.expiration_date = `${expirationDate.substring(
      2
    )}/${expirationDate.substring(0, 2)}` // MMYYYY => YYYY/MM
  }

  if (paymentMethod === 'pse') {
    paymentData.account_type = values.account_type?.value
    paymentData.account_number = values.account_number
    paymentData.bank = values.bank?.id
    paymentData.client_type = values.client_type
    paymentData.id_number = values.id_number
  }

  if (paymentMethod === 'automatic_debit') {
    paymentData.id_number = values.id_number
    paymentData.account_type = values.account_type?.value
    paymentData.account_number = values.account_number
    paymentData.bank_id = values.bank?.id
  }

  if (!isPartner) {
    paymentData.plan_id = planId
  }

  if (isPartner) {
    paymentData.payment_type = 'month'
  }
  if (extraWorkers) {
    paymentData.extra_workers_number = extraWorkers
  }
  paymentData.payment_type = subscriptionType
  paymentData.paid_workers = paidWorkers

  return paymentData
}
