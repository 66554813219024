import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import CardEdit from 'components/UI/MaterialUI/Cards/CardEdit'
import Page from 'components/UI/Page/Page'

import { useCompanyService } from 'utils/hooks/company/companyService'

import useGetCardsData from './helpers'

const useStyles = makeStyles((theme) => ({
  sectionMain: {
    display: 'grid',
    gap: theme.spacing(2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
  },
  content: {
    gap: theme.spacing(2),
    gridAutoRows: 'max-content',
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(4),
    },
  },
  card: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('sm')]: {
      gridColumn: 'span 3',
    },
  },
  header: {
    gridColumn: '1 / -1',
  },
}))

const CompanyShow = () => {
  const classes = useStyles()

  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: 'companyInformation',
    },
  })

  const cardDetails = useGetCardsData(companyQuery.data?.id)

  return (
    <Page
      grid
      documentTitle={`Empresa${
        companyQuery.data?.name ? ` - ${companyQuery.data?.name}` : ''
      }`}
      header={
        <Typography variant="h5" className={classes.header}>
          Empresa
          {companyQuery.data?.name && (
            <>
              {' - '}
              <Typography component="span" variant="h5" color="primary">
                {companyQuery.data?.name}
              </Typography>
            </>
          )}
        </Typography>
      }
      isLoading={companyQuery.isLoading}
      contentClassName={classes.content}
    >
      {cardDetails.map((card, index) => {
        if (card?.hidden) return null

        return (
          <CardEdit
            key={index.toString()}
            card={card}
            className={classes.card}
          />
        )
      })}
    </Page>
  )
}

export default CompanyShow
