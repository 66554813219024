import { useTheme } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import Decree376Toolbar from './Toolbar'
import { columns } from '../helpers'

const Decree376DataTable = ({ decreePayments, isSubmitting, onSubmit }) => {
  const theme = useTheme()

  const getCustomRowStyle = ({ company_balance: companyBalance }) => {
    if (companyBalance === 0) {
      return {
        backgroundColor: theme.palette.grey[100],
      }
    }
    return {}
  }

  const Toolbar = Decree376Toolbar(isSubmitting, onSubmit)

  return decreePayments ? (
    <Table
      data={decreePayments}
      columns={columns}
      components={{ Toolbar }}
      options={{
        selection: true,
        selectionId: 'paid_in_period',
        rowStyle: getCustomRowStyle,
      }}
    />
  ) : null
}

export default Decree376DataTable
