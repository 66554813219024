import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

import NoWrap from 'components/UI/NoWrap'
import Button from 'components/UI/Button/Button'
import HelpModal from 'components/UI/Modal/HelpModal'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { formatDisplayDate } from 'utils/dateTime'

import { MODAL_INFORMATION } from '../helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing(2.5),
      gridTemplateColumns: '1fr',
    },
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2.25),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3.75),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    '& > h6': {
      textAlign: 'center',
    },
  },
  svgIcon: {
    fill: theme.palette.primary.main,
  },
}))

const CardReviewItem = ({ title, value, SvgIcon }) => {
  const classes = useStyles()

  return (
    <CardReview alignCenter>
      <article className={classes.card}>
        <SvgIcon className={classes.svgIcon} />
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h5" component={NoWrap}>
          {value}
        </Typography>
      </article>
    </CardReview>
  )
}

const Review = ({ periodRange, items = [] }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const initialDate = periodRange?.initial_day || ''
  const endDate = periodRange?.end_day || ''

  return (
    <section className={classes.container}>
      <CardReview alignCenter>
        <article className={classes.card}>
          <CalendarTodayOutlinedIcon color="primary" fontSize="large" />
          <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
            Fecha:
          </Typography>
          <Typography variant="h5" component={NoWrap}>
            {formatDisplayDate(initialDate)}
            <br />
            {formatDisplayDate(endDate)}
          </Typography>
        </article>
      </CardReview>
      {items.map((item) => (
        <CardReviewItem key={item.id} {...item} />
      ))}
      <CardReview>
        <Typography variant="h5">Recursos</Typography>
        <Button
          variant="text"
          startIcon={<OndemandVideoIcon />}
          onClick={() => setOpen(true)}
        >
          ¿Cómo liquidar los aportes?
        </Button>
      </CardReview>

      {open && (
        <HelpModal
          modalInfo={MODAL_INFORMATION}
          closeModal={() => setOpen(false)}
        />
      )}
    </section>
  )
}

export default Review
