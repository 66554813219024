import { Button, makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'

import useModals from 'components/App/ModalsManager/useModals'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

import ApproveRequest from '../Modals/ApproveRequest'
import UpdateRequest from '../Modals/UpdateRequest'

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: 750,
    width: '100%',
  },
}))

export const getColumnsData = (classes, handleDownloadEvidence) => {
  const statusLabel = {
    pending: 'Pendiente',
    approved: 'Aprobada',
    rejected: 'Rechazada',
  }

  return [
    {
      Header: 'Persona',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <Link to={WORKER_SHOW(row.original.worker_id)}>
          {row.original.worker_name}
        </Link>
      ),
      customWidth: '200px',
    },
    {
      Header: 'Tipo de permiso',
      accessor: 'payroll_concept_name',
    },
    {
      Header: 'Fecha de ausencia',
      accessor: 'initial_day',
      Cell: ({ row }) => (
        <span style={{ display: 'flex', columnGap: '5px' }}>
          {formatDisplayDateString(row.original.initial_day)}
          <ArrowForwardIcon fontSize="small" />
          {formatDisplayDateString(row.original.end_day)}
        </span>
      ),
    },
    {
      Header: 'Comentarios',
      accessor: 'comment',
      Cell: ({ row }) => {
        if (!row.original.comment) return 'N/A'

        const comment =
          row.original.comment?.length > 45
            ? `${row.original.comment.slice(0, 45)}...`
            : row.original.comment

        return (
          <Tooltip title={row.original.comment}>
            <span>{comment}</span>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Documentos',
      accessor: 'evidence',
      Cell: ({ row }) => {
        if (!row.original.evidence) return 'N/A'

        return (
          <Button
            size="small"
            variant="text"
            onClick={() => handleDownloadEvidence(row.original.evidence)}
          >
            Ver documento
          </Button>
        )
      },
    },
    {
      Header: 'Duración',
      accessor: 'days',
      Cell: ({ row }) =>
        `${row.original.days} ${getWordSingular(
          'días',
          row.original.days_requested <= 1
        )}`,
      customWidth: '150px',
      alignCell: 'center',
      alignHeader: 'center',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => (
        <Chip
          className={classes[row.original.status]}
          label={statusLabel[row.original.status]}
        />
      ),
      customWidth: '150px',
      alignCell: 'center',
      alignHeader: 'center',
    },
  ]
}

export const useTableActions = () => {
  const classes = useStyles()
  const modals = useModals()
  const handleClose = () => modals.closeAll()

  const handleApprove = (rowData) => {
    modals.openModal({
      id: 'approveRequest',
      content: (
        <ApproveRequest
          conceptId={rowData.payroll_concept_id}
          requestId={rowData.id}
          name={rowData.worker_name}
          initialDay={rowData.initial_day}
          endDay={rowData.end_day}
          onClose={handleClose}
        />
      ),
      modalProps: {
        header: 'Solicitud de tiempo fuera',
        onCloseModal: handleClose,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const handleEdit = (rowData) => {
    modals.openModal({
      id: 'editRequest',
      content: <UpdateRequest requestData={rowData} onClose={handleClose} />,
      modalProps: {
        header: 'Modificar tiempo fuera',
        onCloseModal: handleClose,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          maxWidth: false,
          classes: { paper: classes.dialog },
        },
      },
    })
  }

  return [
    (rowData) => ({
      id: 'to_approve',
      tooltip: 'Aprobar o rechazar',
      icon: CheckCircleOutlineIcon,
      disabled: rowData.status === 'approved',
      onClick: () => handleApprove(rowData),
    }),
    (rowData) => ({
      id: 'editable',
      tooltip: 'Editar',
      icon: EditIcon,
      disabled: rowData.status === 'approved',
      onClick: () => handleEdit(rowData),
    }),
  ]
}

export const tabsData = [
  { key: '', label: 'Todas las solicitudes' },
  {
    key: 'pending',
    label: 'Pendientes',
  },
  {
    key: 'approved',
    label: 'Aprobadas',
  },
  {
    key: 'rejected',
    label: 'Rechazadas',
  },
]

export const getEmptyStateTitle = (currentTab) => {
  let status = ''

  if (currentTab === 'approved') {
    status = 'aprobadas'
  } else if (currentTab === 'rejected') {
    status = 'rechazadas'
  } else if (currentTab === 'pending') {
    status = 'pendientes'
  }

  return `No tienes solicitudes de tiempo fuera ${status}`
}
