import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingBox = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" p={4}>
      <CircularProgress size={46} />
    </Box>
  )
}

export default LoadingBox
