import Info from 'components/UI/Modal/ContentSummaryInfo'

import Table from './Table'

const PeriodSendingElectronicPayrollModalContentSummary = ({
  workers,
  periodId,
  setViewWorkers,
  viewWorkers,
}) => {
  const handleClickViewWorkers = () => setViewWorkers(true)

  return viewWorkers ? (
    <Table
      workers={workers?.electronic_payrolls}
      periodId={periodId}
      isViewTable
      handleClickReturn={() => setViewWorkers(false)}
    />
  ) : (
    <Info
      title="¡Estas a punto de emitir nómina electrónica!"
      description="Enviaremos la información de tu nómina de forma automática. Tranqui, te mantendremos informado del proceso"
      workers={workers?.electronic_payrolls_counter.to_issue}
      handleClick={handleClickViewWorkers}
    />
  )
}

export default PeriodSendingElectronicPayrollModalContentSummary
