import Box from '@material-ui/core/Box'

import { isAlegraClaroDist, isArusDist } from 'utils/auth'
import { isDev } from 'utils/general'

import robotErrorImg from 'assets/images/errors/error.svg'

import ErrorPageAtom from './Atoms/ErrorPage'

const ErrorPage = ({ error }) => {
  const errorFooter =
    (isAlegraClaroDist() || isArusDist()) &&
    '¿Podrías contarme por el chat qué pasó?'

  return (
    <Box m={4}>
      <ErrorPageAtom
        subtitle="No eres tú, somos nosotros"
        description="Al parecer algo está fallando, pero no te preocupes estamos trabajando para darle solución."
        image={robotErrorImg}
        details={isDev ? error.toString() : null}
        footer={errorFooter}
      />
    </Box>
  )
}

export default ErrorPage
