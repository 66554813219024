import { Link } from 'react-router-dom'

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'

import { SETTINGS } from 'config/routes'

import IconButton from './IconButton'

const ConfigurationButton = () => {
  return (
    <IconButton Icon={SettingsOutlinedIcon} component={Link} to={SETTINGS()} />
  )
}

export default ConfigurationButton
