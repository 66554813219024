import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const SelectField = ({ id, value, field, options = [], ...props } = {}) => {
  const { placeholder, ...restProps } = props

  return (
    <Select id={id} {...field} displayEmpty value={value ?? ''} {...restProps}>
      <MenuItem disabled value="">
        <em>{placeholder || 'Selecciona...'}</em>
      </MenuItem>
      {options.map((option) => {
        const { label, value: optionValue } = option

        return (
          <MenuItem key={optionValue} value={optionValue}>
            {label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default SelectField
