import { useFormikContext } from 'formik'

import useInstitutionsService from 'utils/hooks/institutionsService'

import FormField from '../FormField/Index'

const BankField = (props) => {
  const { values, setFieldValue } = useFormikContext()

  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'bank'],
      category: 'bank',
    },
    queryOptions: {
      onSuccess: ({ data: banks }) => {
        if (!values.bank) {
          const defaultBank = banks?.find((bank) => bank.name === 'Bancolombia')
          setFieldValue('bank', defaultBank)
        }
      },
    },
  })

  return (
    <FormField
      name="bank"
      label="Banco"
      variant="autocomplete"
      placeholder="Selecciona el banco..."
      options={institutionsQuery.data || []}
      {...props}
    />
  )
}

export default BankField
