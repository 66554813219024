export function getCellNoveltiesText(contractCategory) {
  let modalTitle

  switch (contractCategory) {
    case 'employee':
    case 'pensioner':
    case 'student_law_789':
    case 'terminated':
    case 'part_time_contract':
      modalTitle = 'Vac/Inc/Lic'
      break
    case 'schooling_trainee':
    case 'productive_trainee':
    case 'intern':
      modalTitle = 'Inc/Lic'
      break
    case 'student_decree_055':
      modalTitle = 'Lic'
      break
    default:
      break
  }

  return modalTitle
}

export default getCellNoveltiesText
