import { useQueryClient } from 'react-query'

import { Button as MButton, Typography, makeStyles } from '@material-ui/core'

import Button from 'components/UI/Button/Button'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useNotifications from 'utils/hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.grey[500],
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(5, 0, 4, 0),
    gap: theme.spacing(2),
  },
}))

const DeleteWorkCenter = ({ workCenterId, handleCloseModal }) => {
  const classes = useStyles()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()

  const { companyWorkCentersMutation } = useCompanyWorkCenters({
    queryOptions: { enabled: false },
  })

  const handleDelete = () => {
    companyWorkCentersMutation.mutate(
      {
        mutationMethod: 'DELETE',
        workCenterId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['getWorkCenters', companyId])
          showSuccessMessage('Centro de trabajo eliminado correctamente')
          handleCloseModal()
        },
      }
    )
  }

  return (
    <div>
      <Typography variant="body1" className={classes.description}>
        ¿Estás seguro que quieres eliminar este centro de trabajo? si lo
        eliminas puedes afectar la actividad CIIU y el nivel de riesgo que
        tienen los empleados asignados a este centro de trabajo.
      </Typography>
      <div className={classes.buttonsContainer}>
        <MButton variant="outlined" onClick={handleCloseModal}>
          Volver
        </MButton>
        <Button
          onClick={handleDelete}
          loading={companyWorkCentersMutation.isLoading}
        >
          Eliminar centro de trabajo
        </Button>
      </div>
    </div>
  )
}

export default DeleteWorkCenter
