import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueries, useQueryClient } from 'react-query'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getCompanyConfiguration,
  getConfigurationOptions,
} from 'services/settings/configurationService'

import messages from 'messages/notification'

import Fields from './Fields/Fields'
import validationSchema from './helpers'

const useStyles = makeStyles(() => ({
  button: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const SettingsAdvanced = () => {
  const [redirectToSettings, setRedirectToSettings] = useState(false)
  const queryClient = useQueryClient()
  const queryFunction = [getConfigurationOptions, getCompanyConfiguration]
  const [
    companyConfigurationOptionsQuery,
    companyConfigurationQuery,
  ] = useQueries(
    ['companyConfigurationOptions', 'companyConfiguration'].map(
      (query, index) => {
        return {
          queryKey: query,
          queryFn: () => queryFunction[index](),
        }
      }
    )
  )

  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })
  const isQueriesLoading =
    companyConfigurationOptionsQuery.isLoading ||
    companyConfigurationQuery.isLoading

  const { data: companyConfigurationOptions } = companyConfigurationOptionsQuery
  const { data: companyConfiguration } = companyConfigurationQuery
  const classes = useStyles()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const handleSubmit = (values, form) => {
    const dirtyValues = getDirtyValues(companyConfiguration?.data, values)

    if (isObjectEmpty(dirtyValues)) return setRedirectToSettings(true)

    return companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: dirtyValues,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['companyConfiguration'])
          showSuccessMessage(messages.COMPANY_UPDATE_SUCCESS)
          setRedirectToSettings(true)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  if (redirectToSettings) return <Redirect to="/settings" />

  return (
    <Page
      header="Editar configuración empresa"
      isLoading={isQueriesLoading}
      isLoadingWithModal={companyConfigurationMutation.isLoading}
    >
      <FormBox>
        <Formik
          initialValues={companyConfiguration?.data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            const { isSubmitting } = form

            return (
              <Form>
                <Fields options={companyConfigurationOptions?.data || {}} />
                <div className={classes.button}>
                  <Button type="submit" size="large" loading={isSubmitting}>
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </FormBox>
    </Page>
  )
}

export default SettingsAdvanced
