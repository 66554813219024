import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import ReactDOM from 'react-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/Lock'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import PaymentContext from 'components/Subscription/Atoms/PaymentContext'

import useNotifications from 'utils/hooks/useNotifications'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { getDistributor } from 'utils/auth'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'

import * as routes from 'config/routes'

import {
  getInitialValues,
  dirtyPaymentValues,
  getSchemaValidation,
  getTabsData,
  paymentTypeData,
} from './Form/helpers'

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  headerIcon: {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(1),
  },
  headerIconArus: {
    color: theme.palette.success.dark,
  },
}))

const SubcriptionPaywallPaymentForm = () => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const {
    selectedPlan,
    subscription,
    company,
    submitForm,
    setSubmitForm,
  } = useContext(PaymentContext)
  const distributor = getDistributor()

  const [activePayment, setActivePayment] = useState(() => {
    let activePaymentCategory = subscription.payment_category

    // some payment categories are not available in this view
    if (
      !['automatic_debit', 'credit_card', 'pse'].includes(activePaymentCategory)
    )
      activePaymentCategory = 'credit_card' // default

    return activePaymentCategory
  })

  const { handleError } = useErrorHandler()
  const { showInfoMessage } = useNotifications()
  const formRef = useRef()
  const history = useHistory()
  const classes = useStyles()
  const { paymentMethodMutation } = usePaymentMethodService()

  useEffect(() => {
    if (submitForm) formRef.current.submitForm()
  }, [submitForm])

  const handleChangeTab = (resetForm, newTab) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setActivePayment(newTab)
      resetForm()
    })
  }

  const handleResponse = (response) => {
    if (activePayment === 'pse') window.location.href = response.bank_url

    if (activePayment === 'automatic_debit') showInfoMessage(response.message)

    if (activePayment === 'credit_card') {
      if (response.payment_status) {
        history.push({
          pathname: routes.SUBSCRIPTION_TRANSACTION_RESPONSE(),
          search: `?status=${response.payment_status}`,
          state: {
            payment_status: response.payment_status,
            plan_id: response.plan_id,
            value: response.value,
          },
        })
      } else {
        showInfoMessage(response.message)
      }
    }
  }

  const handleSubmit = (values) => {
    showLoadingModal()
    const dirtyPayment = dirtyPaymentValues(values, activePayment, selectedPlan)

    // don't send plan_id if is partner
    if (subscription.partner) delete dirtyPayment.plan_id

    paymentMethodMutation.mutate(
      {
        paymentMethod: activePayment,
        paymentMethodData: dirtyPayment,
      },
      {
        onSuccess: (response) => {
          handleResponse(response)
        },
        onError: (error) => {
          if (
            error.errors?.[0].code.startsWith('24') ||
            error.errors?.[0].code === '1101'
          ) {
            history.push({
              pathname: routes.SUBSCRIPTION_TRANSACTION_RESPONSE(),
              search: '?status=DECLINED',
              state: {
                payment_status: 'DECLINED',
                plan_id: selectedPlan.id,
                error: error.errors[0],
              },
            })
          } else {
            handleError(error)
          }
        },
        onSettled: () => {
          setSubmitForm(false)
          hideLoadingModal()
        },
      }
    )
  }

  const tabsData = getTabsData(subscription?.payment_category)

  return company ? (
    <>
      <Box display="flex" alignItems="center" mb={1} marginTop={5}>
        <Typography className={classes.header} variant="h5">
          Método de pago
        </Typography>
        <LockIcon
          className={
            distributor === 'arus' ? classes.headerIconArus : classes.headerIcon
          }
        />
      </Box>

      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={getInitialValues(company)}
        validationSchema={getSchemaValidation(activePayment)}
        validateOnChange={false}
        enableReinitialize
      >
        {({ resetForm, errors }) => {
          if (Object.keys(errors).length) setSubmitForm(false)

          return (
            <Paper>
              <Tabs
                tabs={tabsData}
                value={activePayment}
                onChange={(__, newTab) => handleChangeTab(resetForm, newTab)}
                data-cy="paymeny_subscription_options"
                variant="scrollable"
              />
              <Box p={2}>
                <Form>{paymentTypeData[activePayment].fields}</Form>
              </Box>
            </Paper>
          )
        }}
      </Formik>
    </>
  ) : null
}

export default SubcriptionPaywallPaymentForm
