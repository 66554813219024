import { Box, Button, Paper, Typography, makeStyles } from '@material-ui/core'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'

import Table from './Table'

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.grey[500],
  },
  providerName: {
    fontWeight: 'bold',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    justifyContent: 'space-between',
  },
  paperContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3.5),
  },
}))

export const TotalCard = ({ workersTotal, action }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div className={classes.paperContent}>
        <GroupOutlinedIcon color="primary" fontSize="large" />
        <div>
          <Typography variant="subtitle2" className={classes.description}>
            Total de personas
          </Typography>
          <Typography variant="h4">{workersTotal}</Typography>
        </div>
      </div>
      {action || null}
    </Paper>
  )
}

const PeriodSocialSecurityPaymentModalContentSummary = ({
  workers,
  totals,
  isOnlyWorkers,
  providerName,
  showWorkers,
  setShowWorkers,
  provider,
}) => {
  const classes = useStyles()

  const workersTotal = workers.filter((worker) => worker.name !== 'Totales')
    ?.length

  return showWorkers ? (
    <Table
      workers={workers}
      totals={totals}
      isOnlyWorkers={isOnlyWorkers}
      handleClickReturn={() => setShowWorkers(false)}
      provider={provider}
      workersTotal={workersTotal}
    />
  ) : (
    <>
      <Typography variant="body1" className={classes.description}>
        Para continuar el proceso de pago de seguridad social y enviar los datos
        de tu planilla a{' '}
        <span className={classes.providerName}>{providerName}</span> debes dar
        clic en continuar, relax que no estarás solo, te iremos guiando en cada
        paso y siempre podrás regresar y editar cualquier valor.
      </Typography>
      <Box mt={4} mb={1}>
        <TotalCard
          workersTotal={workersTotal}
          action={
            <Button
              size="small"
              variant="text"
              onClick={() => setShowWorkers(true)}
            >
              Ver personas
            </Button>
          }
        />
      </Box>
    </>
  )
}

export default PeriodSocialSecurityPaymentModalContentSummary
