import { useState } from 'react'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useUsersService from 'utils/hooks/settings/usersService'

import { PASSWORD_RECOVERY, PERIOD_PAY_PAYROLL } from 'config/routes'

import AuthLogo from '../common/Logo'
import { useAuthStyles } from '../helpers'
import MainContainer from '../common/MainContainer'
import LinkToSignup from '../common/LinkToSignup'
import ContactToSupport from '../common/ContactToSupport'
import { initialValues, validationSchema } from './helpers'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: '-webkit-fill-available',
  },
}))

const PasswordReset = () => {
  const history = useHistory()
  const classes = useAuthStyles()
  const classesComponent = useStyles()
  const [invalidToken, setInvalidToken] = useState(false)
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const { reset_password_token: resetPasswordToken } = queryString.parse(
    useLocation().search
  )

  const queryPasswordRecovery = '?password_recovered=true'

  const { handleError } = useErrorHandler()

  if (!resetPasswordToken) {
    throw new Error('Error al reestablecer contraseña')
  }

  const handleSubmit = async (values, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'PUT',
        user: {
          ...values,
          reset_password_token: resetPasswordToken,
        },
      },
      {
        onSuccess: () => {
          history.replace({
            pathname: PERIOD_PAY_PAYROLL(),
            search: queryPasswordRecovery,
            state: { passwordRecoveredSuccessfully: true },
          })
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ object: 'reset_password_token' }],
            notistackOptions: {
              preventDuplicate: true,
            },
          })

          if (
            error.errors[0].code === '0101' &&
            error.errors[0].object === 'reset_password_token'
          ) {
            setInvalidToken(true)
          }
        },
      }
    )
  }

  return (
    <MainContainer>
      <div
        className={clsx(classes.formContainer, classesComponent.formContainer)}
      >
        <AuthLogo width={260} height={46} className={classes.logo} />
        <Typography variant="h5" className={classes.title}>
          Cambiar contraseña
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {() => {
            return (
              <Form className={classes.form}>
                <div className={classes.fieldsContainer}>
                  <PasswordField
                    name="password"
                    label="Tu contraseña (Mínimo 8 caracteres)"
                    placeholder="Ingresa tu contraseña"
                    autoComplete="password"
                  />
                  <PasswordField
                    name="password_confirmation"
                    label="Repetir contraseña (Mínimo 8 caracteres)"
                    placeholder="Confirma tu contraseña"
                    autoComplete="password"
                  />
                </div>
                <Button
                  type="submit"
                  loading={usersMutation.isLoading}
                  className={classes.button}
                >
                  Cambiar mi contraseña
                </Button>
              </Form>
            )
          }}
        </Formik>

        {invalidToken && (
          <Alert style={{ textAlign: 'left' }}>
            <Typography variant="body2">
              El código para reestablecer la contraseña ya expiró o es inválido.{' '}
            </Typography>
            <br />
            <Typography variant="body2">
              Vuelve a realizar el proceso haciendo{' '}
              <Link to={PASSWORD_RECOVERY}>click aquí</Link>.
            </Typography>
            <br />
            <Typography variant="body2">
              Para más detalles puedes ver:{' '}
              <MuiLink
                href="https://ayuda.nominapp.com/help/como-recupero-mi-contrasena"
                target="_blank"
              >
                ¿Cómo recupero mi contraseña?
              </MuiLink>
            </Typography>
          </Alert>
        )}
        <LinkToSignup />
        <ContactToSupport />
      </div>
    </MainContainer>
  )
}

export default PasswordReset
