import { useContext } from 'react'

import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import PaymentContext from 'components/Subscription/Atoms/PaymentContext'

import { getDistributor } from 'utils/auth'

import PlanCard from './PlanCard'
import FrequentQuestions from './FrequentQuestions'

const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  periodicityAndDiscount: {
    display: 'grid',
    gridAutoFlow: 'row',
    [theme.breakpoints.up('sm')]: {
      gridAutoFlow: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    gridGap: 8,
    marginBottom: theme.spacing(),
    placeItems: 'center',
  },
  periodicity: {
    display: 'flex',
    alignItems: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  plansContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
    marginTop: 30,
  },
  planFeaturesContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gridAutoRows: '1fr',
    marginBottom: theme.spacing(3.5),
  },
  tooltipContent: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  tooltipArrow: {
    color: theme.palette.secondary.main,
  },
  discount: {
    color: theme.palette.common.black,
  },
  lightDiscount: {
    color: theme.palette.primary.lighter,
  },
  chipInfo: {
    backgroundColor: theme.palette.info.light,
    '& p': {
      fontWeight: 600,
      color: theme.palette.info.dark,
    },
  },
  questionsContainer: {
    marginTop: theme.spacing(5),
  },
}))

const SubscriptionPaywallPlans = ({ forwardedRef }) => {
  const {
    paymentData: { plans },
    isAnually,
    selectedPlan,
    setSelectedPlan,
    subscription,
  } = useContext(PaymentContext)
  const distributor = getDistributor()
  const classes = useStyles()
  const monthlyDiscount =
    selectedPlan?.subscription_value?.discounts?.monthly_percentage
  const yearlyDiscount =
    selectedPlan?.subscription_value?.discounts?.yearly_percentage

  const handleChange = (event) => {
    setSelectedPlan({
      ...selectedPlan,
      payment_type: event.target.checked ? 'year' : 'month',
    })
  }

  const hasDiscountOnPeriodicity =
    (!isAnually && monthlyDiscount > 0) || (isAnually && yearlyDiscount > 0)

  const plansForRender = () => {
    if (plans && plans.length > 0) {
      if (subscription.payrolls_size < 80) return plans

      return [plans[1]]
    }
    return []
  }

  return (
    <>
      <div>
        <Typography variant="h5">Selecciona el plan a pagar</Typography>

        <div className={classes.periodicityAndDiscount}>
          <div className={classes.periodicity}>
            <Typography>Mensual</Typography>
            <Switch
              checked={isAnually}
              onChange={handleChange}
              data-cy="plan_type"
            />
            <Typography>Anual</Typography>
          </div>
          {hasDiscountOnPeriodicity && (
            <Chip
              className={distributor === 'nominapp' ? classes.chipInfo : null}
              label={
                <Typography
                  className={
                    distributor === 'arus'
                      ? classes.lightDiscount
                      : classes.discount
                  }
                >
                  {`Ahorra un ${
                    isAnually
                      ? `${yearlyDiscount * 100}% en pago anual`
                      : `${monthlyDiscount * 100}% en pago mensual`
                  }`}
                </Typography>
              }
              color="secondary"
            />
          )}
        </div>

        <div className={classes.plansContainer}>
          {plansForRender()?.map((plan) => (
            <PlanCard key={plan.name} plan={plan} isAnually={isAnually} />
          ))}
        </div>

        <Typography className={classes.text}>
          <span className={classes.underline}>
            <b>Todos nuestros planes tienen acceso total a Nominapp,</b>
          </span>{' '}
          elige el tuyo con base a la cantidad de empleados de tu empresa.
        </Typography>
      </div>
      <div className={classes.questionsContainer}>
        <FrequentQuestions forwardedRef={forwardedRef} />
      </div>
    </>
  )
}

export default SubscriptionPaywallPlans
