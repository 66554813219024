import { useState, useContext } from 'react'

import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined'
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined'
import DvrIcon from '@material-ui/icons/Dvr'

import { PeriodContext } from 'components/Period/Payroll/Payroll'
import SendElectronicPayrollModal from 'components/Period/ElectronicPayroll/SendModal/SendElectronicPayrolls'
import Link from 'components/UI/MaterialUI/Link'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'

import * as routes from 'config/routes'

const useStyles = makeStyles((theme) => ({
  badge: {
    marginRight: theme.spacing(5),
  },
}))

const ElectronicPayroll = ({ electronicPayrollUnAuthorized }) => {
  const [electronicPayrollModalOpen, setElectronicPayrollModalOpen] = useState(
    false
  )
  const { maintenance } = useDianMaintenance()

  const classes = useStyles()

  const {
    period: {
      electronic_payroll_period_id: electronicPayrollPeriodId,
      end_day: endDay,
    },
    options: {
      electronic_payroll: electronicPayroll,
      electronic_payrolls_counter: electronicPayrollsCounter,
    },
  } = useContext(PeriodContext)

  const sendElectronicPayroll =
    (electronicPayroll?.authorized &&
      electronicPayroll?.transmission_authorized &&
      electronicPayroll?.transmission_in_range &&
      !maintenance) ||
    window.Cypress

  const hasElectronicPayrollsToIssue =
    electronicPayrollsCounter?.modified > 0 ||
    electronicPayrollsCounter?.pending > 0 ||
    electronicPayrollsCounter?.rejected > 0 ||
    electronicPayrollsCounter?.to_issue > 0

  return (
    <CardReview>
      <Typography variant="h5">Nómina electrónica</Typography>
      {sendElectronicPayroll ? (
        <>
          <Badge
            color="secondary"
            className={classes.badge}
            badgeContent={<b>Pendiente</b>}
            invisible={!hasElectronicPayrollsToIssue}
          >
            <Button
              variant="text"
              underline="none"
              startIcon={<RecentActorsOutlinedIcon />}
              onClick={() => setElectronicPayrollModalOpen(true)}
            >
              Emitir nómina electrónica
            </Button>
          </Badge>
        </>
      ) : null}
      {!electronicPayrollUnAuthorized ? (
        <Button
          component={Link}
          variant="text"
          underline="none"
          startIcon={<AppsOutlinedIcon />}
          to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPayrollPeriodId)}
        >
          Ver detalle de nómina electrónica
        </Button>
      ) : (
        <Button
          component={Link}
          variant="text"
          underline="none"
          startIcon={<DvrIcon />}
          to={routes.SETTINGS_ELECTRONIC_PAYROLL()}
        >
          Habilitar nómina electrónica
        </Button>
      )}

      {electronicPayrollModalOpen && (
        <SendElectronicPayrollModal
          handleClose={() => setElectronicPayrollModalOpen(false)}
          periodId={electronicPayrollPeriodId}
          endDay={endDay}
        />
      )}
    </CardReview>
  )
}

export default ElectronicPayroll
