import { Typography } from '@material-ui/core'

export const getColumnsDataIndex = () => {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => row.original.worker.name,
      customWidth: '250px',
    },
    {
      Header: 'Motivo del rechazo',
      accessor: 'error',
      Cell: ({ row }) => row.original.message.error,
    },
    {
      Header: 'Detalles',
      accessor: 'details',
      Cell: ({ row }) =>
        row.original.message.details && row.original.message.details.length > 0
          ? row.original.message.details.map((detail, index) => {
              const key = `detail_${index}`
              return (
                <Typography key={key} variant="body2" gutterBottom>
                  <b>-</b> {detail}
                </Typography>
              )
            })
          : null,
      customWidth: '200px',
      cellDirection: 'column',
    },
  ]
}

export const columnsDataDetail = [
  {
    Header: 'Item',
    accessor: 'item',
    Cell: ({ row }) => row.original.item,
    customWidth: '100px',
  },
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }) => row.original.detail,
  },
]
