import { useMemo } from 'react'

import Typography from '@material-ui/core/Typography'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getLoans } from 'services/worker/loansService'

import * as routes from 'config/routes'

import { columnsDataIndex } from './helpers'

const LoansIndex = ({ match }) => {
  const { worker } = useWorker()
  const { workerId } = match.params

  const fetchLoans = (pageIndex, search) => {
    return {
      queryKey: ['getAllLoans', pageIndex + 1, search],
      queryFunction: () =>
        getLoans(workerId, {
          page: pageIndex + 1,
          search,
        }),
    }
  }

  const columns = useMemo(() => columnsDataIndex(workerId), [workerId])

  return (
    <Page
      documentTitle={`Prestamos${worker ? ` de ${worker.fullName}` : ''}`}
      header={
        <Typography variant="h5">
          Prestamos de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
    >
      <Table columns={columns} data={fetchLoans} />
    </Page>
  )
}

export default LoansIndex
