import { useQueryClient } from 'react-query'

import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import { trackEvent } from 'utils/integration'
import { getDirtyValues } from 'utils/form'
import useNotifications from 'utils/hooks/useNotifications'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { isOrganizer } from 'utils/auth'
import useUsersService from 'utils/hooks/settings/usersService'

import {
  createUser,
  getAllUsers,
  updateUserState,
} from 'services/settings/userService'

import columnsData from './helpers'
import { validationSchema } from './validationSchema'

const SettingsUsers = () => {
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const featureState = usePremiumFeature('addAdmin')
  const usersDataCache = queryClient.getQueryData(['users'], { exact: false })
  const refetchUsers = () =>
    queryClient.invalidateQueries(['users'], { exact: false })
  const hasOneUsers = usersDataCache?.data
    ? usersDataCache?.data.length >= 1
    : refetchUsers()

  const { usersMutation } = useUsersService({
    mutationOptions: {
      onSuccess: refetchUsers,
    },
  })

  const mutationOptions = {
    onSuccess: refetchUsers,
  }

  const fetchUsers = (page, search) => {
    return {
      queryKey: ['users', page, search],
      queryFunction: () => getAllUsers({ page: page + 1, search }),
    }
  }

  const addUser = (user) => {
    return {
      data: {
        user,
      },
      mutationFunction: createUser,
      mutateOptions: {
        onSuccess: () => trackEvent(integrationEvent.INVITE_COLLABORATOR, user),
      },
    }
  }

  const updateUser = (oldUser, newUser) => {
    const dirtyValues = getDirtyValues(
      oldUser,
      newUser,
      validationSchema.fields
    )

    return {
      data: { id: newUser.id, ...dirtyValues },
      mutationFunction: updateUserState,
    }
  }

  const resendEmail = (user) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'resendUserEmail',
        user: {
          id: user.id,
        },
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleResendEmail = (user) => {
    confirm({
      type: 'warning',
      title: 'Reenviar correo de invitación',
      description: `Un correo de invitación para usar Nominapp se enviará a ${user.email}`,
      okText: 'Enviar',
      onOk: () => resendEmail(user),
    })
  }

  const deleteUser = (user) => {
    usersMutation.mutate(
      {
        mutationMethod: 'DELETE',
        user: {
          id: user.id,
        },
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleDeleteUser = (user) => {
    confirm({
      type: 'warning',
      title: 'Eliminar usuario',
      description: `Eliminar al usuario ${user.name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
      okText: 'Eliminar',
      confirmCheckbox: true,
      onOk: () => deleteUser(user),
    })
  }

  const handleAddRow = () => {
    if (featureState.isPremiumFeature && hasOneUsers) {
      featureState.openModal()
      return false
    }
    return true
  }

  return (
    <Page header="Configuración usuarios">
      <Typography>
        Invita a más personas de tu empresa. Puedes leer más sobre cada rol
        aquí.{' '}
        <MuiLink
          href="https://ayuda.nominapp.com/help/con-que-permisos-cuenta-cada-rol"
          target="_blank"
        >
          Visita nuestro centro de ayuda
        </MuiLink>
      </Typography>
      <Table
        columns={columnsData}
        data={fetchUsers}
        options={{
          customActionsWidth: 150,
        }}
        editable={{
          onAddRow: addUser,
          onUpdateRow: updateUser,
          enableAddRow: true,
          addRowActionProps: {
            tooltip: 'Añadir Persona',
            onClick: handleAddRow,
            buttonProps: {
              endIcon: (
                <IconCrown
                  isPremiumAction={featureState.isPremiumFeatureIcon}
                />
              ),
            },
          },
          addRowPosition: 'top',
          validationSchema,
          hideDelete: true,
          tableMinWidth: 1080,
          mutationOptions,
        }}
        actions={[
          (rowData) => ({
            id: 'resendEmail',
            icon: MailOutlineIcon,
            tooltip: 'Enviar correo',
            onClick: (onClickRowData) => handleResendEmail(onClickRowData),
            hidden: !rowData.active,
          }),
          (rowData) => ({
            id: 'deleteUser',
            icon: DeleteForeverIcon,
            tooltip: 'Eliminar usuario',
            onClick: (onClickRowData) => handleDeleteUser(onClickRowData),
            hidden: !rowData.deleteable || !isOrganizer(),
          }),
        ]}
      />
    </Page>
  )
}

export default SettingsUsers
