import { isChristmasTime } from 'utils/dateTime'

import logo from 'assets/images/logos/logo_amarillo.svg'
import logoNavidad from 'assets/images/logos/logo_yellow_christmas.gif'
import arusLogoBlanco from 'assets/images/logos/arus/logo_arus_white.svg'
import alegraClaroLogo from 'assets/images/logos/alegra/alegra_claro_logo.png'

import { useUser } from './UserContext/useUser'

const AppLogo = (props) => {
  const { distributor } = useUser()

  const imgProps = {
    alt: 'Nominapp Logo',
    src: logo,
  }

  if (distributor === 'alegra_claro') {
    imgProps.alt = 'Alegra Claro Logo'
    imgProps.src = alegraClaroLogo
  } else if (distributor === 'arus') {
    imgProps.alt = 'Arus logo'
    imgProps.src = arusLogoBlanco
  } else if (isChristmasTime()) {
    imgProps.alt = 'Nominapp Logo Navidad'
    imgProps.src = logoNavidad
  }

  return (
    <img {...imgProps} {...props} /> // eslint-disable-line jsx-a11y/alt-text
  )
}

export default AppLogo
