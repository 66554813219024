import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import Table from 'components/UI/Table/Table'
import useRecurrentConcepts from 'components/Worker/Show/useRecurrentConcepts'
import useWorker from 'components/Worker/useWorker'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import { updateLoans } from 'services/worker/loansService'
import { updateRecurrentConcept } from 'services/worker/recurrentConceptService'

import NoItems from '../NoItems'
import { getDeductionsColumns, getValidationSchema } from './helpers'

const DeductionsTable = ({ noItemsOnClick }) => {
  const { worker } = useWorker({ useCache: true })
  const { isWorker } = useUser()
  const {
    recurrentConcepts,
    isLoading,
    isError,
    refreshRecurrentConcepts,
  } = useRecurrentConcepts()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const deductionsQuery = queryClient.getQueryData(['deductions', worker.id])
  const optionsDeductionsConcepts = deductionsQuery?.data
    .filter(
      ({ coded_name: codedName }) =>
        codedName !== 'loan' &&
        codedName !== 'withholding_tax' &&
        codedName !== 'avista_payroll_credit'
    )
    .map((concept) => ({
      id: concept.id,
      name: concept.name,
    }))

  const mutationOptions = {
    onSuccess: () => {
      refreshRecurrentConcepts()
    },
  }

  const deductionsColumns = useMemo(
    () => getDeductionsColumns(optionsDeductionsConcepts),
    [optionsDeductionsConcepts]
  )

  const validationSchema = useMemo(
    () => getValidationSchema(worker.base_salary),
    [worker.base_salary]
  )

  const handleMutationConfig = (data, values) => {
    const message =
      data.category === 'deductions' ? 'concepto recurrente' : 'préstamo'
    const mutationFunction =
      data.category === 'deductions' ? updateRecurrentConcept : updateLoans
    const mutationConfig = {
      data: {
        workerId: worker.id,
        recurrentConcepts: values,
      },
      mutationFunction,
      mutateOptions: {
        onSuccess: () =>
          showSuccessMessage(`El ${message} ha sido actualizado.`),
      },
    }

    if (data.category === 'loans') {
      mutationConfig.data.loans = values
      delete mutationConfig.data.recurrentConcepts
    }

    return mutationConfig
  }

  const updateItem = (oldData, newData) => {
    const dirtyValues = getDirtyValues(
      oldData,
      newData,
      validationSchema.fields
    )
    const values = [{ ...dirtyValues, id: newData.id }]

    if (!isObjectEmpty(dirtyValues)) {
      return handleMutationConfig(newData, values)
    }
    return {}
  }

  const deleteItem = (oldData) => {
    const values = [{ id: oldData.id }]
    return handleMutationConfig(oldData, values)
  }

  return (
    <>
      {!isLoading && !isError && (
        <>
          {recurrentConcepts?.deductions.length !== 0 ? (
            <Table
              data={recurrentConcepts.deductions}
              columns={deductionsColumns}
              options={{
                pagination: false,
                search: false,
                toolbar: false,
                customActionsWidth: 110,
              }}
              editable={{
                validationSchema,
                onUpdateRow: updateItem,
                onDeleteRow: deleteItem,
                isDeletable: ({ deleteable }) => deleteable,
                isEditable: () => !isWorker,
                tableMinWidth: 980,
                mutationOptions,
                hideDelete: isWorker,
                hideEdit: isWorker,
              }}
            />
          ) : (
            <NoItems type="deductions" onClick={noItemsOnClick} />
          )}
        </>
      )}
    </>
  )
}

export default DeductionsTable
