import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import Modal from 'components/UI/Modal/Modal'
import OrganizationEmail from 'components/UI/OrganizationEmail'

const TermsConditionsAndPoliticsModal = ({ visible, onOk }) => {
  return (
    <Modal
      header="TÉRMINOS, CONDICIONES Y POLITICA DE PROTECCIÓN DE DATOS PERSONALES."
      open={visible}
      okText="Aceptar"
      onOk={onOk}
      hideCancelButton
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
    >
      <Typography variant="h6">TÉRMINOS Y CONDICIONES</Typography>
      <br />
      <Typography>
        <strong>NOMINAPP</strong>(en adelante “<strong>NOMINAPP</strong>” o “la
        Plataforma”) es software de liquidación de nómina online, que realiza
        todos los cálculos de seguridad social y parafiscales de acuerdo con la
        información que suministren los usuarios. Este es propiedad de{' '}
        <strong>GRUPO QUINCENA S.A.S</strong>, sociedad comercial debidamente
        constituida, identificada con el NIT 900.926.147 y con domicilio en la
        ciudad de Medellín, Colombia.
      </Typography>
      <br />

      <Typography variant="subtitle1">
        1. ¿Que son los términos y condiciones?
      </Typography>
      <br />

      <Typography>
        Los Términos y Condiciones hacen referencia al contrato que se celebra
        entre el usuario de <strong>NOMINAPP</strong> y{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> (en adelante la “Organización” o
        “<strong>GRUPO QUINCENA</strong>”). Estos contienen los deberes y
        derechos de los usuarios, y establecen las condiciones en las cuales
        <strong>GRUPO QUINCENA</strong> prestará sus servicios.
      </Typography>
      <br />

      <Typography variant="subtitle1">2. Generalidades y aceptación</Typography>
      <br />

      <Typography>
        A través de estos Términos y Condiciones, así como al pago del precio
        cuando sea aplicable, <strong>GRUPO QUINCENA</strong> le concede a sus
        usuarios una licencia limitada no exclusiva, no transferible y no
        sublicenciable, de acceso y utilización, no comerciales del software{' '}
        <strong>NOMINAPP</strong>. Dicha licencia no incluye derecho alguno de
        reventa ni de uso comercial de los servicios de{' '}
        <strong>GRUPO QUINCENA</strong>, ni de sus contenidos o la información
        que allí se genere.
        <br />
        <strong>GRUPO QUINCENA</strong> se reserva el derecho de modificar estos
        términos y condiciones sin previo aviso, por lo que constituye una
        obligación de los usuarios revisarlos cada vez que ingresen a la
        Plataforma. Cuando se realice una modificación que pueda afectar los
        deberes y derechos de los usuarios, <strong>GRUPO QUINCENA</strong> lo
        comunicará oportunamente.
        <br />
        En caso de tener dudas frente a cualquier disposición, los usuarios
        pueden enviar una comunicación al correo electrónico{' '}
        <OrganizationEmail variant="hello" /> para entrar en contacto con el
        equipo de atención al cliente.
        <br />
        El incumplimiento de estos Términos y Condiciones podrá conducir a
        sanciones y acciones legales, bloqueos dentro de la Plataforma o
        interrupción en la prestación del servicio.
      </Typography>
      <br />

      <Typography variant="subtitle1">3. Capacidad y Registro</Typography>
      <br />

      <Typography>
        Para poder acceder a los servicios de la Plataforma, el usuario deberá
        inscribirse en <strong>NOMINAPP</strong> proporcionando datos reales y
        verificables, como cédula de ciudadanía, celular, correo electrónico y
        dirección de notificaciones. Igualmente deberá generar un nombre de
        usuario y una clave de acceso de uso único e intransferible. Grupo
        Quincena podrá solicitar otros documentos cuando lo considere
        conveniente para la adecuada individualización de los sujetos inscritos
        en <strong>NOMINAPP</strong>. Los datos personales que se recolectan
        durante esta actividad, serán tratados conforme a la Política de
        Tratamiento de Datos Personales de{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> la cual se encuentra al finalizar
        estos Términos y Condiciones.
        <br />
        <strong>NOMINAPP</strong> está disponible para personas que tengan plena
        capacidad jurídica para contratar. Se presumirá la capacidad jurídica de
        las personas mayores de 18 años en pleno uso de sus facultades mentales,
        siempre que no estén envueltos en una prohibición legal o judicial para
        celebrar contratos. Cuando se está contratando a nombre de una persona
        jurídica, quién realice el registro deberá estar plenamente facultado
        para hacerlo.
        <br />
        Frente a la identidad de las personas, <strong>
          GRUPO QUINCENA
        </strong>{' '}
        presumirá de buena fe que está contratando directamente con la persona
        que se registra en
        <strong>NOMINAPP</strong>, con su representante legal debidamente
        facultado para efectuar este tipo de operaciones o en su defecto con sus
        apoderados. Por ello <strong>GRUPO QUINCENA</strong> no asumirá ninguna
        responsabilidad por cualquier suplantación personal y el usuario se
        compromete a mantener indemne a <strong>GRUPO QUINCENA</strong> por
        estos hechos, salvo que la ley expresamente indique lo contrario.
      </Typography>
      <br />

      <Typography variant="subtitle1">4. Servicios</Typography>
      <br />

      <Typography>
        Como ya lo habíamos mencionado, <strong>NOMINAPP</strong> es un software
        de nómina en la nube para hacer la liquidación de nómina.
        <br />
        Para conocer las particularidades de los servicios, así como su precio,
        los interesados pueden dirigirse al apartado “Características” en{' '}
        <MuiLink href="https://nominapp.com/" target="_blank">
          www.nominapp.com
        </MuiLink>
        . Igualmente recomendamos ingresar al apartado “Preguntas frecuentes” y
        entrar en contacto con un asesor, con el fin de resolver todas las dudas
        antes de realizar una suscripción al servicio.
        <br />
        Es muy importante tener en cuenta que a través de{' '}
        <strong>NOMINAPP</strong> no se pretende prestar ningún tipo de asesoría
        jurídica sobre la contratación de talento humano o conflictos con los
        empleados y contratistas, simplemente se gestiona la liquidación de
        nómina a través del software, basándose en la información que el usuario
        ingresa. Por esto <strong>GRUPO QUINCENA</strong> no responde por
        cualquier tipo de conflicto que se pueda presentar entre los usuarios de{' '}
        <strong>NOMINAPP</strong> y sus trabajadores o contratistas y únicamente
        responderá por la prestación de sus servicios, que consiste en realizar
        los cálculos de liquidación de nómina.
        <br />
        Cuando <strong>GRUPO QUINCENA</strong> realice publicaciones o publique
        artículos, la información contenida en ellos se debe tomar a título
        informativo y nunca como consejo legal o contable aplicable a casos
        concretos.
        <br />
        <strong>GRUPO QUINCENA</strong> no se hace responsable por el mal
        diligenciamiento de un formulario, o por errores cometidos por el
        usuario al momento de digitar o ingresar su información, tanto la de
        carácter personal como de la organización que representa, ni tampoco de
        la clasificación que realice de la misma o de cada empleado, lo cual
        afectará los resultados arrojados por <strong>NOMINAPP</strong>. Los
        servicios de <strong>GRUPO QUINCENA</strong> son de medio y no de
        resultado por lo que <strong>GRUPO QUINCENA</strong> se acoge a las
        obligaciones que generan este tipo de servicios.
        <br />
        Los datos que se alojan en <strong>NOMINAPP</strong> son administrados
        bajo la cuenta y riesgo del usuario que los registra.{' '}
        <strong>GRUPO QUINCENA</strong> despliega las actividades que considera
        necesarias para mantener la información de los usuarios, segura y toma
        las precauciones que están a su alcance teniendo en cuenta el estado de
        la técnica, más no responde por actos mal intencionados e ilegales de
        terceros y los perjuicios que esto puede causar al usuario o a terceros.
        <br />
        <strong>GRUPO QUINCENA</strong> tampoco se responsabiliza por la
        información ingresada por cada usuario en su cuenta, ni por los
        resultados de liquidación de nómina, si la misma se ingresó de manera
        errada. Los cálculos que se realizan para la liquidación de nómina en{' '}
        <strong>NOMINAPP</strong> se realizan teniendo en cuenta la legislación
        colombiana, sin embargo cuando alguien hace uso de{' '}
        <strong>NOMINAPP</strong>, declara conocer la legislación laboral en
        Colombia y declara que realizará las verificaciones de los cálculos una
        vez utilice el servicio de <strong>NOMINAPP</strong>, liberando de esta
        forma a <strong>GRUPO QUINCENA</strong> de cualquier responsabilidad.
        <br />
        Cuando un usuario deba ingresar información que contenga datos
        personales de terceros, este se encuentra obligado, no solo por estos
        términos y condiciones, si no por disposición legal, a obtener la
        correspondiente autorización de tratamiento de datos personales para que
        estos sean ingresados en <strong>NOMINAPP</strong>.
        <br />
        Cuando un usuario se registra en <strong>NOMINAPP</strong> y usa la
        Plataforma se obliga a:
        <ol type="A">
          <li>No causar daño a otros usuarios del servicio.</li>
          <li>No usar el servicio con fines ilegales.</li>
          <li>
            No ingresar información que vulnere derechos de terceros y respecto
            de la cual no se encuentre autorizado.
          </li>
          <li>
            Ingresar información sensible que ya no es vigente o que pueda
            inducir a error a terceros o al Estado.
          </li>
          <li>
            No usar la Plataforma como medio para enviar cualquier forma de
            spam, correo no solicitado o conducta similar.
          </li>
          <li>
            Cumplir las medidas de seguridad física o informática que Grupo
            Quincena disponga, a fin de evitar o restringir el acceso a la
            Plataforma.
          </li>
          <li>
            No presentar documentos falsos durante su proceso de verificación o
            de terceros aduciendo que son de su propiedad.
          </li>
          <li>
            No copiar, modificar, alterar, adaptar, poner a disposición,
            traducir, realizar ingeniería inversa, descompilar o desensamblar
            cualquier parte de la Plataforma.
          </li>
          <li>No compartir los datos de acceso a su cuenta.</li>
        </ol>
        En general los usuarios se comprometen a no desarrollar ninguna
        actividad ilícita o que vaya en contra de{' '}
        <strong>GRUPO QUINCENA</strong>.
      </Typography>
      <br />

      <Typography variant="subtitle1">
        5. Interrupción y Terminación del Servicio
      </Typography>
      <br />

      <Typography>
        <strong>GRUPO QUINCENA</strong> se reserva el derecho de suspender la
        prestación de los servicios al usuario y de inhabilitar su acceso a{' '}
        <strong>NOMINAPP</strong> en caso de incumplimiento de pago o de los
        presentes términos y condiciones, sin que haya lugar a reembolsos.
        <br />
        Igualmente <strong>GRUPO QUINCENA</strong> se reserva el derecho de dar
        de baja la Plataforma en cualquier momento y tratará de dar previo aviso
        a sus usuarios.
        <br />
        Además de lo anterior, en cualquier momento y sin previo aviso Grupo
        Quincena puede eliminar la información de una cuenta de usuario
        inactivo, considerándose un usuario inactivo aquel que no ha hecho uso
        del programa en los últimos 6 meses. Si un usuario no quiere que su
        información sea borrada debe comunicarse con{' '}
        <OrganizationEmail variant="data" /> diciendo que estará inactivo pero
        que desea conservar su información. Cuando se termine la prestación de
        los servicios <strong>GRUPO QUINCENA</strong> no está obligado a llevar
        a cabo ningún tipo de proceso de migración, ni a efectuar un desarrollo
        para que la información pueda ser analizada o ingresada en otro
        software. Para solicitar el acceso a su información o la descarga de la
        misma puede comunicarse con <OrganizationEmail variant="data" />{' '}
        <strong>GRUPO QUINCENA S.A.S</strong>.
        <br />
        En condiciones normales, la información de los usuarios (aquellos que
        tienen suscripción vigente en <strong>NOMINAPP</strong>) se mantendrá
        guardada en su cuenta de usuario. Sin embargo es posible que por fallas
        en el sistema, la información se elimine en cualquier momento. Siempre
        que esto corresponda a una circunstancia de caso fortuito, fuerza mayor
        o sea producto del ataque de un tercero a los sistemas de información,
        <strong>GRUPO QUINCENA</strong> no será responsable por este hecho y
        esto no configura incumplimiento de sus obligaciones.
      </Typography>
      <br />

      <Typography variant="subtitle1">6. Medios de pago y precios</Typography>
      <br />

      <Typography>
        Para acceder a los servicios de <strong>NOMINAPP</strong> será necesario
        registrar la información del medio de pago autorizado por Grupo
        Quincena. Los medios de pago se encuentran descritos en el apartado de
        Preguntas frecuentes “¿Cómo se realiza el pago de la suscripción en{' '}
        Nominapp?”, el cúal se encuentra disponible en{' '}
        <MuiLink href="https://nominapp.com/" target="_blank">
          www.nominapp.com
        </MuiLink>
        .
        <br />
        Para tener información completa en cuanto al precio, cobros periódicos,
        cancelación de la suscripción y demás, los usuarios deben visitar el
        siguiente Link{' '}
        <MuiLink
          href="https://ayuda.nominapp.com/help/suscripci%C3%B3n-y-configuraci%C3%B3n-de-cuenta"
          target="_blank"
        >
          Suscripción y Pagos
        </MuiLink>
        .
        <br />
        Siempre que los medios de pago involucren plataformas externas o de
        terceros, el usuario deberá conocer, entender y aceptar los términos y
        condiciones de dichas plataformas y <strong>GRUPO QUINCENA</strong> no
        se hará responsable de la prestación del servicio de dichas plataformas.
        <br />
        Al momento de realizar un pago en internet, el usuario entiende y acepta
        que estos pagos pueden eventualmente presentar fallas de seguridad y aún
        cuando <strong>GRUPO QUINCENA</strong> ponga a disposición del usuario
        un sistema de conexión segura para la realización de transacciones, en
        ningún caso será responsable por los fallos en las comunicaciones de las
        entidades bancarias, de crédito o servicios de conexión a internet, así
        como tampoco de los daños causados al usuario con ocasión de una acción
        u omisión de dichas entidades o de terceros que mediante maniobras
        ilegales ocasionen daños en la operación, entendiendo que hay terceros
        involucrados y la operación no depende totalmente de{' '}
        <strong>GRUPO QUINCENA</strong>.
        <br />
        Cuando se realiza un pago, los usuarios declaran y garantizan que sus
        ingresos y en especial los fondos que utilizarán para realizar
        transacciones en <strong>NOMINAPP</strong> y a favor de{' '}
        <strong>GRUPO QUINCENA</strong>, provienen de actividades lícitas y que
        no le han entregado a un tercero información bancaria susceptible de ser
        utilizada para realizar compras o adquirir obligaciones a su nombre.
        <br />
        Los usuarios autorizan expresamente que en caso tal de que resulten
        envueltos en una actividad ilícita se termine el presente contrato y
        cualquier otro que se encuentre en curso, sin que esto genere ningún
        tipo de indemnización a favor del usuario y a reparar los daños que se
        le puedan causar a <strong>GRUPO QUINCENA</strong>.
        <br />
        En caso de la existencia de retenciones por parte de las entidades
        financieras o bancarias, gubernamentales, o de cualquier otra índole,
        estas deben ser asumidas por el usuario o titular del medio de pago.
        <br />
        Cuando un usuario haga el registro inicial desde Alegra, el pago de la
        suscripción de <strong>NOMINAPP</strong> se hará directamente desde
        Alegra. En lo relacionado con la información que se pueda recolectar en
        el proceso de pagos, <strong>GRUPO QUINCENA</strong> despliega todas las
        medidas que están a su alcance para mantener esta información protegida
        y segura contra usos inadecuados. Sin embargo,{' '}
        <strong>GRUPO QUINCENA</strong> no se hace responsable por el uso
        inadecuado, falsificación o suplantación de identidad del titular de la
        cuenta o de las tarjetas de crédito o débito, que los usuarios hagan a
        través de <strong>NOMINAPP</strong>, salvo que la ley expresamente
        ordene lo contrario. Corresponde a cada usuario la verificación de sus
        datos personales, y <strong>GRUPO QUINCENA</strong> asume que la
        información suministrada está autorizada por el titular. No obstante,{' '}
        <strong>GRUPO QUINCENA</strong> se reserva el derecho a llevar a cabo
        activiades de verificación de identidad. <strong>GRUPO QUINCENA</strong>{' '}
        podrá hacer uso de sistemas de facturación electrónica cuando sea
        requerida para hacerlo de acuerdo con la legislación vigente.
      </Typography>
      <br />

      <Typography variant="subtitle1">7. Plataformas de terceros</Typography>
      <br />

      <Typography>
        Para mejorar la prestación del servicio y brindar una mejor experiencia
        a los usuarios, es posible que <strong>GRUPO QUINCENA</strong> realice
        integraciones de <strong>NOMINAPP</strong> con plataformas de terceros.
        <br />
        En estos casos el usuario debe tener en cuenta que{' '}
        <strong>NOMINAPP</strong> no responderá por los servicios prestados por
        dichos terceros, ni asume ningún tipo de obligación solidaria con ellos.{' '}
        <strong>GRUPO QUINCENA</strong> no es parte en las transacciones que se
        celebren entre el usuario y el tercero y por lo tanto no asume
        responsabilidad por el cumplimiento o incumplimiento de las obligaciones
        pactadas entre ellos.
        <br />
        <strong>7.1</strong> <strong>GRUPO QUINCENA</strong> cuenta con una
        alianza de servicios con Soluciones Alegra S.A.S. propietaria de una
        plataforma denominada Alegra, que es un sistema de facturación,
        administración y contabilidad para Pymes. Cuando el usuario haga el
        registro inicial desde Alegra, el soporte estará a cargo de Alegra y se
        atenderá los canales ofrecidos por Soluciones Alegra S.A.S.{' '}
        <strong>NOMINAPP</strong> no responderá por los servicios prestados por
        Alegra, ni asume ningún tipo de obligación solidaria con este último,
        salvo que expresamente se indique otra cosa o la ley establezca lo
        contrario. <strong>GRUPO QUINCENA</strong> se reserva el derecho de
        terminar esta alianza de prestación de servicios en cualquier momento,
        sin previo aviso y esto no constituye ningún tipo de incumplimiento en
        sus obligaciones.
        <br />
        <strong>7.2</strong> <strong>GRUPO QUINCENA</strong> cuenta con una
        alianza de servicios con Aportes en Línea, una empresa enfocada en el
        pago de los aportes a seguridad social junto con la prestación de otros
        servicios relacionados. Esta alianza consiste en una integración de la
        Plataforma de Aportes en Línea con <strong>NOMINAPP</strong>,
        brindándole al usuario la posibilidad de realizar el pago de los aportes
        a seguridad social ingresando a la plataforma de Aportes en Linea desde{' '}
        <strong>NOMINAPP</strong>.
        <br />
        Es importante tener en cuenta que Grupo Quicena no es un operador
        encargado de realizar la liquidación de los aportes a seguridad social,
        ni de gestionar su pago y por lo tanto no responderá por las faltas que
        se cometan en la prestación de los servicios de Aportes en Línea, ni
        asume ningún tipo de obligación solidaria con ellos, salvo que
        expresamente se indique otra cosa o la ley establezca lo contrario.
        <br />
        En todo caso es responsabilidad de los usuarios revisar y aceptar bajo
        su propia cuenta y riesgo los términos y condiciones de los servicios
        prestados por Aportes en Línea, ya que para poder utilizar la Plataforma
        de Aportes en Línea y gestionar el pago de aportes en seguridad social
        desde <strong>NOMINAPP</strong>, el Usuario deberá estar previamente
        registrado y tener una cuenta activa en la plataforma de Aportes en
        Línea.
        <br />
        El soporte para esta operación estará a cargo de Aportes en Línea y se
        atenderá los canales ofrecidos por Aportes en Línea.
        <br />
        <strong>GRUPO QUINCENA</strong> se reserva el derecho de eliminar la
        integración con Aportes en Línea en cualquier momento, sin previo aviso
        y esto no constituye ningún tipo de incumplimiento en sus obligaciones.
        <br />
        Igualmente <strong>GRUPO QUINCENA</strong> puede incluir en{' '}
        <strong>NOMINAPP</strong> enlaces a sitios web de terceros, sin que
        exista una alianza o integración de servicios.
        <br />
        El usuario reconoce que <strong>GRUPO QUINCENA</strong> no tiene control
        sobre tales Sitios y reconoce y acepta que{' '}
        <strong>GRUPO QUINCENA</strong> no es responsable por la disponibilidad
        de estos ni por los servicios, contenidos, anuncios, productos o
        cualquier material disponible en ellos.
        <br />
        Estos Sitios de terceros se rigen por sus correspondientes Términos y
        Condiciones de Uso y Política de Privacidad y el usuario debe conocer y
        aceptar tales documentos antes de usarlos, bajo su propia cuenta y
        riesgo.
      </Typography>
      <br />

      <Typography variant="subtitle1">8. Propiedad intelectual</Typography>
      <br />

      <Typography>
        Todo el material informático, gráfico, publicitario, fotográfico, de
        multimedia, audiovisual y/o de diseño, así como todos los contenidos,
        textos y bases de datos puestos a su disposición en este Sitio son de
        propiedad exclusiva de <strong>GRUPO QUINCENA</strong> o en su defecto
        este ha sido licenciado por su titular con el fin de exhibir los
        productos y realizar actividades publicitarias.
        <br />
        Todos los contenidos de la Plataforma están protegidos por las normas
        sobre Derechos de Autor y por todas las normas nacionales e
        internacionales que le sean aplicables. Queda prohibido todo acto de
        copia, reproducción, modificación, creación de trabajos derivados, venta
        o distribución, exhibición de los Contenidos, incluyendo, pero sin
        limitarse a: medios electrónicos, mecánicos, de fotocopiado, de
        grabación o de cualquier otra índole, a menos que expresamente se
        establezca lo contrario. Estos Términos y Condiciones o cualquier otra
        transacción que se genere en la Plataforma NO confieren derechos,
        licencias y/o autorizaciones relacionados con elementos que constituyan
        propiedad intelectual. La exhibición de marcas, logos, piezas gráficas y
        cualquier otro similar tiene como único fin, la comunicación de los
        servicios y productos comercializados por terceros a través de la
        Plataforma.
        <br />
        Cualquier uso no autorizado de los Contenidos constituirá una violación
        a los presentes Términos y Condiciones y a las normas vigentes sobre
        derechos de autor. El usuario se obliga por los presentes Términos y
        Condiciones a no modificar, ni tratar de modificar, alterar o dañar el
        software del Sitio de ninguna manera, ni intentar ingresar a sus bases
        de datos y sistemas de información.
      </Typography>
      <br />

      <Typography variant="subtitle1">9. Disponibilidad</Typography>
      <br />

      <Typography>
        <strong>GRUPO QUINCENA</strong> pone sus mejores esfuerzos para mantener
        la disponibilidad y funcionalidad de la <strong>NOMINAPP</strong> la
        mayor parte del tiempo. Sin embargo, la operatividad de la Plataforma
        depende de diversos factores, tales como redes de comunicación,
        software, hardware, proveedores de servicios, entre otros. Por ello
        Grupo Quicena no garantiza y en todo caso no puede garantizar, que{' '}
        <strong>NOMINAPP</strong> funcionará en todo momento sin interrupción, o
        que sea inmune de accesos no autorizados o libre de errores.
        <br />
        Por esto, si no se puede acceder a la Plataforma de manera temporal, ya
        sea por que el usuario no tiene internet o por que la Plataforma
        presenta fallas o está en mantenimiento, esto no implica en ningún
        momento incumplimiento de la prestación del servicio prestado por parte
        de <strong>GRUPO QUINCENA</strong>, ni será responsable de los
        perjuicios que esto le pueda causar al usuario.
        <br />
        Grupo Quicena podrá sin previo suspender la Plataforma para realizar
        mantenimientos preventivos o correctivos.
        <br />
        <strong>GRUPO QUINCENA</strong> tampoco responderá por los perjuicios
        que se puedan causar al usuario en sus dispositivos electrónicos cuando
        examine la Plataforma, toda vez que <strong>NOMINAPP</strong> podría ser
        víctima de acceso no autorizados o ataques de terceros, y los usuarios
        deben gestionar todas las medidas de protección de cualquier persona
        diligente que navega en internet, de acuerdo con el estado de la
        técnica.
      </Typography>
      <br />

      <Typography variant="subtitle1">10. Cesión</Typography>
      <br />

      <Typography>
        <strong>GRUPO QUINCENA</strong> podrá ceder y transferir estos términos
        y condiciones y puede delegar cualquiera de sus obligaciones a su
        exclusivo criterio y sin restricción alguna. Sin perjuicio de lo
        anterior, sus sucesores y cesionarios autorizados deberán seguir
        cumpliendo con sus obligaciones descritas en estos términos y
        condiciones.
      </Typography>
      <br />

      <Typography variant="subtitle1">11. Legislación Aplicable</Typography>
      <br />

      <Typography>
        Cualquier reclamación relativa a <strong>NOMINAPP</strong> o a su uso se
        regirá por las disposiciones legales Colombianas.
        <br />
        Si cualquier disposición en estos Términos y Condiciones resulta nula, o
        ineficaz, dicha disposición se considerará separable de este documento y
        no afectará la validez y exigibilidad de las demás disposiciones.
      </Typography>
      <br />

      <Typography variant="subtitle1">
        12. Peticiones, quejas, reclamos y felicitaciones
      </Typography>
      <br />

      <Typography>
        Cuando un usuario desee comunicarse con Grupo Quicena lo podrá hacer
        escribiendo a <OrganizationEmail variant="hello" />.
        <br />
        Para poder gestionarla de la mejor manera, el usuario deberá acompañar
        la comunicación de la siguiente información:
        <ol>
          <li>
            Nombre completo del titular de la suscripción y su representante
          </li>
          <li>Número de identificación</li>
          <li>Medio por el cual quiere recibir respuesta</li>
          <li>Copia de su documento de identidad</li>
          <li>
            Autorización para el tratamiento de sus datos personales para la
            gestión de la PQR
          </li>
          <li>Breve explicación de su petición, queja, reclamo o sugerencia</li>
          <li>Las pruebas o documentos que pretenda hacer valer</li>
          <li>Correo electrónico con el cual realizó la suscripción</li>
        </ol>
        La misma será contestada dentro de los 15 días hábiles siguientes al día
        de su recepción. Tratándose de un derecho de petición de información,
        dentro de los 10 días hábiles siguientes a su recepción.
        <br />
        Esta Plataforma es operada por <strong>GRUPO QUINCENA S.A.S</strong>.
        Persona jurídica identificada con NIT 900.926.147, con dirección en la
        calle 4 # 17- 115 interior 703, Medellín, Antioquia, Colombia, teléfono
        +57 3041458452.
        <br />
        <br />
        Fecha de entrada en vigencia: <strong>10 de diciembre de 2020</strong>.
      </Typography>
      <br />

      <Typography variant="h6" gutterBotom>
        POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES
      </Typography>
      <br />
      <Typography>
        Esta es la política de tratamiento de datos personales de{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> la cual se establece en
        cumplimiento de los deberes de la ley 1582 de 2012 y sus decretos
        reglamentarios y aplica para la información que contenga datos
        personales que se registre en <strong>NOMINAPP</strong>.
      </Typography>
      <br />

      <Typography variant="subtitle1">1. Alcance de la Política</Typography>
      <br />

      <Typography>
        Esta Política aplica para todos los datos personales que recolecte Grupo
        Quincena S.A.S dentro de <strong>NOMINAPP</strong>. Esta contiene las
        obligaciones del Responsable del tratamiento de los datos, los derechos
        de los titulares de la información y cómo ejercerlos. Por lo tanto, es
        de obligatorio cumplimiento para todos los colaboradores de Grupo
        Quincena S.A.S. ya sea en calidad de empleados, contratistas o partners.
      </Typography>
      <br />

      <Typography variant="subtitle1">
        2. Responsable del Tratamiento
      </Typography>
      <br />

      <Typography>
        <strong>GRUPO QUINCENA S.A.S.</strong> sociedad comercial legalmente
        constituida identificada con el NIT 900.926.147, con domicilio principal
        en calle 4 # 17- 115 interior 703, Medellín, Antioquia, Colombia. Página
        www.nominapp.com Teléfono +57 3041458452 correo electrónico para
        notificaciones relacionadas con datos personales{' '}
        <OrganizationEmail variant="data" />{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> será quien actúe como Responsable
        del Tratamiento.
      </Typography>
      <br />

      <Typography variant="subtitle1">
        3. Datos que recolecta <strong>GRUPO QUINCENA</strong> en{' '}
        <strong>NOMINAPP</strong>
      </Typography>
      <br />

      <Typography>
        Dentro del ejercicio ordinario de las actividades de{' '}
        <strong>GRUPO QUINCENA</strong> a través de <strong>NOMINAPP</strong>,
        esta recolecta Datos Personales públicos, privados y semi privados como
        nombre, correo electrónico, dirección de notificaciones, télefono,
        actividad laboral, entre otros.
        <br />
        Por regla general <strong>GRUPO QUINCENA</strong> no solicitará Datos
        Personales Sensibles. Sin embargo es importante manifestar que:
        <ol type="A">
          <li>
            Los Datos Personales Sensibles son aquellos que afectan la intimidad
            del Titular o cuyo uso indebido puede generar su discriminación,
            tales como aquellos que revelen el origen racial o étnico, la
            orientación política, las convicciones religiosas o filosóficas, la
            pertenencia a sindicatos, organizaciones sociales, de derechos
            humanos o que promueva intereses de cualquier partido político o que
            garanticen los derechos y garantías de partidos políticos de
            oposición así como los datos relativos a la salud, a la vida sexual
            y los datos biométricos.
          </li>
          <li>
            <strong>GRUPO QUINCENA</strong> no condiciona ninguna actividad
            comercial a que el Titular suministre datos personales sensibles.
          </li>
          <li>
            <strong>GRUPO QUINCENA</strong> únicamente recolectará Datos
            Personales Sensibles cuando se encuentre actuando bajo un deber
            legal o cuando sea necesario la recolección de dichos datos por
            algún motivo objetivo y sustentado y cumplirá con las cargas que
            impone la ley para el tratamiento de dicha información.{' '}
          </li>
        </ol>
        Cualquier persona que pretenda ingresar datos personales de terceros en{' '}
        <strong>NOMINAPP</strong>, para acceder a los servicios de gestión de
        nómina, se encuentra obligado a obtener una autorización expresa, previa
        y verificable antes de ingresar sus datos en <strong>NOMINAPP</strong>.
        En ella deberá indicarle al titular de la información la manera en la
        que serán tratados sus datos y los derechos que le asisten, junto con
        todos los demás requisitos que exija la legislación vigente.
      </Typography>
      <br />

      <Typography variant="subtitle1">4. Tratamiento y finalidad</Typography>
      <br />

      <Typography>
        <strong>GRUPO QUINCENA</strong> tratará los datos personales de acuerdo
        a la autorización otorgada por el Titular de la Información, las
        disposiciones legales y las órdenes de autoridades administrativas y
        judiciales que tengan competencia para actuar en la materia.
        <br />
        El Tratamiento, esto es la Recolección, Almacenamiento, uso, circulación
        y supresión de datos se realizará con ayuda de medios automatizados y
        físicos que en todo caso protejan la información de accesos no
        autorizados.
        <br />
        Con excepción de aquellos datos que pueden ser tratados sin autorización
        del Titular, <strong>GRUPO QUINCENA</strong> solicitará autorización
        expresa para recolectar datos personales. Los datos serán obtenidos
        directamente del titular de la información a través de diferentes
        actividades. La autorización corresponderá a un ejercicio libre e
        informado por parte del Titular de la información.
        <br />
        Es importante tener en cuenta que: <strong>A)</strong> La información
        podrá ser almacenada en registros físicos o digitales, dentro y fuera de
        Colombia. <strong>GRUPO QUINCENA</strong> dispondrá de medidas técnicas
        y administrativas que le permitan cumplir con sus deberes como
        Responsable de la Información. <strong>B)</strong>
        Los datos circularán de manera restringida dentro de{' '}
        <strong>GRUPO QUINCENA</strong>, con autoridades administrativas o
        judiciales cuando éstas se encuentren ejerciendo sus funciones legales o
        por orden judicial, y con terceros siempre que cuente con los debidos
        contratos de transmisión o transferencia de datos, o con la autorización
        expresa del Titular de la información. <strong>C)</strong> Los datos
        serán eliminados de las bases de datos de{' '}
        <strong>GRUPO QUINCENA</strong> cuando el Titular lo solicite y aquello
        sea procedente, cuando ya no exista una finalidad legítima para
        conservar los datos y por orden de autoridad competente.{' '}
        <strong>D)</strong> Los datos personales, salvo la información pública,
        no podrán estar disponibles en Internet u otros medios de divulgación o
        comunicación masiva, salvo que el acceso sea técnicamente controlable
        para brindar un conocimiento restringido sólo a los Titulares o terceros
        autorizados conforme a la ley.<strong>E)</strong> El personal que
        interviene en el tratamiento datos personales está obligado a garantizar
        la reserva de la información, inclusive después de finalizada su
        relación con alguna de las labores que comprende el tratamiento,
        pudiendo sólo realizar suministro o comunicación de datos personales
        cuando ello corresponda al desarrollo de las actividades autorizadas en
        la ley.
        <br />
        Los datos serán tratados con la finalidad de ejecutar labores
        comerciales y administrativas que permitan prestar los servicios de{' '}
        <strong>NOMINAPP</strong> y en específico:
        <ol type="A">
          <li>
            Almacenar los datos y clasificarlos dentro de sus bases de datos,
            identificándose como Clientes, prospectos, entre otros.
          </li>
          <li>
            Compartir información sobre los productos y servicios que
            comercializa <strong>GRUPO QUINCENA</strong> en{' '}
            <strong>NOMINAPP</strong>.
          </li>
          <li>
            Gestionar trámites de los Titulares de la información como
            solicitudes, quejas y reclamos.
          </li>
          <li>Efectuar encuestas de satisfacción.</li>
          <li>
            Realizar invitaciones a eventos y ofrecer nuevos productos y
            servicios.
          </li>
          <li>
            Suministrar información de contacto a la fuerza comercial y/o red de
            distribución, telemercadeo, investigación de mercados y cualquier
            tercero con el cual La Empresa tenga un vínculo contractual para el
            desarrollo de actividades de ese tipo (investigación de mercados y
            telemercadeo, etc) para la ejecución de las mismas.
          </li>
          <li>
            Contactar al Titular a través de medios telefónicos para realizar
            encuestas, estudios y/o confirmación de datos personales necesarios
            para la ejecución de una relación contractual.
          </li>
          <li>
            Contactar al Titular a través de medios electrónicos – SMS o chat
            para el envío de noticias relacionadas con campañas de fidelización
            o mejora de servicio.
          </li>
          <li>
            Contactar al Titular a través de correo electrónico o medio
            telefónico para el envío de extractos, estados de cuenta o facturas
            en relación con las obligaciones derivadas del contrato celebrado
            entre Las Partes (<strong>GRUPO QUINCENA</strong> y el Titular).
          </li>
          <li>
            Dar cumplimiento a las obligaciones contraídas por{' '}
            <strong>GRUPO QUINCENA</strong> con el Titular de la Información.
          </li>
          <li>
            Transmitir los datos personales fuera del país a terceros con los
            cuales <strong>GRUPO QUINCENA</strong> haya suscrito un contrato de
            procesamiento de datos y sea necesario entregársela para el
            cumplimiento del objeto contractual.
          </li>
          <li>
            Realizar en forma directa o a través de terceros gestión de cartera.
          </li>
          <li>
            Controlar y prevenir el fraude mediante la verificación de reportes
            en listas vinculantes sobre Lavado de Activos.
          </li>
          <li>
            Realizar el procesamiento y entrega de los productos comercializados
            por <strong>GRUPO QUINCENA</strong> y para enviar la información que
            al respecto se haya solicitado.
          </li>
          <li>
            Transmitir los datos a la autoridad competente para cumplir con las
            obligaciones contables y tributarias de{' '}
            <strong>GRUPO QUINCENA</strong>, en este caso a la Dirección de
            Impuestos y Aduanas Nacionales DIAN para las cuestiones relacionadas
            con la facturación electrónica, así como al proveedor tecnológico
            para que realice la factura electrónica correspondiente.
          </li>
          <li>Análisis estadísticos e investigación de mercados.</li>
          <li>
            Llevar registro y control de la información, orientada a la
            administración de <strong>NOMINAPP</strong> y{' '}
            <strong>GRUPO QUINCENA</strong>.
          </li>
          <li>
            Compartir la información con terceros en virtud de contratos de
            transmisión de datos o transferencia cuando exista una finalidad
            para ello, garantizando en todo momento los derechos de los
            titulares de la información.
          </li>
          <li>
            Revisar los reportes que puedan existir sobre el titular de la
            información en las centrales de Riesgo crediticio.{' '}
          </li>
          <li>
            Utilizar la información recolectada para la prestación de sus
            servicios.
          </li>
          <li>
            Reportar ante centrales de riesgo Crediticio el incumplimiento de
            las obligaciones entre el Titular de la información y Grupo
            Quincena.
          </li>
          <li>
            Compartir, transmitir, transferir información con las siguientes
            personas: con proveedores que ofrezcan o suministren bienes y
            servicios a <strong>GRUPO QUINCENA</strong>, entidades con las
            cuales <strong>GRUPO QUINCENA</strong> tenga celebrados contratos de
            uso de red, aseguradoras con las cuales{' '}
            <strong>GRUPO QUINCENA</strong> tengan contratadas pólizas de seguro
            y sea necesario entregar la información de terceros o con las cuales
            el titular de la información haya decidido contratarlas, personas
            que en alianza con <strong>GRUPO QUINCENA</strong>, ofrezcan
            beneficios, productos o servicios propios o de{' '}
            <strong>GRUPO QUINCENA</strong>, personas que realicen la promoción
            de los productos y servicios de <strong>GRUPO QUINCENA</strong>, o
            personas que tengan cualquier alianza de servicios con{' '}
            <strong>GRUPO QUINCENA</strong>.
          </li>
        </ol>
      </Typography>
      <br />

      <Typography variant="subtitle1">
        5. Derechos del titular de la información
      </Typography>
      <br />

      <Typography>
        Además de los derechos reconocidos por la ley y la jurisprudencia, los
        titulares de la información tendrán derecho a:
        <br />
        <ol type="A">
          <li>
            Acceder de forma gratuita a los datos proporcionados que hayan sido
            objeto de tratamiento.
          </li>
          <li>
            Conocer, actualizar y rectificar su información frente a datos
            parciales, inexactos, incompletos, fraccionados, que induzcan a
            error, o aquellos cuyo tratamiento esté prohibido o no haya sido
            autorizado.
          </li>
          <li>Solicitar prueba de la autorización otorgada.</li>
          <li>
            Presentar ante la Superintendencia de Industria y Comercio (SIC)
            quejas por infracciones a lo dispuesto en la normatividad vigente.{' '}
          </li>
          <li>
            Revocar la autorización y/o solicitar la supresión del dato, siempre
            que no exista un deber legal o contractual que impida eliminarlos.
          </li>
          <li>
            Abstenerse de responder las preguntas sobre datos sensibles. Tendrá
            carácter facultativo las respuestas que versen sobre datos sensibles
            o sobre datos de las niñas y niños y adolescentes.
          </li>
        </ol>
        El titular de la información puede ejercer sus derechos a conocer,
        actualizar, rectificar y suprimir el dato y revocar la autorización es
        el equipo de Gestión de Datos de <strong>NOMINAPP</strong> en{' '}
        <OrganizationEmail variant="data" />{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> o comunicarse al teléfono
        3041458452.
        <br />
        El titular deberá presentar su comunicación de manera verbal o escrita
        aportando:
        <ol type="A">
          <li>Su nombre completo</li>
          <li>Número de identificación y tipo de documento</li>
          <li>Domicilio / Dirección para notificaciones</li>
          <li>Teléfono de contacto</li>
          <li>Dirección de Correo electrónico</li>
          <li>Copia del documento de identidad</li>
          <li>Descripción del derecho que pretende ejercer</li>
          <li>Pruebas que pretende hacer valer</li>
        </ol>
        Los casos radicados en días no - hábiles de acuerdo con el calendario
        colombiano se entenderán radicados el siguiente día hábil.
        <br />
        Cuando se trate de una <strong>consulta</strong> esta será atendida en
        un término máximo de diez (10) días hábiles contados a partir de la
        fecha de recibo de esta. Cuando no fuere posible atender la consulta
        dentro de dicho término, se informará al interesado, expresando los
        motivos de la demora y señalando la fecha en que se atenderá su
        consulta, la cual en ningún caso podrá superar los cinco (5) días
        hábiles siguientes al vencimiento del primer término. Cuando se trate de{' '}
        <strong>corrección, actualización, supresión o reclamo</strong>, el
        término máximo previsto por la ley para resolver su reclamación es de
        quince (15) días hábiles, contado a partir del día siguiente a la fecha
        de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho
        término, La Empresa informará al interesado los motivos de la demora y
        la fecha en que se atenderá su reclamo, la cual en ningún caso podrá
        superar los ocho (8) días hábiles siguientes al vencimiento del primer
        término. Si el reclamo resulta incompleto, se requerirá al interesado
        dentro de los cinco (5) días siguientes a la recepción del reclamo para
        que subsane las fallas. Transcurridos dos (2) meses desde la fecha del
        requerimiento, sin que el solicitante presente la información requerida,
        se entenderá que ha desistido del reclamo. Una vez cumplidos los
        términos señalados por la Ley 1581 de 2012 y las demás normas que la
        reglamenten o complementen, el Titular al que se deniegue, total o
        parcialmente, el ejercicio de los derechos de acceso, actualización,
        rectificación, supresión y revocación, podrá poner su caso en
        conocimiento de la Superintendencia de Industria y Comercio –Delegatura
        para la Protección de Datos Personales-.
      </Typography>
      <br />

      <Typography variant="subtitle1">6. Vigencia</Typography>
      <br />

      <Typography>
        La presente Política para el Tratamiento de Datos Personales rige a
        partir de <strong>10 de diciembre de 2020</strong>
        <br />
        La vigencia de las bases de datos será el tiempo razonable y necesario
        para cumplir las finalidades para las cuales fueron recolectados. Sin
        embargo, los datos personales proporcionados se conservarán mientras se
        mantenga la relación contractual con el Titular de la información y aún
        después mientras no se solicite su supresión por el interesado y siempre
        que no exista un deber legal de conservarlos o una finalidad de archivo
        por la naturaleza de la relación que existió entre las partes.
        <br />
        Esta Política podrá ser modificada de manera unilateral por parte de{' '}
        <strong>GRUPO QUINCENA</strong> en cumplimiento de algún deber legal o
        reglamentario o cuando sea necesario por la modificación de las
        condiciones de tratamiento y recolección. En dicha eventualidad se
        informará y publicará, para que los titulares de la información se
        manifiesten y ejerzan sus derechos, si estos no se manifiestan en un
        plazo de veinte (20) días hábiles siguientes, se entenderá que aceptan.
        Sin embargo, tal como lo establece la ley, cuando dicha reforma afecte
        aspectos sustanciales se obtendrá nueva autorización del Titular de la
        información.
      </Typography>
      <br />
      <Typography variant="h6">
        AUTORIZACIÓN PARA EL TRATAMIENTO DE DATO PERSONALES
      </Typography>
      <br />
      <Typography>
        Certifico que tengo la autorización legal para hacer el registro de la
        información aquí requerida, la cual se presume verídica, a título y
        cuenta de la persona jurídica o natural que represento. En consecuencia,
        autorizo a <strong>GRUPO QUINCENA S.A.S</strong> y a aquellas entidades
        que pertenezcan o lleguen a pertenecer a su Grupo Empresarial de acuerdo
        con la ley, domiciliadas en Colombia y/o en el exterior, en forma
        permanente para que realice las siguientes actividades:
        <br />
        <br />
        <strong>I.</strong> Solicite, consulte, comparta, informe, reporte,
        procese, modifique, actualice, aclare, retiren o divulgue, ante
        entidades de consulta de bases de datos u Operadores de Información y
        Riesgo crediticio, o ante cualquier otra entidad que maneje o administre
        bases de datos con los fines legalmente definidos para este tipo de
        entidades, todo lo referente a mi información, modificación y extinción
        de mis derechos y obligaciones originados en virtud de cualquier
        contrato celebrado u operación realizada o que llegare a celebrar o
        realizar <strong>GRUPO QUINCENA</strong>.
        <br />
        <strong>II.</strong> Consulte, solicite o verifique información sobre
        mis datos de ubicación o contacto, los bienes o derechos que poseo o
        llegare a poseer y que reposaren en las bases de datos de entidades
        públicas o privadas, o que conozcan personas naturales o jurídicas, o se
        encuentren en buscadores públicos, redes sociales o publicaciones.
        <br />
        <strong>III.</strong> Contacte a los usuarios inscritos a través del
        envío de mensajes a través de correo electrónico y/o redes sociales a
        las cuales esté inscrito.
        <br />
        <strong>IV.</strong> Conserve la información y la documentación incluso
        aún, cuando no se haya perfeccionado una relación contractual o después
        de finalizada la misma con <strong>GRUPO QUINCENA</strong>. Igualmente,
        para recolectarla, actualizarla, procesarla y eliminarla.
        <br />
        <strong>V.</strong> <strong>GRUPO QUINCENA</strong> comparta, remita mi
        información a sus partners, especialmente entidades financieras y a
        operadores de información, acceda a mi información o documentación
        consignada o anexa en las solicitudes de vinculación, actualizaciones,
        en los diferentes documentos, operaciones y/o sistemas de información,
        así como información y/o documentación relacionada con los productos y/o
        servicios que poseo con <strong>GRUPO QUINCENA</strong>.
        <br />
        <strong>VI.</strong> Compile y remita a las autoridades fiscales, y
        reguladores financieros, nacionales o extranjeros, la información
        relacionada con la titularidad de los productos y servicios contratados
        y los que serán contratados en el futuro, datos de contacto, movimientos
        y saldos, y cualquier otra información que sea solicitada por normas
        nacionales o extranjeras de prevención de evasión fiscal o de lavado de
        activos y financiación del terrorismo, así como aquellas que las
        regulen, modifiquen o sustituyan.
        <br />
        <strong>VII.</strong> Comparta, transmita, transfiera y divulgue mi
        información y documentación con las siguientes personas: (a) quienes,
        como proveedores, ofrezcan o suministren bienes y servicios a GRUPO
        QUINCENA, (b) entidades con las cuales tengan celebrados contratos de
        uso de red, (c) la(s) aseguradora(s) con la(s) cual(es){' '}
        <strong>GRUPO QUINCENA</strong> tengan contratadas pólizas o con las
        cuales yo haya decidido contratarlas, los intermediarios de seguros o
        reaseguros; (d) personas, que en alianza con{' '}
        <strong>GRUPO QUINCENA</strong>, ofrezcan beneficios, productos o
        servicios propios o de <strong>GRUPO QUINCENA</strong>, (e) personas que
        realicen la promoción de los productos y servicios de{' '}
        <strong>GRUPO QUINCENA</strong>. Todo lo anterior, con el fin de que{' '}
        <strong>GRUPO QUINCENA</strong> utilice mis datos, a partir de la
        recepción de estos y hasta que expresamente revoque esta autorización,
        para:
        <ol>
          <li>
            Ofrecerme y prestarme sus productos o servicios a través de
            cualquier medio o canal.
          </li>
          <li>
            Mantener actualizada mi información ante Operadores de Información y
            Riesgo o ante cualquier otra entidad que maneje o administre bases
            de datos.
          </li>
          <li>
            Efectuar análisis e investigaciones comerciales, estadísticas, de
            riesgos, de mercado, incluyendo contactarme para estos efectos.
          </li>
        </ol>
        Así mismo, con el fin de que a las personas que aquí he autorizado para
        que se les entreguen mis datos, me ofrezcan beneficios o servicios
        propios de estas personas o de <strong>GRUPO QUINCENA</strong>.
        <br />
        Manifiesto que todos los datos aquí consignados son ciertos, que la
        información que adjunto es veraz y verificable; por ello, autorizo su
        verificación ante cualquier persona natural o jurídica, privada o
        pública, nacional o extranjera desde ahora y mientras subsista alguna
        relación comercial con <strong>GRUPO QUINCENA</strong>, y me comprometo
        actualizar o confirmar la información y/o documentación al menos una vez
        al año o cada vez que un producto o servicio lo amerite, estando
        facultada GRUPO QUINCENA para terminar cualquiera de los contratos con
        ella celebrados cuando no cumpla con este compromiso.
        <br />
        Manifiesto que entiendo que quienes utilicen <strong>
          NOMINAPP
        </strong>{' '}
        como administradores de nómina, es decir quienes utilicen el software
        para pagar nómina de sus subordinados, deben de contar con la
        autorización previa y expresa de estos últimos para que LOS USUARIOS
        compartan sus datos en la plataforma. Por esto los usuarios que ingresan
        datos personales de terceros, aseguran en este sentido que dentro de los
        CONTRATOS con sus subordinados se cuenta con la autorización de estos
        para el tratamiento de datos. <strong>GRUPO QUINCENA</strong> no se hace
        responsable de cualquier reclamación que los subordinados puedan
        realizar en este sentido, y serán LOS USUARIOS quienes asuman la
        responsabilidad.
        <br />
        Igualmente me obligo a informar a <strong>GRUPO QUINCENA</strong>{' '}
        cualquier cambio en la información relacionada con los datos de contacto
        dentro de los 20 días siguientes a la fecha en que se produzca el
        cambio.
        <br />
        <strong>GRUPO QUINCENA</strong> me ha informado que puedo contactarme en
        cualquier momento a través del siguiente correo electrónico{' '}
        <OrganizationEmail variant="data" />{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong> o al celular 3041458452 y que
        tengo el derecho a conocer, actualizar y rectificar mis datos personales
        y en los casos en que sea procedente a suprimirlos o revocar la
        autorización otorgada para su tratamiento, abstenerme de contestar
        preguntas sobre datos sensibles y datos de niños, niñas y adolescentes y
        presentar quejas ante la Superintendencia de Industria y Comercio y que
        conozco el procedimiento para hacerlo ya que he leído y entendido en su
        totalidad de la Política de Tratamiento de datos personales de{' '}
        <strong>GRUPO QUINCENA S.A.S.</strong>
      </Typography>
    </Modal>
  )
}

export default TermsConditionsAndPoliticsModal
