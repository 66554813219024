import { getWorkerId } from 'utils/worker'

import http, { getApiURL } from '../httpService'

export const getWorkerProfile = (workerId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getWorkerPayslip = (
  payrollId,
  { payslip_type: payslipType = 'payroll', payroll_range: payrollRange } = {}
) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${getWorkerId()}/payslips/${payrollId}`,
    withoutCompany: true,
    searchParams: { payslip_type: payslipType, payroll_range: payrollRange },
  })

  return http.getV2(url)
}

export const getPaymentsHistory = (
  workerId,
  { page, file_format: fileFormat = 'json', async = true } = {}
) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/payments_history`,
    withoutCompany: true,
    searchParams: { file_format: fileFormat, page, async },
  })

  return http.getV2(url)
}

export const getWorkerLoans = (workerId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/loans`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getWorkerRecurrentConcepts = (workerId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/recurrent_concepts`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getWorkerProfileOptions = (workerId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/worker_options`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export default {
  getPaymentsHistory,
  getWorkerProfile,
  getWorkerLoans,
  getWorkerRecurrentConcepts,
  getWorkerPayslip,
}
