import { useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'
import LoadingModal from 'components/UI/Loading/LoadingModal'

import useWorkerService from 'utils/hooks/worker/workerService'

import { getOptions } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    },
  },
  locationContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(2),
  },
  informationIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
}))

const PersonalInformationStep = ({ setWorker }) => {
  const classes = useStyles()
  const { state } = useLocation()
  const { values } = useFormikContext()
  const workerId = values?.worker?.id || state?.workerId
  const workerQueryKey = ['getWorkerById', workerId]
  const { workerQuery } = useWorkerService({
    serviceParams: {
      queryKey: ['getAllWorkers'],
      searchParams: { filter: 'affiliations' },
    },
  })
  const workers = workerQuery.data?.workers || []

  const { workerQuery: selectWorkerQuery } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId),
      onSuccess: ({ data }) => {
        setWorker(data)
      },
    },
  })

  return (
    <>
      {selectWorkerQuery.isLoading ? <LoadingModal /> : null}
      <div className={classes.container}>
        <FormField
          name="worker"
          label="Buscar persona"
          variant="autocomplete"
          options={getOptions(workers)}
          tooltipContent="Para realizar una afiliación debes de iniciar una nueva contratación."
          tooltipIconClassName={classes.informationIcon}
          placeholder="Buscar nombre de la persona"
        />
        <br />
        <DocumentTypeField />
        <FormField name="id_number" label="Número de documento" />
        <FormField
          name="birthday"
          label="Fecha de nacimiento"
          variant="datepicker"
        />
        <FormField name="email" label="Correo electrónico" />
        <div className={classes.locationContainer}>
          <DepartmentsList name="department" label="Departamento" />
          <CitiesList name="city" label="Ciudad" />
        </div>
        <FormField name="address" label="Dirección de residencia" />
      </div>
    </>
  )
}

export default PersonalInformationStep
