import DateFnsUtils from '@date-io/date-fns'
import { addYears, endOfYear, startOfYear } from 'date-fns'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik } from 'formik'
import { DatePicker } from 'formik-material-ui-pickers'
import { useMemo } from 'react'

import Typography from '@material-ui/core/Typography'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import Modal from 'components/UI/Modal/Modal'

import usePeriodService from 'utils/hooks/organizer/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

const NewPeriodModal = ({ visible, handleClose, activeTab }) => {
  const { handleError } = useErrorHandler()
  const { showInfoMessage } = useNotifications()
  const { periodMutation } = usePeriodService()
  const maxDate = useMemo(() => {
    const date = new Date()

    return endOfYear(addYears(date, 1))
  }, [])

  const minDate = useMemo(() => {
    const date = new Date()

    return startOfYear(addYears(date, -2))
  }, [])

  const onSubmit = (values, form) => {
    if (activeTab === 'payroll_history') {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createPeriod',
          period: values,
        },
        {
          onSuccess: ({ message }) => {
            showInfoMessage(message)
            handleClose()
          },
          onError: (error) => handleError(error, form),
        }
      )
    }

    if (activeTab === 'electronic_payroll_history') {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createElectronicPayrollPeriod',
          period: values,
        },
        {
          onSuccess: ({ message }) => {
            showInfoMessage(message)
            handleClose()
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ date: new Date() }}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Modal
            okText="Generar periodo"
            header="Generar nuevo periodo"
            open={visible}
            onOk={handleSubmit}
            onCancel={handleClose}
            isLoading={periodMutation.isLoading}
          >
            {activeTab === 'payroll_history' ? (
              <Typography gutterBottom>
                Con esta opción puedes crear un nuevo periodo, dependiendo de la
                frecuencia de pago de la empresa, se crea el periodo quincenal o
                mensual.
              </Typography>
            ) : (
              <Typography gutterBottom>
                Con esta opción puedes crear un nuevo periodo de Nómina
                Electrónica.
              </Typography>
            )}

            <Form>
              <Field
                name="date"
                label="Fecha del periodo a crear"
                component={DatePicker}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                maxDate={maxDate}
                minDate={minDate}
                fullWidth
              />
            </Form>
          </Modal>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  )
}

export default NewPeriodModal
