import { useContext } from 'react'

import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import Button from 'components/UI/Button/Button'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import { NoveltiesHistoryContext } from '../NoveltiesHistory'

const NoveltiesHistoryDownload = () => {
  const { hasAnyNovelty, handleClickNoveltyReport, premiumAction } = useContext(
    NoveltiesHistoryContext
  )

  return (
    <Button
      startIcon={<GetAppOutlinedIcon />}
      onClick={handleClickNoveltyReport}
      variant="outlined"
      disabled={!hasAnyNovelty}
      endIcon={<IconCrown isPremiumAction={premiumAction} />}
    >
      Descargar Novedades (.xlsx)
    </Button>
  )
}

export default NoveltiesHistoryDownload
