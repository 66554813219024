import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Button from 'components/UI/Button/Button'

import { formatDisplayDateString } from 'utils/dateTime'
import useFreetimeService from 'utils/hooks/freetime/freetime'
import useNotifications from 'utils/hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(3)}px 0`,
  },
  arrowContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(0.5),
  },
}))

const ApproveRequest = ({
  conceptId,
  requestId,
  name,
  initialDay,
  endDay,
  onClose,
}) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [actionType, setActionType] = useState()
  const { showSuccessMessage } = useNotifications()
  const { freetimeMutation } = useFreetimeService({
    queryOptions: {
      enabled: false,
    },
  })
  const isApproving = freetimeMutation.isLoading && actionType === 'approved'
  const isRefusing = freetimeMutation.isLoading && actionType === 'rejected'

  const onManageRequest = (status) => {
    setActionType(status)
    const label = status === 'approved' ? 'aprobada' : 'rechazada'
    const formData = new FormData()
    formData.append('payroll_concept_id', conceptId)
    formData.append('initial_day', initialDay)
    formData.append('end_day', endDay)
    formData.append('status', status)

    freetimeMutation.mutate(
      {
        mutationMethod: 'PUT',
        noveltyId: requestId,
        noveltyRequest: formData,
      },
      {
        onSuccess: () => {
          showSuccessMessage(`La solicitud ha sido ${label}`)
          queryClient.invalidateQueries('getAllRequests', {
            exact: false,
            active: true,
          })
          onClose()
        },
      }
    )
  }

  return (
    <>
      <Typography variant="body1" gutterBottom>
        <span className={classes.arrowContainer}>
          {`${name} no estará disponible del `}
          <span>{`${formatDisplayDateString(initialDay)} `}</span>
          <ArrowForwardIcon fontSize="small" />
          <span>{` ${formatDisplayDateString(endDay)}`}</span>
        </span>
      </Typography>
      <div className={classes.footer}>
        <Button
          variant="outlined"
          disabled={isApproving}
          loading={isRefusing}
          onClick={() => onManageRequest('rejected')}
        >
          Rechazar
        </Button>
        <Button
          loading={isApproving}
          disabled={isRefusing}
          onClick={() => onManageRequest('approved')}
        >
          Aprobar
        </Button>
      </div>
    </>
  )
}

export default ApproveRequest
