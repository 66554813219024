import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  content: {
    width: '50%',
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentButton: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))

export const OrganizerGridComponent = (props) => {
  const classes = useStyles()
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {props.children}
      </div>
    </div>
  )
}

export const OrganizerButtonComponent = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.contentButton}>
      {props.children}
    </div>
  )
}