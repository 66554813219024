import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import { contractCategoriesOptions, getMinimumSalary } from 'utils/worker'

import messages from 'messages/contract'

const getWageCategory = (contractCategory, wageCategory) => {
  if (wageCategory) {
    if (contractCategory !== 'contractor' && wageCategory === 'per_hour')
      return null
    return wageCategory
  }

  return null
}

const CategoryField = ({ name = 'category' }) => {
  const form = useFormikContext()
  const { values } = form
  const contractTypes = ['employee', 'pensioner']

  const emptyCategory = (value, infoContract, newTransportSubsidy) => {
    if (contractTypes.includes(value)) {
      Object.assign(infoContract, {
        term: null,
      })
    } else {
      Object.assign(infoContract, {
        term: 'fixed',
        wage_category:
          value !== 'contractor' ? 'monthly' : values.wage_category,
        transport_subsidy: newTransportSubsidy,
      })
    }

    form.setValues(infoContract)
  }

  const handleChange = ({ target: { value } }) => {
    const { term, wage_category: wageCategory } = values
    const category = values[name]
    const infoContract = {
      ...values,
      [name]: value,
    }

    const newTransportSubsidy =
      values.transport_subsidy ??
      (values.base_salary < getMinimumSalary({ multiplier: 2 }) ? true : null)

    // if is the first time that contract_category is selected
    if (!category)
      return emptyCategory(value, infoContract, newTransportSubsidy)

    if (
      contractTypes.includes(category) &&
      value !== 'employee' &&
      value !== 'pensioner'
    ) {
      let isTerm = 'fixed'
      if (term && term !== 'indefinite') isTerm = term

      const salaryCategories = ['integral_salary', 'part_time'].includes(
        wageCategory
      )
        ? null
        : wageCategory

      Object.assign(infoContract, {
        term: isTerm,
        wage_category: salaryCategories,
        transport_subsidy: newTransportSubsidy,
      })
    } else {
      Object.assign(infoContract, {
        wage_category: getWageCategory(value, wageCategory),
        transport_subsidy: newTransportSubsidy,
      })
    }

    return form.setValues(infoContract)
  }

  return (
    <FormField
      name={name}
      label="Tipo de contrato"
      variant="select"
      options={contractCategoriesOptions}
      tooltipContent={messages.category}
      onChange={handleChange}
    />
  )
}

export default CategoryField
