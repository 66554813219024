import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { phoneInput } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const stepNumber = {
  welcome: 0,
  role: 1,
  data: 2,
  select_action: 3,
}

export const stepsData = [
  {
    step: 0,
    id: 'welcome',
    title: 'Selecciona el tipo de plan que quieres',
    subtitle: 'Te estaremos guiando en la configuración de tu cuenta.',
    pathname: '/onboarding/welcome',
  },
  {
    step: 1,
    id: 'role',
    title: 'Selecciona el rol que mejor te describa',
    subtitle: 'Te estaremos guiando en la configuración de tu cuenta.',
    pathname: '/onboarding/role',
  },
  {
    step: 2,
    id: 'data',
    title: 'Ahora personalicemos Nominapp',
    subtitle: 'Ingresa tus datos y los de tu empresa.',
    pathname: '/onboarding/personalize',
  },
  {
    step: 3,
    id: 'select_action',
    title: 'Felicitaciones',
    pathname: '/onboarding/congratulations',
  },
]
export const schemaValidation = {
  1: yup.object({
    user_role: yup.string(),
    user_role_other_info: yup.string().when('user_role', {
      is: (userRole) => userRole === 'other',
      then: yup.string().required(),
    }),
  }),
  2: yup.object({
    user_name: yup.string().nullable().required(),
    phone: yup
      .string()
      .nullable()
      .matches(
        phoneInput,
        'Debes ingresar un número de celular válido Colombiano'
      )
      .min(10, 'El teléfono debe tener 10 dígitos')
      .max(10, 'El teléfono debe tener 10 dígitos')
      .trim()
      .required(),
    name: yup
      .string()
      .trim()
      .min(3, 'El nombre debe tener mínimo 3 caracteres')
      .max(50, 'El nombre debe tener máximo 50 caracteres')
      .required(),
    document_type: yup.string().nullable().required(),
    id_number: yup.string().nullable().required(),
    verification_digit: yup.string().nullable(),
    workers_number: yup
      .string()
      .nullable()
      .when('user_role', {
        is: (userRole) => !['employee', 'student'].includes(userRole),
        then: yup.string().nullable().required(),
      }),
    payroll_frequency: yup.string().nullable().required(),
  }),
}
