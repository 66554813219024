import { useFormikContext } from 'formik'

import CustomField from 'components/UI/Formik/CustomField'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'

import InputsGroupContainer, {
  Horizontal,
  Column,
} from '../InputsGroupContainer'

import { getNestedProp } from '../helpers'
import { useCreditFormFields } from '../hooks'

const WorkerReferences = () => {
  const { inputsWorkerReferenceData } = useCreditFormFields()
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const onChangeDepartment = (
    newSelectedValue,
    path,
    cityKey,
    departmentKey
  ) => {
    const nestedProp = getNestedProp(values, path)
    if (nestedProp && nestedProp?.id !== newSelectedValue?.id) {
      setFieldValue(cityKey, null)
      setFieldTouched(cityKey)
    }
    setFieldValue(departmentKey, newSelectedValue)
  }

  return (
    <>
      {inputsWorkerReferenceData.map((section) => (
        <InputsGroupContainer
          key={section?.id}
          title={section?.title}
          gutterBottom={section?.gutterBottom}
          dataCy={section?.dataCy}
        >
          {section?.rows.map((row) => {
            const Wrapper = row?.component === 'full-row' ? Horizontal : Column

            return (
              <Wrapper key={row?.id} gutterBottom={row?.gutterBottom}>
                {row?.fields.map((field) => {
                  if (field?.name?.includes('.department')) {
                    return (
                      <DepartmentsList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        onChange={(_, newSelectedValue) =>
                          onChangeDepartment(
                            newSelectedValue,
                            field?.departmentPath,
                            field?.cityKey,
                            field?.name
                          )
                        }
                      />
                    )
                  }

                  if (field?.name?.includes('.city')) {
                    const department = getNestedProp(
                      values,
                      field?.departmentPath
                    )

                    return (
                      <CitiesList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        department={department}
                      />
                    )
                  }

                  return (
                    <CustomField
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      placeholder={field?.placeholder}
                      component={field?.component}
                      options={field?.options || undefined}
                      data-cy={field?.dataCy}
                    />
                  )
                })}
              </Wrapper>
            )
          })}
        </InputsGroupContainer>
      ))}
    </>
  )
}

export default WorkerReferences
