import { useMutation } from 'react-query'

import {
  enableDuplicateNit,
  createAutomaticDebit,
} from 'services/organizer/generalActions'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, data } = mutationData

  if (mutationMethod === 'PATCH') return enableDuplicateNit(data)

  if (mutationMethod === 'POST') return createAutomaticDebit(data)

  return null
}

const useGeneralActionsService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const generalActionsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    generalActionsMutation,
  }
}

export default useGeneralActionsService
