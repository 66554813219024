import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import getWorkersUploadMessage from 'messages/workers_file'

import downloadIcon from 'assets/images/views/worker/download.svg'
import downloadImg from 'assets/images/views/worker/downloadWorkers.png'

import useStyles from './helpers'

const WorkerImportBoxDownload = ({ onClick }) => {
  const workerFileInfo = getWorkersUploadMessage()

  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">1. Descargar Formato Excel</Typography>
      <Typography paragraph>{workerFileInfo.download}</Typography>

      <div className={classes.image}>
        <img src={downloadImg} alt="Download File" />
      </div>

      <div className={classes.download}>
        <img src={downloadIcon} alt="Upload Icon" />
        <Button onClick={onClick} className={classes.downloadButton}>
          Descargar formato Excel
        </Button>
      </div>
    </Paper>
  )
}

export default WorkerImportBoxDownload
