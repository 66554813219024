import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'

import messages from 'messages/contract'

import HighRiskPensionField from './HighRiskPension'

const RiskTypeField = ({ contractCategory, isEditContract, ...restProps }) => {
  const companyId = getCompanyId()
  const { setFieldValue } = useFormikContext()
  const { companyWorkCentersQuery } = useCompanyWorkCenters({
    serviceParams: { queryKey: ['getWorkCenters', companyId] },
  })

  const workCenterData = companyWorkCentersQuery?.data?.map((workCenter) => ({
    id: workCenter?.id,
    name: workCenter?.name,
  }))

  const onChange = (_, newSelectedValue) => {
    setFieldValue('work_center', newSelectedValue)

    if (newSelectedValue?.name?.includes('5')) {
      setFieldValue('high_risk_pension', false)
    } else {
      setFieldValue('high_risk_pension', null)
    }
  }

  return contractCategory &&
    contractCategory !== 'contractor' &&
    contractCategory !== 'schooling_trainee' ? (
    <>
      <FormField
        name="work_center"
        label="Centro de trabajo - Nivel de riesgo"
        placeholder="Centro de trabajo - Nivel de riesgo"
        variant="autocomplete"
        options={workCenterData || []}
        tooltipContent={messages.work_center}
        {...restProps}
        onChange={onChange}
      />
      <HighRiskPensionField isEditContract={isEditContract} />
    </>
  ) : null
}

export default RiskTypeField
