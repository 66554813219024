import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import oauthService from 'services/auth/oauthService'

import { LOGIN_OAUTH, PASSWORD_RECOVERY, SUBSCRIPTION } from 'config/routes'

import messages from 'messages/notification'

import ContactToSupport from '../common/ContactToSupport'
import LinkToSignup from '../common/LinkToSignup'
import AuthLogo from '../common/Logo'
import MainContainer from '../common/MainContainer'
import { useAuthStyles } from '../helpers'
import { getValidationSchema, initialValues } from './helpers'

const SignIn = ({ isFromRecoveryPassword }) => {
  const classes = useAuthStyles()
  const { isAuthenticated, logIn } = useUser()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const location = useLocation()

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showInactiveUserMessage, setShowInactiveUserMessage] = useState(false)
  const [otpAuthStep, setOtpAuthStep] = useState(1)
  const { client_id: clientId, subscription, redirect } = queryString.parse(
    location.search
  )
  const { sessionMutation } = useSessionService()

  const fromOAuth = location.pathname === LOGIN_OAUTH

  if (fromOAuth && !clientId) {
    throw new Error('Error en la autenticación OAuth')
  }

  const validateRedirectAfterLogin = () => {
    let pathname = '/'
    if (subscription) {
      pathname = SUBSCRIPTION()
      return {
        pathname,
        state: { fromLogin: true, toSubscription: subscription },
      }
    }

    if (redirect) {
      pathname = redirect
      return { pathname }
    }

    return { pathname }
  }

  if (isAuthenticated()) return <Redirect to={validateRedirectAfterLogin()} />

  const messageSubscription = subscription
    ? ', paga y ten Nómina Electrónica GRATIS de por vida.'
    : ''

  const handleSubmit = async (values, form) => {
    try {
      if (fromOAuth) {
        const {
          access_token: accessToken,
          token_type: tokenType,
        } = await oauthService.login(values)

        const { redirect_uri: redirectUri } = await oauthService.requestAuth(
          clientId,
          tokenType,
          accessToken
        )

        form.setSubmitting(false)
        window.location.href = redirectUri
      } else {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...values,
            },
          },
          {
            onSuccess: ({ data, otp_auth: otpAuth }) => {
              if (data) {
                showSuccessMessage(messages.LOGIN_OK)
                logIn(data)
              } else if (otpAuth) {
                setOtpAuthStep(2)
              }
            },
          }
        )
      }
    } catch (error) {
      setShowErrorMessage(true)
      const { errors } = error
      if (errors[0].code === '0002') setShowInactiveUserMessage(true)
      else setShowInactiveUserMessage(false)
      handleError(error, form, { redirect: false })
    }
  }

  const { isLoading } = sessionMutation

  return (
    <MainContainer isFromRecoveryPassword={isFromRecoveryPassword}>
      <div className={classes.formContainer}>
        {!isFromRecoveryPassword ? (
          <div className={classes.titleContainer}>
            <AuthLogo width={260} height={46} className={classes.logo} />
            <Typography variant="h4" className={classes.title} align="left">
              {otpAuthStep === 1
                ? `Ingresa a tu cuenta${messageSubscription}`
                : 'Ingresa tu código de verificación'}
            </Typography>
          </div>
        ) : null}
        <Collapse in={showErrorMessage}>
          <Alert severity="error" onClose={() => setShowErrorMessage(false)}>
            {otpAuthStep === 1 ? (
              <>
                {showInactiveUserMessage && (
                  <div className={classes.alertAling}>
                    El usuario con el que estás ingresando está inactivo.
                    Contacta con el administrador de la cuenta para activar el
                    usuario.{' '}
                  </div>
                )}
                {!showInactiveUserMessage && (
                  <>
                    El correo electrónico o contraseña son incorrectos. Recuerda
                    que siempre puedes recuperar tu contraseña en caso de que no
                    la recuerdes.{' '}
                    <Link to={PASSWORD_RECOVERY}>Olvidé mi contraseña.</Link>
                  </>
                )}
              </>
            ) : (
              'El código que ingresaste es incorrecto o ya expiró.'
            )}
          </Alert>
        </Collapse>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={getValidationSchema(otpAuthStep)}
        >
          {({ resetForm }) => {
            return (
              <>
                {otpAuthStep === 1 && <GoogleLoginButton action="Sign In" />}
                <div className={classes.divider}>
                  <Divider />
                  <Typography variant="h5" color="textSecondary">
                    O
                  </Typography>
                  <Divider />
                </div>
                <Form className={classes.form}>
                  <div className={classes.fieldsContainer}>
                    {otpAuthStep === 1 ? (
                      <>
                        <FormField
                          type="email"
                          name="email"
                          label="Tu correo electrónico"
                          placeholder="Ingresa tu correo electrónico"
                          autoComplete="email"
                          disabled={isLoading}
                        />
                        <PasswordField
                          name="password"
                          label="Tu contraseña"
                          placeholder="Ingresa tu contraseña"
                          autoComplete="password"
                          disabled={isLoading}
                        />
                      </>
                    ) : (
                      <FormField
                        type="name"
                        name="otp_code"
                        label="Código de autenticación"
                        inputProps={{
                          maxLength: 6,
                          autoComplete: 'one-time-code',
                        }}
                        disabled={isLoading}
                      />
                    )}
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      color="primary"
                      loading={isLoading}
                      className={classes.button}
                      data-cy="sign_in_button"
                    >
                      Ingresar
                    </Button>
                  </div>
                </Form>
                {!isFromRecoveryPassword ? (
                  <Typography
                    variant="body2"
                    className={classes.forgotPassword}
                  >
                    {otpAuthStep === 1 ? (
                      <Link to={PASSWORD_RECOVERY}>
                        ¿Olvidaste tu contraseña?
                      </Link>
                    ) : (
                      <MuiLink
                        component="button"
                        onClick={() => {
                          setOtpAuthStep(1)
                          resetForm()
                        }}
                      >
                        Volver
                      </MuiLink>
                    )}
                  </Typography>
                ) : null}
                {otpAuthStep === 1 && !isFromRecoveryPassword && (
                  <LinkToSignup />
                )}
              </>
            )
          }}
        </Formik>
        {!isFromRecoveryPassword ? <ContactToSupport /> : null}
      </div>
    </MainContainer>
  )
}

export default SignIn
