import { createContext, useState } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Documents from 'components/Worker/Documents/Index'

export const DocumentsContext = createContext()
DocumentsContext.displayName = 'DocumentsContext'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 3, 4),
  },
  divider: {
    margin: theme.spacing(2, 0, 3, 0),
    backgroundColor: theme.palette.grey[500],
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    height: 40,
  },
  subtitle: {
    marginBottom: theme.spacing(4),
  },
}))

const DocumentsContainer = () => {
  const classes = useStyles()
  const [openedFolder, setOpenedFolder] = useState(null)

  const value = {
    setOpenedFolder,
    openedFolder,
  }

  return (
    <DocumentsContext.Provider value={value}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h5">
            {openedFolder?.name || 'Documentos compartidos'}
          </Typography>
          {openedFolder ? (
            <Button
              variant="text"
              size="small"
              onClick={() => setOpenedFolder(null)}
            >
              Regresar
            </Button>
          ) : null}
        </div>
        <Divider className={classes.divider} />
        <Typography variant="body1" className={classes.subtitle}>
          Aquí podrás agregar hasta 30 archivos para tus empleados y
          distribuirlos en las carpetas que quieras! Por ahora, puedes cargar
          archivos en PDF.
        </Typography>
        <Documents />
      </div>
    </DocumentsContext.Provider>
  )
}

export default DocumentsContainer
