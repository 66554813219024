import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import Loading from 'components/UI/Loading/Loading'
import Page from 'components/UI/Page/Page'
import Steps from 'components/UI/Steps/Steps'

import { isObjectEmpty, removeSuffix } from 'utils/general'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_TRANSACTION_RESPONSE } from 'config/routes'

import useSubscriptionPlans from '../useSubscriptionPlans'
import SubscriptionPlan from './Plan/Index'
import SubscriptionSummary from './Summary/SubscriptionSummary'
import {
  getInitialValues,
  getPaymentData,
  getValidationSchema,
} from './helpers'

export const NewPricingContext = createContext()
NewPricingContext.displayName = 'NewPricingContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content',
    height: '100%',
    overflowX: 'hidden',
  },
  paymentContainer: {
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 530px',
      gridTemplateRows: 'auto',
    },
  },
  stepsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepsContainer: {
    width: '100%',
    maxWidth: 300,
    alignSelf: 'center',
    display: 'flex',
  },
  page: {
    [theme.breakpoints.up('lg')]: {
      overflowY: 'scroll',
    },
  },
}))

const Paywall = () => {
  const classes = useStyles()
  const { company } = useUser()
  const history = useHistory()
  const frequentQuestionsRef = useRef()
  const { showInfoMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const formRef = useRef()
  const [selectedPlan, setSelectedPlan] = useState({})
  const [step, setStep] = useState(0)
  const firstInteraction = useRef(false)

  const onSelectPlan = (newPlan) => setSelectedPlan(newPlan)

  const { plansQuery, subscriptionQuery } = useSubscriptionPlans({
    plansQueryOptions: {
      onSuccess: ({ data }) => {
        const currentSelectedPlan = data?.plans?.find(
          (plan) => plan.current_plan
        )
        if (currentSelectedPlan) {
          onSelectPlan({
            ...currentSelectedPlan,
            coded_name: removeSuffix(currentSelectedPlan.coded_name, -8),
          })
        } else {
          const defaultPlan = data?.plans?.find(
            (plan) =>
              removeSuffix(plan.coded_name, -8) === 'premium_per_worker_plan'
          )
          onSelectPlan(defaultPlan)
        }
      },
    },
    subscriptionQueryOptions: {
      onSuccess: ({ data }) => {
        if (formRef.current && step === 0) {
          formRef.current.setValues({
            ...formRef.current.values,
            modality: data?.type || formRef.current.values.modality,
            plan: selectedPlan?.coded_name,
            workers_number: !data?.partner ? data?.payrolls_size || 1 : 1,
            isPartner: data?.partner,
          })
        }

        if (formRef.current && step === 1) {
          if (data?.payment_category === 'clara_payment') {
            formRef.current.setValues({
              ...formRef.current.values,
              modality: 'year',
              workers_number: 20,
            })
          }
        }
      },
    },
  })
  const isPartner = subscriptionQuery.data?.data?.partner === true
  const isClaraPayment =
    subscriptionQuery.data?.data?.payment_category === 'clara_payment'
  const disableLeftButton = isPartner || isClaraPayment
  const [activePayment, setActivePayment] = useState(() => {
    let activePaymentCategory = subscriptionQuery.data?.data?.payment_category
    // some payment categories are not available in this view
    if (
      !['automatic_debit', 'credit_card', 'pse'].includes(activePaymentCategory)
    )
      activePaymentCategory = 'credit_card' // default
    return activePaymentCategory
  })
  const { paymentMethodMutation } = usePaymentMethodService({
    mutationOptions: {
      onSuccess: (response) => {
        if (activePayment === 'pse') window.location.href = response.bank_url

        if (activePayment === 'automatic_debit') {
          showInfoMessage(response.message)
        }

        if (activePayment === 'credit_card') {
          if (response.payment_status) {
            history.push({
              pathname: SUBSCRIPTION_TRANSACTION_RESPONSE(),
              search: `?status=${response.payment_status}`,
              state: {
                payment_status: response.payment_status,
                plan_id: response.plan_id,
                value: response.value,
              },
            })
          } else {
            showInfoMessage(response.message)
          }
        }
      },
      onError: (error) => {
        if (
          error.errors?.[0].code.startsWith('24') ||
          error.errors?.[0].code === '1101'
        ) {
          history.push({
            pathname: SUBSCRIPTION_TRANSACTION_RESPONSE(),
            search: '?status=DECLINED',
            state: {
              payment_status: 'DECLINED',
              plan_id: selectedPlan?.id,
              error: error.errors[0],
            },
          })
        } else {
          handleError(error)
        }
      },
    },
  })

  const onSubmit = (values) => {
    const paymentData = getPaymentData(
      values,
      values?.payment_method,
      selectedPlan?.id,
      values?.modality,
      Number(values?.workers_number),
      subscriptionQuery.data?.data?.partner
    )

    paymentMethodMutation.mutate({
      paymentMethod: values?.payment_method,
      paymentMethodData: paymentData,
    })
  }

  const handleNextStep = () => setStep(step + 1)

  const handlePreviousStep = () => setStep(step - 1)

  const onFirstInteraction = () => {
    firstInteraction.current = true
  }

  const pricingContextValue = useMemo(() => {
    return {
      selectedPlan,
      onSelectPlan,
      subscription: subscriptionQuery.data,
      currentStep: step,
      setStep,
      onFirstInteraction,
      setActivePayment,
      loadingPayment: paymentMethodMutation.isLoading,
    }
  }, [
    paymentMethodMutation.isLoading,
    selectedPlan,
    step,
    subscriptionQuery.data,
  ])

  useEffect(() => {
    if (isPartner || isClaraPayment) {
      setStep((previousValue) => previousValue + 1)
    }
  }, [isClaraPayment, isPartner])

  if (plansQuery.isLoading || subscriptionQuery.isLoading) return <Loading />

  return (
    <NewPricingContext.Provider value={pricingContextValue}>
      <Formik
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={getInitialValues(company)}
        validationSchema={getValidationSchema()}
        enableReinitialize
      >
        {({ handleSubmit }) => {
          return (
            <Form
              className={clsx(classes.container, {
                [classes.paymentContainer]: step === 1,
              })}
            >
              <Page
                documentTitle="Suscripción"
                className={clsx({ [classes.page]: step === 1 })}
              >
                <div className={classes.stepsContent}>
                  <div className={classes.stepsContainer}>
                    <Steps
                      current={step}
                      progress={step}
                      stepsData={[
                        { title: 'selectPlan' },
                        { title: 'paySubscription' },
                      ]}
                      arrows
                      orientation="horizontal"
                      leftArrowProps={{
                        disabled: step === 0 || disableLeftButton,
                        hidden: step === 0,
                        onClick: handlePreviousStep,
                      }}
                      rightArrowProps={{
                        disabled:
                          !firstInteraction?.current ||
                          step === 1 ||
                          isObjectEmpty(selectedPlan),
                        hidden: step === 1,
                        onClick: handleNextStep,
                      }}
                    />
                  </div>
                </div>

                <SubscriptionPlan frequentQuestionsRef={frequentQuestionsRef} />
              </Page>
              {step === 1 ? (
                <SubscriptionSummary handleSubmitForm={handleSubmit} />
              ) : null}
            </Form>
          )
        }}
      </Formik>
    </NewPricingContext.Provider>
  )
}

export default Paywall
