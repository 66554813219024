import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Paper, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'

import { wait } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import socialSecurityService from 'services/payroll/socialSecurityService'

import PendingDots from 'assets/icons/PendingDots'

const useStyles = makeStyles((theme) => ({
  transactionState: {
    display: 'flex',
    borderRadius: 12,
    padding: theme.spacing(1.5, 0, 1.5, 4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  transactionStateCompleted: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
  },
  stepsProgress: {
    borderRadius: 12,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  step: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 0, 1.5, 4),
  },
  opaqueStep: {
    color: theme.palette.grey[400],
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  completedStepText: {
    color: 'initial',
  },
}))

const DynamicLoaderItem = ({ position, content, progressPosition }) => {
  const classes = useStyles()

  return (
    <>
      <Box
        className={clsx(classes.step, {
          [classes.opaqueStep]: position > progressPosition,
        })}
      >
        {position > progressPosition ? (
          <RadioButtonUncheckedOutlinedIcon className={classes.icon} />
        ) : (
          <CheckCircleOutlineIcon color="primary" className={classes.icon} />
        )}
        <Typography variant="body1">{content}</Typography>
      </Box>
    </>
  )
}

const PeriodSocialSecurityPaymentModalStepTwo = ({
  periodId,
  formType,
  completed,
  setCompleted,
  handleClose,
  provider,
  providerName,
}) => {
  const [progressPosition, setProgressPosition] = useState(1)
  const { handleError } = useErrorHandler()
  const { showErrorMessage } = useNotifications()
  const classes = useStyles()

  useEffect(() => {
    const firstFetch = async () => {
      try {
        await socialSecurityService.getGenerateConfirmation(
          periodId,
          formType,
          provider
        )
        setProgressPosition(2)
      } catch (error) {
        if (error.errors) {
          const errors = error.errors[0]

          showErrorMessage(errors.message)
        } else {
          handleError(error)
        }
        handleClose()
      }
    }

    const secondFetch = async () => {
      try {
        await socialSecurityService.getValidateConfirmation(periodId, formType)

        setProgressPosition(3)
        wait(500) // This is for delay to complete te job, product requeriment
        setProgressPosition(4)
        setCompleted(true)
      } catch (error) {
        handleError(error)
        handleClose()
      }
    }

    if (progressPosition === 2) secondFetch()
    if (progressPosition === 1) firstFetch()
  }, [
    formType,
    handleClose,
    handleError,
    periodId,
    progressPosition,
    provider,
    setCompleted,
    showErrorMessage,
  ])

  return (
    <>
      <Typography variant="subtitle1">Creación de planilla</Typography>
      <Typography variant="body1">
        Este proceso puede tardar unos minutos.
      </Typography>

      <Paper
        className={clsx(classes.transactionState, {
          [classes.transactionStateCompleted]: completed,
        })}
      >
        {completed ? (
          <CheckCircleOutlineIcon className={classes.icon} />
        ) : (
          <PendingDots color="primary" className={classes.icon} />
        )}
        <Typography variant="subtitle1" className={classes.completedStepText}>
          {completed
            ? '¡Tu planilla está lista!'
            : 'Estamos preparando tu pago...'}
        </Typography>
      </Paper>
      <Paper className={classes.stepsProgress}>
        <DynamicLoaderItem
          position={1}
          content={`Creando planilla en ${providerName}`}
          progressPosition={progressPosition}
        />
        <Divider />
        <DynamicLoaderItem
          position={2}
          content="Validando errores y advertencias"
          progressPosition={progressPosition}
        />
        <Divider />
        <DynamicLoaderItem
          position={3}
          content="Generando link de pago"
          progressPosition={progressPosition}
        />
      </Paper>
    </>
  )
}

export default PeriodSocialSecurityPaymentModalStepTwo
