import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import MButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import PaperForm from 'components/UI/Form/PaperForm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useWorkerService from 'utils/hooks/worker/workerService'

import {
  COMPANY_EDIT,
  WORKER_AFFILIATIONS_INDEX,
  WORKER_INDEX,
} from 'config/routes'

import AffiliationDataStep from './AffiliationDataStep'
import AttachDocumentsStep from './AttachDocumentsSteps'
import ConfirmAffiliation from './Modals/ConfirmAffiliation'
import CredentialsAlert from './Modals/CredentialsAlert'
import PersonalInformationStep from './PersonalInformationStep'
import {
  formHasChanged,
  getDirtyWorker,
  getInitialValues,
  stepsData,
  validationSchema,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
  buttonsContainer: {
    marginTop: theme.spacing(10),
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    '& .MuiButton-root + .MuiButton-root': {
      marginLeft: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}))

const AffiliationForm = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const history = useHistory()
  const modals = useModals()
  const { handleError } = useErrorHandler()
  const [currentStep, setCurrentStep] = useState(0)
  const [worker, setWorker] = useState({})
  const workerId = worker.id
  const workerQueryKey = ['getWorkerById', workerId]
  const cachedWorker = queryClient.getQueryData(workerQueryKey)

  const affiliationQueryKey = ['getAffiliationById', workerId]
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })
  const { affiliationsQuery, affiliationsMutation } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(workerId),
      onSuccess: ({ data }) => {
        if (data?.id) {
          setCurrentStep(2)
        }
      },
      onError: (error) => {
        // Avoid displaying the error alert when the error is 0001, since it means that the affiliation does not exist yet
        if (error.errors[0]?.code !== '0001') {
          handleError(error)
        }
      },
    },
    mutationOptions: {
      // Avoid displaying the error about administrators' credentials, as the custom alert is displayed instead
      onError: (error) => {
        const isOnlyEntitiesErrors = error.errors?.every((errorItem) =>
          ['3008', '3009', '3010', '3011', '3012'].includes(errorItem.code)
        )

        if (!isOnlyEntitiesErrors) {
          handleError(error)
        }
      },
    },
  })
  const currentAffiliation = affiliationsQuery.data || {}

  const initialValues = getInitialValues(
    cachedWorker?.data || worker,
    currentAffiliation
  )

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((previousStep) => previousStep - 1)
    } else {
      history.push(WORKER_AFFILIATIONS_INDEX())
    }
  }

  const handleAffiliationMutation = (data, onSuccess, onError) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData: data,
      },
      {
        onSuccess,
        onError,
      }
    )
  }

  const handleConfirmationModal = () => {
    const confirmationModalId = modals.openModal({
      id: 'confirmAffiliation',
      content: <ConfirmAffiliation />,
      modalProps: {
        header: 'Finalizar solicitud de afiliación',
        okText: 'Solicitar afiliación',
        cancelText: 'Guardar y continuar después',
        onCancel: () => {
          modals.closeAll()
          history.push(WORKER_AFFILIATIONS_INDEX())
        },
        onOk: () => {
          const confirmationData = new FormData()
          confirmationData.append('status', 'waiting_response')

          modals.setLoadingModal(true)
          handleAffiliationMutation(
            confirmationData,
            async () => {
              await queryClient.invalidateQueries(affiliationQueryKey)
              modals.setLoadingModal(false)
              modals.closeAll()
              history.push(WORKER_AFFILIATIONS_INDEX())
            },
            (error) => {
              modals.setLoadingModal(false)
              modals.closeModal(confirmationModalId)
              modals.openModal({
                id: 'credentialsAlert',
                content: <CredentialsAlert errors={error.errors} />,
                modalProps: {
                  header: 'Credenciales de entidades correspondientes',
                  okText: 'Ir a empresa',
                  onCancel: () => {
                    modals.closeAll()
                  },
                  onOk: () => {
                    modals.closeAll()
                    history.push(COMPANY_EDIT(getCompanyId()), {
                      initialStep: 5,
                    })
                  },
                  dialogProps: {
                    fullWidth: true,
                    maxWidth: 'sm',
                  },
                },
              })
            }
          )
        },
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const onSubmit = (values) => {
    if (currentStep !== 2) {
      const dirtyWorker = getDirtyWorker(values, currentStep)

      if (formHasChanged(values, initialValues, currentStep)) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker: { id: workerId, ...dirtyWorker },
            workerId,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(workerQueryKey, {
                refetchInactive: true,
                refetchActive: true,
                exact: false,
              })
              if (currentStep < stepsData.length - 1) {
                setCurrentStep((previousStep) => previousStep + 1)
              }
            },
          }
        )
      } else if (currentStep < stepsData.length - 1) {
        setCurrentStep((previousStep) => previousStep + 1)
      }
    } else if (currentStep === 2) {
      if (formHasChanged(values, initialValues, currentStep)) {
        const affiliationData = new FormData()

        if (values.identification_document instanceof File) {
          affiliationData.append(
            'identification_document',
            values.identification_document
          )
        }

        if (values.comment && values.comment.length > 0) {
          affiliationData.append('comment', values.comment)
        } else {
          affiliationData.append('comment', '')
        }

        handleAffiliationMutation(affiliationData, async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          handleConfirmationModal()
        })
      } else {
        handleConfirmationModal()
      }
    }
  }

  return (
    <Page
      documentTitle="Nueva afiliación"
      header={
        <div className={classes.header}>
          <Typography variant="h5">
            Nueva afiliación a Seguridad Social
          </Typography>
          <Link to={WORKER_INDEX()}>Regresar a personas</Link>
        </div>
      }
    >
      <Typography variant="body1" className={classes.description}>
        La información de la persona será utilizada para ayudarte a gestionar la
        afiliación a Seguridad Social, pensión y cesantías.
      </Typography>
      <PaperForm
        steps={{
          stepsData,
          currentStep,
          progressStep: currentStep,
          onChangeStep: (step) => setCurrentStep(step),
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep]}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, values }) => {
            return (
              <Form>
                <Typography
                  variant="h6"
                  className={classes.title}
                  color="primary"
                >
                  {stepsData[currentStep].pageTitle}
                </Typography>
                {currentStep === 0 ? (
                  <PersonalInformationStep setWorker={setWorker} />
                ) : null}
                {currentStep === 1 ? (
                  <AffiliationDataStep worker={worker} />
                ) : null}
                {currentStep === 2 ? <AttachDocumentsStep /> : null}
                <div className={classes.buttonsContainer}>
                  <MButton variant="outlined" onClick={handleBack}>
                    {currentStep === 0 ? 'Cancelar' : 'Volver al paso anterior'}
                  </MButton>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    loading={
                      workerMutation.isLoading || affiliationsMutation.isLoading
                    }
                    disabled={
                      currentStep === 2 &&
                      (!values.identification_document ||
                        currentAffiliation?.status === 'waiting_response')
                    }
                  >
                    Continuar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </PaperForm>
    </Page>
  )
}

export default AffiliationForm
