import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import fileService from 'services/payroll/fileService'

import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import * as routes from 'config/routes'

import payrollMessages from 'messages/payroll'

import { PeriodContext } from '../../Payroll'
import DecreeSocialSecurityFileModal from './Modals/DecreeSocialSecurityFileModal'
import useDecreeSocialSecurityFileModal from './Modals/useDecreeSocialSecurityFileModal'

const SocialSecurityFileMenu = () => {
  const {
    period,
    options: {
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      decree376_periods: decree376Periods,
    },
  } = useContext(PeriodContext)

  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()

  const confirm = useConfirm()
  const history = useHistory()
  const hasDecree376Options =
    decree376Periods &&
    decree376Periods.length > 0 &&
    decree376Periods.some((decreePeriod) => decreePeriod.social_security_file)

  const featureState = usePremiumFeature('socialSecurity')

  const {
    decreeSocialSecurityFileModalState,
    openDecreeSocialSecurityFileModal,
    closeDecreeSocialSecurityFileModal,
  } = useDecreeSocialSecurityFileModal(period.id)

  const handleDownloadPeriodFile = async (type) => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }

    const serviceCall = async () => {
      let res

      if (type === 'socialSecurityFile') {
        res = await fileService.getSocialSecurityFile(period.id, 'employee')
      } else if (type === 'studentsSocialSecurityFile') {
        res = await fileService.getSocialSecurityFile(
          period.id,
          'student_decree_055'
        )
      }

      return res
    }

    const callback = () => {
      const studentsSocialSecurity =
        type === 'studentsSocialSecurityFile' ? 'student_decree_055' : null

      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: 'socialSecurity',
        type:
          type === 'socialSecurityFile' ? 'employee' : studentsSocialSecurity,
      })
    }

    const handleErrorMising = (error) => {
      if (error.status === 402) {
        handleError(error)
      } else if (error.errors && error.errors[0].code === '0607') {
        confirm({
          type: 'info',
          okText:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_OK_TEXT,
          title:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_TITLE,
          description:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_DESCRIPTION,
          onOk: () =>
            history.push({
              pathname: routes.WORKER_INDEX(),
              state: { filter: 'not_finished' },
            }),
        })

        return true
      }

      return false
    }

    enqueueDownload({
      name: 'archivo de pago de Seguridad Social',
      fileCode: 'socialSecurityFile',
      serviceCall,
      callback,
      handleError: handleErrorMising,
    })
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialSecurityFileMenu',
  })
  const popupBindTrigger = bindTrigger(popupState)

  const openPopover = (event) => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  const handleDownloadDecreePeriodFile = (decreePeriodId) => {
    openDecreeSocialSecurityFileModal(decreePeriodId)
    popupState.close()
  }

  return (
    <>
      {(socialSecurityFile && studentsSocialSecurityFile) ||
      hasDecree376Options ? (
        <>
          <Button
            variant="text"
            startIcon={<BallotOutlinedIcon />}
            endIcon={
              featureState.isPremiumFeature ? <IconCrown /> : <ExpandMoreIcon />
            }
            {...popupBindTrigger}
            onClick={openPopover}
          >
            Archivo pago Seguridad Social
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
          >
            {socialSecurityFile && (
              <MenuItem
                onClick={() => handleDownloadPeriodFile('socialSecurityFile')}
              >
                Empleados
              </MenuItem>
            )}
            {studentsSocialSecurityFile && (
              <MenuItem
                onClick={() =>
                  handleDownloadPeriodFile('studentsSocialSecurityFile')
                }
              >
                Estudiantes (Decreto 055)
              </MenuItem>
            )}
            {decree376Periods.map((item) => {
              return item.social_security_file ? (
                <MenuItem
                  key={item.id}
                  onClick={() => handleDownloadDecreePeriodFile(item.id)}
                >
                  {`${item.label} (Decreto 376)`}
                </MenuItem>
              ) : null
            })}
          </Menu>
        </>
      ) : (
        <>
          {socialSecurityFile && (
            <Button
              variant="text"
              startIcon={<BallotOutlinedIcon />}
              onClick={() => handleDownloadPeriodFile('socialSecurityFile')}
              endIcon={
                <IconCrown
                  isPremiumAction={featureState.isPremiumFeatureIcon}
                />
              }
            >
              Archivo pago Seguridad Social
            </Button>
          )}
          {studentsSocialSecurityFile && (
            <Button
              variant="text"
              startIcon={<BallotOutlinedIcon />}
              onClick={() =>
                handleDownloadPeriodFile('studentsSocialSecurityFile')
              }
            >
              Archivo pago Seguridad Social (Estudiantes)
            </Button>
          )}
        </>
      )}
      {decreeSocialSecurityFileModalState.open && (
        <DecreeSocialSecurityFileModal
          state={decreeSocialSecurityFileModalState}
          handleClose={closeDecreeSocialSecurityFileModal}
        />
      )}
    </>
  )
}

export default SocialSecurityFileMenu
