import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import OrganizationEmail from 'components/UI/OrganizationEmail'

const useStyles = makeStyles((theme) => ({
  methodContainer: {
    marginTop: theme.spacing(2),
  },
  paymentMessage: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    lineHeight: '12px',
    marginTop: theme.spacing(2.5),
  },
}))

const SummaryModal = ({
  tabsData,
  activePayment,
  paymentTypeData,
  handleChangeTab,
}) => {
  const classes = useStyles()
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down(680))

  return (
    <>
      <Tabs
        tabs={tabsData}
        value={activePayment}
        onChange={(_, newTab) => handleChangeTab(newTab)}
        data-cy="paymeny_subscription_options"
        variant={isSmDown ? 'scrollable' : 'standard'}
      />
      <div className={classes.methodContainer}>
        {paymentTypeData[activePayment].fields}
      </div>
      <Typography variant="h6" className={classes.paymentMessage}>
        Al ingresar los datos de pago autorizas a Nominapp para realizar una
        transacción recurrente de acuerdo al plan seleccionado. Si deseas
        cancelar o modificar el cobro, o si tienes alguna duda, puedes
        escribirnos a <OrganizationEmail />.
      </Typography>
    </>
  )
}

export default SummaryModal
