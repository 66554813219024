import avatar from 'assets/images/avatars/avatar1.png'

export const NEW_CONTRACTS = 'new_contracts'
export const HISTORY = 'history'
export const SIGN = 'sign'
export const AFFILIATIONS_DATA = 'affiliations_data'
export const FINISH_ONBOARDING = 'finish_onboarding'

const workersFormat = (query, id) => {
  const formattedWorkers = []

  if (id === NEW_CONTRACTS) {
    query.forEach((worker) => {
      formattedWorkers.push({
        id: worker.worker_id,
        state: NEW_CONTRACTS,
        name: worker.worker_name,
        created: worker.initial_day,
        avatar,
      })
    })
    return formattedWorkers
  }

  if (id === AFFILIATIONS_DATA) {
    query.forEach((worker) => {
      formattedWorkers.push({
        id: worker.affiliation_id,
        state: AFFILIATIONS_DATA,
        name: worker.worker_name,
        created: worker.created_at.split(' ')[0],
        avatar,
      })
    })
    return formattedWorkers
  }

  if (id === FINISH_ONBOARDING) {
    query.forEach((worker) => {
      formattedWorkers.push({
        name: worker.worker_name,
        state: FINISH_ONBOARDING,
        created: worker.finish_onboarding_date.split(' ')[0],
        avatar,
      })
    })
    return formattedWorkers
  }

  return []
}

export const getSteps = (workersOnboarding) => {
  const newContracts =
    workersOnboarding.find((stateWorker) => stateWorker[NEW_CONTRACTS])
      .new_contracts || []
  const workersAffiliations =
    workersOnboarding.find((stateWorker) => stateWorker[AFFILIATIONS_DATA])
      .affiliations_data || []
  const workersFinishOnboarding =
    workersOnboarding.find((stateWorker) => stateWorker[FINISH_ONBOARDING])
      .finish_onboarding || []

  return [
    {
      title: 'Validación de antecedentes',
      id: HISTORY,
      logo: true,
      titleRedirect: false,
      pending: true,
      workers: [],
    },
    {
      title: 'Nuevas Contrataciones',
      id: NEW_CONTRACTS,
      logo: false,
      titleRedirect: false,
      status: 'ok',
      workers: workersFormat(newContracts, NEW_CONTRACTS),
    },
    {
      title: 'Firma de documentos',
      id: SIGN,
      logo: true,
      titleRedirect: false,
      pending: true,
      workers: [],
    },
    {
      title: 'Afiliación a seguridad social',
      id: AFFILIATIONS_DATA,
      logo: true,
      titleRedirect: '/workers/affiliations',
      status: 'ok',
      workers: workersFormat(workersAffiliations, AFFILIATIONS_DATA),
    },
    {
      id: FINISH_ONBOARDING,
      title: 'Onboarding finalizado',
      logo: false,
      workers: workersFormat(workersFinishOnboarding, FINISH_ONBOARDING),
    },
  ]
}
export default { getSteps }
