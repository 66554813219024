import { makeStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import AppLogo from './Logo'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    minHeight: theme.mobileAppbar,
  },
  logo: {
    width: 180,
    height: 40,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  appBar: {
    minHeight: theme.mobileAppbar,
    zIndex: `${theme.zIndex.appBar + 1}`,
  },
}))

const AppMobileAppBar = ({ mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles()

  return (
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense" disableGutters>
          <AppLogo className={classes.logo} />
          <IconButton
            aria-label="abrir barra de navegación"
            onClick={handleDrawerToggle}
            size="small"
            className={classes.button}
            color="primary"
          >
            {mobileOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </Hidden>
  )
}

export default AppMobileAppBar
