import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
    },
  },
}))

const FormBox = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.box} {...props}>
      {children}
    </Paper>
  )
}

export default FormBox
