import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ForumIcon from '@material-ui/icons/Forum'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'

import Button from 'components/UI/Button/Button'

import { helpOnClick } from 'utils/chat'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  titleContainer: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  helpButtonsContainer: {
    display: 'grid',
    width: '100%',
    gap: theme.spacing(2.5),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '205px 160px',
      width: 'auto',
    },
  },
  helpButton: {
    backgroundColor: 'transparent',
  },
  supportBlogButton: {
    marginLeft: '0 !important',
  },
}))

const SubscriptionHeader = ({ frequentQuestionsRef }) => {
  const classes = useStyles()

  const showFrequentQuestions = () => {
    if (frequentQuestionsRef && frequentQuestionsRef.current) {
      frequentQuestionsRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const openSupportChat = () => {
    helpOnClick()
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">Suscripción</Typography>
      </div>
      <div className={classes.helpButtonsContainer}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ForumIcon />}
          className={classes.helpButton}
          onClick={showFrequentQuestions}
        >
          Preguntas frecuentes
        </Button>
        <Button
          startIcon={<ContactSupportIcon />}
          size="small"
          variant="outlined"
          onClick={openSupportChat}
          className={clsx(classes.helpButton, classes.supportBlogButton)}
        >
          Obtener ayuda
        </Button>
      </div>
    </div>
  )
}

export default SubscriptionHeader
