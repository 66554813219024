import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createPayment,
  deletePayment,
  getPlans,
  updateCurrentPayment,
  updatePreviousPayment,
} from 'services/organizer/companyPaymentService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, companyId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getPlans') return getPlans(companyId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, companyId, payment, paymentId } = mutationData

  if (mutationMethod === 'POST') return createPayment(companyId, payment)

  if (mutationMethod === 'DELETE') return deletePayment(paymentId)

  if (mutationMethod === 'PATCH') return updateCurrentPayment(payment)

  if (mutationMethod === 'PUT') return updatePreviousPayment(payment, paymentId)

  return null
}

const useCompanyPaymentService = ({
  serviceParams = { queryKey: 'getPlans' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCompanyPaymentResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const companyPaymentMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    companyPaymentQuery: {
      data,
      ...restCompanyPaymentResponse,
    },
    companyPaymentMutation,
  }
}

export default useCompanyPaymentService
