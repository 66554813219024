import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Avatar, Divider, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccessTime from '@material-ui/icons/AccessTime'
import MoreVertIcon from '@material-ui/icons/MoreVertRounded'

import IconButton from 'components/App/Header/IconButton'
import DropdownButton from 'components/UI/Button/Dropdown'

import { getCompanyId } from 'utils/company'
import { formatNumberDisplayDate } from 'utils/dateTime'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useWorkerService from 'utils/hooks/worker/workerService'

import { WORKER_AFFILIATIONS_INDEX } from 'config/routes'

import { AFFILIATIONS_DATA, FINISH_ONBOARDING, NEW_CONTRACTS } from './helpers'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 240,
    minHeight: 128,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerCard: {
    height: '60%',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  avatar: {
    height: '40px',
    width: '40px',
  },
  divider: {
    height: '2px',
  },
  created: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  dropDown: {
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: theme.spacing(4),
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
}))

const OnboardingCards = ({ user }) => {
  const classes = useStyles()
  const history = useHistory()
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const queryKey = ['getOnboardingWorkers', companyId]
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })

  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: { enabled: false },
  })

  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })

  const completeTask = () => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: user.id,
      },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }
    )
    popupState.close()
  }

  const completeOnboarding = () => {
    workerMutation.mutate(
      {
        mutationMethod: 'PATCH',
        worker: {
          finish_onboarding: true,
        },
        workerId: user.id,
      },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }
    )
    popupState.close()
  }

  const affiliationState = () => history.push(WORKER_AFFILIATIONS_INDEX())

  const items = [
    {
      id: 'completeTask',
      showItemTo: NEW_CONTRACTS, // or 'ALL' for show in all states
      name: 'Marcar como completada la tarea',
      onClick: completeTask,
    },
    {
      id: 'completeOnboarding',
      showItemTo: NEW_CONTRACTS,
      name: 'Completar el onboarding',
      onClick: completeOnboarding,
    },
    {
      id: 'affiliationState',
      showItemTo: AFFILIATIONS_DATA,
      name: 'Ver estado de la solicitud',
      onClick: affiliationState,
    },
  ]

  return (
    <Paper elevation={2} className={classes.card}>
      <div className={classes.headerCard}>
        <Avatar
          alt="user avatar"
          src={user.avatar}
          className={classes.avatar}
        />
        <Typography variant="subtitle2" color="textSecondary" noWrap>
          {user.name}
        </Typography>
        {user.state !== FINISH_ONBOARDING && (
          <DropdownButton
            popupState={popupState}
            variant="text"
            color="default"
            options={items.filter(
              ({ showItemTo }) =>
                showItemTo === user.state || showItemTo === 'ALL'
            )}
            className={classes.dropDown}
            Icon={
              <IconButton Icon={MoreVertIcon} {...bindTrigger(popupState)} />
            }
          />
        )}
      </div>
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.created}>
        <AccessTime color="disabled" />
        <Typography variant="body2" color="textSecondary">
          Creado el {formatNumberDisplayDate(user.created)}
        </Typography>
      </div>
    </Paper>
  )
}

export default OnboardingCards
