import { useFormikContext } from 'formik'

import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'

const useStyles = makeStyles((theme) => ({
  paymentFields: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '200px 150px',
  },
}))

const ExtraChargeFields = () => {
  const form = useFormikContext()
  const classes = useStyles()
  const { values, setValues } = form
  const { extra_charge_type: extraChargeType } = values

  const handleChangeType = (_, { props: { value: localExtraChargeType } }) => {
    let { payment_extra_charge: paymentExtraCharge } = values

    if (
      localExtraChargeType === 'percentage' &&
      (paymentExtraCharge > 100 || paymentExtraCharge < -100)
    ) {
      paymentExtraCharge = 0
    }

    setValues({
      ...values,
      payment_extra_charge: paymentExtraCharge,
      extra_charge_type: localExtraChargeType,
    })
  }

  const handleChangeValue = ({
    target: { value: localPaymentExtraCharge },
  }) => {
    if (extraChargeType !== 'percentage') return

    let newValue = parseFloat(localPaymentExtraCharge)

    if (localPaymentExtraCharge > 100) newValue = 100
    if (localPaymentExtraCharge < -100) newValue = -100

    setValues({ ...values, payment_extra_charge: newValue })
  }

  return (
    <div className={classes.paymentFields}>
      <FormField
        name="extra_charge_type"
        variant="select"
        onChange={handleChangeType}
        options={[
          { label: 'Valor monetario ($)', value: 'value' },
          { label: 'Porcentaje (%)', value: 'percentage' },
        ]}
      />
      <FormField
        name="payment_extra_charge"
        autoComplete="off"
        onChange={handleChangeValue}
        type={extraChargeType === 'percentage' ? 'number' : undefined}
        component={extraChargeType === 'percentage' ? 'text' : 'currency'}
        endAdornment={
          extraChargeType === 'percentage' && (
            <InputAdornment position="end">%</InputAdornment>
          )
        }
        allowNegative
      />
    </div>
  )
}

export default ExtraChargeFields
