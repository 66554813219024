import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import {
  formatSocialBenefits,
  getAllSocialBenefits,
} from 'services/worker/socialBenefitsService'

import * as routes from 'config/routes'

const getColumnsData = (worker) => {
  return [
    {
      Header: 'Prestación social',
      accessor: 'label',
      Cell: ({ row }) => (
        <Link
          to={routes.WORKER_CONTRACT_SOCIAL_BENEFITS_SHOW(
            worker?.id,
            row.original.contract_id,
            row.original.id
          )}
        >
          {row.original.label}
        </Link>
      ),
    },
    {
      Header: 'Fecha límite de pago',
      accessor: 'payment_limit_day',
      Cell: ({ row }) => formatDisplayDate(row.original.payment_limit_day),
      customWidth: '250px',
    },
    {
      Header: 'Días trabajados',
      accessor: 'days',
      customWidth: '175px',
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Base',
      accessor: 'base_value',
      Cell: ({ row }) => formatCurrency(row.original.base_value),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Saldo pendiente',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
      alignHeader: 'right',
      alignCell: 'right',
    },
  ]
}

const SocialBenefitsIndex = ({ match }) => {
  const queryClient = useQueryClient()
  const { worker } = useWorker()
  const { workerId, contractId } = match.params

  const fetchSocialBenefits = (pageIndex) => {
    return {
      queryKey: ['getAllSocialBenefits', pageIndex + 1],
      queryFunction: () =>
        getAllSocialBenefits(contractId, { page: pageIndex + 1 }),
      queryOptions: {
        onSuccess: ({ data }) => {
          data.forEach(formatSocialBenefits)
          queryClient.setQueryData(data)
        },
      },
    }
  }

  return (
    <Page
      documentTitle={`Prestaciones sociales de ${
        worker ? worker.fullName : 'trabajador'
      }`}
      header={
        <Typography variant="h5">
          Prestaciones sociales de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
    >
      <Table
        columns={getColumnsData(worker)}
        data={fetchSocialBenefits}
        options={{ toolbar: false }}
      />
    </Page>
  )
}

export default SocialBenefitsIndex
