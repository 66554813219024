import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SvgLoading from './SvgLoading'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingTop: '2rem',
    textAlign: 'center',
  },
}))

const Loading = ({ message = 'Cargando...' }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} data-cy="loading-page">
      <SvgLoading />
      {message && <Typography variant="h4">{message}</Typography>}
    </div>
  )
}

export default Loading
