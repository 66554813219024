import { useState } from 'react'

import { Tooltip, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'

const useStyles = makeStyles(() => ({
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clipboardIcon: {
    fontSize: 18,
  },
  clipboardButton: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    '&:focus': {
      border: 'none',
    },
  },
  overflowUserName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const UserName = ({ value }) => {
  const classes = useStyles()
  const [clipboard, setClipboard] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value)
    setClipboard(true)
    setTimeout(() => {
      setClipboard(false)
    }, 1000)
  }

  return (
    <div className={classes.row}>
      <Typography className={classes.overflowUserName} variant="body2">
        {value}
      </Typography>

      <Tooltip title="Acabas de copiar el usuario" open={clipboard} arrow>
        <IconButton
          className={classes.clipboardButton}
          onClick={handleCopyToClipboard}
        >
          <FileCopyOutlined className={classes.clipboardIcon} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default UserName
