import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dropzone from 'components/UI/Dropzone/Dropzone'
import FormField from 'components/UI/Formik/FormField/Index'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { getCompanyId } from 'utils/company'
import { getBase64FromFile } from 'utils/general'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import { isDataCached } from 'utils/reactQuery'

import { electronicPayrollCertificateOptions } from '../helpers'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(3),
  },
  certificateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  certificateOptions: {
    '& > label:nth-of-type(1)': {
      marginRight: theme.spacing(18),
    },
  },
  ownCertificateContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  requiredFileLabel: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
}))

const ElectronicFileInformation = () => {
  const classes = useStyles()
  const { values, setFieldValue, errors } = useFormikContext()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const electronicPayrollConfigurationCache =
    queryClient.getQueryData(['electronicPayrollStatus', companyId]) || {}

  useElectronicPayrollConfigurationService({
    serviceParams: {
      queryKey: ['electronicPayrollStatus', companyId],
    },
    queryOptions: {
      enabled: !isDataCached(electronicPayrollConfigurationCache),
      onSuccess: ({ data }) => {
        setFieldValue('company_certificate', data.company_certificate)
        setFieldValue('certificate_file_name', data.certificate_file_name)
      },
    },
  })

  useEffect(() => {
    if (electronicPayrollConfigurationCache?.data) {
      setFieldValue(
        'company_certificate',
        electronicPayrollConfigurationCache?.data.company_certificate
      )
      setFieldValue(
        'certificate_file_name',
        electronicPayrollConfigurationCache?.data.certificate_file_name
      )
    }
  }, [electronicPayrollConfigurationCache.data, setFieldValue])

  const handleUploadCertificate = async (file) => {
    if (file) {
      const fileBase64 = await getBase64FromFile(file)

      setFieldValue('certificate_file_name', file.name)
      setFieldValue('certificate_file', fileBase64)
    }
  }

  return (
    <>
      <Typography variant="h6" color="primary" gutterBottom>
        Asignación certificados electrónicos
      </Typography>
      <Typography variant="body1" className={classes.description}>
        En este proceso debes asignar el certificado con el que vas a firmar
        Nómina Electrónica.
      </Typography>
      <div className={classes.certificateContainer}>
        <Typography color="primary" variant="subtitle1">
          Certificados electrónicos
        </Typography>
        <TooltipInfoIcon
          title="Nominapp te proporciona todos los certificados electrónicos para que puedas emitir tu nómina electrónica.
            Sin embargo, también puedes cargar tus propios certificados electrónicos directamente"
          tooltipProps={{
            placement: xsDown ? 'top' : 'right',
            arrow: true,
          }}
        />
      </div>
      <FormField
        name="company_certificate"
        variant="radio-group"
        row={lgUp}
        className={clsx({ [classes.certificateOptions]: lgUp })}
        options={electronicPayrollCertificateOptions}
      />
      {values.company_certificate ? (
        <div className={classes.ownCertificateContainer}>
          <div>
            <Dropzone
              value={values.certificate_file_name}
              type="inline"
              accept=".pfx"
              handleAdd={handleUploadCertificate}
              fileType="application/x-pkcs12"
            />
            {errors?.certificate_file_name ? (
              <FormLabel className={classes.requiredFileLabel}>
                {errors?.certificate_file_name}
              </FormLabel>
            ) : null}
          </div>
          <FormField
            name="certificate_password"
            label="Contraseña del certificado"
          />
        </div>
      ) : null}
    </>
  )
}

export default ElectronicFileInformation
