import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'

const ConfirmEditActions = ({ onSave, onCancel }) => {
  return (
    <div>
      <Tooltip title="Guardar">
        <span>
          <IconButton size="small" onClick={onSave}>
            <CheckIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Cancelar">
        <span>
          <IconButton size="small" onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  )
}

export default ConfirmEditActions
