import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'

import useNotifications from 'utils/hooks/useNotifications'
import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'

import { downloadFileWebSocket } from 'services/httpService'

const useStyles = makeStyles(() => ({
  successProcess: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 360,
  },
}))

const useSendElectronicPayrolls = () => {
  const [statusStepOne, setStatusStepOne] = useState('')
  const [statusStepTwo, setStatusStepTwo] = useState('')

  const queryClient = useQueryClient()

  const {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
  } = useNotifications()
  const { closeSnackbar } = useSnackbar()
  const classes = useStyles()

  const { electronicPayrollMutation } = useElectronicPayrollPeriodService({
    queryOptions: { enabled: false },
  })

  const resetStatus = () => {
    setStatusStepOne('progress')
    setStatusStepTwo('progress')
  }

  // Ejecute the socket when the service is onSuccess
  const handleWebSocket = async (periodId, successMessage) => {
    const { data } = await downloadFileWebSocket(
      `/electronic_payroll/periods/${periodId}/payrolls_to_issue`
    )
    const successSocket =
      !data.errors ||
      (data.errors &&
        data.errors.every((error) => {
          return !!error.message?.details
        }))

    const errorSocket = data.errors && data.errors.length > 0

    const messageError =
      data.errors && data.errors.find((error) => !error.message?.details)

    queryClient.invalidateQueries('electronicPayrollPeriod')

    if (successSocket) {
      setStatusStepTwo('completed')
      closeSnackbar('electronicPayrollToIssue')
      showSuccessMessage(successMessage)
    } else {
      if (errorSocket && messageError !== null && messageError !== undefined) {
        showErrorMessage(messageError?.message?.error, {
          persist: true,
          preventDuplicate: true,
        })
      } else {
        showErrorMessage(
          'Ocurrió un error al intentar emitir tu nómina electrónica, por favor inténtalo nuevamente. Si el error persiste, escríbenos a soporte'
        )
      }
      setStatusStepTwo('error')
    }
  }

  const sendElectronicPayroll = (
    electronicPayrollIds,
    periodId,
    electronicPayrollMonth
  ) => {
    const successMessage = (
      <div className={classes.successProcess}>
        <b>¡Felicidades! Emitiste tu nómina electrónica</b>
        <span>
          Tu nómina electrónica para el periodo {electronicPayrollMonth} ha sido
          emitida con éxito. Puedes ver los detalles en tu{' '}
          <b>historial de periodos</b> de nómina electrónica
        </span>
      </div>
    )
    electronicPayrollMutation.mutate(
      {
        mutationMethod: 'PUT',
        periodId,
        electronicPayrolls: electronicPayrollIds,
      },
      {
        onSuccess: () => {
          // This is for E2E testing purposes
          if (window.Cypress) {
            closeSnackbar('electronicPayrollWarning')
            closeSnackbar('electronicPayrollToIssue')
            setStatusStepOne('completed')
            setStatusStepTwo('completed')
            showSuccessMessage(successMessage, { persist: true })
            return
          }
          setStatusStepOne('completed')
          handleWebSocket(periodId, successMessage)
        },
        onError: () => {
          setStatusStepOne('error')
          setStatusStepTwo('error')
        },
      }
    )
  }

  if (statusStepOne === 'progress' || statusStepTwo === 'progress')
    showInfoMessage(
      'Tu emisión de nómina electrónica está en proceso. Te avisaremos cuando termine',
      {
        key: 'electronicPayrollToIssue',
        preventDuplicate: true,
        persist: true,
      }
    )

  return {
    sendElectronicPayroll,
    resetStatus,
    statusStepOne,
    statusStepTwo,
  }
}

export default useSendElectronicPayrolls
