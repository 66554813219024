import * as yup from 'yup'

import { generateEmptyAreaOrPosition } from 'utils/company'
import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

const MAX_FILE_SIZE = 200000
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/x-icon']

function filterArrayValidation(value) {
  let newArr
  if (this.isType(value) && value !== null) {
    newArr = value.filter((e) => e.id || (e.name && e.name.trim()))

    if (newArr.length === 0) {
      newArr.push(generateEmptyAreaOrPosition())
    }
  }

  return newArr
}

export default [
  {
    title: 'Paso 1',
    description: 'Datos básicos',
    contentTitle: 'Datos básicos de tu empresa',
    schemaValidation: yup.object({
      name: yup.string().trim().required(),
      document_type: yup.string().nullable().required(),
      email: yup.string().nullable().email().required(),
      phone: yup.string().nullable().trim().required(),
      logo: yup
        .mixed()
        .nullable()
        .test(
          'fileSize',
          'El tamaño del archivo no debe superar los 200kb.',
          (value) => {
            if (!value) return true

            if (value instanceof File) {
              return value.size <= MAX_FILE_SIZE
            }

            return true
          }
        )
        .test('fileType', 'Formato de imagen no soportado.', (value) => {
          if (!value) return true

          if (value instanceof File) {
            return SUPPORTED_FORMATS.includes(value.type)
          }

          return true
        }),
      tax_document: yup.object().nullable(),
    }),
  },
  {
    title: 'Paso 2',
    description: 'Sedes',
    contentTitle: 'Agrega las sedes de tu empresa',
    contentText:
      'Las sedes son los lugares, ciudades o puntos de venta en donde tu empresa maneja personal y son importantes para asociar cada persona a uno de ellos.',
    contentTextExample: (
      <>
        1. Por ciudades: Bogotá, Cali, Bucaramanga y Medellín.
        <br />
        2. Por puntos de venta: Punto de Venta #1, Punto de Venta #2 y Sede
        Administrativa.
      </>
    ),
    schemaValidation: yup.object({
      locations: yup.array().required(),
    }),
  },
  {
    title: 'Paso 3',
    description: 'Centros de trabajo',
    contentTitle: 'Agrega los centros de trabajo de tu empresa',
    contentText:
      'Un centro de trabajo es según el Decreto 2012 de 2022 la agrupación de empleados que comparten la misma Actividad CIUU y por ende el mismo nivel de riesgo para ARL. ',
    schemaValidation: yup.object({}),
  },
  {
    title: 'Paso 4',
    description: 'Áreas',
    contentTitle: 'Agrega las áreas de tu empresa',
    contentText:
      'Las áreas las puedes ver como los "departamentos que maneja internamente tu empresa. Crea las áreas de tu empresa para poder asociar a las personas y tener así más información y control.',
    contentTextExample:
      ' Mercadeo, Operaciones, Comercial, Administrativa, Gerencia, etc.',
    schemaValidation: yup.object({
      areas: yup
        .array()
        .transform(filterArrayValidation)
        .of(
          yup.object({
            name: yup.string().trim().required(),
          })
        )
        .required(),
    }),
  },
  {
    title: 'Paso 5',
    description: 'Cargos',
    contentTitle: 'Agrega los cargos de tu empresa',
    contentText:
      'Crea los cargos de tu empresa, para poder asociar a las personas y poder tener así más información y control.',
    contentTextExample:
      ' Analista, Diseñador, Ejecutivo de Cuenta, Director, Administrativo, Gerente, etc.',
    schemaValidation: yup.object({
      positions: yup
        .array()
        .transform(filterArrayValidation)
        .of(
          yup.object({
            name: yup.string().trim().required(),
          })
        )
        .required(),
    }),
  },
  {
    title: 'Paso 6',
    description: 'Preferencias de pago',
    contentTitle: 'Configura como vas a pagar la nómina',
    schemaValidation: yup.object({
      payroll_frequency: yup.string().nullable().required(),
      payment_method: yup.string().nullable().required(),
      bank: yup.object().nullable().when('payment_method', {
        is: 'wire_transfer',
        then: yup.object().nullable().required(),
        otherwise: yup.object().nullable(),
      }),
      account_type: yup.object().nullable().when('payment_method', {
        is: 'wire_transfer',
        then: yup.object().nullable().required(),
      }),
      account_number: yup.string().nullable().when('payment_method', {
        is: 'wire_transfer',
        then: yup.string().trim().nullable().required(),
        otherwise: yup.string().nullable(),
      }),
      ss_operator: yup.object().nullable().required(),
      risk_provider: yup.object().nullable().required(),
      constitution_date: yup.date().nullable().required(),
      law_1429: yup.bool().nullable().required(),
      law_590: yup.bool().nullable().required(),
      law_1607: yup.bool().nullable().required(),
      decree_558: yup.bool().nullable().required(),
    }),
  },
  {
    title: 'Paso 7',
    description: 'Credenciales y documentación',
    contentTitle: 'Credenciales y documentación',
    contentText:
      'En caso de que desees realizar Afiliaciones a Seguridad Social, es necesario que adjuntes los siguientes documentos y agregues las credenciales a las operadoras correspondientes para que podamos afiliar con éxito a tus empleados.',
    schemaValidation: yup.object({
      identification_document: yup
        .mixed()
        .test('identification_document', null, (value) => {
          if (!(typeof value === 'string')) {
            return value instanceof File
          }

          return Boolean(value)
        }),
      legal_representative_document: yup
        .mixed()
        .test('legal_representative_document', null, (value) => {
          if (!(typeof value === 'string')) {
            return value instanceof File
          }

          return Boolean(value)
        }),
    }),
  },
]
