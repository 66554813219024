import { IconButton, useMediaQuery } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'

import Button from 'components/UI/Button/Button'

import useDownloadURI from 'utils/hooks/useDownloadURI'
import useFileName from 'utils/hooks/useFileName'

const useStyles = makeStyles((theme) => ({
  downloadContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(4.9),
    border: `1px dashed ${theme.palette.primary.light}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
  button: {
    height: 32,
  },
}))

const DownloadFile = ({ file }) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const downloadURI = useDownloadURI()
  const fileName = useFileName(file)

  const handleDownload = () => {
    downloadURI(file)
  }

  return (
    <div className={classes.downloadContainer}>
      <Typography variant="caption">
        {fileName || 'El documento no está disponible'}
      </Typography>
      {smUp ? (
        <Button
          size="small"
          onClick={handleDownload}
          className={classes.button}
          disabled={!file}
        >
          Descargar archivo
        </Button>
      ) : (
        <IconButton size="small" onClick={handleDownload} disabled={!file}>
          <GetAppIcon />
        </IconButton>
      )}
    </div>
  )
}

export default DownloadFile
