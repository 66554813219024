import * as yup from 'yup'

import Tooltip from '@material-ui/core/Tooltip'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({})

export const workCentersColumns = [
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: 'Código',
    accessor: 'code',
  },
  {
    Header: 'Actividad(CIIU)',
    accessor: 'ciiu_code',
    Cell: ({ row }) => row.original.activity_code?.ciiu_code,
  },
  {
    Header: 'Nivel de riesgo',
    accessor: 'risk_type',
    Cell: ({ row }) => row.original.activity_code?.risk_type,
    customWidth: '200px',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    Cell: ({ row }) => (
      <div>
        <Tooltip title={row.original.activity_code?.description} arrow>
          <p>
            {row.original.activity_code?.description.length > 100
              ? `${row.original.activity_code?.description.slice(0, 100)}...`
              : row.original.activity_code?.description}
          </p>
        </Tooltip>
      </div>
    ),
  },
]

export const getActions = (
  handleNewWorkCenter,
  handleShowHistory,
  handleDeleteWorkCenter,
  handleEditWorkCenter
) => {
  return [
    {
      id: 'addWorkCenter',
      tooltip: 'Agregar centro de trabajo',
      isFreeAction: true,
      onClick: () => {
        handleNewWorkCenter()
      },
    },
    (rowData) => ({
      id: 'editWorkCenter',
      tooltip: 'Editar centro de trabajo',
      icon: EditOutlinedIcon,
      onClick: () => {
        handleEditWorkCenter(rowData)
      },
    }),
    (rowData) => ({
      id: 'workCenterHistory',
      tooltip: 'Historial del centro de trabajo',
      icon: DescriptionOutlinedIcon,
      onClick: () => handleShowHistory(rowData.id),
    }),
    (rowData) => ({
      id: 'deleteWorkCenter',
      tooltip: 'Eliminar centro de trabajo',
      icon: DeleteForeverIcon,
      disabled: !rowData.deleteable,
      onClick: () => handleDeleteWorkCenter(rowData.id),
    }),
  ]
}

export const formatActivityData = (activityData) =>
  activityData?.map(({ id, ciiu_code: ciiuCode, description }) => ({
    id,
    name: `${ciiuCode} - ${description}`,
  }))
