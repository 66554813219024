import SvgIcon from '@material-ui/core/SvgIcon'

const SupportAgent = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 36" {...props}>
      <path d="M38 18.44C38 7.46 29.48 0 20 0C10.62 0 2 7.3 2 18.56C0.8 19.24 0 20.52 0 22V26C0 28.2 1.8 30 4 30H6V17.8C6 10.06 12.26 3.8 20 3.8C27.74 3.8 34 10.06 34 17.8V32H18V36H34C36.2 36 38 34.2 38 32V29.56C39.18 28.94 40 27.72 40 26.28V21.68C40 20.28 39.18 19.06 38 18.44Z" />
      <path d="M14 22C15.1046 22 16 21.1046 16 20C16 18.8954 15.1046 18 14 18C12.8954 18 12 18.8954 12 20C12 21.1046 12.8954 22 14 22Z" />
      <path d="M26 22C27.1046 22 28 21.1046 28 20C28 18.8954 27.1046 18 26 18C24.8954 18 24 18.8954 24 20C24 21.1046 24.8954 22 26 22Z" />
      <path d="M31.9999 16.06C31.0399 10.36 26.0799 6 20.0999 6C14.0399 6 7.51994 11.02 8.03994 18.9C12.9799 16.88 16.6999 12.48 17.7599 7.12C20.3799 12.38 25.7599 16 31.9999 16.06Z" />
    </SvgIcon>
  )
}

export default SupportAgent
