import { useState } from 'react'

const initialState = {
  open: false,
}

const usePayrollsAdvancesModal = () => {
  const [state, setState] = useState({
    ...initialState,
  })
  const openModal = () => {
    setState({ open: true })
  }

  const closeModal = () => {
    setState({ open: false })
  }

  return {
    payrollsAdvancesModalState: state,
    openPayrollsAdvancesModal: openModal,
    closePayrollsAdvancesModal: closeModal,
  }
}

export default usePayrollsAdvancesModal
