import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getWorkerPayslip,
  getWorkerProfile,
  getWorkerProfileOptions,
} from 'services/worker/workerProfileService'
import {
  createWorker,
  deleteWorker,
  getAllWorkers,
  getTotalsWorkers,
  getWorkerById,
  getWorkerOptions,
  updateWorker,
} from 'services/worker/workerService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId, searchParams } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getWorkerTotals') return getTotalsWorkers()

  if (currentQueryKey === 'getWorkerById') return getWorkerById(workerId)

  if (currentQueryKey === 'getWorkerProfile') return getWorkerProfile(workerId)

  if (currentQueryKey === 'getWorkerOptions') return getWorkerOptions(workerId)

  if (currentQueryKey === 'getWorkerProfileOptions')
    return getWorkerProfileOptions(workerId)

  if (currentQueryKey === 'getAllWorkers') return getAllWorkers(searchParams)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    workerId,
    worker,
    payrollId,
    payslip_type: payslipType,
    payroll_range: payrollRange,
  } = mutationData

  if (mutationMethod === 'DELETE') return deleteWorker(workerId)

  if (mutationMethod === 'POST') return createWorker(worker)

  if (mutationMethod === 'PATCH') return updateWorker(worker, workerId)

  if (mutationMethod === 'GET')
    return getWorkerPayslip(payrollId, {
      payslip_type: payslipType,
      payroll_range: payrollRange,
    })

  return null
}

const useWorkerService = ({
  serviceParams = { queryKey: 'getWorkerTotals' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restWorkerResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const workerMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    workerQuery: {
      data,
      ...restWorkerResponse,
    },
    workerMutation,
  }
}

export default useWorkerService
