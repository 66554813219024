import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import Header from 'components/Period/common/Header'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getMonthAndYear } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useNotifications from 'utils/hooks/useNotifications'
import useWageService from 'utils/hooks/worker/wageService'

import * as routes from 'config/routes'

import useModalErrors from './Errors/useModalErrors'
import {
  columnsData,
  getToogleRowSelection,
  getValuesToSend,
  validationSchema,
} from './helpers'

const WagesUpdate = () => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const history = useHistory()
  const openModal = useModalErrors()
  const { showSuccessMessage } = useNotifications()

  const { periodQuery } = usePeriodService({
    serviceParams: {
      queryKey: 'currentPeriod',
    },
  })

  const initialPeriodDay = periodQuery?.data?.initial_day

  const { wageQuery, wageMutation } = useWageService({
    serviceParams: { queryKey: 'indexWagesUpdate' },
  })

  const loadingPage =
    wageQuery.isLoading || wageMutation.isLoading || periodQuery.isLoading

  const getCustomRowStyle = (wage) => {
    const {
      date_new_salary: dateNewSalary,
      current_wage_initial_day: currentWageInitialDay,
      base_salary: baseSalary,
      new_base_salary: newBaseSalary,
    } = wage

    if (
      dateNewSalary <= currentWageInitialDay ||
      baseSalary === newBaseSalary
    ) {
      return {
        backgroundColor: theme.palette.grey[100],
      }
    }
    return {}
  }

  const pageTitle = `Detalle de salarios ${
    getMonthAndYear(initialPeriodDay) || ''
  }`

  const handleSubmit = (values) => {
    const valuesToSend = getValuesToSend(values.wages)

    wageMutation.mutate(
      {
        mutationMethod: 'PUT',
        wages: valuesToSend,
      },
      {
        onSuccess: async ({ data }) => {
          await queryClient.invalidateQueries('indexWagesUpdate')
          const mutationWithErrors = data?.errors.length > 0
          const workersUpdated = data?.success.length

          if (mutationWithErrors) return openModal(data.errors)

          history.push(routes.PERIOD_PAY_PAYROLL())

          return showSuccessMessage(
            `Has actualizado exitosamente a ${workersUpdated} ${getWordSingular(
              'personas',
              workersUpdated === 1
            )}`
          )
        },
      }
    )
  }

  return (
    <Page
      title="Actualizar salarios"
      documentTitle="Actualizar salarios"
      header={<Header label={pageTitle} />}
      isLoading={loadingPage}
    >
      <Typography variant="body1" gutterBottom>
        Selecciona las Personas a las que les quieres actualizar su salario.
        Puedes editar el valor y la fecha desde cuando aplica.
      </Typography>
      <br />
      <Formik
        initialValues={{ wages: wageQuery?.data || [] }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleSubmit: onSubmit }) => {
          const wagesFormatted = getToogleRowSelection(
            wageQuery?.data,
            values?.wages
          )

          return (
            <>
              <Form>
                <Table
                  data={wagesFormatted}
                  columns={columnsData}
                  options={{
                    selection: true,
                    selectionId: 'selected',
                    rowStyle: getCustomRowStyle,
                  }}
                  actions={[
                    {
                      id: 'updateWages',
                      tooltip: 'Actualizar salarios',
                      tooltipTitle:
                        'El salario mínimo subió, haz click y actualiza los salarios de tus empleados ahora',
                      isFreeAction: true,
                      onClick: () => onSubmit(),
                      disabled: wageMutation.isLoading,
                    },
                  ]}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    </Page>
  )
}

export default WagesUpdate
