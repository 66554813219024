import FormField from 'components/UI/Formik/FormField/Index'

import messages from 'messages/contract'

const InitialDayField = ({ contract, ...restProps }) => {
  return (
    <FormField
      name="initial_day"
      label="Fecha de contratación"
      variant="datepicker"
      minDate={contract?.prevEndDay}
      tooltipContent={messages.initial_day}
      {...restProps}
    />
  )
}

export default InitialDayField
