import { bindMenu, bindTrigger } from 'material-ui-popup-state/hooks'

import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const DropdownButton = ({
  title,
  options,
  popupState,
  menuClassName,
  iconButton = false,
  Icon,
  ...props
}) => {
  return (
    <>
      {!iconButton && !Icon ? (
        <Button {...bindTrigger(popupState)} {...props}>
          {title}
        </Button>
      ) : (
        Icon
      )}

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        className={menuClassName}
        disableScrollLock
      >
        {options && options.length > 0
          ? options.map((item) => {
              return (
                <MenuItem
                  onClick={item.onClick}
                  key={`menu_${item.id}_item`}
                  disabled={item.disabled}
                >
                  {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
                  <ListItemText>{item.name}</ListItemText>
                </MenuItem>
              )
            })
          : null}
      </Menu>
    </>
  )
}

export default DropdownButton
