import { useMemo, useState } from 'react'

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import BenefitsTable from 'components/Benefits/Atoms/BenefitsTable'

import CreditDetailModal from './CreditDetailModal'
import { creditsColumnsData } from './helpers'

const CreditsData = ({ data }) => {
  const [selectedCredit, setSelectedCredit] = useState(null)

  const handleCloseDetail = () => setSelectedCredit(null)

  const tableActions = useMemo(
    () => [
      ({ id: creditId, worker_id: workerId }) => ({
        id: 'openDetail',
        tooltip: 'Ver detalle',
        icon: VisibilityOutlinedIcon,
        onClick: () => {
          setSelectedCredit({
            id: creditId,
            status: 'active',
            workerId,
          })
        },
      }),
    ],
    []
  )

  return (
    <>
      <BenefitsTable
        data={data}
        actions={tableActions}
        columns={creditsColumnsData}
      />
      {selectedCredit !== null ? (
        <CreditDetailModal
          onCloseModal={handleCloseDetail}
          selectedCredit={selectedCredit}
        />
      ) : null}
    </>
  )
}

export default CreditsData
