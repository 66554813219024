import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import CustomField from 'components/UI/Formik/CustomField'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import CustomInputRow from 'components/Benefits/Atoms/CustomInputRow'

import InputsGroupContainer, {
  Horizontal,
  Column,
} from '../../InputsGroupContainer'
import AvistaLenderRelative from './AvistaLenderRelative'
import ParticipationPercentages from './ParticipationPercentages'

import { useCreditFormFields } from '../../hooks'

const FinancialInformation = () => {
  const { inputsFinancialInformationData } = useCreditFormFields()
  const { values, setFieldValue } = useFormikContext()
  const {
    salary,
    non_salary_income: nonSalaryIncome,
    salary_income: salaryIncome,
  } = values
  const totalIncome =
    (Number(salary) || 0) +
    (Number(nonSalaryIncome) || 0) +
    (Number(salaryIncome) || 0)

  useEffect(() => {
    setFieldValue('total_income', totalIncome)
  }, [totalIncome, setFieldValue])

  return (
    <>
      {inputsFinancialInformationData.map((section) => {
        return (
          <InputsGroupContainer
            key={section?.id}
            title={section?.title}
            gutterBottom={section?.gutterBottom}
          >
            {section?.rows.map((row) => {
              const Wrapper =
                row?.component === 'full-row' ? Horizontal : Column

              return (
                <Wrapper key={row?.id}>
                  {row?.fields.map((field) => {
                    if (field?.component === 'account-number') {
                      return (
                        <AccountNumberField
                          key={field?.name}
                          name={field?.name}
                          label={field?.label}
                          placeholder={field?.placeholder}
                          disabled={field?.disabled}
                        />
                      )
                    }

                    if (field?.component === 'custom-input-row') {
                      return (
                        <CustomInputRow
                          key={field?.name}
                          title={field?.title}
                          name={field?.name}
                          gutterBottom={field?.gutterBottom}
                          component={field?.type}
                          textfieldProps={field?.textfieldProps}
                        />
                      )
                    }

                    if (field?.name === 'bank') {
                      return (
                        <BankField
                          key={field?.name}
                          label={field?.label}
                          disabled={field?.disabled}
                        />
                      )
                    }

                    if (field?.component === 'avista-lender-relative') {
                      return (
                        <AvistaLenderRelative
                          key={field?.name}
                          title={field?.title}
                          name={field?.name}
                          gutterBottom={field?.gutterBottom}
                          component={field?.type}
                        />
                      )
                    }

                    if (field?.component === 'participation-percentages') {
                      return (
                        <ParticipationPercentages
                          key={field?.name}
                          title={field?.title}
                          name={field?.name}
                          gutterBottom={field?.gutterBottom}
                          component={field?.type}
                          buttonProps={field?.buttonProps}
                        />
                      )
                    }

                    return (
                      <CustomField
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        component={field?.component}
                        align="left"
                        options={field?.options}
                        data-cy={field?.dataCy}
                        disabled={field?.disabled}
                      />
                    )
                  })}
                </Wrapper>
              )
            })}
          </InputsGroupContainer>
        )
      })}
    </>
  )
}

export default FinancialInformation
