import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Typography, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'

import useModals from 'components/App/ModalsManager/useModals'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import SocialSecurityPaymentModal from 'components/Period/SocialSecurity/PaymentModal/SocialSecurityPaymentModal'
import useSocialSecurityPaymentModal from 'components/Period/SocialSecurity/PaymentModal/useSocialSecurityPaymentModal'
import ProviderModal from 'components/Period/SocialSecurity/ProviderModal'

import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import { PeriodContext } from '../../Payroll'

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.grey[500],
  },
}))

const PaySocialSecurityMenu = () => {
  const history = useHistory()
  const classes = useStyles()
  const {
    period,
    options: {
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      social_security_integration: socialSecurityIntegration,
    },
    payPreviousPayroll,
  } = useContext(PeriodContext)

  const {
    socialSecurityPaymentModalState,
    openSocialSecurityPaymentModal,
    closeSocialSecurityPaymentModal,
    activateSocialSecurityPaymentIntegration,
  } = useSocialSecurityPaymentModal(period, socialSecurityIntegration?.active)
  const featureState = usePremiumFeature('socialSecurity')
  const modals = useModals()
  const [socialSecurityProvider, setSocialSecurityProvider] = useState({
    showModal: false,
    selectedProvider: null,
    formType: null,
  })
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'paySocialSecurityMenu',
  })
  const popupBindTrigger = bindTrigger(popupState)
  const { showErrorMessage } = useNotifications()
  const isCurrentForm = Object.values(
    socialSecurityIntegration?.forms_keys
  ).some((value) => value !== null)

  const openPopover = (event) => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  // Close the select social security provider modal
  const handleCloseProviderModal = () =>
    setSocialSecurityProvider({
      showModal: false,
      selectedProvider: null,
      formType: null,
    })

  const openNextStepModal = (formType, provider) => {
    if (formType === 'employee') {
      if (
        socialSecurityIntegration?.forms_keys.employee &&
        socialSecurityIntegration?.active
      ) {
        history.push({
          pathname: payPreviousPayroll
            ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(period.id)
            : routes.PERIOD_SOCIAL_SECURITY_SHOW(period.id),
          state: { formType: 'employee', provider },
        })
      } else {
        openSocialSecurityPaymentModal({ provider })
      }
    } else if (formType === 'student') {
      if (
        socialSecurityIntegration?.forms_keys.student &&
        socialSecurityIntegration?.active
      ) {
        history.push({
          pathname: payPreviousPayroll
            ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(period.id)
            : routes.PERIOD_SOCIAL_SECURITY_SHOW(period.id),
          state: { formType: 'student_decree_055', provider },
        })
      } else {
        openSocialSecurityPaymentModal({
          formType: 'student_decree_055',
          provider,
        })
      }
    }
  }

  const handleClick = (formType = 'employee') => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }

    // Verify if there isn't an activated social security provider
    if (!socialSecurityIntegration?.active) {
      modals.openModal({
        id: `withoutSocialSecurityIntegration`,
        content: (
          <Typography variant="body1" className={classes.description}>
            La empresa no tiene activa alguna integración para pago de seguridad
            social.
          </Typography>
        ),
        modalProps: {
          header: 'Integración para pagar seguridad social',
          okText: 'Realizar integración',
          cancelText: 'Realizar después',
          onOk: () => {
            modals.closeAll()
            history.push(routes.SETTINGS_INTEGRATION_INDEX())
          },
          onCancel: () => modals.closeAll(),
          dialogProps: {
            fullWidth: true,
            maxWidth: 'xs',
          },
        },
      })
      return
    }

    // Check if the user can select between the 2 providers (arus | aportes_en_linea)
    if (socialSecurityIntegration?.providers_options?.length === 2) {
      if (!isCurrentForm) {
        setSocialSecurityProvider((previousState) => ({
          ...previousState,
          showModal: true,
          formType,
        }))
        return
      }
      openNextStepModal(formType)
      return
    }

    // If this point is reached, there's only one active integration
    const currentIntegration = socialSecurityIntegration?.providers_options?.[0]
    openNextStepModal(formType, currentIntegration.provider)
  }

  const handleSelectProvider = (value) =>
    setSocialSecurityProvider((previousState) => ({
      ...previousState,
      selectedProvider: value,
    }))

  const handleNextStepWithSelectedProvider = () => {
    if (!socialSecurityProvider.selectedProvider) {
      showErrorMessage(
        'Por favor selecciona el operador con el que deseas realizar el pago'
      )
      return
    }

    setSocialSecurityProvider((previousState) => ({
      ...previousState,
      showModal: false,
    }))
    openNextStepModal(
      socialSecurityProvider.formType,
      socialSecurityProvider.selectedProvider
    )
  }

  return (
    <>
      {socialSecurityFile && studentsSocialSecurityFile ? (
        <>
          <Button
            variant="text"
            startIcon={<ReceiptOutlinedIcon />}
            endIcon={
              featureState.isPremiumFeature ? <IconCrown /> : <ExpandMoreIcon />
            }
            {...popupBindTrigger}
            onClick={openPopover}
          >
            Pagar Seguridad Social
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
          >
            {socialSecurityFile && (
              <MenuItem onClick={() => handleClick()}>Empleados</MenuItem>
            )}
            {studentsSocialSecurityFile && (
              <MenuItem onClick={() => handleClick('student')}>
                Estudiantes (Decreto 055)
              </MenuItem>
            )}
          </Menu>
        </>
      ) : (
        <>
          {socialSecurityFile && (
            <Button
              variant="text"
              startIcon={<ReceiptOutlinedIcon />}
              onClick={() => handleClick()}
              endIcon={
                <IconCrown
                  isPremiumAction={featureState.isPremiumFeatureIcon}
                />
              }
            >
              Pagar Seguridad Social
            </Button>
          )}
          {studentsSocialSecurityFile && (
            <Button
              variant="text"
              startIcon={<ReceiptOutlinedIcon />}
              onClick={() => handleClick('student')}
              endIcon={
                <IconCrown
                  isPremiumAction={featureState.isPremiumFeatureIcon}
                />
              }
            >
              Pagar Seguridad Social (Estudiantes)
            </Button>
          )}
        </>
      )}
      {socialSecurityPaymentModalState.open && (
        <SocialSecurityPaymentModal
          state={socialSecurityPaymentModalState}
          handleClose={closeSocialSecurityPaymentModal}
          handleActivateIntegration={activateSocialSecurityPaymentIntegration}
        />
      )}
      <ProviderModal
        open={socialSecurityProvider.showModal}
        onCancel={handleCloseProviderModal}
        selectedProvider={socialSecurityProvider.selectedProvider}
        onChangeProvider={handleSelectProvider}
        onOk={handleNextStepWithSelectedProvider}
      />
    </>
  )
}

export default PaySocialSecurityMenu
