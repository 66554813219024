import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

const DeleteItemButton = ({
  deleteable = true,
  onClick,
  size,
  className,
  style,
  ...rest
}) => {
  return (
    <Tooltip
      title={deleteable ? 'Eliminar' : 'No se puede eliminar'}
      className={className}
    >
      {/* this <span> removes MUI warning */}
      <span>
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteable && onClick && onClick()
          }}
          size={size}
          disabled={!deleteable}
          style={style}
          {...rest}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default DeleteItemButton
