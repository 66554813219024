import { useContext } from 'react'

import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import { PeriodContext } from '../Payroll'

const Payslips = () => {
  const {
    options: {
      payslips_pdf: payslispsPdf,
      payslips_zip: payslispsZip,
      payslips_emails: payslipsEmails,
    },
    openPayslipModal,
    handleToggleLoadingModal,
  } = useContext(PeriodContext)

  const featureState = usePremiumFeature('payslip')

  const handleClickGeneratePayslips = async ({
    file_format: fileFormat,
    isSendEmail,
  }) => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    handleToggleLoadingModal(true)
    await openPayslipModal({ file_format: fileFormat, isSendEmail })
    handleToggleLoadingModal(false)
  }

  return payslispsPdf || payslispsZip || payslipsEmails ? (
    <CardReview>
      <Typography variant="h5">Colillas de pago</Typography>
      {payslispsZip && (
        <Button
          variant="text"
          startIcon={<AppsOutlinedIcon />}
          onClick={() => handleClickGeneratePayslips({ file_format: 'pdf' })}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Agrupadas
        </Button>
      )}
      {payslispsPdf && (
        <Button
          variant="text"
          startIcon={<ContactsOutlinedIcon />}
          onClick={() => handleClickGeneratePayslips({ file_format: 'zip' })}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Individuales
        </Button>
      )}
      {payslipsEmails && (
        <Button
          variant="text"
          startIcon={<MailOutlineIcon />}
          onClick={() => handleClickGeneratePayslips({ isSendEmail: true })}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Notificar pago vía email
        </Button>
      )}
    </CardReview>
  ) : null
}

export default Payslips
