import clsx from 'clsx'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import {
  IconButton,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import GetAppIcon from '@material-ui/icons/GetApp'
import MoreVertIcon from '@material-ui/icons/MoreVertRounded'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import DropdownButton from 'components/UI/Button/Dropdown'

import { formatNumberDisplayDate } from 'utils/dateTime'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import { FoldersContainerContext } from './Index'
import { documentsActions } from './helpers'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    display: 'flex',
    padding: theme.spacing(2.5),
    boxShadow:
      '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2),
    [theme.breakpoints.down(1024)]: {
      justifyContent: 'space-between',
    },
  },
  cardItem: {
    width: 224,
    height: 192,
    flexDirection: 'column',
  },
  iconContainer: {
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  topContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topContentList: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up(1024)]: {
      width: '40%',
    },
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  metaContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-end',
    '& > svg': {
      fontSize: 18,
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: theme.spacing(1),
    [theme.breakpoints.up(1024)]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '60%',
    },
  },

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
    '& .MuiIconButton-root + .MuiIconButton-root': {
      marginLeft: 0,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    width: 'initial',
    height: 'initial',
    padding: 0,
    marginRight: theme.spacing(0.5),
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  checkboxList: {
    marginRight: 0,
  },
  iconButton: {
    backgroundColor: 'transparent',
    width: 'initial',
    height: 'initial',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  iconButtonList: {
    border: '2px solid',
    width: 40,
    height: 40,
    '&:focus': {
      border: '2px solid',
    },
  },
}))

const FileCard = ({ showAsCard, card, isAffiliationFolder }) => {
  const classes = useStyles()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { setActionModal } = useContext(FoldersContainerContext)
  const downloadURI = useDownloadURI()

  const handleDownloadFile = () => downloadURI(card.url_file)

  const options = [
    {
      id: documentsActions.move_file.id,
      name: documentsActions.move_file.callToAction,
      onClick: () => {
        setActionModal({ type: documentsActions.move_file.id, context: card })
        popupState.close()
      },
    },
    {
      id: documentsActions.change_file_name.id,
      name: documentsActions.change_file_name.callToAction,
      onClick: () => {
        setActionModal({
          type: documentsActions.change_file_name.id,
          context: card,
        })
        popupState.close()
      },
    },
    {
      id: documentsActions.delete_file.id,
      name: documentsActions.delete_file.callToAction,
      onClick: () => {
        setActionModal({ type: documentsActions.delete_file.id, context: card })
        popupState.close()
      },
    },
  ]

  return (
    <Paper
      elevation={24}
      className={clsx(classes.card, {
        [classes.listItem]: !showAsCard,
        [classes.cardItem]: showAsCard,
      })}
    >
      {showAsCard ? (
        <div className={classes.content}>
          <div className={classes.topContent}>
            <div className={classes.iconContainer}>
              <DescriptionOutlinedIcon />
            </div>

            <div className={classes.actionsContainer}>
              {/* <Checkbox
                className={classes.checkbox}
                onClick={onSelectFile}
                checked={selectedFiles.includes(card.id)}
                value={selectedFiles.includes(card.id)}
              /> */}
              <Tooltip title="Descargar" arrow>
                <IconButton
                  size="small"
                  className={classes.iconButton}
                  onClick={handleDownloadFile}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              {!isWorker && !isAffiliationFolder && (
                <DropdownButton
                  popupState={popupState}
                  variant="text"
                  color="default"
                  options={options}
                  className={classes.dropDown}
                  iconButton
                  Icon={
                    <IconButton
                      size="small"
                      className={classes.iconButton}
                      {...bindTrigger(popupState)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                />
              )}
            </div>
          </div>
          <div className={classes.bottomContent}>
            <Typography variant="subtitle1" noWrap>
              {card.name}
            </Typography>
            <div className={classes.metaContainer}>
              <QueryBuilderIcon />
              <Typography variant="caption">{`Creada el ${formatNumberDisplayDate(
                card.created_at
              )}`}</Typography>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.topContentList}>
            {/* <Checkbox
              className={clsx(classes.checkbox, classes.checkboxList)}
              onClick={onSelectFile}
              checked={selectedFiles.includes(card.id)}
            /> */}
            <div className={classes.iconContainer}>
              <FolderOpenIcon />
            </div>
            <Typography variant="subtitle1" align="left" noWrap>
              {card.name}
            </Typography>
          </div>
          <div className={classes.rightContent}>
            <div className={classes.metaContainer}>
              <QueryBuilderIcon />
              <Typography variant="caption">{`Creada el ${formatNumberDisplayDate(
                card.created_at
              )}`}</Typography>
            </div>

            <div>
              <Tooltip title="Descargar" arrow>
                <IconButton
                  className={clsx(classes.iconButton, classes.iconButtonList)}
                  onClick={handleDownloadFile}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              {!isWorker && !isAffiliationFolder && (
                <>
                  <Tooltip title="Mover carpeta" arrow>
                    <IconButton
                      className={clsx(
                        classes.iconButton,
                        classes.iconButtonList
                      )}
                      onClick={() =>
                        setActionModal({
                          type: documentsActions.move_file.id,
                          context: card,
                        })
                      }
                    >
                      <UnarchiveOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar" arrow>
                    <IconButton
                      className={clsx(
                        classes.iconButton,
                        classes.iconButtonList
                      )}
                      onClick={() =>
                        setActionModal({
                          type: documentsActions.change_file_name.id,
                          context: card,
                        })
                      }
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar" arrow>
                    <IconButton
                      className={clsx(
                        classes.iconButton,
                        classes.iconButtonList
                      )}
                      onClick={() =>
                        setActionModal({
                          type: documentsActions.delete_file.id,
                          context: card,
                        })
                      }
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Paper>
  )
}

export default FileCard
