import { useState } from 'react'

import { Button, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Table from 'components/UI/Table/Table'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import usePeriodService from 'utils/hooks/organizer/periodService'
import useNotifications from 'utils/hooks/useNotifications'

import { getAllElectronicPayrolls } from 'services/payroll/electronicPayrollPeriodService'

import NewPeriodModal from '../Modals/NewPeriodModal'
import PayrollHistoryFilter from '../PayrollHistoryFilter/Index'
import ElectronicPayrollHistoryActions from './ElectronicPayrollHistoryActions'
import { columnsData, useGetActions } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  groupButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

const ElectronicPayrollHistory = ({ activeTab }) => {
  const classes = useStyles()
  const { periodMutation } = usePeriodService()
  const { showInfoMessage } = useNotifications()
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const [
    newElectronicPayrollPeriodModalOpen,
    setNewElectronicPayrollPeriodModalOpen,
  ] = useState(false)

  const fetchElectronicPayrolls = (pageIndex) => {
    return {
      queryKey: [
        'getAllElectronicPayrolls',
        pageIndex + 1,
        selectedMonth?.id,
        selectedYear?.id,
      ],
      queryFunction: () =>
        getAllElectronicPayrolls({
          page: pageIndex + 1,
          month: selectedMonth?.id,
          year: selectedYear?.id,
        }),
    }
  }

  const handleOrganizerElectronicPeriodAction = (
    electronicPayrollPeriodId,
    perform
  ) => {
    periodMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationAction: perform,
        electronicPayrollPeriodId,
      },
      {
        onSuccess: ({ message }) => showInfoMessage(message),
      }
    )
  }

  const renderToolbar = () => (
    <ToolbarContainer className={classes.container}>
      <PayrollHistoryFilter
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        selectedYear={selectedYear}
      />
      <div className={classes.groupButtons}>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setNewElectronicPayrollPeriodModalOpen(true)}
        >
          Crear nuevo periodo
        </Button>
        <ElectronicPayrollHistoryActions />
      </div>
    </ToolbarContainer>
  )

  const actions = useGetActions(handleOrganizerElectronicPeriodAction)

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchElectronicPayrolls}
        actions={actions}
        components={{
          Toolbar: renderToolbar,
        }}
        data-cy="electronic_payrolls-table"
      />
      {newElectronicPayrollPeriodModalOpen && (
        <NewPeriodModal
          visible={newElectronicPayrollPeriodModalOpen}
          handleClose={() => setNewElectronicPayrollPeriodModalOpen(false)}
          activeTab={activeTab}
        />
      )}
    </>
  )
}

export default ElectronicPayrollHistory
