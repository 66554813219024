import Typography from '@material-ui/core/Typography'

import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { ReportsNovelties } from '../ActionGroup/Reports'
import { NoveltiesUploadMenu } from '../ActionGroup/Novelties'

const PayrollNovelties = () => {
  return (
    <CardReview>
      <Typography variant="h5">Novedades</Typography>
      <ReportsNovelties />
      <NoveltiesUploadMenu />
    </CardReview>
  )
}

export default PayrollNovelties
