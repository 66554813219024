import { useState } from 'react'
import { Form, Formik } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import {
  paymentTypeData,
  getSchemaValidation,
  getInitialValues,
} from 'components/Subscription/OldSubscription/Paywall/Payment/Form/helpers'
import SummaryModal from 'components/Subscription/NewSubscription/Index/PaymentMethod/PaymentModalSummary'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'

import usePayment from '../usePayment'

const useStyles = makeStyles((theme) => ({
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(2),
    margin: theme.spacing(6, 0, 4),
  },
}))

const PaymentModal = ({
  handleClose,
  currentPlan,
  extraWorkersEdit,
  handlePreviousModal,
}) => {
  const classes = useStyles()
  const { company, subscription } = useUser()
  const [activePayment, setActivePayment] = useState(() => {
    let activePaymentCategory = subscription?.payment_category

    // some payment categories are not available in this view
    if (
      !['automatic_debit', 'credit_card', 'pse'].includes(activePaymentCategory)
    ) {
      activePaymentCategory = 'credit_card' // default
    }
    if (activePaymentCategory === 'automatic_debit' && extraWorkersEdit) {
      activePaymentCategory = 'credit_card' // default because automatic debit does not supported
    }
    return activePaymentCategory
  })
  const {
    handleChangeTab,
    tabsData,
    onSubmit,
    updatePaymentMethod,
    formRef,
    isLoading,
  } = usePayment(handleClose, currentPlan, extraWorkersEdit)

  const handleChangeTabLocal = (newtab) => {
    setActivePayment(newtab)
    handleChangeTab()
  }

  const handleSubmit = (values) => onSubmit(values, activePayment)

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={getInitialValues(company)}
      validationSchema={getSchemaValidation(activePayment)}
      validateOnChange={false}
      enableReinitialize
    >
      {() => {
        return (
          <Form>
            <SummaryModal
              tabsData={tabsData}
              activePayment={activePayment}
              paymentTypeData={paymentTypeData}
              handleChangeTab={handleChangeTabLocal}
            />
            <section className={classes.buttonSection}>
              <Button
                variant="outlined"
                onClick={extraWorkersEdit ? handlePreviousModal : handleClose}
              >
                {!extraWorkersEdit ? 'Cancelar' : 'Atras'}
              </Button>
              <Button onClick={updatePaymentMethod} loading={isLoading}>
                Procesar pago
              </Button>
            </section>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PaymentModal
