import useLocalStorage from '@rehooks/local-storage'
import { useEffect, useRef } from 'react'
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom'
import { useHistory } from 'react-router-dom'

import {
  checkLocalStorageAuthKeys,
  hasTokenExpired,
  isExcludedPath,
  isInsideAppSignUp,
} from 'utils/auth'

import { refreshSession } from 'services/auth/sessionService'

import { LOGIN, LOGOUT } from 'config/routes'

const useSessionRefresh = () => {
  const history = useHistory()
  const [expires, setExpires] = useLocalStorage('expires')
  const [authToken, setAuthToken] = useLocalStorage('auth_token')
  const timer = useRef()

  useEffect(() => {
    // Schedule refreshing 2 minutes before the current expiration time is reached
    const delay = expires * 1000 - 120000 - Date.now()

    // If there's no expires value the first time, return sooner
    if (!expires) return null

    // If for some strange reason the session did not refresh after 18 minutes, close it abruptly
    if (hasTokenExpired() || !authToken) {
      history.replace(LOGOUT)
    }

    if (expires) {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(async () => {
        if (!isExcludedPath(window.location.pathname) && !isInsideAppSignUp()) {
          try {
            const { data } = await refreshSession()

            if (data) {
              batchedUpdates(() => {
                setExpires(data?.expires)
                setAuthToken(data?.token)
              })
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
      }, delay)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [authToken, expires, history, setAuthToken, setExpires])

  useEffect(() => {
    const handleLocalStorageChange = (event) => {
      const { key, newValue } = event

      // Check if any of the keys (expires or auth_token) are changed manually
      if (
        checkLocalStorageAuthKeys(key, newValue) &&
        !isExcludedPath(window.location.pathname)
      ) {
        history.replace(LOGOUT)
      }

      return undefined
    }

    window.addEventListener('storage', handleLocalStorageChange)

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange)
    }
  }, [history])

  // Check for logout and redirect in all tabs other than the one with focus
  useEffect(() => {
    const handleLocalStorageChange = (event) => {
      const { key, oldValue, newValue } = event

      if (key === 'logout' && newValue !== oldValue) {
        history.replace(LOGIN)
        document.location.reload()
      }

      return undefined
    }

    window.addEventListener('storage', handleLocalStorageChange)

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange)
    }
  }, [history])
}

export default useSessionRefresh
