import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import { Link, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useIntegrationService from 'utils/hooks/settings/integrationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { arusIntegrationDocumentType } from 'utils/worker'

import * as routes from 'config/routes'

import messagesIntegration from 'messages/integration'
import messagesNotification from 'messages/notification'

import {
  BoldName,
  getInitialValues,
  getValidationSchema,
  integrationsProviders,
} from '../helpers'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'inline-flex',
  },
  title: {
    fontWeight: 'normal',
  },
  logo: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  description: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
  },
  documentContainer: {
    display: 'grid',
    gridTemplateColumns: '45% 1fr',
    gap: theme.spacing(1),
  },
  signUpText: {
    color: theme.palette.grey[500],
    marginTop: theme.spacing(3),
  },
}))

const SettingsIntegrationModal = ({ state, handleClose, submitCallback }) => {
  const classes = useStyles()
  const { open, integration } = state
  const { provider } = integration
  const messages = messagesIntegration[provider]
  const { showSuccessMessage, showInfoMessage } = useNotifications()
  const history = useHistory()
  const { handleError } = useErrorHandler()
  const { integrationsMutation } = useIntegrationService({
    queryOptions: {
      enabled: false,
    },
  })
  const {
    name,
    logo,
    logoWidth,
    logoHeight,
    description,
    signUpLink,
    usernameTooltip,
    passwordTooltip,
    usernameLabel,
    passwordLabel,
  } = integrationsProviders[provider]

  const handleSubmit = (values, form) => {
    let credentials = {}

    if (provider === 'arus') {
      credentials = {
        username: `${values.document_type}${values.id_number}`,
        token: values.token,
      }
    } else {
      credentials = {
        username: values.username,
        token: values.token,
      }
    }

    integrationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        integration: {
          ...credentials,
          provider,
          active: true,
        },
      },
      {
        onSuccess: () => {
          if (provider === 'alegra') {
            showInfoMessage(messages.how_configure_accounts)
          }
          showSuccessMessage(messagesNotification.INTEGRATION_ACTIVATION)

          trackEvent(integrationEvent.INTEGRATION_DONE, provider)
          if (submitCallback) {
            submitCallback()
          } else {
            history.push(routes.SETTINGS_INTEGRATION_INDEX())
          }
          handleClose()
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ code: '0003' }],
            notistackOptions: {
              preventDuplicate: true,
            },
          })
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(provider)}
      validationSchema={getValidationSchema(provider)}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps

        return (
          <Modal
            open={open}
            header={
              <Typography variant="h5">
                <span className={classes.title}>Vamos a integrarnos con</span>
                <br />
                {name}
                <img
                  src={logo}
                  alt={`Isotipo de ${name}`}
                  width={logoWidth}
                  height={logoHeight}
                  className={classes.logo}
                />
              </Typography>
            }
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Realizar integración"
            isLoading={integrationsMutation.isLoading}
            dialogProps={{
              maxWidth: 'xs',
              fullWidth: true,
            }}
          >
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
            <Form>
              {provider !== 'arus' ? (
                <>
                  <FormField
                    name="username"
                    label={usernameLabel}
                    tooltipTextContent={usernameTooltip}
                    autoComplete="off"
                    data-cy={`${provider}-username`}
                  />
                  <FormField
                    type="password"
                    name="token"
                    label={passwordLabel}
                    tooltipTextContent={passwordTooltip}
                    autoComplete="off"
                    data-cy={`${provider}-token`}
                  />
                </>
              ) : (
                <>
                  <div className={classes.documentContainer}>
                    <FormField
                      name="document_type"
                      label="Tipo de documento"
                      options={arusIntegrationDocumentType}
                      variant="select"
                    />
                    <FormField
                      name="id_number"
                      label="Número de documento"
                      variant="number"
                      thousandSeparator={false}
                    />
                  </div>
                  <FormField type="password" name="token" label="Contraseña" />
                </>
              )}
            </Form>
            <Typography variant="body2" className={classes.signUpText}>
              ¿Sin cuenta en <BoldName>{name}</BoldName>?{' '}
              <Link target="_blank" href={signUpLink}>
                Crea una cuenta aquí.
              </Link>
            </Typography>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default SettingsIntegrationModal
