import { useState } from 'react'

import Box from '@material-ui/core/Box'
import Page from 'components/UI/Page/Page'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { tabsData } from './helpers'

const OrganizerFiles = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = (e, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <Page header="Acciones generales">
      <Box pb={1} boxShadow={4}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          tabs={tabsData}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        />

        <Box width="1" py={1} px={3}>
          {tabsData[activeTab].content}
        </Box>
      </Box>
    </Page>
  )
}

export default OrganizerFiles
