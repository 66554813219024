import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PauseCircleIcon from '@material-ui/icons/PauseCircleFilled'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    padding: theme.spacing(3),
    gridTemplateColumns: '35px 1fr 35px',
    alignItems: 'center',
    columnGap: theme.spacing(2.5),
    '& > h2': {
      justifySelf: 'center',
    },
  },
  activeStep: {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  arrowIconContainer: {
    backgroundColor: theme.palette.primary.main,
    width: 34,
    height: 30,
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completedIcon: {
    color: theme.palette.success.main,
  },
  defaultColor: {
    color: theme.palette.grey[400],
  },
  completedColor: {
    color: theme.palette.success.main,
  },
  completedTextColor: {
    color: theme.palette.grey[500],
  },
  activeColor: {
    color: theme.palette.primary.main,
  },
  activeTextColor: {
    color: theme.palette.grey[700],
  },
  textContainer: {
    width: '100%',
    textAlign: 'left',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const StepCard = ({
  status = 'pending',
  number,
  title,
  description,
  disabled,
  path,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <ButtonBase
      disabled={disabled}
      component={path ? Link : Box}
      to={path || null}
      onClick={onClick}
    >
      <Paper
        className={clsx(classes.container, {
          [classes.activeStep]: status === 'active',
        })}
        elevation={3}
      >
        <Typography
          variant="h2"
          className={clsx(classes.defaultColor, {
            [classes.completedColor]: status === 'completed',
            [classes.activeColor]: status === 'active',
          })}
        >
          {number}
        </Typography>
        <div className={classes.textContainer}>
          <Typography
            variant="subtitle2"
            className={clsx(classes.defaultColor, {
              [classes.completedTextColor]: status === 'completed',
              [classes.activeTextColor]: status === 'active',
            })}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.defaultColor, {
              [classes.completedTextColor]: status === 'completed',
              [classes.activeTextColor]: status === 'active',
            })}
          >
            {description}
          </Typography>
        </div>
        {status === 'pending' ? (
          <PauseCircleIcon className={classes.defaultColor} fontSize="large" />
        ) : null}
        {status === 'active' ? (
          <ArrowForwardIcon className={classes.arrowIcon} fontSize="large" />
        ) : null}
        {status === 'completed' ? (
          <CheckCircleIcon className={classes.completedIcon} fontSize="large" />
        ) : null}
      </Paper>
    </ButtonBase>
  )
}

export default StepCard
