import { useContext, useMemo } from 'react'

import Chip from '@material-ui/core/Chip'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { getCompanyId } from 'utils/company'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import { WorkerIndexContext } from '../Index'

const WorkerIndexToolbarTabs = () => {
  const { totals, filter, handleChangeFilter, workerGroup } = useContext(
    WorkerIndexContext
  )
  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
    },
  })
  const tabs = useMemo(() => {
    if (workerGroup === 'new') return []

    if (workerGroup === 'active') {
      const activeWorkers = totals?.filter(
        (category) =>
          !['all', 'inactive', 'terminated_worker', 'new_contracts'].includes(
            category?.coded_name
          )
      )

      return activeWorkers?.map((category) => {
        if (category?.coded_name === 'active') {
          return {
            ...category,
            name: 'Todos',
          }
        }
        return category
      })
    }

    if (workerGroup === 'inactive') {
      const inactiveWorkers = totals?.filter((category) =>
        ['inactive', 'terminated_worker'].includes(category?.coded_name)
      )
      const totalDisabledWorkers = inactiveWorkers?.reduce(
        (acc, category) => acc + (category?.size || 0),
        0
      )

      return [
        {
          coded_name: 'disabled',
          name: 'Todos',
          size: totalDisabledWorkers,
        },
      ].concat(inactiveWorkers)
    }

    return []
  }, [totals, workerGroup])

  return totals ? (
    <Tabs
      value={filter}
      onChange={handleChangeFilter}
      tabs={(hrNewModule?.value === 'on' ? tabs : totals)?.map((category) => ({
        key: category.coded_name,
        label: (
          <>
            {category.name}
            <Chip label={category.size} style={{ marginLeft: 5 }} />
          </>
        ),
      }))}
      variant="scrollable"
      data-cy="workers_tabs"
    />
  ) : null
}

export default WorkerIndexToolbarTabs
