import messages from 'messages/payroll'

import DateRangeContent from './DateRangeContent'

export default function Holidays({
  holidays,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  payroll,
}) {
  const noveltyCode = 'enjoyed_days'

  return (
    <DateRangeContent
      title="Días disfrutados en el período"
      data={holidays.enjoyed_days}
      onAddNovelty={onAddNovelty}
      onChangeNovelty={onChangeNovelty}
      onDeleteNovelty={onDeleteNovelty}
      noveltyType="holidays"
      noveltyCode={noveltyCode}
      contentTooltip={messages[noveltyCode]}
      selectedDays={selectedDays}
      payroll={payroll}
    />
  )
}
