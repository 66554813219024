import cardValidator from 'card-validator'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'

import amexImg from 'assets/images/views/subscription/amex.svg'
import mastercardImg from 'assets/images/views/subscription/mastercard.svg'
import visaImg from 'assets/images/views/subscription/visa.svg'

const useStyles = makeStyles((theme) => ({
  // TODO: Refactor these styles when the new subscription view is available to everyone
  container: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '300px auto',
    [theme.breakpoints.down(1030)]: {
      gridTemplateColumns: 'minmax(220px, 300px)',
    },
  },
  logos: {
    marginBottom: 4,
    display: 'flex',
    alignSelf: 'flex-end',
    '& img': {
      marginRight: theme.spacing(2.5),
    },
    '& img.disabled': {
      opacity: 0.2,
    },
  },
  logosNewSubscription: {
    marginTop: theme.spacing(1.75),
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(2.5),
    },
    '& img.disabled': {
      opacity: 0.2,
    },
  },
  cardNumber: {
    maxWidth: 350,
    fontFamily: 'Roboto Mono, monospace',
  },
  cardNumberNewSubscription: {
    fontFamily: 'Roboto Mono, monospace',
  },
}))

const CreditCardNumberField = () => {
  const [cardType, setCardType] = useState()
  const form = useFormikContext()
  const classes = useStyles()

  const { values } = form

  const handleValueChange = ({ value }) => {
    const ccType = cardValidator.number(value).card?.type

    setCardType(ccType)

    form.setValues({
      ...values,
      card_number: value,
    })
  }

  const getClassName = (logoType) => {
    return logoType === cardType || !cardType ? '' : 'disabled'
  }

  return (
    <div className={classes.container}>
      <FormField
        name="card_number"
        label="Número de la tarjeta"
        variant="number"
        isNumericString
        format="#### #### #### ####"
        onValueChange={handleValueChange}
        inputProps={{ autoComplete: 'cc-number' }}
        className={classes.cardNumber}
      />
      <div className={classes.logos}>
        <img
          className={getClassName('visa')}
          height={30}
          width={53}
          src={visaImg}
          alt="Visa Logo"
        />
        <img
          className={getClassName('mastercard')}
          height={25}
          width={40}
          src={mastercardImg}
          alt="MasterCard Logo"
        />
        <img
          className={getClassName('american-express')}
          height={25}
          width={25}
          src={amexImg}
          alt="American Express Logo"
        />
      </div>
    </div>
  )
}

export default CreditCardNumberField
