import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import { getCompanyId } from 'utils/company'
import { getDistributor } from 'utils/auth'

const useDianMaintenance = () => {
  const date = new Date()
  const { dianMaintenance } = useFeatureFlags({
    flags: ['dianMaintenance'],
    trackingMode: 'attributes',
    attributes: {
      date,
      companyTest:
        getCompanyId() === '2ecc9e2f-fa44-4396-b11c-f84bdfbe4b9c' ||
        getDistributor() === 'nominapp_demo',
    },
  })

  let maintenance = false
  let maintenanceDate = ''

  if (dianMaintenance.value === 'on') {
    maintenance = true
    maintenanceDate = 'las 10 p.m del 11/01/2022 y las 10 a.m. del 12/01/2022'
  }

  return { maintenance, maintenanceDate }
}

export default useDianMaintenance
