import { useFormikContext } from 'formik'

import MuiLink from '@material-ui/core/Link'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { payrollFrequencies } from 'utils/company'

import FormField from '../FormField/Index'

const onboardingPayrollFrequencies = {
  ...payrollFrequencies,
  mixed: 'Mixta (Quincenal y mensual)',
  another: 'Otra (Semanal, decenal, catorcenal)',
}

const onboardingPayrollFrequenciesOptions = Object.keys(
  onboardingPayrollFrequencies
).map((value) => ({
  value,
  label: onboardingPayrollFrequencies[value],
}))

const OnboardingPayrollFrequencyField = () => {
  const { values } = useFormikContext()
  const confirm = useConfirm()

  const handleBlur = () => {
    if (values.payroll_frequency === 'mixed')
      confirm({
        type: 'info',
        title: 'Aviso importante',
        description: (
          <>
            Primero configuremos tus empleados con{' '}
            <b>frecuencia de nómina mensual</b>. Una vez termines el tutorial,
            nos puedes escribir a <OrganizationEmail /> para ayudarte a
            configurar los de forma <b>quincenal</b>.
          </>
        ),
        okText: 'Aceptar',
        hideCancelButton: true,
      })

    if (values.payroll_frequency === 'another') {
      confirm({
        type: 'info',
        title: 'Oops! Solo frecuencias mensuales y quincenales',
        description: (
          <>
            Consulta{' '}
            <MuiLink
              href="https://ayuda.nominapp.com/help/con-que-opciones-de-frecuencia-de-pago-cuenta-nominapp?version=latest"
              target="_blank"
            >
              este artículo
            </MuiLink>{' '}
            de nuestro centro de ayuda para saber por qué muchos de nuestros
            clientes han cambiado su forma de liquidar la nómina y están{' '}
            <b>felices con el cambio</b>. Por ahora, vamos a configurar tu
            compañía con <b>frecuencia quincenal</b>. Cero estrés, luego podrás
            editar esto.
          </>
        ),
        okText: 'Aceptar',
        hideCancelButton: true,
      })
    }
  }

  return (
    <FormField
      data-cy="payroll-frecuency-select"
      label="Frecuencia de nómina"
      name="payroll_frequency"
      variant="select"
      options={onboardingPayrollFrequenciesOptions}
      onBlur={handleBlur}
    />
  )
}

export default OnboardingPayrollFrequencyField
