import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import MuiButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'

import useModals from 'components/App/ModalsManager/useModals'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import HandleAffiliation from 'components/Organizer/Affiliations/HandleAffiliation'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Link from 'components/UI/MaterialUI/Link'
import useWorker from 'components/Worker/useWorker'

import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useWorkerService from 'utils/hooks/worker/workerService'

import * as routes from 'config/routes'

import ActivateButton from '../Body/ActivateButton'
import { WorkerProfileContext } from '../Show'
import CertificatesMenu from './Menus/CertificatesMenu'
import HistoryMenu from './Menus/HistoryMenu'
import TerminationMenu from './Menus/TerminationMenu'

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    marginTop: theme.spacing(4),
    '& button, a + button, a': {
      marginLeft: '0 !important',
      marginTop: theme.spacing(2),
      whiteSpace: 'nowrap',
      width: '100%',
    },
  },
  menuButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: `${theme.spacing(1.5)}px`,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  endButton: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 'auto !important',
    },
  },
}))

const WorkerHeaderActions = () => {
  const queryClient = useQueryClient()
  const { activeTab, setActiveTab } = useContext(WorkerProfileContext)
  const { worker, isFromAffiliationView } = useWorker({ useCache: true })
  const { isWorker: isWorkerRole, user } = useUser()
  const { handleDownloadPayslip } = usePayslipModal()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const history = useHistory()
  const confirm = useConfirm()
  const classes = useStyles()
  const modals = useModals()
  const isMulticompanyUser = user.multicompanyUser
  const { freetimeBeta } = useFeatureFlags({
    flags: ['freetimeBeta'],
  })
  const queryKey = ['getWorkerOptions', worker.id]
  const workerProfileQueryKey = ['getWorkerProfileOptions', worker.id]
  const affiliationCache = queryClient.getQueryData([
    'getAffiliationById',
    worker.id,
  ])
  const isAffiliationApproved = affiliationCache?.data?.status === 'approved'
  const isAffiliationRejected = affiliationCache?.data?.status === 'rejected'

  const { workerQuery } = useWorkerService({
    serviceParams: {
      queryKey: isWorkerRole ? workerProfileQueryKey : queryKey,
      workerId: worker.id,
    },
    queryOptions: {
      enabled: Boolean(worker.id),
    },
  })

  const { data: workerOptions } = workerQuery

  const featureState = usePremiumFeature(
    'payslip',
    isMulticompanyUser ? worker.premium : undefined
  )

  const handleClickViewPayslip = async () => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    showLoadingModal()
    await handleDownloadPayslip({ payroll: { id: worker?.payroll_id } })
    hideLoadingModal()
  }

  const handleReintegration = () => {
    confirm({
      type: 'warning',
      title: 'Reincorporar persona',
      description: (
        <>
          ¿Estás seguro de querer reincorporar a esta persona? Esta acción no
          puede deshacerse. Si quieres saber más sobre la reincorporación puedes
          leer nuestro{' '}
          <Link
            href="https://ayuda.nominapp.com/help/puedo-reintegrar-un-empleado-que-ya-ha-sido-liquidado"
            target="_blank"
          >
            Centro de ayuda.
          </Link>
        </>
      ),
      okText: 'Reincorporar',
      onOk: () =>
        history.push(routes.WORKER_CONTRACT_INDEX(worker?.id), {
          worker,
          createContract: true,
        }),
    })
  }

  const handleTermination = () => {
    history.push(
      routes.WORKER_CONTRACT_TERMINATION_NEW(worker?.id, worker?.contract_id)
    )
  }

  const handleOpenFreeTime = () => {
    if (activeTab !== 3) {
      setActiveTab(3)
    }
  }

  const showAffiliationActionModal = (action) => {
    const isRejectAction = action === 'rejected'

    modals.openModal({
      id: `actionsModal`,
      content: (
        <HandleAffiliation
          action={action}
          workerId={worker.id}
          contractCategory={worker.contract_category}
          documentType={worker.document_type}
        />
      ),
      modalProps: {
        header: `${isRejectAction ? 'Rechazar' : 'Aprobar'} afiliación`,
        hideFooter: true,
        onCloseModal: () => modals.closeAll(),
        dialogProps: {
          fullWidth: true,
          maxWidth: isRejectAction ? 'sm' : 'md',
        },
      },
    })
  }

  if (!workerOptions && workerQuery.isLoading) return <LoadingBox />

  if (isFromAffiliationView) {
    return (
      <div className={classes.buttonsContainer}>
        <Button
          onClick={() => showAffiliationActionModal('approved')}
          disabled={isAffiliationApproved}
        >
          Aprobar afiliación
        </Button>
        <Button
          variant="outlined"
          onClick={() => showAffiliationActionModal('rejected')}
          disabled={isAffiliationApproved || isAffiliationRejected}
        >
          Rechazar afiliación
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.buttonsContainer}>
      {/* Liquidación */}
      {!isWorkerRole && (
        <>
          {worker && worker?.terminated && (
            <TerminationMenu
              worker={worker}
              confirm={confirm}
              isEditable={workerOptions?.editable_termination}
            />
          )}

          <div className={classes.menuButtons}>
            {/* Historiales */}
            <HistoryMenu worker={worker} workerOptions={workerOptions} />
          </div>
        </>
      )}
      {/* Certificados */}
      {(freetimeBeta?.value === 'on' &&
        isWorkerRole &&
        (worker.workers_certificate_access || user.workersCertificateAccess)) ||
      !isWorkerRole ? (
        <CertificatesMenu worker={worker} workerOptions={workerOptions} />
      ) : null}
      {/* Colilla de pago */}
      {worker && worker?.payroll_id && (
        <Button
          variant="outlined"
          startIcon={<DescriptionIcon />}
          onClick={handleClickViewPayslip}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Descargar colilla de pago
        </Button>
      )}
      {isWorkerRole &&
      (worker.workers_handle_novelties || user.workersHandleNovelties) &&
      freetimeBeta?.value === 'on' ? (
        <Button variant="outlined" onClick={handleOpenFreeTime}>
          Solicitar tiempo fuera
        </Button>
      ) : null}

      {['employee', 'pensioner', 'part_time_contract'].includes(
        worker?.contract_category
      ) ? (
        <>
          {!isWorkerRole && (
            <>
              {worker && worker?.terminated ? (
                <Button
                  variant="outlined"
                  className={classes.endButton}
                  onClick={handleReintegration}
                >
                  Reincorporar
                </Button>
              ) : (
                <>
                  {worker && worker?.terminatable && (
                    <MuiButton
                      onClick={handleTermination}
                      variant="outlined"
                      className={classes.endButton}
                      data-cy="termination-button"
                    >
                      Liquidar
                    </MuiButton>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <ActivateButton
          isWorker={isWorkerRole}
          worker={worker}
          className={classes.endButton}
        />
      )}
    </div>
  )
}

export default WorkerHeaderActions
