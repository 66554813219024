import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined'

import Building from 'assets/icons/Building'
import StoreBuilding from 'assets/icons/StoreBuilding'

export default [
  {
    label: 'Liquida novedades y prestaciones en segundos',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Nómina electrónica ilimitada',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Notifica el pago de nómina a tus empleados',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Paga la PILA en segundos y evita multas',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Paga la nómina en tu banco sin errores',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Integra tu nómina con +50 software contables',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Genera certificados laborales y reportes',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Capacitación para todo tu equipo sin costo',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Panel multiempresa (para Contadores)',
    plan: 'premium_per_worker_plan',
  },
  {
    label: 'Ejecutivo de cuenta',
    plan: 'pro_per_worker_plan',
  },
  {
    label: 'Migración asistida a Nominapp',
    plan: 'pro_per_worker_plan',
  },
  {
    label: 'Acceso prioritario a nuevas funcionalidades',
    plan: 'pro_per_worker_plan',
  },
  {
    label: 'Asesoría de nómina',
    plan: 'pro_per_worker_plan',
  },
  {
    label: 'Soporte telefónico',
    plan: 'pro_per_worker_plan',
  },
]

export const basePlans = [
  {
    id: 'free',
    name: 'Gratis',
    icon: <HomeOutlinedIcon color="primary" fontSize="large" />,
    description:
      'El plan perfecto para cumplir con la DIAN y tener tu nómina electrónica al día.',
    adminDescription: (
      <span>
        1 persona <br /> Soporte en menos de 24 horas.
      </span>
    ),
    price: 0,
    pricePerPerson: 0,
    details: {
      payroll: [
        'Liquidación de nómina automatizada',
        'Habilitación de nómina electrónica GRATIS ante la DIAN',
        'Transmisión ilimitada de documentos de nómina electrónica',
        'Centro de ayuda con +300 artículos y preguntas frecuentes',
        'Descarga de interfaz contable para carga con su software contable',
      ],
      humanResources: ['Adelantos de nómina'],
      support: [
        'Soporte vía correo electrónico con expertos en nómina',
        'Curso de capacitación Nominapp sin costo',
      ],
    },
  },
  {
    id: 'basic',
    name: 'Básico',
    icon: <StorefrontOutlinedIcon color="primary" fontSize="large" />,
    description:
      'Liquida tu nómina y seguridad social en segundos de forma automatica y sin errores.',
    adminDescription: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    price: 59900,
    pricePerPerson: 6900,
    details: {
      payroll: [
        'Liquidación de nómina automatizada',
        'Habilitación de nómina electrónica GRATIS ante la DIAN',
        'Transmisión ilimitada de documentos de nómina electrónica',
        'Centro de ayuda con +300 artículos y preguntas frecuentes',
        'Descarga de interfaz contable para carga con su software contable',
        'Descarga de archivos para pago en bancos',
        'Liquidaciones laborales a 1 clic',
        'Cálculo de retención en la fuente automático',
        'Liquidación de prestaciones sociales a 1 clic',
        'Usuarios con rol administrador ilimitados',
        'Descarga de archivo plano para el pago de seguridad social',
        'Pago de planilla PILA sin tener que salir de Nominapp',
        'Generación y descarga de certificados laborales',
        'Resumenes y reportes de nómina',
      ],
      humanResources: ['Adelantos de nómina'],
      support: [
        'Soporte prioritario vía correo electrónico con expertos en nóminaa',
        'Curso de capacitación Nominapp sin costo',
        'Soporte por chat',
        'Implementación asistida',
      ],
    },
  },
  {
    id: 'essential',
    name: 'Esencial',
    icon: <StoreBuilding htmlColor="#FFFFFF" fontSize="large" />,
    description:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
    adminDescription: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    price: 59900,
    pricePerPerson: 11900,
    details: {
      payroll: [
        'Liquidación de nómina automatizada',
        'Habilitación de nómina electrónica GRATIS ante la DIAN',
        'Transmisión ilimitada de documentos de nómina electrónica',
        'Centro de ayuda con +300 artículos y preguntas frecuentes',
        'Descarga de interfaz contable para carga con su software contable',
        'Descarga de archivos para pago en bancos',
        'Liquidaciones laborales a 1 clic',
        'Cálculo de retención en la fuente automático',
        'Liquidación de prestaciones sociales a 1 clic',
        'Usuarios con rol administrador ilimitados',
        'Descarga de archivo plano para el pago de seguridad social',
        'Pago de planilla PILA sin tener que salir de Nominapp',
        'Generación y descarga de certificados laborales',
        'Resumenes y reportes de nómina',
      ],
      humanResources: [
        'Adelantos de nómina',
        'Afiliaciones a seguridad social',
        'Validación de identidad y antecedentes',
        'Gestión documental / Archivo',
        'Autogestión de vacaciones, licencias y certificados laborales y de ingresos y retenciones',
        'Contratos y firmas electrónicas',
        'Calendario de novedades',
      ],
      support: [
        'Soporte prioritario vía correo electrónico con expertos en nóminaa',
        'Curso de capacitación Nominapp sin costo',
        'Soporte por chat',
        'Soporte prioritario por correo',
        'Implementación asistida',
      ],
    },
  },
  {
    id: 'professional',
    name: 'Profesional',
    icon: <Building color="primary" fontSize="large" />,
    description:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
    adminDescription: 'Ejecutivo de cuenta y soporte prioritario.',
    price: 399900,
    pricePerPerson: 19900,
    details: {
      payroll: [
        'Liquidación de nómina automatizada',
        'Habilitación de nómina electrónica GRATIS ante la DIAN',
        'Transmisión ilimitada de documentos de nómina electrónica',
        'Centro de ayuda con +300 artículos y preguntas frecuentes',
        'Descarga de interfaz contable para carga con su software contable',
        'Descarga de archivos para pago en bancos',
        'Liquidaciones laborales a 1 clic',
        'Cálculo de retención en la fuente automático',
        'Liquidación de prestaciones sociales a 1 clic',
        'Usuarios con rol administrador ilimitados',
        'Descarga de archivo plano para el pago de seguridad social',
        'Pago de planilla PILA sin tener que salir de Nominapp',
        'Generación y descarga de certificados laborales',
        'Resumenes y reportes de nómina',
      ],
      humanResources: [
        'Adelantos de nómina',
        'Afiliaciones a seguridad social',
        'Validación de identidad y antecedentes',
        'Gestión documental / Archivo',
        'Autogestión de vacaciones, licencias y certificados laborales y de ingresos y retenciones',
        'Contratos y firmas electrónicas',
        'Calendario de novedades',
      ],
      support: [
        'Soporte prioritario vía correo electrónico con expertos en nóminaa',
        'Curso de capacitación Nominapp sin costo',
        'Soporte por chat',
        'Soporte prioritario por correo',
        'Implementación asistida',
        'Migración de software asistida a Nominapp',
        'Acceso directo a un agente especialista en Nómina y HR',
        'Soporte telefónico',
      ],
    },
  },
]

// eslint-disable-next-line no-unused-vars
export const getPlansData = (plansPrices) => {
  // TODO: loop basePlans and add the price to each plan
  return basePlans
}
