import { Form, Formik } from 'formik'

import Modal from 'components/UI/Modal/Modal'
import { getWorkerDirtyValues } from 'components/Worker/Form/helpers'
import useWorker from 'components/Worker/useWorker'

import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import BasicDataSection from './Sections/BasicDataSection'
import ContractDataSection from './Sections/ContractDataSection'
import EntitiesSection from './Sections/EntitiesSection'
import JobPositionSection from './Sections/JobPositionSection'
import PaymentDataSection from './Sections/PaymentDataSection'
import validationSchema from './validationSchema'

const UpdateWorkerGeneralDataModal = ({ onCancel, state }) => {
  const { title, modalType } = state
  const { worker, refreshWorker } = useWorker({
    applyFormat: true,
    useCache: true,
  })
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const callbackSuccess = () => {
    refreshWorker()
    showSuccessMessage('El trabajador fue actualizado exitosamente')
    onCancel()
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorToNotificate: [
        { object: 'worker' },
        { object: 'id_number' },
        { object: 'email' },
        // error returned when a user update the end_date of a contract but it can't
        // be done because the deductions exceed the value to be paid to the worker
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })
  }

  const handleSubmit = async (values, form) => {
    const { dirtyWorker } = getWorkerDirtyValues(worker, values, true)

    if (!isObjectEmpty(dirtyWorker)) {
      workerMutation.mutate(
        {
          mutationMethod: 'PATCH',
          worker: {
            ...dirtyWorker,
          },
          workerId: worker.id,
        },
        {
          onSuccess: ({ data }) => callbackSuccess(values, data),
          onError: (error) => {
            callbackError(error, form)
          },
        }
      )
    } else {
      onCancel()
    }
  }

  return (
    <Formik
      initialValues={worker}
      onSubmit={handleSubmit}
      validationSchema={validationSchema[modalType]}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Modal
            header={title}
            open
            onOk={onSubmit}
            isLoading={workerMutation.isLoading}
            onCancel={onCancel}
            okText="Guardar"
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
            }}
          >
            <Form>
              {modalType === 'contract_data' && (
                <ContractDataSection onClose={onCancel} />
              )}
              {modalType === 'entities' && <EntitiesSection />}
              {modalType === 'job_position_data' && <JobPositionSection />}
              {modalType === 'basic_data' && <BasicDataSection />}
              {modalType === 'payment_data' && <PaymentDataSection />}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default UpdateWorkerGeneralDataModal
