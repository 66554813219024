import clsx from 'clsx'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import ActionStatus from './ActionStatus'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    '& .typography-content': {
      marginLeft: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
  },
  primaryCard: {
    height: 128,
    padding: theme.spacing(2, 4),
    position: 'relative',
  },
  secondaryCard: {
    height: 60,
    width: 184,
    padding: theme.spacing(0.5, 4),
  },
  cardResume: {
    display: 'flex',
    placeContent: 'flex-start',
    placeItems: 'center',
    '& .typography-content > span': {
      color: theme.palette.grey[700],
    },
  },
  iconContainer: {
    display: 'flex',
    placeItems: 'center',
    height: 'fit-content',
    padding: theme.spacing(0),
    fill: theme.palette.primary.main,
  },
  rejectedContainer: {
    position: 'relative',
    top: theme.spacing(-2.5),
  },
}))

const CardStatus = ({ card }) => {
  const classes = useStyles()

  const { Icon, value, title, handleClickChip } = card

  const primaryStatus = ['total', 'rejected', 'issued'].includes(card.status)
  return (
    <Card
      className={clsx(classes.cardRoot, {
        [classes.primaryCard]: primaryStatus,
        [classes.secondaryCard]: !primaryStatus,
      })}
    >
      <div
        className={clsx(classes.cardResume, {
          [classes.primaryCardResume]: primaryStatus,
          [classes.secondaryCardResume]: !primaryStatus,
          [classes.rejectedContainer]: card.status === 'rejected',
        })}
      >
        <CardContent className={classes.iconContainer}>
          <Icon />
        </CardContent>
        <div className="typography-content">
          <Typography variant={primaryStatus ? 'subtitle1' : 'subtitle2'}>
            {title}
          </Typography>
          <Typography variant={primaryStatus ? 'h4' : 'h5'} color="primary">
            {value}
          </Typography>
        </div>
      </div>
      {card.status === 'rejected' ? (
        <ActionStatus handleDetail={handleClickChip} />
      ) : null}
    </Card>
  )
}

export default CardStatus
