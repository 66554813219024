import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { documentTypes as companyDocTypes } from 'utils/company'
import { formatDisplayDate } from 'utils/dateTime'
import { formatIdNumber } from 'utils/format'
import { documentTypes as workerDocTypes } from 'utils/worker'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  root: {
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const headerTitle = {
  company: 'Compañía',
  worker: 'Persona',
  period: 'Periodo de pago',
}

const PeriodDetailsHeader = ({ headerData = {} }) => {
  const classes = useStyles()

  return (
    <Grid container justify="center" alignItems="stretch" spacing={2}>
      {Object.keys(headerData).map((type) => {
        const typeData = headerData[type]

        return (
          type !== 'payroll_options' && (
            <Grid key={type} xs={4} item>
              <Paper className={classes.root}>
                <Typography variant="h5" className={classes.title}>
                  {headerTitle[type]}
                </Typography>

                {(type === 'company' || type === 'worker') && (
                  <>
                    <Typography gutterBottom>{typeData.name}</Typography>
                    <Typography gutterBottom>
                      {type === 'worker'
                        ? workerDocTypes[typeData.document_type]
                        : companyDocTypes[typeData.document_type]}
                      {typeData.document_type && ': '}
                      {formatIdNumber(typeData.id_number)}
                    </Typography>

                    {type === 'worker' && (
                      <Typography gutterBottom>
                        {typeData.contract_category}
                      </Typography>
                    )}
                  </>
                )}

                {type === 'period' && (
                  <>
                    <Typography gutterBottom>
                      {`${formatDisplayDate(
                        typeData.period_payment?.initial_day
                      )} - ${formatDisplayDate(
                        typeData.period_payment?.end_day
                      )}`}
                    </Typography>

                    <Typography gutterBottom>
                      Días trabajados: {typeData.worked_days}
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid>
          )
        )
      })}
    </Grid>
  )
}

export default PeriodDetailsHeader
