import { Button, Card, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  card: {
    minHeight: 264,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3, 2, 3),
  },
  buttons: {
    display: 'flex',
    alignSelf: 'end',
    flexGrow: 1,
    flexDirection: 'column-reverse',
  },
}))

const EditOptions = ({
  handleCloseModal,
  handleNewWorkCenter,
  workCenterToUpdate,
}) => {
  const classes = useStyles()
  return (
    <div>
      <Typography>
        Acá puedes editar un centro de trabajo, recuerda tener presente la fecha
        de cambio ya que este cambio afecta el centro de trabajo.
      </Typography>
      <div className={classes.cardsContainer}>
        <Card className={classes.card}>
          <Typography variant="h5">
            Editar los datos del centro de trabajo
          </Typography>
          <Typography>
            En caso de que desees modificar la información actual de tu centro
            de trabajo modificando la información histórica.
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              onClick={() => {
                handleCloseModal()
                handleNewWorkCenter(workCenterToUpdate, false)
              }}
            >
              Editar
            </Button>
          </div>
        </Card>
        <Card className={classes.card}>
          <Typography variant="h5">
            Crear información nueva al centro de trabajo
          </Typography>
          <Typography>
            En caso de que desees modificar la información de este centro de
            trabajo desde la fecha de definas.
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              onClick={() => {
                handleCloseModal()
                handleNewWorkCenter(workCenterToUpdate, true)
              }}
            >
              Crear
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default EditOptions
