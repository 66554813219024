import { nanoid } from 'nanoid'
import { useContext, useState } from 'react'

import Typography from '@material-ui/core/Typography'

import Dropzone from 'components/UI/Dropzone/Dropzone'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Modal from 'components/UI/Modal/Modal'

import useNoveltiesImportService from 'utils/hooks/payroll/noveltiesImport'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { PeriodContext } from '../Payroll'

const NoveltiesImportModal = ({ open, handleClose }) => {
  const { period, fetchPeriodData } = useContext(PeriodContext)
  const [file, setFile] = useState()
  const [errors, setErrors] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { noveltiesImportMutation } = useNoveltiesImportService()
  const errorKey = nanoid()

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    setErrors(null)

    const data = new FormData()
    data.append('file', file)

    try {
      setIsSubmitting(true)

      const { message } = await noveltiesImportMutation.mutateAsync({
        mutationMethod: 'POST',
        periodId: period.id,
        data,
      })

      if (message) showSuccessMessage(message)

      handleClose()
      fetchPeriodData()

      trackEvent(integrationEvent.NOVELTIES_FILE_UPLOAD)
    } catch (error) {
      // TODO: better handling of import errors
      if (error.status === 402) return handleError(error)
      if (error.errors) {
        showErrorMessage(
          'Error al cargar las novedades. Revisa los datos ingresados.'
        )
        setErrors(error.errors)
      } else {
        handleError(error)
      }
    }

    setIsSubmitting(false)
    // No return statement
  }
  // disable because is the better behavior
  // eslint-disable-next-line no-shadow
  const handleAddFile = (file) => {
    setFile(file)
    setErrors(null)
  }

  return (
    <>
      <Modal
        open={open}
        okText="Cargar"
        header={
          errors ? 'No se lograron cargar las novedades' : 'Cargar novedades'
        }
        loadingText="Importando..."
        onOk={handleSubmit}
        onCancel={handleClose}
        isLoading={isSubmitting}
        dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      >
        {errors?.map((error) => (
          <Alert key={errorKey} closable header={error.worker_name}>
            {error.payroll_concept?.name}: {error.message}.
          </Alert>
        ))}

        <Typography gutterBottom>Selecciona el archivo:</Typography>

        <Dropzone
          value={file && file.name}
          handleAdd={handleAddFile}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="inline"
          disabled={isSubmitting}
        />
      </Modal>
    </>
  )
}

export default NoveltiesImportModal
