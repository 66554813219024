import { Link as RouterLink } from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import RefreshIcon from '@material-ui/icons/Refresh'
import VisibilityIcon from '@material-ui/icons/Visibility'

import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

const paymentStatus = {
  declined: 'Error en el pago',
  paid: 'Pagada',
  created: 'Creada',
  pending: 'Pendiente',
}

export const getColumnsData = (hasTypeColumn, provider) => {
  return [
    {
      Header: 'Periodo',
      accessor: 'period_payment_date',
      Cell: ({ row }) => formatDisplayDate(row.original.period_payment_date),
    },
    {
      Header: 'Tipo planilla',
      accessor: 'type',
      hidden: !hasTypeColumn,
      Cell: ({ row }) =>
        row.original.form_type === 'employee' ? 'Empleados' : 'Estudiantes',
    },
    {
      Header: 'Clave planilla',
      accessor: 'key',
      Cell: ({ row }) => {
        if (!row.original.key) return 'N/A'

        return (
          <Link
            to={{
              pathname: routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(
                row.original.period_id
              ),
              state: { formType: row.original.form_type, provider },
            }}
          >
            {row.original.key}
          </Link>
        )
      },
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      customWidth: '120px',
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => paymentStatus[row.original.status],
    },
    {
      Header: 'Fecha de pago',
      accessor: 'payment_date',
      Cell: ({ row }) => formatDisplayDate(row.original.payment_date) || 'N/A',
    },
    {
      Header: 'Fecha límite de pago',
      accessor: 'due_date',
      Cell: ({ row }) => formatDisplayDate(row.original.due_date),
    },
  ]
}

export const getActions = (handlePay, handleDelete, provider) => {
  return [
    (rowData) => ({
      id: 'pay_form',
      tooltip: 'Pagar planilla',
      icon: LocalAtmIcon,
      onClick: () => handlePay(rowData),
      hidden: rowData.status !== 'created' || !rowData.key,
    }),
    (rowData) => ({
      id: 'form',
      tooltip: 'Ver planilla',
      icon: VisibilityIcon,
      onClick: () => null,
      buttonProps: {
        component: RouterLink,
        to: {
          pathname: routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(
            rowData.period_id
          ),
          state: {
            formKey: rowData.key,
            formType: rowData.form_type,
            provider,
          },
        },
      },
    }),
    (rowData) => ({
      id: 'delete_form',
      tooltip: 'Eliminar planilla',
      icon: DeleteIcon,
      onClick: ({ period_id: periodId, key, form_type: formType }) =>
        handleDelete(periodId, key, formType),
      hidden: !rowData.deleteable,
    }),
    (rowData) => ({
      id: 'refresh_form',
      tooltip: 'Reintentar pago',
      icon: RefreshIcon,
      onClick: () => handlePay(rowData),
      hidden: rowData.status !== 'declined',
    }),
  ]
}
