import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    },
  },
}))

const AffiliationDataStep = ({ worker }) => {
  const classes = useStyles()

  return (
    <>
      <Typography variat="body1">
        Esta información es la que registraste en el perfil de la persona. Para
        realizar algún cambio, ingresa a su perfil y luego vuelve a realizar la
        afiliación a seguridad social.
      </Typography>
      <div className={classes.container}>
        <PositionField placeholder="Cargo" tooltipContent={null} />
        <BaseSalaryField
          isEditing={false}
          contractCategory={worker.contract_category}
          tooltipContent={null}
        />
        <InitialDayField tooltipContent={null} label="Fecha de ingreso" />
        <HealthProviderField
          contractCategory={worker.contract_category}
          tooltipContent={null}
        />
        <PensionProviderField
          contractCategory={worker.contract_category}
          documentType={worker.document_type}
          label="Pensiones"
          tooltipContent={null}
        />
        <SeveranceProviderField
          wageCategory={worker.wage_category}
          contractCategory={worker.contract_category}
          label="Cesantías"
          tooltipContent={null}
        />
        <RiskTypeField
          contractCategory={worker.contract_category}
          label="Centro de trabajo - Nivel de riesgo"
          tooltipContent={null}
        />
        <LocationField tooltipContent={null} label="Sede donde trabaja" />
      </div>
    </>
  )
}

export default AffiliationDataStep
