import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import premiumIcon from 'assets/images/views/common/crown.svg'

const useStyles = makeStyles(() => ({
  premiumDot: {
    top: -5,
    right: -5,
    position: 'absolute',
  },
}))

const IconCrown = ({
  fontSize = 14,
  isPremiumAction = true,
  isDotIcon = false,
  ...props
}) => {
  const classes = useStyles()

  return isPremiumAction ? (
    <img
      src={premiumIcon}
      alt="Pasate a Nominapp Premium"
      width={fontSize}
      height={fontSize}
      className={clsx({ [classes.premiumDot]: isDotIcon })}
      {...props}
    />
  ) : null
}

export default IconCrown
