import clsx from 'clsx'
import { useQueryClient } from 'react-query'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_PAYMENTS } from 'config/routes'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  payment: {
    display: 'grid',
    gridTemplateColumns: '50% 1fr 10%',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}))

const SeePaymentsCard = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const cachedPaymentsData = queryClient.getQueryData('getAllPayments')
  const { subscriptionMutation } = useSubscriptionService()

  const handleResendInvoice = (paymentId) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'POST',
        paymentId,
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  return (
    <Card className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h5">Facturas</Typography>
        <Button
          to={SUBSCRIPTION_PAYMENTS()}
          variant="text"
          component={RouterLink}
        >
          Ver más
        </Button>
      </div>
      <>
        {cachedPaymentsData?.data?.slice(0, 5).map((payment, index) => {
          const lastPayment = index !== cachedPaymentsData?.data.length - 1
          return (
            <div
              key={payment.id}
              className={clsx(classes.payment, lastPayment && classes.divider)}
            >
              <Typography>{formatDisplayDateString(payment.date)}</Typography>
              <Typography variant="subtitle1">
                {formatCurrency(payment.value)}
              </Typography>
              {payment.invoice_id && (
                <Tooltip title="Reenviar factura">
                  <IconButton
                    size="small"
                    onClick={() => handleResendInvoice(payment.id)}
                    disabled={subscriptionMutation.isLoading}
                  >
                    <SendOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )
        })}
      </>
    </Card>
  )
}

export default SeePaymentsCard
