import { makeStyles } from '@material-ui/core/styles'

import Steps from 'components/UI/Steps/Steps'

import FormBox from '../FormBox'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '336px 1fr',
    gap: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    '& .MuiStepper-root': {
      padding: 0,
    },
  },
  formContainer: {
    minWidth: 280,
    padding: theme.spacing(4, 2.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}))

const PaperForm = ({ steps, children }) => {
  const classes = useStyles()
  const { stepsData, currentStep, progressStep, onChangeStep } = steps

  return (
    <div className={classes.container}>
      <section>
        <Steps
          orientation="vertical"
          stepsData={stepsData}
          current={currentStep}
          progress={progressStep}
          onChangeStep={onChangeStep}
          outlined
        />
      </section>
      <FormBox className={classes.formContainer}>{children}</FormBox>
    </div>
  )
}

export default PaperForm
