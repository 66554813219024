export default {
  ELECTRONIC_PAYROLL_CONFIGURATION_ARTICLE:
    'https://ayuda.nominapp.com/help/hc/es-419/articles/4406176625051-paso-a-paso-para-habilitarte-ante-la-dian-con-nominapp',
  ELECTRONIC_PAYROLL_BLOG:
    'https://www.nominapp.com/blog/habilitacion-nomina-electronica-dian',
  ELECTRONIC_PAYROLL_AUTHORIZATION_CONFIRMATION:
    'https://ayuda.nominapp.com/help/hc/es-419/articles/4412813228187-verifica-que-est%C3%A1s-habilitado-para-emitir-tu-n%C3%B3mina-electr%C3%B3nica-y-verifica-el-estado-de-tu-set-de-pruebas-en-el-1651072670612',
  TUTORIAL_STEP_ONE: 'https://www.youtube.com/embed/mOPjEP3DkxM',
  TUTORIAL_STEP_TWO: 'https://www.youtube.com/embed/Yrd0aOfkcsg',
  ELECTRONIC_PAYROLL_BONUS_CONCEPT:
    'https://ayuda.nominapp.com/help/relaciona-en-tu-n%C3%B3mina-electr%C3%B3nica?hs_preview=lcwImLpM-74545227365',
}
