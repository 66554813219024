import { useContext } from 'react'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'

import Button from 'components/UI/Button/Button'
import PaymentContext from 'components/Subscription/Atoms/PaymentContext'
import { getPlanSummary } from 'components/Subscription/Atoms/helpers'
import { useUser } from 'components/App/UserContext/useUser'

import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  summaryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  discount: {
    border: `1px solid ${theme.palette.success.dark}`,
    borderRadius: theme.spacing(0.75),
    backgroundColor: theme.palette.success.light,
    padding: theme.spacing(1.2),
    '& h6, p': {
      color: theme.palette.success.dark,
      fontWeight: 600,
    },
  },
  payUInformation: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(0.75),
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.success.dark,
  },
}))

const PaywallPaymentSummary = ({ paySubscription }) => {
  const classes = useStyles()
  const { company } = useUser()
  const { showWarningMessage } = useNotifications()
  const {
    step,
    selectedPlan,
    isLoading,
    setStep,
    setSubmitForm,
    subscription,
  } = useContext(PaymentContext)

  const isPlanSelect = !isObjectEmpty(selectedPlan)
  const planSummary = getPlanSummary(selectedPlan, subscription, company?.name)

  const continueNextStep = () => {
    if (selectedPlan.personalized) {
      if (
        selectedPlan.subscription_value.monthly.workers_number > 0 ||
        selectedPlan.subscription_value.yearly.workers_number > 0
      ) {
        setStep(2)
      } else {
        showWarningMessage(
          'Para continuar debes ingresar el número de personas extra'
        )
      }
    } else {
      setStep(2)
    }
  }

  return (
    <Slide direction="left" timeout={500} in>
      <div className={classes.main} data-cy="subscription_payment_summary">
        <div>
          <Typography variant="h5" style={{ marginBottom: '2em' }}>
            Resumen de pago
          </Typography>

          {planSummary.map((item) => (
            <div
              key={item.id}
              className={clsx(classes.summaryItem, {
                [classes.discount]: item.id === 'distributor_discount',
              })}
            >
              <Typography variant="subtitle1">{item.label}</Typography>
              <Typography
                style={{
                  fontWeight: item.bold ? 'bold' : 'normal',
                  textAlign: 'end',
                }}
              >
                {item.value}
              </Typography>
            </div>
          ))}
        </div>
        <div>
          <Button
            loading={isLoading}
            disabled={!isPlanSelect}
            onClick={() =>
              step === 1 ? continueNextStep() : setSubmitForm(true)
            }
            className={classes.button}
            data-cy="subscription_payment_button_ok"
          >
            {paySubscription ? 'PAGAR SUSCRIPCIÓN' : 'Continuar'}
          </Button>

          <div className={classes.payUInformation}>
            <LockIcon fontSize="small" />
            <Typography variant="caption">
              Compra protegida por PayU Latam
            </Typography>
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default PaywallPaymentSummary
