import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'

import imgError from 'assets/images/errors/error.svg'
import logoNominapp from 'assets/images/logos/logo_degradado.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 550,
  },
  imgError: {
    width: 225,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  logos: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    justifyItems: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
}))

const ModalIntegrationNotActive = ({
  title,
  description,
  link,
  logo,
  altLogo,
  imgHeight = 30,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        src={imgError}
        alt="No está activa la integración"
        className={classes.imgError}
      />
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography>
        {description}{' '}
        {link && (
          <MuiLink href={link} target="_blank">
            Clic aquí.
          </MuiLink>
        )}
      </Typography>
      <div className={classes.logos}>
        <img src={logoNominapp} alt="Nominapp" width={163} height={30} />
        <img src={logo} alt={altLogo} width={109} height={imgHeight} />
      </div>
    </div>
  )
}

export default ModalIntegrationNotActive
