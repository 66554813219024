import { getToken } from 'utils/auth'

import http, {
  downloadFileWebSocket,
  getApiURL,
  handleFetchResponse,
} from '../httpService'

export const getImportingFile = (periodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/import_payroll_novelties`,
  })

  return http.getV2(url)
}

export const uploadImportFile = async (periodId, data) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/import_payroll_novelties`,
    searchParams: { async: true },
  })

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: { Authorization: `Bearer ${getToken()}` },
    body: data,
  })

  await handleFetchResponse(response)

  return downloadFileWebSocket(`periods/${periodId}/import_payroll_novelties`)
}

export default {
  getImportingFile,
  uploadImportFile,
}
