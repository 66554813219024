import { createContext, useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Page from 'components/UI/Page/Page'
import Link from 'components/UI/MaterialUI/Link'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import { useUser } from 'components/App/UserContext/useUser'

import formatPeriod from 'services/payroll/helpers'

import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import { addDays, checkExpireDays } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import { getCompanyId } from 'utils/company'

import { SETTINGS_ADVANCED } from 'config/routes'

import { getDetailCard, getConfigurationCopy } from './helpers'
import CardStatus from './ResumeStatus/CardStatus'
import ModalRejected from './ModalRejected/ModalRejected'
import SendElectronicIndividual from './SendModal/SendElectronicIndividual'
import TableElectronicPayrollPeriod from './DataTable/Table/Table'
import ElectronicPayrollHeader from './Header'
import SummaryTable from './Summary'

export const ElectronicPeriodContext = createContext()

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(5),
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  statusContainerPrimary: {
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(210px, 230px))',
    },
    gap: theme.spacing(2),
    justifyItems: 'center',
  },
  statusContainerSecondary: {
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(180px, 190px))',
    },
    gap: theme.spacing(2),
    justifyItems: 'center',
  },
  alert: {
    maxWidth: 410,
    position: 'absolute',
    right: theme.spacing(5),
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(2.5),
    },
  },
}))

const ElectronicPayroll = ({ match }) => {
  const { periodId } = match.params
  const [electronicPeriod, setElectronicPeriod] = useState({})
  const [openModalRejected, setOpenModalRejected] = useState(false)
  const [openModalToSend, setOpenModalToSend] = useState(false)
  const [electronicPayrolls, setElectronicPayrolls] = useState([])
  const [resetForm, setResetForm] = useState()
  const [isLoadingSend, setIsLoadingSend] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const electronicPayrollPeriodQueryKey = [
    'electronicPayrollPeriod',
    periodId,
    '',
  ]
  const classes = useStyles()
  const { user } = useUser()
  const { electronicPayrollSummary } = useFeatureFlags({
    flags: ['electronicPayrollSummary'],
    trackingMode: 'attributes',
    attributes: {
      email: user.email,
      companyId: getCompanyId(),
    },
  })

  const handleCloseRejected = () => setOpenModalRejected(false)

  const handleCloseToSend = () => setOpenModalToSend(false)

  const { electronicPayrollPeriodQuery } = useElectronicPayrollPeriodService({
    serviceParams: {
      queryKey: electronicPayrollPeriodQueryKey,
      periodId,
      electronicPayrollStatuses: '',
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const formattedPeriod = formatPeriod(data)
        setElectronicPeriod(formattedPeriod)
      },
    },
  })

  const cardDetails = getDetailCard(
    electronicPeriod?.electronic_payrolls_counter,
    setOpenModalRejected
  )

  // Validation for 60 days from first authorization
  const expiryDaysFromAuthorization = addDays(
    electronicPeriod.authorization_date,
    60
  )
  const hasExpiry = checkExpireDays(expiryDaysFromAuthorization)

  // Variable for get the copy from the configuration option
  const copyAlertElectronicPayroll =
    getConfigurationCopy(electronicPeriod?.electronic_payroll_social_benefit) ||
    ''

  return (
    <ElectronicPeriodContext.Provider
      value={{
        period: electronicPeriod,
        setShowSummary,
        socialBenefitsConfiguration:
          electronicPayrollPeriodQuery.data?.electronic_payroll_social_benefit,
      }}
    >
      <Page
        header={
          !isObjectEmpty(electronicPeriod) ? (
            <ElectronicPayrollHeader
              title="Detalle de nómina electrónica"
              period={electronicPeriod}
            />
          ) : null
        }
        documentTitle="Nómina electrónica"
        isLoading={electronicPayrollPeriodQuery?.isLoading}
      >
        {!isObjectEmpty(electronicPeriod) ? (
          <>
            {!hasExpiry && copyAlertElectronicPayroll ? (
              <Alert
                className={classes.alert}
                severity="warning"
                withBorder
                icon={false}
                header="¡Nómina Electrónica!"
                closable
              >
                {copyAlertElectronicPayroll}
                <br />
                <Link to={SETTINGS_ADVANCED()}>Configurar</Link>
              </Alert>
            ) : null}
            <div className={classes.paperContainer}>
              <section className={classes.statusContainerPrimary}>
                {cardDetails
                  .filter((card) => card.type === 'primary')
                  .map((card) => (
                    <CardStatus key={card.id} card={card} />
                  ))}
              </section>

              <section className={classes.statusContainerSecondary}>
                {cardDetails
                  .filter((card) => card.type === 'secondary')
                  .map((card) => (
                    <CardStatus key={card.id} card={card} />
                  ))}
              </section>
            </div>

            {openModalRejected ? (
              <ModalRejected
                data={electronicPeriod}
                closeModal={handleCloseRejected}
              />
            ) : null}
            {electronicPayrollSummary?.value === 'on' && showSummary ? (
              <SummaryTable />
            ) : (
              <TableElectronicPayrollPeriod
                electronicPayrollsData={electronicPeriod}
                setOpenModalToSend={setOpenModalToSend}
                setElectronicPayrolls={setElectronicPayrolls}
                setResetForm={setResetForm}
                isLoadingSend={isLoadingSend}
              />
            )}
          </>
        ) : null}
      </Page>
      {openModalToSend ? (
        <SendElectronicIndividual
          electronicPayrolls={electronicPayrolls}
          handleClose={handleCloseToSend}
          periodId={periodId}
          endDay={electronicPeriod?.end_day}
          resetForm={resetForm}
          setIsLoadingSend={setIsLoadingSend}
        />
      ) : null}
    </ElectronicPeriodContext.Provider>
  )
}

export default ElectronicPayroll
