import clsx from 'clsx'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textarea: {
    height: 'auto',
  },
}))

const TextareaField = ({ id, name, value, ...props } = {}) => {
  const localClasses = useStyles()
  const { onBlur, onChange, className, ...restProps } = props

  return (
    <OutlinedInput
      id={id}
      name={name}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      multiline
      className={clsx(localClasses.textarea, className)}
      {...restProps}
    />
  )
}

export default TextareaField
