import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import * as routes from 'config/routes'

import { WorkerProfileContext } from '../Show'
import ActivateButton from './ActivateButton'
import DocumentsContainer from './Tabs/DocumentsContainer'
import FreeTimeContent from './Tabs/FreeTimeContent'
import getTabsData from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
    },
  },
  button: {
    display: 'flex',
    justifySelf: 'end',
    marginTop: theme.spacing(3),
  },
}))

const WorkerBody = () => {
  const { activeTab, setActiveTab } = useContext(WorkerProfileContext)
  const classes = useStyles()
  const confirm = useConfirm()
  const history = useHistory()
  const { isWorker, user } = useUser()
  const { worker, isFromAffiliationView } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })

  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
      email: user.email,
    },
  })
  const { freetimeBeta } = useFeatureFlags({
    flags: ['freetimeBeta'],
  })

  const { showSuccessMessage } = useNotifications()

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const tabsData = getTabsData(isFromAffiliationView)

  const callback = (message) => {
    showSuccessMessage(message)
    queryClient.invalidateQueries('getWorkerTotals')
    history.push(routes.WORKER_INDEX())
  }

  const deleteWorker = () => {
    workerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        workerId: worker.id,
      },
      {
        onSuccess: (data) => callback(data.message),
      }
    )
  }

  const handleDeleteWorker = () => {
    confirm({
      type: 'error',
      title: 'Eliminar trabajador',
      description: '¿Estás seguro que deseas eliminar al trabajador?',
      okText: 'Eliminar',
      onOk: deleteWorker,
    })
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  if (
    freetimeBeta?.value === 'on' &&
    isWorker &&
    (worker.workers_handle_novelties || user.workersHandleNovelties) &&
    worker.contract_category !== 'contractor' &&
    !isFromAffiliationView
  ) {
    tabsData.push({
      label: 'Solicitar tiempo fuera',
      content: <FreeTimeContent />,
    })
  }

  if (hrNewModule?.value === 'on' && !isFromAffiliationView) {
    tabsData.push({
      label: 'Documentos',
      content: <DocumentsContainer />,
    })
  }

  if (workerMutation.isLoading) return <LoadingModal />

  return (
    <div className={classes.container}>
      <Box component={Paper} width="100%">
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          tabs={tabsData}
          variant="scrollable"
          scrollButtons={smDown ? 'on' : 'off'}
        />
        {tabsData[activeTab].content}
      </Box>
      <div className={classes.button}>
        {!isFromAffiliationView &&
          ['employee', 'pensioner', 'part_time_contract'].includes(
            worker?.contract_category
          ) && <ActivateButton isWorker={isWorker} worker={worker} />}
        {!isFromAffiliationView && !isWorker && worker.deleteable ? (
          <Button
            size="small"
            startIcon={<ErrorOutlineOutlinedIcon />}
            onClick={handleDeleteWorker}
          >
            Eliminar
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default WorkerBody
