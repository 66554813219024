import { useLayoutEffect, useReducer } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import OrganizationEmail from 'components/UI/OrganizationEmail'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'

import ElectronicPayrollContent from './Content'

const useStyles = makeStyles((theme) => ({
  helpMessage: {
    color: theme.palette.grey[500],
  },
  articleContent: {
    height: 'calc(90vh - 134px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: theme.spacing(2),
  },
  footer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3.75),
    },
    textAlign: 'center',
  },
}))

const initialState = {
  isActivated: false,
  showWelcomeBanner: true,
  showSuccessBanner: false,
  firstAttempt: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'show_only_config_form':
      return {
        ...state,
        showWelcomeBanner: false,
        showSuccessBanner: false,
        isActivated: true,
      }

    case 'show_form_from_banner':
      return {
        ...state,
        showWelcomeBanner: false,
      }

    case 'show_success_banner':
      return {
        ...state,
        showSuccessBanner: true,
      }

    case 'first_config_attempt':
      return {
        ...state,
        firstAttempt: true,
      }

    default:
      throw new Error(`action ${action.type} doesn't exist`)
  }
}

const ElectronicPayrollSettings = () => {
  const classes = useStyles()
  const companyId = getCompanyId()
  const {
    electronicPayrollConfigurationQuery,
  } = useElectronicPayrollConfigurationService({
    serviceParams: {
      queryKey: ['electronicPayrollStatus', companyId],
    },
  })
  const [state, dispatch] = useReducer(reducer, initialState)
  const { isActivated } = state

  const isElectronicPayrollAuthorized =
    electronicPayrollConfigurationQuery.data?.government_enabled_status ===
    'authorized'

  const isElectronicPayrollActive =
    electronicPayrollConfigurationQuery.data?.active

  useLayoutEffect(() => {
    if (
      isElectronicPayrollAuthorized &&
      isElectronicPayrollActive &&
      !isActivated
    ) {
      dispatch({
        type: 'show_only_config_form',
      })
    }
  }, [isActivated, isElectronicPayrollActive, isElectronicPayrollAuthorized])

  return (
    <Page
      documentTitle="Configurar nómina electrónica"
      isLoading={electronicPayrollConfigurationQuery.isLoading}
      header="Configuración de nómina electrónica"
    >
      <article className={classes.articleContent}>
        <ElectronicPayrollContent state={state} dispatch={dispatch} />
        <footer className={classes.footer}>
          <Typography className={classes.helpMessage} variant="body2">
            Si necesitas que te rescatemos, escribenos a <OrganizationEmail />.
          </Typography>
        </footer>
      </article>
    </Page>
  )
}

export default ElectronicPayrollSettings
