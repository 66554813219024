import addDays from 'date-fns/addDays'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useOrganizerWorkerService from 'utils/hooks/organizer/workerService'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'
import useTerminationService from 'utils/hooks/worker/terminationService'

import * as routes from 'config/routes'

import ContractModal, { validateCreateContract } from '../Modal/ContractModal'
import useContractModal from '../Modal/useContractModal'
import getColumnsData from './columnsData'

const ContractIndex = ({ match, history, location }) => {
  const [
    hasDateGapsBetweenContracts,
    setHasDateGapsBetweenContracts,
  ] = useState(false)
  const { createContract } = location?.state || {}
  const confirm = useConfirm()
  const { worker, refreshWorker } = useWorker()
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const { handleDownloadPayslip } = usePayslipModal()

  const { workerId } = match.params
  const {
    contractModalState,
    openContractModal,
    closeContractModal,
  } = useContractModal(workerId)

  const { contractsQuery } = useContractsService({
    serviceParams: {
      queryKey: 'allContracts',
      workerId,
    },
  })

  const { terminationMutation } = useTerminationService({
    queryOptions: {
      enabled: false,
    },
  })

  const { periodQuery } = usePeriodService({
    queryOptions: {
      enabled: !!contractsQuery.data,
      onSuccess: ({ data }) => {
        if (
          !!contractsQuery.data &&
          contractsQuery.data.length > 0 &&
          !!data &&
          createContract
        ) {
          const currentContract = contractsQuery.data[0]

          validateCreateContract({
            currentContract,
            currentPeriod: data,
            handleRedirectToTermination: () => {
              history.push(
                routes.WORKER_CONTRACT_TERMINATION_NEW(
                  workerId,
                  currentContract.id
                )
              )
            },
            handleShowModal: () => {
              let newContract = {}

              if (currentContract) {
                const {
                  health_provider: healthProvider,
                  pension_provider: pensionProvider,
                  severance_provider: severanceProvider,
                  work_center: workCenter,
                  end_day: endDay,
                } = currentContract

                newContract = {
                  health_provider: healthProvider,
                  pension_provider: pensionProvider,
                  severance_provider: severanceProvider,
                  work_center: workCenter,
                  prevEndDay: addDays(endDay, 1),
                }
              }

              openContractModal(newContract)
            },
            confirm,
          })
        }
      },
    },
  })

  const { organizerWorkerMutation } = useOrganizerWorkerService()

  const updateContracts = () =>
    queryClient.invalidateQueries(['allContracts', workerId])

  const handleCreateContract = () => {
    const currentContract = contractsQuery.data[0]

    validateCreateContract({
      currentContract,
      currentPeriod: periodQuery.data,
      handleRedirectToTermination: () => {
        history.push(
          routes.WORKER_CONTRACT_TERMINATION_NEW(workerId, currentContract.id)
        )
      },
      handleShowModal: () => {
        let newContract = {}

        if (currentContract) {
          const {
            health_provider: healthProvider,
            pension_provider: pensionProvider,
            severance_provider: severanceProvider,
            work_center: workCenter,
            end_day: endDay,
          } = currentContract

          newContract = {
            health_provider: healthProvider,
            pension_provider: pensionProvider,
            severance_provider: severanceProvider,
            work_center: workCenter,
            prevEndDay: addDays(endDay, 1),
          }
        }

        openContractModal(newContract)
      },
      confirm,
    })
  }

  const handlePayslipTermination = (contractId) => {
    terminationMutation.mutate(
      {
        mutationMethod: 'GET',
        contractId,
      },
      {
        onSuccess: ({ data }) => {
          const { payroll_id: payrollId } = data
          handleDownloadPayslip({
            payroll: { id: payrollId },
            payslip_type: 'termination',
          })
        },
      }
    )
  }

  const handleEditContract = (contract) => {
    confirm({
      title: '¿Estás seguro de editar el contrato?',
      description:
        'Este cambio afectará a todas las nóminas anteriores del contrato.',
      onOk: () => openContractModal(contract),
    })
  }

  const deleteTerminationCallback = () => {
    updateContracts()
    refreshWorker()
  }

  const handleDeleteTermination = (contractId) => {
    confirm({
      title: 'Eliminar liquidación laboral',
      description:
        '¿Estás seguro de eliminar la liquidación laboral de esta persona? Este cambio no puede deshacerse. Esta persona se incluirá de nuevo en todas las nóminas hasta su fecha final de contrato.',
      type: 'warning',
      onOk: async () => {
        await terminationMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            contractId,
          },
          {
            onSuccess: () => {
              showSuccessMessage('Liquidación laboral eliminada')
              deleteTerminationCallback()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      },
      okText: 'Eliminar liquidación',
    })
  }

  const contractModalSubmitCallback = async () => {
    if (location.state) {
      // remove the createLocation flag when the new contract has been created
      const newLocationState = { ...location.state }
      delete newLocationState.createContract

      history.replace(location.pathname, newLocationState)
    }

    updateContracts()
  }

  const deleteContract = (id) => {
    organizerWorkerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        contractId: id,
      },
      {
        onSuccess: ({ message }) => {
          updateContracts()
          refreshWorker()
          showSuccessMessage(message)
        },
      }
    )
  }

  const handleDeleteContract = (contractId) => {
    confirm({
      type: 'warning',
      title: 'Eliminar contrato',
      description:
        'Eliminar este contrato será permanente y no se podrá deshacer. ¿Estás seguro?',
      okText: 'Eliminar',
      confirmCheckbox: true,
      onOk: () => deleteContract(contractId),
    })
  }

  // checks if exist date gaps between contracts
  useEffect(() => {
    if (worker?.contract_category !== 'contractor') {
      for (let i = 0; i < contractsQuery.data?.length; i += 1) {
        const cont = contractsQuery.data[i]
        const nextCont = contractsQuery.data[i + 1]

        if (nextCont) {
          cont.prevInitialDay = nextCont.initial_day

          const dateLeft = cont.initial_day
          const dateRight = nextCont.end_day

          const diff = differenceInCalendarDays(dateLeft, dateRight)

          if (diff > 1) {
            setHasDateGapsBetweenContracts(diff > 1)
            break
          }
        } else {
          setHasDateGapsBetweenContracts(false)
        }
      }
    }
  }, [contractsQuery.data, worker])

  return (
    <Page
      documentTitle={`Contratos de ${worker ? worker.fullName : 'trabajador'}`}
      header={
        <Typography variant="h5">
          Contratos de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      isLoading={contractsQuery.isLoading}
      isLoadingWithModal={terminationMutation.isLoading}
    >
      {hasDateGapsBetweenContracts && (
        <Alert severity="info">
          Existen días vacíos entre la duración de algunos contratos, estos días
          se contarán como si el trabajador hubiera estado inactivo.
        </Alert>
      )}
      <Table
        columns={getColumnsData(
          workerId,
          handleEditContract,
          handleDeleteTermination,
          handlePayslipTermination,
          handleDeleteContract
        )}
        data={contractsQuery.data}
        options={{
          pagination: false,
          search: false,
        }}
        actions={[
          {
            id: 'add_contract',
            tooltip: 'Crear nuevo Contrato',
            isFreeAction: true,
            onClick: () => handleCreateContract(),
            disabled: !periodQuery.data,
          },
        ]}
      />
      {contractModalState.open && (
        <ContractModal
          state={contractModalState}
          handleClose={closeContractModal}
          submitCallback={contractModalSubmitCallback}
        />
      )}
    </Page>
  )
}

export default ContractIndex
