import MuiLink from '@material-ui/core/Link'

import Link from 'components/UI/MaterialUI/Link'
import NoWrap from 'components/UI/NoWrap'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDate } from 'utils/dateTime'
import { contractCategories } from 'utils/worker'

import * as routes from 'config/routes'

const LinksSeparator = () => {
  return (
    <div
      style={{
        margin: '0 4px',
      }}
    >
      |
    </div>
  )
}

const getColumnsData = (
  workerId,
  handleEditContract,
  handleDeleteTermination,
  handlePayslipTermination,
  handleDeleteContract
) => {
  return [
    {
      Header: '#',
      accessor: 'number',
    },
    {
      Header: 'Tipo de contrato',
      accessor: 'category',
      Cell: ({ row }) =>
        contractCategories[row.original.category]
          ? contractCategories[row.original.category]
          : row.original.category,
    },
    {
      Header: 'Fecha inicial',
      accessor: 'initial_day',
      Cell: ({ row }) => formatDisplayDate(row.original.initial_day),
    },
    {
      Header: 'Fecha final',
      accessor: 'end_day',
      Cell: ({ row }) => formatDisplayDate(row.original.end_day) || 'No aplica',
    },
    {
      Header: 'Opciones contrato',
      accessor: 'contract_options',
      alignHeader: 'center',
      alignCell: 'center',
      Cell: ({ row }) => {
        const { id, terminated, category } = row.original
        return (
          <>
            <Link
              to={{
                pathname: `contracts/${id}/wages`,
                state: { contract: row.original },
              }}
            >
              Salarios
            </Link>
            {!terminated && (
              <>
                <LinksSeparator />
                <MuiLink
                  component="button"
                  variant="body2"
                  onClick={() => handleEditContract(row.original)}
                  data-cy="edit_contract_button"
                >
                  Editar
                </MuiLink>
              </>
            )}
            {(category === 'employee' || category === 'pensioner') && (
              <>
                <LinksSeparator />
                <Link to={`contracts/${id}/social_benefits`}>
                  <NoWrap>Prestaciones sociales</NoWrap>
                </Link>
              </>
            )}
            {isOrganizer() && (
              <>
                <LinksSeparator />
                <MuiLink
                  component="button"
                  variant="body2"
                  onClick={() => handleDeleteContract(id)}
                >
                  Eliminar
                </MuiLink>
              </>
            )}
          </>
        )
      },
    },
    {
      Header: 'Opciones liquidación',
      accessor: 'termination_options',
      alignHeader: 'center',
      alignCell: 'center',
      Cell: ({ row }) => {
        const { id, terminated, termination } = row.original
        if (terminated) {
          return (
            <>
              <MuiLink
                component="button"
                variant="body2"
                onClick={() => handlePayslipTermination(id)}
              >
                <NoWrap>Ver colilla</NoWrap>
              </MuiLink>
              <LinksSeparator />
              <Link to={routes.WORKER_CONTRACT_TERMINATION_EDIT(workerId, id)}>
                Editar
              </Link>
              {termination.deleteable && (
                <>
                  <LinksSeparator />
                  <MuiLink
                    component="button"
                    variant="body2"
                    onClick={() => handleDeleteTermination(id)}
                  >
                    Eliminar
                  </MuiLink>
                </>
              )}
            </>
          )
        }

        return (
          <Link to={routes.WORKER_CONTRACT_TERMINATION_NEW(workerId, id)}>
            <NoWrap>Liquidar contrato</NoWrap>
          </Link>
        )
      },
    },
  ]
}

export default getColumnsData
