import { Formik, Form } from 'formik'
import * as yup from 'yup'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import FormField from 'components/UI/Formik/FormField/Index'
import Button from 'components/UI/Button/Button'
import HeaderImage from 'components/UI/Modal/HeaderImage'

import ilustrationError from 'assets/images/errors/error.svg'

import { isObjectEmpty } from 'utils/general'
import { formatCurrency, getWordSingular } from 'utils/format'
import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'

import { useCalculateExtraWorkers } from '../../NewSubscription/Index/helpers'
import usePaymentModal from '../PaymentModal/usePaymentModal'

yup.setLocale(yupLocaleES)

const getValidationSchema = (initialExtraWorkerNumber) => {
  return yup.object({
    extra_workers_number: yup
      .number()
      .nullable()
      .min(
        initialExtraWorkerNumber,
        `El número mínimo de personas es ${initialExtraWorkerNumber}`
      )
      .required(),
  })
}

const useStyles = makeStyles((theme) => ({
  sectionResume: {
    marginTop: theme.spacing(3),
  },
  totalTextPricing: {
    backgroundColor: theme.palette.primary.lighter,
    justifyContent: 'center',
    height: '-webkit-fill-available',
  },
  customInput: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  inputNumber: {
    display: 'flex',
    width: '185px',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  sectionPricing: {
    marginTop: theme.spacing(2.5),
    display: 'grid',
    gridTemplateColumns: '55% 42%',
    gap: theme.spacing(2.5),
    gridTemplateRows: '100px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '100%',
    },
  },
  pricingPeriod: {
    display: 'flex',
    height: '50px',
    backgroundColor: theme.palette.grey[100],
    padding: '10px',
    borderRadius: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(2),
    margin: theme.spacing(3, 0, 4),
  },
}))

export const ModalExtraWorker = ({ onClose }) => {
  const classes = useStyles()
  const { paidWorkers, additionalWorkersInfo } = useSubscription()
  const { calculateSubscriptionFromInput } = useCalculateExtraWorkers()
  const validationSchema = getValidationSchema(
    additionalWorkersInfo?.extra_workers_number
  )
  const paymentModal = usePaymentModal()
  const handlePayment = (value) => {
    paymentModal.openModal(value)
  }
  const initialDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_initial_day
  )
  const endDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_end_day
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{
        extra_workers_number: additionalWorkersInfo?.extra_workers_number || 0,
        pricing_value: additionalWorkersInfo?.extra_workers_value || 0,
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        const callback = (data) =>
          setFieldValue(
            'pricing_value',
            data?.additional_workers_info?.extra_workers_value
          )
        const handleOnChange = (event) =>
          calculateSubscriptionFromInput(event.target.value, callback)

        return (
          <>
            <Typography variant="body1">
              Vas a superar el límite de {paidWorkers}{' '}
              {getWordSingular('personas', paidWorkers <= 1)} con el que
              actualmente cuentas en tu plan anual. Para agregar más personas
              debes realizar un pago. El cobro se hará proporcional al tiempo
              restante de tu suscripción.
            </Typography>
            <section className={classes.sectionResume}>
              <Typography variant="subtitle1">
                ¿Cuántas personas quieres agregar?
              </Typography>
              <Form>
                <div className={classes.inputNumber}>
                  <FormField
                    variant="number"
                    name="extra_workers_number"
                    onChange={handleOnChange}
                    inputProps={{ className: classes.customInput }}
                  />
                  <Typography color="primary" variant="h6">
                    Personas
                  </Typography>
                </div>
              </Form>
            </section>
            <section className={classes.sectionPricing}>
              <div>
                <Typography variant="subtitle1">Periodo del plan</Typography>
                <div className={classes.pricingPeriod}>
                  <AccessTimeIcon />
                  <Typography variant="subtitle1">{initialDay}</Typography>
                  <ArrowRightAltIcon />
                  <Typography variant="subtitle1">{endDay}</Typography>
                </div>
              </div>
              <div>
                <Typography variant="subtitle1">Total a pagar</Typography>
                <div
                  className={clsx(
                    classes.pricingPeriod,
                    classes.totalTextPricing
                  )}
                >
                  <Typography color="primary" variant="h5">
                    {formatCurrency(values.pricing_value)}
                  </Typography>
                </div>
                <Typography color="primary" variant="caption">
                  *Incluye descuento suscripción anual
                </Typography>
              </div>
            </section>
            <section className={classes.buttonSection}>
              <Button variant="outlined" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                disabled={!isObjectEmpty(errors)}
                onClick={() => handlePayment(values.extra_workers_number)}
              >
                Mejorar mi plan
              </Button>
            </section>
          </>
        )
      }}
    </Formik>
  )
}

export const Header = () => {
  return (
    <HeaderImage
      image={ilustrationError}
      title="Agrega más Personas a tu Plan Anual"
    />
  )
}
