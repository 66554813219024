import { useState } from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import giftIcon from 'assets/images/views/dashboard/gift.svg'
import emptyAvatar from 'assets/images/avatars/empty_avatar.svg'

import { isAlegraClaroDist } from 'utils/auth'

import { hashName } from '../helpers'

const DashboardCommingSoonBirthdaysItem = ({ birthday, index }) => {
  const [avatarImg, setAvatarImg] = useState()

  const { worker_name: workerName, date } = birthday

  const hashedName = hashName(workerName)

  if (!isAlegraClaroDist()) {
    import(`assets/images/avatars/avatar${hashedName}.png`).then((a) =>
      setAvatarImg(a.default)
    )
  }

  return (
    <ListItem key={index}>
      <ListItemAvatar>
        <Avatar src={avatarImg || emptyAvatar} alt="avatar" />
      </ListItemAvatar>
      <ListItemText primary={workerName} secondary={date} />
      <img alt="Ícono Regalo" src={giftIcon} />
    </ListItem>
  )
}

export default DashboardCommingSoonBirthdaysItem
