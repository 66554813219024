import { ReactComponent as TotalPeopleSvgImage } from 'assets/images/views/payroll/icon_empleados.svg'
import { ReactComponent as TotalCompanySvgImage } from 'assets/images/views/payroll/icon_empresa.svg'

import TotalsItem from './TotalsItem'

const PayrollReviewTotals = ({ workersPayment, companyCost }) => {
  const items = [
    {
      id: 'peopleTotal',
      SvgImage: TotalPeopleSvgImage,
      title: 'Total pago a personas',
      value: workersPayment,
    },
    {
      id: 'companyTotal',
      SvgImage: TotalCompanySvgImage,
      title: 'Costo total empresa',
      value: companyCost,
    },
  ]

  return (
    <>
      {items.map((item) => (
        <TotalsItem key={item.id} {...item} />
      ))}
    </>
  )
}

export default PayrollReviewTotals
