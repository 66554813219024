export const helpOnClick = (callback) => {
  if (window.HubSpotConversations) {
    if (window.HubSpotConversations.widget.status().loaded) {
      window.HubSpotConversations.widget.open()
      return
    }
    window.HubSpotConversations.widget.load({ widgetOpen: true })
  } else window.open('https://ayuda.nominapp.com/help', '_blank')

  if (callback) callback()
}

export default helpOnClick
