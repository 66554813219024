import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { PeriodContext } from 'components/Period/Payroll/Payroll'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Modal from 'components/UI/Modal/Modal'

import { formatDate } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import useCompensatedDaysService from 'utils/hooks/payroll/compensatedDays'
import useNoveltiesService from 'utils/hooks/payroll/novelties'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import useContentStyles from '../common/commonStyles'
import AccumulatedHolidays from './AccumulatedHolidays'
import CompensatedDays from './CompensatedDays'
import EarlyPayment from './EarlyPayment'
import Header from './Header'
import Holidays from './Holidays'
import Incapacities from './Incapacities'
import Licenses from './Licenses'
import {
  getModalTitle,
  getTabs,
  noveltyDescription,
  parseCompensatedDayValue,
} from './helpers'
import useNoveltiesModal from './useNoveltiesModal'

const useStyles = makeStyles({
  paper: {
    width: 800,
  },
})

const NoveltiesModal = ({ showModal, handleCloseModal, payroll }) => {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const contentClasses = useContentStyles()
  const { handleError } = useErrorHandler()
  const { updatePeriodCallback } = useContext(PeriodContext)
  const [isEditingHeader, setIsEditingHeader] = useState(false)
  const {
    contract_category: contractCategory,
    id: payrollId,
    holidays_average_salary: holidaysAverageSalary,
    novelties_average_salary: noveltiesAverageSalary,
    early_payment: earlyPayment,
    accumulated_holidays: accumulatedHolidays,
    compensated_days: compensatedDays,
    salary_category: salaryCategory,
  } = payroll
  const [generalData, setGeneralData] = useState({
    holidays_average_salary: holidaysAverageSalary,
    novelties_average_salary: noveltiesAverageSalary,
    early_payment: earlyPayment,
  })
  const holidaysApply = [
    'employee',
    'pensioner',
    'student_law_789',
    'terminated',
    'part_time_contract',
  ].includes(contractCategory)
  const licensesApply = contractCategory !== 'student_decree_055'
  const tabs = getTabs(holidaysApply, licensesApply)
  const [activeTab, setActiveTab] = useState(
    holidaysApply ? tabs[0].key : tabs[1].key
  )
  const [payrollCompensatedDays, setPayrollCompensatedDays] = useState(
    compensatedDays
  )
  const [novelties, setNovelties] = useState({})
  const { getNovelties, getSelectedDays } = useNoveltiesModal()
  const { compensatedDaysMutation } = useCompensatedDaysService()
  const payrollConceptsQueryKey = ['payrollConcepts', 'novelties', payrollId]
  const noveltiesQueryKey = ['noveltiesByPayroll', payrollId]
  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: payrollConceptsQueryKey,
      conceptsCategory: 'novelties',
    },
  })
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const { noveltiesQuery, noveltiesMutation } = useNoveltiesService({
    serviceParams: {
      queryKey: noveltiesQueryKey,
      payrollId,
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: (noveltiesData) => {
        const newNovelties = getNovelties(
          payrollConceptsQuery?.data,
          noveltiesData?.data
        )
        setNovelties(newNovelties)
      },
    },
  })

  const isGettingData =
    payrollConceptsQuery.isLoading || noveltiesQuery.isLoading
  const isMutating =
    compensatedDaysMutation.isLoading ||
    noveltiesMutation.isLoading ||
    payrollMutation.isLoading

  const enableHeaderEditing = () => setIsEditingHeader(true)

  const disableHeaderEditing = () => setIsEditingHeader(false)

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const handleChangeGeneralData = (event, name) => {
    let { value } = event.target

    if (value === 'true') {
      value = true
    } else if (value === 'false') {
      value = false
    } else {
      value = Number(event.target.rawValue)
    }

    setGeneralData((previousGeneralData) => ({
      ...previousGeneralData,
      [name]: value,
    }))
  }

  const copyNovelties = (noveltyType, noveltyCode) => {
    const noveltiesCopy = { ...novelties }

    noveltiesCopy[noveltyType] = { ...novelties[noveltyType] }

    noveltiesCopy[noveltyType][noveltyCode] = {
      ...novelties[noveltyType][noveltyCode],
    }

    noveltiesCopy[noveltyType][noveltyCode].items = [
      ...novelties[noveltyType][noveltyCode].items,
    ]

    return noveltiesCopy
  }

  const handleAddNovelty = (noveltyType, noveltyCode) => {
    const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)

    noveltiesCopy[noveltyType][noveltyCode].items.push({
      initial_day: null,
      end_day: null,
    })

    setNovelties(noveltiesCopy)
  }

  const handleChangeNovelty = (
    noveltyType,
    noveltyCode,
    index,
    date,
    dateType
  ) => {
    const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)
    const formattedDate = formatDate(date)
    const noveltiesItems = noveltiesCopy[noveltyType][noveltyCode].items

    noveltiesItems[index] = {
      ...noveltiesItems[index],
    }
    noveltiesItems[index][dateType] = formattedDate

    // if initial day is greater than end day, put this in null
    if (noveltiesItems[index].initial_day > noveltiesItems[index].end_day) {
      noveltiesItems[index].end_day = null
    }

    setNovelties(noveltiesCopy)

    if (dateType === 'initial_day') {
      // open end day date picker
      document
        .getElementById(
          `end-date-picker-${noveltyType}-${noveltyCode}-${index}`
        )
        ?.click()
    }
  }

  const handleDeleteNovelty = (noveltyType, noveltyCode, indexToDelete) => {
    const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)

    noveltiesCopy[noveltyType][noveltyCode].items.splice(indexToDelete, 1)

    if (noveltiesCopy[noveltyType][noveltyCode].items.length === 0) {
      noveltiesCopy[noveltyType][noveltyCode].items.push({
        initial_day: null,
        end_day: null,
      })
    }

    if (noveltyType === 'holidays') {
      if (
        noveltiesCopy.holidays.enjoyed_days.items.length === 0 &&
        generalData.early_payment === true
      ) {
        const generalDataCopy = { ...generalData, early_payment: false }

        setGeneralData(generalDataCopy)
      }
      setNovelties(noveltiesCopy)
    } else {
      setNovelties(noveltiesCopy)
    }
  }

  const handleChangeCompensatedDays = ({ target: { value } }) => {
    setPayrollCompensatedDays(parseCompensatedDayValue(value))
  }

  const onCloseModal = () => {
    handleCloseModal()
    queryClient.removeQueries(payrollConceptsQueryKey)
    queryClient.removeQueries(noveltiesQueryKey)
  }

  const handleSendNovelties = async () => {
    try {
      let dataToSend = []
      const initialGeneralData = {
        holidays_average_salary: holidaysAverageSalary,
        novelties_average_salary: noveltiesAverageSalary,
        early_payment: earlyPayment,
      }

      // Copy initial novelties
      const initialNovelties = noveltiesQuery.data?.map((noveltyItem) => ({
        ...noveltyItem,
      }))

      Object.entries(novelties).forEach(([, noveltyCategoryGroup]) => {
        Object.entries(noveltyCategoryGroup).forEach(([, noveltyCodeGroup]) => {
          noveltyCodeGroup.items.forEach((novelty) => {
            // if has id means was a previous novelty
            if (novelty.id) {
              initialNovelties.forEach((initialNovelty) => {
                const initialNoveltyCopy = initialNovelty
                // check to which of the initial novelties correspond to
                if (novelty.id === initialNovelty.id) {
                  // if have any change
                  if (
                    (novelty.initial_day !== initialNovelty.initial_day ||
                      novelty.end_day !== initialNovelty.end_day) &&
                    novelty.initial_day &&
                    novelty.end_day
                  ) {
                    dataToSend.push({
                      id: novelty.id,
                      initial_day: novelty.initial_day,
                      end_day: novelty.end_day,
                    })
                  }
                  // This line allow add the better way the holidays
                  initialNoveltyCopy.checked = true
                }
              })
              // new novelty
            } else if (novelty.initial_day && novelty.end_day) {
              dataToSend.push({
                payroll_concept_id: noveltyCodeGroup.id,
                ...novelty,
              })
            }
          })
        })
      })

      // deleted ones
      dataToSend =
        !!initialNovelties &&
        initialNovelties
          .filter((novelty) => !novelty.checked)
          .map((novelty) => ({
            ...novelty,
            initial_day: null,
            end_day: null,
          }))
          .concat(dataToSend)

      let responseData = null

      if (compensatedDays !== payrollCompensatedDays) {
        responseData = await compensatedDaysMutation.mutateAsync({
          mutationMethod: 'PUT',
          payrollId,
          compensatedDays: {
            quantity: payrollCompensatedDays,
          },
        })
        // to correctly update payroll row in datatable
        if (responseData.data) {
          responseData.data.payroll.compensated_days =
            responseData.data.compensated_days.quantity
        }
      }

      if (dataToSend.length > 0) {
        responseData = await noveltiesMutation.mutateAsync(
          {
            mutationMethod: 'PUT',
            payrollId,
            novelties: dataToSend,
          },
          {
            onSuccess: () => trackEvent(integrationEvent.PAYROLL_ADD_OVERTIME),
          }
        )
      }

      if (JSON.stringify(initialGeneralData) !== JSON.stringify(generalData)) {
        const generalDataCopy = { ...generalData }
        generalDataCopy.early_payment = generalDataCopy.early_payment.toString()

        responseData = await payrollMutation.mutateAsync({
          mutationMethod: 'PATCH',
          payrollId,
          data: {
            payroll: generalDataCopy,
          },
        })
      }

      if (responseData?.data) {
        updatePeriodCallback(responseData.data)
      }
      onCloseModal()
    } catch (error) {
      handleError(error, null, {
        notistackOptions: { preventDuplicate: true },
      })
    }
  }

  const selectedDays = getSelectedDays(novelties)
  const { holidays, incapacities, licenses } = novelties

  return (
    <Modal
      open={showModal}
      header={
        <Typography variant="h5">{getModalTitle(contractCategory)}</Typography>
      }
      onOk={handleSendNovelties}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isMutating}
      dialogProps={{
        'data-cy': 'novelties_modal',
        scroll: 'body',
        maxWidth: false,
        classes,
      }}
    >
      <Header
        payroll={payroll}
        holidaysAverageSalary={generalData.holidays_average_salary}
        noveltiesAverageSalary={generalData.novelties_average_salary}
        isEdit={isEditingHeader}
        onEnableEditing={enableHeaderEditing}
        onDisableEditing={disableHeaderEditing}
        onChangeData={handleChangeGeneralData}
        onOK={disableHeaderEditing}
      />
      <>
        {!isGettingData && !isObjectEmpty(novelties) ? (
          <Paper>
            <Tabs value={activeTab} onChange={handleChangeTab} tabs={tabs} />
            <div className={contentClasses.root}>
              <Typography paragraph>{noveltyDescription[activeTab]}</Typography>

              {activeTab === 'holidays' && holidaysApply && (
                <>
                  <AccumulatedHolidays
                    accumulatedHolidays={accumulatedHolidays}
                  />
                  <Divider />
                  <Holidays
                    payroll={payroll}
                    holidays={holidays}
                    onAddNovelty={handleAddNovelty}
                    onChangeNovelty={handleChangeNovelty}
                    onDeleteNovelty={handleDeleteNovelty}
                    selectedDays={selectedDays}
                  />
                  <Divider />
                  {holidays.enjoyed_days.items.length !== 0 &&
                    holidays.enjoyed_days.items[0].end_day && (
                      <>
                        <EarlyPayment
                          holidays={holidays}
                          earlyPayment={generalData.early_payment}
                          onChange={handleChangeGeneralData}
                        />

                        <Divider />
                      </>
                    )}
                  <CompensatedDays
                    compensatedDays={payrollCompensatedDays}
                    handleCompensatedDays={handleChangeCompensatedDays}
                  />
                </>
              )}

              {activeTab === 'incapacities' && (
                <Incapacities
                  payroll={payroll}
                  incapacities={incapacities}
                  onAddNovelty={handleAddNovelty}
                  onChangeNovelty={handleChangeNovelty}
                  onDeleteNovelty={handleDeleteNovelty}
                  selectedDays={selectedDays}
                  contract_category={contractCategory}
                  salary_category={salaryCategory}
                />
              )}

              {activeTab === 'licenses' && licensesApply && (
                <Licenses
                  payroll={payroll}
                  licenses={licenses}
                  onAddNovelty={handleAddNovelty}
                  onChangeNovelty={handleChangeNovelty}
                  onDeleteNovelty={handleDeleteNovelty}
                  selectedDays={selectedDays}
                  contract_category={contractCategory}
                  salary_category={salaryCategory}
                />
              )}
            </div>
          </Paper>
        ) : (
          <LoadingBox />
        )}
      </>
    </Modal>
  )
}

export default NoveltiesModal
