import { usePopupState } from 'material-ui-popup-state/hooks'

import { makeStyles } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1'

import DropdownButton from 'components/UI/Button/Dropdown'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import ToolbarActions from 'components/UI/Table/Toolbar/ToolbarActions'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import { getPreviousPeriod } from 'utils/dateTime'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import periodService from 'services/payroll/periodService'

import PayrollHistoryFilter from '../PayrollHistoryFilter/Index'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsAction: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
}))

const ToolbarPeriodHistory = ({
  lastPeriodDate = {},
  setSelectedMonth,
  selectedMonth,
  setSelectedYear,
  selectedYear,
  ...props
}) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'createPreviousPeriodMenu',
  })
  const { freeActions } = props

  const actionDropdown = freeActions.filter(
    (action) => action.id === 'previousPeriod'
  )
  const actionClick = freeActions.filter(
    (action) => action.id !== 'previousPeriod'
  )

  const { date, length } = lastPeriodDate

  const { start, end } = getPreviousPeriod(date, length) || {}

  const createPreviousPeriod = async (rangeType) => {
    try {
      const { message } = await periodService.createPreviousPeriod(rangeType)
      showSuccessMessage(message)
    } catch (error) {
      handleError(error)
    }
  }

  const formatDateCopy = () => (
    <>
      <b>{start}</b>
      {` ${length === 'fortnightly' ? 'y del' : 'al'} `}
      <b>{end}</b>
    </>
  )

  const handleIndividualPeriod = () => {
    confirm({
      title: 'Crear periodo anterior',
      description: (
        <>
          Al continuar se creará el periodo{' '}
          {start && end ? (
            <>
              de nómina del {formatDateCopy()} que representa el periodo
              anterior al último periodo de nómina activo que tiene tu compañía
              en Nominapp
            </>
          ) : (
            'inmediatamente anterior al último periodo de nómina activo que tiene tu compañía en Nominapp'
          )}{' '}
        </>
      ),
      okText: 'Generar periodo',
      onOk: () => createPreviousPeriod('previous_month'),
    })
    popupState.close()
  }

  const handleHistoricalPeriod = () => {
    confirm({
      title: 'Crear periodos históricos para prestaciones sociales',
      description: (
        <>
          Al continuar se crearán los periodos de nómina necesarios para que
          puedas calcular los promedios históricos de los pagos de prestaciones
          sociales <b>pendientes a la fecha.</b>
          <br />
          <br />
          <b>Nota:</b> tendrás un mes para realizar la carga de información
          histórica y luego estos periodos no podrán editarse
        </>
      ),
      okText: 'Generar histórico',
      onOk: () => createPreviousPeriod('historical'),
    })
    popupState.close()
  }

  const items = [
    {
      id: 'individual',
      name: 'Individual',
      icon: <ExposurePlus1Icon color="primary" />,
      onClick: handleIndividualPeriod,
    },
    {
      id: 'historical',
      name: 'Historicos',
      icon: <DateRangeIcon color="primary" />,
      onClick: handleHistoricalPeriod,
    },
  ]

  return (
    <ToolbarContainer className={classes.container}>
      <PayrollHistoryFilter
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        selectedYear={selectedYear}
      />
      <div className={classes.buttonsAction}>
        {actionClick && actionClick.length > 0 ? (
          <ToolbarActions freeActions={actionClick} />
        ) : null}
        {actionDropdown && actionDropdown.length > 0
          ? actionDropdown.map((action) => {
              return (
                <DropdownButton
                  key={action.id}
                  title={action.tooltip}
                  popupState={popupState}
                  options={items}
                  {...action.buttonProps}
                />
              )
            })
          : null}
      </div>
    </ToolbarContainer>
  )
}

export default ToolbarPeriodHistory
