import { useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Slide from '@material-ui/core/Slide'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { hashName } from 'components/Dashboard/helpers'
import useWorker from 'components/Worker/useWorker'

import { isAlegraClaroDist } from 'utils/auth'
import { formatLocateDateString } from 'utils/dateTime'
import { getWorkerType, workerTagLabel } from 'utils/worker'

import emptyAvatar from 'assets/images/avatars/empty_avatar.svg'

import WorkerHeaderActions from './Actions'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2, 5),
    backgroundColor: theme.palette.common.white,
  },
  avatar: {
    borderWidth: theme.spacing(0.5),
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  chip: {
    margin: theme.spacing(1.5),
  },
}))
const WorkerSidebar = () => {
  const [avatarImg, setAvatarImg] = useState()
  const { worker } = useWorker({ useCache: true })
  const classes = useStyles()
  const avatarIndex = hashName(worker?.id_number)

  if (!isAlegraClaroDist()) {
    import(`assets/images/avatars/avatar${avatarIndex}.png`).then((a) =>
      setAvatarImg(a.default)
    )
  }

  const workerType = getWorkerType(worker)

  return (
    <Slide direction="right" timeout={500} in>
      <div className={classes.main}>
        <Tooltip
          title="Estamos trabajando para que pronto puedas subir la foto del trabajador. 🙂"
          placement="bottom"
          enterDelay={300}
        >
          <Avatar
            src={avatarImg || emptyAvatar}
            alt="avatar"
            className={classes.avatar}
          />
        </Tooltip>
        <Typography variant="h5" color="primary" gutterBottom>
          {worker?.fullName}
        </Typography>
        {workerTagLabel[workerType] ? (
          <Chip
            label={workerTagLabel[workerType]}
            className={classes.chip}
            data-cy="contract-category-chip"
          />
        ) : null}
        <Typography variant="subtitle1" gutterBottom>
          {worker?.position?.name}
        </Typography>
        {worker?.terminated && (
          <Typography color="textSecondary">
            Liquidado el: {formatLocateDateString(worker?.termination.date)}
          </Typography>
        )}
        <WorkerHeaderActions />
      </div>
    </Slide>
  )
}

export default WorkerSidebar
