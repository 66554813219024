import clsx from 'clsx'

import Toolbar from '@material-ui/core/Toolbar'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(),
    '& > *': {
      margin: theme.spacing(),
    },
  },
  justifySpaceBetween: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    overflow: 'hidden',
  },
}))

const ToolbarContainer = ({ children, ...props }) => {
  const classes = useStyles()

  const { isAllRowsSelected, className, showSearch } = props || {}
  return (
    <Toolbar
      className={clsx(classes.root, className, {
        [classes.highlight]: isAllRowsSelected,
        [classes.justifySpaceBetween]: showSearch,
      })}
    >
      {children}
    </Toolbar>
  )
}

export default ToolbarContainer
