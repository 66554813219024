import { useContext, useState } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import { formatNumber } from 'utils/format'

import { PeriodContext } from '../../Payroll'
import WorkedHoursModal from '../Modals/WorkedHours'

const CellWorkedHours = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const [isModalWorkedHoursVisible, setIsModalWorkedHoursVisible] = useState(
    false
  )

  const isSalaryPerHour = payroll.salary_category === 'per_hour'

  return (
    <>
      {getPayrollConceptItemCell({
        payrollConceptValue: isSalaryPerHour && payroll.worked_time,
        text: 'Horas',
        formatter: formatNumber,
        onClick: () => setIsModalWorkedHoursVisible(true),
        editable: period.editable && isSalaryPerHour && !payroll.locked,
      })}
      {isModalWorkedHoursVisible && (
        <WorkedHoursModal
          payroll={payroll}
          visible={isModalWorkedHoursVisible}
          hideWorkedHoursModal={() => setIsModalWorkedHoursVisible(false)}
        />
      )}
    </>
  )
}

export default CellWorkedHours
