import Chip from '@material-ui/core/Chip'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

export const columnsData = [
  {
    Header: 'Ubicación',
    accessor: 'position_info',
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ row }) =>
      `${row.original?.document_type} ${row.original?.id_number}`,
  },
  {
    Header: 'Valor esperado',
    accessor: 'expected_value',
  },
  {
    Header: 'Valor actual',
    accessor: 'current_value',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    customWidth: '420px',
  },
]

export const generateTabs = (tab, handleChangeTab, data) => () => {
  const tabs = []

  if (data.errors)
    tabs.push({ key: 'errors', label: 'Errores', size: data.errors.length })

  if (data.alerts)
    tabs.push({ key: 'alerts', label: 'Alertas', size: data.alerts.length })

  if (data.possible_fixes)
    tabs.push({
      key: 'possible_fixes',
      label: 'Posible corrección',
      size: data.possible_fixes.length,
    })

  return tab ? (
    <Tabs
      value={tab}
      onChange={handleChangeTab}
      tabs={tabs.map((tabItem) => ({
        key: tabItem.key,
        label: (
          <>
            {tabItem.label}
            <Chip label={tabItem.size} style={{ marginLeft: 5 }} />
          </>
        ),
      }))}
    />
  ) : null
}

export const formatData = (data = {}) => {
  const localData = {
    ...data,
  }
  Object.keys(localData).forEach((tab) => {
    if (localData[tab].length === 0) {
      delete localData[tab]
    }
  })

  return localData
}

export const getArusHeaderMessage = (status) => {
  let statusText = ''

  if (status === 'declined') {
    statusText = ` fue Rechazada`
  }

  if (status === 'paid') {
    statusText = ` fue Aprobada`
  }

  if (status === 'pending') {
    statusText = ` está Pendiente`
  }

  return {
    baseText: `Tu Planilla de seguridad social de `,
    statusText,
  }
}

export const getArusHelpMessage = (status) => {
  if (status === 'declined') {
    return (
      <>
        Tu planilla de pago de seguridad social fue <span>rechazada</span>,
        debes consultar con <span>SuAporte</span> y verificar el estado actual
        del pago.
      </>
    )
  }

  if (status === 'paid') {
    return (
      <>
        Tu planilla de pago de seguridad social fue <span>aprobada</span>.
      </>
    )
  }

  if (status === 'pending') {
    return (
      <>
        Tu planilla de pago de seguridad social <span>está pendiente</span>,
        debes consultar con <span>SuAporte</span> y verificar el estado actual
        del pago.
      </>
    )
  }

  return null
}
