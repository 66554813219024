import generateSimpleMessage from './utils'

export default {
  account_number:
    'Es necesario para generar el archivo con el cual vas a pagar en la sucursal virtual de tu banco una vez hayas liquidado tu nómina. No realizaremos ninguna transacción a este número de cuenta.',
  location:
    'Las opciones de “Sedes” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Sedes”.',
  area:
    'Las opciones de “Áreas” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Áreas”.',
  position:
    'Las opciones de “Cargos” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Cargos”.',
  costCenter:
    'El centro de costos permitirá asociar a cada persona a una cuenta contable lo cual facilitará el ingreso de la información al software contable.',
  accumulated_holidays_editing: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-los-dias-de-vacaciones-acumuladas',
    'Este valor indica los días de vacaciones pendientes a la fecha de corte del periodo anterior, restándole las vacaciones disfrutadas en el período actual.'
  ),
  accumulated_holidays:
    'Número de días de vacaciones disfrutados o remunerados a la fecha de corte del período anterior.',
}
