import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 6),
    },
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  divider: {
    margin: theme.spacing(5, 0),
  },
}))

const nameStep = [
  'Datos Personales',
  'Domicilio',
  'Información laboral',
  'Información financiera',
  'Referencias',
]

const CardForm = ({ children, currentStep }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        className={classes.title}
        data-cy="application-step-title"
      >
        {nameStep[currentStep]}
      </Typography>
      <Divider className={classes.divider} />
      {children}
    </Paper>
  )
}

export default CardForm
