import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 100,
  },
}))

const Emoji = ({ code }) => {
  const classes = useStyles()

  return <span className={classes.root}>{code}</span>
}

export default Emoji
