import { useFormikContext } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'

import CreatePayrollConceptModal from 'components/Period/Payroll/DataTable/Modals/CreatePayrollConceptModal'
import CustomField from 'components/UI/Formik/CustomField'
import FormField from 'components/UI/Formik/FormField/Index'
import useWorker from 'components/Worker/useWorker'

import { getAutoCompleteElementFilteredOptions } from 'utils/form'

const DeductionsSection = () => {
  const { worker } = useWorker({ useCache: true })
  const [
    isCreatePayrollConceptModalOpen,
    setIsCreatePayrollConceptModalOpen,
  ] = useState(false)
  const [conceptToCreate, setConceptToCreate] = useState({})
  const { values, setFieldValue } = useFormikContext()
  const queryClient = useQueryClient()
  const deductionsQuery = queryClient.getQueryData(['deductions', worker.id])

  const categoryOptions = [
    { value: 'deductions', label: 'Deducción' },
    { value: 'loans', label: 'Préstamo' },
  ]

  const deductionsConcepts = deductionsQuery?.data
    .filter(
      ({ coded_name: codedName }) =>
        codedName !== 'loan' &&
        codedName !== 'withholding_tax' &&
        codedName !== 'avista_payroll_credit'
    )
    .map((concept) => ({
      id: concept.id,
      name: concept.name,
    }))

  const handleCreateDeductionConcept = (_, newValue) => {
    if (newValue?.id === 'create') {
      setConceptToCreate(newValue.value ? { name: newValue.value } : {})
      setIsCreatePayrollConceptModalOpen(true)
    } else {
      setFieldValue('payroll_concept_id', newValue)
    }
  }

  const handleCloseCreatePayrollConceptModal = () =>
    setIsCreatePayrollConceptModalOpen(false)

  const handleRevalidateConcepts = async () => {
    await queryClient.invalidateQueries(['deductions', worker.id])
  }

  return (
    <>
      <Typography>
        Configura <strong>deducciones o préstamos</strong> que se descontarán de
        forma automática de la nómina.
      </Typography>

      <FormField
        label="Tipo"
        name="category"
        variant="select"
        options={categoryOptions}
      />
      {values.category === 'deductions' ? (
        <CustomField
          label="Concepto"
          name="payroll_concept_id"
          component="autocomplete"
          placeholder="Escribe el concepto de deducción a agregar..."
          noOptionsText="Cargando conceptos de deducción..."
          loadingText="Cargando conceptos de deducción..."
          options={deductionsConcepts}
          onChange={handleCreateDeductionConcept}
          filterOptions={(opt, params) =>
            getAutoCompleteElementFilteredOptions(
              opt,
              params,
              'Crear nuevo concepto de deducción'
            )
          }
        />
      ) : (
        <FormField label="Concepto" name="name" />
      )}
      {values.category === 'loans' && (
        <FormField label="Valor" name="value" variant="currency" align="left" />
      )}
      <FormField
        label="Cuota mensual"
        name={values.category === 'loans' ? 'monthly_fee' : 'monthly_value'}
        variant="currency"
        align="left"
      />
      {isCreatePayrollConceptModalOpen && (
        <CreatePayrollConceptModal
          category="deductions"
          conceptToCreate={conceptToCreate}
          handleClose={handleCloseCreatePayrollConceptModal}
          callback={handleRevalidateConcepts}
        />
      )}
    </>
  )
}

export default DeductionsSection
