import Link from '@material-ui/core/Link'

const MODAL_INFORMATION = [
  {
    title: '¡Así de fácil lo hacemos¡ 👌',
    videoLink: 'https://www.loom.com/embed/5eeba76d33d049a7a56da39c2f4e5959',
    description:
      'En dos minutos te enseñamos cómo usar esta maravillosa parte de Nominapp para liquidar la prima de servicios de tu equipo.',
    footer: (
      <>
        ¡Recuerda! Si tienes dudas, este artículo te podría ayudar.{' '}
        <Link
          href="https://ayuda.nominapp.com/help/como-puedo-pagar-la-prima-de-servicios-de-mis-empleados-con-nominapp"
          target="_blank"
        >
          Ver artículo
        </Link>
      </>
    ),
  },
  {
    title: '¿Qué es recalcular valores?',
    videoLink: 'https://www.loom.com/embed/a27affcc6c994358a444a2392353e07a',
    description:
      '¡Así de fácil puedes corregir la prima de servicios con Nominapp! 👌. En un minuto te enseñamos cómo corregir la liquidación de la prima de servicios de tus empleados con Nominapp.',
    footer: (
      <>
        ¡Recuerda!, Si tienes dudas, este artículo te podría ayudar.
        <Link
          href="https://ayuda.nominapp.com/help/como-puedo-pagar-la-prima-de-servicios-de-mis-empleados-con-nominapp"
          target="_blank"
        >
          Ver artículo
        </Link>
      </>
    ),
  },
]

export default MODAL_INFORMATION
