import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

import yellowHearthPersonImg from 'assets/images/yellow_hearth_person.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
  },
  image: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '40%',
  },
  totalWorkers: {
    backgroundColor: theme.palette.primary.lighter,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`,
    borderRadius: 8,
    alignItems: 'center',
    textAlign: 'left',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  peopleIcon: {
    color: theme.palette.primary.main,
    fontSize: 40,
  },
  buttonLink: {
    fontSize: theme.spacing(1.7),
    '&:hover': {
      backgroundColor: 'initial',
      textDecoration: 'underline',
    },
  },
}))

const ModalContentSummaryInfo = ({
  title,
  description,
  workers,
  handleClick,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        src={yellowHearthPersonImg}
        alt="Person with a happy hearth."
        className={classes.image}
      />

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      <Typography>{description}</Typography>

      <div className={classes.totalWorkers}>
        <PeopleOutlineIcon className={classes.peopleIcon} />
        <div>
          <Typography variant="body2">
            {workers?.length
              ? 'Total personas'
              : 'Total de nóminas electrónicas'}
          </Typography>
          <Typography variant="h5">
            {workers?.length ? workers?.length : workers}
          </Typography>
        </div>
      </div>

      <Button
        variant="text"
        className={classes.buttonLink}
        onClick={handleClick}
      >
        {workers?.length ? 'Ver personas' : 'Ver nóminas electrónicas'}
      </Button>
    </div>
  )
}

export default ModalContentSummaryInfo
