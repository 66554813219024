import { makeStyles } from '@material-ui/core/styles'

import Page from 'components/UI/Page/Page'
import SettingCard from 'components/UI/MaterialUI/Cards/CardEdit'
import useGetCardsData from 'components/Settings/cards'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
  },
}))

export default function Settings() {
  const classes = useStyles()
  const cardsData = useGetCardsData()

  return (
    <Page header="Configuración">
      <div className={classes.root}>
        {cardsData.map((card) =>
          card.hidden ? null : <SettingCard key={card.title} card={card} />
        )}
      </div>
    </Page>
  )
}
