import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import useWorker from 'components/Worker/useWorker'

import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'

import { ReactComponent as PiggyBankIcon } from 'assets/images/views/common/piggyBank_2.0.svg'

import useRecurrentConcepts from '../../useRecurrentConcepts'
import NewRecurrentConceptsItem from './RecurrentConceptsItem'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 4, 2),
  },
  img: {
    fill: theme.palette.primary.main,
    width: 47,
    height: 42,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  values: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}))

const NewRecurrentConceptsContent = () => {
  const classes = useStyles()
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const workerId = worker.id

  const { retentionsQuery } = useRetentionsConfigurationService({
    serviceParams: {
      queryKey: ['retentionConfigurations', workerId],
      workerId,
    },
    queryOptions: {
      enabled:
        Boolean(workerId) && worker?.retention_configuration && !isWorker,
    },
  })
  const {
    recurrentConcepts,
    isLoading: isRecurrentLoading,
    isError: isRecurrentError,
  } = useRecurrentConcepts()

  const isLoading = retentionsQuery.isLoading || isRecurrentLoading
  const isError = retentionsQuery.isError || isRecurrentError

  const getTotal = (conceptsItems) => {
    const filterItems = conceptsItems?.filter((item) => item.active)

    return filterItems?.reduce(
      (result, item) =>
        result + item.monthly_value || result + item.monthly_fee,
      0
    )
  }

  return (
    <Paper className={classes.container}>
      <PiggyBankIcon className={classes.img} />
      <Typography variant="body2" className={classes.title}>
        Conceptos recurrentes
      </Typography>
      {!isError && !isLoading && (
        <div className={classes.values}>
          <NewRecurrentConceptsItem
            label="+ Ingresos"
            value={getTotal(recurrentConcepts?.incomes)}
            color="success"
          />
          <NewRecurrentConceptsItem
            label="- Deducciones"
            value={getTotal(recurrentConcepts?.deductions)}
            color="error"
          />
          <NewRecurrentConceptsItem
            label="Retención"
            value={
              isWorker ? worker.withholding_tax : retentionsQuery.data?.active
            }
            color="primary"
          />
        </div>
      )}
    </Paper>
  )
}

export default NewRecurrentConceptsContent
