import { useContext } from 'react'
import { useFormikContext } from 'formik'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import { useSelectPlan } from '../helpers'
import { NewPricingContext } from '../Index'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '32px 1fr 32px',
    columnGap: theme.spacing(1),
  },
  textfield: {
    width: 60,
    height: 40,
    borderRadius: 0,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  button: {
    backgroundColor: 'transparent',
  },
}))

const WorkersCounter = () => {
  const classes = useStyles()
  const { subscription } = useContext(NewPricingContext)
  const { values, errors } = useFormikContext()
  const { onChangeWorkersNumber, firstInteraction } = useSelectPlan()
  const isClaraPayment =
    subscription?.data?.payment_category === 'clara_payment'
  const disableDecreaseButton =
    isClaraPayment && Number(values?.workers_number) === 20

  const handleIncrease = () => {
    const value = Number(values?.workers_number || 0) + 1
    onChangeWorkersNumber(value)
  }

  const handleDecrease = () => {
    const value = Number(values?.workers_number || 0) - 1
    onChangeWorkersNumber(value > 0 ? value : 1)
  }

  return (
    <div className={classes.container}>
      <IconButton
        aria-label="decrease"
        color="primary"
        size="small"
        className={classes.button}
        onClick={handleDecrease}
        disabled={disableDecreaseButton}
      >
        <RemoveCircleIcon />
      </IconButton>
      <OutlinedInput
        name="workers_number"
        variant="number"
        value={values?.workers_number || ''}
        className={classes.textfield}
        onChange={onChangeWorkersNumber}
        inputProps={{
          type: 'number',
        }}
        error={errors?.workers_number && firstInteraction}
      />
      <IconButton
        aria-label="increase"
        color="primary"
        size="small"
        className={classes.button}
        onClick={handleIncrease}
      >
        <AddCircleIcon />
      </IconButton>
    </div>
  )
}

export default WorkersCounter
