import Box from '@material-ui/core/Box'
import AddButton from 'components/UI/Button/AddButton'

export default function BtnAddNovelty({
  onAddNovelty,
  noveltyType,
  noveltyCode,
}) {
  let noveltyName

  switch (noveltyType) {
    case 'holidays':
      noveltyName = 'Vacaciones'
      break
    case 'incapacities':
      noveltyName = 'Incapacidad'
      break
    default:
      noveltyName = 'Licencia'
  }

  return (
    <Box display="flex" justifyContent="center">
      <AddButton
        onClick={() => onAddNovelty(noveltyType, noveltyCode)}
        size="small"
        style={{ float: 'right' }}
        data-cy={`add-${noveltyCode}-item`}
      >
        {noveltyName}
      </AddButton>
    </Box>
  )
}
