import { useContext } from 'react'

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import DeductionsModal from '../Modals/Deductions/Deductions'
import useDeductionsModal from '../Modals/Deductions/useDeductionsModal'
import { PeriodContext } from '../../Payroll'

const CellDeductions = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const { showModal, handleCloseModal, handleOpenModal } = useDeductionsModal()

  return (
    <>
      {getPayrollConceptItemCell({
        buttonId: `deductions_${payroll.tableData.id}`,
        payrollConceptValue: payroll.deductions,
        text: 'Deducciones',
        icon: <RemoveCircleOutlineIcon />,
        onClick: handleOpenModal,
        editable: period.editable && !payroll.locked,
      })}
      {showModal ? (
        <DeductionsModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          payroll={payroll}
        />
      ) : null}
    </>
  )
}

export default CellDeductions
