import { forwardRef, useEffect, useRef } from 'react'

import Checkbox from '@material-ui/core/Checkbox'

const RowSelectionCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  )
})

export default RowSelectionCheckbox
