import { useQueries } from 'react-query'
import {
  getPlans,
  getSubscription,
} from 'services/subscription/subscriptionService'

const useSubscriptionPlans = ({
  plansQueryOptions = {},
  subscriptionQueryOptions = {},
} = {}) => {
  const plansQueryConfig = {
    queryKey: 'getPlans',
    queryFn: getPlans,
    ...plansQueryOptions,
  }
  const subscriptionQueryConfig = {
    queryKey: 'getSubscription',
    queryFn: getSubscription,
    ...subscriptionQueryOptions,
  }
  const [plansQuery, subscriptionQuery] = useQueries([
    plansQueryConfig,
    subscriptionQueryConfig,
  ])

  return {
    plansQuery,
    subscriptionQuery,
  }
}

export default useSubscriptionPlans
