import { useContext, useState } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

import Collapse from '@material-ui/core/Collapse'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { isArusDist } from 'utils/auth'

import { getAlphaHexValue } from 'styles/colors'
import { isPathActive } from './MenuListItem'
import { SidebarContext } from '../Sidebar'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: theme.palette.common.white,
    borderLeft: `4px solid transparent`,
    '& + &': {
      marginTop: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: isArusDist()
        ? `${theme.palette.grey[400]}${getAlphaHexValue(56)}`
        : `${theme.palette.common.white}${getAlphaHexValue(40)}`,
      borderLeftColor: theme.palette.common.white,
    },
    height: 48,
  },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  textTypography: {
    ...theme.typography.C6,
    color: 'inherit',
  },
  active: {
    color: isArusDist() ? theme.palette.grey[500] : theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(15)}`,
    borderLeftColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(15)}`,
    },
  },
  disabled: {
    '&$disabled': {
      opacity: 1,
    },
  },
  arrowIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  arrowIconExpanded: {
    transform: 'rotate(180deg)',
  },
  subItem: {
    paddingLeft: theme.spacing(8.5),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  subItemText: {
    flex: 'none',
  },
  activeItem: {
    fontWeight: 'bold',
  },
}))

const ExpandableListItem = ({
  Icon,
  text,
  hidden,
  options = [],
  pathsToHighlight = [],
  dataCy,
}) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { expanded: sidebarExpanded, mobileOpen } = useContext(SidebarContext)
  const [expanded, setExpanded] = useState(false)
  let active = false

  pathsToHighlight.some((pathItm) => {
    active = isPathActive(pathItm, pathname, false)
    if (active) return true
    return false
  })

  const onExpand = () => setExpanded((prevValue) => !prevValue)

  if (hidden) {
    return null
  }

  return (
    <>
      <MenuItem
        component="button"
        onClick={onExpand}
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
        disableGutters
        data-cy={dataCy}
      >
        <ListItemIcon className={clsx(classes.icon)}>
          <Icon fontSize="default" />
        </ListItemIcon>
        <ListItemText disableTypography className={classes.subItemText}>
          <Typography variant="inherit" className={classes.textTypography}>
            {text}
          </Typography>
        </ListItemText>
        <ListItemIcon className={clsx(classes.icon)}>
          <KeyboardArrowDownIcon
            className={clsx(classes.arrowIcon, {
              [classes.arrowIconExpanded]: expanded,
            })}
          />
        </ListItemIcon>
      </MenuItem>
      {options.length > 0 ? (
        <Collapse
          in={(expanded && sidebarExpanded) || (expanded && mobileOpen)}
        >
          {options.map((option) => {
            const isItemActive = active && pathname === option.path

            if (option.hidden) {
              return null
            }

            return (
              <MenuItem
                key={option.key}
                component={Link}
                to={option.path}
                className={classes.subItem}
                onClick={option.onClick}
              >
                <ListItemText disableTypography>
                  <Typography
                    variant="inherit"
                    className={clsx(classes.textTypography, {
                      [classes.activeItem]: isItemActive,
                    })}
                  >
                    {option.text}
                  </Typography>
                </ListItemText>
              </MenuItem>
            )
          })}
        </Collapse>
      ) : null}
    </>
  )
}

export default ExpandableListItem
