import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { setCompanyId } from 'utils/company'
import { getDirtyValues, yupLocaleES } from 'utils/form'
import { removeSuffix } from 'utils/general'
import useCompanyService from 'utils/hooks/organizer/company'
import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { billingFrencuencyOptions } from 'utils/payment'

import * as routes from 'config/routes'

import { bankTransferInitialValues } from '../helpers'

yup.setLocale(yupLocaleES)

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
}))

const validationSchema = yup.object({
  payment_date: yup.date().nullable().required(),
  payment_type: yup.string().required(),
  value: yup.number().required(),
})

const OrganizerCompanyPaymentBankTransferForm = ({ companyId }) => {
  const classes = useStyles()
  const history = useHistory()
  const { handleError } = useErrorHandler()
  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: ['getCompanyFromOrganizer', companyId],
      companyId,
    },
  })
  const isPerWorkerPlan =
    removeSuffix(companyQuery.data?.plan_category, -8) === 'per_worker_plan'
  const {
    companyPaymentQuery,
    companyPaymentMutation,
  } = useCompanyPaymentService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
      companyId,
    },
  })
  const plans = (companyPaymentQuery.data?.plans || []).map((plan) => {
    return {
      value: plan.id,
      label: isPerWorkerPlan ? plan.name : plan.name.split(' ')[2],
    }
  })

  const onSubmit = (values, form) => {
    const dirtyValues = getDirtyValues({}, values)

    companyPaymentMutation.mutate(
      {
        mutationMethod: 'POST',
        companyId,
        payment: dirtyValues,
      },
      {
        onSuccess: () => {
          setCompanyId(companyId)
          history.push(routes.SUBSCRIPTION_STATUS())
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={bankTransferInitialValues}
    >
      {(form) => {
        const { handleSubmit } = form

        return (
          <Form className={classes.form}>
            <FormField
              name="payment_date"
              label="Fecha del Pago"
              variant="datepicker"
            />
            <FormField
              name="value"
              label="Valor del Pago"
              variant="currency"
              align="left"
            />
            <div className={classes.content}>
              <FormField
                name="plan_id"
                label="Plan"
                variant="select"
                disabled={plans.length === 0}
                options={plans}
              />

              <FormField
                name="paid_extra_workers"
                label={`Número de personas ${isPerWorkerPlan ? '' : 'extra'}`}
                type="number"
                align="left"
                autoComplete="off"
              />
            </div>
            <FormField
              name="payment_type"
              label="Frecuencia"
              variant="select"
              options={billingFrencuencyOptions}
            />

            {/* TODO: Regex validation to disallow all characters */}
            <FormField name="invoice_id" label="ID Factura" align="left" />

            <div className={classes.submitButton}>
              <Button
                color="primary"
                loading={companyPaymentMutation.isLoading}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrganizerCompanyPaymentBankTransferForm
