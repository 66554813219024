import { Button, Link, Paper, Typography, makeStyles } from '@material-ui/core'

import Page from 'components/UI/Page/Page'

import NumberCard from './NumberCard'
import SummaryCard from './SummaryCard'
import { featuresData, summaryData } from './helpers'

const useStyles = makeStyles((theme) => ({
  firstSection: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxWidth: 640,
    minHeight: 320,
    overflow: 'hidden',
    borderRadius: 16,
    '& > iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  content: {
    paddingTop: theme.spacing(10),
  },
  callToAction: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.white,
  },
  secondSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(15),
  },
  featuresContainer: {
    display: 'flex',
    gap: theme.spacing(10),
    width: '100%',
    maxWidth: 1090,
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(5),
    },
  },
  summaryContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(5),
    },
  },
}))

const PartnerLanding = () => {
  const classes = useStyles()

  return (
    <Page documentTitle="Partners" contentClassName={classes.content}>
      <section className={classes.firstSection}>
        <div>
          <Typography variant="h2" gutterBottom>
            Llevamos tu labor contable a otro nivel
          </Typography>
          <Typography variant="h6">
            En 10 minutos nos estaremos contactando contigo.
          </Typography>
          <Button
            className={classes.callToAction}
            component={Link}
            href="https://nominapp.com/partners"
            target="_blank"
            underline="none"
          >
            Saber más acerca del programa
          </Button>
        </div>
        <Paper className={classes.videoContainer}>
          <iframe
            src="https://www.youtube.com/embed/yT6mqIx8Ago"
            title="Video sé un Partner Nominapp"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Paper>
      </section>
      <section className={classes.secondSection}>
        <Typography variant="h3" align="center">
          ¿Por qué ser un Partner Nominapp?
        </Typography>
        <div className={classes.featuresContainer}>
          {featuresData.map((feature) => (
            <NumberCard key={feature.id} {...feature} />
          ))}
        </div>
      </section>
      <section className={classes.secondSection}>
        <Typography variant="h3" align="center" gutterBottom>
          Somos una plataforma única para gestionar tus clientes
        </Typography>
        <Typography variant="body1">
          Con Partners Nominapp tú y tus clientes automatizan la nómina, la
          integran a +50 software contables, acceden a beneficios que se ajustan
          a sus necesidades, tienen acompañamiento de expertos 100%
          personalizado.
        </Typography>
        <div className={classes.summaryContainer}>
          {summaryData.map((summaryItem) => (
            <SummaryCard key={summaryItem.id} {...summaryItem} />
          ))}
        </div>
      </section>
    </Page>
  )
}

export default PartnerLanding
