export default {
  payment_preferences_notification:
    'La información se agregó a la cola de actualización, en unos minutos el cambio se reflejará en el periodo actual de tu empresa.',
  account_number:
    'Es necesario para generar el archivo con el cual vas a pagar en la sucursal virtual de tu banco una vez hayas liquidado tu nómina. No realizaremos ninguna transacción a este número de cuenta.',
  constitution_date:
    'La fecha de inicio se usará para calcular los beneficios de la Ley 1429 o Ley 590 si aplican',
  law_1429:
    'Si eres beneficiario de la Ley 1429, la liquidación de los valores en tu planilla podrá cambiar según el año en el que se encuentre tú empresa, por lo cual es fundamental esta información. Puedes preguntarle a tú contador o a nuestro equipo de soporte para saber si aplicas.',
  law_590:
    'Si eres beneficiario de la Ley 590, la liquidación de los valores de tu parafiscales, cambiará dependiendo del año en el año de beneficio que se encuentre tu empresa. Puedes preguntarle a tú contador o a nuestro equipo de soporte para saber si aplicas.',
  law_1607:
    'Si eres beneficiario de la Ley 1607, eres exonerado del pago de parafiscales y salud, por lo cual es fundamental saber si aplicas. Puedes preguntarle a tú contador o a nuestro equipo de soporte para saber si aplicas.',
  decree_558:
    'Si quieres acogerte al beneficio  del Decreto 558 de 2020, que permite durante los meses de Abril y Mayo del 2020 tener una tarifa del 3% para pensión.',
}
