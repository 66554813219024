import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  horizontal: {
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '40% 1fr',
    },
  },
  vertical: {
    gridTemplateColumns: '1fr',
  },
  labelVertical: {
    gridRowStart: 2,
  },
  value: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
  },
  valuePending: {
    color: theme.palette.primary.main,
  },
}))

const DataField = ({
  label,
  value,
  direction = 'horizontal',
  tooltipContent,
  styles = {},
}) => {
  const classes = useStyles()
  let textValue
  const valueType = typeof value

  if (valueType === 'object' && value !== null) {
    textValue = value.name || value.value
  } else if (valueType === 'boolean') {
    textValue = value ? 'Sí' : 'No'
  } else {
    textValue = value
  }

  const isValueDefined = textValue != null

  return (
    <div
      className={clsx(classes.root, {
        [classes.horizontal]: direction === 'horizontal',
        [classes.vertical]: direction === 'vertical',
      })}
      style={styles.container}
    >
      <Typography
        className={clsx({
          [classes.label]: direction === 'horizontal',
          [classes.labelVertical]: direction === 'vertical',
        })}
        style={styles.label}
      >
        {label}
      </Typography>
      <div className={classes.value} style={styles.value}>
        <Typography
          variant={direction === 'horizontal' ? 'subtitle1' : 'h5'}
          className={clsx({ [classes.valuePending]: !isValueDefined })}
        >
          {isValueDefined ? textValue : 'Pendiente'}
        </Typography>
        {tooltipContent && (
          <TooltipInfoIcon title={tooltipContent} interactive />
        )}
      </div>
    </div>
  )
}

export default DataField
