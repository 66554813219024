import { useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import Loading from 'components/UI/Loading/Loading'

import auth from 'utils/auth'
import useNotifications from 'utils/hooks/useNotifications'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useDistributorSessionService from 'utils/hooks/auth/distributorService'

import messages from 'messages/notification'

import { getSignUpRedirectPath } from '../helpers'

const IntegrationLogin = () => {
  const { showErrorMessage, showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const location = useLocation()
  const history = useHistory()
  const queryData = queryString.parse(location.search)
  const { distributorSessionMutation } = useDistributorSessionService()

  useEffect(() => {
    distributorSessionMutation.mutate(
      {
        mutationMethod: 'POST',
        token: queryData.token,
      },
      {
        onSuccess: ({ data }) => {
          auth.logIn(data)
          showSuccessMessage(messages.LOGIN_OK)
          history.push(
            data.sign_up
              ? getSignUpRedirectPath()
              : { pathname: '/', state: { fromLogin: true } }
          )
        },
        onError: (error) => {
          showErrorMessage(error.errors && error.errors[0].message)
          handleError(error)
        },
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (auth.isAuthenticated()) return <Redirect to="/" />

  return <Loading message="Estamos procesando la información..." />
}

export default IntegrationLogin
