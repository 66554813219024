import { useState } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import NumberFormat from 'react-number-format'
import clsx from 'clsx'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AddIcon from '@material-ui/icons/Add'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import { DatePicker } from '@material-ui/pickers'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import {
  generateBasicNumberFormatter,
  generateBasicNumberParser,
} from 'utils/format'
import { wait } from 'utils/general'

const useStyles = makeStyles((theme) => ({
  switchControl: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  labelContainer: {
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
  labelOptional: { alignSelf: 'flex-end', marginLeft: theme.spacing(0.5) },
  labelTooltip: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    cursor: 'help',
  },
  switchLabel: { alignSelf: 'center', marginBottom: 0 },
  formLabel: { alignSelf: 'flex-end' },
  checkboxButton: { alignSelf: 'start' },
  alignRight: { textAlign: 'right' },
  adornedEnd: {
    paddingRight: 0,
  },
  tooltipIcon: {
    marginBottom: theme.spacing(0.5),
  },
}))

const CustomField = ({
  label,
  disabled,
  component = 'text',
  tooltipContent,
  tooltipTextContent,
  optional,
  additionalLabelComponent,
  stretchRightButton,
  // 'select', 'checkbox-group', radio-group' and 'autocomplete' components
  options = [],
  // 'custom-format' component
  formatter = generateBasicNumberFormatter(),
  parser = generateBasicNumberParser(),
  align = 'right', //just for type='number' text component and currency component
  row = false, //just for type='checkbox-group' component
  // custom input render
  render,
  margin,
  validate, //destructured from props to prevent it from passing to other elements except than useField
  // specific variant props
  datepickerProps = {},
  ...props
}) => {
  const [field, meta, helpers] = useField({ ...props, validate })
  const form = useFormikContext()
  const [focused, setFocused] = useState()
  const classes = useStyles()

  const { name, value, onBlur } = field
  const { touched, error } = meta
  const { setValue, setTouched } = helpers
  const { submitCount } = form

  const id = `${name}_id`
  const showError = (touched || submitCount > 0) && !!error

  const renderInputText = () => {
    return (
      <OutlinedInput
        id={id}
        {...field}
        value={value ?? ''}
        inputProps={{
          className: clsx(props.className, {
            [classes.alignRight]: props.type === 'number' && align === 'right',
          }),
        }}
        classes={{
          adornedEnd: stretchRightButton && classes.adornedEnd,
        }}
        {...props}
      />
    )
  }

  const renderInputCustomFormat = () => {
    let valueFormatted = formatter(value)

    return (
      <OutlinedInput
        id={id}
        {...field}
        value={valueFormatted}
        onChange={(e) => {
          const parsed = parser(e.target.value)

          setValue(parsed)
        }}
        {...props}
      />
    )
  }

  const renderInputNumberFormat = () => {
    return (
      <NumberFormat
        id={id}
        name={name}
        value={value}
        onBlur={onBlur}
        //DO NOT add onChange from 'field'
        onValueChange={(values) => {
          // const { formattedValue, value, floatValue } = values

          setValue(values.value)
        }}
        thousandSeparator="."
        decimalSeparator=","
        customInput={OutlinedInput}
        allowNegative={false}
        {...props}
      />
    )
  }

  const renderInputCurrency = () => {
    return (
      <NumberFormat
        id={id}
        name={name}
        value={value}
        onBlur={onBlur}
        placeholder="0"
        //DO NOT add onChange from 'field'
        onValueChange={(values) => {
          // const { formattedValue, value, floatValue } = values
          setValue(values.floatValue)
        }}
        onFocus={() => {
          if (value === 0 && !touched) {
            setValue('')
          }
        }}
        customInput={OutlinedInput}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={0}
        allowNegative={false}
        inputProps={{
          className: clsx(props.className, {
            [classes.alignRight]: align === 'right',
          }),
        }}
        {...props}
      />
    )
  }

  const renderInputSelect = () => {
    return (
      <Select {...field} {...props} value={value ?? ''} id={id} displayEmpty>
        <MenuItem disabled value="">
          <em>{props.placeholder || 'Selecciona...'}</em>
        </MenuItem>
        {options.map((opt) => {
          const { label, value } = opt

          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  const renderCheckbox = () => {
    return (
      <Checkbox
        {...field}
        size="medium"
        className={classes.checkboxButton}
        checkedIcon={<CheckBoxOutlinedIcon style={{ fontSize: 30 }} />}
        icon={<CheckBoxOutlineBlankOutlinedIcon style={{ fontSize: 30 }} />}
        {...props}
      />
    )
  }

  const renderCheckboxGroup = () => {
    return (
      <FormGroup row={row}>
        {options.map((opt) => (
          <FormControlLabel
            key={opt.value}
            control={
              <Checkbox
                checked={value.find((val) => val === opt.value) !== undefined}
                onChange={({ target: { name, checked } }) => {
                  //Set makes it easier
                  const valueSet = new Set(value)

                  if (checked) {
                    valueSet.add(name)
                  } else {
                    valueSet.delete(name)
                  }

                  setValue([...valueSet])
                }}
                name={opt.value}
              />
            }
            label={opt.label}
          />
        ))}
      </FormGroup>
    )
  }

  const renderRadioGroup = () => {
    return (
      <RadioGroup
        {...field}
        value={value ?? false}
        onChange={({ target: { value } }) => {
          const newValue =
            value === 'true' ? true : value === 'false' ? false : value

          setValue(newValue)
          setTouched(name, false)
        }}
        {...props}
      >
        {options.map((opt) => {
          const { label, value } = opt

          return (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          )
        })}
      </RadioGroup>
    )
  }

  const renderDatePicker = () => {
    const variantDialogProps = {}

    //to prevent warnings when using cancelLabel and okLabel props when is not 'dialog' variant
    if (!props.variant || props.variant === 'dialog') {
      variantDialogProps.cancelLabel = 'Cancelar'
      variantDialogProps.okLabel = 'Aceptar'
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          id={id}
          variant="dialog"
          {...field}
          onBlur={undefined} //to prevent onBlur from field object, because it's causing unexpected behavior when setting the touched state
          onChange={(date) => setValue(date)}
          {...variantDialogProps}
          {...datepickerProps}
          {...props}
          value={value || null} //to prevent displaying today date when not initial value
          disabled={disabled}
          format="dd/MM/yyyy"
          autoOk
          //focused onOpen/onClose to highlight FormLabel
          onOpen={() => setFocused(true)}
          onClose={async () => {
            setFocused(false)
            //the onClose is being executed before the onChange, causing the validation message be shown even aftet set a valid value
            await wait(100) //this delay prevent that
            setTouched(name)
          }}
          focused={focused}
          error={showError}
          style={{ marginTop: 0, marginBottom: 0, ...props.style }}
        />
      </MuiPickersUtilsProvider>
    )
  }

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        id={id}
        options={options}
        value={value}
        onChange={(_e, newValue) => {
          setValue(newValue)
        }}
        onBlur={() => setTouched(name)}
        renderInput={({ InputProps, InputLabelProps, ...params }) => {
          return (
            <OutlinedInput
              {...params}
              {...InputProps}
              {...InputLabelProps}
              // id={id}
              placeholder={props.placeholder}
            />
          )
        }}
        getOptionLabel={({ name }) => name}
        renderOption={({ id, name }) => (
          <>
            {id === 'create' && <AddIcon style={{ fontSize: 18 }} />}
            {name}
          </>
        )}
        getOptionSelected={(opt, value) => opt.id === value.id}
        openOnFocus
        autoHighlight
        {...props}
      />
    )
  }

  const renderTextArea = () => {
    return (
      <OutlinedInput
        id={id}
        {...field}
        {...props}
        multiline
        value={value ?? ''}
        style={{ height: 'auto', ...props.style }}
      />
    )
  }

  const renderSwitch = () => {
    return (
      <Field
        id={id}
        component={Switch}
        name={name}
        checked={value}
        onChange={async () => {
          form.setFieldValue(name, !value)
        }}
      />
    )
  }

  const renderInput = () => {
    if (render) return render(field, meta, helpers, form)

    if (component === 'text') return renderInputText()
    if (component === 'currency') return renderInputCurrency()
    if (component === 'custom-format') return renderInputCustomFormat()
    if (component === 'number-format') return renderInputNumberFormat()
    if (component === 'select') return renderInputSelect()
    if (component === 'checkbox') return renderCheckbox()
    if (component === 'checkbox-group') return renderCheckboxGroup()
    if (component === 'radio-group') return renderRadioGroup()
    if (component === 'datepicker') return renderDatePicker()
    if (component === 'autocomplete') return renderAutocomplete()
    if (component === 'textarea') return renderTextArea()
    if (component === 'switch') return renderSwitch()
  }

  return (
    <FormControl
      error={showError}
      disabled={disabled}
      margin={margin}
      className={component === 'switch' && classes.switchControl}
    >
      {label && (
        <div className={classes.labelContainer}>
          <FormLabel
            htmlFor={id}
            focused={focused}
            className={
              component === 'switch' ? classes.switchLabel : classes.formLabel
            }
          >
            {label}
            {optional && (
              <Typography variant="caption" className={classes.labelOptional}>
                {' - Opcional'}
              </Typography>
            )}
            {tooltipTextContent && (
              <Tooltip title={tooltipTextContent} placement="right">
                <Typography variant="caption" className={classes.labelTooltip}>
                  ¿Qué es esto?
                </Typography>
              </Tooltip>
            )}
          </FormLabel>
          {tooltipContent && (
            <TooltipInfoIcon
              title={tooltipContent}
              interactive={typeof tooltipContent !== 'string'}
              tooltipProps={{ placement: 'right' }}
              iconProps={{
                className: classes.tooltipIcon,
              }}
            />
          )}
          {additionalLabelComponent}
        </div>
      )}
      {renderInput()}
      {showError && (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomField
