import { Switch, Route } from 'react-router-dom'

import LoginView from 'views/Auth/Login'
import SingupView from 'views/Auth/Signup'
import LogoutView from 'views/Auth/Logout'
import PasswordResetView from 'views/Auth/PasswordReset'
import PasswordRecoveryView from 'views/Auth/PasswordRecovery'
import DistributorLoginView from 'views/Auth/DistributorLogin'

import * as routes from 'config/routes'

import App from './App'

const AppRoot = () => {
  return (
    <Switch>
      {/* Login */}
      <Route
        path={[routes.LOGIN, routes.LOGIN_2, routes.LOGIN_OAUTH]}
        component={LoginView}
      />
      {/* Integration Login */}
      <Route path={routes.DISTRIBUTOR_LOGIN} component={DistributorLoginView} />
      {/* Password Recovery */}
      <Route
        path={[routes.SIGN_UP, routes.SIGN_UP_2, routes.SIGN_UP_3]}
        component={SingupView}
      />
      {/* Logout */}
      <Route path={routes.PASSWORD_RECOVERY} component={PasswordRecoveryView} />
      {/* SingUp */}
      <Route path={routes.PASSWORD_RESET} component={PasswordResetView} />
      {/* SingUp */}
      <Route path={routes.LOGOUT} component={LogoutView} />
      {/* App */}
      <App />
    </Switch>
  )
}

export default AppRoot
