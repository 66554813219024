import addDays from 'date-fns/addDays'
import parseISO from 'date-fns/parseISO'
import { useQuery, QueryClient, useMutation } from 'react-query'

import {
  createContract,
  getAllContracts,
  getContractById,
  getCurrentContract,
  updateContract,
} from 'services/worker/contractService'

import useErrorHandler from '../useErrorHandler'

function formatContract(contract) {
  const formattedContract = contract
  formattedContract.initial_day = parseISO(contract.initial_day)
  if (contract.end_day) formattedContract.end_day = parseISO(contract.end_day)
  formattedContract.period_end_day = parseISO(contract.period_end_day)

  // TODO: API should do this by default
  if (!contract.health_provider.id) formattedContract.health_provider = null
  if (!contract.pension_provider.id) formattedContract.pension_provider = null
  if (!contract.severance_provider.id)
    formattedContract.severance_provider = null

  return formattedContract
}

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId, contractId } = serviceParams

  if (queryKey === 'allContracts') return getAllContracts(workerId)

  if (queryKey === 'contractById') return getContractById(contractId)

  if (queryKey === 'currentContract') return getCurrentContract(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, contract } = mutationData

  if (mutationMethod === 'POST') return createContract(workerId, contract)

  if (mutationMethod === 'PATCH') return updateContract(contract)

  return null
}

const useContractsService = ({
  serviceParams = { queryKey: 'allContracts' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const queryClient = new QueryClient()
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restContractsResponse } = useQuery(
    [serviceParams.queryKey, serviceParams.workerId, serviceParams.contractId],
    () => getQueryFunction(serviceParams),
    {
      onSuccess: (contractData) => {
        if (serviceParams.queryKey !== 'allContracts') {
          // Format data to allContracts
          const contractDataCopy = contractData

          contractDataCopy.data = formatContract(contractData.data)
        } else {
          // Format data to currentContract and contractById
          contractData.data.forEach(formatContract)

          for (let i = 0; i < contractData.data.length; i += 1) {
            const contract = contractData.data[i]

            contract.number = i + 1

            if (contractData.data[i + 1])
              contract.prevEndDay = addDays(contractData.data[i + 1].end_day, 1)
          }
        }

        queryClient.setQueryData(
          [
            serviceParams.queryKey,
            serviceParams.workerId,
            serviceParams.contractId,
          ],
          contractData
        )
      },
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const contractsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    contractsQuery: {
      data,
      ...restContractsResponse,
    },
    contractsMutation,
  }
}

export default useContractsService
