import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'

import AnniversariesItem from './AnniversariesItem'

const DashboardCommingSoonAnniversaries = ({ anniversaries }) => {
  return (
    <Box p={4} marginBottom={2} component={Paper}>
      <Typography variant="h6">Aniversarios</Typography>
      <Typography>
        Las siguientes personas cumplen años trabajando en tu compañía:
      </Typography>
      <List>
        {anniversaries.map((anniversary, index) => {
          const key = `anniversary_${anniversary.worker_name}`
          return (
            <AnniversariesItem
              key={key}
              anniversary={anniversary}
              index={index}
            />
          )
        })}
      </List>
    </Box>
  )
}

export default DashboardCommingSoonAnniversaries
