import http, { getApiURL } from '../httpService'

export const getAllAffiliations = ({ searchParams = {} }) => {
  const url = getApiURL({
    pathname: '/affiliations',
    searchParams,
  })

  return http.getV2(url)
}

export const getAffiliationById = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/affiliation`,
  })

  return http.getV2(url)
}

export const createAffiliation = (workerId, affiliationData) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/affiliation`,
  })

  return http.putV2(url, affiliationData)
}

export const updateBeneficiary = ({ workerId, beneficiaryData }) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/beneficiary`,
  })

  return http.putV2(url, beneficiaryData)
}

export default {
  getAllAffiliations,
  getAffiliationById,
  createAffiliation,
  updateBeneficiary,
}
