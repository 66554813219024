import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import HeaderImage from 'components/UI/Modal/HeaderImage'
import { useUser } from 'components/App/UserContext/useUser'

import eugenioSuccess from 'assets/images/avatars/eugenio/eugenio_success.svg'

import SOCIAL_BENEFIT_INFORMATION from './helpers'

const useStyles = makeStyles((theme) => ({
  modalMain: {
    '& > h6': {
      textAlign: 'center',
      width: '265px !important',
      margin: 'auto',
    },
    '& > ol': {
      paddingInlineStart: theme.spacing(2),
    },
  },
}))

export const SocialBenefitsResponse = ({ category, label }) => {
  const classes = useStyles()

  const socialBenefitItems = SOCIAL_BENEFIT_INFORMATION[category]

  return (
    <section className={classes.modalMain}>
      <Typography variant="subtitle1">
        ¡Genial! Acabas de liquidar {label}
      </Typography>
      <ol>
        {socialBenefitItems?.map((item, index) => {
          const key = `social_benefit_item_${index}`
          return <li key={key}>{item}</li>
        })}
      </ol>
    </section>
  )
}

export const Header = () => {
  const { user } = useUser()

  const userName = user?.name || ''

  const title = `!Hola ${userName}!`
  return <HeaderImage image={eugenioSuccess} title={title} />
}
