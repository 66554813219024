import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useRef } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import Button from 'components/UI/Button/Button'

const useStyles = makeStyles((theme) => ({
  dropzone: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  dropzoneInline: {
    color: '#6f6e6e',
    fontSize: '0.8rem',
    padding: theme.spacing(0.75, 0.5),
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderWidth: 1.5,
    borderRadius: 16,
    '& p': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  dropzoneNormal: {
    height: 250,
    margin: theme.spacing(2, 0),
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 16,
    fontSize: '1.1rem',
    '& p': {
      marginBottom: theme.spacing(1.5),
    },
  },
  fileInput: {
    display: 'none',
  },
  icon: {
    fontSize: 48,
    color: theme.palette.primary.main,
  },
}))

const Dropzone = ({
  value = null,
  type = 'normal',
  handleAdd,
  disabled = false,
  // This prop controls the selection when using the file selector
  accept,
  // This prop controls the selection when dragging the file
  fileType,
  buttonSize,
}) => {
  const classes = useStyles()
  const fileInputRef = useRef(null)

  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onFileAdded = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.target.files[0]
    handleAdd(file)
    fileInputRef.current.value = ''
  }

  const openFileDialog = () => {
    if (disabled) return

    fileInputRef.current.click()
  }

  const onDrop = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.dataTransfer.files[0]
    if (fileType) {
      if (file.type === fileType) {
        handleAdd(file)
      }
    } else {
      handleAdd(file)
    }
  }

  const onDragOver = (event) => stopEvent(event)

  return (
    <div
      role="none"
      onDrop={onDrop}
      onDragOver={onDragOver}
      onClick={openFileDialog}
      className={clsx(classes.dropzone, {
        [classes.dropzoneInline]: type === 'inline',
        [classes.dropzoneNormal]: type === 'normal',
      })}
    >
      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={onFileAdded}
        className={classes.fileInput}
        disabled={disabled}
        data-cy="file-input"
      />

      {type === 'normal' && (
        <CloudUploadOutlinedIcon className={classes.icon} />
      )}

      <Typography variant="body2">
        {value || 'Selecciona o arrastra tu archivo acá...'}
      </Typography>

      <Button color="primary" disabled={disabled} size={buttonSize}>
        {value ? 'Actualizar' : 'Subir'} archivo
      </Button>
    </div>
  )
}

Dropzone.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(['inline', 'normal']),
  accept: PropTypes.string,
  handleAdd: PropTypes.func,
  disabled: PropTypes.bool,
  fileType: PropTypes.string,
  buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),
}

Dropzone.defaultProps = {
  value: null,
  type: 'normal',
  accept: undefined,
  handleAdd: undefined,
  disabled: false,
  fileType: undefined,
  buttonSize: 'medium',
}

export default Dropzone
