import { Link as RouterLink } from 'react-router-dom'
import MuiLink from '@material-ui/core/Link'

const Link = ({ children, ...props }) => {
  return (
    <MuiLink component={RouterLink} {...props}>
      {children}
    </MuiLink>
  )
}

export default Link
