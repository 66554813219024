import { useContext } from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined'
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined'

import { useUser } from 'components/App/UserContext/useUser'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 416,
    gap: theme.spacing(2),
  },
  visualInformation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    width: 128,
    height: 128,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 60,
  },
}))

const EmptyStateDocuments = () => {
  const classes = useStyles()
  const { isWorker } = useUser()
  const { openedFolder } = useContext(DocumentsContext)

  const info = {
    worker: {
      icon: <CreateNewFolderOutlined className={classes.icon} />,
      message: `No tienes ${
        openedFolder ? 'ningún archivo' : 'ninguna carpeta'
      } compartid${openedFolder ? 'o' : 'a'}`,
    },
    admin: {
      icon: <DescriptionOutlined className={classes.icon} />,
      message: 'No has agregado archivos',
    },
  }

  return (
    <div className={classes.container}>
      <section className={classes.visualInformation}>
        <div className={classes.logoContainer}>
          {isWorker ? info.worker.icon : info.admin.icon}
        </div>
      </section>
      <Typography variant="h4" align="center">
        {isWorker ? info.worker?.message : info.admin.message}
      </Typography>
    </div>
  )
}

export default EmptyStateDocuments
