import addDays from 'date-fns/addDays'
import addYears from 'date-fns/addYears'
import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import { useContext } from 'react'

import Typography from '@material-ui/core/Typography'

import { PeriodContext } from 'components/Period/Payroll/Payroll'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import useContentStyles from '../common/commonStyles'
import BtnAddNovelty from './BtnAddNovelty'
import DateRangeItem from './DateRangeItem'

export default function DateRangeContent({
  title,
  data,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  noveltyType,
  noveltyCode,
  contentTooltip,
  selectedDays,
  payroll = {},
}) {
  const { items } = data
  const contentClasses = useContentStyles()
  const {
    period: { initial_day: periodInitialDate, end_day: periodEndDate },
  } = useContext(PeriodContext)
  const {
    contract_initial_day: contractInitialDay,
    contract_end_day: contractEndDay,
  } = payroll

  return (
    <div className={contentClasses.noveltiesItemContainer}>
      <Typography>{title}</Typography>
      <div>
        {items.map((novelty, index) => {
          let initialDay = parseISO(novelty.initial_day)
          initialDay = isValid(initialDay) ? initialDay : null

          let endDay = parseISO(novelty.end_day)
          endDay = isValid(endDay) ? endDay : null

          let initialMinDate = periodInitialDate
          let initialMaxDate =
            endDay || noveltyType === 'holidays'
              ? addDays(periodEndDate, 1)
              : periodEndDate

          const endMinDate = initialDay || periodInitialDate
          let endMaxDate = addYears(new Date(), 10)

          const excludedDates = []

          Object.keys(selectedDays).forEach((type) => {
            const novType = selectedDays[type]

            Object.keys(novType).forEach((code) => {
              const novGroup = novType[code]

              novGroup.forEach((novDates, i) => {
                // calculate min and max dates
                novDates.forEach((date) => {
                  // no dates selected
                  if (!initialDay && !endDay) {
                    if (date.getTime() >= initialMaxDate.getTime()) {
                      endMaxDate = date
                    }
                    // when only initialDay is selected
                  } else if (initialDay && !endDay) {
                    if (endMinDate < date && date < endMaxDate)
                      endMaxDate = date
                    // when only endDay is selected
                  } else if (endDay && !initialDay) {
                    if (date > initialMinDate && date < initialMaxDate) {
                      initialMinDate = date
                    }
                  } else if (
                    endDay &&
                    initialDay &&
                    (type !== noveltyType ||
                      code !== noveltyCode ||
                      i !== index)
                  ) {
                    if (date > endMinDate && date < endMaxDate)
                      endMaxDate = date
                  }
                })

                // if not current novelty
                if (
                  type !== noveltyType ||
                  code !== noveltyCode ||
                  i !== index
                ) {
                  excludedDates.push(novDates)
                }
              })
            })
          })

          // Validate min and max date between contract dates
          if (parseISO(contractEndDay) < initialMaxDate) {
            initialMaxDate = parseISO(contractEndDay) - 1
          }
          if (initialMinDate < parseISO(contractInitialDay)) {
            initialMinDate = parseISO(contractInitialDay)
            if (contractEndDay && initialMaxDate > parseISO(contractEndDay)) {
              initialMaxDate = parseISO(contractEndDay)
            }
          }
          if (contractEndDay && endMaxDate > parseISO(contractEndDay)) {
            endMaxDate = parseISO(contractEndDay)
          }

          const isNoveltyFromThisPayroll =
            !novelty.payroll_id || novelty.payroll_id === payroll.id

          return (
            <DateRangeItem
              key={novelty.id || index}
              index={index}
              noveltyType={noveltyType}
              noveltyCode={noveltyCode}
              initialDay={initialDay}
              initialMinDate={initialMinDate}
              initialMaxDate={initialMaxDate}
              endDay={endDay}
              endMinDate={endMinDate}
              endMaxDate={endMaxDate}
              excludedDates={excludedDates}
              onChangeNovelty={onChangeNovelty}
              onDeleteNovelty={onDeleteNovelty}
              isNoveltyFromThisPayroll={isNoveltyFromThisPayroll}
            />
          )
        })}
        {items.length >= 1 &&
          items[items.length - 1].initial_day &&
          items[items.length - 1].end_day && (
            <BtnAddNovelty
              onAddNovelty={onAddNovelty}
              noveltyType={noveltyType}
              noveltyCode={noveltyCode}
            />
          )}
      </div>
      {contentTooltip !== undefined && (
        <TooltipInfoIcon title={contentTooltip} interactive />
      )}
    </div>
  )
}
