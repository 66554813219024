import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import { getCompanyId } from 'utils/company'

import * as routes from 'config/routes'

import generateSimpleMessage from './utils'

export default {
  button_without_payrolls: () => (
    <>
      Oops! Primero debes{' '}
      <TooltipLink to={routes.WORKER_INDEX()} variant="navigation">
        crear personas
      </TooltipLink>
      .
    </>
  ),
  header_payroll_range: () => (
    <>
      Para que tu nómina sea quincenal o mensual, ve a{' '}
      <TooltipLink
        to={{
          pathname: routes.COMPANY_EDIT(getCompanyId()),
          state: { initialStep: 4 },
        }}
        variant="navigation"
      >
        Editar Empresa
      </TooltipLink>
      .
    </>
  ),
  loans: (
    <>
      <TooltipLink href="https://ayuda.nominapp.com/help/como-creo-un-prestamo-para-un-empleado">
        Aquí
      </TooltipLink>{' '}
      podrás ver cómo ingresar un préstamo.
    </>
  ),
  enable_loans: (
    <>
      Si deseas activar o inactivar un préstamo puedes{' '}
      <TooltipLink href="https://ayuda.nominapp.com/help/como-puedo-activar-o-inactivar-un-prestamo-o-una-deduccion-de-un-empleado">
        ver aquí
      </TooltipLink>{' '}
      cómo hacerlo.
    </>
  ),
  deductions: (
    <>
      <TooltipLink href="https://ayuda.nominapp.com/help/como-registrar-una-deduccion-de-salario-recurrente-a-un-empleado">
        Aquí
      </TooltipLink>{' '}
      podrás ver cómo ingresar deducciones recurrentes.
    </>
  ),
  salary_income:
    'Agrega aquí todos los ingresos que sumarán para la base de seguridad social y prestaciones.',
  non_salary_income:
    'Agrega aquí todos los ingresos que No sumarán para la base de seguridad social y prestaciones.',
  early_payment: (
    <>
      Al elegir &quot;Sí&quot; en pago anticipado, no se incluirá esta persona
      en el archivo final de pago en banco. Se generará un archivo para pagarle
      únicamente.{' '}
      <TooltipLink href="https://ayuda.nominapp.com/help/puedo-pagar-vacaciones-de-forma-anticipada">
        Aquí
      </TooltipLink>{' '}
      verás más información
    </>
  ),
  average_salary: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/puedo-editar-el-salario-promedio-como-base-de-vacaciones-incapacidades-o-licencias-de-un-empleado',
    'Sobre este salario se realizará el pago de las vacaciones.'
  ),
  novelties_average_salary: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-el-salario-base-para-liquidar-las-incapacidades-y-licencias',
    'Sobre este salario se realizará el pago de las incapacidades y licencias.'
  ),
  suspention: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  job_abandonment: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  compensated_days: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-vacaciones-compensadas-de-mis-empleados'
  ),
  enjoyed_days: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-la-liquidacion-de-vacaciones-disfrutadas-por-un-empleado'
  ),
  general_incapacity: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-paga-nominapp-las-incapacidades-a-mis-empleados'
  ),
  maternity_leave: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-licencias-de-maternidad'
  ),
  paternity_leave: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/hc/es-419/articles/4404386113179-conoce-c%C3%B3mo-calcula-nominapp-las-licencias-de-paternidad'
  ),
  unpaid_leave: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_OK_TEXT: 'Editar trabajadores',
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_TITLE:
    'Completa la información de tus empleados',
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_DESCRIPTION:
    'Nos falta la información de la seguridad social de algunos trabajadores. Debes agregar esta información para generar la planilla.',
}
