import { makeStyles } from '@material-ui/core/styles'
import MuiLink from '@material-ui/core/Link'

import Link from './Link'

const useStyles = makeStyles((theme) => ({
  linkText: {
    color: theme.palette.info.main,
    fontWeight: 'bold',
  },
}))

const TooltipLink = ({
  children,
  variant = 'url',
  to,
  href,
  target = '_blank',
}) => {
  const classes = useStyles()

  if (variant === 'navigation') {
    return (
      <Link className={classes.linkText} to={to}>
        {children}
      </Link>
    )
  }

  return (
    <MuiLink className={classes.linkText} href={href} target={target}>
      {children}
    </MuiLink>
  )
}

export default TooltipLink
