import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'

import useNotifications from 'utils/hooks/useNotifications'

import {
  createComment,
  deleteComment,
  getComments,
  updateComment,
} from 'services/organizer/companyCommentService'

import { columnsData, validationSchema } from './helpers'

const CompanyPaymentsComments = ({ companyId }) => {
  const queryClient = useQueryClient()
  const { showInfoMessage } = useNotifications()
  const queryKey = ['companyComments', companyId]
  const fetchCompanyComments = () => {
    return {
      queryKey,
      queryFunction: () => getComments(companyId),
    }
  }

  const handleAddComment = (value) => {
    return {
      data: {
        value,
        companyId,
      },
      mutationFunction: createComment,
      mutateOptions: {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      },
    }
  }

  const handleUpdateComment = (
    { comment: oldComment },
    { comment: newComment, id: commentId }
  ) => {
    if (oldComment !== newComment && newComment !== '') {
      return {
        data: {
          value: {
            comment: newComment,
          },
          commentId,
          companyId,
        },
        mutationFunction: updateComment,
        mutateOptions: {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey)
            showInfoMessage('El comentario ha sido actualizado.')
          },
        },
      }
    }
    return {}
  }

  const handleDeleteComment = ({ id }) => {
    return {
      data: {
        commentId: id,
        companyId,
      },
      mutationFunction: deleteComment,
      mutateOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
          showInfoMessage('El comentario ha sido eliminado.')
        },
      },
    }
  }

  return (
    <Table
      columns={columnsData}
      data={fetchCompanyComments}
      options={{
        customActionsWidth: 200,
        alignActionsHeader: 'center',
        alignActionsCell: 'center',
        search: false,
        pagination: false,
      }}
      editable={{
        onAddRow: handleAddComment,
        onUpdateRow: handleUpdateComment,
        onDeleteRow: handleDeleteComment,
        enableAddRow: true,
        addRowActionProps: {
          tooltip: 'Añadir nuevo comentario',
        },
        addRowPosition: 'top',
        tableMinWidth: 800,
        validationSchema,
      }}
    />
  )
}

export default CompanyPaymentsComments
