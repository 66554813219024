import { useState } from 'react'

import Button from 'components/UI/Button/Button'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'
import usePartnerPayment from 'utils/hooks/organizer/partner/paymentService'

const PaymentRetry = () => {
  const [enablePaymentRetry, setEnablePaymentRetry] = useState(false)
  const { showSuccessMessage } = useNotifications()
  const { subscriptionQuery } = useSubscriptionService({
    serviceParams: { queryKey: 'getSubscription' },
    queryOptions: {
      onSuccess: ({ data }) => {
        const {
          payment_status: paymentStatus,
          payment_category: paymentCategory,
          status,
        } = data

        if (
          paymentCategory === 'credit_card' &&
          (['active', 'expired', 'active_premium', 'premium_expired'].includes(
            status
          ) ||
            (['premium_due', 'basic', 'free_trial'].includes(status) &&
              paymentStatus === 'pending'))
        ) {
          setEnablePaymentRetry(true)
        }
      },
    },
  })
  const { partnerPaymentMutation } = usePartnerPayment({
    queryOptions: {
      enabled: false,
    },
  })

  const handleRetryPayment = () => {
    partnerPaymentMutation.mutate(
      {
        mutationMethod: 'POST',
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  if (enablePaymentRetry && !subscriptionQuery?.isLoading) {
    return (
      <Button
        onClick={handleRetryPayment}
        loading={partnerPaymentMutation.isLoading}
      >
        Reintentar cobro Tarjeta Crédito
      </Button>
    )
  }

  return null
}

export default PaymentRetry
