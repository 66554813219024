import { createContext } from 'react'
import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import cloneDeep from 'lodash/cloneDeep'

import Typography from '@material-ui/core/Typography'

import Page from 'components/UI/Page/Page'

import useDecree376Service from 'utils/hooks/payroll/decree376Service'

import Header from '../common/Header'
import useSocialBenefitsResponse from '../common/Modal/useSocialBenefitsResponse'
import DataTable from './DataTable/DataTable'
import { validationSchema, getCards, getValuesToSend } from './helpers'
import Review from './Review/Index'

export const Decree376Context = createContext()

const formatDataDisableCheck = (data) => {
  const newData = data
  newData.decree376_payments.forEach((payment, index) => {
    if (payment.payment_options.length === 0) {
      newData.decree376_payments[index].disableCheck = true
    }
  })
  return newData
}

const Decree376Index = ({ match }) => {
  const queryClient = useQueryClient()
  const modalSocialBenefit = useSocialBenefitsResponse()

  const { periodId, decree376Id } = match.params
  const decree376QueryKey = ['getDecree376Payments', periodId, decree376Id]
  const decree376DataCache = queryClient.getQueryData(decree376QueryKey) || {}

  const { decree376Query, decree376Mutation } = useDecree376Service({
    serviceParams: {
      queryKey: decree376QueryKey,
      params: { periodId, decree376Id },
    },
    queryOptions: {
      onSuccess: ({ data: decree376Data }) => {
        if (decree376Data) {
          queryClient.setQueryData(
            decree376QueryKey,
            formatDataDisableCheck(cloneDeep(decree376Data))
          )
        }
      },
    },
  })

  const category = 'decree_376'
  const label = 'los aportes según el decreto 376 de tu empresa'

  const handleSubmit = async (values) => {
    const valuesToSend = getValuesToSend(
      decree376DataCache.decree376_payments,
      values.decree376_payments
    )

    decree376Mutation.mutate(
      {
        mutationMethod: 'PUT',
        params: {
          periodId,
          decree376Id,
          dataToSend: valuesToSend,
        },
      },
      {
        onSuccess: () => {
          modalSocialBenefit.openModal(category, label)
          queryClient.invalidateQueries(decree376QueryKey)
        },
      }
    )
  }

  const reviewItems = getCards(decree376DataCache.decree376_period)

  return decree376DataCache ? (
    <Decree376Context.Provider value={{ periodId, decree376Id }}>
      <Page
        documentTitle="Pago aportes a pensión decreto 376"
        header={<Header label="Pago del decreto 376" hideRange />}
        isLoading={decree376Query.isLoading}
        isLoadingWithModal={decree376Mutation.isLoading}
      >
        <Typography>
          Fuiste beneficiado por el decreto 558 de 2020, debes realizar los
          aportes faltantes a seguridad social de tu empresa y empleados según
          el decreto 376 de 2021. Conoce más información aquí y mira el paso a
          paso para efectuarlo.
        </Typography>
        <Review
          periodRange={decree376DataCache?.decree376_period}
          items={reviewItems}
        />

        <Formik
          initialValues={{
            decree376_payments: decree376DataCache.decree376_payments,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            const { values, handleSubmit: onSubmit } = form

            return (
              <Form>
                <DataTable
                  decreePayments={values.decree376_payments}
                  isSubmitting={decree376Mutation.isLoading}
                  onSubmit={onSubmit}
                />
              </Form>
            )
          }}
        </Formik>
      </Page>
    </Decree376Context.Provider>
  ) : null
}

export default Decree376Index
