import { useFormikContext } from 'formik'

import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Button from 'components/UI/Button/Button'
import DataField from 'components/UI/Data/DataField'

import { formatCurrency } from 'utils/format'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useTerminationService from 'utils/hooks/worker/terminationService'

import terminationService from 'services/worker/terminationService'

import * as routes from 'config/routes'

import messages from 'messages/termination'

import { useTerminationFieldsStyles } from './helpers'

const totalsStyles = {
  value: { textAlign: 'right' },
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(5),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 2',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  label: {
    width: 'calc(50% - 30px) !important',
  },
  value: {
    width: 'calc(50% - 30px) !important',
  },
}))

const WorkerContractTerminationFormSummary = ({
  dirtyTermination,
  handleCancel,
  isEditing,
  isSaving,
}) => {
  const { values, handleSubmit, setFieldTouched } = useFormikContext()
  const classes = useStyles()
  const fieldsClasses = useTerminationFieldsStyles()
  const { enqueueDownload } = useDownloadManager()
  const downloadFile = useEnqueueDownload()
  const { terminationMutation } = useTerminationService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleTerminationPreview = () => {
    const serviceCall = () =>
      terminationService.createPreview(values.contract_id, dirtyTermination, {
        file_format: 'pdf',
      })

    enqueueDownload({
      name: 'previsualización de la liquidación',
      fileCode: 'termination_preview',
      serviceCall,
    })
  }

  const handlePaymentsHistory = () => {
    terminationMutation.mutate(
      {
        mutationMethod: 'GET_PAYMENTS_HISTORY',
        contractId: values?.contract_id,
        date: values?.date,
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'reporte de pagos salariales',
            fileCode: 'salary_payments_file',
            pathname: `contracts/${values.contract_id}/salary_payments_file`,
          })
        },
      }
    )
  }

  const validateBeforeSubmit = () => {
    if (values.cause && values.cause?.length > 0) {
      handleSubmit()
      return
    }

    const causeInput = document.querySelector('[name="cause"]')
    causeInput.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    setFieldTouched('cause', true, true)
  }

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" gutterBottom>
          8. Resumen liquidación
        </Typography>
        <div>
          <DataField
            label="Vacaciones"
            value={formatCurrency(values.holidays_value)}
            tooltipContent={messages.holidays_value}
            styles={totalsStyles}
          />
          <DataField
            label="Cesantías"
            value={formatCurrency(values.severance)}
            tooltipContent={messages.severance}
            styles={totalsStyles}
          />
          {values.pay_previous_severance && (
            <DataField
              label="Cesantías año anterior"
              value={formatCurrency(values.previous_severance)}
              styles={totalsStyles}
            />
          )}
          <DataField
            label="Intereses a las Cesantías"
            value={formatCurrency(values.severance_interests)}
            tooltipContent={messages.severance_interests}
            styles={totalsStyles}
          />
          {values.pay_previous_severance_interests && (
            <DataField
              label="Intereses a las Cesantías año anterior"
              value={formatCurrency(values.previous_severance_interests)}
              styles={totalsStyles}
            />
          )}
          <DataField
            label="Prima de Servicios"
            value={formatCurrency(values.service_bonus)}
            tooltipContent={messages.service_bonus}
            styles={totalsStyles}
          />
          {values.pay_previous_service_bonus && (
            <DataField
              label="Prima de Servicios semestre anterior"
              value={formatCurrency(values.previous_service_bonus)}
              styles={totalsStyles}
            />
          )}
          <DataField
            label="Indemnización por despido"
            value={formatCurrency(values.compensation)}
            tooltipContent={messages.compensation}
            styles={totalsStyles}
          />
          <DataField
            label="Otros"
            value={formatCurrency(
              values.non_salary_income -
                values.deductions -
                values.loans -
                values.anticipated_social_benefits_value -
                values.payroll_credit_value -
                (values.pay_decree376 ? values.decree376 : 0)
            )}
            styles={totalsStyles}
          />
          <DataField
            label="Total liquidación"
            value={formatCurrency(values.worker_payment)}
            styles={{
              label: { fontWeight: 'bold' },
              value: { textAlign: 'right' },
            }}
          />
        </div>
      </Box>
      <div className={fieldsClasses.fieldsGroup}>
        <Typography variant="h6" gutterBottom>
          9. Revisión
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <Button
            variant="text"
            onClick={handleTerminationPreview}
            startIcon={<InsertDriveFileOutlinedIcon />}
          >
            Previsualizar
          </Button>
          <Button
            variant="text"
            onClick={handlePaymentsHistory}
            startIcon={<GetAppOutlinedIcon />}
          >
            Pagos salariales
          </Button>
          {isEditing && (
            <Button
              component={MuiLink}
              variant="text"
              href={`${routes.PERIOD_PAYROLL_VIEW_DETAILS(
                values.payroll_id
              )}?selected_tab=termination`}
              target="_blank"
              startIcon={<VisibilityOutlinedIcon />}
            >
              Ver cálculos
            </Button>
          )}
        </Box>
        <br />
        <Typography>
          Si deseas que en la liquidación aparezca el salario pendiente por
          cancelar, primero debes terminar de liquidar el empleado y en el
          siguiente paso podrás descargarla de esta forma.
        </Typography>
        <Typography variant="h6" gutterBottom>
          10. Revisión
        </Typography>
        <Typography gutterBottom>
          Al confirmar la liquidación, el empleado no volverá a aparecer en la
          lista de empleados activos, ni en el siguiente pago de nómina.
          <br />
          <br />
          Si luego deseas editar algo o reincorporar nuevamente el empleado, lo
          podrás hacer desde la sección &quot;Empleados&quot;.
        </Typography>
        <Box display="flex" justifyContent="end">
          <Button variant="outlined" disabled={isSaving} onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            onClick={validateBeforeSubmit}
            loading={isSaving}
            data-cy="termination_save_button"
          >
            {isEditing ? 'Editar Liquidación' : 'Liquidar Persona'}
          </Button>
        </Box>
      </div>
    </Paper>
  )
}

export default WorkerContractTerminationFormSummary
