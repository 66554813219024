import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { generateWageBaseSalaryValidationSchema } from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema()

export default [
  {
    title: 'Paso 1',
    description: 'Datos básicos',
    schemaValidation: yup.object({
      name: yup.string().trim().required(),
      last_name: yup.string().trim().required(),
      document_type: yup.string().trim().required(),
      email: yup.string().nullable().email().when('active_user', {
        is: true,
        then: yup.string().nullable().email().required(),
      }),
      active_user: yup.boolean().nullable().required(),
      term: yup.string().nullable().required(),
      contract_category: yup.string().nullable().required(),
      initial_day: yup.date().nullable().default(null).required(),
      end_day: yup
        .date()
        .nullable()
        .default(null)
        .when('term', {
          is: (term) => term === 'fixed' || term === 'project',
          then: yup.date().nullable().default(null).required(),
        }),
      wage_category: yup.string().nullable().required(),
      base_salary: wageBaseSalaryValidationSchema,
      location: yup.object().nullable().required(),
      area: yup.object().nullable().required(),
      position: yup.object().nullable().required(),
      cost_center: yup.object().nullable().required(),
      transport_subsidy: yup
        .boolean()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory && contractCategory !== 'contractor',
          then: yup.boolean().nullable().required(),
        }),
      work_center: yup
        .object()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory &&
            contractCategory !== 'contractor' &&
            contractCategory !== 'schooling_trainee',
          then: yup.object().nullable().required(),
        }),
      high_risk_pension: yup
        .boolean()
        .nullable()
        .when('work_center', {
          is: (workCenter) => Boolean(workCenter?.name?.includes('5')),
          then: yup.boolean().nullable().required(),
        }),
      rest_days: yup.array().of(yup.object().nullable()).nullable(),
      enjoyed_days_since_hired: yup.number().required(),
    }),
  },
  {
    title: 'Paso 2',
    description: 'Datos de pago',
    schemaValidation: yup.object({
      payment_method: yup.string().nullable().required(),
      bank: yup.mixed().when('payment_method', {
        is: 'wire_transfer',
        then: yup.object().nullable().required(),
        otherwise: yup.mixed().nullable(),
      }),
      account_type: yup.object().nullable().when('payment_method', {
        is: 'wire_transfer',
        then: yup.object().nullable().required(),
      }),
      account_number: yup.number().when('payment_method', {
        is: 'wire_transfer',
        then: yup.number().nullable().required(),
        otherwise: yup.number().nullable(),
      }),
    }),
  },
  {
    title: 'Paso 3',
    description: 'Datos personales',
    schemaValidation: yup.object({
      birthday: yup.date().nullable().default(null),
      address: yup.string().trim().nullable(),
      phone: yup.string().trim().nullable(),
      health_provider: yup
        .object()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory &&
            ![
              'contractor',
              'student_decree_055',
              'part_time_contract',
            ].includes(contractCategory),
          then: yup.object().nullable().required(),
        }),
      pension_provider: yup
        .object()
        .nullable()
        .when(['contract_category', 'document_type'], {
          is: (contractCategory, documentType) =>
            ['ce_no_pension', 'pe_no_pension'].includes(documentType)
              ? false
              : contractCategory &&
                ['employee', 'student_law_789', 'part_time_contract'].includes(
                  contractCategory
                ),
          then: yup.object().nullable().required(),
        }),
      severance_provider: yup
        .object()
        .nullable()
        .when(['contract_category', 'wage_category'], {
          is: (contractCategory, wageCategory) =>
            contractCategory &&
            wageCategory &&
            ['employee', 'pensioner', 'part_time_contract'].includes(
              contractCategory
            ) &&
            wageCategory !== 'integral_salary',
          then: yup.object().nullable().required(),
        }),
    }),
  },
]
