import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { formatDateRange } from 'utils/dateTime'
import usePayrollService from 'utils/hooks/payroll/payroll'

import DetailsBody from './DetailsBody'
import DetailsHeader from './DetailsHeader'
import { getDetails } from './helpers'

const PeriodDetails = () => {
  const companyId = getCompanyId()
  const { payrollId, socialBenefitsId } = useParams()

  const { payrollQuery: basePayrollDetailsQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'base_details'],
      payrollId,
      searchParams: { social_benefit_period_id: socialBenefitsId },
    },
    queryOptions: {
      enabled: !!payrollId,
    },
  })
  const {
    payroll_retention: payrollRetention,
    termination,
    social_benefits: socialBenefits,
  } = basePayrollDetailsQuery.data?.header?.payroll_options || {}
  const { payrollQuery: payrollRetentionQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'payroll_retention'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'payroll_retention',
      },
    },
    queryOptions: {
      enabled: !!payrollRetention,
    },
  })
  const { payrollQuery: terminationQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'termination'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'termination',
      },
    },
    queryOptions: {
      enabled: !!termination,
    },
  })
  const { payrollQuery: socialBenefitsQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'social_benefits'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'social_benefits',
      },
    },
    queryOptions: {
      enabled:
        !!socialBenefits && !socialBenefitsId.includes('socialBenefitsId'),
    },
  })
  const { data: basePayrollDetailsData } = basePayrollDetailsQuery
  const { data: payrollRetentionDetailsData } = payrollRetentionQuery
  const { data: terminationDetailsData } = terminationQuery
  const { data: socialBenefitsDetailsData } = socialBenefitsQuery
  const details = useMemo(
    () =>
      getDetails(
        basePayrollDetailsData,
        payrollRetentionDetailsData,
        terminationDetailsData,
        socialBenefitsDetailsData
      ),
    [
      basePayrollDetailsData,
      payrollRetentionDetailsData,
      socialBenefitsDetailsData,
      terminationDetailsData,
    ]
  )

  return (
    <Page
      isLoading={basePayrollDetailsQuery.isLoading}
      documentTitle={`Cálculos${
        details.header
          ? ` de ${details.header.worker.name} del ${formatDateRange(
              details.header.period.period_payment.initial_day,
              details.header.period.period_payment.end_day
            )}`
          : ''
      }`}
      header={
        <>
          <Typography variant="h5">Cálculos</Typography>
          <Typography>
            A continuación, podrás encontrar los cálculos que realiza Nominapp,
            para que puedas revisar de dónde proviene cada valor de la
            liquidación.
          </Typography>
        </>
      }
    >
      <DetailsHeader headerData={details.header} />
      <DetailsBody details={details} socialBenefitsId={socialBenefitsId} />
    </Page>
  )
}

export default PeriodDetails
