import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import Link from 'components/UI/MaterialUI/Link'

import { isArusDist } from 'utils/auth'
import { generateRandomString, isDev } from 'utils/general'
import useRegistryService from 'utils/hooks/auth/registryService'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { LOGIN } from 'config/routes'

import AuthLogo from '../common/Logo'
import MainContainer from '../common/MainContainer'
import { getSignUpRedirectPath, useAuthStyles } from '../helpers'
import LandingCustomField from './LandingCustomField'
import TermsConditionsAndPoliticsModal from './Modals/TermsConditionsAndPolitics'
import { getInitialValues, getValidationSchema } from './helpers'

const SignUp = () => {
  const classes = useAuthStyles()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { logIn, isAuthenticated } = useUser()
  const [signUpOk, setSignUpOk] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const { handleError } = useErrorHandler()
  const { registryMutation } = useRegistryService()
  const { sessionMutation } = useSessionService()

  if (signUpOk || isAuthenticated()) {
    const planFromLanding = queryParams.plan

    return (
      <Redirect
        to={
          planFromLanding && planFromLanding !== '[plan]'
            ? { pathname: '/subscription', state: { plan: planFromLanding } }
            : getSignUpRedirectPath()
        }
      />
    )
  }

  const handleSubmit = async (values, form) => {
    try {
      const fakeCompanyName = `${generateRandomString()} ${generateRandomString()}`
      const filledValues = {
        ...values,
        name: 'Usuario Nominapp',
        company_name: fakeCompanyName,
      }

      if (isArusDist()) filledValues.distributor = 'arus'

      await registryMutation.mutateAsync({
        mutationMethod: 'POST',
        registry: {
          ...queryParams,
          ...filledValues,
        },
      })
      try {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...queryParams,
              ...filledValues,
            },
          },
          {
            onSuccess: ({ data }) => {
              logIn(data)

              if (!isDev) {
                window.dataLayer.push({
                  event: 'Sign Up',
                })
              }
              setSignUpOk(true)
            },
          }
        )
      } catch (error) {
        handleError(error)
      }
    } catch (error) {
      handleError(error, form, {
        errorFieldMap: { id_number: 'company_id_number' },
      })
    }
  }

  const handleOpenTermsModal = () => setIsTermsModalOpen(true)

  const handleOkTermsModal = () => setIsTermsModalOpen(false)

  const initialValues = getInitialValues(queryParams)

  const validationSchema = getValidationSchema()

  return (
    <MainContainer>
      <div className={classes.formContainer}>
        <AuthLogo width={260} height={46} className={classes.logo} />
        <Typography variant="h4" className={classes.title}>
          Crea tu cuenta
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Ingresa tus datos y comienza a disfrutar de la mejor experiencia
          liquidando nómina ¡Gratis!
        </Typography>
        <GoogleLoginButton action="Sign Up" />
        <div className={classes.divider}>
          <Divider />
          <Typography variant="h5" color="textSecondary">
            O
          </Typography>
          <Divider />
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form name="signup_form" className={classes.form}>
                <div className={classes.fieldsContainer}>
                  <LandingCustomField
                    type="email"
                    name="email"
                    label="Tu correo electrónico"
                    placeholder="Ingresa tu correo electrónico"
                    queryParams={queryParams}
                  />
                  <PasswordField
                    name="password"
                    label="Tu contraseña (Mínimo 8 caracteres)"
                    placeholder="Ingresa tu contraseña"
                    autoComplete="password"
                  />
                  <PasswordField
                    name="password_confirmation"
                    label="Repetir contraseña (Mínimo 8 caracteres)"
                    placeholder="Confirma tu contraseña"
                    autoComplete="password"
                  />
                </div>
                <Button
                  type="submit"
                  color="primary"
                  loading={isSubmitting}
                  className={classes.button}
                  data-cy="sign_up_button"
                >
                  Crear cuenta
                </Button>
              </Form>
            )
          }}
        </Formik>
        <Typography variant="body2">
          ¿Ya tienes una cuenta? <Link to={LOGIN}>Ingresa</Link>
        </Typography>
        <footer className={classes.termsContainer}>
          <Typography variant="body2" className={classes.termsText}>
            Al crear tu cuenta aceptas nuestros{' '}
            <MuiLink component="button" onClick={handleOpenTermsModal}>
              Términos y condiciones y Política de tratamiento de los datos.
            </MuiLink>
          </Typography>
        </footer>
        <TermsConditionsAndPoliticsModal
          visible={isTermsModalOpen}
          onOk={handleOkTermsModal}
        />
      </div>
    </MainContainer>
  )
}

export default SignUp
