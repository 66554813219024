import http, { getApiURL } from '../httpService'

export const getAllContracts = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/contracts` })

  return http.getV2(url)
}

export const getContractById = (contractId) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}` })

  return http.getV2(url)
}

export const getCurrentContract = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/contract` })

  return http.getV2(url)
}

export const createContract = (workerId, contract) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/contracts` })

  const data = { contract }

  return http.postV2(url, data)
}

export const updateContract = (contract) => {
  const url = getApiURL({ pathname: `/contracts/${contract.id}` })

  const data = { contract }

  return http.patchV2(url, data)
}

export default {
  getAllContracts,
  getContractById,
  getCurrentContract,
  createContract,
  updateContract,
}
