import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import useContentStyles from '../Payroll/DataTable/Modals/common/commonStyles'

const AVISTA_ADVANCE = 'avista_payroll_advance'

export default function OptionSelector({
  data,
  listOptions,
  onSelectItem,
  onChangeItemValue,
  onDeleteItem,
  category,
  indexToIgnore, // to ignore the withholding_tax item
  name = 'payroll_concept_id',
}) {
  const contentClasses = useContentStyles()

  return data.map((item, index) => {
    if (indexToIgnore && indexToIgnore.includes(index)) return null
    const defaultKey = `${category}-item-${index}`

    const { value } = item
    // Get the id from the custom name
    const payrollConceptId = item[name]
    // Validation for disabled when deleteable false and does not exist item for created concepts
    const optionItem =
      !!listOptions &&
      listOptions.filter(
        (option) => option.owner_type !== 'Country' && option.name === item.name
      )

    const disableButton =
      (index === 0 && !payrollConceptId && data.length === 1) ||
      (!!optionItem &&
        optionItem.length > 0 &&
        !optionItem[0].deleteable &&
        value === 0)

    return (
      <div
        className={contentClasses.itemContainer}
        key={item.id || defaultKey}
        id={`${category}-item-${index}`}
        data-cy={`${category}-item-${index}`}
      >
        <FormControl>
          <Select
            name={name}
            value={payrollConceptId}
            onChange={(e) => {
              onSelectItem(e.target.value, index, category)
            }}
            displayEmpty
            data-cy={`${category}-select-${index}`}
          >
            <MenuItem disabled value="">
              <em>Selecciona un concepto...</em>
            </MenuItem>

            {listOptions.map((option) => {
              const { name: nameOption, id, selected } = option

              return (selected && id !== payrollConceptId) ||
                option.coded_name === AVISTA_ADVANCE ? null : (
                <MenuItem key={id} value={id}>
                  {nameOption}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <TextField
          name="value"
          value={value}
          onChange={(e) => onChangeItemValue(e, index, category)}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            placeholder: '0',
          }}
        />
        <IconButton
          aria-label="delete"
          onClick={() => onDeleteItem(index, category)}
          disabled={disableButton}
          size="small"
        >
          <DeleteOutlineIcon />
        </IconButton>
      </div>
    )
  })
}
