import * as yup from 'yup'

import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Link from 'components/UI/MaterialUI/Link'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { ORGANIZER_PARTNER_SHOW } from 'config/routes'

const PLAN_CATEGORY = 'partner_plan20220824'

yup.setLocale(yupLocaleES)

const useStyles = makeStyles({
  headerColumn: {
    fontWeight: 500,
    marginRight: 5,
  },
})

export const validationSchema = yup.object({
  name: yup.string().trim().required(),
  id_number: yup.string().trim().required(),
  email: yup.string().email().required(),
  discount: yup.number().min(0).max(100).required(),
  base_discount_type: yup.string().required(),
  base_discount: yup.number().min(0).required(),
})

export const getInitialValues = (partner) => {
  let baseDiscountType = 'percentage'

  if (partner.plan_category && partner.plan_category !== PLAN_CATEGORY) {
    baseDiscountType = 'value'
  }

  return {
    base_discount_type: baseDiscountType,
    discount: 0,
    base_discount: 0,
  }
}

const PlanCategoryHeader = () => {
  const classes = useStyles()

  const messageTooltip = (
    <>
      Ver información de la categoría,{' '}
      <TooltipLink href="https://playbook.nominapp.com/categorias-de-precios">
        click aquí.
      </TooltipLink>
    </>
  )

  return (
    <>
      <Typography variant="body2" className={classes.headerColumn}>
        Categoría plan
      </Typography>
      <Tooltip title={messageTooltip} placement="top" interactive arrow>
        <InfoOutlinedIcon fontSize="small" color="primary" />
      </Tooltip>
    </>
  )
}

export const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link to={ORGANIZER_PARTNER_SHOW(row.original.id)}>
        {row.original.name}
      </Link>
    ),
  },
  {
    Header: 'No. Identificación',
    accessor: 'id_number',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: PlanCategoryHeader,
    accessor: 'plan_category',
  },
  {
    Header: 'Desc. por persona',
    accessor: 'discount',
    Cell: ({ row }) => `${row.original.discount}%`,
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Desc. base',
    accessor: 'base_discount',
    Cell: ({ row }) =>
      row.original.base_discount_type === 'value'
        ? formatCurrency(row.original.base_discount)
        : `${row.original.base_discount}%`,
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: ({ row }) => (row.original.active ? 'Si' : 'No'),
    alignCell: 'center',
    alignHeader: 'center',
  },
]

export const getTableActions = (setModalState) => {
  return [
    {
      id: 'newPartner',
      icon: AddCircleOutlineIcon,
      tooltip: 'Nuevo Partner',
      onClick: () => setModalState({ open: true, partner: {} }),
      isFreeAction: true,
    },
    {
      id: 'editPartner',
      icon: EditIcon,
      tooltip: 'Editar Partner',
      onClick: (rowData) => setModalState({ open: true, partner: rowData }),
    },
  ]
}

export const customBaseDiscountType = (isEdit, partner) => {
  const values = []

  if (isEdit && partner.plan_category !== PLAN_CATEGORY) {
    values.push(
      { label: 'Valor monetario ($)', value: 'value' },
      { label: 'Porcentaje (%)', value: 'percentage' }
    )
  } else {
    values.push({ label: 'Porcentaje (%)', value: 'percentage' })
  }

  return values
}
