import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import IconButton from './IconButton'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[700],

    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    fontSize: '25px !important',
  },
}))

const HelpButton = () => {
  const classes = useStyles()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down(768))

  const onClick = () => window.open('https://ayuda.nominapp.com/help', '_blank')

  if (xsDown) {
    return <IconButton onClick={onClick} Icon={HelpOutlineIcon} />
  }

  return (
    <Button
      startIcon={<HelpOutlineIcon className={classes.icon} />}
      variant="text"
      color="default"
      className={classes.button}
      onClick={onClick}
    >
      <Typography variant="body1" color="inherit" noWrap>
        Centro de ayuda
      </Typography>
    </Button>
  )
}

export default HelpButton
