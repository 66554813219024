import { useFormikContext } from 'formik'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'

import { getFileNameFromUrl, isValidUrl } from 'utils/general'

import BeneficiariesStep from './Beneficiaries/Index'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
    margin: theme.spacing(2, 0, 7, 0),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    },
  },
  commentContainer: {
    display: 'grid',
    gridColumn: '1/-1',
    marginTop: theme.spacing(4),
  },
  beneficiariesContainer: {
    display: 'grid',
    gridColumn: '1/-1',
  },
  textarea: {
    minHeight: 136,
  },
}))

const AttachDocumentsStep = () => {
  const classes = useStyles()
  const { values } = useFormikContext()
  const identificationDocumentUrl = isValidUrl(values?.identification_document)
  const identificationDocumentName = getFileNameFromUrl(
    identificationDocumentUrl ? identificationDocumentUrl.search : undefined
  )

  return (
    <>
      <Typography variant="body1">
        Adjunta la cédula de la persona, esto con el fin de validar la
        información y obtener los datos necesarios para la afiliación a
        seguridad social.
      </Typography>
      <div className={classes.container}>
        <FormField
          name="identification_document"
          label="Cédula de la persona"
          variant="file"
          fileName={identificationDocumentName}
          fileType="application/pdf"
          accept="application/pdf"
        />
      </div>
      <BeneficiariesStep />
      <div className={classes.commentContainer}>
        <FormField
          name="comment"
          label="¿Hay algo más que debemos saber o tener en cuenta sobre esta persona y sus beneficiarios para realizar la afiliación?"
          variant="textarea"
          rows={4}
        />
      </div>
    </>
  )
}

export default AttachDocumentsStep
