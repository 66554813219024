const fontWeightName = {
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
}
/**
 *
 * @param {object} properties
 * @param {string} properties.fontFamily
 * @param {string} properties.fontStyle
 * @param {string} properties.fontDisplay
 * @param {number} properties.fontWeight
 * @param {string} properties.url
 */
const generateFont = ({
  fontFamily,
  fontStyle = 'normal',
  fontDisplay = 'swap',
  fontWeight,
  url,
}) => {
  return {
    fontFamily,
    fontStyle,
    fontDisplay,
    fontWeight,
    src: `
      local('${fontFamily}'),
      local('${fontFamily} ${fontWeightName[fontWeight]}'),
      url(${url}) format('truetype')
    `,
  }
}

export default generateFont
