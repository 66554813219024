import { useMutation } from 'react-query'

import {
  getImportingFile,
  uploadImportFile,
} from 'services/payroll/noveltiesImportService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, periodId, data } = mutationData

  if (mutationMethod === 'POST') return uploadImportFile(periodId, data)

  // Manual activation of the Get method
  if (mutationMethod === 'GET_NOVELTIES_FILE') return getImportingFile(periodId)

  return null
}

const useNoveltiesImportService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const noveltiesImportMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    noveltiesImportMutation,
  }
}

export default useNoveltiesImportService
