import useModals from 'components/App/ModalsManager/useModals'

import ErrorIndex from './Table'

const useModalErrors = () => {
  const modals = useModals()

  const handleClose = () => modals.closeAll()

  const openModal = (errors) => {
    if (errors.length > 0) {
      modals.openModal({
        id: `updateWageErrorModal`,
        content: <ErrorIndex errors={errors} />,
        modalProps: {
          header: '¡Oops!',
          hideCancelButton: true,
          alignButtons: 'center',
          okText: 'Continuar',
          onOk: handleClose,
          onCloseModal: handleClose,
          dialogProps: {
            fullWidth: true,
            maxWidth: 'sm',
          },
        },
      })
    } else {
      throw new Error('Does not have any error')
    }
  }

  return openModal
}

export default useModalErrors
