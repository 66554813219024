import * as yup from 'yup'

import { formatCurrency } from 'utils/format'
import { removeSuffix } from 'utils/general'

import amex from 'assets/images/views/subscription/amex.svg'
import dinersclub from 'assets/images/views/subscription/dinersclub.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import pse from 'assets/images/views/subscription/pse.svg'
import visa from 'assets/images/views/subscription/visa.svg'

const getItems = (planData = {}, subscriptionData = {}) => {
  const items = []
  const isAnually = subscriptionData.type === 'year'
  const { distributor } = planData.subscription_value?.discounts || {}
  const planSubscriptionValue = isAnually
    ? planData.subscription_value?.yearly || {}
    : planData.subscription_value?.monthly || {}
  const monthlyBase = planData?.monthly_value
  const isExonerated = subscriptionData?.status === 'exonerated_payment'
  const perWorkerPlan =
    removeSuffix(subscriptionData?.plan_category, -8) === 'per_worker_plan'
  const pricePerWorker = planData?.monthly_extra_worker_value
  const workersTotal =
    pricePerWorker * subscriptionData?.payrolls_size * (isAnually ? 12 : 1)
  const subtotal = planSubscriptionValue.subtotal + workersTotal
  const total = subscriptionData?.partner
    ? subscriptionData?.month_value
    : planSubscriptionValue.total

  if (!subscriptionData.partner && !isExonerated) {
    items.push({
      id: 'modality',
      label: 'Modalidad',
      value: isAnually ? 'Anual' : 'Mensual',
    })

    items.push({
      id: 'month_base',
      label: `Costo mensual ${perWorkerPlan ? 'base' : ''}`,
      value: formatCurrency(monthlyBase),
    })

    if (perWorkerPlan) {
      items.push({
        id: 'worker_base',
        label: 'Costo por persona',
        value: formatCurrency(pricePerWorker),
      })
    }

    if (planSubscriptionValue.workers_number > 0) {
      items.push({
        id: 'extra_workers',
        label: 'Cantidad de personas',
        value: planSubscriptionValue.workers_number,
      })
    }

    if (planSubscriptionValue.extra_workers_value) {
      items.push({
        id: 'extra_workers_subtotal',
        label: 'Subtotal personas',
        value: formatCurrency(planSubscriptionValue.extra_workers_value),
      })
    }

    items.push({
      id: 'periodicity_subtotal',
      label: `Subtotal ${isAnually ? 'anualidad' : 'mensualidad'}`,
      value: formatCurrency(
        perWorkerPlan ? subtotal : planSubscriptionValue.subtotal
      ),
    })

    // Nominapp discount
    if (planSubscriptionValue.extra_charge?.nominapp)
      items.push({
        label: 'Descuento (Nominapp)',
        value: `- ${formatCurrency(
          Math.abs(planSubscriptionValue.extra_charge?.nominapp)
        )}`,
        id: 'nominapp_discount',
      })

    // Distributor discount value
    if (planSubscriptionValue.extra_charge?.distributor)
      items.push({
        label: `Descuento (${distributor})`,
        value: `- ${formatCurrency(
          Math.abs(planSubscriptionValue.extra_charge?.distributor)
        )}`,
        id: 'distributor_discount',
      })
  }

  items.push({
    label: 'TOTAL',
    value: isExonerated ? 'Exonerado de pago' : formatCurrency(total),
    id: 'total',
    bold: true,
  })

  return items
}

export const billingValidationSchema = yup.object({
  document_type: yup.string().nullable().required(),
  email: yup.string().email().nullable().required(),
  id_number: yup.string().trim().nullable().required(),
  name: yup.string().trim().nullable().required(),
  verification_digit: yup.string().when('document_type', {
    is: (documentType) => documentType === 'ni',
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})

export const getBillingInitialValues = (values) => {
  return {
    document_type: null,
    email: null,
    id_number: null,
    name: null,
    verification_digit: null,
    ...(values || {}),
  }
}

export const paymentMethodLogo = {
  pse,
  'american-express': amex,
  'diners-club': dinersclub,
  mastercard,
  visa,
}

export default getItems
