import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import MuiLink from '@material-ui/core/Link'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import LockIcon from '@material-ui/icons/Lock'
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { isOrganizer } from 'utils/auth'
import usePayrollOrganizerService from 'utils/hooks/organizer/payrollService'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import { PeriodContext } from '../Payroll'
import { getSocialBenefitLink, isSbAlertActionsShown } from '../helpers'
import PayPayrollButton from './PayPayrollButton'

const useTableActions = (handlePremiumReport) => {
  const {
    period: {
      editable,
      id: periodId,
      initial_day: initialDay,
      payrolls_number: payrollsNumber,
    },
    options: { social_benefits: socialBenefits, wages_update: wagesUpdate },
    openPayslipModal,
    openBankFileModal,
    handleToggleLoadingModal,
    updatePeriodCallback,
    payPreviousPayroll,
  } = useContext(PeriodContext)
  const { payrollOrganizerMutation } = usePayrollOrganizerService()
  const history = useHistory()
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const featureState = usePremiumFeature('summaryReport')

  const withoutWorkers = payrollsNumber === 0
  const disablePayPayroll = payrollsNumber <= 0

  const handleClickViewPayslip = async (payroll) => {
    handleToggleLoadingModal(true)
    await openPayslipModal({ payroll })
    handleToggleLoadingModal(false)
  }

  const handleClickSendPayslip = async (payroll) => {
    handleToggleLoadingModal(true)
    await openPayslipModal({ payroll, isSendEmail: true })
    handleToggleLoadingModal(false)
  }

  const handleDeletePayroll = ({ id }) => {
    payrollOrganizerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        payrollId: id,
      },
      {
        onSuccess: ({ data }) => {
          const { payroll } = data
          // Disabled this rule because is a parameter in back for hidden the payroll
          // eslint-disable-next-line no-underscore-dangle
          payroll.__deleted__ = true
          updatePeriodCallback(data)
          showSuccessMessage(data.message)
        },
      }
    )
  }

  const isAlertVisible = isSbAlertActionsShown(socialBenefits, initialDay)
  const periodMonth = new Date(initialDay).getMonth()
  const periodDay = new Date(initialDay).getDate()

  const getFreeButtonText = () => {
    let sbToPay

    if (periodMonth === 5 || periodMonth === 11) sbToPay = 'Prima'
    else if (periodMonth === 0) sbToPay = 'Int. a las cesantías'
    else if (periodMonth === 1) sbToPay = 'Cesantías'

    return `${isAlertVisible ? 'Liquidar' : 'Editar'} ${sbToPay}`
  }

  const benefitNotPaid = isAlertVisible || periodDay === 16
  const isValidPeriod =
    [0, 5, 11].includes(periodMonth) || (periodMonth === 1 && periodDay === 1)

  const actions = [
    {
      id: 'paySocialSecurity',
      tooltip: getFreeButtonText(),
      isFreeAction: true,
      hidden: !benefitNotPaid || !isValidPeriod,
      buttonVariant: 'outlined',
      onClick: () =>
        history.push({
          pathname: getSocialBenefitLink(
            socialBenefits,
            periodId,
            initialDay,
            payPreviousPayroll
          ),
          state: {
            payPreviousPayroll:
              new Date().getMonth() !== new Date(initialDay).getMonth(),
          },
        }),
    },
    {
      id: 'indexWagesUpdate',
      tooltip: 'Actualizar salarios mínimos',
      isFreeAction: true,
      hidden: !wagesUpdate,
      buttonVariant: 'outlined',
      onClick: () =>
        history.push({
          pathname: routes.WORKER_WAGES_UPDATE(),
          state: 'payroll',
        }),
    },
    (rowData) => ({
      id: 'addNote',
      tooltip: 'Agregar Nota',
      icon: NoteOutlinedIcon,
      commentsDialog: true,
      badge: rowData.comments,
      hidden: !editable || rowData.locked,
    }),
    (rowData) => ({
      id: 'seeCalculations',
      tooltip: 'Ver Calculos',
      icon: VisibilityOutlinedIcon,
      hidden: rowData.contract_category === 'contractor' || rowData.locked,
      buttonProps: {
        component: MuiLink,
        href: payPreviousPayroll
          ? routes.PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS(periodId, rowData.id)
          : routes.PERIOD_PAYROLL_VIEW_DETAILS(rowData.id),
        target: '_blank',
        'data-cy': `payroll-${rowData.rowIndex}-view_details`,
      },
    }),
    (rowData) => ({
      id: 'seeBill',
      tooltip: 'Ver colilla de pago',
      icon: DescriptionOutlinedIcon,
      onClick: (payroll) => handleClickViewPayslip(payroll),
      hidden: rowData.locked,
      buttonProps: {
        'data-cy': `payroll-${rowData.rowIndex}-view_payslip`,
      },
    }),
    (rowData) => ({
      id: 'sendBill',
      tooltip: 'Enviar colilla de pago',
      icon: SendOutlinedIcon,
      onClick: (payroll) => handleClickSendPayslip(payroll),
      hidden: !rowData.payslip_mail || rowData.locked,
    }),
    (rowData) => ({
      id: 'bankFile',
      tooltip: 'Archivo pago en banco',
      icon: SystemUpdateAltIcon,
      onClick: (payroll) => openBankFileModal({ payroll }),
      hidden: !rowData.bank_file || rowData.locked,
    }),
    (rowData) => ({
      id: 'deletePayroll',
      tooltip: 'Eliminar nómina',
      icon: DeleteForeverIcon,
      hidden: !isOrganizer(),
      onClick: () => {
        confirm({
          type: 'warning',
          title: 'Eliminar nómina',
          description: `Eliminar la nómina de ${rowData.worker_name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => handleDeletePayroll(rowData),
        })
      },
    }),
    (rowData) => ({
      id: 'decree376Payment',
      tooltip:
        'Esta nómina contiene pagos de aporte por decreto 376 para un empleado que ya fue liquidado',
      icon: LockIcon,
      hidden: !rowData.locked,
    }),
    {
      id: 'addWorker',
      isFreeAction: true,
      hidden: payPreviousPayroll || withoutWorkers,
      Component: <ButtonAddWorker variant="outlined" />,
    },
    {
      id: 'payPayroll',
      isFreeAction: true,
      hidden: withoutWorkers,
      Component: <PayPayrollButton disabled={disablePayPayroll} />,
    },
    {
      id: 'reportPayroll',
      tooltip: 'Descargar resumen',
      isFreeAction: true,
      hidden: !payPreviousPayroll,
      buttonProps: {
        endIcon: (
          <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
        ),
      },
      onClick: handlePremiumReport,
    },
  ]

  return actions
}

export default useTableActions
