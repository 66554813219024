import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useFormikContext } from 'formik'

import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import useLocationService from 'utils/hooks/company/locationService'

import {
  createLocation,
  deleteLocation,
  updateLocation,
} from 'services/company/locationService'

import { validationSchema, getColumnsData, formatLocation } from './helpers'

const CompanyFormFieldsLocations = ({ compensationFunds }) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { setFieldValue } = useFormikContext()

  const { locationQuery } = useLocationService({
    serviceParams: { queryKey: 'companyLocation' },
    queryOptions: {
      onSuccess: ({ data }) => {
        setFieldValue('locations', data)
      },
    },
  })

  const callback = (action) => {
    queryClient.invalidateQueries('companyLocation')
    showSuccessMessage(`La sede  ha sido ${action}.`)
  }

  const handleAddLocation = (location) => {
    const locationFormatted = formatLocation(location)
    return {
      data: {
        ...locationFormatted,
      },
      mutationFunction: createLocation,
      mutateOptions: {
        onSuccess: () => callback('creada'),
      },
    }
  }

  const handleUpdateLocation = (oldLocation, newLocation) => {
    const dirtyValues = getDirtyValues(
      oldLocation,
      newLocation,
      validationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      const locationFormatted = formatLocation(newLocation)
      return {
        data: { ...locationFormatted },
        mutationFunction: updateLocation,
        mutateOptions: {
          onSuccess: () => callback('actualizada'),
        },
      }
    }
    return {}
  }

  const handleDeleteLocation = (location) => {
    return {
      data: location.id,
      mutationFunction: deleteLocation,
      mutateOptions: {
        onSuccess: () => callback('eliminada'),
      },
    }
  }

  const columnsData = useMemo(() => getColumnsData(compensationFunds), [
    compensationFunds,
  ])

  return !locationQuery?.loading ? (
    <Table
      columns={columnsData}
      data={locationQuery?.data}
      options={{ pagination: false, search: false }}
      editable={{
        onAddRow: handleAddLocation,
        onUpdateRow: handleUpdateLocation,
        onDeleteRow: handleDeleteLocation,
        isDeletable: ({ deleteable }) => deleteable,
        enableAddRow: true,
        addRowActionProps: {
          tooltip: 'Añadir Sede',
        },
        addRowPosition: 'top',
        validationSchema,
        tableMinWidth: 1080,
      }}
    />
  ) : null
}

export default CompanyFormFieldsLocations
