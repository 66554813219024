import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const CardEdit = ({ card, className }) => {
  const classes = useStyles()

  const { title, description, btnText, externalLink, to, dataCy } = card

  return (
    <Card className={clsx(classes.card, className)}>
      <CardHeader title={<Typography variant="h5">{title}</Typography>} />
      <CardContent className={classes.content}>
        <Typography>{description}</Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="text"
          data-cy={dataCy}
          component={externalLink ? 'a' : Link}
          to={externalLink ? undefined : to}
          href={externalLink ? to : undefined}
          target={externalLink ? '_blank' : undefined}
        >
          {btnText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default CardEdit
