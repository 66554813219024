import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Link from 'components/UI/MaterialUI/Link'

const useStyles = makeStyles((theme) => ({
  text: {
    userSelect: 'none',
    color: theme.palette.grey[700],
  },
  link: {
    color: theme.palette.primary[600],
    fontWeight: 'bold',
  },
}))

const Breadcrumb = ({ variant = 'text', label, to, onClick }) => {
  const classes = useStyles()

  if (variant === 'text') {
    return <Typography className={classes.text}>{label}</Typography>
  }

  return (
    <Link className={classes.link} to={to} onClick={onClick}>
      {label}
    </Link>
  )
}

export default Breadcrumb
