import Modal from 'components/UI/Modal/Modal'

import ErrorDetail from '../ModalRejected/Content/ErrorDetail'

const ModalErrorDetail = ({ errors, closeModal }) => {
  return (
    <Modal
      onCloseModal={closeModal}
      header="Detalle de motivo del rechazo"
      okText="Continuar"
      onOk={closeModal}
      hideCancelButton
    >
      <ErrorDetail errors={errors} />
    </Modal>
  )
}

export default ModalErrorDetail
