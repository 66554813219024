import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'

import useSubscription from 'components/Subscription/Atoms/useSubscription'

const useStyles = makeStyles((theme) => ({
  // TODO: Refactor these styles when the new subscription view is available to everyone
  inlineField: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    columnGap: theme.spacing(5),
  },
  inlineFieldV2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    columnGap: theme.spacing(4.5),
    rowGap: theme.spacing(2.5),
  },
}))

const PersonalFields = ({ className, disableIdNumberValidate }) => {
  const classes = useStyles()
  const { showNewSubscription } = useSubscription()

  return (
    <div
      className={clsx(classes.inlineField, className, {
        [classes.inlineFieldV2]: showNewSubscription,
      })}
    >
      <DocumentTypeField />
      <IdentificationNumberField disableValidate={disableIdNumberValidate} />
    </div>
  )
}

export default PersonalFields
