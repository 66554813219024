import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import { accountingCategoryNames } from 'utils/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useIntegrationService from 'utils/hooks/settings/integrationService'
import useNotifications from 'utils/hooks/useNotifications'

import { updateAlegraAccountingCodes } from 'services/settings/integrationAccountingCodesService'

import * as routes from 'config/routes'

import { getAccountColumns } from '../helpers'

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'block',
    backgroundColor: theme.palette.grey[100],
  },
}))

const SetttingsAccountingCategory = ({ category, expanded, handleChange }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const history = useHistory()
  const queryKey = ['alegraAccountingCodes', category]
  const { integrationsQuery } = useIntegrationService({
    serviceParams: {
      queryKey,
      category,
    },
    queryOptions: {
      onError: (error) => {
        const { errors } = error
        if (
          error.status === 404 &&
          errors[0].code === '0001' &&
          errors[0].object === 'integration'
        ) {
          history.push({
            pathname: routes.SETTINGS_INTEGRATION_INDEX(),
            state: { error: errors[0] },
          })
        } else if (
          error.status === 422 &&
          errors[0].code === '1202' &&
          errors[0].object === 'integration'
        ) {
          history.push({
            pathname: routes.SETTINGS_INTEGRATION_INDEX(),
            state: { error: errors[0] },
          })
        } else handleError(error[0])
      },
    },
  })
  const isEmptyData =
    integrationsQuery.data && integrationsQuery.data.length === 0
  const codeOptions = integrationsQuery?.alegraOptions?.codes || []
  const counterpartOptions =
    integrationsQuery?.alegraOptions?.counterpart_codes || []
  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      showSuccessMessage('La cuenta contable se ha actualizado correctamente')
    },
  }

  const updateAccountingCode = (oldAccountingData, newAccountingData) => {
    return {
      data: {
        data: {
          integration_code: {
            payroll_concept_id: newAccountingData.payroll_concept_id,
            counterpart_code: newAccountingData.counterpart_code?.id,
            code: newAccountingData.integration_code?.id,
          },
        },
        integrationId: oldAccountingData.integration_id,
      },
      mutationFunction: updateAlegraAccountingCodes,
    }
  }

  const columns = getAccountColumns(codeOptions, counterpartOptions, category)

  return (
    <Accordion
      expanded={expanded === category}
      onChange={handleChange(category)}
      disabled={integrationsQuery.isLoading || isEmptyData}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${category}-content`}
        id={`panel-${category}-header`}
        data-cy={`accordion-${category}`}
      >
        <Typography>
          {accountingCategoryNames[category]}
          {integrationsQuery.isLoading && ' - Cargando...'}
          {isEmptyData &&
            ` - Ninguna persona tiene registrada ${accountingCategoryNames[category]}.`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Table
          columns={columns}
          data={integrationsQuery.data || []}
          editable={{
            onUpdateRow: updateAccountingCode,
            hideDelete: true,
            tableMinWidth: 960,
            mutationOptions,
          }}
          options={{
            toolbar: false,
            pagination: false,
            customActionsWidth: 200,
            alignActionsHeader: 'center',
            alignActionsCell: 'center',
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default SetttingsAccountingCategory
