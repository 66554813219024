import { useLocation } from 'react-router-dom'

import ApartmentIcon from '@material-ui/icons/Apartment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import OrganizerIcon from '@material-ui/icons/SentimentVerySatisfied'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import TuneIcon from '@material-ui/icons/Tune'
import BusinessIcon from '@material-ui/icons/Business'
import PeopleIcon from '@material-ui/icons/People'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'

import { useUser } from 'components/App/UserContext/useUser'

import { isObjectEmpty } from 'utils/general'
import { isOrganizer, isAccountant } from 'utils/auth'
import { getCompanyId, hasSelectedCompany, hasOneCompany } from 'utils/company'

import PayPayroll from 'assets/icons/PayPayroll'
import MultiCompany from 'assets/icons/MultiCompany'
import HistoryPayrolls from 'assets/icons/HistoryPayrolls'
import ElectronicPayroll from 'assets/icons/ElectronicPayroll'

import * as routes from 'config/routes'

import useCloseDrawer from '../useCloseDrawer'

export default function useAdmin() {
  const location = useLocation()
  const handleCloseMobileDrawer = useCloseDrawer()
  const { clearCompany, clearFetchCompany, company } = useUser()
  const {
    onboarding_first_steps: onboardingFirstSteps = {},
    electronic_period_id: electronicPeriodId,
  } = company
  const companyId = getCompanyId()

  const hasFirstSteps = () => {
    let firstStep = true
    if (!isObjectEmpty(onboardingFirstSteps)) {
      firstStep = Boolean(onboardingFirstSteps?.finished_all_steps)
    }
    return firstStep
  }

  return [
    {
      key: 'firstSteps',
      Icon: PlaylistAddCheckIcon,
      text: 'Primeros pasos',
      path: routes.ONBOARDING_FIRST_STEPS(),
      hidden: hasFirstSteps(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'dashboard',
      Icon: TuneIcon,
      text: 'Panel de control',
      path: routes.DASHBOARD,
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'payroll',
      Icon: AttachMoneyIcon,
      text: 'Nómina',
      pathsToHighlight: [
        routes.PERIOD_PAY_PAYROLL(),
        routes.PERIOD_GENERATED_PAYROLL(),
        routes.PERIOD_HISTORY(),
        routes.SETTINGS_ELECTRONIC_PAYROLL(),
        routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId),
      ],
      hidden: !hasSelectedCompany(),
      options: [
        {
          key: 'pay_payroll',
          text: 'Liquidar nómina',
          path: routes.PERIOD_PAY_PAYROLL(),
          onClick: () => handleCloseMobileDrawer(),
        },
        {
          key: 'electronic_payroll',
          text: 'Nómina electrónica',
          path: electronicPeriodId
            ? routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId)
            : routes.SETTINGS_ELECTRONIC_PAYROLL(),
          onClick: () => handleCloseMobileDrawer(),
        },
        {
          key: 'payroll_history',
          text: 'Historial',
          path: routes.PERIOD_HISTORY(),
          onClick: () => handleCloseMobileDrawer(),
        },
      ],
    },
    {
      key: 'company',
      Icon: ApartmentIcon,
      text: 'Empresa',
      pathsToHighlight: [
        routes.COMPANY_SHOW(companyId),
        routes.WORKER_INDEX(),
        routes.COMPANY_INDEX(),
      ],
      hidden: !hasSelectedCompany(),
      options: [
        {
          key: 'company_information',
          text: 'Datos de la empresa',
          path: routes.COMPANY_SHOW(companyId),
          onClick: () => handleCloseMobileDrawer(),
        },
        {
          key: 'company_workers',
          text: 'Personas',
          path: routes.WORKER_INDEX(),
          onClick: () => handleCloseMobileDrawer(),
        },
        {
          key: 'companies_list',
          text: 'Lista de empresas',
          path: routes.COMPANY_INDEX(),
          hidden: isOrganizer() || (hasOneCompany() && !isAccountant()),
          onClick: () => {
            clearFetchCompany()
            handleCloseMobileDrawer()
          },
        },
      ],
    },
    // To show sidebar's companies item in /companies route
    {
      key: 'multi_companies',
      Icon: ApartmentIcon,
      text: 'Empresas',
      path: routes.COMPANY_INDEX(),
      pathsToHighlight: [routes.COMPANY_INDEX()],
      hidden: isOrganizer() || !(location.pathname === routes.COMPANY_INDEX()),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'organizator',
      Icon: OrganizerIcon,
      text: 'Administrador',
      onClick: () => {
        clearCompany()
        clearFetchCompany()
        handleCloseMobileDrawer()
      },
      path: routes.ORGANIZER_COMPANY_INDEX(),
      hidden: !isOrganizer(),
    },
  ]
}

export function useAdmin2() {
  const handleCloseMobileDrawer = useCloseDrawer()
  const { clearCompany, clearFetchCompany, company } = useUser()
  const {
    onboarding_first_steps: onboardingFirstSteps = {},
    electronic_period_id: electronicPeriodId,
  } = company
  const companyId = getCompanyId()

  const hasFirstSteps = () => {
    let firstStep = true
    if (!isObjectEmpty(onboardingFirstSteps)) {
      firstStep = Boolean(onboardingFirstSteps?.finished_all_steps)
    }
    return firstStep
  }

  return [
    {
      key: 'firstSteps',
      Icon: PlaylistAddCheckIcon,
      text: 'Primeros pasos',
      path: routes.ONBOARDING_FIRST_STEPS(),
      hidden: hasFirstSteps(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'dashboard',
      Icon: TuneIcon,
      text: 'Panel de control',
      path: routes.DASHBOARD,
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'company_workers',
      Icon: PeopleIcon,
      text: 'Personas',
      path: routes.WORKER_INDEX(),
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'novelties_requests',
      Icon: InsertInvitationIcon,
      text: 'Solicitudes tiempo fuera',
      path: routes.NOVELTIES_REQUEST_HISTORY(),
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'pay_payroll',
      Icon: PayPayroll,
      text: 'Liquidar nómina',
      path: routes.PERIOD_PAY_PAYROLL(),
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'electronic_payroll',
      Icon: ElectronicPayroll,
      text: 'Nómina electrónica',
      path: electronicPeriodId
        ? routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId)
        : routes.SETTINGS_ELECTRONIC_PAYROLL(),
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'payroll_history',
      Icon: HistoryPayrolls,
      text: 'Historial',
      path: routes.PERIOD_HISTORY(),
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'company_information',
      Icon: BusinessIcon,
      text: 'Empresa',
      path: routes.COMPANY_SHOW(companyId),
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'companies_list',
      Icon: MultiCompany,
      text: 'Panel multiempresa',
      path: routes.COMPANY_INDEX(),
      hidden: isOrganizer() || (hasOneCompany() && !isAccountant()),
      onClick: () => {
        clearFetchCompany()
        handleCloseMobileDrawer()
      },
    },
    {
      key: 'organizator',
      Icon: OrganizerIcon,
      text: 'Administrador',
      onClick: () => {
        clearCompany()
        clearFetchCompany()
        handleCloseMobileDrawer()
      },
      path: routes.ORGANIZER_COMPANY_INDEX(),
      hidden: !isOrganizer(),
    },
  ]
}
