import isValid from 'date-fns/isValid'

import { formatDate } from 'utils/dateTime'

export const getNestedProp = (dataObject, path) => {
  if (!path) return undefined

  return path.reduce((currentValue, currentKey) => {
    try {
      return currentValue[currentKey]
    } catch (_) {
      return undefined
    }
  }, dataObject)
}

const fillReferences = new Array(2).fill({
  first_name: null,
  second_name: null,
  first_last_name: null,
  second_last_name: null,
  kinship: null,
  phone: null,
  department: null,
  city: null,
  address: null,
})

export const spreadInitialValues = (initialValues = {}, currentStep) => {
  let values = {}

  Object.keys(initialValues).forEach((key) => {
    if (['id', 'current_step', 'credit_simulator'].includes(key)) return

    if (key === 'personal_references_data') {
      values = {
        ...values,
        personal_references_attributes:
          initialValues[key]?.length > 0
            ? [...initialValues[key]]
            : fillReferences,
      }
      return
    }

    if (
      initialValues[key] &&
      typeof initialValues[key] === 'object' &&
      !(initialValues[key] instanceof Array)
    ) {
      if (
        currentStep === 3 &&
        key === 'financial_data' &&
        initialValues[key]?.total_income === 0
      ) {
        values = {
          ...values,
          ...initialValues[key],
          total_income:
            initialValues[key]?.salary +
            initialValues[key]?.non_salary_income +
            initialValues[key]?.salary_income,
        }
        return
      }

      values = {
        ...values,
        ...initialValues[key],
      }
      return
    }

    values = {
      ...values,
      [key]: initialValues[key],
    }
  })

  if (values?.participation_percentages) {
    const avistaItem = values?.participation_percentages?.find(
      (percentageItem) => percentageItem?.name === 'Avista'
    )

    if (avistaItem) {
      values = {
        ...values,
        company_participation_percentages_attributes: [
          ...values?.participation_percentages?.filter(
            (otherPercentageItem) => otherPercentageItem?.name !== 'Avista'
          ),
        ],
        avista_percentage: {
          id: avistaItem?.id,
          name: avistaItem?.name,
          percentage: avistaItem?.percentage,
        },
      }
    } else {
      values = {
        ...values,
        company_participation_percentages_attributes: [
          ...values?.participation_percentages,
        ],
        avista_percentage: {
          id: null,
          name: 'Avista',
          percentage: '',
        },
      }
    }
  }

  delete values?.participation_percentages

  return values
}

const removePercentagesKey = (percentages) => {
  return percentages?.map(({ id, name, percentage, _destroy }) => {
    if (_destroy === 1) {
      return {
        id,
        _destroy,
      }
    }

    let percentageItem = {
      id,
      name,
      percentage,
    }

    percentageItem = {
      ...percentageItem,
      percentage: Number.parseInt(percentage, 10),
    }

    return percentageItem
  })
}

export const formatFormData = (values) => {
  let originalValues = {
    ...values,
  }

  Object.entries(values).forEach(([key, value]) => {
    if (
      [
        'issue_date',
        'pension_date',
        'contract_initial_day',
        'birthday',
      ].includes(key)
    ) {
      if (value && !(typeof value === 'string') && isValid(value)) {
        originalValues[key] = formatDate(value)
      }
    }

    if (['dependants', 'worked_months'].includes(key)) {
      originalValues[key] = value ? Number.parseInt(value, 10) : 0
    }

    if (key === 'company_participation_percentages_attributes') {
      let avistaPercentage = []
      if (
        values?.avista_percentage?.percentage === '' &&
        values?.avista_percentage?.id
      ) {
        avistaPercentage = [{ id: values?.avista_percentage?.id, _destroy: 1 }]
      } else if (
        values?.avista_percentage?.percentage !== '' &&
        !values?.avista_percentage?.id
      ) {
        avistaPercentage = [values?.avista_percentage]
      }
      originalValues[key] = removePercentagesKey([
        ...avistaPercentage,
        ...value,
      ])
    }

    if (key === 'personal_references_attributes') {
      if (value[0]?.first_name === null || value[1]?.first_name === null) {
        originalValues.personal_references_attributes = []
        return
      }

      const parsedReferences = value?.map((reference) => {
        const newReference = {
          ...reference,
          city_id: reference?.city?.id || null,
        }
        delete newReference?.department
        delete newReference?.city

        return newReference
      })

      originalValues.personal_references_attributes = parsedReferences
    }

    if (key === 'city') {
      originalValues.city_id = value?.id
    }

    if (key === 'work_city') {
      originalValues.work_city_id = value?.id
    }

    if (key === 'bank') {
      originalValues.bank_id = value?.id
    }

    if (key === 'birth_place') {
      originalValues.birth_place_id = value?.id
    }

    if (key === 'issue_place') {
      originalValues.issue_place_id = value?.id
    }

    originalValues = {
      ...originalValues,
      [key]: originalValues[key],
    }
  })

  delete originalValues?.avista_percentage
  delete originalValues?.work_city
  delete originalValues?.work_department
  delete originalValues?.department
  delete originalValues?.city
  delete originalValues?.bank
  delete originalValues?.birth_place
  delete originalValues?.birth_department
  delete originalValues?.issue_place
  delete originalValues?.issue_department

  return {
    worker_credit_data: {
      ...originalValues,
    },
  }
}
