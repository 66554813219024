import useModalExtraWorker from 'components/Subscription/Atoms/ExtraWorkersPayment/useModalExtraWorker'
import Modal from 'components/UI/Modal/Modal'
import MAlert from 'components/UI/MaterialUI/Alert/Alert'
import Dropzone from 'components/UI/Dropzone/Dropzone'
import Button from 'components/UI/Button/Button'

import Typography from '@material-ui/core/Typography'

import getWorkersUploadMessage from 'messages/workers_file'

import { getWordSingular } from 'utils/format'

const WorkerFileModal = ({ errors, onSubmitFile, onCancel }) => {
  const modalExtraWorker = useModalExtraWorker()

  const regex = /(tu plan es máximo para)/i
  const hasLimitFromWorkers = errors[0].message.match(regex)
  const workers = errors[0].message.split(' ')[5]

  const handleOpenModal = () => modalExtraWorker.openModal()

  const copiesModal = () => {
    const LIMIT_WORKERS = {
      subtitle: `Estás excediendo el límite ${workers}
      ${getWordSingular('personas', workers <= 1)} que tiene tu plan.`,
      body: getWorkersUploadMessage(workers).workersLimit,
    }

    const ERRORS_WORKERS = {
      subtitle: `${errors.length} ${getWordSingular(
        'Registros',
        errors?.length < 1
      )} con error`,
      body: getWorkersUploadMessage().download,
    }

    if (hasLimitFromWorkers) {
      return LIMIT_WORKERS
    }
    return ERRORS_WORKERS
  }
  const copiesAlert = (error) => {
    const LIMIT_WORKERS = {
      alert: {
        header: null,
        body: (
          <>
            Mejora tu plan y agrega más personas.
            <br />
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenModal}
              style={{ padding: '0px', backgroundColor: 'transparent' }}
            >
              Mejorar plan
            </Button>
          </>
        ),
      },
    }

    const ERRORS_WORKERS = {
      alert: {
        header: `${error?.worker_name} ${error?.worker_last_name}`,
        body: `${error?.column_name}: ${error?.message}.`,
      },
    }

    if (hasLimitFromWorkers) {
      return LIMIT_WORKERS
    }
    return ERRORS_WORKERS
  }

  return (
    <Modal
      open
      footer={null}
      header={
        <>
          <Typography variant="h5" gutterBottom>
            No se pudo cargar el archivo
          </Typography>
          <Typography variant="h6">{copiesModal().subtitle}</Typography>
        </>
      }
      onCancel={() => onCancel(null)}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      {errors.map((error, index) => {
        const key = `error_${index}_${error.code}`
        return (
          <MAlert
            icon={!hasLimitFromWorkers}
            severity={hasLimitFromWorkers ? 'warning' : 'error'}
            key={key}
            closable
            header={copiesAlert(error).alert.header}
          >
            {copiesAlert(error).alert.body}
          </MAlert>
        )
      })}

      <Typography style={{ maringBottom: '1em' }}>
        {copiesModal().body}
      </Typography>

      <Dropzone handleAdd={onSubmitFile} />
    </Modal>
  )
}

export default WorkerFileModal
