import DescriptionIcon from '@material-ui/icons/Description'
import SyncIcon from '@material-ui/icons/Sync'
import AddIcon from '@material-ui/icons/Add'
import RestoreIcon from '@material-ui/icons/Restore'
import RestoreAllIcon from '@material-ui/icons/GroupAdd'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import Link from 'components/UI/MaterialUI/Link'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import NoWrap from 'components/UI/NoWrap'

import { formatCurrency } from 'utils/format'
import { formatDateRange } from 'utils/dateTime'
import { isOrganizer } from 'utils/auth'

import * as routes from 'config/routes'

export const columnsData = [
  {
    Header: 'Período',
    accessor: 'initial_day',
    Cell: ({ row }) => (
      <Link to={routes.PERIOD_PAY_PREVIOUS_PAYROLL(row.original.id)}>
        {formatDateRange(row.original.initial_day, row.original.end_day)}
      </Link>
    ),
  },
  {
    Header: '#',
    accessor: 'payrolls_number',
    tooltip: 'Número de nóminas',
    customMinWidth: '80px',
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Salarios',
    accessor: 'salaries',
    customMinWidth: '150px',
    Cell: ({ row }) => formatCurrency(row.original.salaries),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Otros pagos',
    accessor: 'other_payments',
    customMinWidth: '150px',
    Cell: ({ row }) => formatCurrency(row.original.other_payments),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Prestaciones - S.S.',
    accessor: 'total_benefits',
    customMinWidth: '150px',
    Cell: ({ row }) => formatCurrency(row.original.total_benefits),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Costo total',
    accessor: 'company_cost',
    customMinWidth: '150px',
    alignHeader: 'right',
    alignCell: 'right',
    Cell: ({ row }) => <b>{formatCurrency(row.original.company_cost)}</b>,
  },
  {
    Header: 'Deduc. y Reten.',
    accessor: 'deductions_retentions',
    customMinWidth: '150px',
    alignHeader: 'right',
    alignCell: 'right',
    Cell: ({ row }) => (
      <NoWrap>({formatCurrency(row.original.deductions_retentions)})</NoWrap>
    ),
  },
]

export const getActions = (
  handleOpenConsolidatedModal,
  handleOrganizerPeriodAction,
  handleOpenNewPeriodModal,
  hasAlegraIntegration
) => {
  let actions = [
    {
      id: 'consolidated',
      icon: DescriptionIcon,
      tooltip: 'Reporte de pagos',
      isFreeAction: true,
      onClick: handleOpenConsolidatedModal,
      buttonVariant: 'outlined',
    },
    {
      id: 'previousPeriod',
      tooltip: 'Crear periodo anterior',
      isFreeAction: true,
      onClick: () => {},
      buttonProps: {
        startIcon: <AddIcon />,
        endIcon: <ExpandMoreIcon />,
      },
    },
  ]

  const confirm = useConfirm() // eslint-disable-line react-hooks/rules-of-hooks

  if (isOrganizer()) {
    actions = actions.concat([
      {
        id: 'generateNewPeriod',
        icon: AddIcon,
        tooltip: 'Generar nuevo periodo',
        isFreeAction: true,
        onClick: handleOpenNewPeriodModal,
      },
      (rowData) => ({
        id: 'recalculateAllPayroll',
        icon: RestoreAllIcon,
        tooltip: 'Recalcular nómina completo',
        onClick: () => {
          confirm({
            type: 'warning',
            title:
              '¿Desea generar nuevamente todas las nóminas de este periodo?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'generate_payrolls'),
          })
        },
      }),
      (rowData) => ({
        id: 'recalculatePayroll',
        icon: RestoreIcon,
        tooltip: 'Recalcular nómina',
        onClick: () => {
          confirm({
            type: 'warning',
            title: '¿Desea recalcular la nómina de este periodo?',
            okText: 'Continuar',
            onOk: () => handleOrganizerPeriodAction(rowData.id, 'recalculate'),
          })
        },
      }),
      (rowData) => ({
        id: 'deletePeriod',
        icon: DeleteForeverIcon,
        tooltip: 'Eliminar período',
        onClick: () => {
          confirm({
            type: 'warning',
            title: 'Eliminar período',
            description:
              'Eliminar este período es permanente y no se podrá deshacer. ¿Estás seguro?',
            okText: 'Eliminar',
            confirmCheckbox: true,
            hidden: !isOrganizer(),
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'delete_period'),
          })
        },
      }),
    ])

    if (hasAlegraIntegration) {
      actions.push((rowData) => ({
        id: 'syncAlegra',
        icon: SyncIcon,
        tooltip: 'Sincronizar periodo con Alegra',
        onClick: () => {
          confirm({
            type: 'warning',
            title:
              '¿Desea sincronizar la información de este periodo con Alegra?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'sync_with_alegra'),
          })
        },
      }))
    }
  }

  return actions
}
