import getMonth from 'date-fns/getMonth'
import { Form, Formik } from 'formik'
import { useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { isObjectEmpty } from 'utils/general'
import useSocialBenefitsService from 'utils/hooks/payroll/socialBenefits'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { formatSocialBenefits } from 'services/payroll/socialBenefitsService'

import Header from '../common/Header'
import useSocialBenefitsResponse from '../common/Modal/useSocialBenefitsResponse'
import Review from './Review/Index'
import {
  getActions,
  getColumnsData,
  getValidationSchema,
  socialBenefitTypeData,
} from './helpers'

const SocialBenefits = () => {
  const queryClient = useQueryClient()
  const [socialBenefitPaid, setSocialBenefitPaid] = useState(false)
  const { periodId, socialBenefitPeriodId } = useParams()
  const formRef = useRef(null)
  const theme = useTheme()
  const { showInfoMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const confirm = useConfirm()
  const downloadURI = useDownloadURI()
  const modalSocialBenefit = useSocialBenefitsResponse()
  const queryKey = ['getSocialBenefits', periodId]
  const {
    socialBenefitsQuery,
    socialBenefitsMutation,
  } = useSocialBenefitsService({
    serviceParams: {
      queryKey,
      periodId,
      socialBenefitsPeriodId: socialBenefitPeriodId,
    },
  })
  const {
    social_benefit_period: socialBenefitPeriod,
    social_benefits: socialBenefits,
  } = formatSocialBenefits(socialBenefitsQuery.data || {})
  const periodMonth = getMonth(socialBenefitPeriod?.initial_day)
  const { category, action, label } = socialBenefitPeriod || {}
  const { displayName, description = () => {} } =
    socialBenefitTypeData[category] || {}
  const columns = useMemo(() => getColumnsData(category), [category])
  const errorMsg = 'Mes inválido para generar pago de prestaciones sociales.'

  const handleSubmit = (values, form) => {
    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'updateSocialBenefits',
        periodId,
        socialBenefitPeriodId,
        socialBenefits: values.social_benefits,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(queryKey)
          modalSocialBenefit.openModal(category, label)
        },
        onError: (error) =>
          handleError(error, form, {
            errorsToNotificate: [{ object: 'balance' }],
          }),
      }
    )
  }

  const deleteSocialBenefits = () => {
    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'DELETE',
        periodId,
        socialBenefitPeriodId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
          setSocialBenefitPaid(false)
        },
        onError: (error) => handleError(error, formRef.current),
      }
    )
  }

  const handleDeleteSocialBenefits = () => {
    confirm({
      type: 'warning',
      title: `Eliminar el pago de ${displayName.toLowerCase()}`,
      description: `¿Estás seguro de eliminar el pago de ${displayName.toLowerCase()} de este periodo? Luego podrás volver a liquidarlo si quieres.`,
      okText: 'Eliminar pago',
      onOk: deleteSocialBenefits,
    })
  }

  const handleRecalculateSocialBenefits = () => {
    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'recalculateAllSocialBenefits',
        periodId,
        socialBenefitPeriodId,
      },
      {
        onSuccess: ({
          data: { social_benefits: defaultSocialBenefits } = {},
        }) => {
          const newSocialBenefits = defaultSocialBenefits?.map(
            (socialBenefitsItem, index) => {
              const oldSocialBenefitsItem = {
                ...formRef.current?.values?.social_benefits[index],
              }
              return {
                ...oldSocialBenefitsItem,
                ...socialBenefitsItem,
                balance:
                  socialBenefitsItem.value - oldSocialBenefitsItem?.item_value,
                item_value: oldSocialBenefitsItem?.item_value,
              }
            }
          )

          formRef.current?.setFieldValue(`social_benefits`, newSocialBenefits)
          showInfoMessage(`Los valores de ${displayName} fueron recalculados.`)
        },
      }
    )
  }

  const handleRecalculateWorkerSocialBenefits = (rowData) => {
    const { id, worker_name: workerName } = rowData

    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'recalculateWorkerSocialBenefits',
        socialBenefitId: id,
      },
      {
        onSuccess: ({ data: socialBenefit }) => {
          formRef.current?.values?.social_benefits?.forEach(
            (socialBenefitsItem, index) => {
              if (socialBenefitsItem.id === id) {
                // this is required to do when the api returns item_value with a value
                const paidValue =
                  socialBenefit.paid_value -
                  socialBenefitsItem.initial_item_value

                const newSocialBenefitsItem = {
                  ...socialBenefitsItem,
                  ...socialBenefit,
                  balance:
                    socialBenefit.value -
                    paidValue -
                    socialBenefitsItem.item_value,
                  item_value: socialBenefitsItem.item_value,
                }
                delete newSocialBenefitsItem.label

                formRef.current?.setFieldValue(
                  `social_benefits[${index}]`,
                  newSocialBenefitsItem
                )
              }
            }
          )

          showInfoMessage(
            `Los valores de ${displayName} de ${workerName} fueron recalculados.`
          )
        },
      }
    )
  }

  const handleDownloadFile = () => {
    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'GET',
        periodId,
        socialBenefitPeriodId,
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
        },
      }
    )
  }

  const handlePayBenefit = () => {
    const newSocialBenefits = formRef.current?.values?.social_benefits?.map(
      (socialBenefitsItem) => ({
        ...socialBenefitsItem,
        balance: 0,
        item_value: socialBenefitsItem.value - socialBenefitsItem.paid_value,
      })
    )

    formRef.current?.setFieldValue(`social_benefits`, newSocialBenefits)
    setSocialBenefitPaid(true)
  }

  const getBottomMessage = () => {
    if (category === 'service_bonus') return `reflejada la ${label}`
    if (category === 'severance') return `reflejadas las ${label}`
    if (category === 'severance_interests') return `reflejados los ${label}`
    return null
  }

  return (
    <Page
      documentTitle={socialBenefitPeriod ? label : 'Prestaciones sociales'}
      header={
        <Header label={label} hideRange periodInfo={socialBenefitPeriod} />
      }
      isLoading={socialBenefitsQuery.isLoading}
      isLoadingWithModal={socialBenefitsMutation.isLoading}
    >
      {!isObjectEmpty(socialBenefitsQuery.data) ? (
        <>
          <Typography paragraph>
            {description(periodMonth === 6 ? 'Diciembre' : 'Junio')}
          </Typography>
          <Review periodRange={socialBenefitPeriod} />
          <Formik
            initialValues={{ social_benefits: socialBenefits }}
            validationSchema={getValidationSchema(category)}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formRef}
          >
            {(form) => {
              const {
                values,
                handleSubmit: onSubmit,
                isSubmitting,
                dirty,
              } = form

              const enabledSubmit =
                values?.social_benefits.some(
                  (socialBenefitsItem) => socialBenefitsItem.item_value > 0
                ) || dirty

              return (
                <Form>
                  <Table
                    columns={columns}
                    data={values.social_benefits}
                    options={{
                      rowsSize: 50,
                      rowStyle: (rowData) => {
                        if (rowData.balance === 0)
                          return {
                            backgroundColor: theme.palette.grey[100],
                          }
                        return {}
                      },
                    }}
                    actions={getActions({
                      handleDownloadFile,
                      action,
                      handleRecalculateSocialBenefits,
                      form,
                      category,
                      getBottomMessage,
                      handleRecalculateWorkerSocialBenefits,
                      socialBenefitPeriod,
                      handlePayBenefit,
                      socialBenefitPaid,
                      handleSubmit: onSubmit,
                      isSubmitting,
                      enabledSubmit,
                      handleDeleteSocialBenefits,
                      dirtyForm: dirty,
                    })}
                  />
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <Alert severity="error">{errorMsg}</Alert>
      )}
    </Page>
  )
}

export default SocialBenefits
