import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { formatCurrency, formatIdNumber } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    justifySelf: 'stretch',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.grey[500],
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  itemName: {
    color: theme.palette.grey[500],
  },
}))

const TransactionStatuSummary = ({ response }) => {
  const { name, id_number, value, payment_date, date, form_key } = response
  const classes = useStyles()

  const items = [
    { label: 'Fecha de pago', value: date || payment_date },
    { label: 'Nombre de la empresa', value: name },
  ]

  if (id_number) items.push({ label: 'NIT', value: formatIdNumber(id_number) })
  if (form_key) items.push({ label: 'Clave Planilla', value: form_key })

  if (value)
    items.push({ label: 'Total', value: formatCurrency(parseFloat(value)) })

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Resumen de tu transacción
      </Typography>

      {items.map(({ label, value }, index) => (
        <div key={index} className={classes.item}>
          <Typography className={classes.itemName}>{label}</Typography>
          <Typography variant="subtitle1">{value}</Typography>
        </div>
      ))}
    </Paper>
  )
}

export default TransactionStatuSummary
