import clsx from 'clsx'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'

import defaultLogo from 'assets/images/views/common/empty_table.svg'

const useStyles = makeStyles((theme) => ({
  cell: {
    textAlign: 'center',
  },
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 214,
  },
  content: {
    maxWidth: 600,
  },
  description: {
    color: theme.palette.grey[500],
  },
  title: {
    color: theme.palette.grey[900],
    margin: theme.spacing(2, 0),
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  space: {
    marginBottom: theme.spacing(2),
  },
}))

const EmptyState = ({ colSpan, configuration = {}, loading }) => {
  const classes = useStyles()
  const {
    title,
    description,
    logo,
    callToAction,
    hideDescription,
    logoSize,
  } = configuration

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={classes.cell}>
        <div className={classes.container}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className={classes.content}>
              <img
                src={logo || defaultLogo}
                width={logoSize || 128}
                height={logoSize || 128}
                alt="Empty table logo"
                className={clsx({
                  [classes.logo]: !title,
                })}
              />

              {title ? (
                <Typography variant="h5" className={classes.title}>
                  {title}
                </Typography>
              ) : null}
              {!hideDescription ? (
                <Typography
                  variant="body1"
                  className={clsx(classes.description, {
                    [classes.space]: callToAction !== undefined,
                  })}
                >
                  {description || 'No se encontraron registros'}
                </Typography>
              ) : null}
              {callToAction || null}
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default EmptyState
