import Typography from '@material-ui/core/Typography'

import Review from './Review/Review'
import DataTable from './DataTable/DataTable'

const Payroll = ({ period, payPreviousPayroll }) => {
  return (
    <>
      <Review />
      {!payPreviousPayroll ? (
        <Typography variant="h5" gutterBottom>
          Agregar novedades
        </Typography>
      ) : null}
      <DataTable
        payPreviousPayroll={payPreviousPayroll}
        payrolls={period.payrolls}
      />
    </>
  )
}

export default Payroll
