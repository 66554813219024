import { Form, Formik } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Emoji from 'components/UI/Emoji'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { bowAndArrow, redHeart } from 'utils/emojis'

import BenefitsCard from '../BenefitsCard'
import benefits from '../helpers'

const useStyles = makeStyles((theme) => ({
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: theme.spacing(4, 0),
  },
  paper: {
    width: 480,
  },
  form: {
    marginBottom: theme.spacing(4),
  },
}))

const AddAccountantModal = ({ state, handleClose }) => {
  const { open } = state
  const classes = useStyles()

  // eslint-disable-next-line no-console
  const handleSubmit = () => console.log('Submit')

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize>
      {() => {
        return (
          <Modal
            okText="Agregar a mi contador"
            cancelText="Regresar"
            open={open}
            onCancel={handleClose}
            header={
              <Typography variant="h5">
                Tu contador te va a amar <Emoji code={bowAndArrow} />
                <Emoji code={redHeart} style={{ fontSize: '50px' }} />
              </Typography>
            }
            dialogProps={{
              classes: { paper: classes.paper },
            }}
          >
            <Typography variant="subtitle1">
              Agrega a tu contador, para:
            </Typography>
            <div className={classes.benefitsContainer}>
              {benefits.map((benefit) => {
                return (
                  <BenefitsCard
                    key={benefit.id}
                    logo={benefit.logo}
                    alt={benefit.alt}
                    title={benefit.title}
                    description={benefit.description}
                  />
                )
              })}
            </div>
            <Form className={classes.form}>
              <FormField name="name" label="Nombre completo" />
              <FormField name="email" label="Correo electrónico" />
              <FormField name="name" label="Celular" />
            </Form>
            <Typography variant="body2" color="primary">
              * Usaremos el celular para coordinar una reu y configurar todo el
              módulo contable
            </Typography>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AddAccountantModal
