import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

export const getColumnsData = (noveltyType, fromPeriod) => {
  let columnsData = []
  switch (noveltyType) {
    case 'novelties':
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
        },
        {
          Header: 'Inicio (Fecha)',
          accessor: 'initial_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.initial_date),
        },
        {
          Header: 'Fin (Fecha)',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Cantidad',
          accessor: 'quantity',
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
    case 'overtime':
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
        },
        {
          Header: 'Cantidad',
          accessor: 'quantity',
        },
        {
          Header: 'Fecha',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
    default:
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
        },
        {
          Header: 'Fecha',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
  }

  if (fromPeriod) {
    // append workerName to
    columnsData.unshift({
      Header: 'Nombre',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <Link to={routes.WORKER_SHOW(row.original.worker_id)}>
          {row.original.worker_name}
        </Link>
      ),
    })
  }

  return columnsData
}

export const tabsData = [
  {
    label: 'Vacac. Incap. y Licen.',
    key: 'novelties',
  },
  {
    label: 'Extras y Recargos',
    key: 'overtime',
  },
  {
    label: 'Ingresos Constitutivos',
    key: 'salary_income',
  },
  {
    label: 'Ingresos No Constitutivos',
    key: 'non_salary_income',
  },
  {
    label: 'Deducciones',
    key: 'deductions',
  },
]
