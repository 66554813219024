import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import DescriptionIcon from '@material-ui/icons/Description'

import HelpModal from 'components/UI/Modal/HelpModal'

import { tutorialsInformation } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(1.25),
    backgroundColor: theme.palette.primary[200],
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('lg')]: {
      maxWidth: '763px',
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  descriptionContainer: {
    maxWidth: '512px',
    paddingRight: theme.spacing(2),
  },
  playButton: {
    alignItems: 'flex-start',
    height: 'fit-content',
  },
  actionContainer: {
    display: 'flex',
    columnGap: theme.spacing(1.63),
  },
}))

const TutorialCard = ({
  children,
  title,
  description,
  tutorialNumber,
  article,
  link,
  dataCy,
}) => {
  const classes = useStyles()
  const [showTutorial, setShowTutorial] = useState(false)

  const handleCloseTutorial = () => setShowTutorial(false)

  const handleOpenTutorial = () => setShowTutorial(true)

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.descriptionContainer}>
            <Typography color="primary" variant="subtitle2" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </div>
          <ButtonBase
            component={article ? Link : ButtonBase}
            className={classes.playButton}
            onClick={!article ? handleOpenTutorial : null}
            href={article ? link : null}
            target="_blank"
            data-cy={dataCy}
          >
            {!article ? (
              <div className={classes.actionContainer}>
                <Typography color="primary" variant="subtitle1">
                  Ver tutorial
                </Typography>
                <PlayCircleOutlineIcon size={26} color="primary" />
              </div>
            ) : null}
            {article ? (
              <div className={classes.actionContainer}>
                <Typography color="primary" variant="subtitle1">
                  Ver articulo
                </Typography>
                <DescriptionIcon size={26} color="primary" />
              </div>
            ) : null}
          </ButtonBase>
        </div>
        {children}
      </div>
      {showTutorial ? (
        <HelpModal
          closeModal={handleCloseTutorial}
          modalInfo={tutorialsInformation[tutorialNumber]}
        />
      ) : null}
    </>
  )
}

export default TutorialCard
