import { Form, Formik } from 'formik'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import Typography from '@material-ui/core/Typography'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import useDecree376Service from 'utils/hooks/payroll/decree376Service'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { WORKER_INDEX } from 'config/routes'

const initialValues = {
  associated_form: '',
  payment_date: null,
}

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  associated_form: yup.string().max(10).required(),
  payment_date: yup.date().required(),
})

const DecreeSocialSecurityFileModal = ({ state, handleClose }) => {
  const downloadFile = useEnqueueDownload()
  const { decree376Mutation } = useDecree376Service({
    queryOptions: { enabled: false },
  })
  const { handleError } = useErrorHandler()
  const history = useHistory()
  const confirm = useConfirm()

  const { periodId, decreePeriodId } = state

  const getMinDate = useMemo(() => {
    return new Date(2020, 3, 1)
  }, [])
  const getMaxDate = useMemo(() => {
    return new Date(2020, 6, 31)
  }, [])

  const handleSubmit = (values) => {
    const valuesCopy = values
    valuesCopy.payment_date = formatDate(values.payment_date)

    const handleDownloadError = (error) => {
      if (error.status === 402) {
        handleError(error)
      } else if (error.errors && error.errors[0].code === '0607') {
        confirm({
          type: 'info',
          okText: 'Editar trabajadores',
          title: 'Completa la información de tus empleados',
          description: error.errors[0].message,
          onOk: () =>
            history.push({
              pathname: WORKER_INDEX(),
              state: { filter: 'not_finished' },
            }),
        })

        return true
      }

      return false
    }

    decree376Mutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'getDecree376SocialSecurityFile',
        params: { periodId, decreePeriodId, searchParams: values },
      },
      {
        onSuccess: () => {
          handleClose()

          return downloadFile({
            name: 'archivo seguridad social decreto 376',
            fileCode: 'decree_social_security_file',
            pathname: `periods/${periodId}/decree376/social_security_file/${decreePeriodId}`,
            handleError: handleDownloadError,
          })
        },
      }
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Modal
            open
            header="Generar archivo de seguridad social por corrección del Decreto 376 de 2021"
            okText="Generar"
            onOk={onSubmit}
            onCancel={handleClose}
          >
            <Typography gutterBottom>
              Se generará un archivo de corrección con las personas a las cuales
              les hiciste el aporte para 376 en este periodo para que lo subas a
              tu operador (debe ser el mismo que usaste para pagar la planilla a
              corregir).
            </Typography>
            <Form>
              <FormField
                label="Número de planilla a corregir"
                name="associated_form"
                variant="number"
                decimalScale={0}
                allowLeadingZeros
                format="##########"
              />
              <FormField
                variant="datepicker"
                name="payment_date"
                label="Fecha en que realizaste el pago de la planilla a corregir"
                minDate={getMinDate}
                maxDate={getMaxDate}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default DecreeSocialSecurityFileModal
