import { useMutation, useQuery, useQueryClient } from 'react-query'
import parseISO from 'date-fns/parseISO'

import {
  getIndexWages,
  getCurrentContract,
  createContract,
  updateWage,
  updateMassivelyWages,
  getAllWages,
  deleteWage,
} from 'services/worker/wageService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, contractId } = serviceParams

  if (queryKey === 'currentWage') return getCurrentContract(contractId)

  if (queryKey === 'indexWagesUpdate') return getIndexWages()

  if (queryKey === 'allWages') return getAllWages(contractId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, contractId, wage, wages, wageId } = mutationData

  if (mutationMethod === 'POST') return createContract(contractId, wage)

  if (mutationMethod === 'PATCH') return updateWage(wage)

  if (mutationMethod === 'PUT') return updateMassivelyWages(wages)

  if (mutationMethod === 'DELETE') return deleteWage(wageId)

  return null
}

const formatWage = (wage) => {
  const wageCopy = wage

  if (wage.initial_day) wageCopy.initial_day = parseISO(wage.initial_day)
  if (wage.end_day) wageCopy.end_day = parseISO(wage.end_day)
}

const useWageService = ({
  serviceParams = { queryKey: 'currentWage' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  const { data: { data } = {}, ...restWageResponse } = useQuery(
    [serviceParams.queryKey, serviceParams.contractId],
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      onSuccess: ({ data: wage }) => {
        if (serviceParams.queryKey === 'currentWage') {
          queryClient.setQueryData('currentWage', formatWage(wage))
        }
      },
      ...queryOptions,
    }
  )

  const wageMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    wageQuery: {
      data,
      ...restWageResponse,
    },
    wageMutation,
    formatWage,
  }
}

export default useWageService
