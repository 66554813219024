import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'

import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import messages from 'messages/payroll'

import CategoryHeader from '../../common/CategoryHeader'
import useContentStyles from '../../common/commonStyles'

const LoansTabs = ({ loanItems, handleChangeLoanValue }) => {
  const contentClasses = useContentStyles()

  return (
    <>
      <CategoryHeader conceptTooltip={messages.enable_loans} />

      {loanItems.map((loan, index) => {
        return (
          <FormControl
            className={contentClasses.itemContainer}
            key={loan.payroll_concept_id}
          >
            <Typography htmlFor={`inputLoan_${index}`} component="label">
              {loan.name}
            </Typography>

            <TextField
              id={`inputLoan_${index}`}
              value={loan.value}
              onChange={(e) => handleChangeLoanValue(e, index)}
              InputProps={{
                inputComponent: NumberFormatField,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </FormControl>
        )
      })}
    </>
  )
}

export default LoansTabs
