import { useFormikContext } from 'formik'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import useLocalStorage from '@rehooks/local-storage'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import NumberOfEmployeesField from 'components/UI/Formik/CommonFields/NumberOfEmployeesField'
import OnboardingPayrollFrequencyField from 'components/UI/Formik/CommonFields/OnboardingPayrollFrequencyField'
import LoadingBackdrop from 'components/UI/Loading/LoadingBackdrop'
import FormField from 'components/UI/Formik/FormField/Index'

import colombianPhoneNumber from 'assets/images/views/common/colombian_phone_number.png'

import { documentTypesOptions } from 'utils/company'

const useStyles = makeStyles((theme) => ({
  columnsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2.5),
    [theme.breakpoints.up(720)]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(2.5),
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2.5),
    maxWidth: 550,
    width: '100%',
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
}))

const OnboardingPersonalize = ({ loading }) => {
  const classes = useStyles()
  const { handleSubmit, setFieldValue } = useFormikContext()
  const [, setUserName] = useLocalStorage('user_name')

  const handleChangeName = ({ target: { value } }) => {
    const name = value.split(' ')[0]
    setFieldValue('user_name', value)
    setUserName(name || 'Usuario')
  }

  return (
    <div className={classes.container}>
      <LoadingBackdrop open={loading} />
      <FormField
        data-cy="user-name"
        name="user_name"
        label="¿Cómo te llamas?"
        placeholder="Escribe tu nombre completo"
        onChange={handleChangeName}
      />
      <FormField
        data-cy="phone"
        name="phone"
        variant="number"
        label="¿Cúal es tu celular?"
        placeholder="Escribe tu número de celular"
        isNumericString
        format="### ### ####"
        startAdornment={
          <img
            src={colombianPhoneNumber}
            alt="Número de teléfono Colombiano"
            width={50}
            height={20}
          />
        }
      />
      <FormField
        data-cy="company-name"
        name="name"
        label="¿Cúal es el nombre de tu empresa?"
        placeholder="Escribe el nombre o razón social de tu empresa"
      />
      <div className={classes.columnsContainer}>
        <DocumentTypeField
          label="Tipo de documento de tu empresa"
          options={documentTypesOptions}
        />
        <CompanyIdentificationNumberField label="Número de documento" />
        <NumberOfEmployeesField label="¿Cuántos empleados tienes?" />
        <OnboardingPayrollFrequencyField />
      </div>
      <Button onClick={handleSubmit}>Finalizar</Button>
    </div>
  )
}

export default OnboardingPersonalize
