import * as routes from 'config/routes'

export const getPaidSocialBenefits = (socialBenefits) => {
  return socialBenefits.filter((sb) => sb.action === 'update')
}

export const getSocialBenefitsCategoriesAlert = (socialBenefits) => {
  return socialBenefits
    .filter(
      (sb) =>
        sb.action === 'update' || (sb.action === 'create' && !sb.show_reminder)
    )
    .map((sb) => sb.category)
}

export const isSbAlertActionsShown = (
  socialBenefits,
  initial_day = new Date()
) => {
  const date = new Date(initial_day)
  const month = date.getMonth()
  const sbPaidCategories = getSocialBenefitsCategoriesAlert(socialBenefits)

  if (!sbPaidCategories.includes('severance_interests') && month === 0)
    return true
  if (!sbPaidCategories.includes('severance') && month === 1) return true
  if (
    !sbPaidCategories.includes('service_bonus') &&
    (month === 5 || month === 11)
  )
    return true
  return false
}

export const getSocialBenefitLink = (
  socialBenefits,
  periodId,
  initial_day = new Date(),
  isPreviousPeriod = false
) => {
  const month = new Date(initial_day).getMonth()
  let socialBenefitId = null

  if (month === 0)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'severance_interests'
    )[0]?.social_benefits_period_id
  else if (month === 1)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'severance'
    )[0]?.social_benefits_period_id
  else if (month === 5)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'service_bonus'
    )[0]?.social_benefits_period_id
  else if (month === 11)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'service_bonus'
    )[0]?.social_benefits_period_id

  return isPreviousPeriod
    ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
        periodId,
        socialBenefitId
      )
    : routes.PERIOD_SOCIAL_BENEFIT_PERIODS(periodId, socialBenefitId)
}
