import { useState } from 'react'

import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconsGroup: {
    display: 'flex',
  },
  passwordField: {
    fontWeight: 'bold',
  },
  sizeIcon: {
    fontSize: 18,
  },
  iconButton: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    borderRadius: 5,
    '&:focus': {
      border: 'none',
    },
  },
  overflowPassword: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const Password = ({ value }) => {
  const classes = useStyles()
  const [visibility, setVisibility] = useState(false)
  const [clipboard, setClipboard] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value)
    setClipboard(true)
    setTimeout(() => {
      setClipboard(false)
    }, 1000)
  }

  return (
    <div className={classes.container}>
      {visibility ? (
        <Typography className={classes.overflowPassword} variant="body2">
          {value}
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.passwordField}>
          **********
        </Typography>
      )}
      <div className={classes.iconsGroup}>
        <Tooltip title="Acabas de copiar la contraseña" open={clipboard} arrow>
          <IconButton
            className={classes.iconButton}
            onClick={handleCopyToClipboard}
          >
            <FileCopyOutlined className={classes.sizeIcon} />
          </IconButton>
        </Tooltip>
        <IconButton
          className={classes.iconButton}
          onClick={() => setVisibility(!visibility)}
        >
          {visibility ? (
            <VisibilityOffOutlinedIcon className={classes.sizeIcon} />
          ) : (
            <VisibilityOutlinedIcon className={classes.sizeIcon} />
          )}
        </IconButton>
      </div>
    </div>
  )
}

export default Password
