import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import { contractTypesOptions } from 'utils/worker'

const TermField = ({ contractCategory }) => {
  const form = useFormikContext()
  const { values } = form
  const options =
    !contractCategory ||
    ['employee', 'pensioner', 'part_time_contract'].includes(contractCategory)
      ? contractTypesOptions
      : contractTypesOptions.filter((option) => option.value === 'fixed')

  const handleChange = ({ target: { value } }) => {
    const { term } = values

    if ((!term || value === 'indefinite') && values.end_day)
      form.setFieldValue('end_day', null)

    form.setFieldValue('term', value)
  }

  return (
    <FormField
      name="term"
      label="Término del contrato"
      variant="select"
      options={options}
      disabled={!contractCategory}
      onChange={handleChange}
    />
  )
}

export default TermField
