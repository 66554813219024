import SvgIcon from '@material-ui/core/SvgIcon'

const GridView = (props) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <path d="M0 0V13.3333H13.3333V0H0ZM10 10H3.33333V3.33333H10V10ZM0 16.6667V30H13.3333V16.6667H0ZM10 26.6667H3.33333V20H10V26.6667ZM16.6667 0V13.3333H30V0H16.6667ZM26.6667 10H20V3.33333H26.6667V10ZM16.6667 16.6667V30H30V16.6667H16.6667ZM26.6667 26.6667H20V20H26.6667V26.6667Z" />
    </SvgIcon>
  )
}

export default GridView
