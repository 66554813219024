import { useEffect, useState } from 'react'

import { isArusDist } from 'utils/auth'
import { formatDisplayDateString, getMonthAndYear } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'

import {
  PERIOD_PAY_ELECTRONIC_PAYROLL,
  PERIOD_PAY_PAYROLL,
  PERIOD_SOCIAL_BENEFIT_PERIODS,
  SETTINGS_ELECTRONIC_PAYROLL,
  WORKER_WAGES_UPDATE,
} from 'config/routes'

const getPendingsData = (
  pendings,
  history,
  openIntegrationModal,
  hasPersons,
  withoutPersonsAction
) => {
  const {
    next_payroll: nextPayroll,
    social_security_integration: socialSecurityIntegration,
    electronic_payroll_authorized: electronicPayrollAuthorized,
    electronic_payroll_end_day: electronicPayrollEndDay,
    electronic_payroll_period_id: electronicPayrollPeriodId,
    electronic_payroll_to_issue: electronicPayrollToIssue,
    social_benefits_provision: socialBenefitsProvision,
    wages_update: wagesUpdate,
  } = pendings

  const titleSocialBenefits = {
    service_bonus: { title: 'Prima de Servicios', chipLabel: 'la Prima' },
    severance_interests: {
      title: 'Intereses a las Cesantías',
      chipLabel: 'los Int. a las Cesantías',
    },
    severance: { title: 'Cesantías', chipLabel: 'las Cesantías' },
  }

  const monthElectronicPayroll =
    `en ${getMonthAndYear(electronicPayrollEndDay)}` || ''

  return [
    {
      id: 'payroll',
      title: 'Próxima Nómina',
      chipLabel: 'Registra novedades',
      handleClick: () =>
        hasPersons
          ? history.push(PERIOD_PAY_PAYROLL())
          : withoutPersonsAction(),
      description: (
        <>
          Tiempo de hacer a tus empleados felices. <br />
          <b>
            Liquida la nómina del{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {formatDisplayDateString(nextPayroll.initial_day)}
            </span>{' '}
            al{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {formatDisplayDateString(nextPayroll.end_day)}.
            </span>
          </b>
        </>
      ),
      color: 'default',
      hidden: nextPayroll.payroll_paid,
    },
    {
      id: 'pila_integration',
      title: 'Planilla PILA',
      chipLabel: 'Realiza la integración',
      handleClick: () =>
        hasPersons
          ? openIntegrationModal({ provider: 'aportes_en_linea' })
          : withoutPersonsAction(),
      description:
        'Activa esta integración para que puedas realizar el pago de tu planilla PILA sin salir de Nominapp.',
      color: 'default',
      hidden:
        socialSecurityIntegration ||
        isArusDist() ||
        socialBenefitsProvision?.category !== null,
    },
    {
      id: 'update_salary',
      title: `Nuevo salario mínimo ${new Date().getFullYear()}`,
      chipLabel: 'Actualizar salarios mínimos',
      handleClick: () =>
        hasPersons
          ? history.push({
              pathname: WORKER_WAGES_UPDATE(),
              state: { dashboard: true },
            })
          : withoutPersonsAction(),
      description:
        'El salario mínimo subió. Haz clic aquí para ir a actualizar el salario mínimo de las personas que apliquen.',
      color: 'default',
      hidden: !wagesUpdate,
    },
    {
      id: 'social_benefits',
      title: `${
        titleSocialBenefits[socialBenefitsProvision?.category]?.title
      } ${socialBenefitsProvision?.date}`,
      chipLabel: `Liquidar ${
        titleSocialBenefits[socialBenefitsProvision?.category]?.chipLabel
      }`,
      handleClick: () =>
        hasPersons
          ? history.push({
              pathname: PERIOD_SOCIAL_BENEFIT_PERIODS(
                socialBenefitsProvision?.period_id,
                socialBenefitsProvision?.social_benefits_period_id?.id
              ),
            })
          : withoutPersonsAction(),
      description: `Haz clic aquí para liquidar y pagar ${
        titleSocialBenefits[socialBenefitsProvision?.category]?.title
      } de tu nómina en segundos con Nominapp`,
      color: 'default',
      hidden:
        isObjectEmpty(socialBenefitsProvision) ||
        socialBenefitsProvision?.category === null,
    },
    {
      id: 'electronic_payroll',
      title: '¡Habilita tu Nómina Electrónica desde Nominapp!',
      chipLabel: '¡Llegó la Nómina electrónica!',
      description:
        'En 2 clics podrás habilitar y configurar tu Nómina Electrónica desde Nominapp',
      color: 'primary',
      handleClick: () =>
        history.push({
          pathname: SETTINGS_ELECTRONIC_PAYROLL(),
          state: { dashboard: true },
        }),
      hidden: electronicPayrollAuthorized,
    },
    {
      id: 'electronic_payroll_to_issued',
      title: 'Tienes Nóminas Electrónicas sin emitir',
      chipLabel: 'Emitir Nómina Electrónica',
      description: `Haz clic para ver las personas pendientes por emitir ${monthElectronicPayroll}`,
      color: 'primary',
      handleClick: () =>
        history.push(PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPayrollPeriodId)),
      hidden:
        !electronicPayrollAuthorized ||
        !electronicPayrollToIssue ||
        !!electronicPayrollPeriodId,
    },
  ]
}

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, ':', hours, ':', minutes, ':', seconds]
}

export const useCountdown = (targetDate) => {
  const countdownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(
    countdownDate - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countdownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countdownDate])

  return getReturnValues(countDown)
}

export default getPendingsData
