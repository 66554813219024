import { Formik, Form, Field } from 'formik'
import { DatePicker } from 'formik-material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
// Temporal disable for migration
// eslint-disable-next-line import/no-extraneous-dependencies
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import Select from 'components/UI/FormikMaterialUI/Select'
import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import fileService from 'services/payroll/fileService'
import payrollService from 'services/payroll/payrollService'

import { trackEvent } from 'utils/integration'
import { formatDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import integrationEvent from 'utils/integrations/events/eventsNames'
import useErrorHandler from 'utils/hooks/useErrorHandler'

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
  },
  creditsAlertLink: {
    color: theme.palette.info.dark,
  },
}))

const BankFileModal = ({ state, handleClose }) => {
  const {
    open,
    payroll,
    period,
    paymentTypes,
    contractTypes,
    payrollRanges,
    fileFormats,
    bankName,
    locations,
  } = state
  const classes = useStyles()
  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()
  const showCreditsAlert = contractTypes?.some(
    (contractType) => contractType.value === 'payroll_credit'
  )

  const handleSubmit = async (values) => {
    const {
      payment_type: paymentType,
      file_format: fileFormat,
      pay_day: payDay,
      location,
    } = values

    const formattedPayDay = formatDate(payDay)

    const serviceCall = async () => {
      let res
      try {
        if (payroll) {
          const { payroll_range: payrollRange } = values

          res = await payrollService.getBankFile(payroll.id, {
            payroll_range: payrollRange,
            pay_day: formattedPayDay,
            payment_type: paymentType,
            file_format: fileFormat,
          })
        } else {
          const { contract_type: contractType } = values

          res = await fileService.getPeriodBankFile(period.id, {
            contract_type: contractType,
            pay_day: formattedPayDay,
            payment_type: paymentType,
            file_format: fileFormat,
            location,
          })
        }
      } catch (error) {
        handleError(error)
      }

      return res
    }

    const callback = () => {
      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: `bank`,
        type: 'PDF',
      })
    }

    handleClose()
    enqueueDownload({
      name: 'archivo pago banco',
      description: (res) => (
        <div>
          <Typography variant="subtitle2" display="inline">
            Número de personas archivo:
          </Typography>{' '}
          <Typography variant="body2" display="inline">
            {res.size}
          </Typography>
          {', '}
          <Typography variant="subtitle2" display="inline">
            Pago total archivo:
          </Typography>{' '}
          <Typography variant="body2" display="inline">
            {formatCurrency(res.total_value)}
          </Typography>
        </div>
      ),
      fileCode: 'bank_file',
      serviceCall,
      callback,
    })
  }

  const getInitialValues = () => {
    return {
      payment_type:
        paymentTypes &&
        paymentTypes.some((item) => item.value === 'payroll_social_benefit')
          ? 'payroll_social_benefit'
          : 'payroll',
      contract_type: 'payroll',
      location: 'company',
      file_format: fileFormats.length > 0 ? fileFormats[0].value : '',
      pay_day: new Date(),
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, submitForm, setValues, values }) => {
          return (
            <Modal
              open={open}
              onCancel={() => handleClose()}
              header={`Generar archivo del banco ${bankName || ' '}`}
              footer={
                <div className={classes.buttons}>
                  <Button
                    onClick={() => handleClose()}
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>

                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setValues({
                        ...values,
                        file_format: 'xlsx',
                      })

                      submitForm()
                    }}
                    startIcon={isSubmitting && <CircularProgress size={18} />}
                  >
                    Resumen en Excel
                  </Button>

                  <Button
                    disabled={isSubmitting}
                    onClick={() => submitForm()}
                    startIcon={isSubmitting && <CircularProgress size={18} />}
                    data-cy="bank_file_modal_ok"
                  >
                    Generar
                  </Button>
                </div>
              }
            >
              <Form>
                {showCreditsAlert ? (
                  <Alert
                    severity="info"
                    icon={false}
                    header="¡Tu nómina tiene deducciones por libranza!"
                  >
                    Recuerda descargar el archivo de pago en Bancos para
                    libranzas
                    <br />
                    ¡Si tienes dudas, puedes resolverlas{' '}
                    <Link
                      href="https://www.nominapp.com/blog/credito-libranza-empleados"
                      target="_blank"
                    >
                      <b className={classes.creditsAlertLink}>AQUÍ</b>
                    </Link>
                    !
                  </Alert>
                ) : null}
                <Typography gutterBottom>
                  Se generará el archivo para pagar a personas que tengan
                  seleccionado como medio de pago: &quot;Transferencia
                  Bancaria&quot;.
                </Typography>
                {paymentTypes.length > 0 && (
                  <Field
                    name="payment_type"
                    label="¿Qué pagos quieres que incluya el archivo?"
                    component={Select}
                    options={paymentTypes}
                  />
                )}
                {contractTypes.length > 0 && (
                  <Field
                    name="contract_type"
                    label="¿Vas a pagar la nómina o a los contratistas?"
                    component={Select}
                    options={contractTypes}
                  />
                )}
                {contractTypes.length > 0 && (
                  <Field
                    name="location"
                    label="¿Incluir toda la empresa o solo una sede específica?"
                    component={Select}
                    options={locations}
                  />
                )}
                {payrollRanges.length > 0 && (
                  <Field
                    name="payroll_range"
                    label="Período del archivo a generar"
                    component={Select}
                    options={payrollRanges}
                  />
                )}
                <Field
                  name="pay_day"
                  label="Fecha de aplicación del pago"
                  component={DatePicker}
                  variant="inline"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  fullWidth
                />
                {fileFormats.length > 0 && (
                  <Field
                    name="file_format"
                    label="Tipo de archivo"
                    component={Select}
                    options={fileFormats}
                  />
                )}
              </Form>
            </Modal>
          )
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  )
}

export default BankFileModal
