import clsx from 'clsx'
import { createContext, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Page from 'components/UI/Page/Page'
import useWorker from 'components/Worker/useWorker'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'

import WorkerBody from './Body/Body'
import WorkerShowNewHeader from './NewHeader/Header'
import WorkerSidebar from './Sidebar/WorkerSidebar'

export const WorkerProfileContext = createContext()
WorkerProfileContext.displayName = 'WorkerProfileContext'

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      height: '100%',
      gridTemplateColumns: '25% 1fr',
      gridTemplateRows: 'auto',
    },
  },
  page: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  section: {
    [theme.breakpoints.down('xs')]: {
      overflowY: 'initial',
    },
  },
}))

const WorkerShow = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const { worker, isLoading, isError, isFromAffiliationView } = useWorker()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId: worker.id,
    },
    queryOptions: {
      enabled: Boolean(worker.id) && isFromAffiliationView,
    },
  })

  return (
    <WorkerProfileContext.Provider value={{ activeTab, setActiveTab }}>
      <div
        className={clsx({
          [classes.container]: !isLoading && !isError,
        })}
      >
        {!isLoading && !isError && !!worker ? <WorkerSidebar /> : null}
        <Page
          documentTitle={`Ver ${worker ? worker.fullName : 'trabajador'}`}
          isLoading={isLoading && !isError}
          contentClassName={classes.page}
          className={classes.section}
        >
          {!isLoading && !isError && !!worker && (
            <>
              <WorkerShowNewHeader />
              <WorkerBody />
            </>
          )}
        </Page>
      </div>
    </WorkerProfileContext.Provider>
  )
}

export default WorkerShow
