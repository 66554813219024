import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import CreditCardNumberField from 'components/Subscription/Atoms/CreditCardNumberField'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import FormField from 'components/UI/Formik/FormField/Index'

import ContainerFields from './Fields/ContainerFields'
import CreditCardExpirationDateField from './Fields/CreditCardExpirationDate'
import OldCreditCardNumberField from './Fields/CreditCardNumber'

const useStyles = makeStyles((theme) => ({
  // TODO: Refactor these styles when the new subscription view is available to everyone
  creditCardFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '70px 140px',
  },
  creditCardFieldsNewSubscription: {
    display: 'grid',
    gridGap: theme.spacing(4.5),
    gridTemplateColumns: 'minmax(auto, 123px) 1fr',
  },
  ownerName: {
    maxWidth: 300,
  },
}))

const SettingsSubscriptionCreditCardFields = () => {
  const classes = useStyles()
  const { showNewSubscription } = useSubscription()

  return (
    <ContainerFields>
      <FormField
        name="payer_name"
        label="Nombre del titular"
        inputProps={{ autoComplete: 'cc-name' }}
        className={clsx({ [classes.ownerName]: !showNewSubscription })}
      />
      {showNewSubscription ? (
        <CreditCardNumberField />
      ) : (
        <OldCreditCardNumberField />
      )}
      <div
        className={clsx(classes.creditCardFields, {
          [classes.creditCardFieldsNewSubscription]: showNewSubscription,
        })}
      >
        <FormField
          name="security_code"
          label="CVV"
          variant="number"
          placeholder="0000"
          format="####"
          inputProps={{ autoComplete: 'cc-csc' }}
          style={{ fontFamily: 'Roboto Mono, monospace' }}
        />
        <CreditCardExpirationDateField />
      </div>
    </ContainerFields>
  )
}

export default SettingsSubscriptionCreditCardFields
