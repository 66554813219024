import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: 310,
  },
  backgroundNumber: {
    position: 'absolute !important',
    top: 65,
    right: 0,
    fontSize: '12.5rem',
    fontFamily: 'Poppins',
    fontWeight: 800,
    zIndex: -1,
    color: theme.palette.common.black,
    opacity: 0.05,
  },
  iconContainer: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    width: 64,
    height: 64,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const NumberCard = ({ title, number, icon, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.backgroundNumber}>{number}</Typography>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </div>
  )
}

export default NumberCard
