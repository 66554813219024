import { useTheme } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import { columnsData } from './helpers'

const OrganizerPartnerShowPaymentFormTable = ({ paymentsData }) => {
  const theme = useTheme()

  return (
    <Table
      data={paymentsData}
      columns={columnsData}
      options={{
        toolbar: false,
        pagination: false,
        rowStyle: (_, row) => {
          return row.index === paymentsData.length - 1
            ? {
                backgroundColor: theme.palette.grey[300],
                fontWeight: 600, // semi/demi bold
              }
            : {}
        },
      }}
    />
  )
}

export default OrganizerPartnerShowPaymentFormTable
