import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

const columnsData = [
  {
    accessor: 'number',
    Header: 'Pago #',
    Cell: ({ row }) => parseInt(row.id, 10) + 1,
    customWidth: '105px',
  },
  {
    Header: 'Fecha',
    accessor: 'date',
    Cell: ({ row }) =>
      `${formatDisplayDate(row.original.date)}${
        row.original.id === 'termination' ? ' (Liquidación)' : ''
      }`,
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    accessor: 'value',
    Header: 'Valor',
    Cell: ({ row }) => formatCurrency(row.original.value),
    alignHeader: 'center',
    alignCell: 'center',
  },
]

export default columnsData
