import { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import * as routes from 'config/routes'

import { PeriodContext } from '../Payroll'
import SocialSecurityReport from './SocialSecurity'

const Reports = () => {
  const {
    options: {
      payroll_summary_file: payrollSummaryFile,
      novelties_report: noveltiesReport,
      accounting_file: accountingFile,
    },
    openAccountingFileModal,
  } = useContext(PeriodContext)
  const { distributor } = useUser()

  const featureState = usePremiumFeature('accountingInterface')

  const handleAccoutingFileModal = () => {
    if (featureState.isPremiumFeature && distributor !== 'qenta') {
      featureState.openModal()
      return
    }
    openAccountingFileModal()
  }

  return payrollSummaryFile || noveltiesReport || accountingFile ? (
    <CardReview>
      <Typography variant="h5">Reportes</Typography>
      <ReportsNovelties />
      {distributor !== 'alegra_claro' ? (
        <Button
          variant="text"
          startIcon={<DesktopWindowsOutlinedIcon />}
          onClick={handleAccoutingFileModal}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Interfaz contable
        </Button>
      ) : null}
      <SocialSecurityReport />
    </CardReview>
  ) : null
}

export default Reports

export const ReportsNovelties = () => {
  const {
    period,
    payPreviousPayroll,
    options: { novelties_report: noveltiesReport },
  } = useContext(PeriodContext)

  return noveltiesReport ? (
    <Button
      variant="text"
      startIcon={<EventAvailableOutlinedIcon />}
      component={RouterLink}
      to={{
        pathname: payPreviousPayroll
          ? routes.PERIOD_PREVIOUS_PAYROLL_NOVELTIES_HISTORY(period.id)
          : routes.PERIOD_NOVELTIES_HISTORY(period.id),
        state: { payPreviousPayroll },
      }}
    >
      Reporte novedades
    </Button>
  ) : null
}
