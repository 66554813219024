import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import messages from 'messages/payroll'

import DateRangeContent from './DateRangeContent'

export default function Licenses({
  payroll,
  licenses,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  contract_category: contractCategory,
}) {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {contractCategory !== 'part_time_contract' ? (
        <DateRangeContent
          title="Licencia de maternidad"
          data={licenses.maternity_leave}
          onAddNovelty={onAddNovelty}
          onChangeNovelty={onChangeNovelty}
          onDeleteNovelty={onDeleteNovelty}
          noveltyType="licenses"
          noveltyCode="maternity_leave"
          contentTooltip={messages.maternity_leave}
          selectedDays={selectedDays}
          payroll={payroll}
        />
      ) : null}
      <Divider />
      {contractCategory !== 'part_time_contract' ? (
        <DateRangeContent
          title="Licencia de paternidad"
          data={licenses.paternity_leave}
          onAddNovelty={onAddNovelty}
          onChangeNovelty={onChangeNovelty}
          onDeleteNovelty={onDeleteNovelty}
          noveltyType="licenses"
          noveltyCode="paternity_leave"
          contentTooltip={messages.paternity_leave}
          selectedDays={selectedDays}
          payroll={payroll}
        />
      ) : null}
      {[
        'employee',
        'pensioner',
        'student_law_789',
        'terminated',
        'part_time_contract',
      ].includes(contractCategory) ? (
        <>
          <Divider />
          <DateRangeContent
            title="Licencia remunerada"
            data={licenses.paid_leave}
            onAddNovelty={onAddNovelty}
            onChangeNovelty={onChangeNovelty}
            onDeleteNovelty={onDeleteNovelty}
            noveltyType="licenses"
            noveltyCode="paid_leave"
            selectedDays={selectedDays}
            payroll={payroll}
          />
          <Divider />
          <DateRangeContent
            title="Licencia no remunerada"
            data={licenses.unpaid_leave}
            onAddNovelty={onAddNovelty}
            onChangeNovelty={onChangeNovelty}
            onDeleteNovelty={onDeleteNovelty}
            noveltyType="licenses"
            noveltyCode="unpaid_leave"
            contentTooltip={messages.unpaid_leave}
            selectedDays={selectedDays}
            payroll={payroll}
          />
        </>
      ) : null}
      {[
        'employee',
        'pensioner',
        'student_law_789',
        'schooling_trainee',
        'productive_trainee',
        'intern',
        'terminated',
        'part_time_contract',
      ].includes(contractCategory) ? (
        <>
          <Divider />
          <DateRangeContent
            title="Suspensión"
            data={licenses.suspension}
            onAddNovelty={onAddNovelty}
            onChangeNovelty={onChangeNovelty}
            onDeleteNovelty={onDeleteNovelty}
            noveltyType="licenses"
            noveltyCode="suspension"
            contentTooltip={messages.suspention}
            selectedDays={selectedDays}
            payroll={payroll}
          />
        </>
      ) : null}
      {[
        'employee',
        'pensioner',
        'student_law_789',
        'terminated',
        'part_time_contract',
      ].includes(contractCategory) ? (
        <>
          <Divider />
          <DateRangeContent
            title="Ausencia injustificada"
            data={licenses.job_abandonment}
            onAddNovelty={onAddNovelty}
            onChangeNovelty={onChangeNovelty}
            onDeleteNovelty={onDeleteNovelty}
            noveltyType="licenses"
            noveltyCode="job_abandonment"
            contentTooltip={messages.job_abandonment}
            selectedDays={selectedDays}
            payroll={payroll}
          />
        </>
      ) : null}
    </Box>
  )
}
