import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import EditButton from 'components/UI/Button/EditButton'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWageService from 'utils/hooks/worker/wageService'
import { filterContractFields, getMinimumSalary } from 'utils/worker'

import EndDayField from '../Fields/EndDay'
import CategoryField from '../Fields/Category'
import BaseSalaryField from '../Fields/BaseSalary'
import InitialDayField from '../Fields/InitialDay'
import TransportSubsidyField from '../Fields/TransportSubsidy'

import validationSchema from './validationSchema'

const useStyles = makeStyles({
  paper: {
    width: 650,
  },
})

const WageModal = ({
  contract, // contract that the wage to edit belongs to
  wage: initWage = {}, // the wage to create ({}) / edit ({...})
  currentWage: initCurrentWage, // the current wage for the contract
  canCreate = false,
  isEdit = false,
  onCancel,
  submitCallback,
}) => {
  const [wage, setWage] = useState(initWage)
  const [isEditing, setIsEditing] = useState(isEdit)
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const modalClasses = useStyles()

  const { wageQuery, wageMutation } = useWageService({
    serviceParams: {
      queryKey: 'currentWage',
      contractId: contract?.id,
    },
  })
  const onSuccessActions = async (message) => {
    showSuccessMessage(message)
    onCancel() // to close modal
    if (submitCallback) await submitCallback()
  }

  const catchError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { code: '0305' },
        { object: 'end_day', code: '0101' },
      ],
    })
  }

  const currentWage = initCurrentWage || (!!wageQuery.data && wageQuery.data)

  useEffect(() => {
    if (isEditing && currentWage && isObjectEmpty(wage)) setWage(currentWage)
  }, [currentWage, isEditing, wage])

  const handleSwitchRender = () => {
    setWage(isEditing ? {} : currentWage)
    setIsEditing((previousRender) => !previousRender)
  }

  const handleSubmit = (values, form) => {
    const dirtyValues = getDirtyValues(wage, values, validationSchema.fields)

    if (!isObjectEmpty(dirtyValues)) {
      dirtyValues.id = values.id

      const filteredDirtyValues = filterContractFields(
        dirtyValues,
        contract.category,
        values.category
      )

      if (filteredDirtyValues.id && isEditing) {
        wageMutation.mutate(
          {
            mutationMethod: 'PATCH',
            wage: filteredDirtyValues,
          },
          {
            onSuccess: async () => {
              await onSuccessActions(
                'El trabajador fue actualizado exitosamente'
              )
            },
          },
          {
            onError: (error) => {
              catchError(error, form)
            },
          }
        )
      } else {
        wageMutation.mutate(
          {
            mutationMethod: 'POST',
            contractId: contract.id,
            wage: filteredDirtyValues,
          },
          {
            onSuccess: async () => {
              await onSuccessActions('Se ha creado el salario exitosamente')
            },
          },
          {
            onError: (error) => {
              catchError(error, form)
            },
          }
        )
      }
    }
  }

  const initialValues = { ...wage, contract_category: contract?.category }
  // set the base_salary for the new wage with the current wage base_salary
  if (
    initialValues.base_salary === undefined &&
    currentWage &&
    currentWage.base_salary
  ) {
    initialValues.base_salary = currentWage.base_salary
    if (initialValues.base_salary < getMinimumSalary({ multiplier: 2 }))
      initialValues.transport_subsidy = true
  }

  if (isObjectEmpty(currentWage)) {
    return (
      <LoadingBox /> // wait for currentWage to be available
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(form) => {
        const { handleSubmit: onSubmit } = form

        return (
          <Modal
            header={isEditing ? 'Editar salario actual' : 'Crear salario'}
            open
            onOk={onSubmit}
            onCancel={onCancel}
            okText="Guardar"
            isLoading={wageMutation.isLoading}
            dialogProps={{ maxWidth: false, classes: modalClasses }}
          >
            {!isEditing && (
              <Typography>
                Podrás crear un nuevo salario para la persona que comienza a
                aplicar desde la fecha inicial, con el valor que registres. Una
                vez guardes este nuevo salario, Nominapp actualiza las nóminas
                en las que aplique según las fechas seleccionadas.
              </Typography>
            )}
            <Form>
              <BaseSalaryField
                contractCategory={contract?.category}
                isEditing={isEditing}
              />
              <CategoryField contractCategory={contract?.category} />
              <InitialDayField
                currentWage={currentWage}
                contractInitialDay={contract?.initial_day}
              />
              <EndDayField />
              <TransportSubsidyField contractCategory={contract?.category} />
            </Form>
            {canCreate && currentWage && (
              <EditButton
                title={
                  isEditing ? 'Crear nuevo salario' : 'Editar salario actual'
                }
                onClick={handleSwitchRender}
                showTitleOnMobile
              />
            )}
          </Modal>
        )
      }}
    </Formik>
  )
}

export default WageModal
