import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'

import AddButton from 'components/UI/Button/AddButton'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import OptionSelector from '../../../../../common/OptionSelector'
import CategoryHeader from '../../common/CategoryHeader'
import useContentStyles from '../../common/commonStyles'

const AvistaConcepts = ({ name, value, id }) => {
  const contentClasses = useContentStyles()
  return (
    <FormControl className={contentClasses.avistaItemContainer}>
      <Typography htmlFor="avista" component="label">
        {name}
      </Typography>
      <TextField
        id={id}
        value={value}
        disabled
        InputProps={{
          inputComponent: NumberFormatField,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </FormControl>
  )
}

const DeductionsTab = ({
  activeTab,
  withholdingTaxInfo,
  tabData,
  handleSelectItem,
  handleChangeItemValue,
  handleDeleteItem,
  handleAddConcept,
  setIsCreatePayrollConceptModalOpen,
}) => {
  const { deductions, concepts } = tabData
  const { withholdingTaxItemIndex } = withholdingTaxInfo

  // Get the index for avista deductions
  const avistaPayrollCreditItemIndex = deductions?.findIndex(
    (ded) => ded.coded_name === 'avista_payroll_credit'
  )
  const avistaPayrollAdvanceItemIndex = deductions?.findIndex(
    (ded) => ded.coded_name === 'avista_payroll_advance'
  )

  const getIndexToIgnore = () => {
    const indexArray = []
    if (withholdingTaxItemIndex !== -1) {
      indexArray.push(withholdingTaxItemIndex)
    }
    if (avistaPayrollCreditItemIndex !== -1) {
      indexArray.push(avistaPayrollCreditItemIndex)
    }
    if (avistaPayrollAdvanceItemIndex !== -1) {
      indexArray.push(avistaPayrollAdvanceItemIndex)
    }

    return indexArray
  }

  const indexToIgnore = getIndexToIgnore()

  // Get the object for avista deductions
  const avistaPayrollCreditItem = deductions[avistaPayrollCreditItemIndex]
  const avistaPayrollAdvanceItem = deductions[avistaPayrollAdvanceItemIndex]

  return (
    <>
      <CategoryHeader />
      {avistaPayrollCreditItem?.value > 0 ? (
        <AvistaConcepts
          name={avistaPayrollCreditItem.name}
          value={avistaPayrollCreditItem.value}
          id="avistaPayrollCreditInputValue"
        />
      ) : null}
      {avistaPayrollAdvanceItem?.value > 0 ? (
        <AvistaConcepts
          name={avistaPayrollAdvanceItem.name}
          value={avistaPayrollAdvanceItem.value}
          id="avistaPayrollCreditInputValue"
        />
      ) : null}
      <div id={`${activeTab}-items`}>
        <OptionSelector
          data={deductions}
          listOptions={concepts}
          onSelectItem={handleSelectItem}
          onChangeItemValue={handleChangeItemValue}
          onDeleteItem={handleDeleteItem}
          indexToIgnore={indexToIgnore}
        />
      </div>

      <Box display="flex">
        <AddButton
          onClick={() => handleAddConcept()}
          disabled={concepts.length < deductions.length}
        >
          Agregar concepto
        </AddButton>

        <Button
          variant="text"
          startIcon={<OpenInNewOutlinedIcon />}
          onClick={() => {
            setIsCreatePayrollConceptModalOpen(true)
          }}
        >
          Crear nuevo concepto
        </Button>
      </Box>
    </>
  )
}

export default DeductionsTab
