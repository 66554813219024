import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getWorkerLoans } from 'services/worker/workerProfileService'
import { getLoans, updateLoans } from 'services/worker/loansService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getLoans') return getLoans(workerId)

  if (currentQueryKey === 'getWorkerLoans') return getWorkerLoans(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, loans } = mutationData

  if (mutationMethod === 'PUT')
    return updateLoans({
      workerId,
      loans,
    })

  return null
}

const useLoansService = ({
  serviceParams = {},
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restLoansResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const loansMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    loansQuery: {
      data,
      ...restLoansResponse,
    },
    loansMutation,
  }
}

export default useLoansService
