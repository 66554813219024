import { useState } from 'react'
import parseISO from 'date-fns/parseISO'

import { eachDayOfInterval } from 'utils/dateTime'

const useNoveltiesModal = () => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const getNovelties = (noveltiesConcepts = {}, payrollNovelties = []) => {
    const novelties = {} // Includes novelty concept data with items by category

    // This assigns every novelty item with the respective novelty concept
    Object.entries(noveltiesConcepts).forEach(([category, conceptsGroup]) => {
      const temporalConcepts = {}
      conceptsGroup.map((concept) => {
        // Ignore compensated days
        if (concept.coded_name !== 'compensated_days') {
          const newConcept = {
            ...concept,
            items: [],
          }

          payrollNovelties.forEach((payrollNovelty) => {
            if (payrollNovelty.payroll_concept_id === newConcept.id) {
              newConcept.items.push(payrollNovelty)
            }
          })

          if (newConcept.items.length === 0) {
            newConcept.items.push({
              initial_day: '',
              end_day: '',
            })
          }

          temporalConcepts[newConcept.coded_name] = newConcept
        }
        return null
      })

      novelties[category] = temporalConcepts
    })

    return novelties
  }

  // Filters only novelties items with selected dates
  const getSelectedDays = (novelties = {}) => {
    const currentNovelties = {}

    Object.entries(novelties).forEach(
      ([noveltyCategory, noveltyCategoryGroup]) => {
        if (Object.prototype.hasOwnProperty.call(novelties, noveltyCategory)) {
          currentNovelties[noveltyCategory] = {}

          Object.entries(noveltyCategoryGroup).forEach(
            ([noveltyCode, noveltyGroup]) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  noveltyCategoryGroup,
                  noveltyCode
                )
              ) {
                currentNovelties[noveltyCategory][noveltyCode] = []

                noveltyGroup.items.forEach((noveltyItem) => {
                  const {
                    initial_day: initialDay,
                    end_day: endDay,
                  } = noveltyItem

                  if (initialDay && endDay) {
                    currentNovelties[noveltyCategory][noveltyCode].push(
                      eachDayOfInterval(parseISO(initialDay), parseISO(endDay))
                    )
                  } else if (initialDay) {
                    currentNovelties[noveltyCategory][noveltyCode].push([
                      parseISO(initialDay),
                    ])
                  } else if (endDay) {
                    currentNovelties[noveltyCategory][noveltyCode].push([
                      parseISO(endDay),
                    ])
                  }
                })
              }
            }
          )
        }
      }
    )

    return currentNovelties
  }

  return {
    showModal,
    handleOpenModal,
    handleCloseModal,
    getNovelties,
    getSelectedDays,
  }
}

export default useNoveltiesModal
