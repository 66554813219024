import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import ToolbarActions from './ToolbarActions'
import ToolbarSearch from './ToolbarSearch'

const ToolbarElectronicPayroll = (disabledButtonSubmit, tooltipTitle) => (
  props
) => {
  return (
    <ToolbarContainer {...props}>
      <ToolbarSearch {...props} />
      <ToolbarActions
        disabledButton={disabledButtonSubmit}
        tooltipTitle={tooltipTitle}
      />
    </ToolbarContainer>
  )
}

export default ToolbarElectronicPayroll
