import { makeStyles } from '@material-ui/core/styles'

import useWorker from 'components/Worker/useWorker'

import HolidaysContent from './HolidaysContent'
import RecurrentConceptsContent from './RecurrentConceptsContent/RecurrentConceptsContent'
import SalaryContent from './SalaryContent'

const useStyles = makeStyles((theme) => ({
  headerSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    gap: `${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(-4),
      gridTemplateColumns: '1fr 3fr 1fr auto',
    },
  },
  marginElement: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: theme.spacing(4),
      marginLeft: theme.spacing(-3),
      background: 'transparent',
      height: '100%',
    },
  },
}))

const WorkerShowNewHeader = () => {
  const { worker } = useWorker({ useCache: true })
  const classes = useStyles()

  return (
    <div className={classes.headerSection}>
      <SalaryContent />
      <RecurrentConceptsContent />
      <HolidaysContent
        disabled={
          worker.terminated ||
          (worker.contract_category !== 'employee' &&
            worker.contract_category !== 'pensioner')
        }
      />
      <div className={classes.marginElement} />
    </div>
  )
}

export default WorkerShowNewHeader
