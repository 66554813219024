import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

export default () => {
  return (
    <Alert severity="info">
      <Typography variant="body2">
        El auxilio de conectividad es una medida temporal y transitoria del
        Gobierno en reemplazo del auxilio de transporte para empleados que
        reciben hasta dos salarios mínimos.
      </Typography>
    </Alert>
  )
}
