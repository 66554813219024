import { useFormikContext } from 'formik'
import { useState } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import { risks } from 'utils/worker'

import { formatActivityData } from './helpers'

const ActivityCIIU = () => {
  const companyId = getCompanyId()
  const [changeDate, setChangeDate] = useState('')
  const [searchCiiu, setSearchCiiu] = useState('')
  const { setFieldValue, values } = useFormikContext()
  const initialDate = formatDate(values?.initial_day)

  const { companyWorkCentersQuery: CIIUQuery } = useCompanyWorkCenters({
    serviceParams: {
      queryKey: ['getCIIU', companyId],
      searchParams: {
        filter: initialDate,
        per_page: '1000',
        search: searchCiiu,
      },
    },
    queryOptions: {
      enabled: initialDate !== changeDate || searchCiiu.length > 2,
      onSuccess: () => {
        setChangeDate(initialDate)
      },
    },
  })

  const onChange = (__, ciiu) => {
    const riskType = CIIUQuery.data?.find?.(({ id }) => id === ciiu?.id)
      ?.risk_type

    setFieldValue('risk_type', risks[riskType])
    setFieldValue('activity_code_id', ciiu)
  }

  const handleChange = (__, value) => setSearchCiiu(value)

  return (
    <FormField
      name="activity_code_id"
      variant="autocomplete"
      label="Actividad CIIU"
      placeholder="Actividad CIIU"
      options={formatActivityData(CIIUQuery.data) || []}
      onChange={onChange}
      onInputChange={handleChange}
    />
  )
}

export default ActivityCIIU
