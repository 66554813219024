import { useContext } from 'react'

import ReceiptIcon from '@material-ui/icons/Receipt'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Button from 'components/UI/Button/Button'

import socialBenefitsService from 'services/payroll/socialBenefitsService'

import { PeriodContext } from '../Payroll'
import getSeverance from './common/helpers'

const SocialSecurityReport = () => {
  const { enqueueDownload } = useDownloadManager()
  const {
    period: { id: periodId },
    options: { social_benefits: socialBenefits },
    payPreviousPayroll,
  } = useContext(PeriodContext) || {}

  const severance = getSeverance(socialBenefits)

  const handleDownloadFile = async () => {
    const serviceCall = async () =>
      socialBenefitsService.getFile(periodId, severance.socialBenefitPeriodId)

    enqueueDownload({
      name: 'resumen de cesantías',
      fileCode: 'social_benefits_file',
      serviceCall,
    })
  }

  return severance.hasSeverance && !payPreviousPayroll ? (
    <Button
      variant="text"
      startIcon={<ReceiptIcon />}
      onClick={handleDownloadFile}
    >
      Resumen cesantías
    </Button>
  ) : null
}

export default SocialSecurityReport
