import { useFormikContext } from 'formik'

import { FormHelperText } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DeleteItemButton from 'components/UI/Button/DeleteIconButton'
import Dropzone from 'components/UI/Dropzone/Dropzone'

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    marginTop: theme.spacing(7),
    '& > p': {
      margin: theme.spacing(2, 0),
    },
  },
  logoSection: {
    marginTop: theme.spacing(4.25),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '130px',
    height: '80px',
    padding: '1.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImage: {
    objectFit: 'contain',
  },
}))

const LogoFormField = () => {
  const classes = useStyles()
  const form = useFormikContext()

  const onAddLogo = (file) => {
    if (file instanceof Blob) {
      form.setFieldValue('logo', file)

      const reader = new FileReader()

      reader.onload = (e) =>
        document
          .getElementById('company_logo_preview')
          .setAttribute('src', e.target.result)

      reader.readAsDataURL(file)
    }
  }

  const onRemoveLogo = () => form.setFieldValue('logo', null)

  const { values: { logo } = {}, errors = {} } = form

  return (
    <section className={classes.sectionContainer}>
      <Typography variant="h6" color="primary" className={classes.title}>
        Logo de la empresa
      </Typography>
      <Typography variant="body2">
        Agrega el logo de tu empresa para que aparezca en las colillas de pago y
        liquidaciones, Para que tu logo se vea bien, debe tener 110 píxeles de
        ancho y 37 píxeles de alto. Su tamaño no debe superar los 200KB.
      </Typography>
      <div className={classes.logoSection}>
        <div className={classes.preview}>
          <div className={classes.logo}>
            {logo ? (
              <img
                id="company_logo_preview"
                src={logo}
                width={110}
                height={37}
                className={classes.logoImage}
                alt="Logo de la empresa"
              />
            ) : (
              <p>Sin Logo</p>
            )}
          </div>

          {logo && (
            <DeleteItemButton
              onClick={onRemoveLogo}
              style={{ marginLeft: 8 }}
            />
          )}
        </div>
        <div>
          <Dropzone
            value={logo?.name}
            type="inline"
            accept="image/png, image/jpeg, image/x-icon"
            handleAdd={onAddLogo}
          />
          {errors?.logo ? (
            <FormHelperText error>{errors?.logo}</FormHelperText>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default LogoFormField
