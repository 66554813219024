import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import { getDaysInMonth, isValid, parseISO } from 'date-fns'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'

import { getMonthDays, getMonths } from 'utils/dateTime'

import AutocompleteField from './AutocompleteField'

const useStyles = makeStyles((theme) => ({
  datepicker: {
    marginTop: 0,
    marginBottom: 0,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '25% 25% 1fr',
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      columnGap: theme.spacing(1),
    },
  },
  hasPopupIcon: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: theme.spacing(3.75),
    },
  },
  yearButton: {
    backgroundColor: 'transparent',
    color: 'inherit',
    '&.MuiIconButton-root.Mui-disabled': {
      border: 'none',
    },
    '&:focus': {
      border: 'none',
      backgroundColor: 'transparent',
      color: 'inherit',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  yearToolbar: {
    '& .MuiPickersToolbar-toolbar': {
      height: 'auto',
    },
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: '100%',
      width: 110,
      padding: 0,
      minHeight: 'auto',
    },
    '& .MuiPickersToolbarText-toolbarBtnSelected': {
      fontSize: 24,
    },
  },
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
    },
  },
}))

const getDateSegments = (date) => {
  if (date && date instanceof Date) {
    const year = date.getFullYear()?.toString()
    const month =
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

    return {
      year: String(year),
      month: String(month),
      day: String(day),
    }
  }

  if (date && typeof date === 'string') {
    const splittedValue = date ? date.split('-') : []
    const [year, month, day] = splittedValue
    if (splittedValue && splittedValue?.length === 3) {
      return {
        day: String(day),
        month: String(month),
        year: String(year),
      }
    }
  }

  return {
    day: '',
    month: '',
    year: '',
  }
}

const isDateCompleted = (values) =>
  Object.entries(values).every(([key, value]) => {
    if (key === 'year') {
      return (value && value?.length === 4) || false
    }
    if (key === 'month') return Boolean(value)
    return true
  })

const validateDate = (dateValues, minDate, maxDate, field) => {
  let values = { ...dateValues }

  if (field === 'month') {
    const baseDate = new Date(`${+values.year}/${+values.month}/1`)
    const daysInMonth = getDaysInMonth(baseDate)
    if (+values.day > daysInMonth) {
      values = { ...values, day: '' }
    }
  }

  if ((minDate || maxDate) && isDateCompleted(values)) {
    const nextDate = new Date(`${values.year}/${values.month}/${values.day}`)

    if (field === 'year') {
      if (minDate) {
        if (nextDate < minDate) {
          values = {
            ...values,
            month: '',
            day: '',
          }
          return values
        }
      }

      if (maxDate) {
        if (nextDate > maxDate) {
          values = {
            ...values,
            month: '',
            day: '',
          }
          return values
        }
      }
    }
  }

  return values
}

const DatePickerField = ({ id, name, value, ...props } = {}) => {
  const localClasses = useStyles()
  const [focused, setFocused] = useState(false)
  const {
    setValue,
    setTouched,
    disabled,
    className,
    datepickerProps = {},
    minDate,
    maxDate,
    onTryChange,
    showError,
  } = props
  const [values, setValues] = useState(() => getDateSegments(value))
  const dayId = `${name}_day_id`
  const monthId = `${name}_month_id`
  const yearId = `${name}_year_id`
  const dayDataCy = `${name}_field_day`
  const monthDataCy = `${name}_field_month`
  const yearDataCy = `${name}_field_year`

  useEffect(() => {
    setValues(getDateSegments(value))
  }, [value])

  const days = useMemo(() => {
    const currentDate = new Date(`${values.year}/${values.month}/01`)
    const daysNumber = isValid(currentDate) ? getDaysInMonth(currentDate) : 31
    let allDays = getMonthDays(daysNumber)
    const { day: minDay, month: minMonth, year: minYear } = getDateSegments(
      minDate
    )
    const { day: maxDay, month: maxMonth, year: maxYear } = getDateSegments(
      maxDate
    )

    if (minDate && minDay) {
      if (+minMonth === +values.month && +minYear === +values.year) {
        allDays = allDays?.filter((dayItem) => +dayItem?.id >= +minDay)
      }
    }

    if (maxDate && maxDay) {
      if (+maxMonth === +values.month && +maxYear === +values.year) {
        allDays = allDays?.filter((dayItem) => +dayItem?.id <= +maxDay)
      }
    }

    return allDays
  }, [maxDate, minDate, values])
  const months = useMemo(() => {
    let allMonths = getMonths()
    const { month: minMonth, year: minYear } = getDateSegments(minDate)
    const { month: maxMonth, year: maxYear } = getDateSegments(maxDate)

    if (minDate && minMonth) {
      if (+minYear === +values.year) {
        allMonths = allMonths?.filter(
          (monthItem) => +monthItem?.id >= +minMonth
        )
      }
    }

    if (maxDate && maxMonth) {
      if (+maxYear === +values.year) {
        allMonths = allMonths?.filter(
          (monthItem) => +monthItem?.id <= +maxMonth
        )
      }
    }

    return allMonths
  }, [maxDate, minDate, values.year])
  const minDateYear = useMemo(() => {
    const { year: minYear } = getDateSegments(minDate)
    return minYear || null
  }, [minDate])
  const maxDateYear = useMemo(() => {
    const { year: maxYear } = getDateSegments(maxDate)
    return maxYear || null
  }, [maxDate])

  const onClose = () => setFocused(false)

  const handleUpdateFieldValue = (currentValues) => {
    if (currentValues.day && currentValues.month && currentValues.year) {
      const date = new Date(
        +currentValues.year,
        +currentValues.month - 1,
        +currentValues.day
      )
      if (isValid(date)) {
        setValue(date)
        setTouched(true)
      }
    }
  }

  const handleUpdateInput = (dateSegment, selectedValue) => {
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [dateSegment]: selectedValue?.id,
      }
      const validatedValues = validateDate(
        newValues,
        minDate,
        maxDate,
        dateSegment
      )
      handleUpdateFieldValue(validatedValues)
      return validatedValues
    })
  }

  const handleUpdateYear = (_, year) => {
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        year,
      }
      const validatedValues = validateDate(newValues, minDate, maxDate, 'year')
      handleUpdateFieldValue(validatedValues)
      return validatedValues
    })
  }

  const handleFocus = (field) => {
    if (onTryChange && !values[field]) {
      onTryChange()
    }
  }

  const onOpen = () => {
    handleFocus()
    setFocused(true)
  }

  return (
    <div className={localClasses.container}>
      <FormControl margin="none">
        <AutocompleteField
          id={dayId}
          placeholder="Día"
          options={days}
          disabled={disabled}
          value={values.day || null}
          error={(showError && !values.day) || false}
          getOptionSelected={(option, selectedValue) =>
            option.id === selectedValue ? option : null
          }
          getOptionLabel={(option) =>
            option?.name ? option?.name : option?.replace(/^0+/, '')
          }
          disableClearable
          onBlur={() => {}} // This is to override the default behavior of the AutocompleteField's onBlur event
          onChange={(_, selectedValue) =>
            handleUpdateInput('day', selectedValue)
          }
          onFocus={() => handleFocus('day')}
          classes={{
            hasPopupIcon: localClasses.hasPopupIcon,
            inputRoot: localClasses.inputRoot,
          }}
          dataCy={dayDataCy}
        />
      </FormControl>
      <FormControl margin="none">
        <AutocompleteField
          id={monthId}
          options={months}
          placeholder="Mes"
          disabled={disabled}
          error={(showError && !values.month) || false}
          disableClearable
          value={values.month || null}
          getOptionSelected={(option, selectedValue) =>
            option.id === selectedValue ? option : null
          }
          getOptionLabel={(option) => {
            if (option?.name) return option?.name
            return months?.find((month) => month.id === option)?.name
          }}
          onBlur={() => {}} // This is to override the default behavior of the AutocompleteField's onBlur event
          onChange={(_, selectedValue) =>
            handleUpdateInput('month', selectedValue)
          }
          onFocus={() => handleFocus('month')}
          classes={{
            hasPopupIcon: localClasses.hasPopupIcon,
            inputRoot: localClasses.inputRoot,
          }}
          dataCy={monthDataCy}
        />
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <KeyboardDatePicker
          id={yearId}
          value={values.year ? parseISO(values.year) : null}
          views={['year']}
          variant="inline"
          onChange={handleUpdateYear}
          disabled={disabled}
          focused={focused}
          format="yyyy"
          autoOk
          onOpen={onOpen}
          onClose={onClose}
          maxDateMessage={`El año debe ser menor o igual a ${
            maxDateYear || 2099
          }`}
          minDateMessage={`El año debe ser mayor o igual a ${
            minDateYear || 1899
          }`}
          minDate={minDateYear ? parseISO(minDateYear.toString()) : undefined}
          maxDate={maxDateYear ? parseISO(maxDateYear.toString()) : undefined}
          invalidDateMessage={null}
          InputProps={{
            placeholder: 'Año',
            onFocus: () => {
              setFocused(true)
              handleFocus('year')
            },
            onBlur: () => setFocused(false),
            error: (showError && !values.year) || false,
            'data-cy': yearDataCy,
          }}
          className={clsx(localClasses.datepicker, className)}
          KeyboardButtonProps={{
            size: 'small',
            className: localClasses.yearButton,
            id: `${yearId}-button`,
          }}
          PopoverProps={{
            classes: {
              root: localClasses.yearToolbar,
            },
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PaperProps: {
              id: `${yearId}-popover`,
            },
          }}
          {...datepickerProps}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DatePickerField
