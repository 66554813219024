import Button from 'components/UI/Button/Button'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const EditButton = ({
  title = 'Editar',
  showTitleOnMobile = false,
  customBreakText = null,
  ...props
}) => {
  const mediaQueryXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const xsDown = customBreakText !== null ? customBreakText : mediaQueryXs

  return (
    <Button variant="text" startIcon={<EditOutlinedIcon />} {...props}>
      {(!xsDown || showTitleOnMobile) && title}
    </Button>
  )
}

export default EditButton
