import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { formatCurrency } from 'utils/format'

import ExtraChargeFields from './ExtraChargeFields'

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    width: 700,
  },
  summaryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  buttonLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
}))

const OrganizerPartnerShowPaymentFormSummary = ({ summary = {}, values }) => {
  const classes = useStyles()

  const totalValue = () => {
    const {
      payment_extra_charge: paymentExtraCharge,
      extra_charge_type: extraChargeType,
    } = values

    let discountValue = paymentExtraCharge || 0

    if (extraChargeType === 'percentage')
      discountValue = summary.total_value * (paymentExtraCharge / 100)

    return formatCurrency(summary.total_value + discountValue)
  }

  return (
    <>
      {summary.base && (
        <div className={classes.summaryItem}>
          <Typography variant="subtitle1">Base:</Typography>
          <Typography>{formatCurrency(summary.base)}</Typography>
        </div>
      )}

      <div className={classes.summaryItem}>
        <Typography variant="subtitle1">Descuento Base:</Typography>
        <Typography>
          {summary.base_discount_type === 'value'
            ? formatCurrency(summary.base_discount)
            : `${summary.base_discount} %`}
        </Typography>
      </div>

      <div className={classes.summaryItem}>
        <Typography variant="subtitle1">
          Descuento/Recargo sobre la factura:
        </Typography>
        <ExtraChargeFields />
      </div>

      {summary.total_value && (
        <div className={classes.summaryItem}>
          <Typography variant="subtitle1">Total:</Typography>
          <Typography variant="subtitle1">{totalValue()}</Typography>
        </div>
      )}
    </>
  )
}

export default OrganizerPartnerShowPaymentFormSummary
