import PoppinsRegularTtf from 'assets/fonts/Poppins-Regular.ttf'
import PoppinsBoldTtf from 'assets/fonts/Poppins-Bold.ttf'
import PoppinsSemiBoldTtf from 'assets/fonts/Poppins-SemiBold.ttf'

import generateFont from './utils'

function generatePoppins(fontWeight, url) {
  return generateFont({
    fontFamily: 'Poppins',
    fontWeight,
    url,
  })
}

const poppinsRegular = generatePoppins(400, PoppinsRegularTtf)
const poppinsSemiBold = generatePoppins(600, PoppinsSemiBoldTtf)
const poppinsBold = generatePoppins(700, PoppinsBoldTtf)

export default [poppinsRegular, poppinsSemiBold, poppinsBold]
