import { Form, Formik } from 'formik'
import { useContext } from 'react'
import * as yup from 'yup'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import PaymentContext from 'components/Subscription/Atoms/PaymentContext'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  base_workers: yup.number(),
  paid_extra_workers: yup.number().positive().required(),
})

const ExtraWorkersModal = () => {
  const {
    selectedPlan,
    setSelectedPlan,
    setIsExtraWorkerModalOpen,
  } = useContext(PaymentContext)
  const { subscriptionMutation } = useSubscriptionService()

  const handleSubmit = ({ paid_extra_workers: paidExtraWorkers }) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'GET',
        planId: selectedPlan.id,
        extraWorkers: paidExtraWorkers,
      },
      {
        onSuccess: ({ data }) => {
          setSelectedPlan({
            ...selectedPlan,
            ...data,
            paid_extra_workers: paidExtraWorkers,
            name: 'Nominapp Plan Empresarial Plus',
          })
          setIsExtraWorkerModalOpen(false)
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        base_workers: 80,
        paid_extra_workers: selectedPlan.paid_extra_workers,
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, values, setFieldValue }) => {
        const {
          paid_extra_workers: paidExtraWorkers,
          base_workers: baseWorkers,
        } = values

        return (
          <Modal
            open
            header="Empleados extra"
            onOk={onSubmit}
            onCancel={() => setIsExtraWorkerModalOpen(false)}
            okText="Aceptar"
          >
            <Form>
              <FormField
                name="base_workers"
                type="number"
                label="Cantidad de empleados base"
                disabled
              />
              <FormField
                name="paid_extra_workers"
                type="number"
                label="Cantidad de empleados extra"
                autoComplete="off"
                onChange={({ target: { value } }) =>
                  setFieldValue('paid_extra_workers', value >= 0 ? value : 0)
                }
                autoFocus
              />
            </Form>

            <Box
              maxWidth="95%"
              display="flex"
              justifyContent="space-between"
              paddingTop={1}
            >
              <Typography variant="h5">Total empleados:</Typography>
              <Typography variant="h5">
                {parseInt(baseWorkers, 10) +
                  (parseInt(paidExtraWorkers, 10) || 0)}
              </Typography>
            </Box>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default ExtraWorkersModal
