import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  extra_charge_type: yup.string().required(),
  payment_extra_charge: yup.number().required(),
})

export const columnsData = [
  {
    Header: 'Empresa',
    accessor: 'company_name',
  },
  {
    Header: 'No. Empleados',
    accessor: 'payrolls_number',
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Valor por empleado',
    accessor: 'worker_value',
    Cell: ({ row }) => formatCurrency(row.original.worker_value),
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Descuento',
    accessor: 'discount',
    Cell: ({ row }) => `${row.original.discount}%`,
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Total',
    accessor: 'total_value',
    Cell: ({ row }) => formatCurrency(row.original.total_value),
    alignCell: 'right',
    alignHeader: 'right',
    customWidth: '150px',
  },
]

export const getInitialValues = (values = {}) => ({
  extra_charge_type: values.extra_charge_type,
  payment_extra_charge: values.payment_extra_charge,
})

export function formatPartnerPaymentData(paymentInfo) {
  const { data } = paymentInfo
  const partnerInformation = { ...paymentInfo.partner_info }

  let subtotal = {
    company_name: 'Subtotal',
    payrolls_number: 0,
    discount: 0,
    worker_value: 0,
    total_value: 0,
  }
  if (data.length > 0) {
    subtotal = data.reduce(
      (accum, elem) => {
        return {
          ...accum,
          payrolls_number: accum.payrolls_number + elem.payrolls_number,
          discount: accum.discount + elem.discount,
          worker_value: accum.worker_value + elem.worker_value,
          total_value: accum.total_value + elem.total_value,
        }
      },
      {
        company_name: 'Subtotal',
        payrolls_number: 0,
        discount: 0,
        worker_value: 0,
        total_value: 0,
      }
    )

    subtotal.discount /= data.length
    subtotal.worker_value /= data.length
  }

  partnerInformation.total_value -= partnerInformation.payment_extra_charge

  return {
    data: [...data, subtotal],
    partner_info: partnerInformation,
  }
}
