import { forwardRef } from 'react'
import { useSnackbar } from 'notistack'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.success.main}`,
    borderLeft: `8px solid ${theme.palette.success.main}`,
    borderRadius: 4,
  },
  alert: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.success.light,
    '& .MuiSnackbarContent-action .MuiIconButton-root': {
      color: theme.palette.success.dark,
    },
    color: theme.palette.success.dark,
  },
  icon: {
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  description: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
  },
}))

const AppDownloadSnackbar = forwardRef(({ id, message }, ref) => {
  const { title, description, onClose } = message
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = () => closeSnackbar(id)

  return (
    <div ref={ref} className={classes.root}>
      <Alert
        classes={{
          root: classes.alert,
          message: classes.message,
          icon: classes.icon,
        }}
        action={
          <IconButton
            onClick={() => {
              onClose()
              handleDismiss()
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle className={classes.title}>{title}</AlertTitle>
      </Alert>
      {description && <div className={classes.description}>{description}</div>}
    </div>
  )
})

export default AppDownloadSnackbar
