import { isOrganizer } from 'utils/auth'

import * as routes from 'config/routes'

const optionsList = (worker) => ({
  contracts: {
    value: 'Contratos',
    path: routes.WORKER_CONTRACT_INDEX(worker.id),
  },
  novelties: {
    value: 'Novedades de nómina',
    path: {
      pathname: routes.WORKER_NOVELTIES_HISTORY(worker.id),
      state: {
        workerFullName: `${worker.name || ''} ${worker.last_name || ''}`,
      },
    },
  },
  payments: {
    value: 'Pagos',
    path: routes.WORKER_PAYMENTS_HISTORY(worker.id),
  },
  loans: {
    value: 'Préstamos',
    path: routes.WORKER_LOAN_INDEX(worker.id),
  },
  wages: {
    value: 'Salarios',
    path: routes.WORKER_CONTRACT_WAGE_INDEX(worker.id, worker.contract_id),
  },
  holidays: {
    value: 'Vacaciones',
    path: routes.WORKER_HOLIDAYS_HISTORY(worker.id),
  },
  social_benefits: {
    value: 'Prestaciones sociales',
    path: routes.WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX(
      worker.id,
      worker.contract_id
    ),
  },
})

const getHistoryOptions = (worker, avaliableOptions) => {
  let baseOptions = []

  if (!avaliableOptions) return baseOptions

  if (isOrganizer && !avaliableOptions.includes('contracts')) {
    baseOptions = ['contracts', ...avaliableOptions]
  }

  const options = avaliableOptions.map((elem) => {
    return { key: elem, ...optionsList(worker)[elem] }
  })

  return options
}

export default getHistoryOptions
