import Tooltip from '@material-ui/core/Tooltip'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}))

const TablePagination = (props) => {
  const { count, page, pageSize, onChangePage } = props
  const classes = useStyles()
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const numberOfPages = Math.ceil(count / pageSize)

  const handleChange = (_, value) => {
    const newPage = value - 1

    if (newPage !== page) onChangePage(newPage)
  }

  return (
    <Pagination
      page={page + 1}
      count={numberOfPages}
      showFirstButton={mdUp}
      showLastButton={mdUp}
      onChange={handleChange}
      classes={classes}
      siblingCount={mdUp ? 1 : 0}
      size={mdUp ? 'medium' : 'small'}
      renderItem={(item) => {
        const isNotPage = item.type !== 'page'

        return isNotPage ? (
          <Tooltip title={item['aria-label']} placement="bottom">
            {/* span required to prevent warning when button disabled */}
            <span>
              <PaginationItem {...item} />
            </span>
          </Tooltip>
        ) : (
          <PaginationItem {...item} />
        )
      }}
    />
  )
}

export default TablePagination
