import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useQueries, useQueryClient } from 'react-query'
import { Redirect } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import PaperForm from 'components/UI/Form/PaperForm'
import useFormStyles from 'components/UI/Form/formStyles'
import Page from 'components/UI/Page/Page'
import Header from 'components/Worker/Form/Header'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useAreaService from 'utils/hooks/company/areaService'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useCompanyFilesService from 'utils/hooks/company/files'
import useLocationService from 'utils/hooks/company/locationService'
import usePositionService from 'utils/hooks/company/positionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { getInstitutionsByCategory } from 'services/institutionService'

import * as routes from 'config/routes'

import messages from 'messages/company_form'

import { switchRenderFields } from './Fields/fieldsHelpers'
import { formatCompanyData, getDirtyValues } from './helpers'
import stepsData from './stepsData'

const useStyles = makeStyles((theme) => ({
  fieldsContainerWidth: {
    margin: '0 auto',
  },
  steps: {
    marginBottom: theme.spacing(3),
  },
}))

const CompanyForm = ({ match, location: { state } }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [progressStep, setProgressStep] = useState(0)
  const [queryCompany, setQueryCompany] = useState({})
  const [queryLocation, setQueryLocation] = useState([])
  const [queryWorkCenter, setQueryWorkCenter] = useState([])
  const [queryArea, setQueryArea] = useState([])
  const [queryPosition, setQueryPosition] = useState([])
  const [company, setCompany] = useState({})
  const [institutions, setInstitutions] = useState({
    compensationFunds: [],
    riskProviders: [],
    ssOperators: [],
  })
  const [toCompanyShow, setToCompanyShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [anySubmitted, setAnySubmitted] = useState(null)
  const [stepSubmitted, setStepSubmitted] = useState(false)
  const firstMount = useRef(false)
  const classes = useStyles()
  const classesForm = useFormStyles()
  const { user } = useUser()

  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
      email: user.email,
    },
  })

  // TODO: delete when flag is removed
  const stepsDataWithFlag =
    hrNewModule?.value === 'on' ? stepsData : stepsData.slice(0, 6)

  const { handleError } = useErrorHandler()
  const { showInfoMessage, showErrorMessage } = useNotifications()
  const queryClient = useQueryClient()
  const { companyId } = match.params
  const { contentTitle, contentText, contentTextExample } =
    stepsDataWithFlag[currentStep] || {}

  const { locationMutation } = useLocationService({
    queryOptions: { enabled: false },
  })
  const { areaMutation } = useAreaService({ queryOptions: { enabled: false } })
  const { positionMutation } = usePositionService({
    queryOptions: { enabled: false },
  })
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })
  const { companyFilesMutation } = useCompanyFilesService()

  const institutionsQuery = useQueries(
    ['compensation_fund', 'risk_provider', 'ss_operator'].map((category) => {
      return {
        queryKey: ['getInstitutionsByCategory', category],
        queryFn: () => getInstitutionsByCategory(category),
      }
    })
  )

  const institutionsSuccess = institutionsQuery.every(
    (query) => query?.status === 'success'
  )
  const institutionsLoading = institutionsQuery.some(
    (query) => query?.status === 'loading'
  )

  useEffect(() => {
    if (institutionsSuccess)
      setInstitutions({
        compensationFunds: institutionsQuery[0].data?.data,
        riskProviders: institutionsQuery[1].data?.data,
        ssOperators: institutionsQuery[2].data?.data,
      })
  }, [institutionsSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  const isMutating =
    areaMutation?.isLoading ||
    positionMutation?.isLoading ||
    locationMutation?.isLoading

  const dataStepsQueries = useQueries([
    {
      queryKey: 'companyInformation',
      queryFn: useCompanyService({
        serviceParams: {
          queryKey: 'companyInformation',
        },
        queryOptions: {
          onSuccess: ({ data }) => {
            setQueryCompany(data)
          },
        },
      }),
    },
    {
      queryKey: 'companyLocation',
      queryFn: useLocationService({
        serviceParams: { queryKey: 'companyLocation' },
        queryOptions: {
          onSuccess: ({ data }) => {
            setQueryLocation(data)
          },
        },
      }),
    },
    {
      queryKey: ['getWorkCenters', companyId],
      queryFn: useCompanyWorkCenters({
        serviceParams: { queryKey: ['getWorkCenters', companyId] },
        queryOptions: {
          onSuccess: ({ data }) => {
            setQueryWorkCenter(data)
          },
        },
      }),
    },
    {
      queryKey: 'companyAreas',
      queryFn: useAreaService({
        serviceParams: { queryKey: 'companyAreas' },
        queryOptions: {
          onSuccess: ({ data }) => {
            setQueryArea(data)
          },
        },
      }),
    },
    {
      queryKey: 'companyPosition',
      queryFn: usePositionService({
        serviceParams: { queryKey: 'companyPosition' },
        queryOptions: {
          onSuccess: ({ data }) => {
            setQueryPosition(data)
          },
        },
      }),
    },
  ])

  const dataStepsSuccess = dataStepsQueries.every((query) => query.isSuccess)
  const dataStepsLoading = dataStepsQueries.some(
    (status) => status.status === 'loading'
  )

  const dataToFetchCompany =
    !stepSubmitted && dataStepsSuccess
      ? dataStepsQueries.map((query) => query.data.data)
      : [queryCompany, queryLocation, queryWorkCenter, queryArea, queryPosition]

  useEffect(() => {
    const fetchCompany = (data) => {
      const formatted = formatCompanyData(data)

      setCompany(formatted)
    }

    if (dataStepsSuccess || stepSubmitted) {
      fetchCompany(dataToFetchCompany)
    }
    // disable because is the best way for do parallel queries
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStepsSuccess, queryArea, queryCompany, queryPosition, stepSubmitted])

  useEffect(() => {
    const getProgressStep = async () => {
      let step = 0
      let localProgressStep = 0

      while (step < stepsDataWithFlag.length) {
        const { schemaValidation } = stepsDataWithFlag[step]
        try {
          // eslint-disable-next-line no-await-in-loop
          await schemaValidation.validate(company)

          step += 1
          localProgressStep += 1
        } catch (_) {
          break
        }
      }

      return {
        step,
        localProgressStep,
      }
    }

    const fetchAllCompanyData = async () => {
      try {
        const { step, localProgressStep } = await getProgressStep()

        if (
          state &&
          state.initialStep !== undefined &&
          state.initialStep <= step // must not go further from step that needs to be completed
        ) {
          setCurrentStep(state.initialStep)
        } else {
          setCurrentStep(step)
        }
        setProgressStep(localProgressStep)
        firstMount.current = true
      } catch (error) {
        handleError(error)
      }
    }

    if (!firstMount.current && Object.keys(company).length > 0) {
      fetchAllCompanyData()
    }
  }, [anySubmitted, company, handleError, state, stepsDataWithFlag])

  const onSubmit = async (values, form) => {
    let submitOk = true
    setStepSubmitted(false)

    const dirtyValues = getDirtyValues(company, values)

    if (currentStep === 1) {
      setStepSubmitted(true)
    }

    if (currentStep === 3) {
      if (dirtyValues?.areas) {
        if (dirtyValues.areas.length > 0)
          await areaMutation.mutateAsync(
            { mutationMethod: 'PUT', data: dirtyValues.areas },
            {
              onSuccess: () => {
                setStepSubmitted(true)

                trackEvent(integrationEvent.COMPANY_AREA_ADD)
                queryClient.invalidateQueries('companyAreas')
              },
              onError: () => {
                submitOk = false
              },
            }
          )
        delete dirtyValues.areas
      }
    }

    if (currentStep === 4) {
      if (dirtyValues?.positions) {
        if (dirtyValues.positions.length > 0) {
          await positionMutation.mutateAsync(
            { mutationMethod: 'PUT', data: dirtyValues.positions },
            {
              onSuccess: async () => {
                setStepSubmitted(true)

                trackEvent(integrationEvent.COMPANY_POSITION_ADD)
                await queryClient.invalidateQueries('companyPosition')
              },
              onError: () => {
                submitOk = false
              },
            }
          )
        }
        delete dirtyValues.positions
      }
    }

    const {
      tax_document: taxDocument,
      identification_document: identificationDocument,
      legal_representative_document: legalRepresentativeDocument,
    } = values.files

    if (
      values.logo !== company?.logo ||
      taxDocument !== company?.files?.tax_document ||
      identificationDocument !== company?.files?.identification_document ||
      legalRepresentativeDocument !==
        company?.files?.legal_representative_document
    ) {
      const formData = new FormData()

      if (values.logo !== company?.logo) {
        formData.append('logo', values.logo || '')
      }

      if (taxDocument !== company?.files.tax_document) {
        formData.append('tax_document', taxDocument || '')
      }

      if (identificationDocument !== company?.files.identification_document) {
        formData.append('identification_document', identificationDocument || '')
      }

      if (
        legalRepresentativeDocument !==
        company?.files.legal_representative_document
      ) {
        formData.append(
          'legal_representative_document',
          legalRepresentativeDocument || ''
        )
      }

      await companyFilesMutation.mutateAsync(
        {
          mutationMethod: 'PATCH',
          files: formData,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('companyInformation')
            if (formData.get('logo')) {
              trackEvent(integrationEvent.EDIT_SETTINGS, 'Update Logo')
            }
            setStepSubmitted(true)
          },
          onError: () => {
            submitOk = false
          },
        }
      )
    }

    delete dirtyValues.logo
    delete dirtyValues.files

    if (!isObjectEmpty(dirtyValues)) {
      await companyMutation.mutateAsync(
        {
          mutationMethod: 'PATCH',
          company: dirtyValues,
        },
        {
          onSuccess: ({ data }) => {
            trackEvent(integrationEvent.COMPANY_UPDATE, data)
            if (currentStep === 5) {
              showInfoMessage(messages.payment_preferences_notification)
            }
            queryClient.invalidateQueries('companyInformation')
            setStepSubmitted(true)
          },
          onError: () => {
            submitOk = false
          },
        }
      )
    }

    if (submitOk) {
      setIsLoading(true)
      if (currentStep < stepsDataWithFlag.length - 1) {
        if (stepSubmitted) {
          setAnySubmitted(true)
        }
        setCurrentStep((prevStep) => prevStep + 1)
        if (progressStep === currentStep) setProgressStep(currentStep + 1)
        setIsLoading(false)
        setAnySubmitted(false)
      } else {
        setToCompanyShow(true)
      }
    } else {
      setIsLoading(false)
      form.setSubmitting(false)
    }
  }

  const handleClickStep = (index) => {
    setCurrentStep(index)
  }

  const handlePreviousStep = () => {
    setCurrentStep((previous) => previous - 1)
  }

  if (toCompanyShow) return <Redirect to={routes.COMPANY_SHOW(companyId)} />

  const initialValues = {
    logo: null,
    files: {
      tax_document: null,
      indentification_document: null,
      legal_representative_document: null,
    },
    ...company,
    document_type: company.document_type || 'ni',
    account_type: company.account_type || 'savings_account',
  }

  const handleMainSubmit = (validateForm, onHandleSubmit) => {
    if (currentStep === 1) {
      validateForm().then((errors) => {
        if (errors?.locations?.length > 0) {
          showErrorMessage(
            'Crea por lo menos un sede para tu empresa. Por ejemplo: Administrativa'
          )
          return
        }

        onHandleSubmit()
      })
    } else {
      onHandleSubmit()
    }
  }

  return (
    <Page
      documentTitle="Editar empresa"
      header={
        <Header
          title="Editar empresa"
          description="Acá puedes editar la información de la empresa, recuerda que siempre podrás regresar a editar cualquier dato."
        />
      }
      isLoading={institutionsLoading || isLoading || dataStepsLoading}
    >
      <PaperForm
        steps={{
          stepsData: stepsDataWithFlag,
          currentStep,
          progressStep,
          onChangeStep: handleClickStep,
        }}
      >
        <div
          className={clsx({
            [classes.fieldsContainerWidth]: currentStep !== 1,
          })}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            {contentTitle}
          </Typography>
          {contentText && <p>{contentText}</p>}
          {contentTextExample && (
            <>
              <Typography variant="subtitle1"> Ejemplo: </Typography>
              <Typography>{contentTextExample}</Typography>
              <br />
            </>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={
              stepsDataWithFlag[currentStep]?.schemaValidation || {}
            }
            enableReinitialize
          >
            {(form) => {
              const {
                values,
                handleSubmit: handleSubmitLocal,
                isSubmitting,
                setFieldValue,
                validateForm,
              } = form

              // set default ss_operator
              if (!values.ss_operator && institutions.ssOperators.length > 0) {
                const initSssOp = institutions.ssOperators.find(
                  (ssOp) => ssOp.name === 'Aportes en Linea'
                )

                setFieldValue('ss_operator', initSssOp)
              }

              return isObjectEmpty(values) ? null : (
                <>
                  <Form>
                    {switchRenderFields(
                      currentStep,
                      institutions,
                      values?.document_type
                    )}
                  </Form>
                  <div className={classesForm.actionsContainer}>
                    {currentStep !== 0 ? (
                      <Button variant="outlined" onClick={handlePreviousStep}>
                        Volver al paso anterior
                      </Button>
                    ) : null}
                    <Button
                      onClick={() =>
                        handleMainSubmit(validateForm, handleSubmitLocal)
                      }
                      disabled={isSubmitting || isMutating}
                    >
                      Guardar y continuar
                    </Button>
                  </div>
                </>
              )
            }}
          </Formik>
        </div>
      </PaperForm>
    </Page>
  )
}

export default CompanyForm
