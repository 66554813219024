import clsx from 'clsx'
import { useEffect } from 'react'
import { useIsFetching, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import useSocialSecurityService from 'utils/hooks/payroll/socialSecurity'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import SocialSecurityPaymentModal from '../PaymentModal/SocialSecurityPaymentModal'
import useSocialSecurityPaymentModal from '../PaymentModal/useSocialSecurityPaymentModal'
import InfoCard from './InfoCard'
import ErrorsModal from './Modals/ErrorsModal'
import useErrorsModal from './Modals/useErrorsModal'
import PaymentSummary from './PaymentSummary'
import { getInfoCards, getResultContent } from './helpers'

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'auto',
    },
  },
  succesContent: {
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 400px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5.5),
  },
  page: {
    height: `calc(100% - ${theme.headerHeight}px)`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(7),
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  title: {
    paddingRight: theme.spacing(2),
    fontWeight: 'normal',
    '& > span': {
      fontWeight: 'bold',
    },
    '& > img': {
      marginLeft: theme.spacing(1),
    },
  },
  helpCenter: {
    marginTop: theme.spacing(18),
    marginBottom: theme.spacing(3),
  },
  cardsContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    fontSize: 64,
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}))

const UploadResult = () => {
  const queryClient = useQueryClient()
  const params = useParams()
  const { state } = useLocation()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const history = useHistory()
  const classes = useStyles()
  const { periodId } = params
  const {
    socialSecurityPaymentModalState,
    openSocialSecurityPaymentModal,
    closeSocialSecurityPaymentModal,
    activateSocialSecurityPaymentIntegration,
  } = useSocialSecurityPaymentModal({ id: periodId }, true, true)
  const {
    errorsModalState,
    openErrorsModal,
    closeErrorsModal,
  } = useErrorsModal()
  const queryKey = ['getSSForm', periodId, state?.formType]
  const isArusProvider = state?.provider === 'arus'
  const {
    socialSecurityMutation,
    socialSecurityQuery,
  } = useSocialSecurityService({
    serviceParams: {
      queryKey,
      periodId,
      formType: state?.formType,
    },
    queryOptions: {
      refetchOnWindowFocus: isArusProvider,
      onError: (error) => {
        handleError(error)
        history.goBack()
      },
    },
  })
  const hasErrors =
    (socialSecurityQuery.data &&
      socialSecurityQuery.data.totals.errors !== 0) ||
    false
  const canRenderSummary = !socialSecurityQuery.isLoading && !hasErrors
  const { totals: formTotals, form: formDataValues = {} } =
    socialSecurityQuery.data || {}
  const hasWarnings =
    formTotals?.alerts !== 0 && formTotals?.possible_fixes !== 0
  const isRefetching = useIsFetching(queryKey, { exact: false }) === 1

  useEffect(() => {
    return () => {
      queryClient.removeQueries('getSSForm', {
        exact: false,
      })
    }
  }, [queryClient])

  const onPay = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'POST',
        periodId,
        formKey: formDataValues.key,
      },
      {
        onSuccess: ({ data }) => {
          if (data?.payment_url) {
            if (formDataValues?.provider === 'arus') {
              window.open(data?.payment_url, '_blank')
            } else {
              window.location.href = data?.payment_url
            }
          }
        },
      }
    )
  }

  const handleDeleteForm = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'DELETE',
        periodId,
        formKey: formDataValues.key,
        formType: formDataValues.type,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          history.push(routes.SETTINGS_SOCIAL_SECURITY_INDEX(), {
            provider: formDataValues.provider,
          })
        },
      }
    )
  }

  const handleCloseErrorsModal = () => {
    closeErrorsModal()
  }

  const infoCards = getInfoCards(
    formTotals,
    formDataValues?.type,
    openErrorsModal,
    openSocialSecurityPaymentModal,
    formDataValues.provider,
    classes.icon
  )

  const resultContent = getResultContent(
    hasErrors,
    formDataValues?.status,
    formDataValues.provider,
    classes
  )

  const getModalVariant = () => {
    if (hasErrors) return 'errors'
    if (formTotals.alerts > 0) return 'alerts'
    return 'possible_fixes'
  }

  return (
    <div
      className={clsx(classes.main, {
        [classes.succesContent]: canRenderSummary,
      })}
    >
      <Page
        documentTitle="Resultado de carga de planilla"
        isLoading={socialSecurityQuery.isLoading}
        contentClassName={classes.page}
        isLoadingWithModal={isArusProvider && isRefetching}
      >
        <div className={classes.content}>
          <Box>
            {resultContent?.title}
            <Typography variant="body1" className={classes.subtitle}>
              {resultContent?.subtitle}
            </Typography>
          </Box>

          <div className={classes.cardsContainer}>
            {infoCards.map((card, key) => (
              <InfoCard
                key={key.toString()}
                card={card}
                hasErrors={hasErrors}
                hasWarnings={hasWarnings}
              />
            ))}
          </div>

          <div className={classes.buttons}>
            {hasErrors && (
              <Button onClick={() => history.goBack()}>
                Regresar a corregir errores
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => openErrorsModal(getModalVariant())}
            >
              Ver tabla de {hasErrors ? 'errores' : 'alertas'}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                openSocialSecurityPaymentModal({
                  formType: formDataValues?.type,
                  provider: formDataValues.provider,
                })
              }
            >
              Ver personas
            </Button>
          </div>
          <Typography
            variant="body2"
            align="center"
            className={classes.helpCenter}
          >
            Para más información, visita nuestro{' '}
            <MuiLink
              href="https://ayuda.nominapp.com/help/que-es-un-error-que-es-una-alerta-que-es-una-posible-correccion-y-que-hacer-con-ellos-en-aportes-en-linea"
              target="_blank"
            >
              <b>centro de ayuda.</b>
            </MuiLink>
          </Typography>
        </div>

        {errorsModalState.open && (
          <ErrorsModal
            state={errorsModalState}
            periodId={periodId}
            formType={formDataValues?.type}
            handleClose={handleCloseErrorsModal}
          />
        )}

        {socialSecurityPaymentModalState.open && (
          <SocialSecurityPaymentModal
            state={socialSecurityPaymentModalState}
            handleClose={closeSocialSecurityPaymentModal}
            handleActivateIntegration={activateSocialSecurityPaymentIntegration}
          />
        )}
      </Page>

      {canRenderSummary && (
        <PaymentSummary
          formData={formDataValues}
          isLoading={
            socialSecurityMutation.isLoading || socialSecurityQuery.isLoading
          }
          handlePayForm={onPay}
          handleDeleteForm={handleDeleteForm}
          hasErrors={hasErrors}
          provider={formDataValues.provider}
        />
      )}
    </div>
  )
}

export default UploadResult
