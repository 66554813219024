import * as yup from 'yup'
import { yupLocaleES } from 'utils/form'
import { paymentTypesOptions } from 'utils/company'

import BankTransferForm from './Form/BankTransferForm'
import CompanyPaymentForm from './Form/CompanyPaymentForm'

yup.setLocale(yupLocaleES)

export const getTabsData = (companyId, setIsLoading) => {
  return [
    {
      label: 'Transaccion Bancaria',
      content: (
        <BankTransferForm companyId={companyId} setIsLoading={setIsLoading} />
      ),
    },
    {
      label: 'Pagos Empresa',
      content: (
        <CompanyPaymentForm companyId={companyId} setIsLoading={setIsLoading} />
      ),
    },
  ]
}

export const bankTransferInitialValues = {
  payment_date: new Date(),
  payment_type: 'month',
}

export const paymentCategoriesOptions = [
  { label: 'Descuento o Recargo', id: 'discount' },
  { label: 'Cambio fecha proximo pago', id: 'change_date' },
  { label: 'Tipo de Pago', id: 'payment_type' },
  { label: 'Eliminar Suscripción', id: 'remove_subscription' },
  { label: 'Comentarios', id: 'comments' },
]

export const getCurrentPaymentCategory = (value) => {
  const distributorPaymentTypeOption = paymentTypesOptions.find(
    (opt) => opt.value === value
  )

  return distributorPaymentTypeOption?.value
}

export const getInitialValues = (values, payment) => {
  const {
    extra_charge_type: extraChargeType,
    extra_charge_limit_date: extraChargeLimitDate,
    next_payment_date: nextPaymentDate,
    payment_category: paymentCategory,
    payment_extra_charge: paymentExtraCharge,
  } = values

  switch (payment) {
    case 'discount':
      return {
        extra_charge_limit_date: extraChargeLimitDate,
        extra_charge_type: extraChargeType || 'value',
        payment_extra_charge:
          paymentExtraCharge > 0 ? paymentExtraCharge : null,
      }
    case 'change_date':
      return { next_payment_date: nextPaymentDate }
    case 'payment_type':
      return { payment_category: paymentCategory }
    default:
      return {}
  }
}

export const companyPaymentValidationSchema = yup.object({
  payment_category: yup.string(),
})
