import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { updateSubscription } from 'services/freemiumService'
import {
  getAllPayments,
  getExtraWorkersPlan,
  getPlans,
  getSubscription,
  sendInvoice,
} from 'services/subscription/subscriptionService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, page } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getSubscription')
    return getSubscription({ extraWorkersNumber: null })

  if (currentQueryKey === 'getAllPayments') return getAllPayments({ page })

  if (currentQueryKey === 'getPlans') return getPlans()

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    planId,
    paymentId,
    extraWorkers,
    crmEvent,
  } = mutationData

  if (mutationMethod === 'GET' && !planId && !crmEvent)
    return getSubscription({ extraWorkersNumber: extraWorkers })

  if (mutationMethod === 'GET' && crmEvent) return getSubscription({ crmEvent })

  if (mutationMethod === 'PUT') return updateSubscription()

  if (mutationMethod === 'GET') return getExtraWorkersPlan(planId, extraWorkers)

  if (mutationMethod === 'POST') return sendInvoice(paymentId)

  return null
}

const useSubscriptionService = ({
  serviceParams = { queryKey: 'getSubscription' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restSubscriptionResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const subscriptionMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    subscriptionQuery: {
      data,
      ...restSubscriptionResponse,
    },
    subscriptionMutation,
  }
}

export default useSubscriptionService
