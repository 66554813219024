import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: '60vh',
    },
  },
  content: {
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  message: {
    marginBottom: theme.spacing(4),
    color: theme.palette.grey[700],
    textAlign: 'center',
  },
}))

const StatusBanner = ({
  image,
  imageAlt,
  action = undefined,
  title,
  message,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={image} alt={imageAlt} width={128} height={128} />
      <div className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
        {action && <>{action}</>}
      </div>
    </div>
  )
}

export default StatusBanner
