import { useFormikContext } from 'formik'
import { useContext, useState } from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import CreditCardNumberField from 'components/Subscription/Atoms/CreditCardNumberField'
import AccountFields from 'components/Subscription/OldSubscription/Paywall/Payment/Form/Fields/Account'
import ClientTypeField from 'components/Subscription/OldSubscription/Paywall/Payment/Form/Fields/ClientType'
import CreditCardExpirationDate from 'components/Subscription/OldSubscription/Paywall/Payment/Form/Fields/CreditCardExpirationDate'
import PersonalFields from 'components/Subscription/OldSubscription/Paywall/Payment/Form/Fields/PersonalFields'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import FormField from 'components/UI/Formik/FormField/Index'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import securePayment from 'assets/images/views/subscription/secure_payment.png'

import { NewPricingContext } from '../Index'
import MethodAccordion from './MethodAccordion'
import { creditCardOptions, pseOptions } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 730,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(7.5, 4.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(7.5, 2),
    },
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '24px auto',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
  },
  paymentMessage: {
    color: theme.palette.grey[500],
  },
  formContainer: {
    display: 'grid',
    width: '100%',
    rowGap: theme.spacing(2.5),
    gridTemplateColumns: '1fr',
  },
  creditCardContainer: {
    display: 'grid',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '48% auto auto',
      columnGap: theme.spacing(1),
    },
  },
  methodsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(3),
    margin: theme.spacing(4, 0, 10, 0),
    overflow: 'hidden',
  },
  fieldsContainer: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  },
}))

const PaymentMethod = () => {
  const classes = useStyles()
  const { subscription } = useUser()
  const { setFieldValue, values } = useFormikContext()
  const { setActivePayment } = useContext(NewPricingContext)
  const [expandedAccordion, setExpandedAccordion] = useState(null)
  const isCreditCard = values?.payment_method === 'credit_card'
  const isClaraPayment = subscription?.payment_category === 'clara_payment'

  const onExpandAccordion = (panel) => {
    if (expandedAccordion === panel) {
      setExpandedAccordion(null)
      setFieldValue('payment_method', null)
      setActivePayment('credit_card')
      return
    }
    setExpandedAccordion(panel)
    setFieldValue('payment_method', panel)
    setActivePayment(panel)
  }

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" gutterBottom>
        Selecciona tu método de pago
      </Typography>
      <div className={classes.methodsContainer}>
        <MethodAccordion
          id="credit_card"
          logos={creditCardOptions}
          title="Tarjeta de crédito"
          expanded={expandedAccordion}
          onChange={onExpandAccordion}
        >
          <div className={classes.formContainer}>
            <FormField
              name="payer_name"
              label="Nombre del titular"
              inputProps={{ autoComplete: 'cc-name' }}
            />
            <div className={classes.creditCardContainer}>
              <CreditCardNumberField />
              <FormField
                name="security_code"
                label="CVV"
                variant="number"
                placeholder="0000"
                format="####"
                inputProps={{ autoComplete: 'cc-csc' }}
                style={{ fontFamily: 'Roboto Mono, monospace' }}
              />
              <CreditCardExpirationDate />
            </div>
          </div>
        </MethodAccordion>
        {!isClaraPayment ? (
          <>
            <MethodAccordion
              id="pse"
              title="PSE"
              expanded={expandedAccordion}
              onChange={onExpandAccordion}
              logos={pseOptions}
            >
              <div className={classes.formContainer}>
                <ClientTypeField />
                <PersonalFields
                  className={classes.fieldsContainer}
                  disableIdNumberValidate={isCreditCard}
                />
                <BankField />
                <AccountFields className={classes.fieldsContainer} />
              </div>
            </MethodAccordion>
            {subscription?.payment_category !== null ? (
              <MethodAccordion
                id="automatic_debit"
                title="Débito automático"
                expanded={expandedAccordion}
                onChange={onExpandAccordion}
              >
                <div className={classes.formContainer}>
                  <ClientTypeField />
                  <PersonalFields
                    className={classes.fieldsContainer}
                    disableIdNumberValidate={isCreditCard}
                  />
                  <BankField />
                  <AccountFields className={classes.fieldsContainer} />
                </div>
              </MethodAccordion>
            ) : null}
          </>
        ) : null}
      </div>
      <div className={classes.footer}>
        <img src={securePayment} alt="Secure payment" width={24} height={24} />
        <Typography variant="body2">Pago 100% seguro</Typography>
      </div>
      <Typography variant="caption" className={classes.paymentMessage}>
        Al ingresar los datos de pago autorizas a Nominapp para realizar una
        transacción recurrente de acuerdo al plan seleccionado. Si deseas
        cancelar o modificar el cobro, o si tienes alguna duda, puedes
        escribirnos a <OrganizationEmail />.
      </Typography>
    </Paper>
  )
}

export default PaymentMethod
