import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

import MButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { getUserEmail, getUserId } from 'utils/auth'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(68.75),
      width: '100%',
      padding: theme.spacing(3),
    },
  },
  button: {
    display: 'inline-flex',
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  copyButton: {
    borderRadius: theme.spacing(1.25),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: theme.spacing(16),
  },
  copyIconButton: {
    borderRadius: theme.spacing(1.25),
  },
}))

const IntegrationToken = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const userEmail = getUserEmail()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const confirm = useConfirm()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const queryKey = ['userToken', getUserId()]
  const { usersQuery, usersMutation } = useUsersService({
    serviceParams: {
      queryKey,
    },
  })

  const handleCopyToClipboard = async () => {
    try {
      if (!document.hidden) {
        await navigator.clipboard.writeText(usersQuery.data)
        showSuccessMessage(
          'Se ha copiado el token al portapapeles correctamente'
        )
      }
    } catch (error) {
      handleError(error)
    }
  }

  const confirmUpdateToken = () => {
    usersMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: 'updateUserToken',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
          showSuccessMessage('El token se ha renovado correctamente')
        },
      }
    )
  }

  const handleUpdateToken = () => {
    confirm({
      title: 'Renovar API token',
      description:
        'Al renovar el token las aplicaciones conectadas con las credenciales anteriores perderán acceso a tu información. ¿Estás seguro que deseas continuar?',
      okText: 'Renovar',
      onOk: confirmUpdateToken,
    })
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Integración por API
      </Typography>
      <Typography gutterBottom>
        Con la integración por API puedes conectar otros sistemas con Nominapp
        para mantener actualizada tu información. Consulta toda la documentación{' '}
        <Link
          href="https://nominapp.readme.io/reference/información-general"
          target="_blank"
        >
          aquí
        </Link>
        .
        <br />
        <b>Recuerda:</b> cada usuario tiene diferentes credenciales y todas las
        acciones realizadas, quedarán registradas a nombre del usuario que
        utilices.
      </Typography>
      <Paper className={classes.card}>
        <Formik
          enableReinitialize
          initialValues={{
            email: userEmail,
            api_token: usersQuery.data,
          }}
        >
          {() => {
            return (
              <>
                <FormField type="email" name="email" label="Usuario" readOnly />
                <FormField
                  type="text"
                  name="api_token"
                  label="Token"
                  readOnly
                  stretchRightButton
                  endAdornment={
                    <>
                      {xsDown ? (
                        <IconButton
                          className={classes.copyIconButton}
                          onClick={handleCopyToClipboard}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      ) : (
                        <MButton
                          className={classes.copyButton}
                          startIcon={<FileCopyIcon />}
                          onClick={handleCopyToClipboard}
                        >
                          Copiar
                        </MButton>
                      )}
                    </>
                  }
                />
                <Grid container justify="center">
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={handleUpdateToken}
                    loading={usersMutation.isLoading}
                  >
                    Renovar token
                  </Button>
                </Grid>
              </>
            )
          }}
        </Formik>
      </Paper>
    </div>
  )
}

export default IntegrationToken
