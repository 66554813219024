import { gapi, loadAuth2 } from 'gapi-script'
import { useEffect } from 'react'
import { GoogleLogin } from 'react-google-login'

import { useUser } from 'components/App/UserContext/useUser'
import { useAuthStyles } from 'components/Auth/helpers'
import Button from 'components/UI/Button/Button'

import { isDev } from 'utils/general'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import logoGoogle from 'assets/images/logos/logo_Google_color.svg'

import messages from 'messages/notification'

const clientGoogle = process.env.REACT_APP_GOOGLE_CLIENT_ID

const GoogleLoginButton = ({ action }) => {
  const classes = useAuthStyles()
  const { logIn } = useUser()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()

  const { sessionMutation } = useSessionService()

  useEffect(() => {
    const start = async () => {
      await loadAuth2(gapi, clientGoogle, '')
    }
    start()
  }, [])

  const onSuccess = async (res) => {
    const { googleId } = res.profileObj
    const { id_token: idToken } = res.tokenObj

    sessionMutation.mutateAsync(
      {
        mutationMethod: 'POST_GOOGLE',
        credentials: {
          googleId,
          idToken,
        },
      },
      {
        onSuccess: ({ data }) => {
          logIn(data)
          if (action === 'Sign In') showSuccessMessage(messages.LOGIN_OK)
          if (!isDev && data?.active_until) {
            window.dataLayer.push({
              event: 'Sign Up',
            })
          }
        },
      },
      {
        onError: (error) => {
          handleError(error)
        },
      }
    )
  }

  const onError = (error) => {
    const errorMessage = error.error
    if (errorMessage !== 'popup_closed_by_user') handleError(error)
  }

  return (
    <GoogleLogin
      client_id={clientGoogle}
      render={(renderProps) => (
        <Button
          className={classes.googleButton}
          startIcon={<img src={logoGoogle} alt="Google" />}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          {action === 'Sign In'
            ? 'Ingresa con Google'
            : 'Crea tu cuenta con Google'}
        </Button>
      )}
      onSuccess={onSuccess}
      onFailure={onError}
      cookiePolicy="single_host_origin"
      redirectUri="/"
    />
  )
}

export default GoogleLoginButton
