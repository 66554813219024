import CustomField from 'components/UI/Formik/CustomField'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'

import InputsGroupContainer, {
  Horizontal,
  Column,
} from '../InputsGroupContainer'

import { useCreditFormFields } from '../hooks'

const WorkerResidence = () => {
  const { inputsResidenceData } = useCreditFormFields()

  return (
    <>
      {inputsResidenceData.map((section) => (
        <InputsGroupContainer
          title={section?.title}
          key={section?.id}
          gutterBottom={section?.gutterBottom}
        >
          {section?.rows.map((row) => {
            const Wrapper = row?.component === 'full-row' ? Horizontal : Column

            return (
              <Wrapper key={row?.id} gutterBottom={row?.gutterBottom}>
                {row?.fields.map((field) => {
                  if (field?.name === 'department') {
                    return (
                      <DepartmentsList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                      />
                    )
                  }

                  if (field?.name === 'city') {
                    return (
                      <CitiesList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                      />
                    )
                  }

                  return (
                    <CustomField
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      placeholder={field?.placeholder}
                      component={field?.component}
                      options={field?.options || undefined}
                      optional={field?.optional}
                      data-cy={field?.dataCy}
                    />
                  )
                })}
              </Wrapper>
            )
          })}
        </InputsGroupContainer>
      ))}
    </>
  )
}

export default WorkerResidence
