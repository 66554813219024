import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import NoWrap from 'components/UI/NoWrap'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { formatCurrency } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    '& > svg': {
      width: 64,
      height: 32,
      fill: theme.palette.primary.main,
      marginRight: theme.spacing(2),
    },
  },
}))

const PeriodPayrollReviewTotalsItem = ({ SvgImage, title, value }) => {
  const classes = useStyles()

  return (
    <CardReview alignCenter>
      <article className={classes.contentCard}>
        <SvgImage />
        <div>
          <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
            {title}
          </Typography>
          <Typography variant="h5" component={NoWrap}>
            {formatCurrency(value)}
          </Typography>
        </div>
      </article>
    </CardReview>
  )
}

export default PeriodPayrollReviewTotalsItem
