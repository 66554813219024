import clsx from 'clsx'
import { Link as RLink } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import Link from 'components/UI/MaterialUI/Link'

import {
  PERIOD_PAYROLL_VIEW_DETAILS,
  PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS,
  WORKER_SHOW,
} from 'config/routes'

import ExpandButton from './ExpandButton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: 200,
  },
  containerExpanded: {
    padding: theme.spacing(1.5, 0),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  profileButton: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },
  workerDetail: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  workedDays: {
    marginTop: theme.spacing(0.5),
  },
  detailText: {
    color: theme.palette.grey[900],
  },
  actionsContainer: {
    display: 'grid',
    gridTemplateColumns: '24px 24px',
    columnGap: theme.spacing(0.5),
  },
}))

const WorkerExpander = ({
  workerId,
  payrollId,
  onExpand,
  expanded,
  workerName,
  periodId,
  payPreviousPayroll,
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerExpanded]: expanded,
      })}
    >
      <div className={classes.content}>
        <Link to={WORKER_SHOW(workerId)}>
          <Typography variant="caption">{workerName}</Typography>
        </Link>
        <div className={classes.actionsContainer}>
          <ExpandButton onClick={onExpand} expanded={expanded} />
          <IconButton
            component={RLink}
            className={classes.profileButton}
            size="small"
            target="_blank"
            to={
              payPreviousPayroll
                ? PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS(periodId, payrollId)
                : PERIOD_PAYROLL_VIEW_DETAILS(payrollId)
            }
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default WorkerExpander
