import { getDistributor, isOrganizer } from 'utils/auth'
import { isDev } from 'utils/general'
import { chatScripts } from 'utils/integration'

let currentChat = ''

function getChat() {
  if (isOrganizer()) return ''

  const distributor = getDistributor()

  if (distributor === 'innpulsa') return 'flowxo'

  return 'hubspot'
}

function removeCurrentChat() {
  if (currentChat) document.getElementById(currentChat)?.remove()

  document
    .querySelectorAll('iframe')
    ?.forEach?.((iframe) => iframe.parentNode.removeChild(iframe))
}

function loadChatScript() {
  if (isDev) return

  const newChatName = getChat()

  if (!newChatName) {
    currentChat = ''
    removeCurrentChat()
  } else if (currentChat !== newChatName) {
    // Remove Current Chat
    removeCurrentChat()

    // Verify valid chat
    if (!newChatName) return

    // Create script
    currentChat = newChatName

    const chatBody = chatScripts[newChatName]

    const script = document.createElement('script')
    script.setAttribute('id', newChatName)

    if (chatBody.src) script.src = chatBody.src
    if (chatBody.async) script.async = chatBody.async
    if (chatBody.defer) script.defer = chatBody.defer

    if (chatBody.data) {
      script.setAttribute(`data-${chatBody.data.name}`, chatBody.data.value)
    }

    if (chatBody.child) script.onload = chatBody.child()

    document.body.appendChild(script)
  }
}

export default loadChatScript
