import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import BankFileModal from 'components/Period/Payroll/Modals/BankFileModal'
import PayslipModal from 'components/Period/Payroll/Modals/PayslipModal'
import useBankFileModal from 'components/Period/Payroll/Modals/useBankFileModal'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import Button from 'components/UI/Button/Button'
import useWorker from 'components/Worker/useWorker'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useTerminationService from 'utils/hooks/worker/terminationService'

import * as routes from 'config/routes'

const useStyles = makeStyles((theme) => ({
  muiButton: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    gap: theme.spacing(1),
  },
}))

const TerminationMenu = ({ worker, confirm, isEditable }) => {
  const [termination, setTermination] = useState({})
  const { refreshWorker } = useWorker({ useCache: true })
  const { handleError } = useErrorHandler()
  const classes = useStyles()
  const { showSuccessMessage } = useNotifications()
  const reportsFeatureState = usePremiumFeature('reports')
  const payrollPaymentFeatureState = usePremiumFeature('payrollPayment')

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal()

  const {
    bankFileModalState,
    openBankFileModal,
    closeBankFileModal,
  } = useBankFileModal()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerHistoryMenu',
  })

  const { terminationQuery, terminationMutation } = useTerminationService({
    serviceParams: {
      queryKey: ['getTermination', worker?.contract_id],
      contractId: worker?.contract_id,
    },
    queryOptions: {
      onSuccess: ({ data }) => setTermination(data),
      onError: (error) => handleError(error),
    },
  })

  const refreshWorkerData = () => {
    refreshWorker()
  }

  const handleDeleteTermination = (contractId) => {
    confirm({
      title: 'Eliminar liquidación laboral',
      description:
        '¿Estás seguro de eliminar la liquidación laboral de esta persona? Este cambio no puede deshacerse. Esta persona se incluirá de nuevo en todas las nóminas hasta su fecha final de contrato.',
      type: 'warning',
      onOk: async () => {
        await terminationMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            contractId,
          },
          {
            onSuccess: () => {
              showSuccessMessage('Liquidación laboral eliminada')
              refreshWorkerData()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      },
      okText: 'Eliminar liquidación',
    })
  }

  const handleShowPayslip = () => {
    if (reportsFeatureState.isPremiumFeature) {
      reportsFeatureState.openModal()
      return
    }
    openPayslipModal({ payroll: { id: worker.payroll_id } })
    popupState.close()
  }

  const handleBankPaymentFile = () => {
    if (payrollPaymentFeatureState.isPremiumFeature) {
      payrollPaymentFeatureState.openModal()
      return
    }
    openBankFileModal({ payroll: { id: termination.payroll_id } })
    popupState.close()
  }

  if (terminationQuery.isSuccess) {
    return (
      <>
        <Button endIcon={<ExpandMoreIcon />} {...bindTrigger(popupState)}>
          Liquidación
        </Button>

        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            component={MuiLink}
            className={classes.muiButton}
            href={`${routes.PERIOD_PAYROLL_VIEW_DETAILS(
              terminationQuery?.data?.payroll_id
            )}?selected_tab=termination`}
            target="_blank"
          >
            Ver cálculos
          </MenuItem>
          {terminationQuery?.data?.bank_file && (
            <MenuItem onClick={handleBankPaymentFile}>
              <ListItemText>Archivo del banco</ListItemText>
              <ListItemIcon>
                <IconCrown
                  isPremiumAction={
                    payrollPaymentFeatureState.isPremiumFeatureIcon
                  }
                />
              </ListItemIcon>
            </MenuItem>
          )}
          {isEditable && (
            <span>
              <MenuItem
                component={Link}
                to={routes.WORKER_CONTRACT_TERMINATION_EDIT(
                  worker.id,
                  worker.contract_id
                )}
              >
                Editar liquidación
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteTermination(worker.contract_id)}
              >
                Eliminar liquidación
              </MenuItem>
            </span>
          )}
          {worker.termination.id && (
            <MenuItem onClick={handleShowPayslip} className={classes.menuItem}>
              <ListItemText>Liquidación (PDF)</ListItemText>
              <ListItemIcon>
                <IconCrown
                  isPremiumAction={reportsFeatureState.isPremiumFeatureIcon}
                />
              </ListItemIcon>
            </MenuItem>
          )}
        </Menu>
        <BankFileModal
          state={bankFileModalState}
          handleClose={closeBankFileModal}
        />
        {payslipModalState.open && (
          <PayslipModal
            state={payslipModalState}
            handleClose={closePayslipModal}
            handleDownloadPayslip={handleDownloadPayslip}
          />
        )}
      </>
    )
  }

  return null
}

export default TerminationMenu
