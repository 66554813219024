import { useHistory } from 'react-router-dom'

import { Tooltip, Typography, makeStyles } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ListIcon from '@material-ui/icons/ListOutlined'
import FileAddIcon from '@material-ui/icons/NoteAdd'

import Link from 'components/UI/MaterialUI/Link'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import {
  getCurrentDistributor,
  subscriptionPaymentCategories,
} from 'utils/company'
import { booleanLookup, booleanLookupOptions, plansOptions } from 'utils/table'

import * as routes from 'config/routes'

export function getColumnsData(distributors, onClick) {
  const distributorsOptions = distributors.map((distributor) => ({
    value: distributor.id,
    label: distributor.name,
    id: distributor.id,
  }))

  const useStyles = makeStyles({
    headerColumn: {
      fontWeight: 500,
      marginRight: 5,
    },
  })

  const PlanCategoryHeader = () => {
    const classes = useStyles()

    const messageTooltip = (
      <>
        Ver información de la categoría,{' '}
        <TooltipLink href="https://playbook.nominapp.com/categorias-de-precios">
          click aquí.
        </TooltipLink>
      </>
    )

    return (
      <>
        <Typography variant="body2" className={classes.headerColumn}>
          Categoría de plan
        </Typography>
        <Tooltip title={messageTooltip} placement="top" interactive arrow>
          <InfoOutlinedIcon fontSize="small" color="primary" />
        </Tooltip>
      </>
    )
  }

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => (
        <Link onClick={() => onClick(row.original)} to={routes.DASHBOARD}>
          {row.original.name}
        </Link>
      ),
      customWidth: 200,
    },
    {
      Header: 'NIT',
      accessor: 'id_number',
      emptyValue: '-',
      customWidth: 120,
      sortType: 'alphanumericSort',
    },
    {
      Header: 'Editar Periodos',
      accessor: 'edit_periods',
      Cell: ({ row }) => booleanLookup[row.original.edit_periods],
      field: 'select',
      editOptions: booleanLookupOptions,
      editable: true,
      customWidth: 100,
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Activo',
      accessor: 'active',
      Cell: ({ row }) => booleanLookup[row.original.active],
      field: 'select',
      editOptions: booleanLookupOptions,
      editable: true,
      customWidth: 100,
      alignHeader: 'center',
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Suscripción activa',
      accessor: 'active_subscription',
      Cell: ({ row }) =>
        booleanLookup[row.original.active_subscription] || null,
      emptyValue: '-',
      customWidth: 100,
      alignHeader: 'center',
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Freemium',
      accessor: 'freemium',
      Cell: ({ row }) => booleanLookup[row.original.freemium],
      field: 'switch',
      editOptions: booleanLookupOptions,
      editable: (props) => !props.value,
      customWidth: 100,
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: PlanCategoryHeader,
      accessor: 'plan_category',
      Cell: ({ row }) => {
        // This is the oldest plan_category
        if (row.original.plan_category === 'workers_range_plan')
          return row.original.plan_category

        return row.original.plan_category
      },
      field: 'select',
      editOptions: () => plansOptions,
      editable: (props) => props.value === 'workers_range_plan',
      customWidth: 180,
    },
    {
      Header: 'Distribuidor',
      accessor: 'distributor_id',
      Cell: ({ row }) =>
        getCurrentDistributor(distributors, row.original.distributor_id),
      field: 'select',
      editOptions: distributorsOptions,
      editable: true,
      customWidth: 150,
    },
    {
      Header: 'Medio de pago',
      accessor: 'payment_category',
      Cell: ({ row }) =>
        subscriptionPaymentCategories[row.original.payment_category]
          ? subscriptionPaymentCategories[row.original.payment_category]
          : row.original.payment_category,
      emptyValue: '-',
      customWidth: 150,
      sortType: 'alphanumericSort',
    },
  ]

  return columns
}

export const useGetTableActions = (
  handleClick,
  handleDeleteCompany,
  confirm
) => {
  const history = useHistory()

  return [
    (rowData) => ({
      id: 'payments',
      tooltip: 'Ver Pagos',
      icon: ListIcon,
      onClick: () => {
        handleClick(rowData)
        history.push(routes.SUBSCRIPTION_STATUS())
      },
      hidden: rowData.payment_category === 'distributor_payment',
    }),
    (rowData) => ({
      id: 'add_payment',
      tooltip: 'Agregar Pago',
      icon: FileAddIcon,
      onClick: () => history.push(routes.ORGANIZER_COMPANY_PAYMENT(rowData.id)),
    }),
    (rowData) => ({
      id: 'delete_company',
      tooltip: 'Eliminar cuenta',
      icon: DeleteForeverIcon,
      onClick: () => {
        confirm({
          type: 'warning',
          title: 'Eliminar cuenta',
          description: `Eliminar la cuenta de la empresa ${rowData.name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => handleDeleteCompany(rowData.id),
        })
      },
    }),
  ]
}
