import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

import emptyAvatar from 'assets/images/avatars/empty_avatar.svg'

import { isAlegraClaroDist } from 'utils/auth'

import { hashName } from '../helpers'

const useStyles = makeStyles((theme) => ({
  listTextDetail: {
    color: theme.palette.primary.dark,
  },
}))

const DashboardCommingSoonAnniversariesItem = ({ anniversary, index }) => {
  const [avatarImg, setAvatarImg] = useState()
  const classes = useStyles()

  const { worker_name: workerName, date, years } = anniversary

  const hashedName = hashName(workerName)

  if (!isAlegraClaroDist()) {
    import(`assets/images/avatars/avatar${hashedName}.png`).then((a) =>
      setAvatarImg(a.default)
    )
  }

  return (
    <ListItem key={index}>
      <ListItemAvatar>
        <Avatar src={avatarImg || emptyAvatar} alt="avatar" />
      </ListItemAvatar>
      <ListItemText primary={workerName} secondary={date} />
      <Typography variant="body2" className={classes.listTextDetail}>
        {years} años
      </Typography>
    </ListItem>
  )
}

export default DashboardCommingSoonAnniversariesItem
