const planDetail = (handleStarFree, handleStarPremium) => {
  return {
    free: {
      title: 'Nominapp Free',
      description:
        'Liquida y emite tu nómina electrónica a la DIAN sin ningún costo.',
      userMessage: 'Usuarios administradores: 1',
      supportTime: 'Respuesta de soporte en 24 horas',
      buttonText: 'Empezar free',
      buttonAction: handleStarFree,
    },
    premium: {
      title: 'Nominapp Premium',
      description:
        'Superpoderes que te permiten automatizar tu proceso de nómina.',
      userMessage: 'Usuarios administradores: Ilimitados',
      supportTime: 'Soporte por chat en tiempo real',
      buttonText: 'Probar premium gratis 7 días',
      buttonAction: handleStarPremium,
    },
  }
}

export default planDetail
