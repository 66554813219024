import Table from 'components/UI/Table/Table'

import { getColumnsDataIndex } from './helpers'

const ErrorIndex = ({ errors }) => {
  const columnsDataIndex = getColumnsDataIndex()

  return (
    <Table
      columns={columnsDataIndex}
      data={errors}
      options={{ toolbar: false, pagination: false }}
    />
  )
}

export default ErrorIndex
