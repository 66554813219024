import clsx from 'clsx'
import { useFormikContext } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import TermField from 'components/Worker/Contract/Fields/Term'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'
import WageCategoryField from 'components/Worker/Wage/Fields/Category'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2.5),
    columnGap: theme.spacing(4),
    [theme.breakpoints.up(740)]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  bottomSpace: {
    marginBottom: theme.spacing(4),
  },
  baseSalaryContainer: {
    gridColumn: '1 / -1',
  },
}))

const WorkerOnBoardingFormFields = () => {
  const classes = useStyles()
  const form = useFormikContext()
  const { values } = form

  return (
    <>
      <Typography variant="h6" color="primary" gutterBottom>
        Datos principales
      </Typography>
      <div className={clsx(classes.container, classes.bottomSpace)}>
        <FormField name="name" label="Nombre" />
        <FormField name="last_name" label="Apellidos" />
        <DocumentTypeField />
        <IdentificationNumberField />
      </div>
      <Typography variant="h6" color="primary" gutterBottom>
        Información laboral
      </Typography>
      <div className={classes.container}>
        <ContractCategoryField name="contract_category" />
        <TermField contractCategory={values.contract_category} />
        <InitialDayField />
        <EndDayField />
        <WageCategoryField
          name="wage_category"
          contractCategory={values.contract_category}
        />
        <div
          className={clsx({
            [classes.baseSalaryContainer]:
              !values.contract_category ||
              !values.term ||
              values.term === 'indefinite',
          })}
        >
          <BaseSalaryField contractCategory={values.contract_category} />
        </div>
      </div>
    </>
  )
}

export default WorkerOnBoardingFormFields
