import { useState } from 'react'

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import Button from 'components/UI/Button/Button'

import ActivationModal from './Modals/ActivationModal'

const WorkerShowActivateButton = ({ isWorker, worker, className }) => {
  const [showActivationModal, setShowActivationModal] = useState(false)
  return !isWorker && !worker?.terminated ? (
    <>
      <Button
        size="small"
        variant="outlined"
        startIcon={
          worker?.active ? <BlockOutlinedIcon /> : <RadioButtonCheckedIcon />
        }
        onClick={() => setShowActivationModal(true)}
        className={className}
      >
        {worker?.active ? 'Desactivar' : 'Activar'}
      </Button>
      {showActivationModal && (
        <ActivationModal onCancel={() => setShowActivationModal(false)} />
      )}
    </>
  ) : null
}

export default WorkerShowActivateButton
