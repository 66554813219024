import { createContext, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { getFreetimeRequests } from 'services/freetime/freetime'

import calendarLogo from 'assets/images/views/common/calendar_logo.png'

import NoveltiesRequestIndexToolbar from './Toolbar'
import { getColumnsData, getEmptyStateTitle, useTableActions } from './helpers'

const useStyles = makeStyles((theme) => ({
  chip: {
    width: 'fit-content',
  },
  approved: {
    backgroundColor: theme.palette.success.light,
    '& .MuiChip-label': {
      color: theme.palette.success.dark,
    },
  },
  rejected: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    '& .MuiChip-label': {
      color: 'rgba(255, 0, 0, 1)',
    },
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      color: theme.palette.warning.dark,
    },
  },
  sectionTable: {
    marginTop: theme.spacing(4),
  },
}))

export const FreeTimeRequestContext = createContext()
FreeTimeRequestContext.displayName = 'FreeTimeRequestContext'

const IndexTable = () => {
  const classes = useStyles()
  const actions = useTableActions()
  const downloadURI = useDownloadURI()
  const [filter, setFilter] = useState('') // Empty filter fetches all the request

  const fetchRequests = (pageIndex, search) => {
    return {
      queryKey: ['getAllRequests', pageIndex, search, filter],
      queryFunction: () =>
        getFreetimeRequests({ page: pageIndex + 1, search, filter }),
    }
  }

  const handleDownloadEvidence = (documentLink) => {
    downloadURI(documentLink)
  }

  const columns = getColumnsData(classes, handleDownloadEvidence)

  const contextValue = {
    filter,
    setFilter,
  }

  return (
    <FreeTimeRequestContext.Provider value={contextValue}>
      <section className={classes.sectionTable}>
        <Table
          data={fetchRequests}
          columns={columns}
          actions={actions}
          components={{ Toolbar: NoveltiesRequestIndexToolbar }}
          options={{
            customQueryFetch: 'novelty_requests',
            emptyState: {
              title: getEmptyStateTitle(filter),
              logo: calendarLogo,
              hideDescription: true,
            },
            customActionsWidth: '200px',
            alignActionsCell: 'center',
          }}
        />
      </section>
    </FreeTimeRequestContext.Provider>
  )
}

export default IndexTable
