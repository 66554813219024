import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  detail: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    justifyItems: 'flex-start',
    maxWidth: 774,
    marginTop: theme.spacing(6.25),
    paddingLeft: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '50%',
  },
}))

const ContainerDetail = ({ detail }) => {
  const classes = useStyles()
  return (
    <div className={classes.detail}>
      <CardContent className={classes.iconContainer}>{detail.icon}</CardContent>
      <CardContent>
        <Typography variant="body1">{detail.subtitle}</Typography>
        <Typography variant="subtitle1">{detail.body}</Typography>
      </CardContent>
    </div>
  )
}

export default ContainerDetail
