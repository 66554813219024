import Table from 'components/UI/Table/Table'

import { getAllPayments } from 'services/organizer/partner/paymentService'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

import { useHelpersPartnerPayments } from '../helpers'

const OrganizerPartnerPaymentTable = ({ partnerId }) => {
  const { showSuccessMessage } = useNotifications()
  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: { enabled: false },
  })

  const handleSendInvoice = (id, partner) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'POST',
        invoiceId: id,
        companyId: partner,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
        },
      }
    )
  }

  const fetchPayments = (page, search) => {
    return {
      queryKey: ['organizerPartnerPayments', partnerId, page + 1, search],
      queryFunction: () =>
        getAllPayments(partnerId, {
          search,
          page: page + 1,
        }),
    }
  }

  const { actions, columnsData } = useHelpersPartnerPayments(
    partnerId,
    handleSendInvoice
  )

  return <Table data={fetchPayments} columns={columnsData} actions={actions} />
}

export default OrganizerPartnerPaymentTable
