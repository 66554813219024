import { Form, Formik } from 'formik'
import qs from 'query-string'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import usePartnerPayment from 'utils/hooks/organizer/partner/paymentService'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import PaymentSummary from './Summary'
import PaymentsTable from './Table'
import {
  formatPartnerPaymentData,
  getInitialValues,
  validationSchema,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    width: 700,
  },
  buttonLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
}))

const OrganizerPartnerShowPaymentForm = ({ match, location: { state } }) => {
  const classes = useStyles()
  const history = useHistory()
  const partnerId = match.params?.partnerId
  const { showSuccessMessage } = useNotifications()
  const isEdit = state?.isEdit
  const { partnerPaymentQuery, partnerPaymentMutation } = usePartnerPayment({
    serviceParams: {
      queryKey: ['getPartnerInformation', partnerId],
      partnerId,
    },
  })
  const partnerPaymentData = formatPartnerPaymentData({
    data: partnerPaymentQuery.data || [],
    partner_info: partnerPaymentQuery.partnerInformation || {},
  })

  const redirectToPartner = () => {
    history.push({
      pathname: routes.ORGANIZER_PARTNER_SHOW(partnerId),
      search: qs.stringify({ tab: 'payments' }),
    })
  }

  const handleSubmit = (values) => {
    partnerPaymentMutation.mutate(
      {
        mutationMethod: 'PUT',
        partnerId,
        company: values,
      },
      {
        onSuccess: () => {
          showSuccessMessage(
            `Pago ${isEdit ? 'actualizado' : 'creado'} con éxito.`
          )
          redirectToPartner()
        },
      }
    )
  }

  const initialValues = getInitialValues(partnerPaymentData?.partner_info)

  return (
    <Page
      documentTitle={`${isEdit ? 'Editar' : 'Crear'} pago - Aliado`}
      header={`${isEdit ? 'Editar' : 'Crear'} pago`}
      isLoading={partnerPaymentQuery.isLoading}
    >
      <PaymentsTable paymentsData={partnerPaymentData.data || []} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form>
              <Box m={2} display="flex" justifyContent="flex-end">
                <div className={classes.summaryContainer}>
                  <PaymentSummary
                    values={values}
                    summary={partnerPaymentData.partner_info}
                  />

                  <div className={classes.buttonLayout}>
                    <Button
                      variant="outlined"
                      disabled={partnerPaymentMutation.isLoading}
                      onClick={redirectToPartner}
                    >
                      Cancelar
                    </Button>

                    <Button
                      type="submit"
                      size="large"
                      loading={partnerPaymentMutation.isLoading}
                    >
                      Guardar
                    </Button>
                  </div>
                </div>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Page>
  )
}

export default OrganizerPartnerShowPaymentForm
