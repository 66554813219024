import RecurrentConceptsContent from './RecurrentConcepts/RecurrentConceptsContent'
import AttachedDocumentsContainer from './Tabs/AttachedDocumentsContainer'
import BeneficiariesContainer from './Tabs/BeneficiariesContainer'
import EmployerContainer from './Tabs/EmployerContainer/EmployerContainer'
import LaboralDataContent from './Tabs/LaboralDataContent'
import PersonalDataContent from './Tabs/PersonalDataContainer'

const getTabsData = (isFromAffiliationView) => {
  const TABS = {
    common_view: [
      { label: 'Datos laborales', content: <LaboralDataContent /> },
      { label: 'Datos personales', content: <PersonalDataContent /> },
      { label: 'Conceptos recurrentes', content: <RecurrentConceptsContent /> },
    ],
    afiliation_view: [
      { label: 'Datos personales', content: <PersonalDataContent /> },
      { label: 'Administradoras', content: <LaboralDataContent /> },
      { label: 'Documentos adjuntos', content: <AttachedDocumentsContainer /> },
      { label: 'Beneficiarios', content: <BeneficiariesContainer /> },
      { label: 'Empleador', content: <EmployerContainer /> },
    ],
  }

  return isFromAffiliationView ? TABS.afiliation_view : TABS.common_view
}

export default getTabsData
