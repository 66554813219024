import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import GoBackLink from 'components/UI/LinkNav/GoBackLink'

import ErrorIndex from './Content/ErrorIndex'

const useStyles = makeStyles((theme) => ({
  goBackLink: {
    marginBottom: theme.spacing(2.5),
  },
  electronicDetail: {
    marginBottom: theme.spacing(1.5),
  },
}))

const ModalRejected = ({ closeModal, data }) => {
  const [step, setStep] = useState(0)

  const classes = useStyles()

  const {
    electronic_payrolls_counter: { rejected },
    errors,
  } = data

  const handleClickReturn = () => {
    setStep(0)
  }

  const stepContent = [
    {
      title: 'Listado de nóminas electrónicas rechazadas',
      description: (
        <span>
          Total de nóminas electrónicas rechazadas: <b>{rejected}</b>
        </span>
      ),
      content: <ErrorIndex errors={errors} />,
    },
  ]

  return (
    <Modal
      onCloseModal={closeModal}
      header={
        <div>
          {step === 1 ? (
            <GoBackLink
              component="button"
              onClick={handleClickReturn}
              className={classes.goBackLink}
            >
              Regresar
            </GoBackLink>
          ) : null}
          <Typography variant="h5">{stepContent[step].title}</Typography>
          <Typography className={classes.electronicDetail}>
            {stepContent[step].description}
          </Typography>
          <Link
            href="https://ayuda.nominapp.com/help/hc/es-419/articles/4407843781531-n%C3%B3mina-electr%C3%B3nica-en-estado-rechazado-como-verificar-el-estado-de-emisi%C3%B3n-y-listado-de-errores-"
            target="_blank"
          >
            &#128073; Psst. Mira aquí qué significa cada error y cómo
            solucionarlo
          </Link>
        </div>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
      hideCancelButton
      okText="Continuar"
      onOk={closeModal}
    >
      {stepContent[step].content}
    </Modal>
  )
}

export default ModalRejected
