import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DropDownPeriod from '../Payroll/DropDownPeriod'

const useStyles = makeStyles((theme) => ({
  titleHeader: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}))
const ElectronicPayrollHeader = ({ title, period }) => {
  const classes = useStyles()
  return (
    <div className={classes.titleHeader}>
      <Typography variant="h5">{title}</Typography>
      {period ? <DropDownPeriod period={period} electronicPayroll /> : null}
    </div>
  )
}

export default ElectronicPayrollHeader
