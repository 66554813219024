import { useContext, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import MuiLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'

import { PeriodContext } from 'components/Period/Payroll/Payroll'
import AddButton from 'components/UI/Button/AddButton'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Modal from 'components/UI/Modal/Modal'

import { isObjectEmpty, wait } from 'utils/general'
import useItemsService from 'utils/hooks/payroll/items'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import {
  generateEmptyPayrollItem,
  nonSalaryIncomeRecurrentConcepts,
  salaryIncomeRecurrentConcepts,
} from 'utils/payroll'
import { getGeneralContractCategory } from 'utils/worker'

import OptionSelector from '../../../../common/OptionSelector'
import CreatePayrollConceptModal from '../CreatePayrollConceptModal'
import CategoryHeader from '../common/CategoryHeader'
import Info from '../common/Info'
import useContentStyles from '../common/commonStyles'
import ConnectivityAidInfo from './ConnectivityAidInfo'
import { getTabs, incomeDescription } from './helpers'
import useIncomeModal from './useIncomeModal'

const useStyles = makeStyles({
  paper: {
    width: 750,
  },
})

const DataTableIncomeModal = ({ payroll, handleCloseModal, showModal }) => {
  const queryClient = useQueryClient()
  const { getInitialData } = useIncomeModal()

  const { updatePeriodCallback } = useContext(PeriodContext)

  const generalContractCategory = getGeneralContractCategory(
    payroll.contract_category
  )

  const isContractorApprenticeOrStudent = [
    'contractor',
    'apprentice',
    'student',
  ].includes(generalContractCategory)

  const [initialData, setInitialData] = useState({})
  const [incomes, setIncomes] = useState({
    salary_income: [],
    non_salary_income: [],
  })
  const [concepts, setConcepts] = useState({
    salary_income: [],
    non_salary_income: [],
  })
  const [connectivityAidDays, setConnectivityAidDays] = useState(
    payroll.connectivity_aid_days
  )

  const [
    isCreatePayrollConceptModalOpen,
    setIsCreatePayrollConceptModalOpen,
  ] = useState(false)
  const [activeTab, setActiveTab] = useState(
    isContractorApprenticeOrStudent ? 'non_salary_income' : 'salary_income'
  )
  const [deletedItems, setDeletedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { handleError } = useErrorHandler()
  const confirm = useConfirm()
  const modalClasses = useStyles()
  const contentClasses = useContentStyles()
  const salaryIncomeQueryKey = ['payrollConcepts', 'salary_income', payroll.id]
  const nonSalaryIncomeQueryKey = [
    'payrollConcepts',
    'non_salary_income',
    payroll.id,
  ]
  const salaryIncomeItemsQueryKey = [
    'itemsByCategory',
    'salary_income',
    payroll.id,
  ]
  const nonSalaryIncomeItemsQueryKey = [
    'itemsByCategory',
    'non_salary_income',
    payroll.id,
  ]

  const {
    payrollConceptsQuery: salaryConceptsQuery,
  } = usePayrollConceptsService({
    serviceParams: {
      queryKey: salaryIncomeQueryKey,
      conceptsCategory: 'salary_income',
    },
  })

  const {
    payrollConceptsQuery: nonSalaryConceptsQuery,
  } = usePayrollConceptsService({
    serviceParams: {
      queryKey: nonSalaryIncomeQueryKey,
      conceptsCategory: 'non_salary_income',
    },
  })

  const { itemsQuery: salaryItemsQuery } = useItemsService({
    serviceParams: {
      queryKey: salaryIncomeItemsQueryKey,
      payrollId: payroll.id,
      conceptsCategory: 'salary_income',
    },
  })

  const { itemsQuery: nonSalaryItemsQuery } = useItemsService({
    serviceParams: {
      queryKey: nonSalaryIncomeItemsQueryKey,
      payrollId: payroll.id,
      conceptsCategory: 'non_salary_income',
    },
    queryOptions: {
      enabled:
        salaryConceptsQuery.isSuccess &&
        nonSalaryConceptsQuery.isSuccess &&
        salaryItemsQuery.isSuccess,
      onSuccess: ({ data: nonSalaryItemsData }) => {
        const initialModalData = getInitialData(
          salaryConceptsQuery.data,
          nonSalaryConceptsQuery.data,
          salaryItemsQuery.data,
          nonSalaryItemsData
        )

        setInitialData(initialModalData)
        setConcepts({
          salary_income: initialModalData.salaryIncomeConcepts,
          non_salary_income: initialModalData.nonSalaryIncomeConcepts,
        })
        setIncomes({
          salary_income: initialModalData.salary_income,
          non_salary_income: initialModalData.non_salary_income,
        })
      },
    },
  })

  const { itemsMutation } = useItemsService({
    queryOptions: { enabled: false },
  })

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const isGettingData =
    salaryConceptsQuery.isLoading ||
    nonSalaryConceptsQuery.isLoading ||
    salaryItemsQuery.isLoading ||
    nonSalaryItemsQuery.isLoading

  const tabs = useMemo(() => getTabs(payroll), [payroll])

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const handleSelectItem = (payrollConceptId, index, category) => {
    const prevItem = incomes[category][index]

    const newConcepts = { ...concepts }
    let selectedConcept

    newConcepts[category] = newConcepts[category].map((concept) => {
      const conceptCopy = { ...concept }

      if (prevItem.payroll_concept_id === conceptCopy.id)
        conceptCopy.selected = false

      if (conceptCopy.id === payrollConceptId) {
        conceptCopy.selected = true

        selectedConcept = conceptCopy
      }

      return conceptCopy
    })

    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    const newItem = {
      ...prevItem,
      name: selectedConcept.name,
      payroll_concept_id: selectedConcept.id,
      coded_name: selectedConcept.coded_name,
    }

    newIncomes[category][index] = newItem

    setConcepts(newConcepts)
    setIncomes(newIncomes)
  }

  const handleChangeItemValue = async (e, index, category) => {
    const value = Number(e.target.rawValue)

    const oldIncomes = incomes
    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    newIncomes[category][index] = { ...newIncomes[category][index], value }

    setIncomes(newIncomes)

    if (category === 'non_salary_income') {
      const totalPayrollIncome =
        payroll.base_salary +
        payroll.overtime_value +
        payroll.novelties_value +
        payroll.salary_income +
        payroll.non_salary_income +
        value

      if (value > Math.ceil(totalPayrollIncome * 0.4)) {
        await wait(500)
        confirm({
          category: 'warning',
          description: (
            <>
              Estás ingresando bonificaciones por encima del 40% de los ingresos
              salariales. Al hacerlo Nominapp calculará de nuevo la base para la
              liquidación de aportes a seguridad social siguiendo lo estipulado
              en la Ley 1393 de 2010. Para conocer más al respecto haz{' '}
              <MuiLink
                href="https://ayuda.nominapp.com/help/nominapp-calcula-los-pagos-no-salariales-que-exceden-el-40-del-total-de-la-remuneracion"
                target="_blank"
              >
                click aquí
              </MuiLink>
              .
            </>
          ),
          okText: 'Sí, continuar',
          onCancel: () => setIncomes(oldIncomes), // revert the value changed
        })
      }
    }
  }

  const handleAddConcept = (category) => {
    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    newIncomes[category].push(generateEmptyPayrollItem())

    setIncomes(newIncomes)
  }

  const handleDeleteItem = (index, category) => {
    const newIncomes = { ...incomes }

    newIncomes[category] = newIncomes[category].slice(0)

    const itemToDelete = newIncomes[category][index]

    newIncomes[category].splice(index, 1)

    const incomesRecurrentConcepts = salaryIncomeRecurrentConcepts.concat(
      nonSalaryIncomeRecurrentConcepts
    )

    if (!incomesRecurrentConcepts.includes(itemToDelete.coded_name)) {
      setDeletedItems([...deletedItems, itemToDelete])
    }
    if (itemToDelete.payroll_concept_id !== null) {
      const newConcepts = { ...concepts }

      newConcepts[category] = newConcepts[category].map((concept) => {
        const conceptCopy = { ...concept }

        if (itemToDelete.payroll_concept_id === conceptCopy.id)
          conceptCopy.selected = false

        return conceptCopy
      })
      setConcepts(newConcepts)
    }

    if (newIncomes[category].length === 0) {
      newIncomes[category].push(generateEmptyPayrollItem())
    }

    setIncomes(newIncomes)
  }

  const handleCloseCreatePayrollConceptModal = (createdConcept, category) => {
    if (createdConcept) {
      const newConcepts = {
        ...concepts,
        [category]: [
          ...concepts[category],
          { ...createdConcept, selected: true },
        ],
      }

      setConcepts(newConcepts)

      const newIncomes = {
        ...incomes,
        [category]: [
          ...incomes[category],
          generateEmptyPayrollItem(createdConcept.id, createdConcept.name),
        ],
      }

      setIncomes(newIncomes)
    }

    setIsCreatePayrollConceptModalOpen(false)
  }

  const onCloseModal = () => {
    handleCloseModal()
    queryClient.removeQueries(salaryIncomeQueryKey)
    queryClient.removeQueries(nonSalaryIncomeQueryKey)
    queryClient.removeQueries(salaryIncomeItemsQueryKey)
    queryClient.removeQueries(nonSalaryIncomeItemsQueryKey)
  }

  const handleSendData = async () => {
    setIsLoading(true)
    const dataToSend = [...deletedItems]
    const incomesRecurrentConcepts = salaryIncomeRecurrentConcepts.concat(
      nonSalaryIncomeRecurrentConcepts
    )

    Object.keys(incomes).forEach((k) => {
      const incomesType = incomes[k]
      const initIncomes = initialData[k]?.map((i) => ({ ...i }))

      // Compare initial incomes with current incomes to get the changed ones
      incomesType.forEach((inc) => {
        let incChecked = false
        initIncomes.some((initInc) => {
          if (
            inc.payroll_concept_id &&
            inc.payroll_concept_id === initInc.payroll_concept_id
          ) {
            if (inc.value !== initInc.value) {
              dataToSend.push({
                ...inc,
                id: initInc.id,
              })
            }
            // Have to disable this line because this key is needed out of loop
            // eslint-disable-next-line no-param-reassign
            initInc.checked = true
            incChecked = true

            return true
          }
          return false
        })

        let addToDataSend
        if (incomesRecurrentConcepts.includes(inc.coded_name)) {
          addToDataSend = !incChecked && inc.payroll_concept_id && inc.value > 0
        } else {
          addToDataSend = !incChecked && inc.payroll_concept_id
        }
        if (addToDataSend) {
          dataToSend.push(inc)
        }
      })

      // Those not checked are added to deletion
      initIncomes?.forEach((initInc) => {
        if (!initInc.checked && initInc.payroll_concept_id) {
          dataToSend.push({
            id: initInc.id,
            payroll_concept_id: initInc.payroll_concept_id,
            value: 0,
          })
        }
      })
    })

    let dataResponse = null

    if (dataToSend.length > 0) {
      await itemsMutation.mutateAsync(
        {
          mutationMethod: 'PUT',
          payrollId: payroll.id,
          deductionItem: dataToSend,
        },
        {
          onSuccess: ({ data }) => {
            dataResponse = data
            trackEvent(integrationEvent.PAYROLL_ADD_BONUS)
          },
          onError: (error) => handleError(error),
        }
      )
    }

    if (payroll.connectivity_aid_days !== connectivityAidDays) {
      dataResponse = await payrollMutation.mutateAsync(
        {
          mutationMethod: 'PUT_CONNECTIVITY_AIDS',
          payrollId: payroll.id,
          quantity: connectivityAidDays,
        },
        {
          onError: (error) => handleError(error),
        }
      )

      dataResponse = dataResponse.data
      dataResponse.payroll.connectivity_aid_days =
        dataResponse.connectivity_aid.quantity
    }

    setIsLoading(false)
    onCloseModal()
    updatePeriodCallback(dataResponse)
  }

  return (
    <Modal
      open={showModal}
      header="Ingresos"
      onOk={handleSendData}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isLoading}
      dialogProps={{
        'data-cy': 'income_modal',
        scroll: 'body',
        maxWidth: false,
        classes: modalClasses,
      }}
      disableOkButton={connectivityAidDays > 30}
    >
      <Paper>
        <Tabs value={activeTab} onChange={handleChangeTab} tabs={tabs} />
        <div className={contentClasses.root}>
          <Typography paragraph>{incomeDescription[activeTab]}</Typography>
          <CategoryHeader
            valueText={activeTab === 'connectivity_aid' ? 'Días' : 'Valor'}
          />
          {!isGettingData && !isObjectEmpty(initialData) ? (
            <>
              <div id={`${activeTab}-items`}>
                {activeTab === 'salary_income' &&
                  !isContractorApprenticeOrStudent && (
                    <OptionSelector
                      data={incomes.salary_income}
                      listOptions={concepts.salary_income}
                      onSelectItem={handleSelectItem}
                      onChangeItemValue={handleChangeItemValue}
                      onDeleteItem={handleDeleteItem}
                      category="salary_income"
                    />
                  )}
                {activeTab === 'non_salary_income' && (
                  <OptionSelector
                    data={incomes.non_salary_income}
                    listOptions={concepts.non_salary_income}
                    onSelectItem={handleSelectItem}
                    onChangeItemValue={handleChangeItemValue}
                    onDeleteItem={handleDeleteItem}
                    category="non_salary_income"
                  />
                )}
                {activeTab === 'connectivity_aid' && (
                  <FormControl className={contentClasses.itemContainer}>
                    <Typography
                      htmlFor="connectivityAidDaysInput"
                      component="label"
                    >
                      Auxilio de conectividad
                    </Typography>
                    <TextField
                      error={connectivityAidDays > 30}
                      helperText={
                        connectivityAidDays > 30
                          ? 'El valor ingresado debe ser inferior a 30 días'
                          : null
                      }
                      id="connectivityAidDaysInput"
                      value={connectivityAidDays}
                      onChange={(e) =>
                        setConnectivityAidDays(Number(e.target.rawValue))
                      }
                      InputProps={{ inputComponent: NumberFormatField }}
                    />
                  </FormControl>
                )}
              </div>
              {activeTab !== 'connectivity_aid' && (
                <Box display="flex">
                  <AddButton
                    onClick={() => handleAddConcept(activeTab)}
                    disabled={
                      concepts[activeTab].length < incomes[activeTab].length
                    }
                  >
                    Agregar concepto
                  </AddButton>

                  <Button
                    variant="text"
                    startIcon={<OpenInNewOutlinedIcon />}
                    onClick={() => {
                      setIsCreatePayrollConceptModalOpen(true)
                    }}
                  >
                    Crear nuevo concepto
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <LoadingBox />
          )}
        </div>
      </Paper>
      {isCreatePayrollConceptModalOpen && (
        <CreatePayrollConceptModal
          category={activeTab}
          handleClose={handleCloseCreatePayrollConceptModal}
        />
      )}
      <br />
      {activeTab === 'connectivity_aid' ? (
        generalContractCategory !== 'contractor' && <ConnectivityAidInfo />
      ) : (
        <Info />
      )}
    </Modal>
  )
}

export default DataTableIncomeModal
