import { useState } from 'react'

import { isAccountant, isAdmin, isOrganizer, isWorker } from 'utils/auth'
import { isFreemiumCompany } from 'utils/freemium'

import useModals from '../ModalsManager/useModals'
import { useUser } from '../UserContext/useUser'
import PremiumFeature from './PremiumFeature'
import { premiumModals } from './helpers'

const usePremiumFeature = (feature, multicompanyWorker) => {
  const { subscription, user } = useUser()
  const modals = useModals()
  const [featureModal, setFeatureModal] = useState(() => premiumModals[feature])
  const withPremiumAction = subscription?.status === 'basic'

  const getIsPremiumFeature = () => {
    if (isAdmin() || isAccountant())
      return isFreemiumCompany(
        subscription?.status,
        subscription?.payment_status
      )

    if (isOrganizer()) return false

    if (isWorker()) {
      if (multicompanyWorker !== undefined) {
        return !multicompanyWorker
      }

      return !user.isFromPremiumCompany
    }

    return true
  }

  const isPremiumFeatureIcon = getIsPremiumFeature()

  const openModal = (newFeature) => {
    const newFeatureModal =
      newFeature && newFeature !== feature
        ? premiumModals[newFeature]
        : featureModal
    setFeatureModal(newFeatureModal)
    if (newFeatureModal) {
      modals.openModal({
        id: `premiumFeatureModal`,
        content: (
          <PremiumFeature
            title={newFeatureModal.title}
            description={newFeatureModal.description}
            highlightIndex={newFeatureModal.featureId}
            withPremiumAction={withPremiumAction}
            feature={feature}
          />
        ),
        modalProps: {
          header: newFeatureModal?.header || '¡Oops!',
          footer: null,
          dialogProps: {
            fullWidth: true,
            maxWidth: 'md',
          },
        },
      })
    }
  }

  if (!featureModal) {
    throw new Error(`Feature ${feature} is not premium`)
  }

  return {
    openModal,
    isPremiumFeature: getIsPremiumFeature(),
    isPremiumFeatureIcon,
  }
}

export default usePremiumFeature
