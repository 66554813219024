import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import Button from 'components/UI/Button/Button'

const useStyles = makeStyles((theme) => ({
  buttonsModal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
  },
  button: {
    marginRight: theme.spacing(2),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const Footer = ({
  primaryText,
  secondaryText,
  onClickPrimary,
  onClickSecondary,
  status,
  code,
  footerText = false,
  page = false,
}) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.buttonsModal}>
        {secondaryText ? (
          <Button
            component={Link}
            href={
              status === 'premium_due' || code === '0007'
                ? 'https://api.whatsapp.com/send/?phone=573007405537&text&app_absent=0'
                : null
            }
            target="_blank"
            size={page ? 'medium' : 'small'}
            onClick={onClickSecondary}
            variant="outlined"
            className={classes.button}
          >
            {secondaryText}
          </Button>
        ) : null}
        <Button size={page ? 'medium' : 'small'} onClick={onClickPrimary}>
          {primaryText}
        </Button>
      </div>
      {footerText && (
        <Typography variant="caption">
          Relax, no te cobraremos nada por dar una vuelta en nominapp Premium.
        </Typography>
      )}
    </div>
  )
}

export default Footer
