import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import StatusBanner from 'components/Benefits/Atoms/StatusBanner'
import Loading from 'components/UI/Loading/Loading'

import crossedDolarSign from 'assets/images/views/worker/crossed_dolar_sign.png'
import dolarSign from 'assets/images/views/worker/dolar_sign.png'
import checkDolarSign from 'assets/images/views/worker/check_dolar_sign.png'

import { WORKER_BENEFITS_CREDIT_FORM } from 'config/routes'

import CreditDetails from './CreditDetails'
import { useCreditDetail } from './helpers'

const FillApplicationFormAction = () => {
  const history = useHistory()

  const onClick = () => {
    history.push(WORKER_BENEFITS_CREDIT_FORM())
  }

  return <Button onClick={onClick}>Solicitar crédito</Button>
}

const BenefitsIndex = ({ usedByAdmin = false, creditStatusData }) => {
  const creditStatusActive = creditStatusData?.status === 'active'

  const { creditDetailData, isGettingCreditDetail } = useCreditDetail(
    creditStatusData?.id,
    creditStatusActive,
    creditStatusData?.workerId
  )

  if (creditStatusData?.status === 'without_preapproved_credit') {
    return (
      <StatusBanner
        title="Aún no tienes un crédito preaprobado"
        message="Cuando tengas un crédito preaprobado te enviaremos un correo electrónico para que puedas iniciar tu solicitud. Con tu crédito de libranza el valor de tus cuotas será descontado de tu nómina de forma automática."
        image={crossedDolarSign}
        imageAlt="Image you have not sent any request"
      />
    )
  }

  if (
    ['missing_simulation', 'information_not_sended'].includes(
      creditStatusData?.status
    )
  ) {
    return (
      <StatusBanner
        title="¿Aún no has solicitado tu crédito?"
        message="Con tu crédito de libranza el valor de tus cuotas será descontado de tu nómina de forma automática. "
        image={dolarSign}
        imageAlt="Image you have not sent any request"
        action={<FillApplicationFormAction />}
      />
    )
  }

  if (creditStatusData?.status === 'information_sended') {
    return (
      <StatusBanner
        title="Ya tienes una solicitud en trámite"
        message="Has iniciado una solicitud de crédito, tan pronto tengamos información del estado de tu solicitud te estaremos comunicando por correo electrónico."
        image={checkDolarSign}
        imageAlt="Image you already have an application in process"
      />
    )
  }

  if (isGettingCreditDetail) return <Loading />

  if (creditStatusData?.status === 'active') {
    return <CreditDetails data={creditDetailData} usedByAdmin={usedByAdmin} />
  }

  return null
}

export default BenefitsIndex
