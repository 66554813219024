import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Step from '@material-ui/core/Step'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    opacity: 0.32,
    ...theme.typography.subtitle2,
  },
  stepper: {
    padding: 0,
  },
  defaultContainer: {
    width: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(3),
    width: '100%',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '32px 1fr 32px',
      padding: theme.spacing(3, 0),
      columnGap: theme.spacing(5),
    },
  },
  horizontal: {
    gridTemplateColumns: '32px 1fr 32px',
    padding: theme.spacing(3, 0),
  },
  arrowButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '50%',
  },
  leftArrow: {
    transform: 'rotate(180deg)',
  },
  stepperContainer: {
    maxWidth: 400,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 4),
    },
  },
  outlinedStep: {
    padding: theme.spacing(2, 6.75, 2, 1.5),
    borderRadius: theme.spacing(1.25),
    border: `2px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.common.white,
  },
  outlinedStepActive: {
    padding: theme.spacing(2, 6.75, 2, 1.5),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.25),
    backgroundColor: theme.palette.common.white,
  },
  iconContainer: {
    paddingRight: 0,
  },
  hideArrow: {
    visibility: 'hidden',
  },
  muiStepConnector: {
    '& .MuiStepConnector-vertical': {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(3.375),
      '& > *': {
        borderLeftWidth: '2px',
      },
    },
  },
}))

const getStepStatus = (index, current, progress) => {
  if (index === current) return 'current'
  if (index < progress) return 'completed'
  if (index > progress) return 'disabled'
  return 'pending'
}

const Steps = ({
  current = 0,
  progress,
  onChangeStep: onClickStep,
  stepsData,
  style,
  arrows = false,
  leftArrowProps = {},
  rightArrowProps = {},
  orientation = undefined,
  outlined = false,
  ...props
}) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { hidden: hideLeftArrow = false } = leftArrowProps
  const { hidden: hideRightArrow = false } = rightArrowProps

  const orientationStepper = smUp ? 'horizontal' : 'vertical'

  return (
    <div
      className={clsx({
        [classes.defaultContainer]: !arrows,
        [classes.container]: arrows,
        [classes.horizontal]: orientation === 'horizontal',
      })}
    >
      {arrows ? (
        <IconButton
          data-cy="previous-step"
          size="small"
          className={clsx(classes.arrowButton, {
            [classes.hideArrow]: hideLeftArrow,
          })}
          {...leftArrowProps}
        >
          <ArrowForwardIosIcon
            fontSize="inherit"
            className={classes.leftArrow}
          />
        </IconButton>
      ) : null}
      <Stepper
        activeStep={current}
        style={style}
        orientation={orientation || orientationStepper}
        {...props}
        className={clsx({
          [classes.stepper]: arrows,
          [classes.muiStepConnector]: outlined,
        })}
      >
        {stepsData.map((step, index) => {
          const { title, description } = step

          const stepStatus = getStepStatus(index, current, progress)

          const completed = stepStatus === 'completed'
          const disabled = stepStatus === 'disabled'

          const stepLabelProps = {}

          // this icon is only for not completed and not active step
          // to achieve the desired style of the components library
          if (!completed && current !== index)
            stepLabelProps.icon = (
              <div className={classes.iconRoot}>{index + 1}</div>
            )

          return (
            <Step
              key={title}
              disabled={disabled}
              completed={completed}
              className={clsx({
                [classes.outlinedStep]: outlined,
                [classes.outlinedStepActive]:
                  outlined && stepStatus === 'current',
              })}
            >
              {onClickStep ? (
                <StepButton
                  onClick={() => !disabled && onClickStep(index)}
                  {...stepLabelProps}
                >
                  {description}
                </StepButton>
              ) : (
                <StepLabel
                  {...stepLabelProps}
                  classes={{
                    iconContainer: clsx({
                      [classes.iconContainer]: !description,
                    }),
                  }}
                >
                  {description}
                </StepLabel>
              )}
            </Step>
          )
        })}
      </Stepper>
      {arrows ? (
        <IconButton
          data-cy="next-step"
          size="small"
          className={clsx(classes.arrowButton, {
            [classes.hideArrow]: hideRightArrow,
          })}
          {...rightArrowProps}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </div>
  )
}

export default Steps
