import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import DeductionsSection from './Sections/DeductionsSection'
import IncomesSection from './Sections/IncomesSection'

const getContent = (
  modalType,
  worker,
  onCancel,
  handleError,
  showSuccessMessage,
  refreshRecurrentConcepts,
  loansMutation,
  recurrentConceptsMutation
) => {
  return modalType === 'incomes'
    ? {
        title: 'Agregar Ingreso Recurrente',
        body: <IncomesSection />,
        initialValues: {
          category: 'salary_income',
          payroll_concept_id: '',
          monthly_value: 0,
          active: true,
        },
        handleSubmit: (values) => {
          const valuesToSend = {
            ...values,
            payroll_concept_id: values.payroll_concept_id.id,
          }

          recurrentConceptsMutation.mutate(
            {
              mutationMethod: 'PUT',
              workerId: worker.id,
              recurrentConcepts: [valuesToSend],
            },
            {
              onSuccess: () => {
                trackEvent(
                  integrationEvent.EMPLOYEE_ADD_RECURRENT_CONCEPT,
                  values.category
                )
                refreshRecurrentConcepts()
                showSuccessMessage('El concepto recurrente ha sido creado.')
                onCancel()
              },
            }
          )
        },
      }
    : {
        title: 'Agregar un préstamo o deducción',
        body: <DeductionsSection />,
        initialValues: {
          category: 'deductions',
          payroll_concept_id: '',
          active: true,
        },
        handleSubmit: async (values) => {
          const { category } = values
          const valuesToSend = {
            ...values,
          }
          valuesToSend.payroll_concept_id = values.payroll_concept_id.id

          try {
            if (category === 'deductions') {
              await recurrentConceptsMutation.mutateAsync(
                {
                  mutationMethod: 'PUT',
                  workerId: worker.id,
                  recurrentConcepts: [valuesToSend],
                },
                {
                  onSuccess: () =>
                    showSuccessMessage(
                      'El concepto recurrente ha sido creado.'
                    ),
                }
              )
            } else {
              valuesToSend.monthly_fee = values.monthly_fee.toString()
              valuesToSend.value = values.value.toString()

              await loansMutation.mutateAsync(
                {
                  mutationMethod: 'PUT',
                  workerId: worker.id,
                  loans: [valuesToSend],
                },
                {
                  onSuccess: () =>
                    showSuccessMessage(
                      'El concepto recurrente ha sido creado.'
                    ),
                }
              )
            }

            refreshRecurrentConcepts()
            trackEvent(
              integrationEvent.EMPLOYEE_ADD_RECURRENT_CONCEPT,
              category
            )
            onCancel()
          } catch (error) {
            handleError(error)
          }
        },
      }
}

export default getContent
