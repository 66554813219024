import { useFormikContext } from 'formik'

import { useUser } from 'components/App/UserContext/useUser'

import FormField from '../FormField/Index'

const AccountNumberField = (props) => {
  const { values } = useFormikContext()
  const { subscription = {} } = useUser()
  const { showPlaceholder = false } = props

  const getPlaceholder = () => {
    if (
      subscription?.payment_category === 'automatic_debit' &&
      subscription?.account_number
    ) {
      return `*********${subscription?.account_number?.slice(-4)}`
    }
    return ''
  }

  return (
    <FormField
      name="account_number"
      label="Número de cuenta"
      placeholder={showPlaceholder ? getPlaceholder() : undefined}
      variant="number"
      decimalScale={0}
      isNumericString
      allowLeadingZeros
      inputProps={{ maxLength: 20 }}
      style={{ fontFamily: 'Roboto Mono, monospace' }}
      format={
        values.account_number?.length >= 9
          ? '###-######-########'
          : '###-######'
      }
      {...props}
    />
  )
}

export default AccountNumberField
