import { useContext } from 'react'
import { ModalsContext } from './ModalsProvider'

const useModals = () => {
  const context = useContext(ModalsContext)

  if (!context) {
    throw new Error('useModals must be used within a ModalsProvider')
  }
  return context
}

export default useModals
