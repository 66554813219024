import { useMemo } from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Button from 'components/UI/Button/Button'

import { SUBSCRIPTION } from 'config/routes'

import getItems from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
  item: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    '& p': {
      color: theme.palette.grey[500],
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}))

const CurrentPlan = ({ subscriptionData, currentPlan }) => {
  const classes = useStyles()
  const isActive = ['active', 'active_premium', 'exonerated_payment'].includes(
    subscriptionData?.status
  )
  const isOldPlan = subscriptionData?.plan_category === 'workers_range_plan'
  const items = useMemo(() => getItems(currentPlan, subscriptionData), [
    currentPlan,
    subscriptionData,
  ])

  return (
    <Card className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h5">Mi plan: {currentPlan?.name}</Typography>
          <Chip
            className={classes.chip}
            label={
              <Typography className={classes.chipContainer}>
                {isActive ? 'Activo' : 'Inactivo'}{' '}
                <CheckCircleIcon fontSize="small" color="primary" />
              </Typography>
            }
          />
        </div>
        {isActive || isOldPlan ? (
          <Button size="small" component={RouterLink} to={SUBSCRIPTION()}>
            Actualizar
          </Button>
        ) : null}
      </div>
      <div>
        {items.map((item) => {
          const isTotalItem = item.id === 'total'

          return (
            <div
              key={item.id}
              className={clsx(classes.item, !isTotalItem && classes.divider)}
            >
              <Typography variant={isTotalItem ? 'subtitle1' : 'body1'}>
                {item.label}
              </Typography>
              <Typography variant="subtitle1">{item.value}</Typography>
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default CurrentPlan
