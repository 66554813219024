import useLocalStorage from '@rehooks/local-storage'
import { useState } from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import { getEmptyStateTitle } from 'components/Worker/SSAffiliations/Index/helpers'

import { getAllAffiliations } from 'services/organizer/affiliations'

import addPersonLogo from 'assets/images/views/worker/add_person.png'

import { getActions, getColumns, getTabsData } from './helpers'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(7),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
}))

const OrganizerAffiliationsIndex = () => {
  const [, setCompanyId] = useLocalStorage('company_id')
  const [filter, setFilter] = useState('waiting_response')
  const classes = useStyles()
  const [totals, setTotals] = useState({})

  const handleChange = (_, newValue) => setFilter(newValue)

  const handleOpenAffiliation = (companyId) => {
    setCompanyId(companyId)
  }

  const columns = getColumns(handleOpenAffiliation)
  const actions = getActions(handleOpenAffiliation)

  const fetchAffiliations = (pageIndex, search) => {
    return {
      queryKey: ['getAllAffiliations', pageIndex, search, filter],
      queryFunction: () =>
        getAllAffiliations({
          searchParams: { page: pageIndex + 1, search, filter },
        }),
      queryOptions: {
        onSuccess: ({ data }) => setTotals(data.totals),
      },
    }
  }

  return (
    <Page
      documentTitle="Afiliaciones a Seguridad Social"
      header="Afiliaciones a Seguridad Social"
    >
      <Typography className={classes.description}>
        Acá puedes gestionar las afiliaciones a Seguridad, pensión y cesantías
        de las empresas.
      </Typography>
      <Tabs
        value={filter}
        onChange={handleChange}
        tabs={getTabsData(totals, classes.chip)}
        variant="scrollable"
        backgroundColor="white"
      />
      <Table
        data={fetchAffiliations}
        columns={columns}
        options={{
          alignActionsCell: 'center',
          customQueryFetch: 'affiliations',
          emptyState: {
            title: getEmptyStateTitle(filter),
            hideDescription: true,
            logo: addPersonLogo,
          },
        }}
        actions={actions}
      />
    </Page>
  )
}

export default OrganizerAffiliationsIndex
