import nominappColors from './nominapp'

export default {
  ...nominappColors,
  PRIMARY: {
    900: '#00110f',
    700: '#00463e',
    600: '#008e79',
    500: '#00B19D',
    400: '#7fd8ce',
    200: '#bde3dc',
  },
  SECONDARY: {
    // the same as PRIMARY
    900: '#00110f',
    700: '#00463e',
    600: '#008e79',
    500: '#00B19D',
    400: '#7fd8ce',
    200: '#bde3dc',
  },
}
