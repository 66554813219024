import { useState } from 'react'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Page from 'components/UI/Page/Page'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { getTabsData } from './helpers'

const OrganizerCompanyPaymentForm = ({ match }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { companyId } = match.params

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const tabsData = getTabsData(companyId, setIsLoading)

  return (
    <Page header="Nuevo Pago" isLoadingWithModal={isLoading}>
      <Grid container direction="row" justify="center">
        <Box width={1} p={2} component={Paper}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            tabs={getTabsData()}
            variant="fullWidth"
          />
          {tabsData[activeTab].content}
        </Box>
      </Grid>
    </Page>
  )
}

export default OrganizerCompanyPaymentForm
