import { isDev } from 'utils/general'
import { commonScripts } from 'utils/integration'

const cachedScripts = []

function setScript(name, body) {
  if (cachedScripts.includes(name)) return

  cachedScripts.push(name)

  // Create script
  const script = document.createElement('script')
  script.setAttribute('id', name)

  if (body.src) script.src = body.src
  if (body.async) script.async = body.async
  if (body.defer) script.defer = body.defer

  if (body.data) script.setAttribute(`data-${body.data.name}`, body.data.value)

  if (body.child) script.onload = body.child()

  if (body.type) script.type = body.type

  if (body.header) {
    document.getElementsByTagName('head')[0].appendChild(script)
  } else if (body.footer) {
    document.getElementsByTagName('footer')[0].appendChild(script)
  } else {
    document.body.appendChild(script)
  }
}

export default function loadAnalyticScripts() {
  if (isDev) return

  Object.keys(commonScripts).forEach((scriptName) =>
    setScript(scriptName, commonScripts[scriptName])
  )
}
