import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import ConceptCategory from './ConceptCategory'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  payroll_concept_id: yup.string().nullable().required(),
  monthly_value: yup.number().positive().max(999999999).required(),
  active: yup.bool(),
})

export const getIncomesColumns = (concepts, applySalaryIncome) => {
  const categoryLookUp = {
    salary_income: 'Salarial',
    non_salary_income: 'No Salarial',
  }

  return [
    {
      Header: 'Tipo',
      accessor: 'category',
      Cell: ({ row }) => categoryLookUp[row.original.category],
      editable: !!applySalaryIncome,
      Edit: (props) => (
        <ConceptCategory
          {...props}
          options={Object.keys(categoryLookUp).map((id) => ({
            label: categoryLookUp[id],
            value: id,
          }))}
        />
      ),
      editInitialValue: applySalaryIncome
        ? 'salary_income'
        : 'non_salary_income',
    },
    {
      Header: 'Concepto',
      accessor: 'name',
      field: 'select',
      name: 'payroll_concept_id',
      editOptions: (_, rowValues) => {
        const options = (concepts[rowValues.category] || [])?.map?.(
          (option) => ({
            label: option.name,
            value: option.id,
          })
        )
        return options || []
      },
      editable: true,
    },
    {
      Header: 'Valor mensual',
      accessor: 'monthly_value',
      Cell: ({ row }) => formatCurrency(row.original.monthly_value),
      field: 'currency',
      name: 'monthly_value',
      alignHeader: 'right',
      alignCell: 'right',
      editable: true,
    },
    {
      Header: 'Activo',
      accessor: 'active',
      name: 'active',
      field: 'switch',
      type: 'boolean',
      alignHeader: 'center',
      alignCell: 'center',
      editable: true,
      customWidth: 100,
    },
  ]
}
