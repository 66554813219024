import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'

import { documentTypesOptions } from 'utils/company'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(3),
  },
  fieldsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  fullFieldContainer: {
    gridColumn: '1 / -1',
  },
}))

const CompanyInformation = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h6" color="primary" gutterBottom>
        Información de la empresa
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Por favor verifica y completa la información de tu empresa. Esta
        información debe ser la misma que tienes registrada en la <b>DIAN</b>.
      </Typography>
      <div className={classes.fieldsContainer}>
        <FormField name="name" label="Razón social" />
        <FormField name="government_email" label="Correo electrónico del RUT" />
        <DocumentTypeField
          options={documentTypesOptions}
          data-cy="documentType"
        />
        <CompanyIdentificationNumberField />
      </div>
      <Typography variant="h6" color="primary" gutterBottom>
        Datos de ubicación
      </Typography>
      <div className={classes.fieldsContainer}>
        <DepartmentsList name="department" label="Departamento" />
        <CitiesList name="city" label="Ciudad" />
        <div className={classes.fullFieldContainer}>
          <FormField name="address" label="Dirección" />
        </div>
      </div>
    </div>
  )
}

export default CompanyInformation
