import { Formik, Form, Field } from 'formik'
import Typography from '@material-ui/core/Typography'

import Select from 'components/UI/FormikMaterialUI/Select'
import Modal from 'components/UI/Modal/Modal'
import useErrorHandler from 'utils/hooks/useErrorHandler'

const PayslipsModal = ({ state, handleClose, handleDownloadPayslip }) => {
  const {
    open,
    isSendEmail,
    payroll,
    period,
    payslipTypes,
    payrollRanges,
    file_format: fileFormat,
  } = state

  const { handleError } = useErrorHandler()

  const handleSubmit = async (values, form) => {
    const { payslip_type: payslipType, payroll_range: payrollRange } = values

    try {
      await handleDownloadPayslip({
        payroll,
        period,
        payslip_type: payslipType,
        payroll_range: payrollRange,
        file_format: fileFormat,
        isSendEmail,
      })

      form.setSubmitting(false)
    } catch (error) {
      handleError(error, form)
    }
    handleClose()
  }

  // Find termination element to put default when it exists
  const terminationOption = payslipTypes?.find(
    (item) => item.value === 'termination'
  )

  return (
    <Formik
      initialValues={{
        payslip_type: terminationOption?.value || payslipTypes[0]?.value || '',
        payroll_range: payrollRanges[0]?.value || '',
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, isSubmitting, dirty }) => {
        return (
          <Modal
            open={open}
            isLoading={isSubmitting}
            onOk={onSubmit}
            onCancel={handleClose}
            header={
              isSendEmail
                ? 'Enviar colillas de pago por email'
                : `Descargar ${
                    payroll || !period ? 'colilla' : 'colillas'
                  } de pago`
            }
            okText={isSendEmail ? 'Enviar' : 'Generar'}
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
              disableBackdropClick: dirty || isSubmitting,
            }}
          >
            <Form>
              {payslipTypes.length > 0 && (
                <>
                  <Typography gutterBottom>
                    {`Selecciona el tipo de colilla que deseas ${
                      isSendEmail ? 'enviar por email.' : 'generar.'
                    }`}
                  </Typography>
                  <Field
                    name="payslip_type"
                    label="Tipo de colilla"
                    component={Select}
                    options={payslipTypes}
                  />
                </>
              )}
              {payrollRanges.length > 0 && (
                <Field
                  name="payroll_range"
                  label="Periodo de colilla"
                  component={Select}
                  options={payrollRanges}
                />
              )}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PayslipsModal
