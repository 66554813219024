import { useContext } from 'react'

import Box from '@material-ui/core/Box'
import RefreshIcon from '@material-ui/icons/Refresh'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import OrganizationEmail from 'components/UI/OrganizationEmail'
import PaymentContext from 'components/Subscription/Atoms/PaymentContext'

import PaymentForm from './Form'
import PlanCard from '../Plans/PlanCard'

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(5),
  },
  copySecuritySubs: {
    display: 'grid',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

const SubscriptionPaywallPayment = ({ onChangePlan }) => {
  const { selectedPlan, isAnually, subscription } = useContext(PaymentContext)
  const classes = useStyles()

  return (
    <>
      {!subscription.partner && (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography className={classes.header} variant="h5">
              Tu plan
            </Typography>

            <Button
              color="secondary"
              size="small"
              startIcon={<RefreshIcon />}
              onClick={() => onChangePlan(1)}
              className={classes.button}
            >
              Cambiar Plan
            </Button>
          </Box>

          {/* <PlanDetails /> */}
          <PlanCard
            plan={selectedPlan}
            isAnually={isAnually}
            className={classes.card}
          />
        </>
      )}
      <PaymentForm />
      <div className={classes.copySecuritySubs}>
        <Typography variant="caption" gutterBottom>
          El procesamiento de tu pago está protegido por PayU Latam, cuenta con
          sistemas de validación antifraude y todas las certificaciones
          requeridas por la legislación Colombiana para garantizar la
          transparencia y seguridad en tu transacción.
        </Typography>
        <Typography variant="caption">
          Al ingresar los datos de pago estás autorizando a Nominapp a realizar
          una transacción recurrente de acuerdo al plan seleccionado. Cada que
          se realice el cobro, recibirás una notificación con la transacción
          realizada. Si deseas cancelar o modificar el cobro, o si tienes alguna
          duda, puedes escribirnos a <OrganizationEmail />.
        </Typography>
      </div>
    </>
  )
}

export default SubscriptionPaywallPayment
