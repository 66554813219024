import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import eugenioError from 'assets/images/avatars/eugenio/eugenio_error.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    margin: theme.spacing(8, 0, 4, 0),
  },
  description: {
    marginTop: theme.spacing(1),
  },
}))

const WithoutPersonsAlert = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <img
        className={classes.image}
        src={eugenioError}
        alt="No has agregado personas"
        width={270}
        height={240}
      />
      <Typography variant="h4">¡Oops!</Typography>
      <Typography variant="h5">Todavía no has agregado personas</Typography>
      <Typography variant="body1" className={classes.description}>
        Agrega las personas en nómina para poder acceder a esta función
      </Typography>
    </div>
  )
}

export default WithoutPersonsAlert
