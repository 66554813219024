import { useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import {
  PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS,
  PERIOD_SOCIAL_BENEFIT_PERIODS,
} from 'config/routes'

import { getPaidSocialBenefits } from '../helpers'
import { PeriodContext } from '../Payroll'

const reviewStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginBottom: '2em',
  },
  alert: {
    maxWidth: '800px',
  },
  link: {
    color: theme.palette.success.dark,
    textDecoration: 'underline',
  },
}))

const SocialBenefitsAlert = () => {
  const classes = reviewStyles()
  const {
    period: { id: periodId },
    options: { social_benefits: socialBenefits },
    payPreviousPayroll,
  } = useContext(PeriodContext)

  const paidSocialBenefits = getPaidSocialBenefits(socialBenefits)

  const serviceBonus = paidSocialBenefits?.find(
    (benefit) => benefit.category === 'service_bonus'
  )

  const getAlertMessage = () => {
    const sbTitles = paidSocialBenefits.map((sb) => sb.label)

    return (
      sbTitles.length > 0 &&
      sbTitles.reduce(
        (res, currentSb, idx) =>
          res + (idx < sbTitles.length - 1 ? ', ' : ' y ') + currentSb
      )
    )
  }
  const alertMessage = getAlertMessage()

  const sbCategories = paidSocialBenefits.map((sb) => sb.category)
  const getDetailMessage = () => {
    const copyArray = []
    const copy = {
      severance: (
        <>
          El valor del pago de cesantías puedes conocerlo en el{' '}
          <MuiLink
            href="https://ayuda.nominapp.com/help/nominapp-genera-un-archivo-de-resumen-de-cesantias"
            target="_blank"
          >
            Resumen de cesantías.
          </MuiLink>
        </>
      ),
      severance_interests:
        'Los intereses a las cesantías están reflejados en el resumen de nómina, las colillas de pago y el archivo para pago en banco.',
      service_bonus:
        'La prima está reflejada en el resumen de nómina, y si eres Premium, en las colillas de pago y en el archivo para pago en banco.',
    }

    paidSocialBenefits.forEach((socialBenefit) => {
      copyArray.push(copy[socialBenefit.category])
    })

    return copyArray
  }

  const detailMessage = getDetailMessage()

  if (alertMessage) {
    return (
      <Alert
        severity="success"
        fontSize="big"
        className={classes.alert}
        withBorder
      >
        <>
          <Typography variant="subtitle2">
            ¡Liquidaste{' '}
            {serviceBonus ? (
              <Link
                className={classes.link}
                to={{
                  pathname: payPreviousPayroll
                    ? PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
                        periodId,
                        serviceBonus.social_benefits_period_id
                      )
                    : PERIOD_SOCIAL_BENEFIT_PERIODS(
                        periodId,
                        serviceBonus.social_benefits_period_id
                      ),
                  state: { payPreviousPayroll },
                }}
              >
                {alertMessage}
              </Link>
            ) : (
              alertMessage
            )}
            !
          </Typography>
          {!(sbCategories.length === 1 && sbCategories.includes('severance')) &&
            detailMessage.map((detail, index) => {
              const key = `social_security_${index}`

              return (
                <Typography key={key} variant="body2" gutterBottom>
                  {detail}
                </Typography>
              )
            })}
        </>
      </Alert>
    )
  }

  return null
}

export default SocialBenefitsAlert
