import { subYears } from 'date-fns'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { isAlegraClaroDist } from 'utils/auth'

const BasicDataSection = () => {
  const maxDateBirthDay = subYears(new Date(), 15)

  return (
    <>
      <FormField name="name" label="Nombre" />
      <FormField name="last_name" label="Apellidos" />
      <DocumentTypeField />
      <IdentificationNumberField />
      <FormField name="email" type="email" label="Correo electrónico" />
      <FormField
        name="birthday"
        label="Fecha de nacimiento"
        variant="datepicker"
        maxDate={maxDateBirthDay}
      />
      <FormField name="address" label="Dirección Hogar" optional />
      <FormField name="phone" label="Número celular" optional />
      {!isAlegraClaroDist() && (
        <FormField
          name="active_user"
          label="¿Desea que la persona tenga acceso web?"
          variant="radio-group"
          data-cy="active_user_radio_group"
          options={[
            {
              value: true,
              label:
                'Si, permitir que la persona pueda acceder a sus colillas de pago online.',
            },
            { value: false, label: 'No permitir que tenga acceso online.' },
          ]}
        />
      )}
    </>
  )
}

export default BasicDataSection
