import { ReactComponent as SvgLoader } from 'assets/images/loader.svg'
import { useTheme } from '@material-ui/core'

const SvgLoading = () => {
  const theme = useTheme()

  return <SvgLoader style={{ fill: theme.palette.primary.main }} />
}

export default SvgLoading
