import { useState } from 'react'

import { useUser } from 'components/App/UserContext/useUser'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

const initialState = {
  open: false,
  integration: {},
}

const useIntegrationModal = () => {
  const [state, setState] = useState(initialState)
  const { distributor } = useUser()
  const featureState = usePremiumFeature('socialSecurity')

  const openModal = (integration) => {
    const featureModal =
      integration?.provider === 'aportes_en_linea'
        ? 'socialSecurity'
        : 'accountingInterface'

    const isAlegraDistributorAndIntegration =
      ['alegra', 'alegra_claro', 'alegra_nomina', 'alegra_old'].includes(
        distributor
      ) && integration.provider === 'alegra'

    if (!isAlegraDistributorAndIntegration && featureState.isPremiumFeature) {
      featureState.openModal(featureModal)
    } else {
      setState({
        open: true,
        integration,
      })
    }
  }

  const closeModal = () => setState(initialState)

  return {
    integrationModalState: state,
    openIntegrationModal: openModal,
    closeIntegrationModal: closeModal,
  }
}

export default useIntegrationModal
