import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import Link from 'components/UI/MaterialUI/Link'

import { getCurrentDistributor } from 'utils/company'
import useDistributorService from 'utils/hooks/organizer/distributorService'
import { isDataCached } from 'utils/reactQuery'
import { booleanLookupOptions } from 'utils/table'
import { roles } from 'utils/worker'

import * as routes from 'config/routes'

export default function useGetColumnsData() {
  const queryClient = useQueryClient()
  const { changeCompany } = useUser()

  const distributorQueryCache = queryClient.getQueryData(
    'organizerDistributors'
  )

  const { distributorsQuery } = useDistributorService({
    serviceParams: { queryKey: 'organizerDistributors' },
    queryOptions: {
      enabled: !isDataCached(distributorQueryCache),
    },
  })

  return [
    {
      Header: 'Nombre',
      accessor: 'user_name',
      alignCell: 'left',
      customWidth: 120,
    },
    {
      Header: 'Correo',
      accessor: 'email',
      customWidth: 220,
    },
    {
      Header: 'Empresa',
      accessor: 'company_name',
      alignCell: 'left',
      Cell: ({ row }) => (
        <Link
          onClick={() => {
            changeCompany({
              distributor: getCurrentDistributor(
                distributorsQuery?.data,
                row.original.distributor_id
              ),
              id: row.original.company_id,
              freemiumStatus: row.original.freemium_status,
              premiumTrialEndDate: row.original.premium_trial_end_date,
            })
          }}
          to={routes.DASHBOARD}
        >
          {row.original.company_name}
        </Link>
      ),
      customWidth: 160,
    },
    {
      Header: 'Activo',
      accessor: 'active',
      alignCell: 'left',
      Cell: ({ row }) => (row.original.active ? 'Si' : 'No'),
      editable: true,
      field: 'select',
      editOptions: () => booleanLookupOptions,
      customWidth: 100,
      sortType: 'booleanSort',
    },
    {
      Header: 'Rol',
      accessor: 'role',
      Cell: ({ row }) =>
        roles[row.original.role] ? roles[row.original.role] : row.original.role,
      customWidth: 120,
    },
  ]
}
