import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'

import ToolbarActions from './ToolbarActions'
import TableToolbarSearch from './ToolbarSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(),
  },
  justifySpaceBetween: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  title: {
    overflow: 'hidden',
  },
}))

const TableToolbar = (props) => {
  const classes = useStyles()
  const { setGlobalFilter, globalFilter, freeActions, showSearch, rows } = props

  const leftActions = freeActions.filter((action) => action.position === 'left')
  const rightActions = freeActions.filter(
    (action) => action.position !== 'left'
  )

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.justifySpaceBetween]: showSearch,
      })}
    >
      {showSearch && (
        <TableToolbarSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          freeActions={leftActions}
        />
      )}

      {freeActions && freeActions.length > 0 ? (
        <ToolbarActions freeActions={rightActions} rows={rows} />
      ) : null}
    </Toolbar>
  )
}

export default TableToolbar
