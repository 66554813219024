import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ModalitySwitch from 'components/Subscription/Atoms/ModalitySwitch'
import FrequentQuestions from 'components/Subscription/OldSubscription/Paywall/Plans/FrequentQuestions'
import Emoji from 'components/UI/Emoji'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { helpOnClick } from 'utils/chat'
import { getCompanyId } from 'utils/company'
import { partyPopperEmoji } from 'utils/emojis'
import { formatCurrency } from 'utils/format'
import { removeSuffix } from 'utils/general'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import { WHATSAPP_SALES } from 'config/organization'

import { NewPricingContext } from '../Index'
import PaymentMethod from '../PaymentMethod/Index'
import { useSelectPlan } from '../helpers'
import PlanCard from './PlanCard'
import PlanCardV2 from './PlanCardV2'
import { getPlansData } from './helpers'

const useStyles = makeStyles((theme) => ({
  plansContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    rowGap: theme.spacing(3.5),
    marginBottom: theme.spacing(8),
    justifyItems: 'center',
    [theme.breakpoints.up(800)]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3.5),
      justifyItems: 'initial',
    },
  },
  plansContainerV2: {
    marginTop: theme.spacing(8),
    display: 'flex',
    width: '100%',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(8),
    justifyContent: 'center',
    alignItems: 'stretch',
    flexWrap: 'wrap',
  },
  questionsContainer: {
    marginTop: theme.spacing(7.5),
  },
  mainContainer: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
    paddingBottom: theme.spacing(3),
  },
  title: {
    margin: theme.spacing(2, 0, 3, 0),
    fontSize: 18,
  },
  alert: {
    maxWidth: 310,
    width: '100%',
  },
  headerContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 310px',
    },
  },
  paddingHeader: {
    paddingLeft: theme.spacing(38.75),
  },
  reverseAlert: {
    '& .MuiCollapse-entered': {
      order: -1,
    },
  },
  divider: {
    width: '100%',
  },
  freePlanContainer: {
    textAlign: 'center',
    padding: theme.spacing(7, 0, 3, 0),
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(3.5),
    gridAutoFlow: 'column',
    [theme.breakpoints.down(900)]: {
      gridAutoFlow: 'row',
    },
  },
  helpButtonsContainer: {
    display: 'grid',
    rowGap: theme.spacing(2),
    margin: theme.spacing(3, 0),
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(5),
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: '1fr',
      maxWidth: '100%',
    },
  },
  helpButtonsContainerV2: {
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: '1fr',
      maxWidth: '100%',
    },
  },
  helpButton: {
    backgroundColor: theme.palette.grey[100],
    textAlign: 'center',
  },
  supportBlogButton: {
    marginLeft: '0 !important',
  },
  freePlanText: {
    fontSize: 18,
  },
  expandPlansContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(15),
  },
  expandPlansButton: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const SubscriptionPlan = ({ frequentQuestionsRef }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { values } = useFormikContext()
  const { selectedPlan, setStep, currentStep, onFirstInteraction } = useContext(
    NewPricingContext
  )
  const cachedPlansData = queryClient.getQueryData('getPlans')
  const isPlanAnnually = values?.modality === 'year'
  const { plans } = cachedPlansData?.data || {}
  const isNotFreePlan = selectedPlan?.coded_name !== 'free_per_worker_plan'
  const [expanded, setExpanded] = useState(false)

  const { handleSelectPlan } = useSelectPlan()
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const showFrequentQuestions = () => {
    if (frequentQuestionsRef && frequentQuestionsRef.current) {
      frequentQuestionsRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
    },
  })
  const isNewPricing = hrNewModule?.value === 'on'

  const openSupportChat = () => {
    helpOnClick()
  }

  const onSelectPlan = (plan) => {
    handleSelectPlan(plan)
    setStep(1)
    onFirstInteraction()
  }

  const handleShowPlanDetail = () => setExpanded(!expanded)

  return (
    <section>
      <div className={classes.mainContainer}>
        {currentStep === 0 ? (
          <>
            {!isNewPricing ? (
              <>
                <div
                  className={clsx(classes.headerContainer, {
                    [classes.paddingHeader]: isLgUp,
                    [classes.reverseAlert]: !isLgUp,
                  })}
                >
                  <ModalitySwitch discount={10} name="modality" />
                  <Alert
                    className={classes.alert}
                    severity="success"
                    withBorder
                    icon={false}
                    header={
                      <>
                        ¡Tienes un descuento aprobado!{' '}
                        <Emoji code={partyPopperEmoji} />
                      </>
                    }
                  >
                    Elige tu plan para verlo reflejado
                  </Alert>
                </div>
                <Typography variant="h6" className={classes.title}>
                  Mejora tu plan y gana superpoderes para automatizar tu nómina
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6" className={classes.title}>
                  Mejora tu plan y gana superpoderes para automatizar tu nómina
                </Typography>
                <ModalitySwitch discount={10} name="modality" />
              </>
            )}
            <div
              className={clsx(classes.plansContainer, {
                [classes.plansContainerV2]: isNewPricing,
              })}
            >
              {!isNewPricing &&
                plans
                  ?.filter(
                    (plan) =>
                      removeSuffix(plan?.coded_name, -8) !==
                      'free_per_worker_plan'
                  )
                  ?.map((plan) => {
                    const codedName = removeSuffix(plan?.coded_name, -8)
                    const baseValue = isPlanAnnually
                      ? plan?.yearly_value_by_month
                      : plan?.monthly_value
                    const workerValue = isPlanAnnually
                      ? plan?.yearly_extra_worker_value
                      : plan?.monthly_extra_worker_value

                    return (
                      <PlanCard
                        key={codedName}
                        name={plan?.name}
                        planId={codedName}
                        baseValue={formatCurrency(baseValue)}
                        workerValue={formatCurrency(workerValue)}
                        selected={selectedPlan?.coded_name === codedName}
                        onSelect={() => onSelectPlan(plan)}
                        isPremiumPlan={codedName === 'premium_per_worker_plan'}
                      />
                    )
                  })}
              {isNewPricing &&
                getPlansData().map((plan, index) => {
                  // TODO: adjust these values when the new categories exist on the backend
                  return (
                    <PlanCardV2
                      key={plan.id}
                      name={plan.name}
                      description={plan.description}
                      adminDescription={plan.adminDescription}
                      // planId={codedName}
                      baseValue={formatCurrency(plan.price).replace(' ', '')}
                      workerValue={formatCurrency(plan.pricePerPerson).replace(
                        ' ',
                        ''
                      )}
                      selected={index === 2}
                      // onSelect={() => onSelectPlan(plan)}
                      isEssentialPlan={plan.id === 'essential'}
                      isFreePlan={plan.id === 'free'}
                      icon={plan.icon}
                      isNewPricing={isNewPricing}
                      expanded={expanded}
                      features={plan.details}
                    />
                  )
                })}
            </div>
            {isNewPricing ? (
              <div className={classes.expandPlansContainer}>
                <Button
                  variant="text"
                  size="small"
                  onClick={handleShowPlanDetail}
                  className={classes.expandPlansButton}
                >
                  {expanded
                    ? 'Ocultar detalle de los planes'
                    : 'Ver detalle de los planes'}
                </Button>
              </div>
            ) : null}
            <Divider variant="middle" className={classes.divider} />
            <div
              className={clsx(classes.helpButtonsContainer, {
                [classes.helpButtonsContainerV2]: isNewPricing,
              })}
            >
              <Button
                variant="text"
                size="small"
                className={classes.helpButton}
                onClick={showFrequentQuestions}
              >
                Preguntas frecuentes
              </Button>
              {!isNewPricing ? (
                <Button
                  size="small"
                  variant="text"
                  onClick={openSupportChat}
                  className={clsx(
                    classes.helpButton,
                    classes.supportBlogButton
                  )}
                >
                  Obtener ayuda
                </Button>
              ) : null}
              <Button
                size="small"
                variant="text"
                href={WHATSAPP_SALES}
                className={classes.helpButton}
                target="_blank"
              >
                Hablar con ventas
              </Button>
            </div>
            <Divider variant="middle" className={classes.divider} />
            {!isNotFreePlan && !isNewPricing ? (
              <>
                <div className={classes.freePlanContainer}>
                  <Typography variant="h6" className={classes.freePlanText}>
                    Nominapp Free
                  </Typography>
                  <Typography variant="h6" className={classes.freePlanText}>
                    Usuario administrador único | Funcionalidades y soporte
                    limitados
                  </Typography>
                  <Typography variant="h6" className={classes.freePlanText}>
                    <b>Tu plan actual</b>
                  </Typography>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <PaymentMethod />
        )}
      </div>
      {currentStep === 0 ? (
        <div className={classes.questionsContainer}>
          <FrequentQuestions
            forwardedRef={frequentQuestionsRef}
            filterIndexes={[0]}
          />
        </div>
      ) : null}
    </section>
  )
}

export default SubscriptionPlan
