import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import useSocialSecurityService from 'utils/hooks/payroll/socialSecurity'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { getAllForms } from 'services/payroll/socialSecurityService'

import { getActions, getColumnsData } from './helpers'

const providerName = {
  arus: 'SuAporte',
  aportes_en_linea: 'Aportes en Línea',
}
const alertMessage = {
  arus:
    'Si eliminas tu planilla de seguridad social acá, también debes ingresar a SuAporte y eliminarla allí.',
  aportes_en_linea:
    'Eliminar esta planilla es permanente y no se podrá recuperar, ¿Estás seguro?',
}

const PeriodSocialSecurityPaymentHistory = () => {
  const {
    location: { state = {} },
  } = useHistory()
  const [isLoadingWithModal, setIsLoadingWithModal] = useState(false)
  const [hasTypeColumn, setHasTypeColumn] = useState(false)
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const confirm = useConfirm()
  const { socialSecurityMutation } = useSocialSecurityService({
    queryOptions: {
      enabled: false,
    },
  })

  const fetchCompanies = (pageIndex) => {
    return {
      queryKey: ['getAllForms', state?.provider, pageIndex],
      queryFunction: () =>
        getAllForms({ page: pageIndex + 1, provider: state?.provider }),
      queryOptions: {
        refetchOnWindowFocus: true,
        onSuccess: ({ data }) => {
          const typesList = data.map((item) => {
            return item.form_type
          })
          setHasTypeColumn(
            typesList.includes('employee') &&
              typesList.includes('student_decree_055')
          )
        },
      },
    }
  }

  const handlePay = (rowData) => {
    setIsLoadingWithModal(true)
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'POST',
        periodId: rowData.period_id,
        formKey: rowData.key,
      },
      {
        onSuccess: ({ data }) => {
          if (data?.payment_url) {
            if (state?.provider === 'arus') {
              window.open(data?.payment_url, '_blank')
            } else {
              window.location.href = data?.payment_url
            }
          }
          setIsLoadingWithModal(false)
        },
        onError: (error) => {
          setIsLoadingWithModal(false)
          handleError(error)
        },
      }
    )
  }

  const handleDelete = (periodId, key, formType) => {
    confirm({
      type: 'warning',
      title: 'Eliminar pago de planilla',
      description:
        alertMessage[state?.provider] ||
        'Eliminar la planilla es permanente y no se podrá deshacer. ¿Estás seguro?',
      okText: 'Eliminar',
      onOk: async () => {
        await socialSecurityMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            periodId,
            formKey: key,
            formType,
          },
          {
            onSuccess: ({ message }) => {
              showSuccessMessage(message)
              queryClient.invalidateQueries(['getAllForms'], { exact: false })
            },
          }
        )
      },
    })
  }

  const columnsData = getColumnsData(hasTypeColumn, state?.provider)
  const actions = getActions(handlePay, handleDelete, state?.provider)

  return (
    <Page
      documentTitle="Planillas"
      header={
        <>
          <Typography variant="h5">
            Historial Planillas de Seguridad Social
          </Typography>
        </>
      }
      isLoadingWithModal={isLoadingWithModal}
    >
      <Typography gutterBottom>
        Verifica aquí la información de cada una de las planillas PILA que has
        pagado a través de la integración de Nominapp y{' '}
        {providerName[state?.provider]}. Si deseas descargar un certificado de
        pago, debes ingresar directamente a tu cuenta de{' '}
        {providerName[state?.provider]}.
      </Typography>
      <Table
        columns={columnsData}
        data={fetchCompanies}
        options={{ toolbar: false }}
        actions={actions}
      />
    </Page>
  )
}

export default PeriodSocialSecurityPaymentHistory
