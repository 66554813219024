import { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'

import { useBreadcrumbsConfig } from 'components/App/BreadcrumbsProvider'

import { validRoute } from 'utils/regex'

export const pathsMap = {
  // General routes
  '/first_steps': 'Primeros pasos',
  '/dashboard': 'Panel de control',
  '/payment_required': 'Pago requerido',
  '/social_security_integration_payment/transaction_response':
    'Estado de transacción',
  '/user/profile': 'Editar perfil',
  '/partner_landing': 'Partners',

  // Period routes
  '/pay_payroll': 'Liquidar nómina',
  '/pay_payroll/generated_payroll': 'Resumen de nómina',
  '/pay_payroll/*/view_details/*': 'Cálculos del periodo',
  '/pay_payroll/*/novelties_history': 'Novedades',
  '/pay_payroll/*/social_benefits_period/*': 'Prestaciones sociales',
  '/pay_payroll/*/social_security': 'Seguridad social',
  '/pay_payroll/*/decree376/*': 'Pago decreto 376',
  '/periods_history/*/social_security': 'Seguridad social',
  '/periods_history/*/social_benefits_period/*': 'Prestaciones sociales',
  '/periods_history/*/novelties_history': 'Novedades',
  '/periods_history/*/*/view_details/*': 'Cálculos del periodo',
  '/periods_history': 'Historial de periodos',
  '/periods_history/*': 'Detalle periodo',
  '/periods_history/electronic_payroll/*': 'Detalle nómina electrónica',
  '/periods_history/*/decree376/*': 'Pago decreto 376',
  '/periods_history/*/generated_payroll': 'Resumen de nómina',

  // Worker routes (admin profile)
  '/workers': 'Personas',
  '/workers/import': 'Importar personas',
  '/workers/new': 'Crear nueva persona',
  '/workers/*/create': 'Crear nueva persona',
  '/workers/benefits': 'Beneficios',
  '/workers/wages': 'Actualizar salarios',
  '/workers/affiliations': 'Afiliaciones a seguridad social',
  '/workers/affiliations/new': 'Nueva afiliación',
  '/workers/*': 'Perfil',
  '/workers/*/payments_history': 'Pagos',
  '/workers/*/holidays_history': 'Vacaciones',
  '/workers/*/novelties_history': 'Novedades',
  '/workers/*/contracts': 'Contratos',
  '/workers/*/contracts/*/wages': 'Salarios',
  '/workers/*/contracts/*/termination/new': 'Liquidar contrato',
  '/workers/*/contracts/*/termination/edit': 'Editar liquidación',
  '/workers/*/contracts/*/social_benefits': 'Prestaciones sociales',
  '/workers/*/contracts/*/social_benefits/*': 'Detalle prestación social',
  '/workers/*/loans': 'Préstamos',
  '/workers/*/loans/*': 'Detalle préstamo',

  // Novelties request routes (admin profile)
  '/novelties_request_history': 'Historial de solicitudes de tiempo fuera',

  // Worker routes (worker profile)
  '/payments_history': 'Historial de pagos',
  '/profile': 'Perfil',
  '/benefits': 'Beneficios',
  '/benefits/credits/application': 'Solicitud de crédito',
  '/benefits/credits/simulator': 'Simulador de crédito',

  // Company routes
  '/companies_list': 'Empresas',
  '/companies/*': 'Detalle de empresa',
  '/companies/*/edit': 'Editar empresa',

  // Subscription routes
  '/subscription': 'Suscripción',
  '/subscription/transaction_response': 'Estado de transacción',
  '/subscription_detail': 'Detalle de suscripción',
  '/subscription_detail/payments': 'Historial de pagos',

  // Settings routes
  '/settings': 'Configuración',
  '/settings/users': 'Usuarios',
  '/settings/advanced': 'Configuración avanzada',
  '/settings/company_history': 'Historial de acciones',
  '/settings/accounting': 'Contabilidad',
  '/settings/integrations': 'Integraciones',
  '/settings/integrations/alegra/accounting': 'Contabilidad Alegra',
  '/settings/integrations/social_security_payments_history':
    'Planillas seguridad social',
  '/settings/electronic_payroll': 'Nómina electrónica',
  '/settings/workers_benefits': 'Beneficios y accesibilidad para personas',

  // Organizer routes
  '/organizer/companies': 'Empresas',
  '/organizer/companies/*/payment': 'Agregar nuevo pago',
  '/organizer/users': 'Usuarios',
  '/organizer/actions': 'Acciones generales',
  '/organizer/partners': 'Partners',
  '/organizer/partners/*': 'Detalle de partner',
  '/organizer/partners/*/payment': 'Crear pago',
  '/organizer/affiliations': 'Afiliaciones a seguridad social',
  '/organizer/affiliations/*': 'Detalle de afiliación',
}

const INITIAL_STATE = {
  breadcrumbs: [],
}

const breadcrumbsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BREADCRUMBS':
      return {
        ...state,
        breadcrumbs: action.payload,
      }
    default:
      return state
  }
}

export const useBreadcrumbs = () => {
  const location = useLocation()
  const [state, dispatch] = useReducer(breadcrumbsReducer, INITIAL_STATE)
  const { breadcrumbsConfig } = useBreadcrumbsConfig()

  useEffect(() => {
    const pathnames = location.pathname.split('/')
    const paths = pathnames
      .map((_, index) => {
        const subpaths = pathnames.slice(0, index + 1)
        return {
          value: subpaths
            ?.map((value) =>
              validRoute.test(value) || value === 'decree376' ? value : '*'
            )
            .slice(1)
            .join('/'),
          originalPath: `${subpaths.join('/')}`,
        }
      })
      .slice(1)

    const breadcrumbsData = []
    paths.forEach((path, index) => {
      const to = path?.originalPath
      const pathValue = `/${path?.value}`
      const label = pathsMap[pathValue] || ''
      const isLast = index === paths.length - 1
      const config = breadcrumbsConfig[pathValue]

      if (!label) return undefined

      return breadcrumbsData.push({
        label,
        variant: to && !isLast ? 'link' : 'text',
        to,
        ...(config || {}),
      })
    })

    if (location.pathname) {
      dispatch({ type: 'SET_BREADCRUMBS', payload: breadcrumbsData })
    }
  }, [breadcrumbsConfig, location.pathname])

  return state.breadcrumbs
}
