import clsx from 'clsx'
import { useFormikContext } from 'formik'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useFormStyles from 'components/UI/Form/formStyles'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import LogoFormField from './LogoField'

const useStyles = makeStyles((theme) => ({
  basicContainer: {
    maxWidth: '700px',
  },
  fieldRow: {
    gridColumnEnd: 'span 2',
  },
  documentFields: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  taxDocumentContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    columnGap: theme.spacing(1),
    width: '100%',
    minWidth: 0,
  },
  downloadButton: {
    marginBottom: theme.spacing(1),
  },
}))

const CompanyFormFieldsBasics = ({ documentType }) => {
  const classes = useStyles()
  const classesForm = useFormStyles()
  const { values } = useFormikContext()
  const downloadURI = useDownloadURI()
  const taxDocumentUrl = isValidUrl(values?.files?.tax_document)
  const textDocumentName = getFileNameFromUrl(
    taxDocumentUrl ? taxDocumentUrl.search : undefined
  )

  const handleDownloadTaxDocument = () => downloadURI(taxDocumentUrl)

  return (
    <>
      <section className={classesForm.columnsContainer}>
        <FormField name="name" label="Razón social" />
        <FormField name="email" type="email" label="Email contacto" />
        <div
          className={clsx(classes.documentFields, {
            [classes.fieldRow]: documentType === 'ni',
          })}
        >
          <DocumentTypeField options={documentTypesOptions} />
          <CompanyIdentificationNumberField />
        </div>
        <FormField name="phone" type="phone" label="Teléfono" />
        <div className={classes.taxDocumentContainer}>
          <FormField
            name="files.tax_document"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={textDocumentName}
            label="Adjuntar RUT de la empresa"
            tooltipContent="
              Adjunta el RUT de la empresa para
              realizar la correcta Facturación
              Electrónica de tu suscripción."
          />
          <Button
            variant="text"
            size="small"
            className={classes.downloadButton}
            disabled={!taxDocumentUrl}
            onClick={handleDownloadTaxDocument}
          >
            Ver
          </Button>
        </div>
      </section>
      <LogoFormField />
    </>
  )
}

export default CompanyFormFieldsBasics
