import Typography from '@material-ui/core/Typography'

import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import notification from 'messages/notification'

const WorkerShowActivationModal = ({ onCancel }) => {
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })
  const { showSuccessMessage } = useNotifications()

  const handleSubmitActivation = () => {
    const newState = { active: !worker.active }

    workerMutation.mutate(
      {
        mutationMethod: 'PATCH',
        workerId: worker.id,
        worker: newState,
      },
      {
        onSuccess: (onSuccessData) => {
          refreshWorker()

          if (onSuccessData.data?.active)
            trackEvent(integrationEvent.EMPLOYEE_INACTIVATE)

          onCancel()
          showSuccessMessage(notification.WORKER_ACTIVATION_SUCCESS)
        },
      }
    )
  }

  return (
    <Modal
      open
      header={`¿Estás seguro que deseas ${
        worker.active ? 'desactivar' : 'activar'
      } esta persona?`}
      onOk={handleSubmitActivation}
      onCancel={onCancel}
      okText={worker.active ? 'Sí, desactivarlo' : 'Sí, activarlo'}
      isLoading={workerMutation.isLoading}
    >
      {worker.active ? (
        <>
          <Typography gutterBottom>
            Al desactivarlo ten en cuenta que:
          </Typography>
          <ol>
            <li>
              No aparecerá en la sección de Liquidar Nomina, ni podrá acceder a
              la plataforma.
            </li>
            <li>
              No quedará marcado como &ldquo;Retiro&rdquo; en tu archivo de
              seguridad social.
            </li>
          </ol>
          <br />
          <Typography>
            <i>
              Si es empleado o pensionado y saldrá de la compañía, te
              recomendamos <b>liquidarlo</b>, para que el historial de pagos
              quede correcto y se genere la novedad en tu archivo de Seguridad
              Social.
            </i>
          </Typography>
        </>
      ) : (
        <Typography>
          Activándolo comenzará a aparecer en la sección de personas, en la
          sección de Liquidar Nomina y tendrá acceso a sus datos,{' '}
          <i>(Si no quieres esto, desactivalo en Configuración)</i>. ¿Deseas
          continuar?
        </Typography>
      )}
    </Modal>
  )
}

export default WorkerShowActivationModal
