import { getWordSingular } from 'utils/format'

export default function getWorkersUploadMessage(workers) {
  const message = {
    upload:
      'Luego de haber terminado de llenar el archivo con toda la información, puedes cargarlo aquí:',
    download:
      'Descarga el formato Excel, completa los datos de tus empleados y cárgalo nuevamente.',
    error:
      'Por favor corrige los errores directamente en el archivo y luego vuelve a cargarlo para poder continuar',
    workersLimit: `Si no quieres mejorar tu plan en este momento, puedes subir tu archivo de Excel nuevamente sin superar tu límite de ${workers} ${getWordSingular(
      'personas',
      workers <= 1
    )}.`,
  }

  return message
}
