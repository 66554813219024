import { useUser } from 'components/App/UserContext/useUser'
import WorkerForm from 'components/Worker/Form/Form'
import OnBoardingWorkerForm from 'components/Worker/Form/Onboarding/Form'

export default (props) => {
  const { company } = useUser()
  const { onboarding_first_steps: onboardingFirstSteps = {} } = company

  return !onboardingFirstSteps?.first_worker ? (
    <OnBoardingWorkerForm {...props} />
  ) : (
    <WorkerForm {...props} />
  )
}
