import { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'

import NoWrap from 'components/UI/NoWrap'
import Button from 'components/UI/Button/Button'
import HelpModal from 'components/UI/Modal/HelpModal'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { formatDisplayDate } from 'utils/dateTime'

import MODAL_INFORMATION from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 340px)',
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing(2.5),
      gridTemplateColumns: '1fr',
    },
    columnGap: theme.spacing(4),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3.75),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
  },
}))

const Review = ({ periodRange }) => {
  const classes = useStyles()
  const [{ step, open }, setIsModalOpen] = useState({
    step: null,
    open: false,
  })

  const initialDate = periodRange?.initial_day || ''
  const endDate = periodRange?.end_day || ''

  return (
    <section className={classes.container}>
      <CardReview alignCenter>
        <article className={classes.card}>
          <CalendarTodayOutlinedIcon color="primary" fontSize="large" />
          <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
            Fecha:
          </Typography>
          <Typography variant="h5" component={NoWrap}>
            {formatDisplayDate(initialDate)}
            <br />
            {formatDisplayDate(endDate)}
          </Typography>
        </article>
      </CardReview>
      <CardReview>
        <Typography variant="h5">Recursos</Typography>
        <Button
          variant="text"
          startIcon={<OndemandVideoIcon />}
          onClick={() => setIsModalOpen({ step: 0, open: true })}
        >
          ¿Cómo liquidar prestaciones sociales?
        </Button>
        <Button
          variant="text"
          startIcon={<OndemandVideoIcon />}
          onClick={() => setIsModalOpen({ step: 1, open: true })}
        >
          ¿Cómo reiniciar valores?
        </Button>
      </CardReview>
      {open && (
        <HelpModal
          modalInfo={MODAL_INFORMATION[step]}
          closeModal={() => setIsModalOpen({ step: null, open: false })}
        />
      )}
    </section>
  )
}

export default Review
