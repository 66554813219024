import { Form, Formik } from 'formik'
import { useContext } from 'react'
import * as yup from 'yup'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { PeriodContext } from '../../Payroll'

yup.setLocale(yupLocaleES)

const validationSchema = (periodType) => {
  if (periodType === 'monthly')
    return yup.object({
      worked_time: yup
        .number()
        .integer()
        .min(0)
        .max(29, 'El valor debe ser menor o igual a 29'),
    })

  return yup.object({
    worked_time: yup
      .number()
      .integer()
      .min(0)
      .max(15, 'El valor debe ser menor o igual a 15'),
  })
}

const DataTableWorkedDays = ({ payroll, visible, hideWorkedDaysModal }) => {
  const { updatePeriodCallback, period } = useContext(PeriodContext)
  const { handleError } = useErrorHandler()
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = async (values, form) => {
    payrollMutation.mutate(
      {
        mutationMethod: 'PATCH',
        payrollId: payroll.id,
        data: {
          payroll: values,
        },
      },
      {
        onSuccess: ({ data }) => {
          hideWorkedDaysModal()
          updatePeriodCallback(data)
        },
      },
      {
        onError: (error) => handleError(error, form),
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ worked_time: payroll.worked_time }}
      validationSchema={validationSchema(period?.length || 'monthly')}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps

        return (
          <Modal
            open={visible}
            header="Días trabajados en el periodo"
            onOk={onSubmit}
            okText="Guardar"
            onCancel={() => hideWorkedDaysModal()}
            dialogProps={{ fullWidth: true, maxWidth: 'sm' }}
            isLoading={payrollMutation.isLoading}
            disableClickOutsideModal={payrollMutation.isLoading}
          >
            <Form>
              <FormField
                name="worked_time"
                variant="number"
                label="Número de días trabajados"
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default DataTableWorkedDays
