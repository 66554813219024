import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    cursor: 'pointer',
    borderRadius: '16px',
    padding: theme.spacing(3),
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  title: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
  },
  chip: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(),
  },
  text: {
    marginLeft: theme.spacing(),
  },
}))

const PendingCard = ({ pending }) => {
  // color can be 'primary' or 'default'
  const { title, chipLabel, handleClick, description, color, styles } = pending

  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Paper
        onClick={handleClick}
        className={clsx(classes.container, {
          [classes.primaryColor]: color === 'primary',
        })}
        style={styles}
      >
        <Chip
          label={
            <Typography
              variant="subtitle2"
              color={color === 'primary' ? 'textPrimary' : 'initial'}
            >
              {chipLabel}
            </Typography>
          }
          color={color === 'primary' ? 'secondary' : 'default'}
          className={classes.chip}
        />

        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>

        <Typography variant="body2" className={classes.text}>
          {description}
        </Typography>
      </Paper>
    </Grid>
  )
}

export default PendingCard
