import { useHistory } from 'react-router-dom'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RemoveIcon from '@material-ui/icons/Remove'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import lightGreen from '@material-ui/core/colors/lightGreen'

import useModals from 'components/App/ModalsManager/useModals'

import { formatCurrency } from 'utils/format'

import { ReactComponent as payrollSvg } from 'assets/images/views/common/salary.svg'
import { ReactComponent as socialSecuritySvg } from 'assets/images/views/common/piggyBank.svg'

import messages from 'messages/dashboard'

import * as routes from 'config/routes'

import WithoutPersonsAlert from './WithoutPersonsAlert'

export const costsInPercentagesTypes = {
  staff: 'Costos y Gastos de Nómina',
  cost_centers: 'Costos por Centros de Costos',
  locations: 'Costos por Sedes',
  areas: 'Costos por Áreas',
}

export const nextPaymentsTypes = {
  payroll_payments: {
    name: 'Nómina',
    ImageSvg: payrollSvg,
    tooltipInfo: messages.payroll_payments,
    pathTo: routes.PERIOD_PAY_PAYROLL(),
  },
  social_security: {
    name: 'Seguridad Social',
    ImageSvg: socialSecuritySvg,
    tooltipInfo: messages.social_security,
    pathTo: routes.SETTINGS_USERS(),
  },
  social_benefits: {
    name: 'Prestaciones Sociales',
    ImageSvg: socialSecuritySvg,
  },
}

/**
 * Returns a number between 0-9 from the first char code of string
 * @param {string} name
 */
export const hashName = (name) => (name ? name.charCodeAt(0) % 9 : 0)

export const creditsAlertValues = {
  enabled: {
    title: '¡Llegaron los créditos descontables de Nómina para tus empleados!',
    description: (
      <>
        Nos pusimos la 10 para{' '}
        <b>pensar en tus empleados y combatir el pagadiario.</b> Buscamos que
        tus empleados puedan llevar bienestar a sus hogares accediendo a una
        solución de crédito fácil, simple, sin trámites y sin necesidad de
        historial crediticio.
      </>
    ),
    okText: 'Ver video',
  },
  disabled: {
    title: 'Ahora tus empleados tienen créditos de libranza en Nominapp',
    description: (
      <>
        Entre Nominapp y AVISTA COLOMBIA SAS nos unimos para crear soluciones
        financieras que permitan a tus empleados cumplir sus sueños.
        <br />
        <br />
        Ahora en Nominapp tus empleados tendrán créditos que serán descontados
        automáticamente de la nómina. Eso significa que tu no debes realizar
        ningún proceso de deducciones al liquidar tu nómina. Para realizar el
        pago de las libranzas descontadas podrás descargar el archivo en la
        sección de “Archivos de Bancos” al terminar de liquidar tu nómina.{' '}
        <b>¡Activa este beneficio para tus empleados con un clic!</b>
      </>
    ),
    okText: 'Activar beneficios',
  },
}

export const creditsMessageInformation = {
  videoLink: 'https://www.youtube.com/embed/GBKiWEw0s_w',
  title: 'Créditos para empleados en Nominapp',
  description: (
    <>
      Nos pusimos la 10 para pensar en tus empleados y combatir el pagadiario.
      Ahora tus empleados pueden llevar bienestar a sus hogares accediendo a una
      solución de crédito fácil, simple, sin trámites y sin necesidad de
      historial crediticio.
      <br />
      <br />
      Conoce más sobre este beneficio para empleados{' '}
      <Link
        href="https://www.nominapp.com/blog/credito-libranza-empleados"
        target="_blank"
        style={{ display: 'inline-block' }}
      >
        <Typography>
          <b>aquí</b>
        </Typography>
      </Link>
    </>
  ),
}

export const getVariationsColumnsData = (
  { first_month: firstMonth, second_month: secondMonth } = {},
  classes
) => {
  return [
    {
      Header: 'Concepto',
      accessor: 'name',
    },
    {
      Header: firstMonth || 'Mes anterior',
      accessor: 'first_month_value',
      emptyValue: '-',
      Cell: ({ row: { original: data } }) =>
        data.coded_name === 'payrolls_number'
          ? data.first_month_value
          : formatCurrency(data.first_month_value),
      alignCell: 'right',
      alignHeader: 'right',
    },
    {
      Header: secondMonth || 'Mes actual',
      accessor: 'second_month_value',
      emptyValue: '-',
      Cell: ({ row: { original: data } }) =>
        data.coded_name === 'payrolls_number'
          ? data.second_month_value
          : formatCurrency(data.second_month_value),
      alignCell: 'right',
      alignHeader: 'right',
    },
    {
      Header: 'Variación',
      accessor: 'variation',
      Cell: ({ row: { original: data } }) => {
        let columnIcon
        if (data.variation > 0)
          columnIcon = <ArrowUpwardIcon style={{ color: lightGreen[500] }} />
        else if (data.variation < 0)
          columnIcon = <ArrowDownwardIcon style={{ color: red[500] }} />
        else columnIcon = <RemoveIcon style={{ color: blue[500] }} />

        return (
          <div className={classes.variationCell}>
            <Chip label={`${data.variation}%`} />
            {columnIcon}
          </div>
        )
      },
      alignCell: 'center',
      alignHeader: 'center',
    },
  ]
}

export const useWithoutPersonsAction = () => {
  const modals = useModals()
  const history = useHistory()

  const handleAddPersons = () => {
    modals.closeAll()
    history.push(routes.WORKER_INDEX())
  }

  return () =>
    modals.openModal({
      id: 'withoutPersonsModal',
      content: <WithoutPersonsAlert />,
      modalProps: {
        alignButtons: 'center',
        hideCancelButton: true,
        okText: 'Agregar personas',
        onOk: handleAddPersons,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
}
