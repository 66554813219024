import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  pendingValue: {
    color: theme.palette.primary.light,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}))

const Item = ({ item }) => {
  const classes = useStyles()

  const { label, value } = item

  return (
    <Grid item xs={12} sm={6}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        className={clsx(!value && classes.pendingValue)}
      >
        {value || 'Pendiente'}
      </Typography>
    </Grid>
  )
}

export default Item
