import { useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import { getVariationsColumnsData } from './helpers'

const useStyles = makeStyles(() => ({
  variationCell: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
    width: '100%',
  },
}))

const DashboardVariations = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'variations', getCompanyId()]
  const { data: variationsCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'variations',
    },
    queryOptions: {
      enabled: !isDataCached(variationsCache),
    },
  })

  const { variations } = variationsCache || {}

  const columnsData = getVariationsColumnsData(variations, classes)

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        Variaciones
      </Typography>
      {dashboardQuery?.isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          columns={columnsData}
          data={variations?.concepts || []}
          options={{ toolbar: false, pagination: false }}
        />
      )}
    </div>
  )
}

export default DashboardVariations
