import BankField from 'components/UI/Formik/CommonFields/BankField'

import ContainerFields from './Fields/ContainerFields'
import AccountFields from './Fields/Account'
import ClientTypeField from './Fields/ClientType'
import PersonalFields from './Fields/PersonalFields'

const AutomaticDebitFields = () => {
  return (
    <ContainerFields>
      <ClientTypeField />
      <PersonalFields />
      <BankField />
      <AccountFields />
    </ContainerFields>
  )
}

export default AutomaticDebitFields
