import parseISO from 'date-fns/parseISO'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Link from 'components/UI/MaterialUI/Link'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import NoWrap from 'components/UI/NoWrap'

import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import { socialBenefitsName } from 'utils/payroll'

import * as routes from 'config/routes'

import { nextPaymentsTypes } from '../helpers'

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: 60,
  },
  image: {
    width: 50,
    fill: theme.palette.primary.main,
  },
  infoIcon: {
    marginTop: 'auto',
    alignSelf: 'flex-end',
  },
}))

const DashboardNextPaymentsItem = ({ nextPayments, type }) => {
  const classes = useStyles()
  const nextPayment = nextPayments[type]

  const { name, ImageSvg, tooltipInfo } = nextPaymentsTypes[type]

  return (
    <Grid item xs={12}>
      <Box
        p={2}
        textAlign="center"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
      >
        <div className={classes.imageContainer}>
          <ImageSvg className={classes.image} />
        </div>
        <Typography variant="subtitle2" gutterBottom>
          {name}
        </Typography>
        {type === 'social_security' && nextPayment.company_id_number ? (
          <Link to={routes.COMPANY_EDIT(getCompanyId())}>
            Ingresa tu NIT para tener información de seguridad social
          </Link>
        ) : (
          <>
            <Typography variant="h5" component={NoWrap}>
              {formatCurrency(
                type === 'payroll_payments' ? nextPayment : nextPayment.payment
              )}
            </Typography>
            {nextPayment.category && (
              <Typography>
                Próximo Pago: {socialBenefitsName[nextPayment.category]}
              </Typography>
            )}
            {type !== 'payroll_payments' && (
              <Typography>
                Límite:{' '}
                {parseISO(nextPayment.limit_date).toLocaleDateString('es-CO', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
            )}
          </>
        )}
        {tooltipInfo && (
          <TooltipInfoIcon
            title={tooltipInfo}
            size="default"
            iconProps={{ className: classes.infoIcon }}
          />
        )}
      </Box>
    </Grid>
  )
}

export default DashboardNextPaymentsItem
