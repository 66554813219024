import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { closedQuestionOptions } from 'utils/worker'

import messages from 'messages/contract'

const HighRiskPensionField = ({ isEditContract = false }) => {
  const { values } = useFormikContext()
  // Show the input when the risk type is five
  const riskEqualToFive = values?.work_center?.name?.includes('5')

  return riskEqualToFive ? (
    <>
      <FormField
        name="high_risk_pension"
        row
        label="¿Es una persona de alto riesgo de acuerdo al decreto 2090 de 2003?"
        variant="radio-group"
        tooltipContent={messages.high_risk_pension}
        options={closedQuestionOptions}
      />
      {values.high_risk_pension && isEditContract ? (
        <Alert
          header="Modificación de periodos anteriores"
          severity="warning"
          closable
          withBorder
          fullWidth
        >
          Esta modificación va a afectar todos los periodos anteriores.
        </Alert>
      ) : null}
    </>
  ) : null
}

export default HighRiskPensionField
