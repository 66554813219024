import http, { getApiURL } from '../httpService'

export const enableDuplicateNit = (data) => {
  const url = getApiURL({
    withoutCompany: true,
    withoutVersion: true,
    pathname: '/organizers/countries',
  })

  return http.patchV2(url, data)
}

export const createAutomaticDebit = (data) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/automatic_debits',
  })

  return http.postFile(url, data)
}

export default {
  enableDuplicateNit,
  createAutomaticDebit,
}
