import BusinessIcon from '@material-ui/icons/Business'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'

import Changelog from 'components/App/Sidebar/Changelog'

import { getUserEmail } from 'utils/auth'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import Badge from 'assets/icons/Badge'

import * as routes from 'config/routes'

import useCloseDrawer from '../useCloseDrawer'

export default function useOrganizer() {
  const userEmail = getUserEmail()
  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: null,
      userEmail,
    },
  })
  const handleCloseMobileDrawer = useCloseDrawer()

  return [
    {
      key: 'companies',
      Icon: BusinessIcon,
      text: 'Empresas',
      path: routes.ORGANIZER_COMPANY_INDEX(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'users',
      Icon: PeopleOutlineOutlinedIcon,
      text: 'Usuarios',
      path: routes.ORGANIZER_USERS(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'files',
      Icon: DescriptionIcon,
      text: 'Acciones generales',
      path: routes.ORGANIZER_GENERAL_ACTIONS(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'partners',
      Icon: GroupWorkOutlinedIcon,
      text: 'Partners',
      path: routes.ORGANIZER_PARTNER_INDEX(),
      onClick: () => handleCloseMobileDrawer(),
    },
    ...(hrNewModule?.value === 'on'
      ? [
          {
            key: 'afiliations',
            Icon: Badge,
            text: 'Afiliaciones',
            path: routes.ORGANIZER_AFFILIATIONS(),
            onClick: () => handleCloseMobileDrawer(),
          },
        ]
      : []),
    {
      key: 'news',
      Icon: Changelog,
      text: 'Novedades',
      id: 'changelog',
    },
  ]
}
