import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  selectedRole: {
    backgroundColor: theme.palette.grey[100],
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  card: {
    borderRadius: 12,
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    height: 104,
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    cursor: 'pointer',
    gap: theme.spacing(3),
    textAlign: 'left',
    fill: theme.palette.primary.main,
  },
  role: {
    color: theme.palette.grey[500],
  },
  roleDetail: {
    color: theme.palette.grey[500],
  },
}))

const BadgeRole = ({
  role,
  id,
  detail,
  SvgImage,
  isSelectedRole,
  handleSelectRole,
  dataCy,
}) => {
  const classes = useStyles()
  return (
    <Paper
      className={clsx(classes.card, {
        [classes.selectedRole]: isSelectedRole,
      })}
      onClick={() => handleSelectRole(id)}
      id={id}
      role="button"
      data-cy={dataCy}
    >
      <SvgImage />
      <div>
        <Typography variant="subtitle1" className={classes.role}>
          {role}
        </Typography>
        {detail ? (
          <Typography variant="caption" className={classes.roleDetail}>
            {detail}
          </Typography>
        ) : null}
      </div>
    </Paper>
  )
}

export default BadgeRole
