import { createContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import useModals from 'components/App/ModalsManager/useModals'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useDownloadURI from 'utils/hooks/useDownloadURI'
import useNotifications from 'utils/hooks/useNotifications'

import {
  deleteFreeTimeRequest,
  getFreeTimeRequest,
} from 'services/worker/freeTimeService'

import calendarLogo from 'assets/images/views/common/calendar_logo.png'

import RequestFreeTimeModal from '../Modals/RequestFreeTimeModal'
import NoveltiesRequestIndexToolbar from './Toolbar'
import { getActions, getColumnsData } from './helpers'

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  approved: {
    backgroundColor: theme.palette.success.light,
    '& .MuiChip-label': {
      color: theme.palette.success.dark,
      fontWeight: 500,
    },
  },
  rejected: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    '& .MuiChip-label': {
      color: 'rgba(255, 0, 0, 1)',
      fontWeight: 500,
    },
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      color: theme.palette.warning.dark,
      fontWeight: 500,
    },
  },
}))

export const FreeTimeRequestContext = createContext()
FreeTimeRequestContext.displayName = 'FreeTimeRequestContext'

const FreeTimeIndex = () => {
  const classes = useStyles()
  const { worker: { id: workerId } = {} } = useWorker({ useCache: true })
  const modals = useModals()
  const downloadURI = useDownloadURI()
  const queryClient = useQueryClient()
  const [filter, setFilter] = useState('')

  const fetchRequests = (pageIndex, search) => {
    return {
      queryKey: ['allRequests', pageIndex, search, filter],
      workerId,
      queryFunction: () =>
        getFreeTimeRequest({ workerId, page: pageIndex + 1, search, filter }),
    }
  }
  const { showSuccessMessage } = useNotifications()

  const deleteRequest = (rowData) => {
    return {
      data: rowData,
      mutationFunction: deleteFreeTimeRequest,
      mutateOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries('allRequests')
          showSuccessMessage('Solicitud eliminada correctamente')
        },
      },
    }
  }

  const handleCloseModal = () => modals.closeAll()

  const handleNewRequest = () => {
    modals.openModal({
      id: 'newRequest',
      content: (
        <RequestFreeTimeModal onClose={handleCloseModal} workerId={workerId} />
      ),
      modalProps: {
        header: 'Solicitar tiempo fuera',
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          maxWidth: 750,
        },
      },
    })
  }

  const handleDownloadEvidence = (documentLink) => {
    downloadURI(documentLink)
  }

  const actions = getActions(
    workerId,
    handleNewRequest,
    modals,
    handleDownloadEvidence
  )

  const contextValue = {
    filter,
    setFilter,
  }

  return (
    <>
      <FreeTimeRequestContext.Provider value={contextValue}>
        <Table
          data={fetchRequests}
          columns={getColumnsData(classes)}
          actions={actions}
          components={{ Toolbar: NoveltiesRequestIndexToolbar }}
          options={{
            emptyState: {
              title: 'No tienes solicitudes de tiempo fuera',
              callToAction: (
                <Button onClick={handleNewRequest}>
                  Solicitar días libres
                </Button>
              ),
              logo: calendarLogo,
              hideDescription: true,
            },
            alignActionsCell: 'center',
            customActionsWidth: '150px',
            customQueryFetch: 'novelty_requests',
          }}
          editable={{
            hideEdit: true,
            isDeletable: (row) => row.status === 'pending',
            onDeleteRow: deleteRequest,
            tableMinWidth: '1040px',
          }}
        />
      </FreeTimeRequestContext.Provider>
    </>
  )
}

export default FreeTimeIndex
