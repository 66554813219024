import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  previousButton: {
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  cancelButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
}))

const StepsActions = ({
  cancelButtonProps = {},
  previousButtonProps = {},
  nextButtonProps = {},
} = {}) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <div className={classes.container}>
      <div className={classes.cancelButtonContainer}>
        <Button
          variant="text"
          size="small"
          className={classes.cancelButton}
          disabled={cancelButtonProps?.disabled || false}
          onClick={cancelButtonProps?.onClick || null}
        >
          {cancelButtonProps?.text || ''}
        </Button>
      </div>
      <div>
        <Button
          onClick={previousButtonProps?.onClick || null}
          disabled={previousButtonProps?.disabled || false}
          className={classes.previousButton}
          variant="outlined"
          size={smUp ? 'medium' : 'small'}
        >
          {previousButtonProps?.text || ''}
        </Button>
        <Button
          onClick={nextButtonProps?.onClick || null}
          disabled={nextButtonProps?.disabled || false}
          size={smUp ? 'medium' : 'small'}
        >
          {nextButtonProps?.text || ''}
        </Button>
      </div>
    </div>
  )
}

export default StepsActions
