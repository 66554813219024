import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import periodService from 'services/payroll/periodService'

import PayrollConsolidatedReportModal from '../Modals/ConsolidatedReportModal'
import NewPeriodModal from '../Modals/NewPeriodModal'
import ToolbarPeriodHistory from './Toolbar'
import { columnsData, getActions } from './helpers'

const PayrollHistory = ({ hasAlegraIntegration, activeTab }) => {
  const [newPeriodModalOpen, setNewPeriodModalOpen] = useState(false)
  const [consolidatedModalOpen, setConsolidatedModalOpen] = useState(false)
  const [lastPeriodDate, setLastPeriodDate] = useState({
    date: null,
    length: 'monthly',
  })
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showInfoMessage } = useNotifications()

  const fetchPeriods = (pageIndex) => {
    const page = pageIndex + 1

    return {
      queryKey: [
        'getAllPeriods',
        pageIndex,
        selectedMonth?.id,
        selectedYear?.id,
      ],
      queryFunction: () =>
        periodService.getAllPeriods(page, selectedMonth?.id, selectedYear?.id),
      queryOptions: {
        onSuccess: ({ data }) => {
          setLastPeriodDate({
            date: data[data.length - 1]?.initial_day,
            length: data[data.length - 1]?.length,
          })
        },
      },
    }
  }

  const handleOrganizerPeriodAction = async (periodId, perform) => {
    try {
      const { message } = await periodService.updateOrganizerAction(
        periodId,
        perform
      )

      queryClient.invalidateQueries(['getAllPeriods'], { exact: false })
      showInfoMessage(message)
    } catch (error) {
      handleError(error)
    }
  }

  const handleOpenNewPeriodModal = () => setNewPeriodModalOpen(true)
  const handleOpenConsolidatedModal = () => setConsolidatedModalOpen(true)

  const actions = getActions(
    handleOpenConsolidatedModal,
    handleOrganizerPeriodAction,
    handleOpenNewPeriodModal,
    hasAlegraIntegration
  )

  const Toolbar = (props) => (
    <ToolbarPeriodHistory
      lastPeriodDate={lastPeriodDate}
      setSelectedMonth={setSelectedMonth}
      selectedMonth={selectedMonth}
      setSelectedYear={setSelectedYear}
      selectedYear={selectedYear}
      {...props}
    />
  )

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchPeriods}
        options={{ search: false, alignActionsCell: 'center' }}
        actions={actions}
        components={{ Toolbar }}
      />
      {newPeriodModalOpen && (
        <NewPeriodModal
          visible={newPeriodModalOpen}
          handleClose={() => setNewPeriodModalOpen(false)}
          activeTab={activeTab}
        />
      )}
      {consolidatedModalOpen && (
        <PayrollConsolidatedReportModal
          visible={consolidatedModalOpen}
          handleClose={() => setConsolidatedModalOpen(false)}
        />
      )}
    </>
  )
}

export default PayrollHistory
