import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

import Button from 'components/UI/Button/Button'

import { formatCurrency } from 'utils/format'

export function getPayrollConceptItemCell({
  buttonId,
  payrollConceptValue,
  formatter = formatCurrency,
  icon = <AddCircleOutlineIcon />,
  text,
  onClick,
  editable,
}) {
  let itemValue = '-'

  if (payrollConceptValue)
    itemValue = formatter ? formatter(payrollConceptValue) : payrollConceptValue

  if (!editable) return itemValue

  return payrollConceptValue ? (
    <>
      {itemValue}
      <IconButton
        id={buttonId}
        size="small"
        onClick={onClick}
        data-cy={buttonId}
      >
        <EditIcon fontSize="inherit" />
      </IconButton>
    </>
  ) : (
    <Button
      id={buttonId}
      size="small"
      startIcon={icon}
      variant="text"
      onClick={onClick}
      data-cy={buttonId}
    >
      {text}
    </Button>
  )
}

export default getPayrollConceptItemCell
