import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import MButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useNotifications from 'utils/hooks/useNotifications'

import { ORGANIZER_AFFILIATIONS } from 'config/routes'

import { getValidationSchema } from './helpers'

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
  },
  sectionFiles: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  columnSection: {
    display: 'grid',
    gridColumn: '1 / -1',
    gridTemplateColumns: '60% 40%',
    alignItems: 'flex-end',
    columnGap: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
  },
}))

const description = {
  rejected:
    'Describe brevemente el motivo por el que rechazas esta afiliación Ej: Falta algún documento, un dato mal diligenciado, etc.',
  approved:
    'Para aprobar la afiliación debes adjuntar los soportes requeridos, ya que la empresa solicitante los necesita.',
}

const ModalActions = ({ action, workerId, contractCategory, documentType }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const history = useHistory()
  const modals = useModals()
  const { showSuccessMessage } = useNotifications()
  const isRejectAction = action === 'rejected'
  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: {
      enabled: false,
    },
  })

  const onSubmit = (values) => {
    const affiliationData = new FormData()

    if (isRejectAction) {
      affiliationData.append('status', 'rejected')
      if (values.comment && values.comment.length > 0) {
        affiliationData.append('rejected_comment', values.comment)
      }
    } else if (!isRejectAction) {
      affiliationData.append('status', 'approved')

      if (values.approved_compensation_fund_document instanceof File) {
        affiliationData.append(
          'approved_compensation_fund_document',
          values.approved_compensation_fund_document
        )
      }

      if (values.approved_health_document instanceof File) {
        affiliationData.append(
          'approved_health_document',
          values.approved_health_document
        )
      }

      if (values.approved_pension_document instanceof File) {
        affiliationData.append(
          'approved_pension_document',
          values.approved_pension_document
        )
      }

      if (values.approved_risk_document instanceof File) {
        affiliationData.append(
          'approved_risk_document',
          values.approved_risk_document
        )
      }

      if (values.approved_severance_document instanceof File) {
        affiliationData.append(
          'approved_severance_document',
          values.approved_severance_document
        )
      }
    }

    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData,
      },
      {
        onSuccess: async () => {
          showSuccessMessage(
            `La afiliación ha sido ${isRejectAction ? 'rechazada' : 'aprobada'}`
          )
          modals.closeAll()
          await queryClient.invalidateQueries(['getAllAffiliations'], {
            exact: false,
          })
          history.push(ORGANIZER_AFFILIATIONS())
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(
        isRejectAction,
        documentType,
        contractCategory
      )}
      initialValues={{
        comment: null,
      }}
    >
      {() => (
        <Form>
          <Typography variant="body2" className={classes.description}>
            {description[action]}
          </Typography>
          {action === 'rejected' ? (
            <FormField
              name="comment"
              variant="textarea"
              label="Detalle del rechazo"
              rows="4"
              inputProps={{
                maxLength: 1000,
              }}
            />
          ) : null}
          {action === 'approved' ? (
            <div className={classes.sectionFiles}>
              <FormField
                name="approved_compensation_fund_document"
                label="Certificado de afiliación CCF"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
              />
              <FormField
                name="approved_health_document"
                label="Certificado de afiliación EPS"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
              />
              <FormField
                name="approved_pension_document"
                label="Certificado de afiliación Pensión"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
              />
              <FormField
                name="approved_risk_document"
                label="Certificado de afiliación ARL"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
              />
              <FormField
                name="approved_severance_document"
                label="Certificado de afiliación Fondo de Cesantías"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
              />
            </div>
          ) : null}
          <div className={classes.buttonsContainer}>
            <MButton variant="outlined" onClick={() => modals.closeAll()}>
              Volver
            </MButton>
            <Button type="submit" loading={affiliationsMutation.isLoading}>
              {isRejectAction ? 'Rechazar' : 'Aprobar'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ModalActions
