import { useState } from 'react'

import Box from '@material-ui/core/Box'

import Button from 'components/UI/Button/Button'
import Dropzone from 'components/UI/Dropzone/Dropzone'
import Loading from 'components/UI/Loading/Loading'

import useGeneralActionsService from 'utils/hooks/organizer/generalActions'

import AutomaticDebitResponse from './Response/Response'

const OrganizerAutomaticDebitForm = () => {
  const [file, setFile] = useState(null)
  const { generalActionsMutation } = useGeneralActionsService()
  const [response, setResponse] = useState(null)

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('file', file)

    generalActionsMutation.mutate(
      {
        mutationMethod: 'POST',
        data: formData,
      },
      {
        onSuccess: (data) => {
          setResponse(data)
        },
      }
    )
  }

  if (generalActionsMutation?.isLoading) return <Loading />

  if (response)
    return <AutomaticDebitResponse response={response} setNew={setResponse} />

  return (
    <>
      <Dropzone
        value={file && file.name}
        handleAdd={setFile}
        accept="text/plain"
      />

      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleSubmit} disabled={!file}>
          Generar Archivo
        </Button>
      </Box>
    </>
  )
}

export default OrganizerAutomaticDebitForm
