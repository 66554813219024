import { useHistory } from 'react-router-dom'

import MBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { useBreadcrumbs } from './helpers'
import Breadcrumb from './Breadcrumb'

const useStyles = makeStyles((theme) => ({
  separator: {
    color: theme.palette.grey[700],
  },
}))

const Breadcrumbs = () => {
  const classes = useStyles()
  const history = useHistory()
  const breadcrumbs = useBreadcrumbs()
  const multipleBreadcrumbs = breadcrumbs.length > 1
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const handleGoBack = () => {
    if (multipleBreadcrumbs) {
      const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2]
      if (previousBreadcrumb) {
        previousBreadcrumb?.onClick?.()
        history.push(previousBreadcrumb.to)
      } else {
        history.goBack()
      }
    }
  }

  return (
    <>
      {!xsDown ? (
        <MBreadcrumbs
          aria-label="breadcrumb"
          separator={
            <NavigateNextIcon fontSize="small" className={classes.separator} />
          }
          classes={{
            separator: classes.separator,
          }}
        >
          {breadcrumbs.map((breadcrumb) => {
            return <Breadcrumb key={breadcrumb?.to} {...breadcrumb} />
          })}
        </MBreadcrumbs>
      ) : (
        <>
          {multipleBreadcrumbs ? (
            <Button
              size="small"
              variant="text"
              startIcon={<ChevronLeftIcon />}
              component="a"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          ) : null}
        </>
      )}
    </>
  )
}

export default Breadcrumbs
