import { createContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { formatDateRange } from 'utils/dateTime'
import usePayrollsFileService from 'utils/hooks/payroll/fileService'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { getNoveltiesHistory } from 'services/payroll/fileService'
import periodService from 'services/payroll/periodService'
import { getNoveltiesHistoryWorker } from 'services/worker/workerService'

import * as routes from 'config/routes'

import ToolbarNoveltiesHistory from './Toolbar/Toolbar'
import { getColumnsData } from './helpers'

export const NoveltiesHistoryContext = createContext()

const NoveltiesHistory = () => {
  const { workerId, periodId } = useParams()
  const { state } = useLocation()
  const downloadFile = useEnqueueDownload()
  const { handleError } = useErrorHandler()
  const isFromPeriod = periodId !== undefined

  const [isLoading, setIsLoading] = useState(false)
  const [period, setPeriod] = useState(null)
  const [hasAnyNovelty, setHasAnyNovelty] = useState(false)
  const [activeTab, setActiveTab] = useState('novelties')
  const [filterChanged, setFilterChanged] = useState(false)

  const { downloadFileMutation } = usePayrollsFileService()

  useEffect(() => {
    const fetchPeriod = async () => {
      setIsLoading(true)
      try {
        const data = await periodService.getById(periodId)

        setPeriod(data)
      } catch (error) {
        handleError(error)
      }

      setIsLoading(false)
    }

    if (periodId) fetchPeriod()
  }, [handleError, periodId])

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
    setFilterChanged(true)
  }

  const onSuccessQuery = (filters) => {
    setHasAnyNovelty(filters.total_records > 0)
    setFilterChanged(false)
  }

  const fetchNovelties = (pageIndex, search) => {
    const options = {
      filter: activeTab,
      page: pageIndex + 1,
      search,
      async: false,
    }
    const queryOptionsPeriod = {
      queryKey: [
        'getAllNoveltiesHistoryPerPeriod',
        pageIndex,
        search,
        activeTab,
      ],
      queryFunction: () => getNoveltiesHistory(periodId, options),
      queryOptions: {
        onSuccess: ({ filters }) => {
          onSuccessQuery(filters)
        },
      },
    }

    const queryOptionsWorker = {
      queryKey: [
        'getAllNoveltiesHistoryPerWorker',
        pageIndex,
        search,
        activeTab,
      ],
      queryFunction: () => getNoveltiesHistoryWorker(workerId, options),
      queryOptions: {
        onSuccess: ({ filters }) => {
          onSuccessQuery(filters)
        },
      },
    }

    if (periodId) {
      return queryOptionsPeriod
    }
    if (workerId) {
      return queryOptionsWorker
    }

    return null
  }

  const featureState = usePremiumFeature('reports')

  const handleClickNoveltyReport = async () => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    if (!hasAnyNovelty) return

    let mutationKey = ''
    let options = {}
    let pathname = ''

    const callback = () => {
      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: 'noveltiesReport',
        type: 'xlsx',
      })
    }
    if (periodId) {
      mutationKey = 'noveltiesHistorySummaryPeriod'
      options = {
        file_format: 'xlsx',
      }
      pathname = `periods/${periodId}/novelties_history`
    } else {
      mutationKey = 'noveltiesHistorySummaryWorker'
      options = {
        file_format: 'xlsx',
      }
      pathname = `workers/${workerId}/novelties_history`
    }

    downloadFileMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey,
        options,
        periodId,
        workerId,
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'Reporte de novedades',
            fileCode: 'novelties_report',
            pathname,
            callback,
          })
        },
      }
    )
  }

  const getDocumentTitle = () => {
    let title = 'Novedades '
    if (workerId) title += `de ${state?.workerFullName || 'trabajador'}`
    else if (isFromPeriod && period)
      title += ` del ${formatDateRange(period.initial_day, period.end_day)}`
    return title
  }

  const columns = getColumnsData(activeTab, isFromPeriod)

  return (
    <Page
      isLoading={isLoading}
      documentTitle={getDocumentTitle()}
      header={
        <>
          <Typography variant="h5">
            Novedades
            {period &&
              ` del ${formatDateRange(period.initial_day, period.end_day)}`}
            {workerId && (
              <>
                {' de '}
                {state?.workerFullName ? (
                  <Link to={routes.WORKER_SHOW(workerId)}>
                    {state?.workerFullName}
                  </Link>
                ) : (
                  'trabajador'
                )}
              </>
            )}
          </Typography>
        </>
      }
    >
      <NoveltiesHistoryContext.Provider
        value={{
          activeTab,
          handleChangeTab,
          handleClickNoveltyReport,
          hasAnyNovelty,
          premiumAction: featureState.isPremiumFeatureIcon,
        }}
      >
        <Table
          columns={columns}
          data={fetchNovelties}
          filterChanged={filterChanged}
          components={{ Toolbar: ToolbarNoveltiesHistory }}
        />
      </NoveltiesHistoryContext.Provider>
    </Page>
  )
}

export default NoveltiesHistory
