const useAutoScroll = (
  config = {
    behavior: 'smooth',
  }
) => {
  return (element) => {
    element?.scrollIntoView(config)
  }
}

export default useAutoScroll
