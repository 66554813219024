import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import NoWrap from 'components/UI/NoWrap'
import { formatCurrency } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  disabledColor: {
    color: theme.palette.grey[400],
  },
}))

const NewRecurrentConceptsItem = ({ label, value, color }) => {
  const classes = useStyles()

  let valueColor =
    color === 'primary'
      ? classes.primaryColor
      : color === 'success'
      ? classes.successColor
      : classes.errorColor

  valueColor =
    value === 0 || value === false ? classes.disabledColor : valueColor

  const getValue = () => {
    if (label === 'Retención') return value ? 'Activa' : 'Inactiva'
    else return formatCurrency(value)
  }

  return (
    <div className={classes.item}>
      <Typography>
        <NoWrap>{label}</NoWrap>
      </Typography>
      <Typography variant="h5" className={valueColor}>
        <NoWrap>{getValue()}</NoWrap>
      </Typography>
    </div>
  )
}

export default NewRecurrentConceptsItem
