import http, { getApiURL } from './httpService'

export const getInstitutionsByCategory = (category) => {
  const url = getApiURL({
    pathname: '/institutions',
    withoutCompany: true,
    searchParams: { category },
  })

  return http.getV2(url)
}

export default {
  getInstitutionsByCategory,
}
