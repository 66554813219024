import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import useSubscription from 'components/Subscription/Atoms/useSubscription'

const useStyles = makeStyles((theme) => ({
  // TODO: Refactor these styles when the new subscription view is available to everyone
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    columnGap: theme.spacing(5),
  },
  contentV2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    columnGap: theme.spacing(4.5),
    rowGap: theme.spacing(2.5),
  },
}))

const AccountFields = ({ className }) => {
  const classes = useStyles()
  const { showNewSubscription } = useSubscription()

  return (
    <div
      className={clsx(classes.content, className, {
        [classes.contentV2]: showNewSubscription,
      })}
    >
      <AccountTypeField />
      <AccountNumberField showPlaceholder />
    </div>
  )
}

export default AccountFields
