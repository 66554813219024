import { useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

import Button from 'components/UI/Button/Button'

const ConfirmationDialog = ({ open, options, onCancel, onOk, okClicked }) => {
  const [checked, setChecked] = useState(false)

  const {
    title,
    description,
    type,
    okText,
    cancelText,
    dialogProps,
    okButtonProps,
    cancelButtonProps,
    confirmCheckbox,
    hideCancelButton,
  } = options

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  let MuiIcon

  switch (type) {
    case 'confirm':
      MuiIcon = HelpOutlineIcon
      break
    case 'success':
      MuiIcon = CheckCircleOutlineIcon
      break
    case 'info':
      MuiIcon = InfoOutlinedIcon
      break
    case 'warning':
      MuiIcon = ReportProblemOutlinedIcon
      break
    case 'error':
      MuiIcon = ErrorOutlineIcon
      break
    default:
      break
  }

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      onEscapeKeyDown={onCancel}
      {...dialogProps}
      open={open}
      onClose={onCancel}
    >
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h6">
            <MuiIcon style={{ float: 'right' }} color="primary" />
            {title}
          </Typography>
        </DialogTitle>
      )}
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      {confirmCheckbox && (
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color="secondary"
              />
            }
            label="Confirmar"
          />
        </DialogContent>
      )}
      <DialogActions>
        {!hideCancelButton && (
          <Button
            variant="outlined"
            {...cancelButtonProps}
            onClick={() => {
              onCancel()
              setChecked(false)
            }}
            data-cy="confirm_dialog_cancel"
            disabled={okClicked}
          >
            {cancelText}
          </Button>
        )}
        <Button
          {...okButtonProps}
          onClick={() => {
            onOk()
            setChecked(false)
          }}
          loading={okClicked}
          disabled={(confirmCheckbox && !checked) || okClicked}
          data-cy="confirm_dialog_ok"
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
