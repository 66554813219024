import { useState } from 'react'

import {
  FormControl,
  FormLabel,
  Typography,
  makeStyles,
} from '@material-ui/core'

import SelectField from 'components/UI/Formik/FormField/SelectField'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { certificatesLabel } from './helpers'

const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(4),
  },
}))

const DownloadDocuments = ({ documents = {} }) => {
  const classes = useStyles()
  const downloadURI = useDownloadURI()
  const [selectedDocument, setSelectedDocument] = useState('')
  const options =
    Object.values(documents).length > 0
      ? [
          {
            label: 'Todos',
            value: 'all',
          },
          ...Object.keys(documents).map((key) => ({
            label: certificatesLabel[key],
            value: documents[key],
          })),
        ]
      : []

  const onChange = ({ target: { value } }) => {
    setSelectedDocument(value)

    if (value === 'all') {
      Object.values(documents).forEach((item) => {
        downloadURI(item)
      })
      return
    }

    downloadURI(value)
  }

  return (
    <>
      <Typography variant="body1" className={classes.description}>
        Acá puedes descargar todos los documentos o seleccionar el que
        necesites.
      </Typography>
      <FormControl className={classes.formControl}>
        <FormLabel>Selecciona el documento a descargar</FormLabel>
        <SelectField
          options={options}
          value={selectedDocument}
          onChange={onChange}
        />
      </FormControl>
    </>
  )
}

export default DownloadDocuments
