import { formatLocateDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

const getFormattedResponse = (user, location, subscription, pseResponse) => {
  let dataFormatted = {
    name: user?.name,
    paymentMethodInfo: {},
    plan: subscription.plan.name,
    type: subscription.type === 'month' ? 'mes' : 'año',
    value:
      subscription.type === 'month'
        ? formatCurrency(subscription.month_value)
        : formatCurrency(subscription.year_value),
    response: {},
  }

  if (subscription.payment_method_info !== null)
    dataFormatted.paymentMethodInfo = subscription.payment_method_info
  if (subscription.active_until !== null)
    dataFormatted.activeUntil = formatLocateDateString(
      subscription.active_until
    )
  if (location.state !== undefined)
    dataFormatted.response.state = location.state
  if (subscription.payment_status !== null)
    dataFormatted = {
      ...dataFormatted,
      response: {
        state: { payment_status: pseResponse?.payment_status },
      },
    }

  return dataFormatted
}

export default getFormattedResponse
