function generateTypographyStyle(
  fontFamily = 'Roboto',
  fontSize = '1rem',
  lineHeight = '24px',
  fontWeight = 'normal',
  letterSpacing
) {
  return {
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
  }
}

const T1 = generateTypographyStyle('Poppins', '4rem', '72px', 'bold', '-0.02em') // 64px
const T2 = generateTypographyStyle('Poppins', '3rem', '56px', 'bold', '-0.02em') // 48px
const T3 = generateTypographyStyle('Poppins', '2.5rem', '48px', 600) // 40px
const T4 = generateTypographyStyle('Poppins', '2rem', '40px', 600) // 32px
const C1 = generateTypographyStyle('Poppins', '1.5rem', '36px', 600) // 24px
const C2 = generateTypographyStyle('Poppins', '1.5rem', '36px', 'normal') // 24px
const C3 = generateTypographyStyle('Roboto', '1rem', '24px', 'bold', '0.02em') // 16px
const C4 = generateTypographyStyle('Roboto', '1rem', '24px', 'normal', '0.02em') // 16px
const C5 = generateTypographyStyle(
  'Roboto',
  '0.875rem',
  '20px',
  'bold',
  '0.02em'
) // 14px
const C6 = generateTypographyStyle(
  'Roboto',
  '0.875rem',
  '20px',
  'normal',
  '0.02em'
) // 14px
const C7 = generateTypographyStyle('Roboto', '0.75rem', '16px', 500, '0.02em') // 12px
const C8 = generateTypographyStyle(
  'Roboto',
  '0.75rem',
  '16px',
  'normal',
  '0.02em'
) // 12px

const typographyStyles = {
  T1,
  T2,
  T3,
  T4,
  C1,
  C2,
  C3,
  C4,
  C5,
  C6,
  C7,
  C8,
}

export default typographyStyles
