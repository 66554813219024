import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import useNotifications from 'utils/hooks/useNotifications'

import PeriodDetailsBodyNewSection from './NewSection'
import PeriodDetailsBodySection from './Section'
import { pageDescriptions, tabsData } from './helpers'

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(3),
  },
  separator: {
    margin: '50px 0 50px 0',
    color: theme.palette.grey[500],
  },
}))

const PeriodDetailsBody = ({ details = {}, socialBenefitsId }) => {
  const location = useLocation()
  const queryParams = qs.parse(location.search)

  const [activeDetail, setActiveDetail] = useState(
    queryParams.selected_tab || 'payslip_detail'
  )
  const { showErrorMessage } = useNotifications()
  const classes = useStyles()

  useEffect(() => {
    if (!details?.header?.payroll_options?.[activeDetail]) {
      showErrorMessage('Ocurrió un error al tratar de acceder a los cálculos.')
      setActiveDetail('payslip_detail')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const description = pageDescriptions[activeDetail] || {}

  const handleChangeTab = (e, newTab) => setActiveDetail(newTab)

  return (
    <Paper>
      <Tabs
        value={activeDetail}
        tabs={tabsData(details?.header?.payroll_options, socialBenefitsId)}
        onChange={handleChangeTab}
        className={classes.spacing}
        wrapped
      />
      <Box p={3}>
        <Box>
          <Typography variant="h5" paragraph>
            {description.title}
          </Typography>
          {description.description && (
            <Typography variant="body1" paragraph>
              {description.description}
            </Typography>
          )}
          {description.link && (
            <Link href={description.link} target="_blank">
              Conoce más acerca de {description.title}
            </Link>
          )}
        </Box>
        <Divider className={classes.separator} />
        {['social_benefits', 'payroll_retention', 'termination'].includes(
          activeDetail
        ) ? (
          <PeriodDetailsBodyNewSection
            details={details}
            activeDetail={activeDetail}
          />
        ) : (
          <PeriodDetailsBodySection
            details={details}
            activeDetail={activeDetail}
          />
        )}
      </Box>
    </Paper>
  )
}

export default PeriodDetailsBody
