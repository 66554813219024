export default {
  AEL_LOAD_FORM: 'Ael Load Form',
  AEL_PAID_FORM: 'Ael Paid Form',
  COMPANY_UPDATE: 'Company Update',
  COMPANY_ADD_AREA: 'Company Area Add',
  COMPANY_ADD_POSITION: 'Company Position Add',
  COMPANY_ADD_LOCATION: 'Company Location Add',
  DOWNLOAD_FILE: 'Download File',
  DOWNLOAD_TEMPLATE: 'Download Template',
  EMPLOYEE_REGISTER: 'Employee Register',
  EMPLOYEE_LABOR_CERTIFICATE: 'Employee Labor Certificate',
  EMPLOYEE_INCOME_CERTIFICATE: 'Employee Income Certificate',
  EMPLOYEE_ADD_RECURRENT_CONCEPT: 'Recurrent Add',
  EMPLOYEE_INACTIVATE: 'Employee Inactivate',
  EMPLOYEE_REINCORPORATE: 'Employee Reincorporate',
  EMPLOYEE_VIEW_HISTORY: 'View Employee',
  EMPLOYEE_TERMINATION: 'Employee Termination',
  PAYROLL_GENERATE: 'Payroll Generate',
  PAYROLL_ADD_BONUS: 'Payroll Bonus Add',
  PAYROLL_ADD_OVERTIME: 'Payroll Overtime Add',
  PAYROLL_ADD_NOVELTIES: 'Payroll Novelties Add',
  PAYROLL_ADD_LOANS_AND_DEDUCTIONS: 'Payroll Loan Other Deduction Add',
  PAYROLL_OLD_PERIOD: 'Pay Payroll Old Period Edit',
  PAYSLIPS_SEND_EMAIL: 'Payslips Send Email',
  NOVELTIES_FILE_UPLOAD: 'Novelties File Upload',
  SIGN_UP: 'Sign Up',
  SEND_EMAIL: 'Send Email',
  EDIT_SETTINGS: 'Edit Settings',
  SUBSCRIPTION_BUY: 'Subscription Buy',
  INTEGRATION_DONE: 'Integration Done',
  INVITE_COLLABORATOR: 'Invite Collaborator',
  TEST_PAYROLL: 'Test PayPayroll',
  FIRST_EMPLOYEE: 'First Employee Create',
  FIRST_PAYROLL: 'First PayPayroll',
  FIRST_PAYMENT: 'First Payment',
  FREE_TRIAL_ENDED: 'Free Trial Ended',
  FREE_TRIAL_STARTED: 'Free Trial Started',
}
