import clsx from 'clsx'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Loading from 'components/UI/Loading/Loading'

import { isOrganizer } from 'utils/auth'
import { useHasPendingOnboarding } from 'utils/onboarding'

import * as routes from 'config/routes'

import FloatingActionGroup from './FloatingActionGroup/Index'
import Header from './Header/Index'
import MobileAppBar from './MobileAppBar'
import Sidebar from './Sidebar/Sidebar'
import SwitchRoutes from './SwitchRoutes'
import { useUser } from './UserContext/useUser'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  appbarSpace: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.mobileAppbar,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}))

const App = () => {
  const { isAuthenticated, company, isLoading } = useUser()
  const location = useLocation()
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = useState(false)
  const isOnboardingActive = location.pathname.includes('/onboarding')

  const classes = useStyles()

  const queryParams = queryString.parse(location.search)
  const isInsideAuth = queryParams.password_recovered

  const handleDrawerToggle = useCallback(
    () => setMobileOpen((previousValue) => !previousValue),
    []
  )

  const pendingOnboarding = useHasPendingOnboarding(
    company?.onboarding?.onboarding_step
  )

  useEffect(() => {
    window.Appcues?.page()
  }, [location.pathname])

  useEffect(() => {
    if (pendingOnboarding.value && !isOnboardingActive) {
      history.replace(pendingOnboarding.pathname)
    }
  }, [
    history,
    isOnboardingActive,
    pendingOnboarding.pathname,
    pendingOnboarding.value,
  ])

  const isInsideAppAuth =
    isInsideAuth && location.pathname === routes.PERIOD_PAY_PAYROLL()

  const isToSubscription = location.state?.toSubscription

  // if come from login and has onboarding pending, redirect to respective current step path
  if (location.state?.fromLogin && company.id) {
    let redirectTo = isToSubscription
      ? routes.SUBSCRIPTION()
      : routes.PERIOD_PAY_PAYROLL()
    const { onboarding } = company

    // check onboarding_step to redirect
    if (pendingOnboarding.value) {
      redirectTo = pendingOnboarding.pathname
    } else {
      if (onboarding.test_payroll_processed_at) redirectTo = routes.WORKER_NEW()
      if (!onboarding.test_workers) redirectTo = routes.PERIOD_PAY_PAYROLL()
      if (onboarding.first_payroll_processed_at) redirectTo = '/'
    }

    return <Redirect to={redirectTo} />
  }

  if (!isAuthenticated() && !isInsideAppAuth) {
    let redirect = location.pathname
    if (redirect !== '/' && redirect.startsWith('/')) {
      redirect = `${routes.LOGIN}?redirect=${redirect}`
    } else {
      redirect = routes.LOGIN
    }

    return <Redirect to={redirect} />
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      {pendingOnboarding?.value && !isOrganizer() ? null : (
        <>
          <MobileAppBar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </>
      )}
      <main
        className={clsx(classes.main, {
          [classes.appbarSpace]: !isOnboardingActive,
        })}
      >
        {!isOnboardingActive ? <Header /> : null}
        <SwitchRoutes />
        <div id="app-bottom-nav" />
      </main>
      <FloatingActionGroup />
    </div>
  )
}

export default App
