import * as yup from 'yup'
import Link from '@material-ui/core/Link'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  name: yup.string().trim().min(3).required(),
  constant_value: yup
    .number()
    .max(10, 'El valor sobre la hora ordinaria no debe ser mayor de 10')
    .moreThan(0, 'El valor debe ser mayor que 0')
    .required(),
  quantity: yup
    .number()
    .lessThan(100, 'El valor debe ser menor que 100')
    .moreThan(0, 'El valor debe ser mayor que 0'),
})

export const getDataToSend = (initData = {}, currData = {}) => {
  const dataToSend = []
  const categories = ['extra_hours', 'surcharges', 'others']

  categories.forEach((category) => {
    const initCategoryData = initData[category] || []
    const currCategoryData = currData[category] || []

    for (let i = 0; i < currCategoryData.length; i += 1) {
      const { quantity } = currCategoryData[i]
      const { quantity: initialQuantity } = initCategoryData[i] || {}

      if (quantity !== undefined) {
        if (initialQuantity === undefined && quantity !== 0) {
          dataToSend.push({
            payroll_concept_id: currCategoryData[i].id,
            quantity,
          })
        }

        if (initialQuantity !== undefined && quantity !== initialQuantity) {
          dataToSend.push({
            id: currCategoryData[i].itemId,
            quantity,
          })
        }
      }
    }
  })

  return dataToSend
}

export const validateEmptyNewExtraHour = (values) => {
  const { name, constant_value: constantValue, quantity } = values

  return !(/\S/.test(name) || /\S/.test(constantValue) || /\S/.test(quantity))
}

export const tabs = [
  {
    key: 'extra_hours',
    label: 'Horas extras',
  },
  {
    key: 'surcharges',
    label: 'Recargos',
  },
  {
    key: 'others',
    label: 'Otros conceptos',
  },
]

export const overtimeDescription = {
  extra_hours: (
    <>
      Ingresa aquí el número de horas extras o recargos. Si tienes inquietudes
      ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-ingresar-horas-extras-y-recargos"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  surcharges: (
    <>
      Ingresa aquí el número de horas extras o recargos. Si tienes inquietudes
      ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-ingresar-horas-extras-y-recargos"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  others: (
    <>
      Si deseas crear una nueva hora extra o recargo con un valor sobre la hora
      ordinaria diferentes a los estipulados por la ley, aquí puedes crear
      nuevos conceptos y definir el valor que desees. Si tienes inquietudes
      ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-puedo-crear-un-nuevo-concepto-para-una-hora-extra-y-slash-o-recargo-con-nominapp"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
}
