import { useFormikContext } from 'formik'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Button from 'components/UI/Button/Button'

const Decree376ToolbarActions = ({ rows = [] }) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormikContext()
  let someSelected = false
  let somePaid = false

  rows?.map((row) => {
    if (!someSelected && row.isSelected) {
      someSelected = true
    }
    if (!somePaid && row.original.paid_in_period) {
      somePaid = true
    }
    return undefined
  })

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'massiveActionsMenu',
  })

  const disabledButtonOptions = values.decree376_payments.every(
    (payment) => payment.disableCheck
  )
  const setMassiveAction = (option) => {
    values.decree376_payments.forEach((payment, index) => {
      if (payment.payment_options.includes(option) && payment.selection)
        setFieldValue(`decree376_payments[${index}].paid_in_period`, option)
    })
    popupState.close()
  }

  return (
    <div>
      <Button
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        disabled={disabledButtonOptions}
        {...bindTrigger(popupState)}
      >
        Pago masivo
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={() => setMassiveAction('company_and_worker')}>
          Empresa y Empleado
        </MenuItem>
        <MenuItem onClick={() => setMassiveAction('company')}>
          Solo Empresa
        </MenuItem>
      </Menu>
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || (!somePaid && !someSelected)}
      >
        Guardar
      </Button>
    </div>
  )
}

export default Decree376ToolbarActions
