import InputAdornment from '@material-ui/core/InputAdornment'

import FormField from 'components/UI/Formik/FormField/Index'

import { isObjectEmpty } from 'utils/general'

export const institutionCategoriesNames = {
  health_provider: 'EPS',
  pension_provider: 'Fondos de Pensiones',
  arl_compensation_fund: 'ARL y Caja de Compensación',
}

export const getColumns = (
  category,
  isInstitution,
  ssAcounting,
  integratedCounterpartCode
) => {
  const columns = [
    {
      Header: isInstitution ? 'Nombre' : 'Concepto',
      accessor: isInstitution ? 'institution_name' : 'payroll_concept_name',
      editable: !isInstitution,
      Edit: ({ rowValues, row }) => {
        if (!rowValues?.editable) return row.original.payroll_concept_name

        return (
          <FormField
            name="payroll_concept_name"
            startAdornment={
              !isInstitution &&
              category !== 'deductions' &&
              category !== 'retentions' &&
              category !== 'payment_method' ? (
                <InputAdornment position="start">
                  (Prefijo Contable)
                </InputAdornment>
              ) : undefined
            }
          />
        )
      },
    },
  ]

  if (!(isInstitution && ssAcounting === 'worker')) {
    columns.push({
      Header: 'Cuenta contable',
      accessor: 'accounting_code',
      Cell: ({ row }) =>
        `${
          !isInstitution &&
          category !== 'deductions' &&
          category !== 'retentions' &&
          category !== 'payment_method'
            ? '(Prefijo Contable) '
            : ''
        }${row.original.accounting_code || ''}`,
      editable: true,
      Edit: () => (
        <FormField
          name="accounting_code"
          startAdornment={
            !isInstitution &&
            category !== 'deductions' &&
            category !== 'retentions' &&
            category !== 'payment_method' ? (
              <InputAdornment position="start">
                (Prefijo Contable)
              </InputAdornment>
            ) : undefined
          }
        />
      ),
    })
  }

  if (
    (integratedCounterpartCode &&
      ['social_benefits', 'social_security', 'termination', 'legal'].includes(
        category
      )) ||
    (!integratedCounterpartCode &&
      [
        'legal',
        'non_salary_income',
        'overtime',
        'salary_income',
        'social_benefits',
        'social_security',
        'termination',
      ].includes(category))
  ) {
    const getLegalCategorySpecificCondition = (payrollConceptName) => {
      return (
        integratedCounterpartCode &&
        category === 'legal' &&
        ['Auxilio de conectividad', 'Subsidio de Transporte'].includes(
          payrollConceptName
        )
      )
    }

    columns.push({
      Header: 'Contrapartida',
      accessor: 'counterpart_code',
      editable: (_, rowValues) => {
        return (
          rowValues.counterpart_code != null &&
          !getLegalCategorySpecificCondition(rowValues.payroll_concept_name)
        )
      },
      Cell: ({ row }) =>
        getLegalCategorySpecificCondition(row.original.payroll_concept_name)
          ? null
          : row.original.counterpart_code,
    })
  }

  if (isInstitution) {
    columns.push({
      Header: 'NIT',
      accessor: 'id_number',
    })
  }

  return columns
}

export const getAccountDirtyValues = (oldValues, newValues, isInstitution) => {
  let key
  const dirtyValues = {}

  if (isInstitution) {
    key = 'accounting_code'

    if (oldValues[key] !== newValues[key]) {
      dirtyValues[key] = newValues[key]
      dirtyValues.institution_id = oldValues.institution_id
    }
  } else {
    key = 'payroll_concept_name'
    if (oldValues[key] !== newValues[key]) dirtyValues[key] = newValues[key]

    key = 'counterpart_code'
    if (oldValues[key] !== newValues[key]) dirtyValues[key] = newValues[key]

    key = 'accounting_code'
    if (!newValues.id || oldValues[key] !== newValues[key])
      dirtyValues[key] = newValues[key]

    if (!isObjectEmpty(dirtyValues))
      dirtyValues.payroll_concept_id = oldValues.payroll_concept_id
  }

  return dirtyValues
}
