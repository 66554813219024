import queryString from 'query-string'
import { createContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useBreadcrumbsConfig } from 'components/App/BreadcrumbsProvider'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Page from 'components/UI/Page/Page'
import Steps from 'components/UI/Steps/Steps'

import { formatDateRange } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'
import periodService from 'services/payroll/periodService'

import * as routes from 'config/routes'

import DropDownPeriod from './DropDownPeriod'
import GeneratedPayroll from './GeneratedPayroll'
import AccountingFileModal from './Modals/AccountingFileModal'
import BankFileModal from './Modals/BankFileModal'
import PayslipModal from './Modals/PayslipModal'
import SeveranceFileModal from './Modals/SeveranceFileModal'
import SignInModal from './Modals/SignInModal'
import useAccountingFileModal from './Modals/useAccountingFileModal'
import useBankFileModal from './Modals/useBankFileModal'
import usePayslipModal from './Modals/usePayslipModal'
import useSeveranceFileModal from './Modals/useSeveranceFileModal'
import PayPayroll from './PayPayroll'
import { signUpOptions, signUpPeriod } from './fromSignUpHelpers'
import stepsData from './stepsData'

export const PeriodContext = createContext()

const useStyles = makeStyles((theme) => ({
  stepsContainer: {
    width: '-webkit-fill-available',
    backgroundColor: theme.palette.primary.lighter,
    '& > div': {
      [theme.breakpoints.up('sm')]: {
        height: '64px',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  titleHeader: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerPage: {
    margin: theme.spacing(0),
  },
}))

const Payroll = ({ match, history, payPreviousPayroll }) => {
  const classes = useStyles()
  const [period, setPeriod] = useState()
  const [options, setOptions] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false)
  const { handleError } = useErrorHandler()
  const location = useLocation()
  const { setBreadcrumbConfig } = useBreadcrumbsConfig()
  const [showSummaryTable, setShowSummaryTable] = useState(false)
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()

  const queryParams = queryString.parse(location.search)
  const isInsideAuth = queryParams.password_recovered

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal(period)
  const {
    bankFileModalState,
    openBankFileModal,
    closeBankFileModal,
  } = useBankFileModal(period)
  const {
    severanceFileModalState,
    openSeveranceFileModal,
    closeSeveranceFileModal,
  } = useSeveranceFileModal(period)
  const {
    accountingFileModalState,
    openAccountingFileModal,
    closeAccountingFileModal,
  } = useAccountingFileModal(period)

  const payrollRoutes = [
    routes.PERIOD_PAY_PAYROLL(),
    routes.PERIOD_PAY_PREVIOUS_PAYROLL(),
  ]
  const stepIndex = payrollRoutes.includes(match.path) ? 0 : 1
  const isGeneratedPayroll = stepIndex === 1

  const updatePeriodCallback = (updatedData, hasLoading) => {
    if (!updatedData) return

    if (hasLoading) setIsLoading(true)

    const newPeriod = {
      ...period,
      ...updatedData.period,
      // Have to disable because back receives prop this way
      // eslint-disable-next-line no-underscore-dangle
      payrolls: updatedData.payroll?.__deleted__
        ? period.payrolls.filter(
            (payroll) => payroll.id !== updatedData.payroll.id
          )
        : period.payrolls.map((p) => {
            if (p.id === updatedData.payroll.id) {
              return {
                ...p,
                ...updatedData.payroll,
              }
            }
            return p
          }),
    }

    setPeriod(newPeriod)
    if (hasLoading) setIsLoading(false)

    if (payPreviousPayroll) trackEvent(integrationEvent.PAYROLL_OLD_PERIOD)
  }

  const { periodId } = match.params

  const fetchPeriodData = async () => {
    setIsLoading(true)

    if (isInsideAuth) {
      setPeriod(signUpPeriod)
      setOptions(signUpOptions)
      setIsLoading(false)
    } else {
      try {
        let periodRes
        let optionsRes

        if (periodId) {
          ;[periodRes, optionsRes] = await Promise.all([
            periodService.getById(periodId),
            fileService.getPeriodOptions(periodId),
          ])
        } else {
          periodRes = await periodService.getCurrent()
          optionsRes = await fileService.getPeriodOptions(periodRes.id)
        }

        ReactDOM.unstable_batchedUpdates(() => {
          if (!payPreviousPayroll) {
            if (periodRes.payrolls_paid && stepIndex === 0)
              history.push(routes.PERIOD_GENERATED_PAYROLL())

            if (!periodRes.payrolls_paid && stepIndex === 1)
              history.push(routes.PERIOD_PAY_PAYROLL())
          }
          setShowSummaryTable(periodRes.payrolls_paid)
          setPeriod(periodRes)
          setOptions(optionsRes)
          setIsLoading(false)
        })
      } catch (error) {
        handleError(error)
      }
    }
  }

  useEffect(() => {
    fetchPeriodData()
  }, [payPreviousPayroll, periodId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleReturnToPayPayroll = async () => {
    setIsLoadingModalOpen(true)

    try {
      await periodService.updateCurrent({ payrolls_paid: false })

      history.replace(routes.PERIOD_PAY_PAYROLL())
    } catch (error) {
      handleError(error)
    }

    setIsLoadingModalOpen(false)
  }

  useEffect(() => {
    const updatePayrollView = async () => {
      if (payPreviousPayroll) {
        showLoadingModal()
        try {
          await periodService.updateCurrent({ payrolls_paid: false })
        } catch (error) {
          handleError(error)
        }
        hideLoadingModal()
      }

      setShowSummaryTable(false)
    }

    if (!payPreviousPayroll) {
      setBreadcrumbConfig('/periods_history/*', {
        onClick: () => updatePayrollView(),
      })
    } else {
      setBreadcrumbConfig('/pay_payroll', {
        onClick: () => updatePayrollView(),
      })
    }
  }, [
    handleError,
    hideLoadingModal,
    payPreviousPayroll,
    setBreadcrumbConfig,
    showLoadingModal,
    showSummaryTable,
  ])

  const handleToggleLoadingModal = (value) => {
    setIsLoadingModalOpen(value)
  }

  const currentPayrollTitle = isGeneratedPayroll
    ? 'Resumen de nómina'
    : 'Liquidar nómina'

  const getTitle = () => {
    if (payPreviousPayroll) return 'Historial de Periodos'
    return currentPayrollTitle
  }

  return (
    <PeriodContext.Provider
      value={{
        period,
        openPayslipModal,
        openBankFileModal,
        openSeveranceFileModal,
        openAccountingFileModal,
        options,
        handleToggleLoadingModal,
        payPreviousPayroll,
        fetchPeriodData,
        updatePeriodCallback,
        isGeneratedPayroll,
        setShowSummaryTable,
      }}
    >
      <Page
        header={
          <section>
            {!payPreviousPayroll && (
              <div className={classes.stepsContainer}>
                <Steps
                  current={stepIndex}
                  progress={stepIndex}
                  stepsData={Object.keys(stepsData).map(
                    (step) => stepsData[step]
                  )}
                  style={{ width: 767 }}
                  onChangeStep={
                    isGeneratedPayroll ? handleReturnToPayPayroll : null
                  }
                />
              </div>
            )}
            <div className={classes.titleHeader}>
              <Typography variant="h5">{getTitle()}</Typography>
              {!!period && !isObjectEmpty(period) ? (
                <DropDownPeriod
                  period={period}
                  generatedPayroll={isGeneratedPayroll}
                />
              ) : null}
            </div>
          </section>
        }
        headerClassName={classes.headerPage}
        documentTitle={
          payPreviousPayroll
            ? `Nómina${
                period
                  ? ` del ${formatDateRange(
                      period.initial_day,
                      period.end_day
                    )}`
                  : ''
              } `
            : currentPayrollTitle
        }
        isLoading={isLoading}
        isLoadingWithModal={isLoadingModalOpen}
      >
        <Box>
          {isGeneratedPayroll ? (
            <GeneratedPayroll
              handleReturnToPayPayroll={handleReturnToPayPayroll}
            />
          ) : (
            <PayPayroll
              period={period}
              payPreviousPayroll={payPreviousPayroll}
            />
          )}
        </Box>
        {payslipModalState.open && (
          <PayslipModal
            state={payslipModalState}
            handleClose={closePayslipModal}
            handleDownloadPayslip={handleDownloadPayslip}
          />
        )}
        <BankFileModal
          state={bankFileModalState}
          handleClose={closeBankFileModal}
        />
        <SeveranceFileModal
          state={severanceFileModalState}
          handleClose={closeSeveranceFileModal}
          period={period}
        />
        <AccountingFileModal
          state={accountingFileModalState}
          handleClose={closeAccountingFileModal}
        />
        {/** Add login inside app when recovery password */}
        {queryParams.password_recovered && <SignInModal />}
      </Page>
    </PeriodContext.Provider>
  )
}

export default Payroll
