import { useState } from 'react'

const initialState = {
  open: false,
  title: '',
  modalType: '',
}

const useUpdateGeneralWorkerDataModal = (title, modalType) => {
  const [state, setState] = useState({
    ...initialState,
    title,
    modalType,
  })

  const openModal = (title = '', modalType = '') =>
    setState({ ...state, open: true, modalType: modalType, title: title })

  const closeModal = () =>
    setState({ ...state, open: false, modalType: '', title: '' })

  return {
    updateGeneralDataModalState: state,
    openGeneralDataModal: openModal,
    closeGeneralDataModal: closeModal,
  }
}

export default useUpdateGeneralWorkerDataModal
