import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import { getDistributor } from 'utils/auth'

import { PeriodContext } from '../../Payroll'
import getSeverance from '../common/helpers'
import PayPayrollAdvancesMenu from './PayPayrollAdvancesMenu'
import PaySocialSecurityMenu from './PaySocialSecurityMenu'
import SocialBenefitsMenu from './SocialBenefitsMenu'
import SocialSecurityFileMenu from './SocialSecurityFileMenu'

const Payments = () => {
  const {
    period: {
      editable: periodEditable,
      payrolls_advance_value: payrollAdvanceValue,
    },
    options: {
      bank_file: bankFile,
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      social_benefits: socialBenefits,
      decree376_periods: decree376Periods,
    },
    openBankFileModal,
    openSeveranceFileModal,
  } = useContext(PeriodContext)

  const distributor = getDistributor()

  const notAportesEnLinea = distributor !== 'aportes_en_linea'

  const featureState = usePremiumFeature('payrollPayment')

  const handleClickBankFile = featureState.isPremiumFeature
    ? featureState.openModal
    : openBankFileModal

  const socialBenefitsToDownload =
    !periodEditable &&
    socialBenefits?.findIndex((sb) => sb.action === 'update') !== -1

  const hasDecree376Options =
    decree376Periods &&
    decree376Periods.length > 0 &&
    decree376Periods.some((decreePeriod) => decreePeriod.social_security_file)

  const severance = getSeverance(socialBenefits)

  const handleClickSeveranceFile = () => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }

    openSeveranceFileModal()
  }

  return bankFile ||
    socialSecurityFile ||
    studentsSocialSecurityFile ||
    hasDecree376Options ||
    socialBenefitsToDownload ||
    severance.hasSeverance ? (
    <CardReview>
      <Typography variant="h5">Pagos</Typography>

      {socialSecurityFile && <PaySocialSecurityMenu />}

      {(socialSecurityFile ||
        studentsSocialSecurityFile ||
        hasDecree376Options) && <SocialSecurityFileMenu />}
      {bankFile && (
        <Button
          variant="text"
          startIcon={<AccountBalanceOutlinedIcon />}
          onClick={() => handleClickBankFile()}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Archivo pago en banco
        </Button>
      )}
      {severance.hasSeverance && notAportesEnLinea && (
        <Button
          variant="text"
          startIcon={<BallotOutlinedIcon />}
          onClick={handleClickSeveranceFile}
          endIcon={
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          }
        >
          Archivo pago de cesantías
        </Button>
      )}
      {socialBenefitsToDownload && <SocialBenefitsMenu />}
      {payrollAdvanceValue > 0 ? <PayPayrollAdvancesMenu /> : null}
    </CardReview>
  ) : null
}

export default Payments
