import { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { getAvistaCreditSimulation } from 'services/benefits/credits'

yup.setLocale(yupLocaleES)

export const simulatorInitialValues = {
  credit_value: null,
  accept_avista_terms: false,
  number_fees: null,
}

export const getValidationSchema = (
  creditMinimumValue,
  creditMaximumValue,
  numberFeeMax
) =>
  yup.object({
    accept_avista_terms: yup.bool(),
    number_fees: yup
      .number()
      .min(numberFeeMax === '24' ? 6 : 3)
      .max(numberFeeMax),
    credit_value: yup
      .number()
      .nullable()
      .min(
        creditMinimumValue,
        `El valor mínimo es de ${formatCurrency(creditMinimumValue)}`
      )
      .max(
        creditMaximumValue,
        `El valor máximo es de ${formatCurrency(creditMaximumValue)}`
      )
      .required(),
  })

export const getSliderFeesMarks = (numberFees) => {
  const minMark = numberFees === 24 ? 5 : 2

  return new Array(numberFees)
    .fill()
    .map((_, markIndex) => {
      if (markIndex >= minMark) {
        return { value: markIndex + 1, label: `${markIndex + 1}` }
      }
      return null
    })
    .filter((mark) => mark !== null)
}

export const isValidCreditValue = (currentValue, maximumValue) => {
  if (
    Number(currentValue) &&
    Number(maximumValue) &&
    currentValue > maximumValue
  )
    return false

  return true
}

// This is because if it is monthly in 1 year the person can pay 12 fees and if it is fortnightly the person can pay 24 fees
const payrollFrequencies = {
  12: 'monthly',
  24: 'fortnightly',
}

export const useAvistaCreditSimulation = () => {
  const queryClient = useQueryClient()
  const { data: currentApplicationData = {} } =
    queryClient.getQueryData('creditApplicationData') || {}
  const [creditConditions, setCreditConditions] = useState({
    creditValue: 0,
    numberFees: 0,
    feeValue: 0,
    suretyBond: 0,
    insurance: 0,
    fourOneThousandTax: 0,
    disbursementAmount: 0,
  })
  const [isSimulationValid, setIsSimulationValid] = useState(false)
  const initialSimulationRef = useRef(true)
  const { credit_simulator: creditSimulatorData } = currentApplicationData
  const payrollFrequency = payrollFrequencies[creditSimulatorData?.max_term]
  const saveSimulationResult = (data, status, message, error) => {
    if (
      error === null &&
      status?.toLowerCase() === 'success' &&
      message === ''
    ) {
      setCreditConditions(data)
      setIsSimulationValid(true)
    }
  }

  const { data: initialSimulationData } = useQuery(
    'creditSimulation',
    () =>
      getAvistaCreditSimulation({
        identification: creditSimulatorData.identification,
        incomes: creditSimulatorData.incomes,
        deductions: creditSimulatorData.deductions,
        retentions: creditSimulatorData.retentions,
        term: currentApplicationData.credit_simulator.term,
        lending_amount: creditSimulatorData.preapproved_value,
        payroll_frequency: payrollFrequency,
      }),
    {
      enabled:
        initialSimulationRef.current && creditSimulatorData !== undefined,
      onSuccess: ({ data, status, error }) => {
        saveSimulationResult(
          {
            creditValue: creditSimulatorData.preapproved_value,
            numberFees: currentApplicationData.credit_simulator.max_term,
            feeValue: data?.fee || 0,
            suretyBond: data?.surety_bond || 0,
            insurance: data?.insurance || 0,
            fourOneThousandTax: data?.['4_100_tax'] || 0,
            disbursementAmount: data?.dibursement_amount || 0,
          },
          status,
          data?.message,
          error
        )
        initialSimulationRef.current = false
      },
    }
  )

  const {
    data: simulationData,
    mutate: onSimulateCreditFee,
    isLoading: isCalculatingFee,
  } = useMutation((data) => getAvistaCreditSimulation(data))

  const getCreditFeeValue = (currentValues, { setFieldError } = {}) => {
    onSimulateCreditFee(
      {
        identification: creditSimulatorData.identification,
        incomes: creditSimulatorData.incomes,
        deductions: creditSimulatorData.deductions,
        term: currentValues.number_fees,
        lending_amount: currentValues.credit_value,
        payroll_frequency: payrollFrequency,
      },
      {
        onSuccess: ({ data, status, error }) => {
          saveSimulationResult(
            {
              creditValue: currentValues.credit_value || 0,
              numberFees: currentValues.number_fees,
              feeValue: data?.fee || 0,
              suretyBond: data?.surety_bond || 0,
              insurance: data?.insurance || 0,
              fourOneThousandTax: data?.['4_100_tax'] || 0,
              disbursementAmount: data?.dibursement_amount || 0,
            },
            status,
            data?.message,
            error
          )
        },
        onError: ({ message, code }) => {
          if (['002', '003'].includes(code)) {
            setFieldError('credit_value', message)
          }
          if (isSimulationValid) {
            setIsSimulationValid(false)
          }
        },
      }
    )
  }

  return {
    creditConditions,
    simulationData,
    isCalculatingFee,
    getCreditFeeValue,
    currentApplicationData,
    isSimulationValid,
    initialSimulationFee: initialSimulationData?.data?.fee,
  }
}
