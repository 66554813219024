import { useState } from 'react'

const initialState = {
  open: false,
}

const useAddAccountantModal = () => {
  const [state, setState] = useState(initialState)

  const openModal = () => {
    setState({
      open: true,
    })
  }

  const closeModal = () => {
    setState(initialState)
  }

  return {
    addAccountantModalState: state,
    openAddAccountantModal: openModal,
    closeAddAccountantModal: closeModal,
  }
}

export default useAddAccountantModal
