import http, { getApiURL } from '../httpService'

export const getComments = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments`,
  })

  return http.getV2(url)
}

export const createComment = ({ value, companyId }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments`,
  })

  return http.postV2(url, value)
}

export const updateComment = ({ value, commentId, companyId }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments/${commentId}`,
  })

  return http.patchV2(url, value)
}

export const deleteComment = ({ commentId, companyId }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments/${commentId}`,
  })

  return http.deleteV2(url)
}

export default {
  getComments,
  createComment,
  updateComment,
  deleteComment,
}
