import { useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import FormField from 'components/UI/Formik/FormField/Index'

import { getSliderFeesMarks } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(auto, 430px))',
    },
  },
  valueTextfield: {
    height: 72,
    fontSize: 45,
  },
  valueTextfieldColor: {
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: theme.palette.primary[400],
    },
  },
  currencySymbol: {
    fontSize: 45,
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
  },
  feeTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  feeValue: {
    color: theme.palette.grey[700],
    fontSize: 45,
  },
  alertIcon: {
    alignSelf: 'center',
  },
  sliderRoot: {
    marginTop: theme.spacing(2),
  },
  sliderRail: {
    height: 10,
  },
  sliderTrack: {
    height: 10,
  },
  sliderMark: {
    height: 10,
    width: 1,
  },
  sliderThumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.white,
    border: `4px solid`,
    marginTop: -7,
    marginLeft: -12,
  },
  sliderMarkLabel: {
    top: 40,
    color: theme.palette.grey[700],
    fontWeight: 700,
  },
  calculateFeeContainer: {
    display: 'flex',
    marginTop: theme.spacing(5),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}))

const CreditValueInput = ({
  feeValue,
  isValidFeeValue,
  maximumCreditValue,
  numberFee = 24,
}) => {
  const classes = useStyles()
  const { values } = useFormikContext()

  const minValue = numberFee === 24 ? 6 : 3

  return (
    <div>
      <div className={classes.container}>
        <FormField
          name="credit_value"
          variant="currency"
          className={classes.valueTextfield}
          startAdornment={
            <InputAdornment>
              <Typography className={classes.currencySymbol}>$</Typography>
            </InputAdornment>
          }
          inputProps={{
            className: classes.valueTextfieldColor,
          }}
        />
        {isValidFeeValue ? (
          <div>
            <Typography variant="h6" className={classes.feeTitle}>
              Valor de tu cuota
            </Typography>
            <Typography variant="h6" className={classes.feeValue}>
              {feeValue}
            </Typography>
          </div>
        ) : (
          <Alert
            classes={{ icon: classes.alertIcon }}
            header="¡Pasaste el límite!"
          >
            <Typography variant="body1">
              Máximo puedes solicitar <b>{maximumCreditValue}</b>
            </Typography>
          </Alert>
        )}
      </div>
      <Typography id="number-fees-slider">
        <b>Selecciona el plazo para pagar tu crédito:</b>
        {` ${values?.number_fees} Cuotas`}
      </Typography>
      <FormField
        name="number_fees"
        variant="slider"
        defaultValue={numberFee}
        aria-labelledby="number-fees-slider"
        step={1}
        marks={getSliderFeesMarks(numberFee)}
        min={minValue}
        max={numberFee}
        classes={{
          root: classes.sliderRoot,
          rail: classes.sliderRail,
          track: classes.sliderTrack,
          mark: classes.sliderMark,
          thumb: classes.sliderThumb,
          markLabel: classes.sliderMarkLabel,
        }}
      />
    </div>
  )
}

export default CreditValueInput
