import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import { socialBenefitTypeData } from 'components/Period/SocialBenefits/helpers'

import { formatCurrency } from 'utils/format'
import { formatDisplayDateString } from 'utils/dateTime'

import messages from 'messages/dashboard'

const useStyles = makeStyles({
  infoIcon: {
    float: 'right',
  },
})

const DashboardCommingSoonProvisions = ({ provisions }) => {
  const classes = useStyles()

  return (
    <Box p={4} component={Paper}>
      <Typography variant="subtitle1">Provisiones</Typography>
      <Typography>
        Valores aproximados que deberias tener ahorrados para el pago de tus
        prestaciones sociales al{' '}
        {formatDisplayDateString(provisions.limit_date)}.
      </Typography>
      <List>
        {Object.keys(provisions).map((key) => {
          const prov = provisions[key]
          // ignore limit_date
          if (key === 'limit_date') return null

          return (
            <ListItem key={key}>
              <ListItemText
                primary={formatCurrency(prov)}
                secondary={socialBenefitTypeData[key].name}
              />
            </ListItem>
          )
        })}
      </List>
      <TooltipInfoIcon
        title={messages.provisions}
        iconProps={{ className: classes.infoIcon }}
        size="default"
        interactive
      />
    </Box>
  )
}

export default DashboardCommingSoonProvisions
