import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Modal from 'components/UI/Modal/Modal'

import { WORKER_PROFILE_BENEFITS } from 'config/routes'

import generatedCreditImg from 'assets/images/views/payroll/generated_payroll.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '160px',
    },
  },
  title: {
    margin: theme.spacing(2, 0),
  },
  message: {
    color: theme.palette.grey[500],
  },
}))

const SentApplicationModal = ({ open, onClose }) => {
  const classes = useStyles()
  const history = useHistory()

  const onAgreed = () => {
    history.replace(WORKER_PROFILE_BENEFITS())
  }

  return (
    <Modal
      open={open}
      okText="¡Entendido!"
      onOk={onAgreed}
      onCancel={onClose}
      hideCancelButton
      alignButtons="center"
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <div className={classes.root}>
        <img
          src={generatedCreditImg}
          alt="Application sent"
          className={classes.image}
        />
        <Typography variant="h5" className={classes.title}>
          ¡Tu solicitud fue enviada!
        </Typography>
        <Typography variant="body1" className={classes.message}>
          Acabamos de enviar toda la información que nos compartiste a AVISTA
          COLOMBIA SAS. Pronto recibirás un mensaje SMS para realizar una
          verificación de identidad y ¡listo!. Si todo sale bien, tendrás tu
          crédito en la mano.
        </Typography>
      </div>
    </Modal>
  )
}

export default SentApplicationModal
