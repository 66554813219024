import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import useNotifications from 'utils/hooks/useNotifications'

import { updateUser, getUsers } from 'services/organizer/userService'

import notification from 'messages/notification'

import useGetColumnsData from './helpers'

const OrganizerUsersIndex = () => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const columnsData = useGetColumnsData()

  const fetchData = (pageIndex, search) => {
    return {
      queryKey: ['getAll', pageIndex + 1, search],
      queryFunction: () => getUsers({ page: pageIndex + 1, search }),
    }
  }

  const handleUpdateUser = (oldUser, newUser) => {
    const dirtyValues = getDirtyValues(oldUser, newUser)
    return {
      data: { id: newUser.id, ...dirtyValues },
      mutationFunction: updateUser,
    }
  }

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAll'], {
        exact: false,
      })
      showSuccessMessage(notification.SETTINGS_USER_PROFILE_SUCCESS)
    },
  }

  return (
    <Page header="Usuarios">
      <Table
        columns={columnsData}
        data={fetchData}
        editable={{
          onUpdateRow: handleUpdateUser,
          hideDelete: true,
          mutationOptions,
        }}
        options={{
          customActionsWidth: 90,
        }}
      />
    </Page>
  )
}

export default OrganizerUsersIndex
