import { useState } from 'react'

import Box from '@material-ui/core/Box'
import FormLabel from '@material-ui/core/FormLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import MuiLink from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { formatCurrency } from 'utils/format'

import messages from 'messages/payroll'

export default function Header({
  payroll,
  holidaysAverageSalary,
  noveltiesAverageSalary,
  isEdit,
  onEnableEditing,
  onDisableEditing,
  onChangeData,
  onOK,
}) {
  const { contract_category: contractCategory } = payroll

  return (
    <>
      <Box display="flex" mb={2}>
        {[
          'employee',
          'pensioner',
          'student_law_789',
          'terminated',
          'part_time_contract',
        ].includes(contractCategory) && (
          <SalaryBase
            isEdit={isEdit}
            title="Salario base vacaciones"
            contentTooltip={messages.average_salary}
            onChangeData={(e) => onChangeData(e, 'holidays_average_salary')}
            onEditing={onEnableEditing}
            salaryValue={holidaysAverageSalary}
            salaryType="holidaysAverageSalary"
          />
        )}

        <SalaryBase
          isEdit={isEdit}
          title="Salario base incapacidades y licencias"
          contentTooltip={messages.novelties_average_salary}
          onChangeData={(e) => onChangeData(e, 'novelties_average_salary')}
          onEditing={onEnableEditing}
          salaryValue={noveltiesAverageSalary}
          salaryType="noveltiesAverageSalary"
        />

        {isEdit && <HeaderActions onCancel={onDisableEditing} onOK={onOK} />}
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&:first-child': {
      marginRight: theme.spacing(6),
    },
  },
}))

function SalaryBase({
  isEdit,
  title,
  contentTooltip,
  onChangeData,
  onEditing,
  salaryValue,
  salaryType,
}) {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <FormLabel htmlFor={`input_${salaryType}`} focused={isInputFocused}>
          {title}
        </FormLabel>
        <TooltipInfoIcon title={contentTooltip} interactive />
      </Box>
      {isEdit ? (
        <TextField
          id={`input_${salaryType}`}
          value={salaryValue}
          onChange={onChangeData}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          style={{ width: 175 }}
          data-cy={`input-${salaryType}`}
        />
      ) : (
        <>
          <Typography
            component="b"
            variant="h5"
            display="block"
            data-cy={`${salaryType}-value`}
          >
            {formatCurrency(salaryValue)}
          </Typography>

          <MuiLink
            component="button"
            onClick={onEditing}
            underline="always"
            data-cy={`edit-${salaryType}`}
          >
            Editar
          </MuiLink>
        </>
      )}
    </Box>
  )
}

function HeaderActions({ onCancel, onOK }) {
  return (
    <Box display="flex" alignItems="end" justifyContent="end" ml="auto">
      <Button variant="outlined" onClick={onCancel} size="small">
        Cancelar
      </Button>
      <Button onClick={onOK} size="small" data-cy="ok_button_average_salaries">
        OK
      </Button>
    </Box>
  )
}
