import { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'

import { makeStyles } from '@material-ui/core/styles'

import Steps from 'components/UI/Steps/Steps'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Page from 'components/UI/Page/Page'
import StepsActions from 'components/UI/StepsActions/StepsActions'

import useNotifications from 'utils/hooks/useNotifications'
import { isDev } from 'utils/general'

import {
  WORKER_PROFILE_BENEFITS,
  WORKER_BENEFITS_CREDIT_SIMULATOR,
} from 'config/routes'

import CardWrapper from './CardWrapper'
import SentApplicationModal from './SentApplicationModal'
import PersonalDataStep from './Fields/PersonalData'
import WorkerResidenceStep from './Fields/Residence'
import WorkerLaboralInformationStep from './Fields/LaboralInformation'
import FinancialInformationStep from './Fields/FinancialInformation/Index'
import WorkerReferencesStep from './Fields/References'

import { stepsData, validationSchema } from './stepsData'
import { spreadInitialValues } from './helpers'
import { useCreditApplication, useApplicationMutation } from './hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))

const CreditsForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState(-1)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const confirm = useConfirm()
  const { showErrorMessage } = useNotifications()
  const queryClient = useQueryClient()
  const [
    creditApplicationCurrentStep,
    ,
    removeCreditApplicationCurrentStep,
  ] = useLocalStorage('credit_current_step')
  const formattedCurrentStep = currentStep - 1

  useEffect(() => {
    if (creditApplicationCurrentStep === 1 && currentStep === 0) {
      setCurrentStep(creditApplicationCurrentStep)
    }
  }, [creditApplicationCurrentStep, currentStep])

  const { applicationData, isGettingApplicationData } = useCreditApplication({
    onSuccess: ({ data }) => {
      if (!creditApplicationCurrentStep && data?.current_step === 0) {
        history.replace(WORKER_BENEFITS_CREDIT_SIMULATOR())
        return
      }

      if (data?.current_step < 5) {
        setCurrentStep(data.current_step + 1)
      } else if (data?.current_step === 5) {
        setCurrentStep(data.current_step)
        setShowSuccessModal(true)
      }
    },
  })

  const onClickNext = () => {
    if (currentStep < 4) {
      setCurrentStep((prevStep) => prevStep + 1)
    }
  }

  const { onMutateApplication, isMutatingApplication } = useApplicationMutation(
    {
      onSuccess: () => {
        if (currentStep < 4) {
          onClickNext()
        }
        queryClient.invalidateQueries('creditApplicationData')
      },
      retry: false,
    }
  )

  const onClickStep = (index) => {
    setCurrentStep(index + 1)
  }

  const onClickPrevious = () => {
    if (currentStep !== 0) {
      setCurrentStep(formattedCurrentStep)
    }
    return currentStep
  }

  const saveFormData = (currentValues) => {
    const finalValues = {
      ...currentValues,
      current_step: currentStep,
    }

    onMutateApplication(finalValues)
  }

  const onSaveAndClose = async (
    currentValues,
    validateForm,
    setErrors,
    setTouched
  ) => {
    const currentErrors = await validateForm()

    if (Object.keys(currentErrors).length > 0) {
      const touchedFields = {}

      Object.keys(currentErrors).forEach((key) => {
        touchedFields[key] = true
      })
      setErrors(currentErrors)
      setTouched(touchedFields)
      showErrorMessage(
        'Para guardar el paso actual completa los campos faltantes'
      )
    } else {
      saveFormData(currentValues)
    }
  }

  const onCancelForm = () => {
    if (applicationData?.id !== null) {
      onMutateApplication({
        id: applicationData?.id,
      })
    } else {
      history.replace(WORKER_PROFILE_BENEFITS())
    }

    if (creditApplicationCurrentStep) {
      removeCreditApplicationCurrentStep()
    }
  }

  const onClickCancel = (values, validateForm, setErrors, setTouched) => {
    confirm({
      title: '¿Quieres guardar tu progreso?',
      description:
        'Al cerrar el formulario, puedes guardar tu progreso y en tu próximo inicio de sesión puedes retomar el punto en dónde quedaste.',
      okText: 'Guardar y salir',
      cancelText: 'Cancelar sin guardar',
      onOk: () => onSaveAndClose(values, validateForm, setErrors, setTouched),
      onCancel: onCancelForm,
    })
  }

  const onSubmit = (values) => {
    saveFormData(values)
  }

  const initialValues = {
    birth_department: null,
    issue_department: null,
    ...spreadInitialValues(applicationData, currentStep),
  }

  return (
    <Page
      documentTitle="Solicitud crédito de libranza"
      header="Solicitud crédito de libranza"
      isLoading={isGettingApplicationData || currentStep === -1}
    >
      <Steps
        current={formattedCurrentStep}
        progress={formattedCurrentStep}
        onChangeStep={onClickStep}
        stepsData={stepsData}
        className={classes.root}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema[formattedCurrentStep]}
        enableReinitialize
      >
        {({ handleSubmit, values, validateForm, setErrors, setTouched }) => {
          return (
            <Form>
              <CardWrapper currentStep={formattedCurrentStep}>
                {currentStep === 1 && <PersonalDataStep />}
                {currentStep === 2 && <WorkerResidenceStep />}
                {currentStep === 3 && <WorkerLaboralInformationStep />}
                {currentStep === 4 && <FinancialInformationStep />}
                {currentStep === 5 && <WorkerReferencesStep />}
                <StepsActions
                  cancelButtonProps={{
                    text: 'Cancelar y salir',
                    disabled: isMutatingApplication,
                    onClick: () =>
                      onClickCancel(
                        values,
                        validateForm,
                        setErrors,
                        setTouched
                      ),
                  }}
                  previousButtonProps={{
                    text: 'Anterior',
                    disabled: currentStep === 1,
                    onClick: onClickPrevious,
                  }}
                  nextButtonProps={{
                    text: currentStep < 5 ? 'Siguiente' : 'Guardar',
                    disabled: isMutatingApplication,
                    onClick: handleSubmit,
                  }}
                />
              </CardWrapper>
            </Form>
          )
        }}
      </Formik>
      <SentApplicationModal
        open={showSuccessModal}
        onClose={isDev ? () => setShowSuccessModal(false) : undefined}
      />
    </Page>
  )
}

export default CreditsForm
