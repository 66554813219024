import { useState } from 'react'

import Page from 'components/UI/Page/Page'

import Table from 'components/UI/Table/Table'
import { getLoandsById } from 'services/worker/loansService'

import { columnsDataShow } from './helpers'

const ShowLoans = ({ match }) => {
  const [loanName, setLoanName] = useState(null)

  const { loanId } = match.params

  const fetchLoanPayments = (page) => {
    const queryKey = ['getLoans', loanId, page]
    return {
      queryKey,
      queryFunction: () =>
        getLoandsById(loanId, {
          page: page + 1,
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setLoanName(data.name)
        },
      },
    }
  }

  return (
    <Page header={`Pagos del préstamo${loanName ? ` "${loanName}"` : ''}`}>
      <Table
        columns={columnsDataShow}
        data={fetchLoanPayments}
        options={{
          toolbar: false,
          customQueryFetch: 'payments',
          customFilterFetch: 'filters',
        }}
      />
    </Page>
  )
}

export default ShowLoans
