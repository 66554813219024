import clsx from 'clsx'

import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.info.main,
    cursor: 'help',
    marginLeft: 5,
    fontSize: '1.1rem',
  },
}))

const TooltipInfoIcon = ({
  title,
  size = 'small',
  interactive,
  tooltipProps,
  iconProps,
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      title={title}
      aria-label="info"
      interactive={interactive}
      {...tooltipProps}
    >
      <InfoOutlinedIcon
        fontSize={size}
        {...iconProps}
        className={clsx(classes.icon, iconProps?.className)}
      />
    </Tooltip>
  )
}

export default TooltipInfoIcon
