import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getCurrentPeriod,
  getSeveranceFile,
} from 'services/payroll/periodService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'currentPeriod') return getCurrentPeriod()

  return null
}

const getMutationFunction = (mutationData) => {
  const { periodId, mutationMethod, searchParams } = mutationData

  if (mutationMethod === 'GET_SEVERANCE_FILE')
    return getSeveranceFile(periodId, searchParams)

  return null
}

const usePeriodService = ({
  serviceParams = { queryKey: 'currentPeriod' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPeriodResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )
  const periodMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    periodQuery: {
      data,
      ...restPeriodResponse,
    },
    periodMutation,
  }
}

export default usePeriodService
