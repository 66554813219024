import { Redirect, Route, Switch } from 'react-router-dom'

import SettingsAccounting from 'components/Settings/Accounting/Accounting'
import SettingsIntegrationAccounting from 'components/Settings/Integration/Accounting/Accounting'

import OnboardingFirstSteps from 'views/Auth/Onboarding/FirstSteps'
import Onboarding from 'views/Auth/Onboarding/Index'
import WorkerCreditFormView from 'views/Benefits/CreditForm'
import WorkerCreditSimulatorView from 'views/Benefits/CreditSimulator'
import WorkerBenefitsView from 'views/Benefits/Index'
import CompanyFormView from 'views/Company/Form'
import CompanyIndexView from 'views/Company/Index'
import CompanyShowView from 'views/Company/Show'
import CompanyRoute from 'views/CompanyRoute'
import DashboardView from 'views/Dashboard'
import NoveltiesRequestIndex from 'views/NoveltiesRequest/Index'
import OrganizerAffiliationsIndex from 'views/Organizer/Affiliations/Index'
import OrganizerCompaniesView from 'views/Organizer/Company/Index'
import OrganizerCompanyPaymentsView from 'views/Organizer/Company/Payment/Form'
import OrganizerFilesView from 'views/Organizer/Files/Files'
import OrganizerPartnerIndexView from 'views/Organizer/Partner/Index'
import OrganizerPartnerPaymentFormView from 'views/Organizer/Partner/Payment/Form'
import OrganizerPartnerShowView from 'views/Organizer/Partner/Show'
import OrganizerUsersView from 'views/Organizer/Users/Index'
import OrganizerRoute from 'views/OrganizerRoute'
import PartnersLanding from 'views/PartnersLanding'
import Decree376View from 'views/Period/Decree376'
import PeriodDetailsView from 'views/Period/Details'
import ElectronicPayrollView from 'views/Period/ElectronicPayroll'
import NoveltiesHistoryView from 'views/Period/NoveltiesHistory'
import PayrollView from 'views/Period/Payroll'
import PeriodHistoryView from 'views/Period/PeriodHistory'
import SocialBenefitsView from 'views/Period/SocialBenefits'
import SocialSecurityView from 'views/Period/SocialSecurity'
import SocialSecurityPaymentHistory from 'views/Period/SocialSecurityPaymentHistory'
import SocialSecurityTransactionResponse from 'views/Period/SocialSecurityTransactionResponse'
import SettingsAdvancedView from 'views/Settings/Advanced'
import SettingsElectronicPayrollView from 'views/Settings/ElectronicPayroll'
import SettingsHistory from 'views/Settings/History'
import SettingsIntegrationView from 'views/Settings/Integration'
import SettingsProfileView from 'views/Settings/Profile'
import SettingsView from 'views/Settings/Settings'
import SettingsUsersView from 'views/Settings/Users'
import SettingsWorkersBenefitsView from 'views/Settings/WorkersBenefits'
import SubscriptionPaymentsView from 'views/Subscription/Payments'
import SubscriptionPaywallView from 'views/Subscription/Paywall'
import SubscriptionStatusView from 'views/Subscription/Status'
import SubscriptionTransactionResponseView from 'views/Subscription/TransactionResponse'
import ContractIndexView from 'views/Worker/Contract/Index'
import ContractTerminationForm from 'views/Worker/Contract/TerminationForm'
import WageIndex from 'views/Worker/Contract/WageIndex'
import WorkerFormView from 'views/Worker/Form'
import HolidaysView from 'views/Worker/HolidaysView'
import WorkerImportView from 'views/Worker/Import/Import'
import WorkerIndexView from 'views/Worker/Index'
import LoanIndex from 'views/Worker/Loan/Index'
import LoanShow from 'views/Worker/Loan/Show'
import PaymentHistoryView from 'views/Worker/PaymentHistory'
import SSAffiliationsView from 'views/Worker/SSAffiliations/Index'
import NewAffiliationView from 'views/Worker/SSAffiliations/NewAffiliation'
import WorkerShowView from 'views/Worker/Show'
import WorkerSocialBenefitsIndex from 'views/Worker/SocialBenefits/Index'
import WorkerSocialBenefitsShow from 'views/Worker/SocialBenefits/Show'
import WorkersWagesView from 'views/Worker/WagesUpdate'
import WorkerProfileRoute from 'views/WorkerProfileRoute'

import { getCompanyId } from 'utils/company'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import * as routes from 'config/routes'

import RouteNotFound from './Error/NotFound'
import PaymentRequired from './Error/PaymentRequired'
import { useUser } from './UserContext/useUser'

const SwitchRoutes = () => {
  const { active, workerWithBenefits, isWorker, isOrganizer, user } = useUser()
  const { hrNewModule, partnerLanding } = useFeatureFlags({
    flags: ['hrNewModule', 'partnerLanding'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
      email: user.email,
      userEmail: user.email,
    },
  })
  const { freetimeBeta } = useFeatureFlags({
    flags: ['freetimeBeta'],
  })

  let toPathUser

  if (isOrganizer) {
    toPathUser = routes.ORGANIZER_COMPANY_INDEX()
  } else if (isWorker) {
    if (!active && workerWithBenefits)
      toPathUser = routes.WORKER_PROFILE_BENEFITS()
    else toPathUser = routes.WORKER_PROFILE_SHOW()
  } else {
    toPathUser = routes.DASHBOARD
  }

  const affiliationsRoute =
    hrNewModule?.value === 'on'
      ? [
          <CompanyRoute
            key="affiliations"
            exact
            path={routes.WORKER_AFFILIATIONS_INDEX()}
            component={SSAffiliationsView}
          />,
          <CompanyRoute
            key="new-affiliation"
            exact
            path={routes.WORKER_AFFILIATIONS_NEW()}
            component={NewAffiliationView}
          />,
        ]
      : []

  const workerAffiliation =
    hrNewModule?.value === 'on'
      ? [
          <OrganizerRoute
            key="worker-affiliation"
            exact
            path={routes.ORGANIZER_WORKER_SHOW()}
            component={WorkerShowView}
          />,
        ]
      : []

  return (
    <Switch>
      <Redirect exact from="/" to={toPathUser} />
      <Redirect
        exact
        from="/worker"
        to={routes.WORKER_PROFILE_PAYMENTS_HISTORY()}
      />
      <Redirect exact from="/organizer" to={routes.ORGANIZER_COMPANY_INDEX()} />
      {partnerLanding?.value === 'on' ? (
        <CompanyRoute
          exact
          path={routes.PARTNER_LANDING()}
          component={PartnersLanding}
        />
      ) : null}
      {/* Organizer */}
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_COMPANY_INDEX()}
        component={OrganizerCompaniesView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_COMPANY_PAYMENT()}
        component={OrganizerCompanyPaymentsView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_USERS()}
        component={OrganizerUsersView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_GENERAL_ACTIONS()}
        component={OrganizerFilesView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_PARTNER_INDEX()}
        component={OrganizerPartnerIndexView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_PARTNER_SHOW()}
        component={OrganizerPartnerShowView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_PARTNER_EDIT_PAYMENT()}
        component={OrganizerPartnerPaymentFormView}
      />
      <OrganizerRoute
        exact
        path={routes.ORGANIZER_AFFILIATIONS()}
        component={OrganizerAffiliationsIndex}
      />
      {workerAffiliation}
      {/* Onboarding */}
      <CompanyRoute
        exact
        path={[
          routes.ONBOARDING_WELCOME(),
          routes.ONBOARDING_ROLE(),
          routes.ONBOARDING_PERSONALIZE(),
          routes.ONBOARDING_CONGRATULATIONS(),
        ]}
        component={Onboarding}
      />
      <CompanyRoute
        exact
        path={routes.ONBOARDING_FIRST_STEPS()}
        component={OnboardingFirstSteps}
      />
      {/* Dashboard */}
      <CompanyRoute exact path={routes.DASHBOARD} component={DashboardView} />
      {/* Edit User Profile */}
      <CompanyRoute
        exact
        path={routes.USER_PROFILE()}
        component={SettingsProfileView}
      />
      {/* Worker Profile */}
      <WorkerProfileRoute
        exact
        path={routes.WORKER_PROFILE_PAYMENTS_HISTORY()}
        component={PaymentHistoryView}
      />
      <WorkerProfileRoute
        exact
        path={routes.WORKER_PROFILE_SHOW()}
        component={WorkerShowView}
      />
      <WorkerProfileRoute
        exact
        path={routes.WORKER_PROFILE_BENEFITS()}
        component={WorkerBenefitsView}
      />
      <WorkerProfileRoute
        exact
        path={routes.WORKER_BENEFITS_CREDIT_FORM()}
        component={WorkerCreditFormView}
      />
      <WorkerProfileRoute
        exact
        path={routes.WORKER_BENEFITS_CREDIT_SIMULATOR()}
        component={WorkerCreditSimulatorView}
      />
      {/* Period */}
      <CompanyRoute
        exact
        path={[routes.PERIOD_PAY_PAYROLL(), routes.PERIOD_GENERATED_PAYROLL()]}
        component={PayrollView}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_PAY_PREVIOUS_PAYROLL(),
          routes.PERIOD_PREVIOUS_GENERATED_PAYROLL(),
        ]}
        render={(routeProps) => (
          <PayrollView {...routeProps} payPreviousPayroll />
        )}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_PAYROLL_VIEW_DETAILS(),
          routes.PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS(),
        ]}
        component={PeriodDetailsView}
      />
      <CompanyRoute
        exact
        path={routes.PERIOD_PAY_ELECTRONIC_PAYROLL()}
        component={ElectronicPayrollView}
      />
      <CompanyRoute
        exact
        path={routes.PERIOD_HISTORY()}
        component={PeriodHistoryView}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_NOVELTIES_HISTORY(),
          routes.PERIOD_PREVIOUS_PAYROLL_NOVELTIES_HISTORY(),
        ]}
        component={NoveltiesHistoryView}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_SOCIAL_BENEFIT_PERIODS(),
          routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(),
        ]}
        component={SocialBenefitsView}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_SOCIAL_SECURITY_SHOW(),
          routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(),
        ]}
        component={SocialSecurityView}
      />
      <CompanyRoute
        exact
        path={routes.SETTINGS_SOCIAL_SECURITY_INDEX()}
        component={SocialSecurityPaymentHistory}
      />
      <CompanyRoute
        exact
        path={routes.PERIOD_SOCIAL_SECURITY_TRANSACTION_RESPONSE()}
        component={SocialSecurityTransactionResponse}
      />
      <CompanyRoute
        exact
        path={[
          routes.PERIOD_DECREE_376_INDEX(),
          routes.PERIOD_PREVIOUS_PAYROLL_DECREE_376_INDEX(),
        ]}
        component={Decree376View}
      />
      {/* Workers */}
      <CompanyRoute
        exact
        path={routes.WORKER_INDEX()}
        component={WorkerIndexView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_NEW()}
        component={WorkerFormView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_IMPORT()}
        component={WorkerImportView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_BENEFITS_SHOW()}
        component={WorkerBenefitsView}
      />
      <CompanyRoute
        path={routes.WORKER_WAGES_UPDATE()}
        component={WorkersWagesView}
      />
      {affiliationsRoute}
      <CompanyRoute
        exact
        path={routes.WORKER_SHOW()}
        component={WorkerShowView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CREATE()}
        component={WorkerFormView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_PAYMENTS_HISTORY()}
        component={PaymentHistoryView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_HOLIDAYS_HISTORY()}
        component={HolidaysView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_NOVELTIES_HISTORY()}
        component={NoveltiesHistoryView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_INDEX()}
        component={ContractIndexView}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_WAGE_INDEX()}
        component={WageIndex}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_TERMINATION_NEW()}
        component={ContractTerminationForm}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_TERMINATION_EDIT()}
        render={(routeProps) => (
          <ContractTerminationForm {...routeProps} isEditing />
        )}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX()}
        component={WorkerSocialBenefitsIndex}
      />
      <CompanyRoute
        exact
        path={routes.WORKER_CONTRACT_SOCIAL_BENEFITS_SHOW()}
        component={WorkerSocialBenefitsShow}
      />
      <CompanyRoute path={routes.WORKER_LOAN_SHOW()} component={LoanShow} />
      <CompanyRoute path={routes.WORKER_LOAN_INDEX()} component={LoanIndex} />
      {/* Novelties request */}
      {freetimeBeta?.value === 'on' ? (
        <CompanyRoute
          path={routes.NOVELTIES_REQUEST_HISTORY()}
          component={NoveltiesRequestIndex}
        />
      ) : null}
      {/* Company */}
      <Route exact path={routes.COMPANY_INDEX()} component={CompanyIndexView} />
      <Route exact path={routes.COMPANY_SHOW()} component={CompanyShowView} />
      <Route exact path={routes.COMPANY_EDIT()} component={CompanyFormView} />
      {/* Settings */}
      <CompanyRoute exact path={routes.SETTINGS()} component={SettingsView} />
      <CompanyRoute
        exact
        path={routes.SETTINGS_ADVANCED()}
        component={SettingsAdvancedView}
      />
      <CompanyRoute
        exact
        path={routes.SETTINGS_USERS()}
        component={SettingsUsersView}
      />
      <CompanyRoute
        exact
        path={routes.SETTINGS_COMPANY_HISTORY()}
        component={SettingsHistory}
      />
      <CompanyRoute
        exact
        path={routes.SETTINGS_ACCOUNTING()}
        component={SettingsAccounting}
      />
      <CompanyRoute
        exact
        path={routes.SETTINGS_INTEGRATION_ALEGRA_ACCOUNTING()}
        component={SettingsIntegrationAccounting}
      />
      <CompanyRoute
        path={routes.SETTINGS_INTEGRATION_INDEX()}
        component={SettingsIntegrationView}
      />
      <CompanyRoute
        path={routes.SETTINGS_ELECTRONIC_PAYROLL()}
        component={SettingsElectronicPayrollView}
      />
      <CompanyRoute
        path={routes.SETTINGS_WORKERS_BENEFITS()}
        component={SettingsWorkersBenefitsView}
      />
      {/* Errors */}
      <CompanyRoute
        exact
        path={routes.PAYMENT_REQUIRED()}
        component={PaymentRequired}
      />
      {/* Subscription */}
      <CompanyRoute
        exact
        path={routes.SUBSCRIPTION()}
        component={SubscriptionPaywallView}
      />
      <CompanyRoute
        exact
        path={routes.SUBSCRIPTION_STATUS()}
        component={SubscriptionStatusView}
      />
      <CompanyRoute
        exact
        path={routes.SUBSCRIPTION_PAYMENTS()}
        component={SubscriptionPaymentsView}
      />
      <CompanyRoute
        exact
        path={routes.SUBSCRIPTION_TRANSACTION_RESPONSE()}
        component={SubscriptionTransactionResponseView}
      />
      {/* Error views */}
      <CompanyRoute component={RouteNotFound} />
    </Switch>
  )
}
export default SwitchRoutes
