import { useUser } from 'components/App/UserContext/useUser'

const useSubscription = () => {
  const { subscription } = useUser()

  const showNewSubscription = subscription?.plan_category === 'per_worker_plan'

  const additionalWorkersInfo = subscription?.additional_workers_info
  const paidWorkers = subscription?.paid_workers

  return {
    showNewSubscription,
    additionalWorkersInfo,
    paidWorkers,
    subscription,
  }
}

export default useSubscription
