import { useCallback, useRef } from 'react'
import { useFormikContext } from 'formik'
import useLocalStorage from '@rehooks/local-storage'

import { makeStyles } from '@material-ui/core/styles'

import LoadingBackdrop from 'components/UI/Loading/LoadingBackdrop'
import FormField from 'components/UI/Formik/FormField/Index'
import Button from 'components/UI/Button/Button'

import { isObjectEmpty, wait } from 'utils/general'
import useAutoScroll from 'utils/hooks/useAutoScroll'

import useOnboardingMutation from '../useOnboardingMutation'
import BadgeRole from './BadgeRole'
import { detailsRole, getValuesToSend } from './helpers'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '-webkit-fill-available',
    marginTop: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 676,
    alignSelf: 'center',
    paddingBottom: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(200px, 326px))',
      marginTop: theme.spacing(10),
    },
  },
  inputContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifySelf: 'center',
  },
}))

const OnboardingRole = ({ handleNextStep }) => {
  const classes = useStyles()
  const [, setRole] = useLocalStorage('role')
  const otherRoleRef = useRef(false)
  const autoScroll = useAutoScroll()

  const { values: initialValues, errors, setFieldValue } = useFormikContext()

  const { updateRoleStep, onSuccessMutation, loading } = useOnboardingMutation(
    handleNextStep
  )

  const handleSaveRoleStep = useCallback(
    (value) => {
      if (value === 'other') {
        return
      }
      const valuesToSend = getValuesToSend(value)

      updateRoleStep(valuesToSend, onSuccessMutation)
    },
    [onSuccessMutation, updateRoleStep]
  )

  // Event for send the role when selected other role and wait for the other information
  const handleSaveOtherRoleInformation = () => {
    const valuesToSend = getValuesToSend(
      initialValues.user_role,
      initialValues.user_role_other_info
    )

    updateRoleStep(valuesToSend, onSuccessMutation)
  }

  const handleClick = async (id) => {
    setFieldValue('user_role', id)
    handleSaveRoleStep(id)
    if (id === 'other') {
      otherRoleRef.current = true
      // Auto scroll to write the other role wait while render the component
      await wait(200)
      autoScroll(document.querySelector('input[name=user_role_other_info]'))
    } else {
      otherRoleRef.current = false
    }

    if (id === 'accountant' || id === 'outsourcer') setRole('accountant')
  }

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <LoadingBackdrop open={loading} />
        {detailsRole.map(({ id, role, detail, Image, dataCy }) => {
          const isSelectedRole = id === initialValues.user_role

          return (
            <BadgeRole
              key={id}
              id={id}
              role={role}
              detail={detail}
              SvgImage={Image}
              isSelectedRole={isSelectedRole}
              handleSelectRole={handleClick}
              dataCy={dataCy}
            />
          )
        })}
      </div>
      {otherRoleRef.current || initialValues.user_role === 'other' ? (
        <div className={classes.inputContainer}>
          <FormField
            name="user_role_other_info"
            label="¿Cuál es tu rol?"
            placeholder="Escribe aquí tu rol"
            data-cy="user_role_other_info"
          />
          <Button
            onClick={handleSaveOtherRoleInformation}
            className={classes.button}
            disabled={!isObjectEmpty(errors)}
          >
            Continuar
          </Button>
        </div>
      ) : null}
    </section>
  )
}

export default OnboardingRole
