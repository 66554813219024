import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'

import { costsInPercentagesTypes } from '../helpers'

const DashboardCostsInPercentagesItem = ({ costsInPercentages, type }) => {
  const theme = useTheme()

  const { yellow, orange, blue, darkBlue, grey } = theme.palette.complementary

  const dataToGenerateChart = costsInPercentages[type]

  const backgroundColor = [yellow, orange, blue, darkBlue, grey]
  const chartData = useMemo(() => {
    const data = []
    const labels = []

    dataToGenerateChart.forEach((element) => {
      data.push(element.percentage || 0) // 0 if null
      labels.push(element.name)
    })

    return {
      title: costsInPercentagesTypes[type],
      labels,
      datasets: [
        {
          backgroundColor,
          data,
        },
      ],
    }
  }, [backgroundColor, dataToGenerateChart, type])

  return (
    <Box p={2} minWidth={0} component={Paper}>
      <Doughnut
        data={chartData}
        options={{
          title: {
            display: true,
            text: costsInPercentagesTypes[type],
            fontFamily: theme.typography.fontFamily,
            fontColor: theme.palette.grey[900],
            fontSize: 16,
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const idx = tooltipItem.index
                const label = `${data.labels[idx]}: ${data.datasets[0].data[idx]}%`

                return label
              },
            },
          },
          maintainAspectRatio: false,
        }}
        legend={{
          position: 'top',
          fontFamily: theme.typography.fontFamily,
          labels: {
            generateLabels: ({ data: { datasets, labels } }) => {
              return labels.map((label, idx) => {
                return {
                  text: `${label} ${datasets[0].data[idx]}%`,
                  fillStyle: datasets[0].backgroundColor[idx],
                }
              })
            },
          },
        }}
      />
    </Box>
  )
}

export default DashboardCostsInPercentagesItem
