import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core/styles'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CustomField from 'components/UI/Formik/CustomField'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getDirtyValues } from 'utils/form'
import useLocationService from 'utils/hooks/company/locationService'
import useInstitutionsService from 'utils/hooks/institutionsService'

import { validationSchema } from './validationSchema'

const useStyles = makeStyles({
  paper: {
    width: 650,
  },
})

const WorkerFormFieldsPersonalLocationDialog = ({
  open,
  handleClose,
  initialLocation,
  submitCallback,
}) => {
  const modalClasses = useStyles()
  const queryClient = useQueryClient()

  const { locationMutation } = useLocationService({
    queryOptions: { enabled: false },
  })
  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'compensation_fund'],
      category: 'compensation_fund',
    },
  })

  const mutateLocation = (values) => {
    const dirtyValues = getDirtyValues({}, values)

    locationMutation.mutate(
      {
        mutationMethod: 'POST',
        data: dirtyValues,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries('companyLocation')

          submitCallback(data.data)
          handleClose()
        },
      }
    )
  }

  const initialLocalLocation = {
    ...initialLocation,
    department: null,
    compensation_fund: null,
    city: null,
  }

  return (
    <Formik
      onSubmit={mutateLocation}
      initialValues={initialLocalLocation}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { values, dirty, handleSubmit } = formProps

        return (
          <Modal
            header="Agregar nueva sede"
            open={open}
            onOk={handleSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={locationMutation.isLoading}
            dialogProps={{
              maxWidth: false,
              classes: modalClasses,
              disableBackdropClick: dirty || locationMutation.isLoading,
              disableEscapeKeyDown: locationMutation.isLoading,
            }}
          >
            <Form>
              <FormField
                name="name"
                label="Nombre"
                placeholder="Nombre de la sede"
                autoFocus
              />
              <DepartmentsList
                name="department"
                label="Departamento"
                placeholder="Seleccione un departamento..."
              />
              <CitiesList
                name="city"
                label="Ciudad"
                placeholder={
                  values.department
                    ? 'Seleccione una ciudad...'
                    : 'Seleccione primero un departamento.'
                }
                department={values.department}
              />
              <CustomField
                name="compensation_fund"
                label="C. de Compensación"
                component="autocomplete"
                placeholder="Selecciona un caja de compensación..."
                options={institutionsQuery?.data || []}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default WorkerFormFieldsPersonalLocationDialog
