import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import Typography from '@material-ui/core/Typography'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import periodService from 'services/payroll/periodService'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  initial_day: yup.string().required(),
  end_day: yup.string().required(),
})

const PayrollConsolidatedReportModal = ({ visible, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState({})
  const [initialDayOptions, setInitialDayOptions] = useState([])
  const [endDayOptions, setEndDayOptions] = useState([])
  const { handleError } = useErrorHandler()
  const { enqueueDownload } = useDownloadManager()

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      try {
        const data = await periodService.getPeriodOptions()

        setInitialDayOptions(data.initial_dates)
        setEndDayOptions(data.end_dates)

        setOptions(data)
      } catch (error) {
        handleError(error)
      }

      setIsLoading(false)
    }

    fetchOptions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values) => {
    const serviceCall = () =>
      periodService.getConsolidatePayrollSummaryFile(values)

    handleClose()
    enqueueDownload({
      name: 'reporte consolidado del periodo',
      fileCode: 'consolidated_report',
      serviceCall,
    })
  }

  const handleOptionsChange = (values) => {
    if (values.initial_day) {
      // Todos los valores que son Mayores que initial_day

      const newEndDayOptions = options.end_dates.filter(
        (date) => date > values.initial_day.toString()
      )

      setEndDayOptions(newEndDayOptions)
    }

    if (values.end_day) {
      // Todos los valores que son Menores que end_day
      const newInitialDayOptions = options.initial_dates.filter(
        (date) => date < values.end_day.toString()
      )

      setInitialDayOptions(newInitialDayOptions)
    }
  }

  const formatOptions = (optionsDate) => {
    return optionsDate.map((date) => {
      return {
        value: date,
        label: formatDisplayDateString(date),
      }
    })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ initial_day: '', end_day: '' }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { values, handleSubmit: handle, isSubmitting } = formProps

        // TODO: reimplement this, this broke rules of hooks!!!
        /* eslint-disable react-hooks/rules-of-hooks */
        useEffect(() => {
          handleOptionsChange(values)
        }, [values])
        /* eslint-enable react-hooks/rules-of-hooks */
        return (
          <Modal
            okText="Generar archivo"
            header="Reporte consolidado por períodos"
            loadingText="Generando archivo..."
            open={visible}
            onOk={handle}
            onCancel={handleClose}
            isLoading={isSubmitting || isLoading}
          >
            <Typography gutterBottom>
              Con esta opción puedes descargar resúmenes de nómina de varios
              períodos juntos o de forma individual.
            </Typography>
            <Typography gutterBottom gutterTop>
              <b>Recuerda:</b> los contratistas y empleados liquidados aparecen
              en hojas diferentes dentro del reporte.
            </Typography>
            <Form style={{ display: 'flex' }}>
              <FormField
                name="initial_day"
                label="Día inicial del reporte"
                placeholder="Día inicial del reporte"
                variant="select"
                options={formatOptions(initialDayOptions)}
                style={{ marginRight: 5 }}
              />
              <FormField
                name="end_day"
                label="Día final del reporte"
                placeholder="Día final del reporte"
                variant="select"
                options={formatOptions(endDayOptions)}
                style={{ marginLeft: 5 }}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PayrollConsolidatedReportModal
