import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useContext, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import CardReview from 'components/UI/MaterialUI/Cards/CardReview'

import useNoveltiesImportService from 'utils/hooks/payroll/noveltiesImport'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import * as routes from 'config/routes'

import NoveltiesImportModal from '../Modals/NoveltiesImportModal'
import { PeriodContext } from '../Payroll'

const Novelties = () => {
  const {
    period: { editable },
  } = useContext(PeriodContext)

  return editable ? (
    <CardReview>
      <Typography variant="h5">Novedades</Typography>
      <NoveltiesUploadMenu />
      <SocialBenefitsMenu />
      <Decree376Menu />
    </CardReview>
  ) : null
}

export default Novelties

export const NoveltiesUploadMenu = () => {
  const { period } = useContext(PeriodContext)
  const [isNoveltiesImportModalOpen, setIsNoveltiesImportModalOpen] = useState(
    false
  )
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'noveltiesMenu',
  })
  const { enqueueDownload } = useDownloadManager()
  const featureState = usePremiumFeature('default')
  const { noveltiesImportMutation } = useNoveltiesImportService()

  const handleDownloadFile = () => {
    popupState.close()

    const serviceCall = () =>
      noveltiesImportMutation.mutateAsync({
        mutationMethod: 'GET_NOVELTIES_FILE',
        periodId: period.id,
      })

    const callback = () =>
      trackEvent(integrationEvent.DOWNLOAD_TEMPLATE, 'novelties')

    enqueueDownload({
      name: 'Formato de carga de novedades',
      fileCode: 'novelties_upload_file',
      serviceCall,
      callback,
    })
  }

  const handleUploadFile = () => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    setIsNoveltiesImportModalOpen(true)

    popupState.close()
  }

  return period.editable ? (
    <>
      <NoveltiesImportModal
        open={isNoveltiesImportModalOpen}
        handleClose={() => setIsNoveltiesImportModalOpen(false)}
      />
      <Button
        variant="text"
        startIcon={<LocalAtmOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
      >
        Cargar novedades
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleDownloadFile}>
          <ListItemIcon>
            <GetAppOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Descargar formato</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleUploadFile}>
          <ListItemIcon>
            <PublishOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Cargar formato</ListItemText>
          <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
        </MenuItem>
      </Menu>
    </>
  ) : null
}

export const SocialBenefitsMenu = () => {
  const {
    period: { id: periodId },
    options: { social_benefits: socialBenefits },
    payPreviousPayroll,
  } = useContext(PeriodContext)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialBenefitsMenu',
  })

  return (
    <>
      <Button
        variant="text"
        startIcon={<SupervisedUserCircleOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
      >
        Prestaciones sociales
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {socialBenefits.map((sb) => {
          return (
            <MenuItem
              key={sb.social_benefits_period_id}
              component={RouterLink}
              to={{
                pathname: payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      sb.social_benefits_period_id
                    )
                  : routes.PERIOD_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      sb.social_benefits_period_id
                    ),
                state: { payPreviousPayroll },
              }}
            >
              {sb.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export const Decree376Menu = () => {
  const {
    period: { id: periodId },
    options: { decree376_periods: decree376Periods },
    payPreviousPayroll,
  } = useContext(PeriodContext)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'Decree376Menu',
  })

  return decree376Periods && decree376Periods.length > 0 ? (
    <>
      <Button
        variant="text"
        startIcon={<FileCopyOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
      >
        Aportes decreto 376
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {decree376Periods.map((item) => {
          return (
            <MenuItem
              key={item.id}
              component={RouterLink}
              to={{
                pathname: payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_DECREE_376_INDEX(
                      periodId,
                      item.id
                    )
                  : routes.PERIOD_DECREE_376_INDEX(periodId, item.id),
                state: { payPreviousPayroll },
              }}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  ) : null
}
