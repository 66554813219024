const getColumnsDataIndex = () => {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => row.original.worker_name,
      customWidth: '250px',
    },
    {
      Header: 'Error',
      accessor: 'error',
      Cell: ({ row }) => row.original.error,
    },
  ]
}

export default getColumnsDataIndex
