import { Route, Redirect } from 'react-router-dom'
import { useUser } from 'components/App/UserContext/useUser'

import {
  WORKER_PROFILE_BENEFITS,
  WORKER_BENEFITS_CREDIT_FORM,
  WORKER_BENEFITS_CREDIT_SIMULATOR,
} from 'config/routes'

/**
 * View related to a worker so the user needs to have worker role to access this routes
 */
export default ({ component: Component, render, ...rest }) => {
  const { isWorker, workerWithBenefits } = useUser()

  const notAllowedUser =
    !isWorker ||
    ([
      WORKER_PROFILE_BENEFITS(),
      WORKER_BENEFITS_CREDIT_FORM(),
      WORKER_BENEFITS_CREDIT_SIMULATOR(),
    ].includes(rest.path) &&
      !workerWithBenefits)

  const getPath = (props) => {
    if (notAllowedUser) {
      return <Redirect to="/" />
    }
    return <Component {...props} />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return getPath(props)
      }}
    />
  )
}
