import Link from 'components/UI/MaterialUI/Link'
import { contractCategories } from 'utils/worker'
import { formatCurrency, formatIdNumber } from 'utils/format'
import * as routes from 'config/routes'

const columnsData2 = [
  {
    Header: 'Nombre',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link to={routes.WORKER_SHOW(row.original.id)}>
        {`${row.original.name}\u00A0${row.original.last_name}`}
      </Link>
    ),
  },
  {
    Header: 'N. Identificación',
    accessor: 'id_number',
    Cell: ({ row }) => formatIdNumber(row.original.id_number),
    customWidth: 175,
    sorting: false,
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Cargo',
    accessor: 'position',
  },
  {
    Header: 'Sede',
    accessor: 'location',
  },
  {
    Header: 'Contrato',
    accessor: 'contract_category',
    Cell: ({ row }) =>
      contractCategories[row.original.contract_category] || '-',
  },
  {
    Header: 'Estado',
    accessor: 'active',
    Cell: ({ row }) => {
      if (row.original.terminated) return 'Liquidado'
      if (row.original.active) return 'Activo'
      return 'Inactivo'
    },
    type: 'boolean',
    width: 80,
  },
  {
    Header: 'Salario base',
    accessor: 'base_salary',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
    alignHeader: 'right',
    alignCell: 'right',
  },
]

export default columnsData2
