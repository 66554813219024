import { useState } from 'react'
import LoadingModal from './LoadingModal'
import LoadingModalContext from './LoadingModalContext'

const LoadingModalProvider = ({ children }) => {
  const [isloadingModalOpen, setIsLoadingModalOpen] = useState(false)

  const showLoadingModal = () => setIsLoadingModalOpen(true)
  const hideLoadingModal = () => setIsLoadingModalOpen(false)

  return (
    <>
      <LoadingModalContext.Provider
        value={{
          showLoadingModal,
          hideLoadingModal,
        }}
      >
        {children}
      </LoadingModalContext.Provider>
      {isloadingModalOpen && <LoadingModal />}
    </>
  )
}

export default LoadingModalProvider
