import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.grey[500],
  },
}))

const ConfirmAffiliation = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="body1" className={classes.message}>
        Estás a un paso de terminar tu solicitud de afiliación a seguridad
        social. Una vez realicemos todo el proceso, te estaremos notificando vía
        correo electrónico.
        <br />
        <br />
        También podrás validar el estado de cada afiliación a seguridad social
        en el módulo de Personas.
      </Typography>
    </div>
  )
}

export default ConfirmAffiliation
