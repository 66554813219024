import { useContext } from 'react'
import { useQueryClient } from 'react-query'

import { getCompanyId } from 'utils/company'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import EmptyState from './EmptyState'
import FileCard from './FileCard'
import FolderCard from './FolderCard'
import { FoldersContainerContext } from './Index'

const Content = ({ filteredData, isAffiliationFolder }) => {
  const queryClient = useQueryClient()
  const { worker } = useWorker({ useCache: true })
  const companyId = getCompanyId()
  const workerId = worker.id
  const foldersCache = queryClient.getQueryData([
    'getAllFolders',
    companyId,
    workerId,
  ])
  const { showAsGrid } = useContext(FoldersContainerContext)
  const { openedFolder } = useContext(DocumentsContext)
  const isEmpty =
    foldersCache?.data?.folders?.find(
      (folder) => folder.id === openedFolder?.id
    )?.total_files === 0

  if (openedFolder && isEmpty) {
    return <EmptyState />
  }

  return filteredData.map((card) => {
    if (openedFolder)
      return (
        <FileCard
          key={card.id}
          showAsCard={showAsGrid}
          card={card}
          isAffiliationFolder={isAffiliationFolder}
        />
      )

    return <FolderCard key={card.id} showAsCard={showAsGrid} card={card} />
  })
}

export default Content
