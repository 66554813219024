import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'

const useStyles = makeStyles((theme) => ({
  justifiSpaceBetween: {
    [theme.breakpoints.up(1215)]: {
      justifyContent: 'space-between',
    },
  },
}))

const CheckboxGroupField = ({
  value,
  row = false,
  options = [],
  setValue,
  justifiSpaceBetween = false,
}) => {
  const classes = useStyles()
  return (
    <FormGroup
      row={row}
      className={clsx({
        [classes.justifiSpaceBetween]: justifiSpaceBetween,
      })}
    >
      {options.map((option) => {
        return (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={
                  value?.find?.(
                    (currentValue) => currentValue === option.value
                  ) !== undefined
                }
                onChange={({ target: { name, checked } }) => {
                  const valueSet = new Set(value)

                  if (checked) {
                    valueSet.add(name)
                  } else {
                    valueSet.delete(name)
                  }

                  setValue([...valueSet])
                }}
                name={option.value}
              />
            }
            label={option.label}
          />
        )
      })}
    </FormGroup>
  )
}

export default CheckboxGroupField
