import { makeStyles } from '@material-ui/core'

import PayrollReviewActions from './Actions'

import SocialBenefitsAlert from './SocialBenefitsAlert'

const reviewStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    '& > *': {
      marginLeft: theme.spacing(),
    },
  },
}))

const PayrollReview = () => {
  const classes = reviewStyles()

  return (
    <div className={classes.container}>
      <PayrollReviewActions />
      <SocialBenefitsAlert />
    </div>
  )
}

export default PayrollReview
