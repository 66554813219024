import { Button, Link, Paper, Typography, makeStyles } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    width: '100%',
    maxWidth: 416,
    padding: theme.spacing(6.5, 4.5),
    borderRadius: 22,
    justifyItems: 'start',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4.5, 3),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
}))

const SummaryCard = ({ title, description, link }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.container} variant="outlined">
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      <Button
        component={Link}
        href={link}
        target="_blank"
        endIcon={<ArrowForwardIosIcon fontSize="small" />}
        variant="text"
        underline="none"
      >
        Leer más
      </Button>
    </Paper>
  )
}

export default SummaryCard
