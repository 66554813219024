import http, { getApiURL } from '../httpService'

export const getSubscription = ({
  extraWorkersNumber = null,
  crmEvent = null,
}) => {
  const url = getApiURL({
    pathname: '/subscription',
    searchParams: {
      extra_workers_number: extraWorkersNumber,
      crm_event: crmEvent,
    },
  })

  return http.getV2(url)
}

export const getPlans = () => {
  const url = getApiURL({ pathname: '/plans' })

  return http.getV2(url)
}

export const getAllPayments = ({ page } = {}) => {
  const url = getApiURL({ pathname: '/payments', searchParams: { page } })

  return http.getV2(url)
}

export const sendInvoice = (paymentId) => {
  const url = getApiURL({
    pathname: `/payments/${paymentId}/send_invoice`,
  })

  return http.postV2(url)
}

export const updateAutomaticDebit = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/automatic_debit_information' })

  const data = { automatic_debit_information: paymentMethodData }

  return http.patchV2(url, data)
}

export const addCreditCard = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/payments/credit_card' })

  const data = { payment_info: paymentMethodData }

  return http.putV2(url, data)
}

export const addPSEPayment = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/payments/pse' })

  const data = { payment_info: paymentMethodData }

  return http.postV2(url, data)
}

export const getPaymentResponsePSE = () => {
  const url = getApiURL({ pathname: '/payments/pse' })

  return http.getV2(url)
}

export const getExtraWorkersPlan = (planId, extraWorkers) => {
  const url = getApiURL({
    pathname: `/plans/${planId}`,
    searchParams: {
      workers_number: extraWorkers,
    },
  })

  return http.getV2(url)
}

export default {
  getSubscription,
  getAllPayments,
  sendInvoice,
  getPaymentResponsePSE,
  getExtraWorkersPlan,
  getPlans,
  updateAutomaticDebit,
  addCreditCard,
  addPSEPayment,
}
