import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import Chip from '@material-ui/core/Chip'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { contractCategories } from 'utils/worker'

import { ORGANIZER_WORKER_SHOW } from 'config/routes'

yup.setLocale(yupLocaleES)

export const getTabsData = (totals, chipClassName) => [
  {
    key: 'waiting_response',
    label: (
      <>
        Pendientes
        <Chip
          label={totals.waiting_response_total || 0}
          className={chipClassName}
        />
      </>
    ),
  },
  {
    key: 'approved',
    label: (
      <>
        Aprobadas
        <Chip label={totals.approved_total || 0} className={chipClassName} />
      </>
    ),
  },
  {
    key: 'rejected',
    label: (
      <>
        Rechazadas
        <Chip label={totals.rejected_total || 0} className={chipClassName} />
      </>
    ),
  },
]

export const getColumns = (handleOpenAffiliation) => {
  const columns = [
    {
      accessor: 'status_change_date',
      Header: 'Fecha',
      Cell: ({ row }) =>
        formatDisplayDateString(row.original.status_change_date),
    },
    {
      accessor: 'worker_name',
      Header: 'Persona',
      Cell: ({ row }) => (
        <Link
          to={{
            pathname: ORGANIZER_WORKER_SHOW(row.original.id),
            state: {
              fromAffiliation: true,
              workerId: row.original.worker_id,
            },
          }}
          onClick={() => handleOpenAffiliation(row.original.company_id)}
        >
          {row.original.worker_name}
        </Link>
      ),
    },
    {
      accessor: 'company_name',
      Header: 'Empresa',
    },
    {
      accessor: 'worker_location',
      Header: 'Sede',
    },
    {
      accessor: 'contract_category',
      Header: 'Contrato',
      Cell: ({ row }) => contractCategories[row.original.contract_category],
    },
  ]

  return columns
}

export const getActions = (handleOpenAffiliation) => {
  return [
    (rowData) => ({
      id: 'view_profile',
      tooltip: 'Ver perfil',
      icon: VisibilityOutlinedIcon,
      onClick: () => handleOpenAffiliation(rowData.company_id),
      buttonProps: {
        component: RouterLink,
        to: {
          pathname: ORGANIZER_WORKER_SHOW(rowData.id),
          state: {
            fromAffiliation: true,
            workerId: rowData.worker_id,
          },
        },
      },
    }),
  ]
}

export const getValidationSchema = (
  isRejectAction,
  documentType,
  contractCategory
) => {
  if (isRejectAction) {
    return yup.object({
      comment: yup.string().nullable().required(),
    })
  }

  let schema = yup.object({
    approved_severance_document: yup
      .mixed()
      .test('approved_severance_document', null, (value) => {
        if (!(typeof value === 'string')) {
          return value instanceof File
        }

        return true
      })
      .optional(),
  })

  if (
    ['employee', 'pensioner', 'part_time_contract'].includes(contractCategory)
  ) {
    schema = schema.concat(
      yup.object({
        approved_compensation_fund_document: yup
          .mixed()
          .test('approved_compensation_fund_document', null, (value) => {
            if (!(typeof value === 'string')) {
              return value instanceof File
            }

            return true
          })
          .required(),
      })
    )
  }

  if (
    !['contractor', 'student_decree_055', 'part_time_contract'].includes(
      contractCategory
    )
  ) {
    schema = schema.concat(
      yup.object({
        approved_health_document: yup
          .mixed()
          .test('approved_health_document', null, (value) => {
            if (!(typeof value === 'string')) {
              return value instanceof File
            }

            return true
          })
          .required(),
      })
    )
  }

  if (
    !['ce_no_pension', 'pe_no_pension'].includes(documentType) &&
    ['employee', 'student_law_789', 'part_time_contract'].includes(
      contractCategory
    )
  ) {
    schema = schema.concat(
      yup.object({
        approved_pension_document: yup
          .mixed()
          .test('approved_pension_document', null, (value) => {
            if (!(typeof value === 'string')) {
              return value instanceof File
            }

            return true
          })
          .required(),
      })
    )
  }

  if (!['contractor', 'schooling_trainee'].includes(contractCategory)) {
    schema = schema.concat(
      yup.object({
        approved_risk_document: yup
          .mixed()
          .test('approved_risk_document', null, (value) => {
            if (!(typeof value === 'string')) {
              return value instanceof File
            }

            return true
          })
          .required(),
      })
    )
  }

  return schema
}
