import { OutlinedInput } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const DateField = ({
  id,
  options,
  value,
  onChange,
  className,
  placeholder,
  ...restProps
}) => {
  const renderInput = ({ InputProps, InputLabelProps, ...params }) => {
    return (
      <OutlinedInput
        {...params}
        {...InputProps}
        {...InputLabelProps}
        placeholder={placeholder}
      />
    )
  }

  const getOptionLabel = ({ name: optionName }) => {
    if (optionName && optionName !== '') return optionName

    return ''
  }

  return (
    <Autocomplete
      id={id}
      className={className}
      options={options}
      value={value}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      onChange={(__, selectedValue) => onChange(selectedValue)}
      {...restProps}
    />
  )
}

export default DateField
