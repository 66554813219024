import { useState, useEffect } from 'react'

import Modal from 'components/UI/Modal/Modal'

import { getMonthAndYear } from 'utils/dateTime'
import useNotifications from 'utils/hooks/useNotifications'

import logoDian from 'assets/images/logos/logo_dian.svg'

import useSendElectronicPayrolls from '../useSendElectronicPayrolls'
import { getStepContent, payrollsToSend } from './helpers'

const SendElectronicIndividual = ({
  electronicPayrolls,
  handleClose,
  periodId,
  endDay,
  resetForm,
  setIsLoadingSend,
}) => {
  const [step, setStep] = useState(1)

  const { showWarningMessage } = useNotifications()

  const electronicPayrollMonth = getMonthAndYear(endDay)

  const {
    sendElectronicPayroll,
    resetStatus,
    statusStepOne,
    statusStepTwo,
  } = useSendElectronicPayrolls()

  const statusCompleted =
    statusStepOne === 'completed' && statusStepTwo === 'completed'
      ? 'completed'
      : 'progress'

  let statusProcess =
    statusStepOne === 'error' || statusStepTwo === 'error'
      ? 'error'
      : statusCompleted

  // Get the content since the position step and put the data into object
  const dataSteps = {
    setStep,
    periodId,
    handleClose,
    logoDian,
    statusProcess,
    statusStepOne,
    statusStepTwo,
  }

  const stepDetails = getStepContent(dataSteps)

  const handleSubmit = () => {
    setIsLoadingSend(true)
    const {
      electronic_payroll_ids: electronicPayrollIds,
      electronicPayrollToEmit,
    } = payrollsToSend(electronicPayrolls)

    resetStatus()

    if (electronicPayrollToEmit) {
      sendElectronicPayroll(
        electronicPayrollIds,
        periodId,
        electronicPayrollMonth
      )
      setIsLoadingSend(false)
      if (statusProcess === 'completed' && typeof resetForm === 'function') {
        resetForm()
      }
    } else {
      statusProcess = 'error'
      handleClose()
      setIsLoadingSend(false)
      showWarningMessage(
        'Debes seleccionar por lo menos una persona para hacer la emisión de nómina electrónica'
      )
    }
  }

  useEffect(() => {
    handleSubmit()
    // Ignore because I need to send when modal is open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOkStep = stepDetails[step].okText
  return (
    <Modal
      open
      onOk={statusProcess === 'error' ? handleSubmit : handleClose}
      okText={statusProcess === 'error' ? 'Intentar de nuevo' : onOkStep}
      onCloseModal={handleClose}
      hideCancelButton
      isLoading={step === 1 && statusProcess === 'progress'}
      alignButtons={step === 0 ? 'center' : 'right'}
      dialogProps={{ maxWidth: false }}
    >
      {stepDetails[step].content}
    </Modal>
  )
}

export default SendElectronicIndividual
