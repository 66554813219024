import { isArusDist } from 'utils/auth'
import { isChristmasTime } from 'utils/dateTime'

import arusLogo from 'assets/images/logos/arus/logo_arus_dark.svg'
import logoNoiminapp from 'assets/images/logos/logo_degradado.svg'
import logoNominappChristmas from 'assets/images/logos/logo_degraded_christmas.gif'

const AuthLogo = (props) => {
  const imgProps = {
    alt: 'Nominapp Logo',
    src: logoNoiminapp,
  }

  if (isArusDist()) {
    imgProps.alt = 'Arus Logo'
    imgProps.src = arusLogo
  } else if (isChristmasTime()) {
    imgProps.alt = 'Nominapp Logo Navidad'
    imgProps.src = logoNominappChristmas
  }

  return (
    <img {...imgProps} {...props} /> // eslint-disable-line jsx-a11y/alt-text
  )
}

export default AuthLogo
