import Assignment from '@material-ui/icons/Assignment'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Payment from '@material-ui/icons/Payment'

import OrganizationEmail from 'components/UI/OrganizationEmail'

import EugenioDeclined from 'assets/images/avatars/eugenio/eugenio_error.png'
import EugenioApproved from 'assets/images/avatars/eugenio/eugenio_success.svg'
import EugenioPending from 'assets/images/views/common/genio_think_nominapp.svg'

import { SUBSCRIPTION } from 'config/routes'

export const getContentResponse = ({
  value,
  cardBrand,
  cardLastNumber,
  subscriptionType,
  activeUntil,
  state,
}) => {
  let contentDialogs = {}

  const SUBSCRIPTION_DIALOG = {
    APPROVED: {
      icon: <Payment color="primary" />,
      subtitle: 'Tu suscripción',
      body: (
        <b>
          Debitaremos {value}{' '}
          {cardBrand && cardLastNumber
            ? `de tu tarjeta ${cardBrand} ****${cardLastNumber}`
            : null}{' '}
          cada {subscriptionType}.
        </b>
      ),
    },
    DECLINED: {
      icon: <Payment color="primary" />,
      subtitle: 'Tu suscripción',
      body: (
        <b>
          Fallo el debito de {value} de tu tarjeta {cardBrand} ****
          {cardLastNumber}.
        </b>
      ),
    },
  }

  const NEXT_PAYMENT_DATE = {
    APPROVED: {
      icon: <CalendarToday color="primary" />,
      subtitle: 'Próximo cobro',
      body: (
        <>
          <b>{activeUntil}</b> (el cobro aparecerá en tu extracto como grupo
          Quincena S.A.S).
        </>
      ),
    },
  }

  const ELECTRONIC_RECEIPT = {
    APPROVED: {
      icon: <Assignment color="primary" />,
      subtitle: 'Confirma la información de tu factura',
      body: (
        <b>
          Envia el RUT de tu empresa a <OrganizationEmail variant="billing" />{' '}
          junto al nombre y teléfono del encargado para recibir tu factura.
        </b>
      ),
    },
    DECLINED: {
      icon: <Assignment color="primary" />,
      subtitle: 'Confirma el estado de tu pago',
      body: (
        <b>
          Si el error persiste, escribenos por el chat o a <OrganizationEmail />
          .
        </b>
      ),
    },
  }

  if (['APPROVED', 'PENDING'].includes(state)) {
    contentDialogs = {
      subscription: SUBSCRIPTION_DIALOG.APPROVED,
      paymentDate: NEXT_PAYMENT_DATE.APPROVED,
      electronicReceipt: ELECTRONIC_RECEIPT.APPROVED,
    }
  } else {
    contentDialogs = {
      subscription: SUBSCRIPTION_DIALOG.DECLINED || null,
      electronicReceipt: ELECTRONIC_RECEIPT.DECLINED || null,
    }
  }

  return contentDialogs
}

export const getContentSummary = ({ state, name, plan }) => {
  let contentSummary = {}

  const SUMMARY_TITLE = {
    APPROVED: (
      <>
        ¡GRACIAS! {name},
        <br /> recibimos tu pago
      </>
    ),
    PENDING: (
      <>
        Estamos procesando tu pago {name}, <br />
        te enviaremos un email cuando se complete.
      </>
    ),
    DECLINED: (
      <>
        Lo sentimos {name},
        <br /> algo ha ocurrido con tu banco.
      </>
    ),
  }

  const SUMMARY_SUBTITLE = {
    APPROVED: (
      <>
        Haz activado tu plan <b>{plan}</b>, revisa tu correo para consultar la
        información de la transacción ✉️.
      </>
    ),
    DECLINED: (
      <>
        Haz intentado activar tu plan <b>{plan}</b>, revisa tu correo para
        consultar la información de la transacción ✉️.
      </>
    ),
  }

  const SUMMARY_IMAGE = {
    APPROVED: EugenioApproved,
    PENDING: EugenioPending,
    DECLINED: EugenioDeclined,
  }

  contentSummary = {
    heading: SUMMARY_TITLE[state] || SUMMARY_TITLE.DECLINED,
    description: ['APPROVED', 'PENDING'].includes(state)
      ? SUMMARY_SUBTITLE.APPROVED
      : SUMMARY_SUBTITLE.DECLINED,
    image: SUMMARY_IMAGE[state] || SUMMARY_IMAGE.DECLINED,
  }

  return contentSummary
}

export const getActionButtons = (state) => {
  let buttons = {}

  if (
    state?.payment_status === 'DECLINED' ||
    state?.payment_status === undefined
  ) {
    buttons = {
      ...buttons,
      primary: {
        action: { pathname: SUBSCRIPTION(), state: { plan: state?.plan_id } },
        text: 'Intentar de nuevo',
      },
    }
  } else {
    buttons = {
      ...buttons,
      primary: {
        href: 'https://curso-nominapp1.teachable.com/',
        target: '_blank',
        text: 'Accede gratis al curso Nominapp',
      },
    }
  }

  return buttons
}
