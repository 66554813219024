import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import IntegrationModal from 'components/Settings/Integration/Modal/IntegrationModal'
import useIntegrationModal from 'components/Settings/Integration/Modal/useIntegrationModal'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import { useWithoutPersonsAction } from '../helpers'
import Countdown from './Countdown'
import PendingCard from './PendingCard'
import getPendingsData from './helpers'

const useStyles = makeStyles((theme) => ({
  countdownContainer: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  pendingsCards: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.up(1520)]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
}))

const DashboardPendings = () => {
  const classes = useStyles()
  const { subscription } = useUser()
  const hasPersons = subscription?.payrolls_size > 0
  const withoutPersonsAction = useWithoutPersonsAction()
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'pendings', getCompanyId()]
  const { data: pendingsCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'pendings',
    },
    queryOptions: {
      enabled: !isDataCached(pendingsCache),
    },
  })
  const { pendings } = pendingsCache || {}

  const {
    integrationModalState,
    openIntegrationModal,
    closeIntegrationModal,
  } = useIntegrationModal()

  const history = useHistory()

  if (dashboardQuery?.isLoading) return <LoadingBox />

  return pendings &&
    !(
      pendings.accountant_added &&
      pendings.social_security_integration &&
      pendings.next_payroll?.payroll_paid
    ) ? (
    <>
      <Box marginBottom={4} className={classes.container}>
        <Box className={classes.countdownContainer}>
          <Countdown />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            className={classes.title}
            gutterBottom
          >
            Pendientes
          </Typography>
          <div className={classes.pendingsCards}>
            {getPendingsData(
              pendings,
              history,
              openIntegrationModal,
              hasPersons,
              withoutPersonsAction
            ).map((pending) => {
              return (
                !pending.hidden && (
                  <PendingCard pending={pending} key={pending.id} />
                )
              )
            })}
          </div>
        </Box>
      </Box>
      {integrationModalState.open && (
        <IntegrationModal
          state={integrationModalState}
          handleClose={closeIntegrationModal}
        />
      )}
    </>
  ) : null
}

export default DashboardPendings
