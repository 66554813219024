import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import Typography from '@material-ui/core/Typography'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import PayslipModal from 'components/Period/Payroll/Modals/PayslipModal'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import { getWorkerId } from 'utils/worker'

import workerProfileService from 'services/worker/workerProfileService'
import workerService from 'services/worker/workerService'

import * as routes from 'config/routes'

import getColumnsData from './columnsData'

const PaymentHistory = ({ match }) => {
  const { isWorker } = useUser()
  const { worker } = useWorker()

  const [isLoading, setIsLoading] = useState(false)
  const downloadFile = useEnqueueDownload()

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal()

  const [service, workerId] = isWorker
    ? [workerProfileService, getWorkerId()]
    : [workerService, match.params.workerId]

  const fetchPayments = (pageIndex) => {
    return {
      queryKey: ['getWorkerPayments', pageIndex + 1],
      queryFunction: () =>
        service.getPaymentsHistory(workerId, {
          page: pageIndex + 1,
          async: false,
        }),
    }
  }

  const { mutate: downloadPaymentHistory } = useMutation(
    (data) => {
      service.getPaymentsHistory(data, { file_format: 'xlsx' })
    },
    {
      onSuccess: () => {
        return downloadFile({
          name: 'Reporte de pagos salariales',
          fileCode: 'salary_payments_file',
          pathname: `/workers/${workerId}/payments_history`,
        })
      },
    }
  )

  const handleClickNoveltyReport = () => {
    downloadPaymentHistory(workerId)
  }

  const showPayslip = useCallback(
    async (payment) => {
      setIsLoading(true)
      if (payment.payslip_options.length > 0) {
        await openPayslipModal({
          payroll: { id: payment.payroll_id },
          payslipTypes: payment.payslip_options,
        })
      } else {
        await handleDownloadPayslip({ payroll: { id: payment.payroll_id } })
      }
      setIsLoading(false)
    },
    [handleDownloadPayslip, openPayslipModal]
  )

  const columnsData = useMemo(() => getColumnsData(showPayslip), [showPayslip])

  return (
    <Page
      documentTitle={`Historial de Pagos${
        worker && !isWorker ? ` de ${worker.fullName}` : ''
      }`}
      header={
        <Typography variant="h5">
          Historial de Pagos de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      isLoadingWithModal={isLoading}
    >
      <Table
        columns={columnsData}
        data={fetchPayments}
        actions={[
          {
            id: 'downloadPaymentsHistory',
            tooltip: 'Descargar historial de pagos',
            isFreeAction: true,
            onClick: handleClickNoveltyReport,
            icon: GetAppOutlinedIcon,
          },
        ]}
        options={{
          search: false,
        }}
      />
      {payslipModalState.open && (
        <PayslipModal
          state={payslipModalState}
          handleClose={closePayslipModal}
          handleDownloadPayslip={handleDownloadPayslip}
        />
      )}
    </Page>
  )
}

export default PaymentHistory
