import { useContext } from 'react'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { NoveltiesHistoryContext } from '../NoveltiesHistory'
import { tabsData } from '../helpers'

const NoveltiesHistoryTabs = () => {
  const { activeTab, handleChangeTab } = useContext(NoveltiesHistoryContext)

  return (
    <Tabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabsData}
      variant="scrollable"
    />
  )
}

export default NoveltiesHistoryTabs
