import * as yup from 'yup'

import { Button } from '@material-ui/core'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'
import FormField from 'components/UI/Formik/FormField/Index'

import { yupLocaleES } from 'utils/form'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'

yup.setLocale(yupLocaleES)

export const kinshipLabel = {
  spouse: 'Cónyugue',
  child: 'Hijos',
  progenitor: 'Padre o madre',
}

const kinshipOptions = Object.entries(kinshipLabel).map(([key, value]) => ({
  label: value,
  value: key,
}))

const DocumentSelector = ({ children, className }) => {
  return (
    <Button
      size="small"
      variant="text"
      className={className}
      startIcon={<CloudDownloadOutlinedIcon fontSize="small" />}
    >
      {children}
    </Button>
  )
}

const documentName = {
  spouse: {
    civil_registration: 'Registro civil de matrimonio',
    identification_document: 'Cédula del cónyugue',
  },
  child: {
    identification_document: 'Documento de identificación',
    civil_registration: 'Registro civil de nacimiento',
    study_document: 'Certificado de estudio',
  },
  progenitor: {
    identification_document: 'Cédula de padre o madre',
  },
}
export const getDocumentLabel = (kinship, documentType) =>
  documentName[kinship][documentType]

const getFilesName = (files) => {
  const names = {}

  Object.entries(files).forEach(([key, value]) => {
    const documentUrl = isValidUrl(value)
    const fileName = getFileNameFromUrl(
      documentUrl ? documentUrl.search : undefined
    )

    names[key] = fileName
  })

  return names
}

export const getColumns = (classes, canAddProgenitor, canAddSpouse) => {
  return [
    {
      accessor: 'name',
      Header: 'Nombre completo',
      Cell: ({ row }) => row.original.name,
      editable: true,
      field: 'textfield',
      name: 'name',
      customWidth: '200px',
    },
    {
      accessor: 'kinship',
      Header: 'Tipo de beneficiario',
      Cell: ({ row }) => kinshipLabel[row.original.kinship],
      editable: true,
      field: 'select',
      editOptions: (cell) => {
        let options = [...kinshipOptions]

        if (!cell.value) {
          if (!canAddProgenitor) {
            options = options.filter((item) => item.value !== 'progenitor')
          }

          if (!canAddSpouse) {
            options = options.filter((item) => item.value !== 'spouse')
          }
        } else {
          if (cell.value === 'progenitor') {
            if (!canAddSpouse) {
              options = options.filter((item) => item.value !== 'spouse')
            }
          }

          if (cell.value === 'spouse') {
            if (!canAddProgenitor) {
              options = options.filter((item) => item.value !== 'progenitor')
            }
          }
        }

        return options
      },
      name: 'kinship',
      customWidth: '200px',
    },
    {
      accessor: 'files',
      Header: 'Documentación',
      editable: true,
      Cell: ({ row }) => {
        const { files, kinship } = row.original
        const names = getFilesName(files)

        return (
          <div className={classes.documentsContainer}>
            {names.identification_document_file ? (
              <div className={classes.loadedFileContainer}>
                <LoadedFile
                  fileName={names.identification_document_file}
                  hideRemoveButton
                  className={classes.loadedFile}
                />
              </div>
            ) : null}

            {['spouse', 'child'].includes(kinship) &&
            names.civil_registration_file ? (
              <div className={classes.loadedFileContainer}>
                <LoadedFile
                  fileName={names.civil_registration_file}
                  hideRemoveButton
                  className={classes.loadedFile}
                />
              </div>
            ) : null}
            {kinship === 'child' && names.study_document_file ? (
              <div className={classes.loadedFileContainer}>
                <LoadedFile
                  fileName={names.study_document_file}
                  hideRemoveButton
                  className={classes.loadedFile}
                />
              </div>
            ) : null}
          </div>
        )
      },
      Edit: ({ rowValues }) => {
        const { kinship, files } = rowValues
        const names = getFilesName(files)

        if (!kinship)
          return 'Selecciona el tipo de beneficiario para ver cuáles documentos debes adjuntar'

        return (
          <div className={classes.documentsContainer}>
            <FormField
              name="files[identification_document_file]"
              variant="file"
              fileType="application/pdf"
              accept="application/pdf"
              disableDropzone
              fullWidth={false}
              fileName={names.identification_document_file}
              dropzoneClassName={classes.documentItem}
              selector={
                <DocumentSelector>
                  {getDocumentLabel(kinship, 'identification_document')}
                </DocumentSelector>
              }
            />
            {['spouse', 'child'].includes(kinship) ? (
              <FormField
                name="files[civil_registration_file]"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
                disableDropzone
                dropzoneClassName={classes.documentItem}
                fullWidth={false}
                fileName={names.civil_registration_file}
                selector={
                  <DocumentSelector>
                    {getDocumentLabel(kinship, 'civil_registration')}
                  </DocumentSelector>
                }
              />
            ) : null}
            {kinship === 'child' ? (
              <FormField
                name="files[study_document_file]"
                variant="file"
                fileType="application/pdf"
                accept="application/pdf"
                disableDropzone
                fullWidth={false}
                fileName={names.study_document_file}
                dropzoneClassName={classes.documentItem}
                selector={
                  <DocumentSelector>
                    {getDocumentLabel(kinship, 'study_document')}
                  </DocumentSelector>
                }
              />
            ) : null}
          </div>
        )
      },
    },
  ]
}

export const getActions = (removeBeneficiary) => {
  return [
    {
      id: 'deleteBeneficiary',
      tooltip: 'Eliminar beneficiario',
      icon: DeleteOutlineOutlinedIcon,
      onClick: (row) => removeBeneficiary(row.rowIndex),
    },
  ]
}

export const validationSchema = yup.object({
  name: yup.string().nullable().required(),
  kinship: yup.string().nullable().required(),
  files: yup
    .object()
    .when('kinship', {
      is: (kinship) => kinship === 'spouse',
      then: yup.object().shape(
        {
          civil_registration_file: yup
            .mixed()
            .when('identification_document_file', {
              is: (identificationDocument) => {
                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('civil_registration_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          identification_document_file: yup
            .mixed()
            .when('civil_registration_file', {
              is: (civilRegistration) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('identification_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
        },
        ['identification_document_file', 'civil_registration_file']
      ),
    })
    .when('kinship', {
      is: (kinship) => kinship === 'child',
      then: yup.object().shape(
        {
          civil_registration_file: yup
            .mixed()
            .when(['identification_document_file', 'study_document_file'], {
              is: (identificationDocument, studyDocument) => {
                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                if (
                  studyDocument &&
                  (studyDocument instanceof File ||
                    typeof studyDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('civil_registration_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          identification_document_file: yup
            .mixed()
            .when(['civil_registration_file', 'study_document_file'], {
              is: (civilRegistration, studyDocument) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                if (
                  studyDocument &&
                  (studyDocument instanceof File ||
                    typeof studyDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('identification_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          study_document_file: yup
            .mixed()
            .when(['identification_document_file', 'civil_registration_file'], {
              is: (identificationDocument, civilRegistration) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('study_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
        },
        [
          ['civil_registration_file', 'identification_document_file'],
          ['civil_registration_file', 'study_document_file'],
          ['identification_document_file', 'study_document_file'],
        ]
      ),
    })
    .when('kinship', {
      is: (kinship) => kinship === 'progenitor',
      then: yup.object().shape(
        {
          identification_document_file: yup
            .mixed()
            .test('identification_document_file', null, (value) => {
              if (!(typeof value === 'string')) {
                return value instanceof File
              }

              return Boolean(value)
            })
            .required(),
        },
        ['identification_document_file', 'identification_document_file']
      ),
    }),
})
