import SvgIcon from '@material-ui/core/SvgIcon'

function MultiCompany(props) {
  return (
    <SvgIcon width="30" height="26" viewBox="0 0 30 26" {...props}>
      <path d="M21.666 0.333984H1.66602V3.00065H21.666V0.333984Z" />
      <path
        d="M18.9997 17.6673H21.6663V13.6673H22.9997V11.0007L21.6663 4.33398H1.66634L0.333008 11.0007V13.6673H1.66634V21.6673H13.6663V13.6673H18.9997V17.6673ZM10.9997 19.0007H4.33301V13.6673H10.9997V19.0007ZM3.05301 11.0007L3.85301 7.00065H19.4797L20.2797 11.0007H3.05301Z"
        fill="white"
      />
      <path d="M29.6667 19H25.6667V15H23V19H19V21.6667H23V25.6667H25.6667V21.6667H29.6667V19Z" />
    </SvgIcon>
  )
}

export default MultiCompany
