import { makeStyles } from '@material-ui/core/styles'

import LoadingBackdrop from 'components/UI/Loading/LoadingBackdrop'
import { usePremiumActions } from 'components/App/Premium/helpers'

import useOnboardingMutation from '../useOnboardingMutation'
import BadgePlan from './BadgePlans'
import planDetail from './helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    textAlign: 'center',
  },
  image: {
    position: 'relative',
    top: 20,
    width: 308,
    height: 207,
  },
  containerStep: {
    '& > h6': {
      marginBottom: theme.spacing(2),
      maxWidth: 551,
    },
    '& > p': {
      marginBottom: theme.spacing(2),
    },
  },
  containerPlans: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    justifyContent: 'center',
    marginBottom: theme.spacing(3.8),
  },

  // styles for new design
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(14),
    },
  },
  containerPlans2: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'center',
    marginBottom: theme.spacing(3.8),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}))

const OnboardingWelcome = ({ handleNextStep }) => {
  const classes = useStyles()
  const { updateRoleStep, onSuccessMutation, loading } = useOnboardingMutation(
    handleNextStep
  )

  const valuesToSend = { onboarding_step: 'role', start_freemium: false }

  const { handleGetFreeTrial, subscriptionMutation } = usePremiumActions()

  const handleStarFree = () => {
    updateRoleStep(valuesToSend, onSuccessMutation)
  }

  const handleStarPremium = () => {
    handleGetFreeTrial()

    updateRoleStep({ ...valuesToSend, start_freemium: true }, onSuccessMutation)
  }

  const featuresPlans = planDetail(handleStarFree, handleStarPremium)

  const { free, premium } = featuresPlans

  return (
    <div className={classes.container}>
      <div className={classes.containerPlans2}>
        <LoadingBackdrop open={subscriptionMutation.isLoading || loading} />
        <BadgePlan
          premium
          title={premium.title}
          userMessage={premium.userMessage}
          supportTime={premium.supportTime}
          description={premium.description}
          buttonText={premium.buttonText}
          handleClick={premium.buttonAction}
        />
        <BadgePlan
          title={free.title}
          userMessage={free.userMessage}
          supportTime={free.supportTime}
          description={free.description}
          buttonText={free.buttonText}
          handleClick={free.buttonAction}
        />
      </div>
    </div>
  )
}

export default OnboardingWelcome
