import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import FormField from 'components/UI/Formik/FormField/Index'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { SETTINGS } from 'config/routes'

import messages from 'messages/notification'

import validationSchema from './validationSchema'

const SettingsProfile = () => {
  const { user, logIn } = useUser()
  const history = useHistory()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = async (values, form) => {
    const dataToSend = {
      ...values,
    }

    if (dataToSend.password) {
      dataToSend.password = btoa(values.password)
    } else delete dataToSend.password

    if (dataToSend.password_confirmation) {
      dataToSend.password_confirmation = btoa(values.password_confirmation)
    } else delete dataToSend.password_confirmation

    const dirtyValues = getDirtyValues(user, dataToSend)

    if (!isObjectEmpty(dirtyValues)) {
      usersMutation.mutate(
        {
          mutationMethod: 'PATCH',
          mutationKey: 'updateProfile',
          user: dirtyValues,
        },
        {
          onSuccess: ({ data }) => {
            logIn(data)
            showSuccessMessage(messages.SETTINGS_USER_PROFILE_SUCCESS)
            history.push(SETTINGS())
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  return (
    <>
      <Page header="Editar mi perfil">
        <FormBox>
          <Typography>
            Para realizar la modificación de alguno de los siguientes campos, la
            contraseña siempre será requerida.
          </Typography>
          <Formik
            initialValues={{
              name: user.name,
              email: user.email,
              current_password: user.current_password,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formProps) => {
              const { handleSubmit: onSubmit, isSubmitting } = formProps

              return (
                <Form>
                  <FormField name="name" label="Nombre" />
                  <FormField
                    type="email"
                    name="email"
                    label="Correo electrónico"
                  />
                  <FormField
                    type="password"
                    name="current_password"
                    label="Contraseña Actual *"
                    autoComplete="current-password"
                  />
                  <FormField
                    type="password"
                    name="password"
                    label="Nueva Contraseña"
                    autoComplete="new-password"
                  />
                  <FormField
                    type="password"
                    name="password_confirmation"
                    label="Confirmar Contraseña"
                    autoComplete="new-password"
                  />
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button onClick={onSubmit} loading={isSubmitting}>
                      Actualizar
                    </Button>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </FormBox>
      </Page>
    </>
  )
}

export default SettingsProfile
