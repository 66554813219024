import http, { getApiURL } from '../httpService'

export const getAllCompanies = ({ search, page }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/companies',
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const getCompanyFromOrganizer = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${companyId}`,
  })

  return http.getV2(url)
}

export const updateCompany = ({ id, ...restRequest }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${id}`,
  })

  return http.patchV2(url, restRequest)
}

export const deleteCompany = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${companyId}`,
  })

  return http.deleteV2(url)
}

export default {
  getAllCompanies,
  updateCompany,
  deleteCompany,
  getCompanyFromOrganizer,
}
