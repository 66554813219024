import clsx from 'clsx'
import { useRef } from 'react'

import { useMediaQuery } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'

import LoadedFile from '../CommonFields/LoadedFile'

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  dropzone: {
    width: '100%',
    height: 48,
    display: 'flex',
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    borderRadius: 8,
    padding: theme.spacing(0, 1, 0, 2),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[500],
    },
  },
  innerContainer: {
    height: 48,
    justifyContent: 'space-between',
  },
  button: {
    height: 32,
  },
  description: {
    color: theme.palette.grey[400],
  },
  fileInput: {
    display: 'none',
  },
  withBorder: {
    border: `1px solid ${theme.palette.grey[400]} !important`,
  },
}))

const FileField = ({
  id,
  name,
  value,
  disabled,
  accept, // This prop controls the selection when using the file selector
  fileType, // This prop controls the selection when dragging the file
  fileName = null,
  ...props
} = {}) => {
  const { setValue, selector, disableDropzone, dropzoneClassName } = props
  const classes = useStyles()
  const fileInputRef = useRef(null)
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  if (!accept || !fileType) {
    throw new Error(
      `accept and fileType props are required and you provided: accept: ${accept} and fileType: ${fileType}`
    )
  }

  const onFileAdded = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.target.files[0]
    setValue(file)
  }

  const openFileDialog = () => {
    if (disabled) return

    fileInputRef.current.click()
  }

  const onDrop = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.dataTransfer.files[0]
    if (fileType) {
      if (typeof fileType === 'string' && file.type === fileType) {
        setValue(file)
      } else if (Array.isArray(fileType) && fileType.includes(file.type)) {
        setValue(file)
      }
    }
  }

  const onDragOver = (event) => stopEvent(event)

  const handleDeleteFile = (event) => {
    stopEvent(event)

    setValue(null)
  }

  return (
    <div
      role="none"
      onDrop={!disableDropzone ? onDrop : undefined}
      onDragOver={!disableDropzone ? onDragOver : undefined}
      onClick={openFileDialog}
      className={clsx(classes.dropzone, dropzoneClassName, {
        [classes.withBorder]: selector && value,
      })}
    >
      <input
        id={id}
        name={name}
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={onFileAdded}
        className={classes.fileInput}
        disabled={disabled}
        data-cy="file-input"
      />
      {value ? (
        <LoadedFile
          fileName={fileName}
          handleDeleteFile={handleDeleteFile}
          value={value}
        />
      ) : (
        <>
          {selector || (
            <div
              className={clsx(classes.baseContainer, classes.innerContainer)}
            >
              <Typography variant="body2" className={classes.description}>
                {smUp ? 'Arrastrar aquí' : 'Arrastrar'}
              </Typography>
              <Button
                color="primary"
                disabled={disabled}
                size="small"
                className={classes.button}
              >
                {smUp ? 'Adjuntar archivo' : 'Adjuntar'}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FileField
