import Typography from '@material-ui/core/Typography'
import { formatDateRange, formatDisplayDate } from 'utils/dateTime'
import { roundDecimals } from 'utils/number'
import { formatNumber } from 'utils/format'

export default [
  {
    Header: 'Periodo',
    accessor: 'initial_day',
    Cell: ({ row }) => (
      <>
        {row.original.previous_holidays !== 0 && (
          <Typography>{formatDisplayDate(row.original.end_day)}</Typography>
        )}
        {row.original.termination && (
          <Typography>
            Liquidación empleado
            <br />
            {formatDisplayDate(row.original.end_day)}
          </Typography>
        )}
        {!row.original.termination && !row.original.previous_holidays && (
          <Typography>
            {formatDateRange(row.original.initial_day, row.original.end_day)}
          </Typography>
        )}
      </>
    ),
  },
  {
    Header: 'Días hábiles de vacaciones disfrutadas o compensadas en dinero',
    accessor: 'enjoyed_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.enjoyed_holidays)),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Vacaciones acumuladas en el periodo',
    accessor: 'period_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.period_holidays)),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Vacaciones acumuladas al final del periodo',
    accessor: 'accumulated_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.accumulated_holidays)),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Comentario',
    Cell: ({ row }) => (
      <>
        {row.original.previous_holidays !== 0 ? (
          <Typography>
            Ajuste manual (Valor anterior{' '}
            {formatNumber(roundDecimals(row.original.previous_holidays))}) por{' '}
            {row.original.user_email}
          </Typography>
        ) : (
          <Typography>Generado automáticamente</Typography>
        )}
      </>
    ),
  },
]
