import { useContext } from 'react'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import auth from 'utils/auth'

import logoWhite from 'assets/images/logos/logo_blanco_degradado.svg'
import logoWhiteIsotipo from 'assets/images/logos/isotipo_blanco.svg'

import { SidebarContext } from './Sidebar'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 'auto',
    display: 'flex',
    alignSelf: 'center',
    paddingTop: theme.spacing(),
    justifyContent: 'center',
    whiteSpace: 'nowrap', // to prevent a rare effect collapsing the sidebar
  },
  longContent: {
    alignItems: 'center',
    display: 'none',
  },
  longContentExpanded: {
    display: 'flex',
  },
  longContentText: {
    ...theme.typography.C8,
    color: theme.palette.common.white,
    fontSize: '0.7rem',
  },
  longContentImg: {
    marginLeft: 5,
    width: 85,
  },
  imgIsotype: {
    display: 'block',
    width: 16,
  },
  imgIsotypeExpanded: {
    display: 'none',
  },
}))

const AppSidebarPoweredBy = () => {
  const { expanded } = useContext(SidebarContext)
  const classes = useStyles()

  return auth.isAlegraClaroDist() || auth.isArusDist() ? (
    <div className={classes.root}>
      <div
        className={clsx(classes.longContent, {
          [classes.longContentExpanded]: expanded,
        })}
      >
        <Typography className={classes.longContentText} display="inline">
          Con la tecnología de
        </Typography>
        <img
          src={logoWhite}
          alt="Nominapp logo"
          className={classes.longContentImg}
        />
      </div>
      <img
        src={logoWhiteIsotipo}
        alt="Nominapp logo"
        className={clsx(classes.imgIsotype, {
          [classes.imgIsotypeExpanded]: expanded,
        })}
      />
    </div>
  ) : null
}

export default AppSidebarPoweredBy
