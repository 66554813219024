import { useState } from 'react'
import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

import Button from 'components/UI/Button/Button'
import EditButton from 'components/UI/Button/EditButton'
import FormField from 'components/UI/Formik/FormField/Index'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'

import { documentTypesOptions, getCompanyId } from 'utils/company'
import useBillingService from 'utils/hooks/subscription/billing'
import { areObjectsEqual } from 'utils/general'

import { billingValidationSchema, getBillingInitialValues } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  documentFields: {
    display: 'grid',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
    gridTemplateColumns: '30% 1fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.between(960, 1410)]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const BillingCard = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const [editBilling, setEditBilling] = useState(false)
  const disableField = !editBilling
  const billingQueryKey = ['getBillingInformation', companyId]

  const { billingQuery, billingMutation } = useBillingService({
    serviceParams: {
      queryKey: billingQueryKey,
    },
  })

  const enableBillingForm = () => setEditBilling(true)

  const disableBillingForm = () => setEditBilling(false)

  const handleSubmit = (values) => {
    billingMutation.mutate(
      {
        mutationMethod: 'PATCH',
        billingInformation: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(billingQueryKey)
          disableBillingForm()
        },
      }
    )
  }

  const handleSubmitBilling = (isFormChanged, onSubmit) => {
    if (isFormChanged) {
      onSubmit()
    } else {
      disableBillingForm()
    }
  }

  return (
    <Card className={classes.container}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getBillingInitialValues(billingQuery.data)}
        validationSchema={billingValidationSchema}
        enableReinitialize
      >
        {({ handleSubmit: onSubmit, values }) => {
          const isFormChanged = !areObjectsEqual(billingQuery.data, values)

          return (
            <>
              <div className={classes.header}>
                <Typography variant="h5">Datos de facturación</Typography>
                {editBilling ? (
                  <Button
                    onClick={() => handleSubmitBilling(isFormChanged, onSubmit)}
                    variant={isFormChanged ? 'contained' : 'outlined'}
                    loading={billingMutation.isLoading}
                  >
                    {isFormChanged ? 'Actualizar' : 'Cancelar'}
                  </Button>
                ) : (
                  <EditButton onClick={enableBillingForm} />
                )}
              </div>
              <Form>
                <FormField
                  name="name"
                  label="Razón social"
                  disabled={disableField}
                />
                <div className={classes.documentFields}>
                  <DocumentTypeField
                    options={documentTypesOptions}
                    disabled={disableField}
                  />
                  <CompanyIdentificationNumberField disabled={disableField} />
                </div>
                <FormField
                  name="email"
                  label="Correo electrónico"
                  disabled={disableField}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    </Card>
  )
}

export default BillingCard
