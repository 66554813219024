export const signUpOptions = {
  payslips_pdf: true,
  payslips_zip: true,
  payslips_emails: true,
  payroll_summary_file: true,
  novelties_report: true,
  accounting_file: true,
  bank_file: true,
  social_security_file: true,
  students_social_security_file: false,
  social_benefits: [
    {
      label: 'Prima de Servicios 2020-1',
      social_benefits_period_id: 'a3c450d1-85b8-4614-8154-36a9095590eb',
      action: 'create',
    },
    {
      label: 'Prima de Servicios 2020-2',
      social_benefits_period_id: '35367ca3-7078-4bfe-a628-e4614af201b8',
      action: 'create',
    },
    {
      label: 'Intereses a las Cesantías 2020',
      social_benefits_period_id: '4e7bec26-052b-4be6-8b16-0ef3db802322',
      action: 'create',
    },
    {
      label: 'Cesantías 2020',
      social_benefits_period_id: 'b086e8be-5b4c-400a-97fb-6a561ea49fbe',
      action: 'create',
    },
  ],
}

const currentDate = new Date()

export const signUpPeriod = {
  previous_period_id: 'a563d3b1-e032-43e1-b134-c892e2811769',
  id: '682c24ea-6800-461f-9b9d-59ede998b04a',
  initial_day: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  end_day: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  length: 'monthly',
  payrolls_paid: false,
  payrolls_number: 3,
  workers_payment: 5050433,
  company_cost: 7561501,
  editable: true,
  contracts_counter: {
    employee: 3,
    pensioner: 0,
    apprentice: 0,
    student: 0,
    contractor: 0,
    terminated: 0,
  },
  payrolls: [
    {
      id: 'db48c376-ddbe-414a-bf4c-b8d1c1217b26',
      worker_id: '2441efa5-81ba-4e1d-8286-ba7a46dcddeb',
      worker_name: 'Andrés Ángel',
      worker_id_number: '446993140',
      worker_document_type: 'cc',
      contract_id: 'be3e2063-8c88-4799-b8bb-6ee4e6ee1735',
      contract_category: 'employee',
      social_benefits_category: null,
      salary_category: 'monthly',
      base_salary: 1500000,
      early_payment: false,
      compensated_days: 0,
      accumulated_holidays: 2.5,
      holidays_average_salary: 1500000,
      novelties_average_salary: 1500000,
      salary: 1500000,
      worked_time: 30,
      worker_payment: 1380000,
      company_cost: 2075330,
      novelties_days: 0,
      novelties_value: 0,
      overtime_hours: 0,
      overtime_value: 0,
      salary_income: 0,
      non_salary_income: 0,
      deductions: 0,
      comments: null,
      payslip_mail: false,
      bank_file: false,
      connectivity_aid_option: false,
      connectivity_aid_days: 0,
      tableData: { id: 0 },
    },
    {
      id: '6f062e8b-9a57-4769-ac7c-fc19c43aeba1',
      worker_id: '5307d906-cf97-44aa-a1c6-750cd096f01b',
      worker_name: 'Juan Rodríguez',
      worker_id_number: '685744522',
      worker_document_type: 'cc',
      contract_id: '89fdb0ef-1b53-4da9-80c8-32d5f6ac8ebf',
      contract_category: 'employee',
      social_benefits_category: null,
      salary_category: 'monthly',
      base_salary: 877803,
      early_payment: false,
      compensated_days: 0,
      accumulated_holidays: 2.5,
      holidays_average_salary: 877803,
      novelties_average_salary: 877803,
      salary: 877803,
      worked_time: 30,
      worker_payment: 910433,
      company_cost: 1335511,
      novelties_days: 0,
      novelties_value: 0,
      overtime_hours: 0,
      overtime_value: 0,
      salary_income: 0,
      non_salary_income: 0,
      deductions: 0,
      comments: null,
      payslip_mail: false,
      bank_file: false,
      connectivity_aid_option: true,
      connectivity_aid_days: 0,
      tableData: { id: 1 },
    },
    {
      id: '51764143-ba7e-48b9-ac53-245edc034723',
      worker_id: '8403d9b9-411c-4dcd-8478-d6a16be4ab66',
      worker_name: 'Eugenio Nominapp',
      worker_id_number: '476127693',
      worker_document_type: 'cc',
      contract_id: '65e6fcac-170a-469e-9951-ac2972363753',
      contract_category: 'employee',
      social_benefits_category: null,
      salary_category: 'monthly',
      base_salary: 3000000,
      early_payment: false,
      compensated_days: 0,
      accumulated_holidays: 2.5,
      holidays_average_salary: 3000000,
      novelties_average_salary: 3000000,
      salary: 3000000,
      worked_time: 30,
      worker_payment: 2760000,
      company_cost: 4150660,
      novelties_days: 0,
      novelties_value: 0,
      overtime_hours: 0,
      overtime_value: 0,
      salary_income: 0,
      non_salary_income: 0,
      deductions: 0,
      comments: null,
      payslip_mail: false,
      bank_file: false,
      connectivity_aid_option: false,
      connectivity_aid_days: 0,
      tableData: { id: 2 },
    },
  ],
}
