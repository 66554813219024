import { Form, Formik } from 'formik'
import * as yup from 'yup'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import CustomField from 'components/UI/Formik/CustomField'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { formatValues, yupLocaleES } from 'utils/form'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'

const AccountingFileModal = ({ state, handleClose }) => {
  const { enqueueDownload } = useDownloadManager()
  const { open, period, providerOptions, periodOptions, fileOptions } = state

  const fortnightlyOptions = fileOptions.filter(
    (e) => e.value !== 'social_security_payment'
  )

  const handleSubmitForm = async (values, form) => {
    const serviceCall = async () => {
      // eslint-disable-next-line no-return-await
      return await fileService.getAccountingFile(
        period.id,
        formatValues(values)
      )
    }

    const callback = () => {
      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: 'accounting',
        type: 'PDF',
      })
    }

    form.setSubmitting(false)
    handleClose()
    enqueueDownload({
      name: 'archivo de Interfaz Contable',
      fileCode: 'accounting_file',
      serviceCall,
      callback,
    })
  }

  const selectedProvider = providerOptions.find((p) => p.selected)

  const initialValues = {
    provider_id: selectedProvider,
    file_options: [],
    // if there are periodOptions, set first value as initial value
    period_option: periodOptions[0]?.value || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      // eslint-disable-next-line no-use-before-define
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, values, dirty }) => {
        return (
          <Modal
            open={open}
            onOk={handleSubmit}
            onCancel={handleClose}
            isLoading={isSubmitting}
            okText="Generar"
            header="Generar Interfaz Contable"
            loadingText="Generando Archivo..."
            dialogProps={{
              disableBackdropClick: dirty || isSubmitting,
            }}
          >
            <Form>
              <Typography gutterBottom>
                Selecciona el software contable para el cual deseas generar el
                archivo:
              </Typography>
              {providerOptions.length > 0 && (
                <CustomField
                  name="provider_id"
                  label="Software Contable"
                  component="autocomplete"
                  options={providerOptions}
                  getOptionLabel={({ label }) => label}
                  renderOption={({ label }) => label}
                  getOptionSelected={(opt, value) => opt.value === value.value}
                  disableClearable
                />
              )}
              {periodOptions.length > 0 && (
                <FormField
                  name="period_option"
                  label="Período"
                  variant="select"
                  options={periodOptions}
                />
              )}
              <Typography style={{ marginTop: 15 }} gutterBottom>
                Selecciona el tipo de archivo que deseas generar. Puedes
                seleccionar varios tipos. Los archivos de `(provisión)`
                contienen la provisión de los diferentes conceptos y los
                archivos de causación contienen los pagos de estos, haz{' '}
                <Link
                  href="https://ayuda.nominapp.com/help/cual-es-la-diferencia-entre-los-archivos-contables-provision-y-pagos-que-genera-nominapp"
                  target="_blank"
                >
                  click aquí
                </Link>{' '}
                para conocer las diferencias.
              </Typography>
              {fileOptions.length > 0 && (
                <FormField
                  name="file_options"
                  label="Tipo de archivo"
                  variant="checkbox-group"
                  options={
                    values.period_option === 'fortnightly'
                      ? fortnightlyOptions
                      : fileOptions
                  }
                />
              )}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AccountingFileModal

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  provider_id: yup.object().nullable().required(),
  period_option: yup.string(),
  file_options: yup
    .array(yup.string())
    .min(1, 'Selecciona al menos un tipo de archivo')
    .required(),
})
