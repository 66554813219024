import clsx from 'clsx'
import { isValid } from 'date-fns'
import { useField, useFormikContext } from 'formik'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import AutocompleteField from './AutocompleteField'
import CheckboxField from './CheckboxField'
import CheckboxGroupField from './CheckboxGroupField'
import CurrencyField from './CurrencyField'
import CustomFormatField from './CustomFormatField'
import DateField from './DateField'
import FileField from './FileField'
import NumberField from './NumberField'
import RadioGroupField from './RadioGroupField'
import SelectField from './SelectField'
import SliderField from './SliderField'
import SwitchField from './SwitchField'
import TextField from './TextField'
import TextareaField from './TextareaField'

export const useStyles = makeStyles((theme) => ({
  switchControl: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  labelContainer: {
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
  labelOptional: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(0.5),
  },
  labelTooltip: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    cursor: 'help',
  },
  switchLabel: {
    alignSelf: 'center',
    marginBottom: 0,
  },
  formLabel: {
    alignSelf: 'flex-end',
  },
  tooltipIcon: {
    marginBottom: 4,
  },
}))

const FormField = ({
  // Props for FormControl
  disabled,
  margin,
  fullWidth = true,
  optional,

  // Content for FormControl
  label,
  tooltipTextContent,
  tooltipContent,
  additionalLabelComponent,
  tooltipIconClassName,

  // Props for field variant
  variant = 'textfield',
  name, // This prop is mandatory for any variant of the FormField, because it must be related to a value defined in the Formik context
  type,
  value,
  multiple,
  validate,
  options,
  row,
  justifiSpaceBetween, // This prop is only for the variant 'checkbox-group'

  // Aditional props
  render,
  formControlClassName,
  ...props
}) => {
  const classes = useStyles()
  const form = useFormikContext()
  const [field, meta, helpers] = useField({
    name,
    validate,
    type,
    multiple,
    value,
  })

  const { value: fieldValue, onBlur, onChange } = field
  const { touched, error } = meta
  const { setValue, setTouched } = helpers
  const { submitCount } = form

  const id = `${name}_id`
  const showError = (touched || submitCount > 0) && !!error

  const getDatePickerShowError = () => {
    if (variant === 'datepicker') {
      return showError && !isValid(fieldValue)
    }
    return false
  }
  const datePickerError = getDatePickerShowError()

  const renderFieldVariant = () => {
    if (render) return render(field, meta, helpers, form)

    if (variant === 'currency') {
      return (
        <CurrencyField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          touched={touched}
          onBlur={onBlur}
          {...props}
        />
      )
    }

    if (variant === 'checkbox') {
      return <CheckboxField id={id} field={field} {...props} />
    }

    if (variant === 'slider') {
      return (
        <SliderField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'checkbox-group') {
      return (
        <CheckboxGroupField
          row={row}
          options={options}
          value={fieldValue}
          setValue={setValue}
          justifiSpaceBetween={justifiSpaceBetween}
        />
      )
    }

    if (variant === 'number') {
      return (
        <NumberField
          id={id}
          name={name}
          value={fieldValue}
          onBlur={onBlur}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'custom-format') {
      return (
        <CustomFormatField
          id={id}
          name={name}
          onBlur={onBlur}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'select') {
      return (
        <SelectField
          id={id}
          field={field}
          name={name}
          value={fieldValue}
          options={options}
          {...props}
        />
      )
    }

    if (variant === 'radio-group') {
      return (
        <RadioGroupField
          id={id}
          name={name}
          value={fieldValue}
          options={options}
          setValue={setValue}
          setTouched={setTouched}
          row={row}
          {...props}
        />
      )
    }

    if (variant === 'autocomplete') {
      return (
        <AutocompleteField
          id={id}
          name={name}
          value={fieldValue}
          options={options}
          setValue={setValue}
          setTouched={setTouched}
          disabled={disabled}
          {...props}
        />
      )
    }

    if (variant === 'switch') {
      return (
        <SwitchField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'textarea') {
      return (
        <TextareaField
          id={id}
          name={name}
          value={fieldValue}
          onBlur={onBlur}
          onChange={onChange}
          {...props}
        />
      )
    }

    if (variant === 'datepicker') {
      return (
        <DateField
          id={id}
          name={name}
          value={fieldValue}
          disabled={disabled}
          setValue={setValue}
          showError={showError}
          setTouched={setTouched}
          {...props}
        />
      )
    }

    if (variant === 'file') {
      return (
        <FileField
          id={id}
          name={name}
          value={fieldValue}
          disabled={disabled}
          setValue={setValue}
          {...props}
        />
      )
    }

    return (
      <TextField
        id={id}
        field={field}
        value={fieldValue}
        type={type}
        {...props}
      />
    )
  }

  return (
    <FormControl
      error={variant === 'datepicker' ? datePickerError : showError}
      disabled={disabled}
      margin={margin}
      className={clsx(
        formControlClassName,
        variant === 'switch' && classes.switchControl
      )}
      fullWidth={fullWidth}
    >
      {label ? (
        <div className={classes.labelContainer}>
          <FormLabel
            htmlFor={id}
            className={
              variant === 'switch' ? classes.switchLabel : classes.formLabel
            }
          >
            {label}
            {optional ? (
              <Typography variant="caption" className={classes.labelOptional}>
                {' - Opcional'}
              </Typography>
            ) : null}
            {tooltipTextContent ? (
              <Tooltip title={tooltipTextContent} placement="right">
                <Typography variant="caption" className={classes.labelTooltip}>
                  ¿Qué es esto?
                </Typography>
              </Tooltip>
            ) : null}
          </FormLabel>
          {tooltipContent ? (
            <TooltipInfoIcon
              title={tooltipContent}
              interactive={typeof tooltipContent !== 'string'}
              tooltipProps={{ placement: 'right' }}
              iconProps={{
                className: clsx(classes.tooltipIcon, tooltipIconClassName),
              }}
            />
          ) : null}
          {additionalLabelComponent}
        </div>
      ) : null}
      {renderFieldVariant()}
      {showError && variant !== 'datepicker' ? (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error}
        </FormHelperText>
      ) : null}
      {datePickerError && variant === 'datepicker' ? (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default FormField
