import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

import AuthSlideShow from './SlideShow'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    backgroundColor: theme.palette.common.white,
    justifyItems: 'center',
    minHeight: '100%',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  containerInModalView: {
    gridTemplateColumns: '1fr',
    '& > div > form > div': {
      marginBottom: theme.spacing(3),
    },
    '& > div, form': {
      marginBottom: theme.spacing(0),
    },
  },
  slideshowContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.lighter,
  },
}))

const MainContainer = ({ children, isFromRecoveryPassword = false }) => {
  const classes = useStyles()
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerInModalView]: isFromRecoveryPassword,
      })}
    >
      {children}
      {desktopUp && !isFromRecoveryPassword ? (
        <div className={classes.slideshowContainer}>
          <AuthSlideShow />
        </div>
      ) : null}
    </div>
  )
}

export default MainContainer
