import { Link as RouterLink, useHistory } from 'react-router-dom'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import useModalExtraWorker from 'components/Subscription/Atoms/ExtraWorkersPayment/useModalExtraWorker'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'

import * as routes from 'config/routes'

const ButtonAddWorker = ({ variant }) => {
  const {
    showNewSubscription,
    additionalWorkersInfo,
    subscription,
  } = useSubscription()
  const history = useHistory()
  const modalExtraWorker = useModalExtraWorker()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'addWorkersMenu',
  })
  const hasToPayNewWorkers =
    !additionalWorkersInfo?.add_workers && subscription?.type === 'year'

  const handleAddWorker = () => {
    // need to add worker into plan
    if (showNewSubscription && hasToPayNewWorkers) {
      popupState.close()
      modalExtraWorker.openModal()
    } else {
      history.push(routes.WORKER_NEW())
    }
  }

  return (
    <>
      <Button
        startIcon={<PersonAddOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
        variant={variant}
      >
        Agregar Personas
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleAddWorker}>
          <ListItemIcon>
            <EditOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Ingresar manualmente</ListItemText>
        </MenuItem>
        <MenuItem component={RouterLink} to={routes.WORKER_IMPORT()}>
          <ListItemIcon>
            <PublishOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText> Subir archivo</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ButtonAddWorker
