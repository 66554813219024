import parseISO from 'date-fns/parseISO'

import http, { getApiURL } from '../httpService'

export function formatSocialBenefits(socialBenefits) {
  const socialBenefitsCopy = socialBenefits
  // for getAll
  if (socialBenefits.payment_limit_day)
    socialBenefitsCopy.payment_limit_day = parseISO(
      socialBenefits.payment_limit_day
    )

  // for getByid
  if (socialBenefits.payments) {
    socialBenefits.payments.forEach((item) => {
      const itemCopy = item
      itemCopy.date = parseISO(item.date)
    })
  }

  return socialBenefits
}

export const getAllSocialBenefits = (contractId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/contracts/${contractId}/social_benefits`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const getSocialBenefitsById = (
  contractId,
  socialBenefitId,
  { page } = {}
) => {
  const url = getApiURL({
    pathname: `/contracts/${contractId}/social_benefits/${socialBenefitId}`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export default {
  getAllSocialBenefits,
  getSocialBenefitsById,
}
