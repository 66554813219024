import parseISO from 'date-fns/parseISO'

function formatPeriod(period) {
  const periodCopy = period
  periodCopy.initial_day = parseISO(period.initial_day)
  periodCopy.end_day = parseISO(period.end_day)

  return period
}

export default formatPeriod
