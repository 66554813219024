import { Fragment } from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DeleteItemButton from 'components/UI/Button/DeleteIconButton'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'
import { formatNumber } from 'utils/format'

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '60% 1fr 1fr 42px',
    alignItems: 'center',
  },
}))

const OvertimeItem = ({ data, category, onChange, onDeleteItem }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {Object.values(data).map((item, index) => (
        <Fragment key={item.id}>
          <div className={classes.container} id={`overtime_${item.coded_name}`}>
            <Typography id={`overtime_${item.coded_name}_name`} variant="body2">
              {item.name}
            </Typography>
            <Typography
              id={`overtime_${item.coded_name}_value`}
              variant="body2"
            >
              {formatNumber(item.value)}
            </Typography>
            <TextField
              id={`overtime_${item.coded_name}_quantity`}
              name={`overtime_${item.coded_name}_quantity`}
              value={item.quantity}
              onChange={(e) => onChange(e, item.id, category)}
              InputProps={{
                options: { numeralIntegerScale: 3 },
                inputComponent: NumberFormatField,
              }}
              placeholder="0,0"
            />
            {onDeleteItem && (
              <DeleteItemButton
                size="small"
                onClick={() => onDeleteItem(item.id)}
                deleteable={item.deleteable}
                style={{ marginLeft: 10 }}
              />
            )}
          </div>
          {index < data.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  )
}

export default OvertimeItem
