import { useEffect } from 'react'

import headway from 'utils/integrations/scripts/headway'

import CampaignIcon from 'assets/icons/Campaign'

const Changelog = () => {
  const CHANGELOG_ID = process.env.REACT_APP_HEADWAY_CHANGELOG_ID_ORGANIZER

  useEffect(() => {
    headway.headwayChangelog(CHANGELOG_ID)
  }, [CHANGELOG_ID])

  return <CampaignIcon />
}
export default Changelog
