import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'

import loadAnalyticScripts from 'components/App/loadAnalyticScripts'
import ConfirmProvider from 'components/UI/ConfirmModal/ConfirmProvider'
import LoadingModalProvider from 'components/UI/Loading/LoadingModalProvider'

import BreadcrumbsProvider from './BreadcrumbsProvider'
import ErrorBoundaries from './Error/ErrorBoundaries'
import HelmetScripts from './HelmetScripts'
import ModalsProvider from './ModalsManager/ModalsProvider'
import Notistack from './Notistack'
import ReactQueryProvider from './ReactQueryProvider'
import AppRoot from './Root'
import SplitProvider from './SplitProvider'
import ThemeProvider from './ThemeProvider'
import UserProvider from './UserContext/UserProvider'
import loadChatScript from './UserContext/loadChatScript'

const AppWrapper = () => {
  useEffect(() => {
    // Use the scripts for analytics in all the application
    loadAnalyticScripts()
    loadChatScript() // Load the chat script for user authenticated
  }, [])

  return (
    <ReactQueryProvider>
      <HelmetProvider>
        <HelmetScripts />
        <SplitProvider>
          <BrowserRouter>
            <ThemeProvider>
              <CssBaseline />
              <Notistack>
                <ErrorBoundaries>
                  <LoadingModalProvider>
                    <UserProvider>
                      <ConfirmProvider>
                        <ModalsProvider>
                          <BreadcrumbsProvider>
                            <AppRoot />
                          </BreadcrumbsProvider>
                        </ModalsProvider>
                      </ConfirmProvider>
                    </UserProvider>
                  </LoadingModalProvider>
                </ErrorBoundaries>
              </Notistack>
            </ThemeProvider>
          </BrowserRouter>
        </SplitProvider>
      </HelmetProvider>
    </ReactQueryProvider>
  )
}

export default AppWrapper
