import { parseISO } from 'date-fns'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const useTerminationFieldsStyles = makeStyles((theme) => ({
  fieldsGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldsGroupWide: {
    [theme.breakpoints.up('lg')]: {
      gridColumn: 'span 2',
    },
  },
}))

export const validationSchema = yup.object({
  id: yup.string().nullable(),
  contract_id: yup.string().nullable(),
  payroll_id: yup.string().nullable(),
  cause: yup.string().required('La causa es requerida').nullable(),
  possible_trial_period: yup.boolean().nullable(),
  trial_period: yup.boolean().nullable(),
  with_just_cause: yup.boolean().nullable(),
  hire_date: yup.date().nullable(),
  date: yup.date().nullable(),
  min_date: yup.date().nullable(),
  max_date: yup.date().nullable(),
  worked_days: yup.number().nullable(),
  holidays: yup.string().nullable(),
  accumulated_holidays: yup.number().nullable(),
  holidays_value: yup.number().nullable(),
  termination_base: yup.number().nullable(),
  holidays_base: yup.number().nullable(),
  service_bonus_base: yup.number().nullable(),
  severance_base: yup.number().nullable(),
  previous_service_bonus_base: yup.number().nullable(),
  previous_severance_base: yup.number().nullable(),
  service_bonus_days: yup.number().nullable(),
  previous_service_bonus_days: yup.number().nullable(),
  severance_days: yup.number().nullable(),
  previous_severance_days: yup.number().nullable(),
  severance_interests_days: yup.number().nullable(),
  previous_severance_interests_days: yup.number().nullable(),
  pay_rest_days: yup.boolean().nullable(),
  show_pay_service_bonus: yup.boolean().nullable(),
  pay_service_bonus: yup.boolean().nullable(),
  pay_previous_service_bonus: yup.boolean().nullable(),
  show_pay_previous_severance: yup.boolean().nullable(),
  pay_previous_severance: yup.boolean().nullable(),
  show_pay_previous_severance_interests: yup.boolean().nullable(),
  pay_previous_severance_interests: yup.boolean().nullable(),
  non_salary_income: yup.number().nullable(),
  loans: yup.number().nullable(),
  deductions: yup.number().nullable(),
  anticipated_social_benefits_value: yup.number().nullable(),
  notes: yup.string().nullable(),
  show_payroll_credit: yup.boolean().nullable(),
  payroll_credit_value: yup.number().nullable(),
  show_decree376: yup.boolean().nullable(),
  pay_decree376: yup.boolean().nullable(),
  decree376: yup.number().nullable(),
  service_bonus: yup.number().nullable(),
  previous_service_bonus: yup.number().nullable(),
  severance: yup.number().nullable(),
  previous_severance: yup.number().nullable(),
  severance_interests: yup.number().nullable(),
  previous_severance_interests: yup.number().nullable(),
  compensation_fund: yup.number().nullable(),
  sena: yup.number().nullable(),
  icbf: yup.number().nullable(),
  compensation: yup.number().nullable(),
  worker_payment: yup.number().nullable(),
  company_cost: yup.number().nullable(),
  bank_file: yup.boolean().nullable(),
})

export const getInitialValues = (terminationData = {}) => {
  const dateFields = ['hire_date', 'date', 'min_date', 'max_date']
  const values = {
    ...terminationData,
  }

  Object.entries(values).forEach(([key, value]) => {
    if (dateFields.includes(key)) {
      if (value) {
        values[key] = parseISO(value)
      }
    }
  })

  return values
}
