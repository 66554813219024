import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import NoWrap from 'components/UI/NoWrap'
import useWorker from 'components/Worker/useWorker'

import { formatCurrency } from 'utils/format'
import { wageCategories } from 'utils/worker'

import { ReactComponent as SalaryIcon } from 'assets/images/views/common/salary_2.0.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(3, 4, 2),
    position: 'relative',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    minWidth: theme.spacing(24.25),
  },
  value: {
    marginTop: 'auto !important',
  },
  img: {
    fill: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
}))

const SalaryContent = () => {
  const classes = useStyles()
  const { worker } = useWorker({ useCache: true })
  return (
    <Paper className={classes.container}>
      <div>
        <SalaryIcon className={classes.img} />
        <Typography variant="body2" className={classes.title}>
          {wageCategories[worker.wage_category]
            ? wageCategories[worker.wage_category]
            : worker.wage_category}
        </Typography>
      </div>
      <Typography variant="h5" className={classes.value}>
        <NoWrap>{formatCurrency(worker.base_salary)}</NoWrap>
      </Typography>
    </Paper>
  )
}

export default SalaryContent
