import { isOrganizer } from 'utils/auth'
import { isDev } from 'utils/general'

import { isPremiumCompany } from './freemium'
import { integrationEventsPayload } from './integrations/events/eventsHandler'
import appcues from './integrations/scripts/appcues'
import { flowxoScript } from './integrations/scripts/flowxo'
import { googleOptimizeScript } from './integrations/scripts/googleOptimize'
import { googleTagManagerScript } from './integrations/scripts/googleTagManager'
import headway from './integrations/scripts/headway'
import hubspotScript from './integrations/scripts/hubspot'
import polyfill from './integrations/scripts/polyfill'
import trackDataLayer from './integrations/trackDataLayer'

const identifyUserOnLuckyOrange = async (userId, data) => {
  window.LOQ = window.LOQ || []
  window.LOQ.push([
    'ready',
    async (LO) => {
      await LO.$internal.ready('visitor')
      LO.visitor.identify(userId, data)
    },
  ])
}

// Function to load integration data
export default async function loadIntegrationsData(company, subscription) {
  if (isOrganizer() || isDev) return

  const userId = localStorage.getItem('user_id')

  // LuckyOrange
  await identifyUserOnLuckyOrange(userId, {
    is_premium: isPremiumCompany(subscription?.status),
    subscription_status: subscription?.status,
    workers_number: subscription?.payrolls_size,
  })

  // Appcues
  await appcues.initialConfiguration(userId, company, subscription)

  // to verify, reload page
  window.Appcues?.page()
}

// Function to handle eventTrigger
export function trackEvent(...args) {
  if (isOrganizer() || isDev) return

  const eventPayload = integrationEventsPayload(args)

  // Appcues
  appcues.trackEvent(JSON.stringify(eventPayload.appcues))

  // trackDataLayer
  trackDataLayer.trackEvent(JSON.stringify(eventPayload.trackDataLayer))
}

export const chatScripts = {
  flowxo: flowxoScript,
  hubspot: hubspotScript,
}

export const commonScripts = {
  // Analitycs
  googleOptimize: googleOptimizeScript,
  googleTagManager: googleTagManagerScript,
  // Tracking Events
  appcues: appcues.script,
  trackDataLayer,
  polyfill,
  headway: headway.headwayScript,
}
