import AvistaError, {
  AVISTA_ERROR_MESSAGES,
} from 'components/Benefits/Credits/Worker/CreditSimulator/AvistaError'

import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'

import http, { getApiURL } from 'services/httpService'

export const getApplication = (workerId, creditStatus = false) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/worker_credit_informations`,
    withoutCompany: true,
    searchParams: {
      worker_payroll_credit_information: creditStatus,
    },
  })

  return http.getV2(url)
}

export const applicationMutation = (workerId, data) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/worker_credit_informations`,
    withoutCompany: true,
  })

  return http.putV2(url, data)
}

export const updateWorkerCredit = (data) => {
  const url = getApiURL({
    pathname: '/benefits_data/workers_credits',
    withoutCompany: true,
  })

  return http.putV2(url, data)
}

export const getWorkersCredits = () => {
  const url = getApiURL({
    pathname: `/benefits_data/companies/${getCompanyId()}/workers_credits`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

const handleAvistaSimulationEndpoint = async (resourceUrl, options) => {
  const promise = await fetch(resourceUrl, options)
  const response = await promise.json()

  if (promise.ok) {
    if (
      response?.status.toLowerCase() === 'success' &&
      response?.data?.message === ''
    ) {
      return response
    }
  }

  /**
   * This is to better control Avista SAS errors
   * code 001: Estamos teniendo problemas para conectarnos con AVISTA SAS
   * code 002: Se debe modificar el número de quincenas
   * code 003: El valor ingresado supera el máximo valor a prestar de {maxCreditAmount}
   */
  const avistaError = new AvistaError()

  if (response.data && response.data.message !== '') {
    const { message, lending_amount: lendingAmount } = response?.data

    if (
      message.toLowerCase().includes(AVISTA_ERROR_MESSAGES['003'].toLowerCase())
    ) {
      avistaError.message = `${
        AVISTA_ERROR_MESSAGES['003']
      } de ${formatCurrency(lendingAmount)}`
      avistaError.code = '003'
    }

    if (
      message.toLowerCase().includes(AVISTA_ERROR_MESSAGES['002'].toLowerCase())
    ) {
      avistaError.message = AVISTA_ERROR_MESSAGES['002']
      avistaError.code = '002'
    }
  }

  throw avistaError
}

export const getAvistaCreditSimulation = (data) => {
  const request = handleAvistaSimulationEndpoint(
    process.env.REACT_APP_AVISTA_CREDIT_SIMULATION_URL,
    {
      method: 'POST',
      headers: {
        apikey: process.env.REACT_APP_AVISTA_API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  )

  return request
}

export const getCreditDetail = (workerId, creditId) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/workers_credits/${creditId}`,
    withoutCompany: true,
  })

  return http.getV2(url)
}
