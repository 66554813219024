import clsx from 'clsx'

import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3, 4, 1, 4),
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    flex: '1',
    minWidth: '220px',
  },
  cardBold: {
    backgroundColor: theme.palette.primary[200],
    border: 'none',
  },
  headerTitle: {
    color: theme.palette.grey[500],
    padding: theme.spacing(1, 0),
  },
  content: {
    color: theme.palette.primary[500],
    padding: theme.spacing(1, 0),
  },
}))

const CreditResumeInfoCard = ({
  headerTitle,
  creditData,
  cardBold = false,
  hidden = false,
}) => {
  const classes = useStyles()

  return (
    <Card
      className={clsx(classes.card, {
        [classes.cardBold]: cardBold,
      })}
      hidden={hidden}
    >
      <CardHeader
        className={classes.headerTitle}
        title={<Typography variant="body2">{headerTitle}</Typography>}
      />
      <CardContent className={classes.content}>
        <Typography variant="h5">{creditData}</Typography>
      </CardContent>
    </Card>
  )
}

export default CreditResumeInfoCard
