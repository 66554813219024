import { useFormikContext } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

import FormField from 'components/UI/Formik/FormField/Index'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'

import { isArusDist } from 'utils/auth'
import { booleanLookupOptions } from 'utils/table'
import { payrollFrequenciesOptions, paymentMethodsOptions } from 'utils/company'

import messages from 'messages/company_form'

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    maxWidth: '700px',
  },
  twoColumnsFields: {
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
      alignItems: 'end',
    },
  },
  bigField: {
    gridColumn: '1 / 3',
  },
  columnEnd: {
    gridColumnEnd: '2',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

const CompanyFormFieldsPaymentPreferences = ({
  ssOperators,
  riskProviders,
}) => {
  const classes = useStyles()
  const form = useFormikContext()

  const { values } = form
  const companyPaymentMethodsOptions = paymentMethodsOptions.filter(
    (paymentOption) => paymentOption.value !== 'check'
  )

  return (
    <div className={classes.paymentContainer}>
      <div className={classes.twoColumnsFields}>
        <FormField
          name="payroll_frequency"
          label="Frecuencia de Pago"
          variant="select"
          options={payrollFrequenciesOptions}
        />
        <FormField
          name="payment_method"
          label="Medio de pago"
          variant="select"
          options={companyPaymentMethodsOptions}
          formControlClassName={classes.columnEnd}
        />
        {values.payment_method === 'wire_transfer' && (
          <>
            <BankField label="Banco del cual realizarás los pagos" />
            <AccountTypeField />
            <AccountNumberField tooltipContent={messages.account_number} />
          </>
        )}
      </div>
      <Typography variant="h6" color="primary" className={classes.title}>
        Configura la seguridad social
      </Typography>
      <div className={classes.twoColumnsFields}>
        {!isArusDist() && (
          <FormField
            name="ss_operator"
            label="Operador de pago PILA"
            variant="autocomplete"
            options={ssOperators}
          />
        )}
        <FormField
          name="risk_provider"
          label="Administradora de riesgos ARL"
          variant="autocomplete"
          options={riskProviders}
        />
        <FormField
          name="law_1607"
          label="Aportante exonerado de pago - Ley 1607 de 2012"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_1607}
          formControlClassName={classes.bigField}
          row
        />
        <FormField
          name="law_590"
          label="Aplica para la Ley 590 de 2000"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_590}
          onChange={({ target: { value } }) => {
            let newValue
            if (value === 'true') newValue = true
            else if (value === 'false') newValue = false
            else newValue = value

            form.setFieldValue('law_590', newValue)
            if (newValue === true && values.law_1429 === true) {
              form.setFieldValue('law_1429', false)
            }
          }}
          row
        />
        <FormField
          name="constitution_date"
          label="Fecha de Constitución"
          variant="datepicker"
          maxDate={new Date()}
          tooltipContent={messages.constitution_date}
        />
        <FormField
          name="law_1429"
          label="Aplica para la Ley 1429 de 2010"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_1429}
          formControlClassName={classes.bigField}
          onChange={({ target: { value } }) => {
            let newValue
            if (value === 'true') newValue = true
            else if (value === 'false') newValue = false
            else newValue = value

            form.setFieldValue('law_1429', newValue)
            if (newValue === true && values.law_590 === true) {
              form.setFieldValue('law_590', false)
            }
          }}
          row
        />
        <FormField
          name="decree_558"
          label="Aplica para Decreto 558 de 2020"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.decree_558}
          formControlClassName={classes.bigField}
          row
        />
      </div>
    </div>
  )
}

export default CompanyFormFieldsPaymentPreferences
