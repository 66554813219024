import { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useModals from 'components/App/ModalsManager/useModals'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getAllAffiliations } from 'services/affiliations/affiliations'

import addPersonLogo from 'assets/images/views/worker/add_person.png'

import { WORKER_AFFILIATIONS_NEW, WORKER_INDEX } from 'config/routes'

import AffiliationRequirements from './AffiliationRequirements'
import DownloadDocuments from './DownloadDocuments'
import Toolbar from './Toolbar/Index'
import { getActions, getColumns, getEmptyStateTitle } from './helpers'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
  approved: {
    backgroundColor: theme.palette.success.light,
    '& .MuiChip-label': {
      color: theme.palette.success.dark,
      fontWeight: 500,
    },
  },
  rejected: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    '& .MuiChip-label': {
      color: 'rgba(255, 0, 0, 1)',
      fontWeight: 500,
    },
  },
  pending_info: {
    backgroundColor: theme.palette.info.light,
    '& .MuiChip-label': {
      color: theme.palette.info.dark,
      fontWeight: 500,
    },
  },
  waiting_response: {
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      color: theme.palette.warning.dark,
      fontWeight: 500,
    },
  },
}))

export const AffiliationsContext = createContext()
AffiliationsContext.displayName = 'AffiliationsContext'

const SSAffiliations = () => {
  const classes = useStyles()
  const history = useHistory()
  const modals = useModals()
  const [filter, setFilter] = useState('')
  const [canAddAffiliation, setCanAddAffiliation] = useState(false)

  const fetchAffiliations = (pageIndex, search) => {
    return {
      queryKey: ['getAllAffiliations', pageIndex, search, filter],
      queryFunction: () =>
        getAllAffiliations({
          searchParams: { page: pageIndex + 1, search, filter },
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setCanAddAffiliation(data?.can_add_affiliation)

          if (!data?.can_add_affiliation) {
            modals.openModal({
              id: 'affiliationRequirements',
              content: (
                <AffiliationRequirements
                  onClose={() => modals.closeAll()}
                  onRedirect={() => modals.closeAll()}
                />
              ),
              modalProps: {
                header: 'Credenciales y documentación requeridas',
                footer: null,
                hideCloseButton: true,
                dialogProps: {
                  fullWidth: true,
                  maxWidth: 'sm',
                },
              },
            })
          }
        },
      },
    }
  }

  const value = {
    filter,
    setFilter,
  }

  const openAffiliation = (workerId) => {
    const state = {
      workerId,
    }

    history.push({
      pathname: WORKER_AFFILIATIONS_NEW(),
      state,
    })
  }

  const openDocumentsModal = (documents) => {
    modals.openModal({
      id: 'downloadDocuments',
      content: <DownloadDocuments documents={documents} />,
      modalProps: {
        header: 'Descargar documentos',
        onOk: () => modals.closeAll(),
        okText: 'Cerrar',
        hideCancelButton: true,
        dialogProps: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
    })
  }

  const actions = getActions(openAffiliation, canAddAffiliation)
  const columns = getColumns(
    classes,
    filter,
    openDocumentsModal,
    openAffiliation
  )

  return (
    <Page
      documentTitle="Afiliaciones a Seguridad Social"
      header={
        <div className={classes.header}>
          <Typography variant="h5">Afiliaciones a Seguridad Social</Typography>
          <Link to={WORKER_INDEX()}>Regresar a personas</Link>
        </div>
      }
    >
      <Typography variant="body1" className={classes.description}>
        Acá puedes gestionar las afiliaciones a seguridad social, pensión y
        cesantías de tus compañeros.
      </Typography>
      <AffiliationsContext.Provider value={value}>
        <Table
          data={fetchAffiliations}
          columns={columns}
          options={{
            alignActionsCell: 'center',
            customQueryFetch: 'affiliations',
            emptyState: {
              title: getEmptyStateTitle(filter),
              hideDescription: true,
              callToAction: (
                <Button
                  onClick={() => openAffiliation()}
                  disabled={!canAddAffiliation}
                >
                  Afiliar persona
                </Button>
              ),
              logo: addPersonLogo,
            },
          }}
          components={{
            Toolbar,
          }}
          actions={actions}
        />
      </AffiliationsContext.Provider>
    </Page>
  )
}

export default SSAffiliations
