import { useState } from 'react'

import ConfirmContext from './ConfirmContext'
import ConfirmDialog from './ConfirmDialog'

const initialDefaultOptions = {
  title: '¿Estás seguro?',
  description: '',
  type: 'confirm',
  okText: 'Ok',
  cancelText: 'Cancelar',
  dialogProps: {},
  okButtonProps: {},
  cancelButtonProps: {},
  onOk: null,
  onCancel: null,
  confirmCheckbox: false,
  hideCancelButton: false,
}

const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({
    ...initialDefaultOptions,
    ...defaultOptions,
  })
  const [open, setOpen] = useState(false)
  const [okClicked, setOkClicked] = useState(false)

  const { onOk, onCancel } = options

  const confirm = (customOptions = {}) => {
    setOptions({
      ...initialDefaultOptions,
      ...defaultOptions,
      ...customOptions,
    })
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }

    handleClose()
  }

  const handleOk = async () => {
    setOkClicked(true)

    if (onOk) {
      await onOk()
      handleClose()
      setOkClicked(false)
    } else {
      handleClose()
      setOkClicked(false)
    }
  }

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmDialog
        open={open}
        options={options}
        onCancel={handleCancel}
        onOk={handleOk}
        okClicked={okClicked}
      />
    </>
  )
}

export default ConfirmProvider
