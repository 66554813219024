import { leadingZerosOrNotDigits } from './regex'

/**
 * Parse and format a number to string.
 *
 * Example (default): 123456.78 => '123.456,78'
 * @param {Number} number
 * @param {String} thousandSeparator
 * @param {String} decimalSeparator
 */
export const formatNumber = (
  number,
  thousandSeparator = '.',
  decimalSeparator = ','
) => {
  if (number == null) return null

  if (thousandSeparator === null && decimalSeparator === null)
    return number.toString()

  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
  return parts.join(decimalSeparator)
}

/**
 * Set number of decimal and format a number
 *
 * @param {Number} value
 */
export const formatNumberWithDecimal = (number, decimalAmount) => {
  const numberComplete = Number(number).toFixed(decimalAmount)

  return formatNumber(parseFloat(numberComplete))
}

/**
 * Add dolar sign as prefix and format the number
 *
 * @param {Number} value
 */
export const formatCurrency = (value) => {
  return `$ ${formatNumberWithDecimal(value, 0)}`
}

/**
 * Returns the number that doesn't exceed the limits between min and max.
 *
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 */
export const calculateNumberBetwenLimits = (value, min, max) => {
  let newValue = value
  if (value < min) {
    newValue = min
  } else if (value > max) {
    newValue = max
  }

  return newValue
}

export function generateBasicNumberFormatter({
  prefix,
  thousandSeparator,
  decimalSeparator,
} = {}) {
  return (value) => {
    if (value == null) return ''

    let formatted = ''

    if (prefix != null) formatted += prefix

    formatted += formatNumber(value, thousandSeparator, decimalSeparator)

    return formatted
  }
}

export function generateBasicNumberParser(
  { regexToRemove = leadingZerosOrNotDigits, max, convertToNumber = true } = {
    regexToRemove: leadingZerosOrNotDigits,
    convertToNumber: true,
  }
) {
  return (value) => {
    let parsed

    if (regexToRemove) parsed = value.replace(regexToRemove, '')

    if (max && parsed.length > max) parsed = parsed.substring(0, max)

    if (convertToNumber) parsed *= 1 // fastest convertion to number

    return parsed
  }
}
/**
 * Parse and format an ID number.
 *
 * Example: '012345678' => '012.345.678'
 * @param {string} number
 */

export function formatIdNumber(number) {
  if (!number) return undefined

  return number.split(/(?=(?:...)*$)/).join('.')
}

/**
 *
 * @param {String} value
 * @param {Integer} decimalAmount
 * @returns value initially as string, converted to Number
 * Example: '4000000.0' => '4000000' (returned value's typeof is number)
 */
export const formatNumberStringToNumber = (value, decimalAmount = 0) => {
  return parseFloat(Number(value).toFixed(decimalAmount))
}

/**
 *
 * @param {String} word - word to be singularized
 * @param {Boolean} singular - if true, word will be singularized
 * @returns singularized word
 */
export const getWordSingular = (word, singular = false) => {
  if (singular) return word.substr(0, word.length - 1)

  return word
}
