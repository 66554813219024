import { useState } from 'react'
import { useFormikContext } from 'formik'
import cardValidator from 'card-validator'

import { makeStyles } from '@material-ui/core/styles'
import PaymentIcon from '@material-ui/icons/Payment'

import FormField from 'components/UI/Formik/FormField/Index'

import visa from 'assets/images/views/subscription/visa.svg'
import amex from 'assets/images/views/subscription/amex.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import dinersClub from 'assets/images/views/subscription/dinersclub.svg'

const useStyles = makeStyles((theme) => ({
  cardNumber: {
    fontFamily: 'Roboto Mono, monospace',
  },
  creditCardIcon: {
    color: theme.palette.primary.light,
    fontSize: 35,
  },
}))

const creditCardLogos = {
  'american-express': amex,
  'diners-club': dinersClub,
  mastercard,
  visa,
}

const CreditCardNumberField = () => {
  const classes = useStyles()
  const { values, setValues } = useFormikContext()
  const [cardType, setCardType] = useState()

  const handleValueChange = ({ value }) => {
    const ccType = cardValidator.number(value).card?.type

    setCardType(ccType)

    setValues({
      ...values,
      card_number: value,
    })
  }

  return (
    <FormField
      name="card_number"
      label="Número de la tarjeta"
      variant="number"
      isNumericString
      format="#### #### #### ####"
      onValueChange={handleValueChange}
      inputProps={{ autoComplete: 'cc-number' }}
      className={classes.cardNumber}
      endAdornment={
        cardType && creditCardLogos[cardType] ? (
          <img
            height={25}
            width={50}
            src={creditCardLogos[cardType]}
            alt={`${cardType} logo`}
          />
        ) : (
          <PaymentIcon className={classes.creditCardIcon} />
        )
      }
    />
  )
}

export default CreditCardNumberField
