import { Link as RouterLink } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'

import { DASHBOARD } from 'config/routes'

import ContainerDetail from './ContainerDetail'
import {
  getContentResponse,
  getContentSummary,
  getActionButtons,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  rootContent: {
    marginTop: theme.spacing(3.75),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
    '& h3': {
      wordBreak: 'break-word',
    },
  },
  sectionSummary: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
    alignItems: 'center',
    justifyItems: 'center',
    [theme.breakpoints.down(1235)]: {
      gridTemplateColumns: '1fr',
    },
  },
  image: {
    width: 350,
    [theme.breakpoints.down(1373)]: {
      width: 350,
    },
    [theme.breakpoints.down(1235)]: {
      display: 'none',
    },
  },
  containerButtons: {
    marginTop: theme.spacing(9.75),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    gridColumn: '1 / -1',
    '& a:last-child': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  },
}))

const PaymentSummary = ({ summary }) => {
  const classes = useStyles()

  const {
    response,
    paymentMethodInfo,
    activeUntil,
    value,
    type,
    name,
    plan,
  } = summary

  const paymentStatus = response?.state?.payment_status

  const contentByStatus = getContentResponse({
    value,
    cardBrand: paymentMethodInfo.card_brand,
    cardLastNumber: paymentMethodInfo.card_last_numbers,
    subscriptionType: type,
    activeUntil,
    state: paymentStatus,
  })

  const summaryByStatus = getContentSummary({
    state: paymentStatus,
    name,
    plan,
  })

  const buttonsAction = getActionButtons(response?.state)
  return (
    <Paper className={classes.rootContent}>
      <section>
        <Typography variant="h3" gutterBottom>
          {summaryByStatus.heading}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {summaryByStatus.description}
        </Typography>
      </section>
      <section className={classes.sectionSummary}>
        <img
          src={summaryByStatus.image}
          alt={`Summary response ${response.state}`}
          className={classes.image}
        />
        <div>
          <ContainerDetail detail={contentByStatus.subscription} />
          {contentByStatus.paymentDate ? (
            <ContainerDetail detail={contentByStatus.paymentDate} />
          ) : null}
          <ContainerDetail detail={contentByStatus.electronicReceipt} />
        </div>
        <div className={classes.containerButtons}>
          {paymentStatus !== 'DECLINED' ? (
            <Button component={RouterLink} to={DASHBOARD} variant="outlined">
              Ir a Nominapp
            </Button>
          ) : null}
          <Button
            component={paymentStatus === 'DECLINED' ? RouterLink : Link}
            to={buttonsAction.primary.action}
            href={buttonsAction.primary.href}
            target={buttonsAction.primary.target}
          >
            {buttonsAction.primary.text}
          </Button>
        </div>
      </section>
    </Paper>
  )
}

export default PaymentSummary
