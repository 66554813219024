import cardValidator from 'card-validator'
import * as yup from 'yup'

import Box from '@material-ui/core/Box'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

import { isAccountant, isAdmin, isOrganizer } from 'utils/auth'
import { accountTypesOptions } from 'utils/company'
import { yupLocaleES } from 'utils/form'

import SubscriptionAutomaticDebitFields from './AutomaticDebitFields'
import SubscriptionCreditCardFields from './CreditCardFields'
import SubscriptionPSEFields from './PSEFields'

yup.setLocale(yupLocaleES)

export const paymentTypeData = {
  automatic_debit: {
    fields: <SubscriptionAutomaticDebitFields />,
  },
  credit_card: {
    fields: <SubscriptionCreditCardFields />,
  },
  pse: {
    fields: <SubscriptionPSEFields />,
  },
  bank_transfer: {
    fields: <SubscriptionPSEFields />,
  },
}

export const dirtyPaymentValues = (values, paymentMethod, plan) => {
  let dirtyValues

  if (paymentMethod === 'credit_card') {
    dirtyValues = {
      card_number: values.card_number,
      security_code: values.security_code,
      payer_name: values.payer_name,
      payment_type: plan.type,
    }

    const { expiration_date: expirationDate } = values

    dirtyValues.expiration_date =
      // MMYYYY => YYYY/MM
      `${expirationDate.substring(2)}/${expirationDate.substring(0, 2)}`
  }

  if (paymentMethod === 'pse') {
    dirtyValues = {
      account_type: values.account_type?.value,
      account_number: values.account_number,
      bank: values.bank?.id,
      client_type: values.client_type,
      id_number: values.id_number,
      payment_type: plan.type,
    }
  }

  if (paymentMethod === 'automatic_debit') {
    dirtyValues = {
      id_number: values.id_number,
      account_type: values.account_type?.value,
      account_number: values.account_number,
      bank_id: values.bank?.id,
    }
  }

  dirtyValues.plan_id = plan.id
  dirtyValues.payment_type = plan.payment_type

  if (plan.paid_extra_workers)
    dirtyValues.paid_extra_workers = plan.paid_extra_workers

  return dirtyValues
}

export const getSchemaValidation = (paymentMethod) => {
  let schemaValidation

  if (paymentMethod === 'credit_card') {
    schemaValidation = yup.object({
      payer_name: yup.string().trim().required(),
      card_number: yup
        .number()
        .required()
        .test(
          'test-number',
          'Número de tarjeta de crédito inválido',
          (value) => cardValidator.number(value).isValid
        ),
      expiration_date: yup
        .string()
        .required()
        .test('test-expiration-year', 'Debe ser una fecha válida', (value) => {
          return cardValidator.expirationDate(value).isValid
        }),
      security_code: yup
        .string()
        .required()
        // For some reason is necessary to declare thdi function
        // eslint-disable-next-line func-names
        .test('test-number', 'Código de seguridad inválido', function (value) {
          const type = cardValidator.number(this.parent.card_number).card?.type

          return cardValidator.cvv(
            `${value}`,
            type === 'american-express' ? 4 : 3
          ).isValid
        }),
    })
  } else {
    schemaValidation = yup.object({
      document_type: yup.string().nullable().required(),
      account_type: yup.object().nullable().required(),
      account_number: yup.number().nullable().required(),
      client_type: yup.string().required(),
      bank: yup.object().nullable().required(),
    })
  }

  return schemaValidation
}

export function getInitialValues(company) {
  const initialValues = {
    document_type: 'cc',
    client_type: 'N',
  }

  if (company.bank) initialValues.bank = company.bank

  initialValues.account_type = accountTypesOptions.find(
    (opt) => opt.value === (company.account_type || 'savings_account')
  )

  return initialValues
}

export const getTabsData = (paymentCategory, extraWorkersPayment) => {
  const iconStyles = {
    marginRight: '5px',
    marginBottom: '4px', // To be align with text
  }

  const tabs = [
    {
      key: 'credit_card',
      label: (
        <Box display="flex" alignItems="center">
          <CreditCardIcon fontSize="small" style={iconStyles} />
          Tarjeta de crédito
        </Box>
      ),
    },
    {
      key: 'pse',
      label: (
        <Box display="flex" alignItems="center">
          <LocalAtmIcon fontSize="small" style={iconStyles} />
          PSE
        </Box>
      ),
    },
  ]

  if (!extraWorkersPayment) {
    if (
      isOrganizer() ||
      ((isAdmin() || isAccountant()) && paymentCategory === 'automatic_debit')
    )
      tabs.unshift({
        key: 'automatic_debit',
        label: (
          <Box display="flex" alignItems="center">
            <MonetizationOnIcon fontSize="small" style={iconStyles} />
            Débito automático
          </Box>
        ),
      })
  }

  return tabs
}
