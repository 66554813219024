import clsx from 'clsx'

import Chip from '@material-ui/core/Chip'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import SendIcon from '@material-ui/icons/Send'

import Link from 'components/UI/MaterialUI/Link'

import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

import CellOtherIncomes from './Columns/CellOtherIncomes'

const getEmployeesStatus = (status, classes) => {
  const statusOptions = {
    issued: { title: 'Emitido', className: classes.issuedChip },
    rejected: { title: 'Rechazado', className: classes.rejectedChip },
    processing: { title: 'En proceso', className: classes.processingChip },
    pending: { title: 'Por emitir', className: classes.pendingChip },
    modified: { title: 'Modificado', className: classes.modifiedChip },
    canceled: { title: 'Anulado', className: classes.canceledChip },
  }
  return statusOptions[status]
}

export const getColumnsData = (classes) => {
  return [
    {
      Header: 'Persona',
      accessor: 'worker.name',
      Cell: ({ row }) => (
        <Link to={routes.WORKER_SHOW(row.original.worker.id)}>
          {row.original.worker.name}
        </Link>
      ),
    },
    {
      Header: 'Fecha',
      accessor: 'electronic_period',
      Cell: ({ row }) =>
        formatDateRange(row.original.initial_day, row.original.end_day),
    },
    {
      Header: 'Ingresos',
      accessor: 'incomes',
      Cell: ({ row }) => formatCurrency(row.original.incomes),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Otros ingresos',
      accessor: 'bonus_incomes',
      Cell: ({ row }) => (
        <CellOtherIncomes
          index={row.index}
          electronicPayrollId={row.original.id}
          bonusIncomes={row.original.bonus_incomes}
        />
      ),
      alignHeader: 'right',
      alignCell: 'right',
      customWidth: '180px',
    },
    {
      Header: 'Deducciones',
      accessor: 'deductions',
      Cell: ({ row }) => formatCurrency(row.original.deductions),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Total pagado',
      accessor: 'payment',
      Cell: ({ row }) => formatCurrency(row.original.payment),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => {
        const status = getEmployeesStatus(row.original.status, classes)
        return (
          <Chip
            label={status.title}
            className={clsx(classes.chip, status.className)}
          />
        )
      },
      alignHeader: 'center',
      alignCell: 'center',
    },
    {
      accessor: 'worker.id_number',
    },
  ]
}

export const getActionsData = (
  handleSendElectronicPayroll,
  handleDownloadFile,
  handleShowErrors,
  maintenance
) => {
  return [
    (rowData) => ({
      id: 'to_issued',
      tooltip: 'Emitir',
      icon: SendIcon,
      disabled: !rowData.options.to_issue || maintenance,
      onClick: () => handleSendElectronicPayroll(rowData),
    }),
    (rowData) => ({
      id: 'cancelable',
      tooltip: 'Anular',
      icon: DeleteIcon,
      disabled: !rowData.options.cancelable,
      // TODO: add cancel actions
      // onClick: (rowData) => console.log('action', rowData),
    }),
    (rowData) => ({
      id: 'download_files',
      tooltip: 'Descargar',
      icon: CloudDownloadIcon,
      disabled: !rowData.options.download_files,
      onClick: () => handleDownloadFile(rowData),
    }),
    (rowData) => ({
      id: 'show_errors',
      tooltip: 'Ver detalle de errores',
      icon: CancelOutlinedIcon,
      disabled: !rowData.options.show_errors,
      onClick: () => handleShowErrors(rowData.error_messages),
    }),
  ]
}

export const statusFilter = [
  { name: 'Todos', status: 'total' },
  { name: 'Aceptados', status: 'issued' },
  {
    name: 'Rechazados',
    status: 'rejected',
  },
  { name: 'Procesando', status: 'processing' },
  { name: 'Pendientes', status: 'pending' },
  { name: 'Anuladas', status: 'canceled' },
  { name: 'Modificados', status: 'modified' },
]

export const getDataFilter = (data, status) => {
  const dataFilter =
    status === 'total'
      ? data
      : data.filter((electronicPayroll) => electronicPayroll.status === status)
  return dataFilter
}
