import { useEffect, useState } from 'react'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { isWorker } from 'utils/auth'
import { capitalize } from 'utils/general'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'

const initialState = {
  open: false,
  payroll: null,
  payslipTypes: [],
  payrollRanges: [],
  file_format: 'pdf',
  isSendEmail: false,
}

/**
 * Used in conjunction with PayslipModal
 * @param {Object} period If not specified, the modal will only works for payroll payslips
 */
const usePayslipModal = (period) => {
  const [state, setState] = useState({ ...initialState, period })
  const { showSuccessMessage } = useNotifications()
  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()
  const downloadURI = useDownloadURI()
  const confirm = useConfirm()
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  useEffect(() => {
    setState({ ...initialState, period })
  }, [period])

  const handleDownloadPayslip = async ({
    // Temporal disable, is more complicated change that
    // eslint-disable-next-line no-shadow
    period,
    payroll,
    payslip_type: payslipType,
    payroll_range: payrollRange,
    file_format: fileFormat = 'pdf',
    isSendEmail = false,
  }) => {
    // toggle ON global loading modal
    let response
    try {
      if (isSendEmail) {
        if (payroll) {
          response = await payrollMutation.mutateAsync({
            mutationMethod: 'SEND_PAYSLIP',
            payrollId: payroll.id,
            payslipType,
            payrollRange,
          })
        } else {
          // from period
          response = await fileService.sendPeriodPayslips(period.id, {
            payslip_type: payslipType,
          })
        }

        const { message } = response
        showSuccessMessage(message)
        trackEvent(integrationEvent.PAYSLIPS_SEND_EMAIL)
      } else if (payroll) {
        if (isWorker()) {
          response = await workerMutation.mutateAsync({
            mutationMethod: 'GET',
            payrollId: payroll.id,
            payslip_type: payslipType,
          })
        } else {
          response = await payrollMutation.mutateAsync({
            mutationMethod: 'GET_PAYSLIP',
            payrollId: payroll.id,
            payslipType,
            payrollRange,
          })
        }

        const { file } = response
        if (file) downloadURI(file)

        trackEvent(integrationEvent.DOWNLOAD_FILE, {
          name: 'individualPayslip',
          type: fileFormat.toUpperCase(),
        })
      } else {
        // eslint-disable-next-line consistent-return
        const serviceCall = async () => {
          try {
            const periodPayslips = await fileService.getPeriodPayslips(
              period.id,
              {
                file_format: fileFormat,
                payslip_type: payslipType,
                payroll_range: payrollRange,
              }
            )
            return periodPayslips
          } catch (error) {
            handleError(error)
          }
          // No return statament
        }

        const callback = () => {
          const format = capitalize(fileFormat)

          trackEvent(integrationEvent.DOWNLOAD_FILE, {
            name: `payslip${format}`,
            type: fileFormat.toUpperCase(),
          })
        }

        enqueueDownload({
          name: 'colillas de pago',
          fileCode: 'period_payslips',
          serviceCall,
          callback,
          namePlural: true,
          nameGender: 'female',
        })
      }
      // toggle OFF global loading modal
    } catch (error) {
      handleError(error)
    }
  }

  /**
   * Required options:
   *
   * For period payslip: Do not specify payroll object and file_format is optional, default is 'pdf'
   *
   * For payroll payslip: Just specify payroll option and payslipTypes is optional
   *
   * @param {Object} options
   */
  const openPayslipModal = async ({
    payroll,
    payslipTypes,
    file_format: fileFormat = 'pdf',
    isSendEmail,
  } = {}) => {
    // toggle ON global loading modal
    let newState

    try {
      if (payroll) {
        newState = {
          ...state,
          payroll,
        }

        if (payslipTypes) {
          newState.payslipTypes = payslipTypes
        } else {
          await payrollMutation.mutateAsync(
            {
              mutationMethod: 'GET_PAYSLIP_OPTIONS',
              payrollId: payroll.id,
            },
            {
              onSuccess: ({ data }) => {
                newState.payslipTypes = data.payslip_type
                newState.payrollRanges = data.payroll_range
              },
            }
          )
        }
      } else if (period) {
        // from period
        const {
          payslip_type: payslipType,
        } = await fileService.getPayslipOptions(period.id)

        newState = {
          ...state,
          payslipTypes: payslipType,
          file_format: fileFormat,
        }
      } else {
        throw new Error('No payroll or period data specified for PayslipsModal')
      }

      if (
        newState.payrollRanges.length === 0 &&
        newState.payslipTypes.length === 0
      ) {
        const downloadPayslipOptions = {
          payroll,
          period,
          file_format: fileFormat,
          isSendEmail,
        }

        if (isSendEmail) {
          confirm({
            type: 'warning',
            title: `Enviar colilla${!payroll ? 's a todas las personas' : ''}`,
            description: `${
              !payroll ? 'A cada persona de tu nómina le' : 'Le'
            } llegará la colilla al correo electrónico que hayas registrado en su perfil.`,
            okText: `Enviar colilla${!payroll ? 's' : ''}`,
            onOk: async () => {
              const download = await handleDownloadPayslip(
                downloadPayslipOptions
              )
              return download
            },
          })
        } else {
          await handleDownloadPayslip(downloadPayslipOptions)
        }

        return
      }

      newState.isSendEmail = isSendEmail
      newState.open = true

      setState(newState)
    } catch (error) {
      handleError(error)
    }
    // toggle OFF global loading modal
  }

  const closePayslipModal = () => {
    setState({ ...initialState, period })
  }

  return {
    payslipModalState: state,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  }
}

export default usePayslipModal
