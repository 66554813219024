import Typography from '@material-ui/core/Typography'

import Page from 'components/UI/Page/Page'

import IndexTable from './DataTable/Table'

const NoveltiesRequestIndex = () => {
  return (
    <Page
      header="Historial de solicitudes de tiempo fuera"
      documentTitle="Historial de solicitudes de tiempo fuera"
    >
      <Typography>
        Acá puedes gestionar todas las solicitudes de tiempo fuera de tus
        compañeros.
      </Typography>

      <IndexTable />
    </Page>
  )
}

export default NoveltiesRequestIndex
