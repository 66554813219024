// Temporal disable

/* eslint-disable react/require-default-props */
import clsx from 'clsx'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import FreeTrialCounter from 'components/App/Premium/FreeTrialCounter'
import AppSubscriptionStatusAlert from 'components/App/SubscriptionStatusAlert'
import { useUser } from 'components/App/UserContext/useUser'
import Loading from 'components/UI/Loading/Loading'

import auth from 'utils/auth'
import { getDiferenceOfDays } from 'utils/dateTime'

import * as routes from 'config/routes'

import LoadingModal from '../Loading/LoadingModal'

const useStyles = makeStyles((theme) => ({
  flexRoot: {
    flexGrow: 1,
    overflowY: 'auto',
    '&::after': {
      // workarround to fix problem related to overflow with padding
      content: '""',
      display: 'block',
      height: 32,
    },
  },
  flexHeader: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
  },
  flexContent: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
  },
  gridRoot: {
    display: 'grid',
    gridTemplateRows: 'minmax(6.75rem, max-content) 1fr',
    columnGap: theme.spacing(3),
    padding: theme.spacing(0, 2),
    '&::after': {
      gridColumn: '1 / -1',
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  gridHeader: {
    gridColumn: '1 / -1',
  },
  gridHeaderContainer: {
    display: 'grid',
    gridColumn: '1 / -1',
    alignItems: 'center',
    columnGap: theme.spacing(3),
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
      columnGap: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
  },
  gridContent: {
    display: 'grid',
    gridColumn: '1 / -1',
    columnGap: theme.spacing(3),
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
      columnGap: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
  },
}))

const Page = ({
  header,
  documentTitle,
  isLoading,
  isLoadingWithModal,
  className,
  contentClassName,
  headerClassName,
  children,
  grid,
}) => {
  const classes = useStyles()
  const { subscription } = useUser()
  const location = useLocation()
  const { origin: isFromSignUp } = queryString.parse(location.search)
  const isInSubscriptionView = location.pathname === routes.SUBSCRIPTION()
  const hasTwoMonthsAway = getDiferenceOfDays(subscription?.active_until) <= 60

  const showFreeTrialSnackbar =
    !isFromSignUp &&
    ['free_trial', 'free_trial_terminated', 'expired'].includes(
      subscription?.status
    ) &&
    subscription?.payment_status !== 'pending' &&
    subscription?.payment_category !== 'accountant_payment' &&
    !auth.isAlegraClaroDist() &&
    !auth.isBancolombiaDist() &&
    !auth.isInnpulsaDist() &&
    !auth.isOrganizer()

  const showSubsidizedPlanToInnpulsa =
    subscription?.payment_category === null &&
    auth.isInnpulsaDist() &&
    hasTwoMonthsAway &&
    !isInSubscriptionView

  const isHeaderString = typeof header === 'string'

  const isOnboardingView = location.pathname.includes('/onboarding')

  const hasFreeTrial =
    subscription.status === 'premium_trial' &&
    !isInSubscriptionView &&
    !isOnboardingView

  useEffect(() => {
    if (documentTitle) document.title = `${documentTitle} - Nominapp`
    else if (isHeaderString) document.title = `${header} - Nominapp`
    else document.title = 'Nominapp'
  }, [documentTitle, header, isHeaderString])

  return (
    <section
      className={clsx(
        classes.flexRoot,
        {
          [classes.gridRoot]: grid,
        },
        className
      )}
    >
      {hasFreeTrial && <FreeTrialCounter />}
      {showFreeTrialSnackbar ||
        (showSubsidizedPlanToInnpulsa && (
          <AppSubscriptionStatusAlert
            isInSubscriptionView={isInSubscriptionView}
          />
        ))}
      {header && (
        <section
          className={clsx(
            {
              [classes.flexHeader]: !grid,
              [classes.gridHeaderContainer]: grid,
            },
            headerClassName
          )}
        >
          {isHeaderString ? (
            <Typography variant="h5" className={classes.gridHeader}>
              {header}
            </Typography>
          ) : (
            header
          )}
        </section>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className={clsx(
            {
              [classes.flexContent]: !grid,
              [classes.gridContent]: grid,
            },
            contentClassName
          )}
        >
          {children}
        </div>
      )}
      {isLoadingWithModal && <LoadingModal />}
    </section>
  )
}

Page.propTypes = {
  header: PropTypes.node,
  documentTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingWithModal: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.node,
  grid: PropTypes.bool,
}

export default Page
