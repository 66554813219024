import { useFormikContext } from 'formik'
import { useState } from 'react'

import Link from '@material-ui/core/Link'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import CustomField from 'components/UI/Formik/CustomField'

import { wait } from 'utils/general'
import { getMinimumSalary, getYearWithholdingTax } from 'utils/worker'

import messages from 'messages/wage'

const BaseSalaryField = ({ contractCategory, isEditing = false, ...props }) => {
  const confirm = useConfirm()
  const form = useFormikContext()

  const { values } = form
  const [baseSalary, setBaseSalary] = useState(values.base_salary)

  const handleValueChange = ({ floatValue: baseSalaryToChange }) => {
    const twoSMMLV = getMinimumSalary({
      multiplier: 2,
    })

    let transportSubsidy

    if (contractCategory !== 'contractor') {
      if (baseSalaryToChange <= twoSMMLV) {
        if (values.base_salary <= twoSMMLV) {
          transportSubsidy = values.transport_subsidy
        } else {
          transportSubsidy = true
        }
      } else {
        transportSubsidy = false
      }
    } else {
      transportSubsidy = null // N.A
    }

    form.setValues({
      ...values,
      base_salary: baseSalaryToChange,
      transport_subsidy: transportSubsidy,
    })
  }

  const handleFocus = () => setBaseSalary(values.base_salary)

  const handleMinimumSalaryLimit = (smlv) => {
    if (
      values.initial_day &&
      contractCategory !== 'contractor' &&
      values.base_salary < smlv
    ) {
      wait(200).then(() =>
        confirm({
          type: 'warning',
          okText: 'Sí',
          cancelText: 'No',
          title:
            '¿Estas seguro de que esta persona tendrá un salario inferior al salario mínimo?',
          onCancel: () => {
            form.setFieldValue('base_salary', smlv)
          },
        })
      )
    }
  }

  const handleWithholdingTaxLimit = () => {
    confirm({
      type: 'info',
      okText: 'Entendido',
      hideCancelButton: true,
      title: 'Activa el cálculo automático de la retención en la fuente',
      description: (
        <>
          Este empleado está sujeto a retención en la fuente por concepto de
          salarios dado que su salario supera el tope legal. En el perfil de la
          Persona, en la opción de Conceptos Recurrentes puedes activar el
          cálculo automático de este valor bajo el procedimiento 1. Conoce más{' '}
          <Link
            href="https://ayuda.nominapp.com/help/como-realizar-la-retencion-en-la-fuente-por-salarios-a-mi-empleado-en-nominapp?version=latest"
            target="_blank"
          >
            aquí
          </Link>
          .
        </>
      ),
    })
  }

  const handleBlur = () => {
    if (values.base_salary !== baseSalary) {
      const checkSalaryLimits = () => {
        // little delay
        wait(200).then(() => {
          if (values.initial_day && contractCategory !== 'contractor') {
            const smlv = getMinimumSalary({
              date: values.initial_day,
            })

            const withholdingTaxLimit = getYearWithholdingTax(
              typeof values.initial_day === 'string'
                ? new Date(values.initial_day?.replace(/-/g, ','))
                : values.initial_day
            )

            if (
              values.base_salary < smlv &&
              [
                'employee',
                'pensioner',
                'student_decree_055',
                'student_law_789',
              ].includes(contractCategory)
            ) {
              handleMinimumSalaryLimit(smlv)
            } else if (values.base_salary >= withholdingTaxLimit) {
              handleWithholdingTaxLimit()
            }
          }
        })
      }

      if (isEditing) {
        confirm({
          type: 'warning',
          title: 'Editar salario',
          description:
            '¿Estás seguro de querer editar este salario? Este cambio no puede deshacerse y afectará a todas las nóminas que incluyan este contrato.',
          okText: 'Sí',
          cancelText: 'No',
          onCancel: () => {
            form.setFieldValue('base_salary', baseSalary)
          },
          onOk: () => checkSalaryLimits(),
        })
      } else checkSalaryLimits()
    }
  }

  return (
    <CustomField
      name="base_salary"
      label="Valor del salario"
      component="currency"
      tooltipContent={messages.base_salary}
      onBlur={handleBlur}
      onValueChange={handleValueChange}
      onFocus={handleFocus}
      align="left"
      {...props}
    />
  )
}

export default BaseSalaryField
