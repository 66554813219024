import { makeStyles } from '@material-ui/core'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import { getWorkCenterHistory } from 'services/company/decree2012Service'

import { historyColumns } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0, 5, 0),
  },
}))

const WorkCenterHistory = ({ workCenterId }) => {
  const classes = useStyles()
  const companyId = getCompanyId()

  const fetchHistory = (pageIndex) => {
    return {
      queryKey: ['getWorkCenterHistory', companyId, workCenterId, pageIndex],
      queryFunction: () =>
        getWorkCenterHistory({ page: pageIndex + 1, workCenterId }),
    }
  }

  return (
    <div className={classes.container}>
      <Table
        columns={historyColumns}
        data={fetchHistory}
        options={{
          pagination: false,
          search: false,
          customQueryFetch: 'work_center_histories',
        }}
      />
    </div>
  )
}

export default WorkCenterHistory
