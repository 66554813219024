import { createContext, useMemo, useState } from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { isArusDist } from 'utils/auth'

import SidebarContent from './Content'

export const SidebarContext = createContext()

const drawerWidth = 60
const drawerWidthExpanded = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    // custom narrow scrollbar
    // firefox browser
    scrollbarWidth: 'thin',
    scrollbarColor: `${
      isArusDist() ? theme.palette.grey[400] : theme.palette.primary.light
    } transparent`,
    // webkit browsers
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(0.25), // half width
      background: isArusDist()
        ? theme.palette.grey[400]
        : theme.palette.primary.main,
    },
  },
  drawerPaperExpanded: {
    width: drawerWidthExpanded,
  },
  drawerMobile: {
    marginTop: theme.mobileAppbar,
    '& .MuiBackdrop-root': {
      top: theme.mobileAppbar,
    },
  },
  drawerPaperMobile: {
    background: isArusDist()
      ? theme.palette.grey[500]
      : theme.palette.primary.main,
    width: '100%',
    marginTop: theme.mobileAppbar,
    boxShadow: 'none',
  },
}))

const AppSidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const [expanded, setExpanded] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const classes = useStyles()

  const container =
    window !== undefined ? () => window.document.body : undefined

  const contextValue = useMemo(
    () => ({
      expanded,
      setUserMenuOpen,
      handleDrawerToggle,
      mobileOpen,
    }),
    [expanded, handleDrawerToggle, mobileOpen]
  )

  return (
    <SidebarContext.Provider value={contextValue}>
      <nav>
        <Hidden smUp>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              root: classes.drawerMobile,
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SidebarContent mobileOpen={mobileOpen} />
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            variant="permanent"
            classes={{
              root: classes.drawer,
              paper: clsx(classes.drawerPaper, {
                [classes.drawerPaperExpanded]: expanded,
              }),
            }}
            anchor="left"
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => {
              if (!userMenuOpen) setExpanded(false)
            }}
            PaperProps={{
              'data-cy': 'desktop-sidebar',
            }}
          >
            <SidebarContent />
          </Drawer>
        </Hidden>
      </nav>
    </SidebarContext.Provider>
  )
}

export default AppSidebar
