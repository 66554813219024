import * as yup from 'yup'

import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import SyncIcon from '@material-ui/icons/Sync'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'

import FormField from 'components/UI/Formik/FormField/Index'
import MuiLink from 'components/UI/MaterialUI/Link'

import { yupLocaleES } from 'utils/form'
import {
  calculateNumberBetwenLimits,
  formatCurrency,
  formatNumber,
  generateBasicNumberParser,
} from 'utils/format'

import * as routes from 'config/routes'

yup.setLocale(yupLocaleES)

const currencyValueParser = generateBasicNumberParser({ max: 13 })
const daysParser = generateBasicNumberParser({ max: 3 })

function handleChangeBaseValueAndDays(form, idx, category, newValues) {
  const { social_benefits: socialBenefits } = form.values
  const currentSb = socialBenefits[idx]

  const baseValue = newValues.base_value ?? currentSb.base_value
  const days = newValues.days ?? currentSb.days

  const calculation =
    category === 'severance_interests' ? baseValue : (baseValue * days) / 360

  const newValue =
    category === 'severance_interests'
      ? Math.round(calculation * 0.12 * (days / 360))
      : Math.round(calculation)

  const { paid_value: paidValue, item_value: itemValue } = currentSb

  let newBalance = newValue - paidValue - itemValue
  let newItemValue = itemValue

  // to prevent negative balance
  if (newBalance < 0) {
    newBalance = 0
  }

  if (currentSb.initial_item_value > 0) {
    newBalance = newValue - paidValue - itemValue
  }

  // to prevent negative item_value
  if (newItemValue < 0) {
    newItemValue = 0
  }

  form.setFieldValue(`social_benefits[${idx}]`, {
    ...socialBenefits[idx],
    base_value: baseValue,
    days,
    value: newValue,
    balance: newBalance,
    item_value: newItemValue,
  })
}

export const socialBenefitTypeData = {
  service_bonus: {
    name: 'Prima de servicios',
    displayName: 'la Prima de servicios',
    description: (month = 'Junio') => (
      <>
        Si tus empleados reciben comisiones, bonificaciones o tienen
        modificaciones de salario durante el mes, te recomendamos realizar la
        liquidación de la prima de servicios durante la liquidación de nómina de
        la 2da quincena (15 al 30 de {month}). Si ya la realizaste y debes
        modificarla, utiliza la funcionalidad <b>Reiniciar valores</b> para
        calcular nuevamente la prima con la información actualizada.
      </>
    ),
  },
  severance: {
    name: 'Cesantías',
    displayName: 'las Cesantías',
    description: () => (
      <>
        Calcula el valor de las Cesantías, para pagar a través del operador tu
        operador de información. Revisa la base promedio (usamos la información
        la base de los intereses de las cesantías disponible en Nominapp), donde
        esta incluido el subsidio de transporte (si aplica) y modifícala si lo
        deseas. Si deseas conocer más sobre cómo realizar este proceso{' '}
        <Link
          href="https://ayuda.nominapp.com/help/como-puedo-liquidar-las-cesantias-con-nominapp"
          target="_blank"
        >
          haz clic aquí
        </Link>
        .
      </>
    ),
  },
  severance_interests: {
    name: 'Intereses a las cesantías',
    displayName: 'los Intereses a las cesantías',
    description: () => (
      <>
        Realiza el pago de los Intereses de las Cesantías fácilmente con
        Nominapp. La base promedio incluye incluye el salario, horas extra,
        recargos, ingresos salariales y subsidio de transporte (Si aplica).
        Usamos la información del año anterior disponible en Nominapp para
        calcular la base de los intereses, la cual puedes modificar. Si deseas
        conocer más sobre cómo realizar este proceso{' '}
        <Link
          href="https://ayuda.nominapp.com/help/como-puedo-pagar-los-intereses-sobre-cesantias-de-mis-empleados-con-nominapp"
          target="_blank"
        >
          haz clic aquí
        </Link>
        .
      </>
    ),
  },
}

export const getColumnsData = (category) => {
  if (!category) return []

  const maxDays = category === 'service_bonus' ? 180 : 360

  let valueLabel
  if (category === 'service_bonus') valueLabel = 'prima'
  if (category === 'severance') valueLabel = 'cesantías'
  if (category === 'severance_interests') valueLabel = 'intereses'

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <MuiLink to={routes.WORKER_SHOW(row.original.worker_id)}>
          {row.original.worker_name}
        </MuiLink>
      ),
    },
    {
      Header: `Base ${socialBenefitTypeData[category].name?.toLowerCase()}`,
      accessor: 'base_value',
      Cell: ({ row }) => {
        const rowIndex = row.index
        return (
          <FormField
            name={`social_benefits[${rowIndex}].base_value`}
            render={(field, _meta, _helpers, form) => {
              const { name, value } = field

              const valueFormatted = formatNumber(value)

              const id = `${name}_id`

              return (
                <OutlinedInput
                  id={id}
                  {...field}
                  value={valueFormatted}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  onChange={(event) => {
                    const baseValue = currencyValueParser(event.target.value)

                    handleChangeBaseValueAndDays(form, rowIndex, category, {
                      base_value: baseValue,
                    })
                  }}
                  inputProps={{ style: { textAlign: 'right' } }}
                />
              )
            }}
          />
        )
      },
    },
    {
      Header: 'Días trabajados',
      accessor: 'days',
      customWidth: '180px',
      Cell: ({ row }) => {
        const rowIndex = row.index
        return (
          <FormField
            name={`social_benefits[${rowIndex}].days`}
            render={(field, _meta, _helpers, form) => {
              const { name } = field

              const id = `${name}_id`

              return (
                <OutlinedInput
                  id={id}
                  {...field}
                  type="number"
                  onChange={(event) => {
                    const days = calculateNumberBetwenLimits(
                      daysParser(event.target.value),
                      0,
                      maxDays
                    )

                    handleChangeBaseValueAndDays(form, rowIndex, category, {
                      days,
                    })
                  }}
                  inputProps={{ style: { textAlign: 'right' } }}
                />
              )
            }}
          />
        )
      },
    },
    {
      Header: `Valor ${valueLabel}`,
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Saldo pendiente',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Valor a pagar',
      accessor: 'item_value',
      Cell: ({ row }) => {
        const rowIndex = row.index
        return (
          <FormField
            name={`social_benefits[${rowIndex}].item_value`}
            render={(field, _meta, _helpers, form) => {
              const { name, value: fieldValue } = field
              const id = `${name}_id`

              const valueFormatted = formatNumber(fieldValue)
              const { social_benefits: socialBenefits = [] } = form.values
              const {
                value = 0,
                paid_value: paidValue = 0,
                item_value: itemValue = 0,
                balance = 0,
              } = socialBenefits[rowIndex] || {}
              const currentBalance = value - paidValue

              return (
                <OutlinedInput
                  id={id}
                  {...field}
                  value={valueFormatted}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  onChange={(event) => {
                    let parsedValue = currencyValueParser(event.target.value)

                    if (parsedValue > currentBalance)
                      parsedValue = currentBalance

                    const newBalance = value - paidValue - parsedValue

                    form.setFieldValue(`social_benefits[${rowIndex}]`, {
                      ...socialBenefits[rowIndex],
                      balance: newBalance,
                      item_value: parsedValue,
                    })
                  }}
                  inputProps={{ style: { textAlign: 'right' } }}
                  disabled={balance === 0 && itemValue === 0}
                />
              )
            }}
          />
        )
      },
    },
  ]

  return columns
}

const getSocialBenefitAction = {
  service_bonus: 'prima',
  severance: 'cesantías',
  severance_interests: 'intereses',
}

export const getActions = ({
  handleDownloadFile,
  action,
  handleRecalculateSocialBenefits,
  form,
  category,
  getBottomMessage,
  handleRecalculateWorkerSocialBenefits,
  socialBenefitPeriod,
  handlePayBenefit,
  socialBenefitPaid,
  handleSubmit,
  isSubmitting,
  enabledSubmit,
  handleDeleteSocialBenefits,
  dirtyForm,
}) => {
  return [
    {
      id: 'downloadSummary',
      icon: GetAppOutlinedIcon,
      tooltip: 'Descargar Resumen',
      tooltipTitle:
        'Presiona este botón para descargar un resumen en Excel del cálculo de esta prestación social, los valores pagados y por pagar para cada empleado.',
      isFreeAction: true,
      onClick: handleDownloadFile,
      buttonVariant: 'outlined',
      position: 'left',
    },
    {
      id: 'deleteSocialBenefit',
      icon: DeleteOutlineOutlinedIcon,
      tooltip: `Eliminar pago ${getSocialBenefitAction[category] || ''}`,
      tooltipTitle:
        'Presiona este botón para eliminar el pago de esta prestación social.',
      isFreeAction: true,
      onClick: () => handleDeleteSocialBenefits(form),
      buttonVariant: 'outlined',
      hidden: action === 'create',
    },
    {
      id: 'resetValues',
      icon: SyncIcon,
      tooltip: 'Reiniciar valores',
      tooltipTitle:
        'Al presionar este botón Nominapp recalculará el saldo a pagar para todos los empleados con base en la información a la fecha.',
      isFreeAction: true,
      hidden: action === 'create',
      onClick: () => handleRecalculateSocialBenefits(form),
      buttonVariant: 'outlined',
    },
    {
      id: 'payBenefit',
      tooltip: `Liquidar ${getSocialBenefitAction[category] || ''}`,
      tooltipTitle: !socialBenefitPaid
        ? 'Al presionar este botón se agregará el saldo pendiente en la columna de valor a pagar para todos los empleados.'
        : undefined,
      isFreeAction: true,
      buttonVariant: 'outlined',
      onClick: handlePayBenefit,
      hidden: action === 'update',
      disabled: socialBenefitPaid,
    },
    {
      id: 'submitForm',
      tooltip: 'Guardar',
      tooltipTitle: `Al guardar verás ${getBottomMessage()} en el resumen de nómina, las colillas de pago y el archivo para pago en banco.`,
      isFreeAction: true,
      onClick: handleSubmit,
      hidden: action === 'update' && !dirtyForm,
      disabled: !enabledSubmit || isSubmitting,
    },
    (rowData) => ({
      id: 'recalculate',
      icon: SyncIcon,
      tooltip: 'Recalcular',
      disabled: rowData.id === null,
      onClick: (rowDataClick) =>
        handleRecalculateWorkerSocialBenefits(rowDataClick, form),
    }),
    {
      id: 'showCalculations',
      icon: VisibilityOutlined,
      tooltip: 'Ver cálculos',
      onClick: (rowDataClick) => {
        window.open(
          `${routes.PERIOD_PAYROLL_VIEW_DETAILS(
            rowDataClick.payroll_id,
            socialBenefitPeriod.id
          )}?selected_tab=social_benefits`
        )
      },
    },
  ]
}

export const getValidationSchema = (category) =>
  yup.object({
    social_benefits: yup
      .array()
      .of(
        yup.object({
          base_value: yup.number().min(0).required(),
          days: yup
            .number()
            .min(0)
            .max(category === 'service_bonus' ? 180 : 360)
            .required(),
          item_value: yup.number().min(0).required(),
        })
      )
      .required(),
  })
