import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import messages from 'messages/payroll'

import DateRangeContent from './DateRangeContent'

export default function Incapacities({
  payroll,
  incapacities,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  contract_category: contractCategory,
}) {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {!['student_decree_055', 'part_time_contract'].includes(
        contractCategory
      ) ? (
        <DateRangeContent
          title="Incapacidad por enfermedad general"
          data={incapacities.general_incapacity}
          onAddNovelty={onAddNovelty}
          onChangeNovelty={onChangeNovelty}
          onDeleteNovelty={onDeleteNovelty}
          noveltyType="incapacities"
          noveltyCode="general_incapacity"
          contentTooltip={messages.general_incapacity}
          selectedDays={selectedDays}
          payroll={payroll}
        />
      ) : null}

      {contractCategory !== 'schooling_trainee' &&
        contractCategory !== 'student_decree_055' && <Divider />}

      {contractCategory !== 'schooling_trainee' && (
        <DateRangeContent
          title="Incapacidad por enfermedad profesional o accidente de trabajo"
          data={incapacities.labor_incapacity}
          onAddNovelty={onAddNovelty}
          onChangeNovelty={onChangeNovelty}
          onDeleteNovelty={onDeleteNovelty}
          noveltyType="incapacities"
          noveltyCode="labor_incapacity"
          selectedDays={selectedDays}
          payroll={payroll}
        />
      )}
    </Box>
  )
}
