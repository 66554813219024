import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import useSubscription from 'components/Subscription/Atoms/useSubscription'

const useStyles = makeStyles((theme) => ({
  // TODO: Refactor these styles when the new subscription view is available to everyone
  fieldsContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(220px, 505px)',
    gap: theme.spacing(1),
  },
  fieldsContainerV2: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2.5),
  },
}))

const ContainerFields = ({ children }) => {
  const classes = useStyles()
  const { showNewSubscription } = useSubscription()

  return (
    <div
      className={clsx(classes.fieldsContainer, {
        [classes.fieldsContainerV2]: showNewSubscription,
      })}
    >
      {children}
    </div>
  )
}

export default ContainerFields
