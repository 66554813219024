import clsx from 'clsx'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right',
  },
  adornedEnd: {
    paddingRight: 0,
  },
}))

const TextField = ({ id, field, value, type = 'text', ...props } = {}) => {
  const localClasses = useStyles()
  const {
    classes,
    inputProps = {},
    className,
    align,
    stretchRightButton,
    ...restProps
  } = props
  const { className: inputPropsClassName, ...restInputProps } = inputProps

  return (
    <OutlinedInput
      id={id}
      {...field}
      value={value ?? ''}
      type={type}
      className={className}
      inputProps={{
        className: clsx(inputPropsClassName, {
          [localClasses.alignRight]: type === 'number' && align === 'right',
        }),
        ...restInputProps,
      }}
      classes={{
        adornedEnd: stretchRightButton && localClasses.adornedEnd,
        ...classes,
      }}
      {...restProps}
    />
  )
}

export default TextField
