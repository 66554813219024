import { roles, rolesOptions, rolesOptionsWithoutWorker } from 'utils/worker'

const columnsData = [
  {
    Header: 'Nombre',
    placeholder: 'Nombre',
    accessor: 'name',
    editable: 'onAdd',
  },
  {
    Header: 'Correo',
    placeholder: 'Correo',
    accessor: 'email',
    editable: 'onAdd',
  },
  {
    Header: 'Rol',
    accessor: 'role',
    Cell: ({ row }) =>
      roles[row.original.role] ? roles[row.original.role] : row.original.role,
    field: 'select',
    editOptions: ({ row }) =>
      row.original?.name !== '' ? rolesOptions : rolesOptionsWithoutWorker,
    editable: true,
    editInitialValue: 'admin',
    customWidth: 200,
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: ({ row }) => (row.original.active ? 'Si' : 'No'),
    field: 'switch',
    editable: 'onUpdate',
    alignHeader: 'center',
    alignCell: 'center',
    customWidth: 150,
  },
]

export default columnsData
