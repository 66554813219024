import { useEffect, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import MButton from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

import Button from 'components/UI/Button/Button'

import { getColombianCurrentDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import DeleteForm from './Modals/DeleteForm'
import { getSummaryItems } from './helpers'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.common.white,
  },
  itemContainer: {
    margin: theme.spacing(5, 0, 5),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > button, button + a, a': {
      marginLeft: '0 !important',
    },
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  deleteButton: {
    width: 'fit-content',
    marginTop: theme.spacing(2),
  },
  label: {
    color: theme.palette.grey[500],
  },
}))

const SocialSecurityPaymentSummary = ({
  isLoading,
  formData,
  hasErrors,
  handlePayForm,
  handleDeleteForm,
  provider,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { showErrorMessage } = useNotifications()
  const { status } = formData
  const [showDeleteForm, setShowDeleteForm] = useState(false)

  const paymentSummary = getSummaryItems(formData)

  const date = getColombianCurrentDate()
  const dateHour = date.getHours()
  const dateMinute = date.getMinutes()
  const isAELDisabled =
    (dateHour === 16 && dateMinute >= 30) ||
    dateHour === 17 ||
    (dateHour === 18 && dateMinute <= 30)

  useEffect(() => {
    if (isAELDisabled && (status === 'created' || status === 'declined')) {
      showErrorMessage(
        'Recuerda que por reglamentación en Colombia no es posible realizar pago de la seguridad social entre las 4:30 y las 6:30pm'
      )
    }
  }, [isAELDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slide direction="left" timeout={500} in>
      <div className={classes.main}>
        <Typography variant="h5">Resumen de tu pago</Typography>

        <div className={classes.itemContainer}>
          {paymentSummary.map((item, index) => {
            if (item.hidden) return null

            return (
              <div key={`${index.toString()}_${item.label}`}>
                <div className={classes.item}>
                  <Typography className={classes.label}>
                    {item.label}
                  </Typography>
                  <Typography variant="subtitle1">{item.value}</Typography>
                </div>
                {index !== paymentSummary.length - 1 && <Divider />}
              </div>
            )
          })}
        </div>

        <div className={classes.total}>
          <Typography>Total a pagar</Typography>

          <Typography variant="h4">
            {formatCurrency(formData?.value || 0)}
          </Typography>
        </div>

        <div className={classes.buttonsContainer}>
          {!hasErrors && (
            <>
              {(status === 'created' || status === 'declined') && (
                <Button
                  onClick={handlePayForm}
                  className={classes.button}
                  disabled={isLoading || isAELDisabled}
                  loading={isLoading}
                >
                  {status === 'created'
                    ? 'Pagar seguridad social'
                    : 'Reintentar pago'}
                </Button>
              )}
              {status === 'pending' && (
                <MButton
                  className={classes.button}
                  component={RouterLink}
                  to={{
                    pathname: routes.SETTINGS_SOCIAL_SECURITY_INDEX(),
                    state: {
                      provider,
                    },
                  }}
                >
                  Ver historial de planillas
                </MButton>
              )}
              <MButton
                variant={status === 'paid' ? 'contained' : 'outlined'}
                className={classes.button}
                onClick={() => history.goBack()}
              >
                Regresar{status === 'created' && ' y pagar luego'}
              </MButton>
            </>
          )}

          {(status === 'created' || status === 'declined') && (
            <>
              <Button
                variant="text"
                className={classes.deleteButton}
                onClick={() => setShowDeleteForm(true)}
                disabled={isLoading}
              >
                Eliminar planilla
              </Button>
              <DeleteForm
                open={showDeleteForm}
                provider={provider}
                status={status}
                onCancel={() => setShowDeleteForm(false)}
                onOk={handleDeleteForm}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
      </div>
    </Slide>
  )
}

export default SocialSecurityPaymentSummary
