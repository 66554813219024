import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useFormikContext } from 'formik'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: 330,
    height: 48,
  },
  root: {
    maxWidth: 330,
    width: '100%',
    height: 60,
    backgroundColor: theme.palette.common.white,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
    boxShadow: theme.shadows[1],
  },
  toggle: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    left: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    transition: 'transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  },
  toggleRight: {
    transform: 'translateX(100%)',
  },
  names: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    position: 'absolute',
    userSelect: 'none',
  },
  backgroundText: {
    color: theme.palette.grey[500],
    transition: 'color .3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  },
  currentText: {
    color: theme.palette.common.white,
    transition: 'color .3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  },
  checkbox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  discountChip: {
    position: 'absolute',
    top: '-25%',
    right: '12%',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    padding: 0,
    '& .MuiChip-label': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      right: '-10%',
      top: '25%',
    },
  },
}))

const ModalitySwitch = ({ discount = 0, name }) => {
  const classes = useStyles()
  const { setFieldValue, values } = useFormikContext()
  const isYearly = values[name] === 'year'
  const [togglePosition, setTogglePosition] = useState(() =>
    isYearly ? 'right' : 'left'
  )

  useEffect(() => {
    setTogglePosition(isYearly ? 'right' : 'left')
  }, [isYearly])

  const onChange = ({ target: { value } }) => {
    setFieldValue(name, value === 'year' ? 'month' : 'year')
    setTogglePosition((previousPosition) =>
      previousPosition === 'left' ? 'right' : 'left'
    )
  }

  return (
    <FormControl fullWidth className={classes.formControl}>
      <div className={classes.root}>
        <div
          className={clsx(classes.toggle, {
            [classes.toggleRight]: isYearly,
          })}
        />
        <div className={classes.names}>
          <Typography
            variant="subtitle1"
            className={clsx(classes.backgroundText, {
              [classes.currentText]: togglePosition === 'left',
            })}
          >
            Mensual
          </Typography>
          <Typography
            variant="subtitle1"
            className={clsx(classes.backgroundText, {
              [classes.currentText]: togglePosition === 'right',
            })}
          >
            Anual
          </Typography>
        </div>
        {discount ? (
          <Chip
            label={`${discount}% DTO`}
            size="small"
            className={classes.discountChip}
          />
        ) : null}
        <Checkbox
          name={name}
          value={values[name] || ''}
          className={classes.checkbox}
          onChange={onChange}
        />
      </div>
    </FormControl>
  )
}

export default ModalitySwitch
