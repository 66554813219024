import { useQueryClient } from 'react-query'

import SingleFieldModal from 'components/UI/Modal/SingleFieldModal'

import { isObjectEmpty } from 'utils/general'
import { getDirtyValues } from 'utils/form'

import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'

const CurrentPaymentModal = ({
  state: { payment, formData },
  handleClose,
  submitCallback,
}) => {
  const queryClient = useQueryClient()
  const {
    id: paymentId,
    invoice_id: invoiceId,
    paid_extra_workers: paidExtraWorkers,
  } = payment
  const { fieldName, title, schemaValidation } = formData
  const { companyPaymentMutation } = useCompanyPaymentService({
    queryOptions: {
      enabled: false,
    },
  })

  const onCompleteSubmit = (message) => {
    queryClient.invalidateQueries(['getAllPayments'], {
      exact: false,
    })
    submitCallback(message)
    handleClose()
  }

  const handleSubmit = (values) => {
    const dirtyValues = getDirtyValues(payment, values)

    if (!isObjectEmpty(dirtyValues)) {
      if (formData.fieldName === 'invoice_id') {
        companyPaymentMutation.mutate(
          {
            mutationMethod: 'PUT',
            payment: dirtyValues,
            paymentId,
          },
          {
            onSuccess: ({ message }) => {
              onCompleteSubmit(message)
            },
          }
        )
      } else {
        companyPaymentMutation.mutate(
          {
            mutationMethod: 'PATCH',
            payment: dirtyValues,
          },
          {
            onSuccess: ({ message }) => {
              onCompleteSubmit(message)
            },
          }
        )
      }
    }
  }

  return (
    <SingleFieldModal
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      formikProps={{
        initialValues: {
          paid_extra_workers: paidExtraWorkers,
          invoice_id: invoiceId,
        },
        validationSchema: schemaValidation,
      }}
      modalProps={{
        header: `Actualizar ${title}`,
      }}
      fieldProps={{
        name: fieldName,
        type: 'number',
        label: `Número de ${title}`,
      }}
      fieldValidation={
        fieldName === 'paid_extra_workers' && {
          min: 0,
          max: 500,
        }
      }
    />
  )
}

export default CurrentPaymentModal
