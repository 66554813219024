import TableToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import WorkerActions from './WorkerActions'
import WorkerIndexToolbarTabs from './Tabs'

/**
 * This component its supposed to be the same as src\components\UI\MaterialUI\MTable\Toolbar.jsx
 * so we have to keep then synchronized for now while we found a better general solution
 */
const WorkerIndexMTableToolbar = (props) => {
  return (
    <>
      <WorkerIndexToolbarTabs />
      <ToolbarContainer {...props}>
        <TableToolbarSearch {...props} />
        <WorkerActions />
      </ToolbarContainer>
    </>
  )
}

export default WorkerIndexMTableToolbar
