import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

import Modal from 'components/UI/Modal/Modal'

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    paddingBottom: '62.5%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 0,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
}))

const HelpModal = ({ modalInfo, closeModal }) => {
  const { videoLink, title, description, footer } = modalInfo
  const classes = useStyles()

  return (
    <Modal onOk={closeModal} okText="Entiendo" open hideCancelButton>
      <>
        <div className={classes.videoContainer}>
          <iframe
            title="Loom video"
            src={videoLink}
            frameBorder="0"
            className={classes.iframe}
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
        </div>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography gutterBottom>{description}</Typography>
        <Typography>{footer}</Typography>
      </>
    </Modal>
  )
}

export default HelpModal
