import { useContext } from 'react'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { AffiliationsContext } from '../Index'
import { tabsData } from '../helpers'

const AffiliationTabs = () => {
  const { filter, setFilter } = useContext(AffiliationsContext)

  const handleChangeTab = (_, tab) => {
    setFilter(tab)
  }

  return (
    <Tabs
      value={filter}
      onChange={handleChangeTab}
      tabs={tabsData}
      variant="scrollable"
      backgroundColor="white"
    />
  )
}

export default AffiliationTabs
