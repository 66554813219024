import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import CommentIcon from '@material-ui/icons/Comment'
import EditIcon from '@material-ui/icons/Edit'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import MoneyIcon from '@material-ui/icons/LocalAtm'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'

import {
  subscriptionPaymentCategories,
  subscriptionPaymentStatus,
} from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'
import { booleanLookup } from 'utils/table'

import * as routes from 'config/routes'

yup.setLocale(yupLocaleES)

export const useHelpersPartnerPayments = (partnerId, handleSendInvoice) => {
  const history = useHistory()

  const columnsData = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ row }) => formatDisplayDateString(row.original.date),
      },
      {
        Header: 'Valor total',
        accessor: 'value',
        Cell: ({ row }) => formatCurrency(row.original.value),
        customWidth: '200px',
      },
      {
        Header: 'Forma de pago',
        accessor: 'category',
        Cell: ({ row }) =>
          subscriptionPaymentCategories[row.original.category]
            ? subscriptionPaymentCategories[row.original.category]
            : row.original.category,
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ row }) =>
          subscriptionPaymentStatus[row.original.status]
            ? subscriptionPaymentStatus[row.original.status]
            : row.original.status,
      },
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        tooltip: 'Agregar Pago',
        icon: AddIcon,
        isFreeAction: true,
        onClick: () =>
          history.push({
            pathname: routes.ORGANIZER_PARTNER_EDIT_PAYMENT(partnerId),
            state: { isEdit: false },
          }),
      },
      (rowData) => ({
        tooltip: 'Enviar factura',
        icon: SendOutlinedIcon,
        onClick: () => handleSendInvoice(rowData.id, partnerId),
        hidden: !rowData.invoice,
      }),
      ({ first, status }) => ({
        tooltip: 'Editar Pago',
        icon: EditIcon,
        onClick: () =>
          history.push({
            pathname: routes.ORGANIZER_PARTNER_EDIT_PAYMENT(partnerId),
            state: { isEdit: true },
          }),
        hidden: !first || status !== 'pending',
      }),
    ],
    [handleSendInvoice, history, partnerId]
  )

  return { columnsData, actions }
}

export const useHelpersPartnerCompanies = (openModal, deleteCompany) => {
  const confirm = useConfirm()
  const { changeCompany } = useUser()

  const columnsData = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        Cell: ({ row }) => (
          <Link
            onClick={() => changeCompany({ id: row.original.id })}
            to={routes.DASHBOARD}
          >
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: 'Forma de pago',
        accessor: 'payment_category',
        Cell: ({ row }) =>
          subscriptionPaymentCategories[row.original.payment_category] || '-',
      },
      {
        Header: 'Activo',
        accessor: 'active',
        Cell: ({ row }) => booleanLookup[row.original.active],
        sortType: 'booleanSort',
      },
      {
        Header: 'Fecha de creación',
        accessor: 'created_at',
        Cell: ({ row }) => formatDisplayDateString(row.original.created_at),
      },
    ],
    [changeCompany]
  )

  const actions = useMemo(
    () => [
      {
        id: 'addCompany',
        tooltip: 'Agregar Empresa',
        icon: AddIcon,
        isFreeAction: true,
        onClick: () => openModal({ company: {}, open: true }),
      },
      {
        id: 'editCompany',
        tooltip: 'Editar Empresa',
        icon: EditIcon,
        onClick: (company) => openModal({ company, open: true }),
      },
      {
        id: 'removeCompany',
        icon: LinkOffIcon,
        tooltip: 'Desvincular Empresa',
        onClick: ({ id, name }) => {
          confirm({
            type: 'warning',
            title: 'Desvincular empresa',
            description: `Desvincular la compañía ${name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
            okText: 'Desvincular',
            confirmCheckbox: true,
            onOk: () => deleteCompany(id),
          })
        },
      },
    ],
    [confirm, deleteCompany, openModal]
  )

  return {
    columnsData,
    actions,
  }
}

export const formatSummaryData = (data) => {
  if (!data) return null

  const summaryData = [
    {
      Icon: PeopleOutlineIcon,
      label: 'Descuento por empleado',
      value: `${data.discount} %`,
    },
    {
      Icon: MoneyIcon,
      label: 'Descuento sobre la base',
      value:
        data.base_discount_type === 'value'
          ? formatCurrency(data.base_discount)
          : `${data.base_discount} %`,
    },
    {
      Icon: CommentIcon,
      label: 'Comentarios',
      value: data.comment || 'No hay comentarios',
      comment: true,
    },
  ]

  return summaryData
}

export const validationSchema = yup.object({
  name: yup.string().trim().required(),
  document_type: yup.string().trim().required(),
  email: yup.string().nullable().email().required(),
  id_number: yup.string().nullable().trim().required(),
  payment_category: yup.string().trim().required(),
})

export const initialValues = {
  name: '',
  email: '',
  id_number: '',
}
