import Typography from '@material-ui/core/Typography'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { formatNumber } from 'utils/format'

import useContentStyles from '../common/commonStyles'

export default function AccumulatedHolidays({ accumulatedHolidays }) {
  const contentClasses = useContentStyles()

  return (
    <div className={contentClasses.noveltiesItemContainer}>
      <Typography>Vacaciones acumuladas</Typography>
      <Typography align="center">
        {formatNumber(accumulatedHolidays)}
      </Typography>
      <TooltipInfoIcon title="Días que le faltan por disfrutar." />
    </div>
  )
}
