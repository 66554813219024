import { useQueryClient } from 'react-query'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Emoji from 'components/UI/Emoji'
import OrganizationEmail from 'components/UI/OrganizationEmail'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { isChristmasTime } from 'utils/dateTime'
import { fatherChristmas } from 'utils/emojis'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import CommingSoon from './CommingSoon/CommingSoon'
import CostsInPercentages from './CostsInPercentages/CostsInPercentages'
import NextPayments from './NextPayments/NextPayments'
import PayollCosts from './PayrollCosts/PayrollCosts'
import Pendings from './Pendings/Pendings'
import Variations from './Variations'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  gridMain: {
    gridArea: 'main',
    minWidth: 0,
  },
  gridAside: {
    maxWidth: 440,
  },
}))

const Dashboard = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'greet_message', getCompanyId()]
  const { data: greetMessageCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'greet_message',
    },
    queryOptions: {
      enabled: !isDataCached(greetMessageCache),
    },
  })

  const { greet_message: greetMessage } = greetMessageCache || {}

  // Variable for add personalize message christmas
  const christmasGreetMessage = isChristmasTime() ? (
    <>
      Jo, jo, jo <Emoji code={fatherChristmas} />
    </>
  ) : null

  return (
    <Page
      documentTitle="Panel de Control"
      header={
        greetMessage ? (
          <div>
            <Typography variant="h5">
              {christmasGreetMessage}
              {greetMessage}
            </Typography>
            <Typography variant="body2">
              ¿Tienes alguna pregunta?{' '}
              <Link href="https://ayuda.nominapp.com/help" target="_blank">
                Visita nuestro centro de ayuda
              </Link>{' '}
              o escríbenos a <OrganizationEmail />.
            </Typography>
          </div>
        ) : null
      }
      isLoading={dashboardQuery?.isLoading}
    >
      <div className={classes.grid}>
        <div className={classes.gridMain}>
          <Pendings />
          <NextPayments />
          <Variations />
          <PayollCosts />
          <CostsInPercentages />
        </div>

        <div className={classes.gridAside}>
          <CommingSoon />
        </div>
      </div>
    </Page>
  )
}

export default Dashboard
