import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import OrganizationEmail from 'components/UI/OrganizationEmail'

import approvedImg from 'assets/images/views/payment/payment_approved.png'
import pendingImg from 'assets/images/views/payment/payment_pending.png'
import declinedImg from 'assets/images/views/payment/payment_declined.png'
import Summary from './Summary'

const getContentFromResponse = (status, response) => {
  const contentByPaymentStatus = {
    APPROVED: {
      headerImg: approvedImg,
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            ¡Tu pago ha sido
            <br />
            procesado con éxito!
          </Typography>
          <Typography gutterBottom>
            Revisa tu correo para confirmar que{' '}
            {response.name || 'Aportes en Línea'} recibió el pago. 🙂
          </Typography>
        </>
      ),
    },
    PENDING: {
      headerImg: pendingImg,
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            Tu pago está
            <br />
            pendiente de aprobación.
          </Typography>
          <Typography gutterBottom>
            La institución financiera está procesando el pago y aún no tenemos
            respuesta por favor no reintentes el pago hasta que tengas claro si
            el pago fue rechazado o aprobado.
          </Typography>
        </>
      ),
      footerText: (
        <>
          Si tienes alguna duda, escríbenos por el chat o a{' '}
          <OrganizationEmail />, o contacta a tu institución financiera.
        </>
      ),
    },
    DECLINED: {
      headerImg: declinedImg,
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            Parece que
            <br />
            algo salió mal.
          </Typography>
          <Typography gutterBottom>
            {response.message ||
              response.error?.message ||
              'Ocurrió un error al intentar realizar tu pago. Por favor inténtalo nuevamente.'}
          </Typography>
        </>
      ),
      footerText: (
        <>
          Si el error persiste, escríbenos por el chat o a <OrganizationEmail />
          , y te ayudaremos con mucho gusto en lo que necesites.
        </>
      ),
    },
  }

  return contentByPaymentStatus[status]
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'minmax(auto, 720px)',
    gridTemplateRow: 'auto',
    height: '100%',
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  img: {
    height: 180,
    marginLeft: 50,
  },
  button: {
    alignSelf: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    minWidth: 250,
  },
}))

const TransactionStatus = ({
  status,
  response = {},
  buttonText,
  buttonLinkTo,
}) => {
  const classes = useStyles()
  const { headerText, headerImg, footerText } = getContentFromResponse(
    status,
    response
  )

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.banner}>
          <div>{headerText}</div>
          <img className={classes.img} src={headerImg} alt="Response Img" />
        </div>

        <Summary response={response} />

        {footerText && (
          <Typography gutterBottom align="center">
            {footerText}
          </Typography>
        )}

        <Button
          color="primary"
          component={RouterLink}
          to={buttonLinkTo}
          className={classes.button}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default TransactionStatus
