import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import CommentsDialog from './Modals/Comments'

const ActionButton = ({ action, completeRow }) => {
  const featureState = usePremiumFeature('payslip')

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dialogPopover',
  })

  const handleOnClick = () => {
    if (
      featureState.isPremiumFeature &&
      action.tooltip === 'Ver colilla de pago'
    ) {
      featureState.openModal()
      return
    }

    if (action.onClick) {
      action.onClick(completeRow)
    }
  }

  return !action.hidden ? (
    <>
      <Tooltip title={action.tooltip}>
        <IconButton
          size="small"
          disabled={action.disabled}
          onClick={handleOnClick}
          {...action.buttonProps}
          {...(action.commentsDialog ? bindTrigger(popupState) : {})}
        >
          {action.badge ? (
            <Badge color="primary" variant="dot" {...action.badgeProps}>
              <action.icon />
            </Badge>
          ) : (
            <action.icon />
          )}
          {action.tooltip === 'Ver colilla de pago' ? (
            <IconCrown
              fontSize={10}
              isPremiumAction={featureState.isPremiumFeatureIcon}
              isDotIcon
            />
          ) : null}
        </IconButton>
      </Tooltip>
      {action.commentsDialog && (
        <CommentsDialog payroll={completeRow} popupState={popupState} />
      )}
    </>
  ) : null
}

export default ActionButton
