import { useContext } from 'react'

import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import Button from 'components/UI/Button/Button'
import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'

import { getCompanyId } from 'utils/company'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import { useCompanyService } from 'utils/hooks/company/companyService'

import { WorkerIndexContext } from '../Index'

const WorkerIndexToolbarWorkerActions = () => {
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })
  const downloadFile = useEnqueueDownload()
  const { totals } = useContext(WorkerIndexContext)

  const withoutWorkers = totals && totals?.length === 0

  const handleDownload = () => {
    companyMutation.mutate(
      {
        mutationMethod: 'GET',
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'archivo de trabajadores',
            fileCode: 'wokers_file',
            pathname: `companies/${getCompanyId()}/workers_file`,
          })
        },
      }
    )
  }

  return (
    <>
      {!withoutWorkers ? (
        <div>
          <Button
            startIcon={<GetAppOutlinedIcon />}
            onClick={handleDownload}
            variant="outlined"
          >
            Descargar Información
          </Button>
          <ButtonAddWorker />
        </div>
      ) : null}
    </>
  )
}

export default WorkerIndexToolbarWorkerActions
