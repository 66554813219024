import TagFacesIcon from '@material-ui/icons/TagFaces'

import PendingActions from 'assets/icons/PendingActions'

const benefits = [
  {
    id: 1,
    logo: <PendingActions fontSize="large" color="primary" />,
    alt: 'Pending action image',
    title: 'Ahorrarle mucho tiempo',
    description:
      'Cargando la info de tu nómina a su software contable preferido de forma automática.',
  },
  {
    id: 2,
    logo: <TagFacesIcon fontSize="large" color="primary" />,
    title: 'Relajarte aún más',
    description:
      'Tendrás unos ojos extras validando que tu nómina está perfecta',
  },
]

export default benefits
