import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { formatCurrency } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  formula: {
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  formulaTemplate: {
    justifyContent: 'center',
  },
  template: {
    margin: '20px 0 10px 0',
    backgroundColor: theme.palette.grey[200],
  },
  margin: { marginRight: theme.spacing(2) },
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
}))

const Formula = ({ formula, isTemplate = true }) => {
  const classes = useStyles()

  const { label, numerator, denominator, complement, value } = formula

  return (
    <Paper
      elevation={isTemplate ? 1 : 0}
      className={clsx(classes.container, { [classes.template]: isTemplate })}
    >
      {isTemplate && label && (
        <Typography variant="subtitle2" align="center" paragraph>
          {label}
        </Typography>
      )}
      <div
        className={`${classes.formula} ${
          isTemplate ? classes.formulaTemplate : null
        }`}
      >
        {!isTemplate && <span className={classes.margin}>{label} = </span>}
        <div className={classes.margin}>
          <span>{numerator}</span>
          {denominator && (
            <>
              <Divider className={classes.divider} />
              <span className={classes.margin}>{denominator}</span>
            </>
          )}
        </div>
        {complement && <span className={classes.margin}> {complement}</span>}
        {value && (
          <span style={{ color: green[500], fontWeight: 'bold' }}>
            {` = ${formatCurrency(value)}`}
          </span>
        )}
      </div>
    </Paper>
  )
}
export default Formula
