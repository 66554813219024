import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import ToolbarActions from './ToolbarActions'
import ToolbarSearch from './ToolbarSearch'

const Decree376Toolbar = (enabledSubmit, isSubmitting, onSubmit) => (props) => {
  const { preGlobalFilteredRows, setGlobalFilter, globalFilter, rows } = props

  return (
    <ToolbarContainer {...props}>
      <ToolbarSearch
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <ToolbarActions
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        rows={rows}
      />
    </ToolbarContainer>
  )
}

export default Decree376Toolbar
