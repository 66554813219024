import SvgIcon from '@material-ui/core/SvgIcon'

const Building = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 36" {...props}>
      <path d="M15.9993 9.33333V4H2.66602V28H29.3327V9.33333H15.9993ZM13.3327 25.3333H5.33268V22.6667H13.3327V25.3333ZM13.3327 20H5.33268V17.3333H13.3327V20ZM13.3327 14.6667H5.33268V12H13.3327V14.6667ZM13.3327 9.33333H5.33268V6.66667H13.3327V9.33333ZM26.666 25.3333H15.9993V12H26.666V25.3333ZM23.9993 14.6667H18.666V17.3333H23.9993V14.6667ZM23.9993 20H18.666V22.6667H23.9993V20Z" />
    </SvgIcon>
  )
}

export default Building
