import { makeStyles } from '@material-ui/core/styles'

const useContentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  itemContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 160px 30px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  withholdingItemContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 160px 150px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  noveltiesItemContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: '50% 1fr 25px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  avistaItemContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
}))

export default useContentStyles
