import { makeStyles } from '@material-ui/core/styles'

import FieldsAreas from './Areas'
import FieldsBasics from './Basics'
import FieldsCredentials from './Credentials/Credentials'
import FieldsLocations from './Locations/Locations'
import FieldsPaymentPreferences from './PaymentPreferences'
import FieldsPositions from './Positions'
import WorkCenters from './WorkCenters/Index'

export const switchRenderFields = (index, institutions, documentType) => {
  switch (index) {
    case 0:
      return <FieldsBasics documentType={documentType} />
    case 1:
      return (
        <FieldsLocations compensationFunds={institutions.compensationFunds} />
      )
    case 2:
      return <WorkCenters />
    case 3:
      return <FieldsAreas />
    case 4:
      return <FieldsPositions />
    case 5:
      return (
        <FieldsPaymentPreferences
          riskProviders={institutions.riskProviders}
          ssOperators={institutions.ssOperators}
        />
      )
    case 6:
      return <FieldsCredentials />
    default:
      return null
  }
}

export const useArrayFieldsStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
    [theme.breakpoints.up(645)]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(220px, 1fr))',
      gap: theme.spacing(2),
    },
  },
  item: {
    display: 'grid',
    gridTemplateColumns: 'minmax(153px, 390px) min-content',
    columnGap: theme.spacing(1),
    alignItems: 'end',
    alignSelf: 'center',
  },
  addBtn: {
    gridColumnEnd: 'span 2',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1),
  },
}))
