import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'

import BirthdaysItem from './BirthdaysItem'

const DashboardCommingSoonBirthdays = ({ birthdays }) => {
  return (
    <Box p={4} marginBottom={2} component={Paper}>
      <Typography variant="h6">Cumpleaños</Typography>
      <Typography>Las siguientes personas cumplen años:</Typography>
      <List>
        {birthdays.map((birthday, index) => {
          const key = `birthday_${birthday.worker_name}`
          return <BirthdaysItem key={key} index={index} birthday={birthday} />
        })}
      </List>
    </Box>
  )
}

export default DashboardCommingSoonBirthdays
