import isSameDay from 'date-fns/isSameDay'
import isWithinInterval from 'date-fns/isWithinInterval'

import Link from '@material-ui/core/Link'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

export function getModalTitle(contractCategory) {
  let modalTitle

  switch (contractCategory) {
    case 'employee':
    case 'pensioner':
    case 'student_law_789':
      modalTitle = (
        <>
          Vacaciones, Incapacidades y Licencias
          <TooltipInfoIcon
            title={
              <>
                <TooltipLink
                  href="https://ayuda.nominapp.com/help/como-ingresar-vacaciones-a-un-empleado"
                  target="_blank"
                >
                  Aquí
                </TooltipLink>{' '}
                podrás ver cómo agregar las vacaciones.
              </>
            }
            size="default"
            interactive
          />
        </>
      )
      break
    case 'schooling_trainee':
    case 'productive_trainee':
    case 'intern':
      modalTitle = 'Incapacidades y Licencias'
      break
    case 'student_decree_055':
      modalTitle = 'Licencias'
      break
    default:
      break
  }

  return modalTitle
}

export function getTabs(holidaysApply, licensesApply) {
  return [
    {
      key: 'holidays',
      label: 'Vacaciones',
      hidden: !holidaysApply,
      cyData: 'holidays-tab',
    },
    {
      key: 'incapacities',
      label: 'Incapacidades',
      cyData: 'incapacities-tab',
    },
    {
      key: 'licenses',
      label: 'Licencias',
      hidden: !licensesApply,
      cyData: 'licenses-tab',
    },
  ]
}

export const getDateRangeDaysClassification = (day, start, end) => {
  const dayIsBetween = start && end && isWithinInterval(day, { start, end })
  const isFirstDay = isSameDay(day, start)
  const isLastDay = isSameDay(day, end)

  return [dayIsBetween, isFirstDay, isLastDay]
}

export const noveltyDescription = {
  holidays: (
    <>
      Ingresa aquí los días de vacaciones disfrutados o compensados en dinero
      durante el periodo. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-ingresar-vacaciones-a-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  incapacities: (
    <>
      Ingresa aquí los días de incapacidad por enfermedad general o accidente de
      trabajo de la persona. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-ingresar-las-incapacidades-de-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  licenses: (
    <>
      Ingresa aquí los diferentes días de licencias que tuvo la persona en el
      periodo. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://ayuda.nominapp.com/help/como-ingresar-las-licencias-a-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
}

export function parseCompensatedDayValue(value) {
  let result = parseInt(value, 10)

  if (Number.isNaN(result) || value < 0) {
    result = 0
  } else if (value > 30) {
    result = 30
  }

  return result
}
