import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Emoji from 'components/UI/Emoji'

import { isWorker } from 'utils/auth'
import { partyPopperEmoji } from 'utils/emojis'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import crown from 'assets/images/views/common/crown.svg'

import { SUBSCRIPTION } from 'config/routes'

import useModals from '../ModalsManager/useModals'
import { useUser } from '../UserContext/useUser'
import FeatureCard from './Atoms/FeatureCard'
import { premiumFeatures, usePremiumActions } from './helpers'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },
  badgeContainer: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    columnGap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  startPaper: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down(760)]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    paddingBottom: theme.spacing(4),
  },
  bottomMesagge: {
    marginBottom: theme.spacing(4),
  },
  singleActionContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: theme.spacing(0, 25),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    paddingBottom: theme.spacing(4),
  },
  featuresContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    marginTop: theme.spacing(5.5),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const Actions = ({ withPremiumAction = true }) => {
  const classes = useStyles()
  const { company, subscription } = useUser()
  const modals = useModals()
  const history = useHistory()
  const {
    handleGetFreeTrial,
    handlePremiumForPartnerCompany,
  } = usePremiumActions()
  const partnerAction =
    (company?.partner_company_freemium &&
      !['free_trial', 'active', 'premium_trial', 'active_premium'].includes(
        subscription?.status
      )) ||
    (subscription.partner && subscription.status !== 'active_premium')

  const handleGoToPlans = () => {
    modals.closeAll()
    history.push(SUBSCRIPTION())
  }

  const getFreeTrial = () => {
    trackEvent(integrationEvent.FREE_TRIAL_STARTED)
    handleGetFreeTrial(() => modals.closeAll())
  }

  const getFreeTrialForPartner = () => {
    handlePremiumForPartnerCompany(() => modals.closeAll())
  }

  if (partnerAction) {
    return (
      <div className={classes.singleActionContainer}>
        <Button fullWidth onClick={getFreeTrialForPartner}>
          Pasarme a Premium
        </Button>
      </div>
    )
  }

  if (withPremiumAction) {
    return (
      <div className={classes.actionsContainer}>
        <Button fullWidth variant="outlined" onClick={handleGoToPlans}>
          Ver planes
        </Button>
        <Button fullWidth onClick={getFreeTrial}>
          Empezar prueba de 7 días
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.singleActionContainer}>
      <Button fullWidth onClick={handleGoToPlans}>
        Ver planes
      </Button>
    </div>
  )
}

const PremiumFeature = ({
  title,
  description,
  withPremiumAction,
  highlightIndex,
  feature,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <div className={classes.badgeContainer}>
        <Paper className={classes.startPaper}>
          <img src={crown} alt="Feature crown" width={24} height={24} />
        </Paper>
        <Typography variant="body1">
          Estos son los superpoderes que vas a ganar:
        </Typography>
      </div>
      <div className={classes.featuresContainer}>
        {premiumFeatures(feature).map((premiumFeature) => {
          const highlight = highlightIndex === premiumFeature.id
          return (
            <FeatureCard
              key={premiumFeature.id}
              Image={premiumFeature.image}
              text={premiumFeature.description}
              highlight={highlight}
            />
          )
        })}
      </div>
      <Typography
        variant="body2"
        align="center"
        className={classes.bottomMesagge}
      >
        Más de 3.000 pymes automatizan su nómina con <b>Nominapp Premium</b>{' '}
        <Emoji code={partyPopperEmoji} />
      </Typography>
      {!isWorker() ? <Actions withPremiumAction={withPremiumAction} /> : null}
    </div>
  )
}

export default PremiumFeature
