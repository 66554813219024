import { useEffect, useRef, useState } from 'react'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles((theme) => ({
  slideshow: {
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  slide: {
    display: 'block',
    height: 'auto',
    width: '100%',
    flex: 'none',
  },
  slideshowDotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  slideshowDot: {
    color: theme.palette.primary.main,
  },
  slideshowDotActive: {
    color: theme.palette.secondary.main,
  },
  dotBackground: {
    backgroundColor: `${theme.palette.primary.main}20`,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '& > svg': {
      color: theme.palette.common.white,
      fontSize: 16,
    },
  },
}))

const SlideDot = ({ isActive, handleSelectSlide }) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classes.slideshowDot} onClick={handleSelectSlide}>
      {isActive ? (
        <div className={classes.dotBackground}>
          <FiberManualRecordIcon />
        </div>
      ) : (
        <FiberManualRecordIcon />
      )}
    </ButtonBase>
  )
}

const SlideShow = ({ slides = [], delay = 6000 }) => {
  const classes = useStyles()
  const [currentIndex, setCurrentIndex] = useState(2)
  const timeoutRef = useRef(null)
  const [visited, setVisited] = useState(false)

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    if (!visited) {
      timeoutRef.current = setTimeout(
        () =>
          setCurrentIndex((prevIndex) =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      )
    }

    return () => {
      resetTimeout()
    }
  }, [currentIndex, delay, slides.length, visited])

  const handleSelectSlide = (index) => setCurrentIndex(index)

  const handleMouseEnter = () => {
    resetTimeout()
    if (!visited) {
      setVisited(true)
    }
  }

  const handleMouseLeave = () => {
    if (visited) {
      setVisited(false)
    }
  }

  return (
    <div className={classes.slideshow}>
      {slides.map(({ id, slide }, index) => {
        if (currentIndex === index) {
          return (
            <Slide
              key={id}
              in
              timeout={1000}
              direction="left"
              className={classes.slide}
            >
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {slide}
              </div>
            </Slide>
          )
        }
        return null
      })}
      <div className={classes.slideshowDotsContainer}>
        {slides.map((slide, index) => (
          <SlideDot
            key={`${slide.id}_${index.toString()}`}
            isActive={index === currentIndex}
            handleSelectSlide={() => handleSelectSlide(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default SlideShow
