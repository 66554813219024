import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Emoji from 'components/UI/Emoji'

import { wrench } from 'utils/emojis'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1088,
    padding: theme.spacing(4, 3, 4, 5),
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5),
    },
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  genieImage: {
    justifySelf: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  helpMessage: {
    color: theme.palette.grey[500],
    marginTop: theme.spacing(24.5),
  },
}))

const EmptyState = ({ children, image, title, maintenance = false }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" color="primary">
        {title}
      </Typography>
      <section className={classes.contentContainer}>
        <article>
          {children}
          {maintenance ? (
            <Alert
              severity="info"
              header="Conexión estará deshabilitada temporalmente por mantenimiento en la DIAN."
              icon={false}
            >
              <Emoji code={wrench} />
              Entre {maintenance}.
            </Alert>
          ) : null}
        </article>
        <article>
          <img
            src={image}
            width={308}
            height={352}
            className={classes.genieImage}
            alt="Genio Nominapp"
          />
        </article>
      </section>
    </Paper>
  )
}

export default EmptyState
