import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

export default ({
  startText = 'Si deseas configurar préstamos o deducciones recurrentes para que se apliquen automáticamente en cada periodo',
  hideTooltipInfoIcon,
}) => {
  return (
    <Alert
      severity="info"
      icon={<></>} //workaround to remove its icon
    >
      <Typography variant="body2">
        {startText}, ingresa al perfil del empleado y entra a la pestaña
        Conceptos Recurrentes.
        {!hideTooltipInfoIcon && (
          <TooltipInfoIcon
            title={
              <>
                <MuiLink
                  href="http://ayuda.nominapp.com/knowledge_base/topics/como-registrar-un-ingreso-o-deduccion-que-es-recurrente-se-repite-cada-nomina-a-un-empleado?from_search=true"
                  target="_blank"
                >
                  Aquí
                </MuiLink>{' '}
                puedes encontrar cómo registrar ingresos o deducciones
                recurrentes.
              </>
            }
            interactive
          />
        )}
      </Typography>
    </Alert>
  )
}
