import { useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import headway from 'utils/integrations/scripts/headway'

import CampaignIcon from 'assets/icons/Campaign'

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginRight: theme.spacing(2),
    '& #HW_badge_cont': {
      position: 'absolute !important',
      top: theme.spacing(-0.75),
      right: theme.spacing(1.375),
    },
    '& #HW_badge': {
      border: `2px solid ${theme.palette.common.white}`,
      lineHeight: '13px',
    },
    '& #HW_frame_cont': {
      top: '72.5px',
    },
  },
  icon: {
    color: '#29282B',
    fontSize: '33px',
  },
}))

const Changelog = () => {
  const classes = useStyles()
  const CHANGELOG_ID = process.env.REACT_APP_HEADWAY_CHANGELOG_ID_ADMIN

  const activeHeadway = useCallback(
    () => headway.headwayChangelog(CHANGELOG_ID),
    [CHANGELOG_ID]
  )

  useEffect(() => {
    activeHeadway()
  }, [activeHeadway])

  return (
    <div className={classes.container} id="changelog">
      <CampaignIcon
        fill="primary"
        className={classes.icon}
        onClick={activeHeadway}
      />
    </div>
  )
}
export default Changelog
