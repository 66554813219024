import React, { useState } from 'react'

import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'

import Link from 'components/UI/MaterialUI/Link'

import * as routes from 'config/routes'

import { getDateEndFreeTrial } from 'utils/freemium'
import { formatLongDisplayDate, getDiferenceOfDays } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: 0,
    marginBottom: 0,
  },
}))

const FreeTrialCounter = ({ hasFreeTrial = true }) => {
  const classes = useStyles()
  const PREMIUM_END_DATE = getDateEndFreeTrial()
  const formatPremiumEndDate = formatLongDisplayDate(PREMIUM_END_DATE)
  const remainingDays = getDiferenceOfDays(PREMIUM_END_DATE)
  const [open, setOpen] = useState(() => remainingDays > 0)

  const LinkToSubscription = () => (
    <Link to={routes.SUBSCRIPTION()}>Actualiza tu método de pago aquí</Link>
  )

  return (
    <Collapse in={open}>
      <Alert
        severity="info"
        onClose={hasFreeTrial ? null : () => setOpen(false)}
        className={classes.alert}
      >
        Tu período de prueba termina en{' '}
        <b>
          {remainingDays} {getWordSingular('días', remainingDays === 1)}
        </b>
        . <LinkToSubscription /> antes del <b>{formatPremiumEndDate}</b> y
        conserva todas tus funcionalidades Premium!
      </Alert>
    </Collapse>
  )
}

export default FreeTrialCounter
