import FormField from 'components/UI/Formik/FormField/Index'

import useCostCenterService from 'utils/hooks/company/costCenterService'

import tooltipInfo from 'messages/worker'

const WorkerFormFieldsPersonalCostCenter = () => {
  const { costCenterQuery } = useCostCenterService({
    serviceParams: {
      queryKey: 'costCenters',
    },
  })

  return (
    <FormField
      name="cost_center"
      label="Centro de costos"
      variant="autocomplete"
      placeholder="Selecciona un centro de costos"
      options={costCenterQuery?.data || []}
      tooltipContent={tooltipInfo.costCenter}
    />
  )
}

export default WorkerFormFieldsPersonalCostCenter
