import { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Button from 'components/UI/Button/Button'
import Modal from 'components/UI/Modal/Modal'
import Table from 'components/UI/Table/Table'

import useSocialSecurityService from 'utils/hooks/payroll/socialSecurity'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import { columnsData, formatData, generateTabs } from './helpers'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const ErrorsModal = ({ state, periodId, formType, handleClose, hasErrors }) => {
  const [tab, setTab] = useState(state.initialTab)
  const downloadURI = useDownloadURI()
  const {
    socialSecurityMutation,
    socialSecurityQuery,
  } = useSocialSecurityService({
    serviceParams: {
      queryKey: ['getSSFormErrors', periodId, state?.formType],
      periodId,
      formType,
      format: 'json',
    },
  })
  const errors = formatData(socialSecurityQuery.data)

  const classes = useStyles()

  const getErrorsFile = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'GET',
        periodId,
        formType,
        format: 'xlsx',
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
        },
      }
    )
  }

  const handleChangeTab = (_, newTab) => setTab(newTab)

  const ErrorTabs = generateTabs(tab, handleChangeTab, errors)

  return (
    <Modal
      header={`Tabla de ${
        hasErrors ? 'errores' : 'alertas'
      } planilla seguridad social`}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      onCancel={handleClose}
      isLoading={socialSecurityMutation.isLoading}
      footer={
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={getErrorsFile}
            loading={socialSecurityMutation.isLoading}
          >
            Ver archivo completo
          </Button>
        </div>
      }
    >
      <Table
        data={errors[tab]}
        columns={columnsData}
        options={{
          pagination: false,
        }}
        components={{
          Toolbar: ErrorTabs,
        }}
      />
    </Modal>
  )
}

export default ErrorsModal
