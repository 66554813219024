import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import TutorialCard from '../TutorialCard'
import { tutorialCardsConfirmationDian } from '../helpers'

const useStyles = makeStyles((theme) => ({
  tutorialContent: {
    marginTop: theme.spacing(2.5),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3.5),
  },
}))

const DianConfirmation = () => {
  const classes = useStyles()
  return (
    <article>
      <Typography variant="h6" color="primary" gutterBottom>
        Proceso de habilitación para emitir nómina electrónica
      </Typography>
      <Typography variant="body1">
        Debes verificar el estado de tu SET de pruebas en el portal de la DIAN y
        validar que estás habilitado para emitir tu Nómina Electrónica:
      </Typography>
      <section className={classes.tutorialContent}>
        {tutorialCardsConfirmationDian.map((tutorialCard, index) => (
          <TutorialCard
            key={tutorialCard.id}
            tutorialNumber={tutorialCard.number}
            title={tutorialCard.title}
            description={tutorialCard.description}
            article={tutorialCard.article}
            link={tutorialCard.link}
            dataCy={`tutorial-card-${index + 1}`}
          />
        ))}
      </section>
    </article>
  )
}

export default DianConfirmation
