import { useEffect } from 'react'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'

import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import PaymentSummary from './Summary/Summary'
import getFormattedResponse from './helpers'

const SubscriptionTransactionResponse = ({ location }) => {
  const { user } = useUser()
  const { subscriptionQuery } = useSubscriptionService()
  const { paymentMethodQuery } = usePaymentMethodService({
    serviceParams: { queryKey: 'getPaymentPSE' },
    queryOptions: { enabled: !!location },
  })

  const isGettingQuery =
    subscriptionQuery.isLoading || paymentMethodQuery.isLoading

  const paymentData = location || paymentMethodQuery.data

  const paymentResponseFormatted =
    subscriptionQuery.status === 'success'
      ? getFormattedResponse(
          user,
          paymentData,
          subscriptionQuery?.data,
          paymentMethodQuery?.data
        )
      : {}
  const trackPaymentEvent = location?.state?.payment_status === 'APPROVED'

  useEffect(() => {
    if (trackPaymentEvent) {
      trackEvent(integrationEvent.FIRST_PAYMENT)
    }
  }, [trackPaymentEvent])

  return (
    <Page documentTitle="Respuesta de tu pago" isLoading={isGettingQuery}>
      <PaymentSummary summary={paymentResponseFormatted} />
    </Page>
  )
}

export default SubscriptionTransactionResponse
