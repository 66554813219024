import MuiLink from '@material-ui/core/Link'

const deductionDescription = {
  deductions: (
    <>
      Agrega aquí las deducciones adicionales sobre el pago de la persona en el
      periodo. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/como-realizar-una-deduccion"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  loans: (
    <>
      Agrega aquí el valor a deducir por concepto de préstamos sobre el pago de
      la persona en el periodo. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/como-creo-un-prestamo-para-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  withholding_tax: (
    <>
      Agrega aquí el valor a deducir por concepto de retención en la fuente por
      salarios sobre el pago de la persona en el periodo. Si deseas que Nominapp
      calcule automáticamente este valor o tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.nominapp.com/help/como-realizar-la-retencion-en-la-fuente-por-salarios-a-mi-empleado-en-nominapp"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
}

export default deductionDescription
