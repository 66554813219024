import { Link } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { getWordSingular } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.grey[500],
  },
  entities: {
    fontWeight: 'bold',
  },
}))

const getEntitiesType = (errors) => {
  return errors
    .map((error) => {
      if (error.code === '3008') return 'Caja de Compensación'
      if (error.code === '3009') return 'Salud'
      if (error.code === '3010') return 'Pensión'
      if (error.code === '3011') return 'ARL'
      if (error.code === '3012') return 'Cesantías'

      return null
    })
    .filter(Boolean)
}

const CredentialsAlert = ({ errors = [] }) => {
  const classes = useStyles()
  const credentialsEntities = getEntitiesType(errors)
  const isOneMissingCredential = credentialsEntities.length === 1

  return (
    <div>
      <Typography variant="body1" className={classes.message}>
        Para poder realizar la solicitud de afiliación de tu empleado, es
        necesario que agregues las credenciales de{' '}
        {getWordSingular('las', isOneMissingCredential)}{' '}
        {getWordSingular('administradoras', isOneMissingCredential)} de{' '}
        <span className={classes.entities}>
          {credentialsEntities.toString().replace(/,/g, ', ')}
        </span>{' '}
        en el perfil de tu Empresa.
        <br />
        <br />
        En este{' '}
        <Link
          href="https://ayuda.nominapp.com/help/agrega-las-credenciales-de-las-entidades-de-seguridad-social-para-afiliar-a-tus-empleados?hs_preview=TmzrdqEx-95110183018"
          target="_blank"
        >
          artículo
        </Link>{' '}
        podrás encontrar el paso a paso de cómo realizar el proceso.
      </Typography>
    </div>
  )
}

export default CredentialsAlert
