import { useQuery } from 'react-query'

import { getAllDistributors } from 'services/organizer/distributorService'

import { getQueryKey } from 'utils/reactQuery'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'organizerDistributors') return getAllDistributors()

  return null
}

const useDistributorService = ({
  serviceParams = { queryKey: 'organizerDistributors' },
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restDistributorsResponses } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    distributorsQuery: {
      data,
      ...restDistributorsResponses,
    },
  }
}

export default useDistributorService
