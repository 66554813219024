import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import { useMutation } from 'react-query'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'
import SummaryItem from 'components/UI/Summary/SummaryItem'
import SummaryTotal from 'components/UI/Summary/SummaryTotal'

import useNotifications from 'utils/hooks/useNotifications'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { formatCurrency } from 'utils/format'

import { updateWorkerCredit } from 'services/benefits/credits'

import { WORKER_BENEFITS_CREDIT_FORM } from 'config/routes'

import { useAvistaCreditSimulation } from './helpers'

const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(8, 3, 4.5, 3),
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  conditionsChip: {
    backgroundColor: theme.palette.complementary.yellow,
    marginBottom: theme.spacing(5),
  },
  conditionsTitle: {
    color: theme.palette.grey[700],
  },
  conditionsFields: {
    padding: theme.spacing(6.5, 0),
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  totalCreditContainer: {
    marginBottom: theme.spacing(8.5),
  },
  totalCreditValue: {
    marginTop: theme.spacing(1.5),
  },
  acceptTermsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  acceptTermsCheckbox: {
    background: 'transparent',
  },
  feeMessageContainer: {
    marginTop: theme.spacing(5),
    textAlign: 'justify',
  },
}))

const CreditSummary = ({ creditConditions, isSimulationValid }) => {
  const classes = useStyles()
  const history = useHistory()
  const { values } = useFormikContext()
  const [
    creditApplicationCurrentStep,
    setCreditApplicationCurrentStep,
  ] = useLocalStorage('credit_current_step')
  const { showErrorMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { currentApplicationData } = useAvistaCreditSimulation()

  const openApplicationForm = () => {
    if (creditApplicationCurrentStep === undefined) {
      setCreditApplicationCurrentStep(1)
    }
    history.push(WORKER_BENEFITS_CREDIT_FORM())
  }

  const {
    mutate: updateCreditMutation,
    isLoading: isUpdatingWorkerCredit,
  } = useMutation((data) => updateWorkerCredit(data), {
    onSuccess: () => openApplicationForm(),
    onError: (error) => {
      handleError(error)
    },
  })

  const onSaveCreditSimulation = () => {
    updateCreditMutation({
      workers_credits: [
        {
          id: currentApplicationData?.credit_simulator?.credit_id,
          value: values?.credit_value,
          fee: creditConditions?.feeValue,
          term: values?.number_fees,
        },
      ],
    })
  }

  const sendCreditSimulation = () => {
    if (isSimulationValid && !window.Cypress) {
      onSaveCreditSimulation()
    } else if (window.Cypress) {
      // This is for E2E testing purposes
      openApplicationForm()
    } else {
      showErrorMessage('Para continuar debes hacer la simulación del crédito')
    }
  }

  return (
    <Slide direction="left" timeout={500} in>
      <div className={classes.sidebarContainer}>
        <Chip
          label={
            <Typography variant="subtitle2" className={classes.conditionsTitle}>
              Revisa las condiciones de tu crédito
            </Typography>
          }
          className={classes.conditionsChip}
        />
        <Typography variant="h5">Detalle del crédito</Typography>
        <div className={classes.conditionsFields}>
          <SummaryItem
            label="Monto"
            value={formatCurrency(creditConditions?.creditValue)}
          />
          <SummaryItem
            label="Plazo"
            value={
              creditConditions?.numberFees > 0
                ? `${creditConditions?.numberFees} Cuotas`
                : '-'
            }
          />
          <SummaryItem
            label="Cuota"
            value={formatCurrency(creditConditions?.feeValue)}
          />
          <SummaryItem
            label="Fianza"
            value={formatCurrency(creditConditions?.suretyBond)}
          />
          <SummaryItem
            label="Seguro"
            value={formatCurrency(creditConditions?.insurance)}
          />
          <SummaryItem
            label="4 x 1000"
            value={formatCurrency(creditConditions?.fourOneThousandTax)}
            isLastItem
          />
        </div>
        <div className={classes.totalCreditContainer}>
          <SummaryTotal
            label="Total a desembolsar"
            value={formatCurrency(creditConditions?.disbursementAmount)}
          />
        </div>
        <div className={classes.acceptTermsContainer}>
          <FormField
            name="accept_avista_terms"
            variant="checkbox"
            className={classes.acceptTermsCheckbox}
            fullWidth={false}
            margin="none"
            data-cy="accept-credit-terms"
          />
          <Typography variant="caption" className={classes.greyText}>
            Aceptar términos y condiciones de Avista SAS
          </Typography>
        </div>
        <Button
          disabled={!values?.accept_avista_terms || isUpdatingWorkerCredit}
          fullWidth
          onClick={sendCreditSimulation}
        >
          Solicitar crédito de libranza
        </Button>
        <div className={classes.feeMessageContainer}>
          <Typography variant="caption" className={classes.greyText}>
            {`Esta oferta de crédito tiene una tasa de interés del ${currentApplicationData?.credit_simulator?.rate}%
            efectivo anual y tiene una vigencia de 30 días a partir de la presentación
            del preaprobado al empleado. Esta solicitud está sujeta a verificación y análisis por parte
            del equipo de AVISTA SAS.`}
          </Typography>
        </div>
      </div>
    </Slide>
  )
}

export default CreditSummary
