import OldSubscriptionPaywall from 'components/Subscription/OldSubscription/Paywall/Paywall'
import SubscriptionPaywall from 'components/Subscription/NewSubscription/Index/Index'
import useSubscription from 'components/Subscription/Atoms/useSubscription'

export default (props) => {
  const { showNewSubscription } = useSubscription()

  if (showNewSubscription) return <SubscriptionPaywall {...props} />

  return <OldSubscriptionPaywall {...props} />
}
