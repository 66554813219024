import { useHistory, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Button from 'components/UI/Button/Button'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import TimeStopImg from 'assets/images/errors/stopwatch.png'

import * as routes from 'config/routes'

import PageGetPremium from '../Premium/Page'

function getMessagesByErrorCode(errorCode) {
  let title
  let description

  switch (errorCode) {
    case '0004':
      title = 'Lo sentimos, tu plan actual no te permite añadir más empleados.'
      description = 'Para cambiar de plan o si tienes alguna duda'
      break
    default:
      title = 'Lo sentimos, tu periodo de prueba ha terminado.'
      description =
        'Para continuar liquidando la nómina más rápida de tu vida puedes pagar haciendo click en este botón. Si tienes alguna duda o ya habías realizado tu pago'
      break
  }

  return { title, description }
}

const PaymentRequired = () => {
  const history = useHistory()
  const location = useLocation()

  const error = (location.state && location.state.error) || {}

  const { code } = error

  const { title, description } = getMessagesByErrorCode(code)

  if (
    code === '0006' ||
    code === '0007' ||
    code === '0008' ||
    code === '0009'
  ) {
    return <PageGetPremium code={code} />
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Box width="80%" m={3} textAlign="center">
        <img
          src={TimeStopImg}
          alt="Time's Up"
          style={{ maxWidth: 250, marginBottom: 60 }}
        />

        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {title}
        </Typography>

        <Typography width="75%">
          {description}, escríbenos por el chat o a <OrganizationEmail /> y te
          ayudaremos con gusto en lo que necesites. 🙂
        </Typography>

        {code !== '0004' && (
          <Button
            color="primary"
            onClick={() => history.push(routes.SUBSCRIPTION_STATUS())}
            style={{ margin: 30, padding: '10px 40px' }}
          >
            Ir a Pagar
          </Button>
        )}
      </Box>
    </Grid>
  )
}

export default PaymentRequired
