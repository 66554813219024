import { Fragment } from 'react'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { green } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { formatCurrency } from 'utils/format'

import DataTable from './DataTable'
import Formula from './Formula'
import { calculations, tableSubtitles } from './helpers'

const useStyles = makeStyles((theme) => ({
  calculationTitle: {
    display: 'flex',
  },
  result: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  separator: {
    margin: '50px 0 50px 0',
    color: theme.palette.grey[500],
  },
  icon: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    borderRadius: 5,
    width: '27px',
    height: '27px',
  },
}))

const PeriodDetailsBodyNewSection = ({ activeDetail, details }) => {
  const classes = useStyles()
  const theme = useTheme()

  const sectionDetails = details[activeDetail]

  if (!sectionDetails) return <LoadingBox />

  const sectionDetailsKeys = Object.keys(sectionDetails)

  return sectionDetailsKeys.map((category, keysIndex) => {
    // in some categories there are many elements in an array of objects
    // and in other categories a single element.
    // We make sure it is always in an array.
    const sectionDetailsArray = [].concat(sectionDetails[category])

    return sectionDetailsArray.map((item, index) => {
      const { information, data, totals } = item

      return (
        information && (
          <Fragment key={`${category}_item${index.toString()}`}>
            <Grid container spacing={4}>
              <Grid xs={4} item>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: theme.spacing(2) }}
                  data-cy={`${category}-item-title`}
                >
                  {information.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {information.description}
                </Typography>
                {information.formulas?.map((formula, indexFormula) => (
                  <Formula
                    key={indexFormula.toString()}
                    formula={calculations[formula]}
                  />
                ))}
                {information.link && (
                  <Link href={information.link} target="_blank">
                    <Typography variant="caption">
                      Ver más detalles acerca del cálculo de{' '}
                      {information.title.replace(/[0-9]/g, '').replace('-', '')}
                    </Typography>
                  </Link>
                )}
              </Grid>
              <Grid xs={8} item>
                <div style={{ display: 'flex' }}>
                  <AddIcon className={classes.icon} />
                  <Typography
                    variant="subtitle1"
                    paragraph
                    style={{ marginTop: '1px' }}
                  >
                    {tableSubtitles[category]}
                  </Typography>
                </div>
                <DataTable data={data} name={category} type="new" />
                <Box marginTop={6}>
                  {totals.formulas.map((formula, totalIndex) => (
                    <div key={totalIndex.toString()}>
                      <div className={classes.calculationTitle}>
                        <DoneIcon className={classes.icon} />
                        <Typography
                          variant="subtitle1"
                          paragraph
                          style={{ marginTop: '1px' }}
                        >
                          Cálculo de {formula.label}
                        </Typography>
                      </div>
                      <Formula isTemplate={false} formula={formula} />
                    </div>
                  ))}
                </Box>
                {totals.total.label && (
                  <div className={classes.result}>
                    <DragHandleIcon className={classes.icon} />
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 'bold', marginTop: '1px' }}
                    >
                      {totals.total.label}
                      {' = '}
                      <span style={{ color: green[500] }}>
                        {formatCurrency(totals.total.value)}
                      </span>
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
            {keysIndex < sectionDetailsKeys.length - 1 && (
              <Divider className={classes.separator} />
            )}
          </Fragment>
        )
      )
    })
  })
}

export default PeriodDetailsBodyNewSection
