import { useState, useContext, useRef } from 'react'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import { PeriodContext } from 'components/Period/Payroll/Payroll'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { trackEvent } from 'utils/integration'
import { generateNewCustomConcept } from 'utils/payroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import integrationEvent from 'utils/integrations/events/eventsNames'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useOvertimeService from 'utils/hooks/payroll/overtime'
import { isObjectEmpty } from 'utils/general'

import NewExtraHour from './NewExtraHour'
import OvertimeItem from './Item'
import useContentStyles from '../common/commonStyles'
import {
  validationSchema,
  validateEmptyNewExtraHour,
  overtimeDescription,
  getDataToSend,
  tabs,
} from './helpers'
import useOvertimeModal from './useOvertimeModal'

const useModalStyles = makeStyles({
  paper: {
    width: 650,
  },
})

const useStyles = makeStyles({
  headings: {
    display: 'grid',
    gridTemplateColumns: '70% 1fr 55px',
    justifyItems: 'end',
  },
  othersConceptsContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
})

const OvertimeModal = ({ showModal, handleCloseModal, payroll }) => {
  const classes = useStyles()
  const contentClasses = useContentStyles()
  const modalClasses = useModalStyles()
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState(tabs[0].key)
  const { getOvertimeConcepts, addNewOvertimeItem } = useOvertimeModal()
  const { id: payrollId } = payroll
  const { updatePeriodCallback } = useContext(PeriodContext)
  const [currentOvertimeData, setCurrentOvertimeData] = useState({})
  const [initialData, setInitialData] = useState()
  const [activeNewExtraHour, setActiveNewExtraHour] = useState(false)
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const isInvalidating = useRef(false)
  const overtimeQueryKey = ['overtimeByPayroll', payrollId]
  const payrollConceptsQueryKey = ['payrollConcepts', 'overtime', payrollId]

  const {
    payrollConceptsQuery,
    payrollConceptsMutation,
  } = usePayrollConceptsService({
    serviceParams: {
      queryKey: payrollConceptsQueryKey,
      conceptsCategory: 'overtime',
    },
  })

  const { overtimeQuery, overtimeMutation } = useOvertimeService({
    serviceParams: {
      queryKey: overtimeQueryKey,
      payrollId,
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: ({ data }) => {
        const overtimeConcepts = getOvertimeConcepts(
          payrollConceptsQuery?.data,
          data
        )
        setCurrentOvertimeData(overtimeConcepts)
        setInitialData(overtimeConcepts)
        isInvalidating.current = false
      },
    },
  })

  const isGettingData =
    overtimeQuery.isLoading || payrollConceptsQuery.isLoading

  const isMutatingData =
    overtimeMutation.isLoading ||
    payrollConceptsMutation.isLoading ||
    isInvalidating.current

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const handleShowAddExtraHour = () => {
    setActiveNewExtraHour(true)
  }

  const handleHideExtraHour = () => {
    setActiveNewExtraHour(false)
  }

  const handleChangeItem = (event, itemConceptId, category) => {
    let itemConceptQuantity = Number(event.target.rawValue)

    if (itemConceptQuantity > 200) itemConceptQuantity = 200

    const updatedData = addNewOvertimeItem(
      currentOvertimeData,
      category,
      itemConceptId,
      itemConceptQuantity
    )
    setCurrentOvertimeData((previousOvertimeData) => ({
      ...previousOvertimeData,
      [category]: updatedData,
    }))
  }

  const handleDeleteItem = (payrollConceptId) => {
    payrollConceptsMutation.mutate(
      {
        mutationMethod: 'DELETE',
        payrollConceptId,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          const newOvertimeData = {
            ...currentOvertimeData,
            others: currentOvertimeData.others.filter(
              (item) => item.id !== payrollConceptId
            ),
          }
          setCurrentOvertimeData(newOvertimeData)
        },
      }
    )
  }

  const submitNewExtraHour = (form) => {
    const { values } = form

    const dataToSend = generateNewCustomConcept({
      payrollId,
      payrollConcepts: [values],
      category: 'overtime',
    })

    return payrollConceptsMutation.mutateAsync(
      {
        mutationMethod: 'PUT',
        concepts: dataToSend,
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)
          handleHideExtraHour()
          showSuccessMessage(
            'El nuevo concepto de hora extra fue creado exitosamente.'
          )
          form.resetForm()
        },
      }
    )
  }

  const submitItemsData = () => {
    const dataToSend = getDataToSend(initialData, currentOvertimeData)
    if (dataToSend.length !== 0) {
      return overtimeMutation.mutateAsync(
        {
          mutationMethod: 'PUT',
          payrollId,
          items: dataToSend,
        },
        {
          onSuccess: ({ data }) => {
            updatePeriodCallback(data)
            trackEvent(integrationEvent.PAYROLL_ADD_OVERTIME)
          },
        }
      )
    }
    return null
  }
  const handleClose = () => {
    handleCloseModal()
    queryClient.removeQueries(payrollConceptsQueryKey)
    queryClient.removeQueries(overtimeQueryKey)
  }

  const handleSendData = async (form) => {
    const { values } = form

    let hasValidNewExtraHour = false

    if (activeNewExtraHour) {
      if (validateEmptyNewExtraHour(values)) {
        // if ALL fields are empty, just ignore creating the new extra hour
        // reset form so no validation error message will be shown
        form.resetForm()
      } else if (!form.isValid) {
        // if some field has a valid value
        // set them touched to show validation on other fields
        form.setTouched({ name: true, constant_value: true, quantity: true })
        return
      } else {
        hasValidNewExtraHour = true
      }
    }
    const overtimePromises = []
    let conceptsResponse
    const itemsResponse = submitItemsData()

    if (hasValidNewExtraHour) {
      conceptsResponse = submitNewExtraHour(form)
      overtimePromises.push(conceptsResponse)
    }

    if (itemsResponse) {
      overtimePromises.push(itemsResponse)
    }

    try {
      await Promise.all(overtimePromises)

      if (overtimePromises.length > 0) {
        isInvalidating.current = true
        await queryClient.invalidateQueries(payrollConceptsQueryKey, {
          stale: true,
        })
        await queryClient.invalidateQueries(overtimeQueryKey, { stale: true })
      }

      // only when a new hour has been created the modal will not be closed
      if (!(conceptsResponse || (!conceptsResponse && hasValidNewExtraHour))) {
        handleClose()
      }
    } catch (error) {
      handleError(error)
    }
  }

  const { extra_hours: extraHours, surcharges, others } = currentOvertimeData

  return (
    <Formik
      initialValues={{
        name: '',
        constant_value: '',
        quantity: '',
      }}
      validationSchema={validationSchema}
    >
      {(form) => {
        return (
          <Modal
            open={showModal}
            header="Extras y recargos"
            onOk={() => handleSendData(form)}
            okText="Guardar"
            onCancel={handleClose}
            isLoading={isMutatingData}
            dialogProps={{
              scroll: 'body',
              maxWidth: false,
              classes: modalClasses,
            }}
          >
            <>
              {!isGettingData && !isObjectEmpty(currentOvertimeData) ? (
                <Paper>
                  <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    tabs={tabs}
                  />
                  <div className={contentClasses.root}>
                    <Typography paragraph>
                      {overtimeDescription[activeTab]}
                    </Typography>
                    <div className={classes.headings}>
                      <Typography variant="subtitle2">
                        Valor sobre hora ordinaria
                      </Typography>
                      <Typography variant="subtitle2"># de horas</Typography>
                    </div>
                    {activeTab === 'extra_hours' ? (
                      <OvertimeItem
                        category="extra_hours"
                        data={extraHours}
                        onChange={handleChangeItem}
                      />
                    ) : null}
                    {activeTab === 'surcharges' ? (
                      <OvertimeItem
                        category="surcharges"
                        data={surcharges}
                        onChange={handleChangeItem}
                      />
                    ) : null}
                    {activeTab === 'others' ? (
                      <>
                        {others.length !== 0 ? (
                          <div className={classes.othersConceptsContainer}>
                            <OvertimeItem
                              category="others"
                              data={others}
                              onChange={handleChangeItem}
                              onDeleteItem={handleDeleteItem}
                            />
                          </div>
                        ) : null}
                        <NewExtraHour
                          isActive={activeNewExtraHour}
                          handleShow={handleShowAddExtraHour}
                          handleClose={handleHideExtraHour}
                        />
                      </>
                    ) : null}
                  </div>
                </Paper>
              ) : (
                <LoadingBox />
              )}
            </>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default OvertimeModal
