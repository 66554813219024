import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import Link from 'components/UI/MaterialUI/Link'
import AddButton from 'components/UI/Button/AddButton'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import OptionSelector from 'components/Period/common/OptionSelector'

import messages from 'messages/electronic_payroll'

import useOtherIncomes from './useOtherIncomes'

const useStyles = makeStyles((theme) => ({
  headings: {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    justifyItems: 'flex-start',
  },
  content: {
    padding: theme.spacing(1),
  },
}))

const OtherIncomes = ({ showModal, handleClose, electronicPayrollId }) => {
  const classes = useStyles()

  const {
    handleSubmit,
    handleAddConcept,
    handleSelectItem,
    handleChangeItemValue,
    handleDeleteItem,
    incomes,
    concepts,
    isQueryLoading,
    isMutatingData,
  } = useOtherIncomes(handleClose, electronicPayrollId)

  return (
    <Modal
      open={showModal}
      header="Otros ingresos"
      onOk={() => handleSubmit(incomes)}
      okText="Guardar"
      onCancel={handleClose}
      fullScreenBreakpoint="xs"
      isLoading={isMutatingData}
    >
      <section className={classes.content}>
        <Typography paragraph>
          Aquí podrás agregar todos los Pagos Adicionales que tiene tu empleado
          por medio de Bonos Electrónicos, Dotación, Pagos alimenticios y demás.
          Para conocer en detalle cada uno de estos conceptos, lee este artículo
          de nuestro{' '}
          <Link
            href={messages.ELECTRONIC_PAYROLL_BONUS_CONCEPT}
            target="_blank"
          >
            centro de ayuda.
          </Link>
        </Typography>
        {!isQueryLoading ? (
          <>
            <article className={classes.headings}>
              <Typography variant="subtitle2">Concepto</Typography>
              <Typography variant="subtitle2">Valor</Typography>
            </article>
            <div>
              <OptionSelector
                data={incomes}
                listOptions={concepts}
                onSelectItem={handleSelectItem}
                onChangeItemValue={handleChangeItemValue}
                onDeleteItem={handleDeleteItem}
                category="other_income"
                name="payroll_concept_id"
              />
            </div>

            <AddButton
              onClick={() => handleAddConcept()}
              disabled={concepts.length === incomes.length}
            >
              Agregar ingreso
            </AddButton>
          </>
        ) : (
          <LoadingBox />
        )}
      </section>
    </Modal>
  )
}

export default OtherIncomes
