import { useContext } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { SidebarContext } from '../../Sidebar'

const useCloseDrawer = () => {
  const { handleDrawerToggle } = useContext(SidebarContext)
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return () => {
    if (xsDown) {
      handleDrawerToggle()
    }
  }
}

export default useCloseDrawer
