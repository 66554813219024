import { useFormikContext } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import CreatePayrollConceptModal from 'components/Period/Payroll/DataTable/Modals/CreatePayrollConceptModal'
import CustomField from 'components/UI/Formik/CustomField'
import FormField from 'components/UI/Formik/FormField/Index'
import useWorker from 'components/Worker/useWorker'

import { getAutoCompleteElementFilteredOptions } from 'utils/form'

const IncomesSection = () => {
  const [
    isCreatePayrollConceptModalOpen,
    setIsCreatePayrollConceptModalOpen,
  ] = useState(false)
  const [conceptToCreate, setConceptToCreate] = useState({})
  const { values, setFieldValue } = useFormikContext()
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const incomesConcepts = queryClient.getQueryData(['salary_income', worker.id])
  const nonIncomesConcepts = queryClient.getQueryData([
    'non_salary_income',
    worker.id,
  ])

  const optionsIncomesConcepts = {
    salary_income: incomesConcepts?.data || [],
    non_salary_income: nonIncomesConcepts?.data || [],
  }

  const applySalaryIncome =
    worker.contract_category === 'employee' ||
    worker.contract_category === 'pensioner'

  const categoryOptions = [
    { value: 'salary_income', label: 'Salarial' },
    { value: 'non_salary_income', label: 'No Salarial' },
  ]

  const categoryConcepts =
    optionsIncomesConcepts[values.category]?.map((concept) => ({
      id: concept.id,
      name: concept.name,
    })) || []

  const handleCreateIncomeConcept = (_, newValue) => {
    if (newValue?.id === 'create') {
      setConceptToCreate(newValue.value ? { name: newValue.value } : {})
      setIsCreatePayrollConceptModalOpen(true)
    } else {
      setFieldValue('payroll_concept_id', newValue)
    }
  }

  const handleCloseCreatePayrollConceptModal = () =>
    setIsCreatePayrollConceptModalOpen(false)

  const handleRevalidateConcepts = async () => {
    await queryClient.invalidateQueries(['salary_income', worker.id])
    await queryClient.invalidateQueries(['non_salary_income', worker.id])
  }

  return (
    <>
      <Typography gutterBottom>
        Configura ingresos recurrentes que se agregan de forma automática a la
        nómina.
      </Typography>
      <Typography>
        <b>Recuerda:</b> Si agregas ingresos recurrentes no salariales y estos
        superan el 40% de los ingresos salariales en algún periodo, Nominapp
        calculará el IBC siguiendo lo estipulado en la Ley 1393 de 2010. Conoce
        más{' '}
        <MuiLink
          href="https://ayuda.nominapp.com/help/nominapp-calcula-los-pagos-no-salariales-que-exceden-el-40-del-total-de-la-remuneracion"
          target="_blank"
        >
          aquí
        </MuiLink>
        .
      </Typography>
      <FormField
        label="Tipo"
        name="category"
        variant="select"
        options={categoryOptions}
      />
      <CustomField
        label="Concepto"
        name="payroll_concept_id"
        component="autocomplete"
        placeholder="Escribe el concepto de ingreso a agregar..."
        noOptionsText="Cargando conceptos de ingreso..."
        loadingText="Cargando conceptos de ingreso..."
        options={categoryConcepts}
        onChange={handleCreateIncomeConcept}
        filterOptions={(opt, params) =>
          getAutoCompleteElementFilteredOptions(
            opt,
            params,
            'Crear nuevo concepto de ingreso recurrente'
          )
        }
      />
      <FormField
        label="Valor mensual"
        name="monthly_value"
        variant="currency"
        align="left"
      />
      {isCreatePayrollConceptModalOpen && (
        <CreatePayrollConceptModal
          options={
            applySalaryIncome
              ? ['salary_income', 'non_salary_income']
              : ['non_salary_income']
          }
          conceptToCreate={conceptToCreate}
          handleClose={handleCloseCreatePayrollConceptModal}
          callback={handleRevalidateConcepts}
        />
      )}
    </>
  )
}

export default IncomesSection
