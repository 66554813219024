import clsx from 'clsx'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({
  addIcon: {
    fontSize: '18px',
  },
  popupIndicator: {
    '&.MuiIconButton-root.Mui-disabled': {
      border: 'none',
    },
  },
}))

const AutocompleteField = ({ id, name, options, value, ...props }) => {
  const localClasses = useStyles()
  const {
    setValue,
    setTouched,
    placeholder,
    disabled,
    classes = {},
    error,
    dataCy,
    ...restProps
  } = props
  const { popupIndicator, ...restClasses } = classes

  const onChange = (_, newSelectedValue) => {
    setValue(newSelectedValue)
  }

  const onBlur = () => setTouched(name)

  const getOptionLabel = ({ name: optionName }) => {
    if (optionName && optionName !== '') return optionName

    return ''
  }

  const renderOption = ({ id: optionId, name: optionName }) => (
    <>
      {optionId === 'create' && <AddIcon className={localClasses.addIcon} />}
      {optionName}
    </>
  )

  const renderInput = ({ InputProps, InputLabelProps, ...params }) => {
    return (
      <OutlinedInput
        {...params}
        {...InputProps}
        {...InputLabelProps}
        placeholder={placeholder}
        error={error}
        data-cy={dataCy}
      />
    )
  }

  const getOptionSelected = (option, newValue) =>
    option.id === newValue.id ? newValue : null

  return (
    <Autocomplete
      id={id}
      options={options}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      openOnFocus
      autoHighlight
      classes={{
        popupIndicator: clsx(localClasses.popupIndicator, popupIndicator),
        ...restClasses,
      }}
      {...restProps}
    />
  )
}

export default AutocompleteField
