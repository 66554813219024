import useLocalStorage from '@rehooks/local-storage'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import { ButtonBase, Tooltip, Typography } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { helpOnClick } from 'utils/chat'
import { isTest } from 'utils/general'

import SupportAgent from 'assets/icons/SupportAgent'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(12),
    pointerEvents: 'auto',
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.complementary.strongOrange,
    '&:hover': {
      backgroundColor: theme.palette.complementary.strongOrange,
    },
    zIndex: 1110,
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(3),
    },
  },
  activeButton: {
    bottom: theme.spacing(3),
    transition: 'bottom 0.3s ease-in-out',
  },
  inactiveButton: {
    bottom: theme.spacing(12),
    transition: 'bottom 0.3s ease-in-out',
  },
  hubspotContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(12),
    zIndex: 1101,
    maxHeight: 500,
    height: '100%',
    maxWidth: 320,
    '& #hubspot-conversations-inline-iframe': {
      width: '100%',
      maxWidth: 320,
      maxHeight: 500,
      height: '100%',
      borderRadius: '10px',
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    '& #hubspot-conversations-inline-parent': {
      maxHeight: 500,
      height: '100%',
    },
    [theme.breakpoints.up(370)]: {
      width: '100%',
      maxWidth: 320,
    },
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(3),
      maxWidth: 400,
      '& #hubspot-conversations-inline-iframe': {
        width: '100%',
        maxWidth: 400,
      },
    },
  },
  closedHubspotContainer: {
    display: 'none',
  },
  icon: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    position: 'relative',
  },
  tooltipText: {
    color: theme.palette.grey[500],
  },
  tooltipButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: theme.palette.grey[500],
  },
  popper: {
    zIndex: 1299,
  },
}))

const FloatingActionGroup = () => {
  const [showChat, setShowChat] = useState(false)
  const classes = useStyles()
  const opened = useRef(false)
  const [showChatAlert, setShowChatAlert] = useLocalStorage('show_chat_alert')
  const [showAlert, setShowAlert] = useState(() => {
    if ((showChatAlert !== undefined && showChatAlert === false) || isTest)
      return false

    return true
  })

  const handleOpenChat = () => {
    setShowChat(true)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
    setShowChatAlert(false)
  }

  const openActionGroup = () => {
    if (showAlert) {
      handleCloseAlert()
    }

    if (!opened.current) {
      if (window.HubSpotConversations) {
        opened.current = true
      }

      helpOnClick(handleOpenChat)
    } else {
      opened.current = false

      if (
        window.HubSpotConversations &&
        window.HubSpotConversations.widget.status().loaded
      ) {
        window.HubSpotConversations.widget.remove()
        setShowChat(false)
      }
    }
  }

  return (
    <>
      <div id="app-floating-action-group">
        <Tooltip
          open={showAlert}
          interactive
          title={
            <div>
              <ButtonBase
                className={classes.tooltipButton}
                onClick={handleCloseAlert}
              >
                <CloseIcon fontSize="small" />
              </ButtonBase>
              <Typography variant="body2" className={classes.tooltipText}>
                ¿Necesitas un experto Nominapp? Escríbenos...
              </Typography>
            </div>
          }
          classes={{
            tooltip: classes.tooltip,
            popper: classes.popper,
          }}
          placement="top-end"
        >
          <Fab
            aria-label="Helpdesk"
            className={clsx(classes.fab, {
              [classes.activeButton]: opened.current && showChat,
              [classes.inactiveButton]: !opened.current,
            })}
            onClick={openActionGroup}
          >
            {!opened.current ? (
              <SupportAgent className={classes.icon} />
            ) : (
              <CloseIcon className={classes.icon} />
            )}
          </Fab>
        </Tooltip>
      </div>
      <div
        className={clsx(classes.hubspotContainer, {
          [classes.closedHubspotContainer]: !showChat,
        })}
        id="hubspot-chat_inline-embed"
      />
    </>
  )
}

export default FloatingActionGroup
