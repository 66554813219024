import isSameDay from 'date-fns/isSameDay'
import isValid from 'date-fns/isValid'

import { accountTypesOptions, generateEmptyAreaOrPosition } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import { fillArray } from 'utils/general'

export const formatCompanyData = (dataFormatted) => {
  // asign the data from parallel queries
  const company = dataFormatted[0]
  const locations = dataFormatted[1]
  const workCenters = dataFormatted[2]
  const areas = dataFormatted[3]
  const positions = dataFormatted[4]

  const newCompany = {
    ...company,
  }

  if (company.account_type) {
    newCompany.account_type = accountTypesOptions.find(
      (opt) => opt.value === company.account_type
    )
  }

  if (company?.accounting_provider?.id === null) {
    newCompany.accounting_provider = null
  }
  if (company?.ss_operator?.id === null) {
    newCompany.ss_operator = null
  }
  if (company?.bank?.id === null) {
    newCompany.bank = null
  }
  if (company?.risk_provider?.id === null) {
    newCompany.risk_provider = null
  }
  // #endregion

  newCompany.locations = locations
  newCompany.workCenters = workCenters
  newCompany.areas = areas
  newCompany.positions = positions

  fillArray(newCompany.areas, 5, generateEmptyAreaOrPosition())
  fillArray(newCompany.positions, 5, generateEmptyAreaOrPosition())

  return newCompany
}

const getDirtyAreas = (initialAreas, areas) => {
  const initAreas = {}

  initialAreas.forEach((area) => {
    if (area.id) {
      initAreas[area.id] = { ...area }
    }
  })

  const areasToSend = []

  areas.forEach((area) => {
    if (area.id) {
      if (area.name !== initAreas[area.id].name) {
        areasToSend.push(area)
      }
      initAreas[area.id].checked = true
    } else if (area.name && area.name.trim()) {
      areasToSend.push(area)
    }
  })

  // find for areas to delete
  Object.values(initAreas).forEach((a) => {
    if (!a.checked) {
      // just id is deletion
      areasToSend.unshift({ id: a.id })
    }
  })

  if (areasToSend.length === 0) return undefined

  return areasToSend
}

const getDirtyPositions = (initialPositions, positions) => {
  const initPositions = {}

  initialPositions.forEach((pos) => {
    if (pos.id) {
      initPositions[pos.id] = { ...pos }
    }
  })

  const positionsToSend = []

  positions.forEach((pos) => {
    if (pos.id) {
      if (pos.name !== initPositions[pos.id].name) {
        positionsToSend.push(pos)
      }
      initPositions[pos.id].checked = true
    } else if (pos.name && pos.name.trim()) {
      positionsToSend.push(pos)
    }
  })

  // find for positions to delete
  Object.values(initPositions).forEach((pos) => {
    if (!pos.checked) {
      // just id is deletion
      positionsToSend.unshift({ id: pos.id })
    }
  })

  if (positionsToSend.length === 0) return undefined

  return positionsToSend
}

// for all company values

export const getDirtyValues = (initialValues, values) => {
  const dirtyValues = {}

  Object.keys(values).forEach((key) => {
    const value = values[key]
    const type = typeof value
    const initialValue = initialValues[key]

    if (type === 'object') {
      if (value instanceof Array) {
        if (key === 'areas') {
          const areasResult = getDirtyAreas(initialValues.areas, value)

          if (areasResult) {
            dirtyValues[key] = areasResult
          }
        } else if (key === 'positions') {
          const positionsResult = getDirtyPositions(
            initialValues.positions,
            value
          )

          if (positionsResult) {
            dirtyValues[key] = positionsResult
          }
        }
      } else if (
        isValid(value) &&
        (!initialValue || !isSameDay(initialValue, value))
      ) {
        dirtyValues[key] = formatDate(value)
      } else if (value !== initialValue) {
        if (value) {
          if (value.id) dirtyValues[`${key}_id`] = value.id
          else dirtyValues[key] = value.value
        } else {
          dirtyValues[key] = value
        }
      }
    } else if (value !== initialValue) {
      dirtyValues[key] = value
    }
  })

  return dirtyValues
}
