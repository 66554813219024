import { useFormikContext } from 'formik'

import CustomField from 'components/UI/Formik/CustomField'

import useDepartmentsService from 'utils/hooks/departmentsService'

/**
 * This component is intended to be used in conjunction with the <CitiesList /> component, since when changing the department
 * a query is executed to bring the cities of that department.
 *
 * If the onChange prop is provided, you have the control to receive the new selected value to use it as needed.
 * By default, selecting a new value automatically updates the key "department" in the Formik context in which this component is being used,
 * and also clears the key "city" in that context.
 *
 * For an example of custom usage, see: src/components/Benefits/Credits/Form/Fields/References.jsx
 */

const DepartmentsList = ({
  name = '',
  label = '',
  placeholder = '',
  onChange,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const { departmentsQuery } = useDepartmentsService({
    serviceParams: {
      queryKey: 'departments',
    },
    queryOptions: {
      staleTime: Infinity,
    },
  })
  const handleChange = (_, newSelectedValue) => {
    if (values?.department && values?.department?.id !== newSelectedValue?.id) {
      setFieldValue('city', null)
      setFieldTouched('city')
    }
    setFieldValue('department', newSelectedValue)
  }

  return (
    <CustomField
      component="autocomplete"
      options={departmentsQuery.data || []}
      name={name}
      data-cy={name}
      label={label}
      placeholder={placeholder}
      onChange={onChange || handleChange}
    />
  )
}

export default DepartmentsList
