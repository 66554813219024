import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  endIcon: {
    color: theme.palette.grey[500],
  },
}))

const CalendarIcon = () => {
  const classes = useStyles()

  return <CalendarTodayIcon className={classes.endIcon} />
}

export default CalendarIcon
