import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import useColumnsData from './helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const OrganizerAutomaticDebitResponse = ({ response, setNew }) => {
  const classes = useStyles()
  const columnsData = useColumnsData()

  const approvedData = response?.approved || []
  const failuredData = response?.failure || []

  return (
    <>
      <Box pr={4} py={2} display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={() => setNew(null)}>
          Subir Nuevo Archivo
        </Button>
      </Box>
      <article className={classes.root}>
        <section>
          <Typography variant="subtitle1" gutterBottom>
            Aprobados {approvedData.length}
          </Typography>
          <Table
            data={approvedData}
            columns={columnsData}
            options={{ toolbar: false, pagination: false }}
          />
        </section>
        <section>
          <Typography variant="subtitle1" gutterBottom>
            Rechazadas {failuredData.length}
          </Typography>
          <Table
            data={failuredData}
            columns={columnsData}
            options={{ toolbar: false, pagination: false }}
          />
        </section>
      </article>
    </>
  )
}

export default OrganizerAutomaticDebitResponse
