import Button from '@material-ui/core/Button'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'

const Resume = ({ electronicPayrollPeriodId }) => {
  const { downloadFileMutation } = useElectronicPayrollFileService()
  const downloadFile = useEnqueueDownload()

  const featureState = usePremiumFeature('reports')

  const handleDownload = () => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    downloadFileMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'electronicPayrollSummary',
        electronicPayrollPeriodId,
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'Resumen de nómina electrónica',
            fileCode: 'summary_electronic_payroll',
            pathname: `/electronic_payroll/${electronicPayrollPeriodId}/electronic_payroll_summary`,
          })
        },
      }
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleDownload}
        endIcon={
          <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
        }
      >
        Descargar resumen
      </Button>
    </>
  )
}

export default Resume
