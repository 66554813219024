import isValid from 'date-fns/isValid'
import isSameDay from 'date-fns/isSameDay'
import formatISO from 'date-fns/formatISO'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

/**
 * Checks if a form value is dirty (modified).
 *
 * @param {*} initialValue
 * @param {*} value
 */
export function isValueDirty(initialValue, value) {
  const type = typeof value
  let dirty = false

  if (type === 'object') {
    if (isValid(value)) {
      dirty = !isSameDay(initialValue, value)
    } else if (value !== initialValue) {
      dirty = true
    }
  } else if (value !== initialValue) {
    dirty = true
  }

  return dirty
}

/**
 * Format a value and save it in the object. Mutates the original object 'obj'.
 *
 * @param {object} obj The object where the formatted value will be.
 * @param {string} key
 * @param {*} value
 */
export function formatValue(obj, key, value) {
  const type = typeof value
  const objCopy = { ...obj }

  if (type === 'object') {
    if (value === null) {
      objCopy[key] = value
    } else if (value.id) {
      objCopy[`${key}_id`] = value.id
    } else if (value.value) {
      objCopy[key] = value.value
    } else if (isValid(value)) {
      objCopy[key] = formatISO(value, { representation: 'date' })
    } else {
      objCopy[key] = value
    }
  } else {
    objCopy[key] = value
  }

  return objCopy
}

/**
 * Get dirty (modified) values in the form object.
 *
 * @param {Object} initialValues
 * @param {Object} values
 * @param {Object} fields If not specified, it will use 'values' to get the fields.
 */
export function getDirtyValues(
  initialValues = {},
  values,
  fields = values,
  format = true
) {
  let dirtyValues = {}

  Object.keys(fields).forEach((key) => {
    const initialValue = initialValues[key]
    const value = values[key]

    if (isValueDirty(initialValue, value)) {
      if (format) dirtyValues = formatValue(dirtyValues, key, value)
      else dirtyValues[key] = value
    }
  })

  return dirtyValues
}

/**
 * Format form values to submit.
 * @param {object} values Object with the form values.
 * @param {object} fields Object with the keys to be formatted from the values object.
 * @returns {object} Object with the values formatted ready to submit.
 */
export function formatValues(values, fields = values) {
  let formattedValues = {}

  Object.keys(fields).forEach((key) => {
    const value = values[key]
    formattedValues = formatValue(formattedValues, key, value)
  })

  return formattedValues
}

/**
 * Used when creating in WorkerForm to return to the step whith errors from server validaiton.
 *
 * @deprecated Due to the form is now submitted in every step.
 * @param {Object} errors Formik errors object.
 * @param {Array} stepsData
 */
export const findStepWithError = (formikErrors, stepsData) => {
  let stepIdx = -1

  const stepFields = stepsData.map((step) =>
    Object.keys(step.schemaValidation.fields)
  )

  formikErrors.forEach((key) => {
    for (let idx = 0; idx < stepFields.length; idx += 1) {
      const step = stepFields[idx]

      // eslint-disable-next-line no-loop-func
      const goOut = step.some((field) => {
        if (field === key) {
          stepIdx = idx
          return true
        }
        return false
      })

      if (goOut) {
        break
      }
    }
  })

  return stepIdx
}

// TODO: finish all error messages
export const yupLocaleES = {
  mixed: {
    default: 'No es válido',
    required: 'Debes ingresar este campo.',
    typeError: 'Debes ingresar un valor válido.',
  },
  number: {
    default: 'Debes ingresar un número',
    min: 'Debe ser mayor que ${min}.', // eslint-disable-line no-template-curly-in-string
    max: 'Debe ser menor que ${max}.', // eslint-disable-line no-template-curly-in-string
    positive: 'Debe ser un valor mayor que cero.',
    integer: 'Debe ser un número entero.',
  },
  string: {
    required: 'Debes ingresar este campo.',
    email: 'Debe ser un correo electrónico válido.',
    min: 'Demasiado corto. Mínimo ${min} caracteres.', // eslint-disable-line no-template-curly-in-string
    max: 'Demasiado largo. Máximo ${max} caracteres.', // eslint-disable-line no-template-curly-in-string
  },
}

/**
 * Get the first field name key with error from the formik 'errors' object.
 *
 * Recursive function.
 * @param {*} errorValue
 * @param {*} start MUST be truw the first time is called
 */
export const findFirstErrorStringKey = (errorValue, start) => {
  let stringKey = ''

  if (errorValue instanceof Array) {
    for (let i = 0; i < errorValue.length; i += 1) {
      const item = errorValue[i]

      if (item) {
        stringKey += `[${i}]${findFirstErrorStringKey(item)}`
      }
    }
  } else if (typeof errorValue === 'object') {
    const keys = Object.keys(errorValue)

    if (keys.length > 0) {
      const firstKey = keys[0]
      const firstValue = errorValue[firstKey]

      if (firstValue) {
        if (!start) stringKey += '.'
        stringKey += `${firstKey}${findFirstErrorStringKey(firstValue)}`
      }
    }
  }

  return stringKey
}

export const getAutoCompleteElementFilteredOptions = (
  options,
  params,
  createText
) => {
  const value = params.inputValue
  const filter = createFilterOptions()

  const filteredOptions = filter(options, params)

  if (value !== '') {
    if (!filteredOptions.find((e) => e.name === value))
      filteredOptions.push({
        id: 'create',
        name: `${createText}: '${params.inputValue}'`,
        value,
      })
  } else {
    filteredOptions.push({
      id: 'create',
      name: `${createText}...`,
    })
  }

  return filteredOptions
}
