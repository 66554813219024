import clsx from 'clsx'
import { useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core'

import FormField from 'components/UI/Formik/FormField/Index'

import { useTableStyles } from '../helpers'
import { validateEditFieldMetadata, getIsRowEditable } from './helpers'
import RowCell from './RowCell'

const useStyles = makeStyles(() => ({
  switch: {
    '& .MuiIconButton-root': {
      margin: 0,
    },
  },
  formControl: {
    flexDirection: 'column',
  },
}))

const EditRowField = ({
  name,
  variant = 'textfield',
  placeholder,
  options,
}) => {
  const classes = useStyles()

  return (
    <FormField
      name={name}
      data-cy={name}
      variant={variant}
      placeholder={placeholder}
      options={options}
      className={clsx({
        [classes.switch]: variant === 'switch',
      })}
      formControlClassName={clsx({
        [classes.formControl]: variant === 'switch',
      })}
    />
  )
}

const EditRowCell = ({ cell, editMode }) => {
  const { values } = useFormikContext()
  const { name, field, editOptions } = validateEditFieldMetadata(cell.column)
  const { getCellStyles } = useTableStyles()
  const options =
    typeof editOptions === 'function' ? editOptions(cell, values) : editOptions
  const fieldName =
    typeof name === 'function' ? name(cell, values) : name || cell.column.id
  const isEditable = getIsRowEditable(cell, values, editMode)

  if (isEditable && cell.column.Edit) {
    return (
      <div
        className={getCellStyles(cell.column)}
        style={{
          ...cell.column.cellStyle,
          width: `calc(${cell.column.customWidth} - 32px)`,
        }}
      >
        {cell.render('Edit', { rowValues: values })}
      </div>
    )
  }

  if (isEditable) {
    return (
      <div
        className={getCellStyles(cell.column)}
        style={{
          ...cell.column.cellStyle,
          width: `calc(${cell.column.customWidth} - 32px)`,
        }}
      >
        <EditRowField
          name={fieldName}
          variant={field}
          options={options}
          placeholder={cell.column.placeholder}
        />
      </div>
    )
  }

  return (
    <div
      className={getCellStyles(cell.column)}
      style={{
        ...cell.column.cellStyle,
        width: `calc(${cell.column.customWidth} - 32px)`,
      }}
    >
      <RowCell cell={cell} />
    </div>
  )
}

export default EditRowCell
