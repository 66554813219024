import MuiLink from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import * as organizationConfig from 'config/organization'

const OrganizationEmail = ({ variant }) => {
  const emailByVariant = {
    support: organizationConfig.EMAIL_SUPPORT,
    data: organizationConfig.EMAIL_DATA,
    hello: organizationConfig.EMAIL_HELLO,
    activation: organizationConfig.EMAIL_ACTIVATION,
    billing: organizationConfig.EMAIL_ACCOUNTANT,
  }

  const email = emailByVariant[variant]

  return <MuiLink href={`mailto:${email}`}>{email}</MuiLink>
}

OrganizationEmail.propTypes = {
  variant: PropTypes.oneOf(['support', 'data', 'hello', 'activation']),
}

OrganizationEmail.defaultProps = {
  variant: 'support',
}

export default OrganizationEmail
