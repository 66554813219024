import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const getSelectedValue = (currentValue) => {
  if (currentValue === 'true') return true
  if (currentValue === 'false') return false
  return currentValue
}

const RadioGroupField = ({ id, name, value, options = [], ...props } = {}) => {
  const { setValue, setTouched, row = false, ...restProps } = props

  return (
    <RadioGroup
      id={id}
      name={name}
      value={value ?? false}
      row={row}
      onChange={({ target: { value: selectedValue } }) => {
        const newValue = getSelectedValue(selectedValue)
        setValue(newValue)
        setTouched(name, false)
      }}
      {...restProps}
    >
      {options.map((option) => {
        const { label, value: optionValue } = option

        return (
          <FormControlLabel
            key={optionValue}
            value={optionValue}
            control={<Radio />}
            label={label}
          />
        )
      })}
    </RadioGroup>
  )
}

export default RadioGroupField
