import { useContext } from 'react'

import Button from '@material-ui/core/Button'

import { ElectronicPeriodContext } from '../ElectronicPayroll'

const ShowSummary = () => {
  const { setShowSummary } = useContext(ElectronicPeriodContext) || {}

  const handleShowSummary = () => setShowSummary(true)

  return (
    <Button variant="outlined" onClick={handleShowSummary}>
      Ver resumen
    </Button>
  )
}

export default ShowSummary
