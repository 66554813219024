import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CreditResumeInfoCard from 'components/Benefits/Atoms/ResumeCard'
import DetailSectionContainer from 'components/Benefits/Atoms/DetailSectionContainer'
import StatusBanner from 'components/Benefits/Atoms/StatusBanner'

import { formatCurrency } from 'utils/format'
import { isDataCached } from 'utils/reactQuery'

import crossedDolarSign from 'assets/images/views/worker/crossed_dolar_sign.png'

import { SETTINGS_ADVANCED } from 'config/routes'

import CreditsData from './CreditsData'
import { useWorkersCredits } from './helpers'

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr));',
    },
  },
}))

const EnableBenefitsAction = () => {
  const history = useHistory()

  const onClick = () => {
    history.push(SETTINGS_ADVANCED())
  }

  return <Button onClick={onClick}>Activar beneficios</Button>
}

const BenefitsShow = ({ benefitsEnabled }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const cachedWorkersCredits = queryClient.getQueryData('workersCredits') || {}

  useWorkersCredits({
    enabled: !isDataCached(cachedWorkersCredits),
  })

  const { data: workersCreditsData } = cachedWorkersCredits

  if (!benefitsEnabled) {
    return (
      <StatusBanner
        title="Aún no tienes activado ningún beneficio para tus empleados"
        message="Activa los beneficios para empleados y permite que puedan acceder a programas de financiamiento exclusivos que les permitan alcanzar sus metas más fácil."
        image={crossedDolarSign}
        imageAlt="Image you don't have any benefits activated"
        action={<EnableBenefitsAction />}
      />
    )
  }

  return (
    <>
      <DetailSectionContainer title="Información general">
        <div
          className={classes.cardsContainer}
          data-cy="credits-totals-container"
        >
          <CreditResumeInfoCard
            headerTitle="Créditos activos"
            creditData={workersCreditsData?.totals.active_credits}
            cardBold
          />
          <CreditResumeInfoCard
            headerTitle="Pre aprobados"
            creditData={workersCreditsData?.totals.preapproveds}
          />
          <CreditResumeInfoCard
            headerTitle="Solicitudes a crédito "
            creditData={workersCreditsData?.totals.credit_requests}
          />
          <CreditResumeInfoCard
            headerTitle="Deducciones de nómina"
            creditData={formatCurrency(
              workersCreditsData?.totals.payroll_deductions
            )}
          />
        </div>
      </DetailSectionContainer>
      <DetailSectionContainer title="Créditos activos" gutterBottom={false}>
        <CreditsData data={workersCreditsData?.company_workers_credits} />
      </DetailSectionContainer>
    </>
  )
}
export default BenefitsShow
