import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import AddButton from 'components/UI/Button/AddButton'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import TermField from 'components/Worker/Contract/Fields/Term'
import ContractModal, {
  validateCreateContract,
} from 'components/Worker/Contract/Modal/ContractModal'
import useContractModal from 'components/Worker/Contract/Modal/useContractModal'
import TransportSubsidyField from 'components/Worker/Wage/Fields/TransportSubsidy'
import useWorker from 'components/Worker/useWorker'

import { weekdaysOptions } from 'utils/dateTime'
import useContractsService from 'utils/hooks/worker/contracts'

import * as routes from 'config/routes'

import messages from 'messages/worker'

const ContractDataSection = ({ onClose }) => {
  const { values } = useFormikContext()
  const queryClient = useQueryClient()
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const confirm = useConfirm()
  const history = useHistory()

  const {
    contractModalState,
    openContractModal,
    closeContractModal,
  } = useContractModal(worker.id)

  const { contractsQuery } = useContractsService({
    serviceParams: { queryKey: 'currentContract', workerId: worker.id },
    queryOptions: { enabled: Boolean(worker.contract_id) },
  })

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const contractModalCallback = () => {
    queryClient.invalidateQueries(['currentContract', worker.id])
    refreshWorker()
    onClose()
  }

  const handleCreateContract = () => {
    validateCreateContract({
      currentContract: contractsQuery.data,
      currentPeriod: { end_day: contractsQuery.data?.period_end_day },
      handleRedirectToTermination: () => {
        history.push(
          routes.WORKER_CONTRACT_TERMINATION_NEW(
            worker.id,
            contractsQuery.data?.id
          )
        )
      },
      handleShowModal: openContractModal,
      confirm,
    })
  }

  return (
    <>
      <ContractCategoryField name="contract_category" />
      <TermField contractCategory={values.contract_category} />
      {!contractsQuery.isLoading && (
        <AddButton
          variant="text"
          onClick={handleCreateContract}
          style={{ alignSelf: 'end' }}
        >
          Nuevo Contrato
        </AddButton>
      )}
      <InitialDayField />
      <EndDayField />
      <TransportSubsidyField contractCategory={values.contract_category} />
      <RiskTypeField
        contractCategory={values.contract_category}
        isEditContract
      />
      {values.contract_category &&
        [
          'employee',
          'pensioner',
          'student_law_789',
          'student_decree_055',
          'part_time_contract',
        ].includes(values.contract_category) && (
          <>
            {values.contract_category !== 'part_time_contract' && (
              <FormField
                name="rest_days"
                label="Días de descanso"
                variant="checkbox-group"
                options={weekdaysOptions}
                row={lgUp}
              />
            )}
            <FormField
              name="accumulated_holidays"
              label="Días de Vacaciones acumuladas"
              type="number"
              align="left"
              tooltipContent={messages.accumulated_holidays_editing}
            />
          </>
        )}
      <ContractModal
        state={contractModalState}
        handleClose={closeContractModal}
        submitCallback={contractModalCallback}
      />
    </>
  )
}

export default ContractDataSection
