import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'
import usePeriodService from 'utils/hooks/organizer/periodService'

import { getAllPayments } from 'services/subscription/subscriptionService'

import CurrentPaymentModal from '../Modals/CurrentPaymentModal'
import useCurrentPaymentModal from '../Modals/useCurrentPaymentModal'
import { getColumnsData, getTableActions } from '../../helpers'

const SubscriptionStatusPayments = ({ setIsLoading }) => {
  const [actionsDisabled, setActionsDisabled] = useState(false)

  const {
    currentPaymentModalState,
    openCurrentPaymentModal,
    closeCurrentPaymentModal,
  } = useCurrentPaymentModal()

  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const refetchPayments = () =>
    queryClient.invalidateQueries(['getAllPayments', getCompanyId()], {
      exact: false,
    })

  const confirm = useConfirm()
  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: {
      enabled: false,
    },
  })
  const { companyPaymentMutation } = useCompanyPaymentService({
    queryOptions: {
      enabled: false,
    },
  })
  const { periodMutation } = usePeriodService()

  const fetchPayments = (pageIndex) => {
    return {
      queryKey: ['getAllPayments', getCompanyId(), pageIndex + 1],
      queryFunction: () => getAllPayments({ page: pageIndex + 1 }),
    }
  }

  const handleSendInvoice = (paymentId) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'POST',
        paymentId,
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleReInvoice = () => {
    setIsLoading(true)

    periodMutation.mutate(
      {
        mutationMethod: 'PATCH',
      },
      {
        onSuccess: () => {
          setActionsDisabled(true)
          showSuccessMessage('En unos minutos se generará la factura.')
          setIsLoading(false)
        },
        onError: (error) => {
          handleError(error, null, {
            notistackOptions: {
              preventDuplicate: true,
            },
          })
          setIsLoading(false)
        },
      }
    )
  }

  const handleRemovePayment = (paymentId) => {
    companyPaymentMutation.mutate(
      {
        mutationMethod: 'DELETE',
        paymentId,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          refetchPayments()
        },
      }
    )
  }

  const handleClickAction = (paymentId, action) => {
    if (action === 're-invoice') {
      handleReInvoice()
    } else {
      handleSendInvoice(paymentId)
    }
  }

  const submitModalCallback = (message) => {
    showSuccessMessage(message)

    refetchPayments()
  }

  const columnsData = getColumnsData(handleClickAction, actionsDisabled)
  const tableActions = getTableActions(
    openCurrentPaymentModal,
    handleRemovePayment,
    confirm
  )

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchPayments}
        actions={tableActions}
        options={{ toolbar: false }}
      />

      {currentPaymentModalState.open && (
        <CurrentPaymentModal
          state={currentPaymentModalState}
          handleClose={closeCurrentPaymentModal}
          submitCallback={submitModalCallback}
        />
      )}
    </>
  )
}

export default SubscriptionStatusPayments
