import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'

import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

const getColumnsData = (showPayslip) => {
  return [
    {
      Header: 'Periodo',
      accessor: 'initial_day',
      Cell: ({ row }) => (
        <Tooltip title="Descargar colilla de pago">
          <Button
            variant="text"
            onClick={() => showPayslip(row.original)}
            startIcon={<DescriptionOutlinedIcon />}
          >
            {formatDateRange(row.original.initial_day, row.original.end_day)}
          </Button>
        </Tooltip>
      ),
    },
    {
      Header: 'Salarios',
      accessor: 'salary',
      Cell: ({ row }) => formatCurrency(row.original.salary),
    },
    {
      Header: 'Otros ingresos',
      accessor: 'other_income',
      Cell: ({ row }) => formatCurrency(row.original.other_income),
    },
    {
      Header: 'Deduc. y Reten.',
      accessor: 'deductions_retentions',
      Cell: ({ row }) =>
        `(${formatCurrency(row.original.deductions_retentions)})`,
    },
    {
      Header: 'Pago Neto',
      accessor: 'worker_payment',
      Cell: ({ row }) => <b>{formatCurrency(row.original.worker_payment)}</b>,
    },
  ]
}

export default getColumnsData
