import { cloneElement } from 'react'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import esLocale from 'date-fns/locale/es'

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

import DeleteIconButton from 'components/UI/Button/DeleteIconButton'

import CustomDatePickerToolbar from './CustomDatePickerToolbar'
import { getDateRangeDaysClassification } from './helpers'

const useStyles = makeStyles((theme) => ({
  dateRangeItemContainer: {
    display: 'grid',
    gridTemplateColumns: '115px 32px 115px 30px',
    alignItems: 'center',
    justifyItems: 'center',
  },
  highlight: {
    backgroundColor: theme.palette.secondary.main,
    '& .MuiPickersDay-day': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  firstHighlight: {
    background: `linear-gradient(to right, transparent 50%, ${theme.palette.secondary.main} 50%)`,
    '& .MuiPickersDay-day': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  endHighlight: {
    background: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, transparent 50%)`,
    '& .MuiPickersDay-day': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  highlightExcluded: {
    backgroundColor: theme.palette.secondary.light,
    '& .MuiPickersDay-day': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  firstHighlightExcluded: {
    background: `linear-gradient(to right, transparent 50%, ${theme.palette.secondary.light} 50%)`,
    '& .MuiPickersDay-day': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  endHighlightExcluded: {
    background: `linear-gradient(to right,  ${theme.palette.secondary.light} 50%, transparent 50%)`,
    '& .MuiPickersDay-day': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  sameFirstEndHighlight: {
    background: 'transparent',
  },
}))

// TODO: reimplemenet with DateRangePicker in material-ui-pickers v4
const NoveltiesDateRangeItem = ({
  initialDay,
  initialMinDate,
  initialMaxDate,
  endDay,
  endMinDate,
  endMaxDate,
  noveltyType,
  noveltyCode,
  index,
  excludedDates, // already selected dates
  onChangeNovelty,
  onDeleteNovelty,
  isNoveltyFromThisPayroll,
}) => {
  const classes = useStyles()

  const renderDay = (day, _selectedDate, _dayInCurrentMonth, dayComponent) => {
    let isExcludedDayBetween = false
    let isExcludedDayFirstDay = false
    let isExcludedDayLastDay = false

    excludedDates.some((excludedDateGroup) => {
      const excludedDateGroupStart = excludedDateGroup[0]
      const excludedDateGroupEnd =
        excludedDateGroup[excludedDateGroup.length - 1]

      return excludedDateGroup.some((excludedDate) => {
        // is this day excluded
        if (isSameDay(day, excludedDate)) {
          ;[
            isExcludedDayBetween,
            isExcludedDayFirstDay,
            isExcludedDayLastDay,
          ] = getDateRangeDaysClassification(
            excludedDate,
            excludedDateGroupStart,
            excludedDateGroupEnd
          )

          return true
        }

        return false
      })
    })

    const [
      isDayBetween,
      isFirstDay,
      isLastDay,
    ] = getDateRangeDaysClassification(day, initialDay, endDay)

    const newDayComponent = cloneElement(dayComponent, {
      hidden: false, // to show days that don't belong to the month
    })

    const wrapperClassName = clsx({
      [classes.highlight]: isDayBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
      [classes.highlightExcluded]: isExcludedDayBetween,
      [classes.firstHighlightExcluded]: isExcludedDayFirstDay,
      [classes.endHighlightExcluded]: isExcludedDayLastDay,
      [classes.sameFirstEndHighlight]:
        (isExcludedDayFirstDay && isExcludedDayLastDay) ||
        (isFirstDay && isLastDay),
    })

    return <div className={wrapperClassName}>{newDayComponent}</div>
  }

  const checkIsBeforeCurrentPeriod = (day) => isBefore(day, initialMinDate)

  const checkIsExcluded = (day) => {
    excludedDates.forEach((excludedDateGroup) =>
      // necessary because default return is not needed
      // eslint-disable-next-line consistent-return
      excludedDateGroup.forEach((excludedDate) => {
        if (isSameDay(day, excludedDate)) return true
      })
    )

    return false
  }

  const checkDisabledInitialDay = (day) => {
    return checkIsExcluded(day) || checkIsBeforeCurrentPeriod(day)
  }

  const checkDisabledEndDay = (day) => {
    return (
      checkIsExcluded(day) ||
      (initialDay ? isBefore(day, initialDay) : checkIsBeforeCurrentPeriod(day))
    )
  }

  const idInitialDP = `initial-date-picker-${noveltyType}-${noveltyCode}-${index}`
  const idEndDP = `end-date-picker-${noveltyType}-${noveltyCode}-${index}`

  const disabledControls =
    noveltyType === 'holidays' && !isNoveltyFromThisPayroll

  const enabledRenderDay = initialDay && endDay

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <div className={classes.dateRangeItemContainer}>
        <MuiDatePicker
          id={idInitialDP} // label for
          placeholder="Día inicio"
          margin="dense"
          format="dd/MM/yyyy"
          value={initialDay}
          initialFocusedDate={initialMinDate}
          onChange={(date) => {
            onChangeNovelty(
              noveltyType,
              noveltyCode,
              index,
              date,
              'initial_day'
            )
          }}
          autoOk
          inputVariant="outlined"
          minDate={initialMinDate}
          minDateMessage={null}
          maxDate={initialMaxDate}
          renderDay={enabledRenderDay ? renderDay : null}
          shouldDisableDate={checkDisabledInitialDay}
          inputProps={{
            id: idInitialDP,
            'data-cy': `${noveltyCode}-${index}-initial_day`,
          }}
          ToolbarComponent={(props) => (
            <CustomDatePickerToolbar title="Día inicio" {...props} />
          )}
          okLabel="Aceptar"
          cancelLabel="Cancelar"
          DialogProps={{
            'data-cy': `${noveltyCode}-${index}-initial_day-picker`,
          }}
          disabled={disabledControls || isBefore(initialDay, initialMinDate)}
        />
        <ArrowForwardOutlinedIcon color="primary" />
        <MuiDatePicker
          id={idEndDP}
          placeholder="Día final"
          margin="dense"
          format="dd/MM/yyyy"
          value={endDay}
          initialFocusedDate={initialMaxDate - 1}
          onChange={(date) => {
            onChangeNovelty(noveltyType, noveltyCode, index, date, 'end_day')
          }}
          autoOk
          inputVariant="outlined"
          minDate={endMinDate}
          maxDate={endMaxDate}
          maxDateMessage={null}
          renderDay={enabledRenderDay ? renderDay : null}
          shouldDisableDate={checkDisabledEndDay}
          inputProps={{
            id: idEndDP,
            'data-cy': `${noveltyCode}-${index}-end_day`,
          }}
          ToolbarComponent={(props) => (
            <CustomDatePickerToolbar title="Día final" {...props} />
          )}
          okLabel="Aceptar"
          cancelLabel="Cancelar"
          DialogProps={{
            'data-cy': `${noveltyCode}-${index}-end_day-picker`,
          }}
          disabled={disabledControls}
        />
        {(initialDay || endDay) && (
          <DeleteIconButton
            size="small"
            onClick={() => onDeleteNovelty(noveltyType, noveltyCode, index)}
            style={{ marginLeft: 5 }}
            data-cy={`delete-${noveltyCode}-${index}-item`}
            disabled={disabledControls}
          />
        )}
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default NoveltiesDateRangeItem
