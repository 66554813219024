import * as yup from 'yup'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  comment: yup.string().trim().nullable().required(),
})

export const columnsData = [
  {
    Header: 'Comentario',
    accessor: 'comment',
    field: 'textfield',
    name: 'comment',
    editable: true,
  },
  {
    Header: 'Correo electrónico',
    accessor: 'email',
    editable: false,
  },
  {
    Header: 'Fecha de creación',
    accessor: 'created_at',
    Cell: ({ row }) => formatDisplayDateString(row.original.created_at),
    editable: false,
  },
]
