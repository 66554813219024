import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SvgLoading from './SvgLoading'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000, //arbitrary higher number to be in top of modals
  },
  paper: {
    minWidth: 400,
    width: 400,
    padding: theme.spacing(4),
    textAlign: 'center',
  },
}))

const LoadingModal = ({
  header = 'Estamos procesando la información...',
  text = 'En unos instantes estaremos listos.',
}) => {
  const classes = useStyles()

  return (
    <Backdrop open className={classes.backdrop}>
      <Paper className={classes.paper} data-cy="loading_modal">
        <Typography variant="h5">{header}</Typography>
        <SvgLoading />
        <Typography>{text}</Typography>
      </Paper>
    </Backdrop>
  )
}

export default LoadingModal
