import nominappColors from './nominapp'

export default {
  ...nominappColors,
  PRIMARY: {
    900: '#A91E37',
    700: '#C72D49',
    600: '#EA3B56',
    500: '#8B1833',
    400: '#DC7082',
    200: '#FAEAF0',
  },
  SECONDARY: {
    // the same as PRIMARY
    900: '#A91E37',
    700: '#C72D49',
    600: '#EA3B56',
    500: '#8B1833',
    400: '#DC7082',
    200: '#FAEAF0',
  },
  GREY: {
    900: '#1F1F21',
    700: '#404143',
    500: '#757171',
    400: '#BABCBD',
    200: '#C4C6D5',
    100: '#C9C9C9',
  },
}
