import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import featuresPerPlan from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 20,
    boxShadow: theme.shadows[3],
    maxWidth: 400,
    width: '100%',
    padding: theme.spacing(4, 3, 4, 3),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'fit-content',
  },
  premiumContainer: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up(800)]: {
      justifySelf: 'end',
    },
  },
  badge: {
    backgroundColor: `${theme.palette.common.white}20`,
    marginBottom: theme.spacing(1),
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  premiumTitle: {
    color: theme.palette.secondary.main,
  },
  featureIcon: {
    color: theme.palette.success.main,
    width: 20,
    height: 20,
    marginRight: theme.spacing(1.25),
  },
  featureContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  featuresContainer: {
    marginBottom: theme.spacing(3.75),
  },
  divider: {
    width: '100%',
    height: 0,
    margin: theme.spacing(1, 0, 1.5, 0),
    border: `1px dashed ${theme.palette.grey[100]}50`,
  },
  priceBadge: {
    backgroundColor: theme.palette.primary.lighter,
    height: 40,
    borderRadius: 30,
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    marginBottom: theme.spacing(3),
  },
  premiumPriceBadge: {
    backgroundColor: `${theme.palette.common.white}20`,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  premiumButton: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}80`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  moreFeaturesTitle: {
    margin: theme.spacing(1.5, 0, 3.75, 0),
  },
}))

const NewPlanCard = ({
  planId,
  name,
  baseValue,
  workerValue,
  onSelect,
  isPremiumPlan,
}) => {
  const classes = useStyles()
  const features = featuresPerPlan.filter((feature) => feature.plan === planId)

  return (
    <Paper
      className={clsx(classes.container, {
        [classes.premiumContainer]: isPremiumPlan,
      })}
    >
      {isPremiumPlan ? (
        <Chip label="El favorito" className={classes.badge} />
      ) : null}
      <Typography
        variant="h5"
        color="primary"
        className={clsx(classes.title, {
          [classes.premiumTitle]: isPremiumPlan,
        })}
      >
        Nominapp {name}
      </Typography>
      {!isPremiumPlan ? (
        <Typography variant="body2" className={classes.moreFeaturesTitle}>
          Todas las <b>Funcionalidades Premium</b> más:
        </Typography>
      ) : null}
      <div className={classes.featuresContainer}>
        {features.map(({ label }) => {
          return (
            <div key={label} className={classes.featureContainer}>
              <CheckCircleIcon className={classes.featureIcon} />
              <Typography
                variant="body2"
                className={clsx({
                  [classes.whiteText]: isPremiumPlan,
                })}
              >
                {label}
              </Typography>
            </div>
          )
        })}
      </div>
      <Typography
        variant="body2"
        className={clsx({
          [classes.whiteText]: isPremiumPlan,
        })}
      >
        <b>Usuarios administradores:</b> Ilimitados
      </Typography>
      <Typography
        variant="body2"
        className={clsx({
          [classes.whiteText]: isPremiumPlan,
        })}
      >
        Soporte por chat en tiempo real
      </Typography>
      <Divider className={classes.divider} />
      <Typography
        variant="h4"
        color="primary"
        className={clsx({
          [classes.whiteText]: isPremiumPlan,
        })}
        gutterBottom
      >
        {`${baseValue} / Mes`}
      </Typography>
      <Chip
        label={`+ ${workerValue} / Persona`}
        className={clsx(classes.priceBadge, {
          [classes.premiumPriceBadge]: isPremiumPlan,
        })}
      />
      <Button
        fullWidth
        size="large"
        className={clsx({
          [classes.premiumButton]: isPremiumPlan,
        })}
        onClick={onSelect}
      >
        Mejorar mi plan
      </Button>
    </Paper>
  )
}

export default NewPlanCard
