import { useFormikContext } from 'formik'
import { nanoid } from 'nanoid'

import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import CustomField from 'components/UI/Formik/CustomField'
import CustomInputRow from 'components/Benefits/Atoms/CustomInputRow'

import useNotifications from 'utils/hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  percentageContainer: {
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: 228,
    },
  },
  inputsContainer: {
    display: 'flex',
    flex: 1,
    marginRight: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  removeButton: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      paddingBottom: theme.spacing(0.5),
    },
  },
}))

const ParticipationPercentages = ({
  title,
  name,
  gutterBottom,
  component,
  buttonProps,
}) => {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext()
  const { showErrorMessage } = useNotifications()
  const {
    company_participation_percentages_attributes: participationPercentages,
  } = values
  const companyPercentagesEmpty =
    participationPercentages && participationPercentages?.length === 0

  const addCompanyPercentage = () => {
    if (participationPercentages?.length < 5) {
      const lastCompanyPercentage = companyPercentagesEmpty
        ? undefined
        : participationPercentages[participationPercentages?.length - 1]

      if (
        companyPercentagesEmpty ||
        (lastCompanyPercentage &&
          !(
            lastCompanyPercentage?.name === null ||
            lastCompanyPercentage?.name === '' ||
            lastCompanyPercentage?.percentage === null ||
            lastCompanyPercentage?.percentage === ''
          ))
      ) {
        const uniqueKey = nanoid()

        setFieldValue(name, [
          ...participationPercentages,
          {
            key: uniqueKey,
            id: null,
            name: null,
            percentage: null,
          },
        ])
      } else {
        showErrorMessage(
          'Debes completar el nombre de la empresa y su porcentaje'
        )
      }
    } else {
      showErrorMessage('Solo puedes agregar un máximo de 5 empresas')
    }
  }

  const removeCompanyPercentage = (itemIndex) => {
    const filteredPercentages = participationPercentages
      ?.map((currentPercentage, index) => {
        if (itemIndex === index) {
          const { id } = currentPercentage
          if (id !== null) {
            return {
              id,
              _destroy: 1,
            }
          }

          return null
        }

        return currentPercentage
      })
      .filter((percentage) => percentage !== null)

    setFieldValue(name, filteredPercentages)
  }

  const newButtonProps = {
    ...buttonProps,
    onClick: addCompanyPercentage,
  }

  return (
    <CustomInputRow
      title={title}
      gutterBottom={gutterBottom}
      component={component}
      buttonProps={newButtonProps}
    >
      {!companyPercentagesEmpty ? (
        <div className={classes.container}>
          {(participationPercentages || []).map((companyPercentage, index) => {
            const { _destroy: destroyFlag } = companyPercentage
            if (!destroyFlag) {
              return (
                <div
                  className={classes.rowContainer}
                  key={companyPercentage?.key || companyPercentage?.id}
                >
                  <div className={classes.inputsContainer}>
                    <CustomField
                      name={`${name}[${index}].name`}
                      label="Nombre de empresa"
                      placeholder="Nombre de la empresa"
                    />
                    <div className={classes.percentageContainer}>
                      <CustomField
                        name={`${name}[${index}].percentage`}
                        label="Participación"
                        component="number-format"
                        startAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.removeButton}>
                    <IconButton onClick={() => removeCompanyPercentage(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              )
            }
            return null
          })}
        </div>
      ) : null}
    </CustomInputRow>
  )
}

export default ParticipationPercentages
