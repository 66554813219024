import CheckIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/Remove'

import { useTableStyles } from '../helpers'

const renderCellContent = (cell, renderEmpty) => {
  if (renderEmpty) return cell.column.emptyValue

  if (cell.column.type !== undefined) {
    if (cell.column.type === 'boolean')
      return cell.value === true ? <CheckIcon /> : <RemoveIcon />
    return null
  }

  return cell.render('Cell')
}

const RowCell = ({ cell }) => {
  const { getCellStyles } = useTableStyles()
  const hasEmptyValue =
    cell.column.emptyValue !== undefined &&
    cell.column.emptyValue !== null &&
    (cell.value === undefined || cell.value === null) &&
    cell.column.id !== 'actions'

  return (
    <div
      className={getCellStyles(cell.column)}
      style={{
        ...cell.column.cellStyle,
        width: `calc(${cell.column.customWidth} - 32px)`,
      }}
    >
      {renderCellContent(cell, hasEmptyValue)}
    </div>
  )
}

export default RowCell
