import ToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'
import ToolbarActions from 'components/UI/Table/Toolbar/ToolbarActions'

import generateTabs from './TabsPayroll'

const PayrollToolbar = (activeTab, handleChangeTab, contractsCounter) => (
  props
) => {
  const { setGlobalFilter, globalFilter, freeActions } = props

  const Tabs = generateTabs(activeTab, handleChangeTab, contractsCounter)
  return (
    <>
      <Tabs />
      <ToolbarContainer {...props}>
        <ToolbarSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div>
          {freeActions && freeActions.length > 0 ? (
            <ToolbarActions freeActions={freeActions} />
          ) : null}
        </div>
      </ToolbarContainer>
    </>
  )
}

export default PayrollToolbar
