import clsx from 'clsx'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import EditButton from 'components/UI/Button/EditButton'
import useWorker from 'components/Worker/useWorker'

import { formatCurrency } from 'utils/format'
import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'
import { isDataCached } from 'utils/reactQuery'

import RetentionConfigurationModal from '../Modals/RetentionConfigrationModal/RetentionConfigurationModal'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  content: {
    width: '100%',
    padding: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[100],
  },
  contentActive: {
    backgroundColor: theme.palette.primary[200],
  },
  retStatusInactive: {
    width: '75px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    color: theme.palette.grey[700],
  },
  restStatusActive: {
    color: theme.palette.primary.main,
  },
  grid: {
    width: '90%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: `${theme.spacing(4)}px`,
  },
  gridInactive: {
    color: theme.palette.grey[400],
  },
}))

const RetentionConfigurationItem = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { worker } = useWorker({ useCache: true })
  const workerId = worker.id
  const queryClient = useQueryClient()
  const queryKey = ['retentionConfigurations', worker.id]
  const retentionConfigurationDataCache =
    queryClient.getQueryData(queryKey) || {}
  const { retentionsQuery } = useRetentionsConfigurationService({
    serviceParams: {
      queryKey,
      workerId,
    },
    queryOptions: {
      enabled:
        Boolean(workerId) &&
        worker.retention_configuration &&
        !isDataCached(retentionConfigurationDataCache),
    },
  })
  const retentionConfiguration = isDataCached(retentionConfigurationDataCache)
    ? retentionConfigurationDataCache.data
    : retentionsQuery.data
  const classes = useStyles()
  const { isWorker } = useUser()

  const isRetentionActive = retentionConfigurationDataCache.data?.active

  const getActiveText = (isActive) => {
    return isActive ? 'Activa' : 'Inactiva'
  }

  const featureState = usePremiumFeature('retention')

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="subtitle1">Retención en la fuente</Typography>

        {!isWorker &&
          !retentionsQuery.isLoading &&
          !retentionsQuery.isError && (
            <EditButton
              onClick={() =>
                featureState.isPremiumFeature
                  ? featureState.openModal()
                  : setIsModalOpen(true)
              }
              endIcon={
                <IconCrown
                  isPremiumAction={featureState.isPremiumFeatureIcon}
                />
              }
              data-cy="retention_configuration_button"
            />
          )}
      </div>

      {retentionConfiguration ? (
        <div
          className={clsx(
            classes.content,
            isRetentionActive && classes.contentActive
          )}
        >
          <div
            className={clsx(
              classes.retStatusInactive,
              isRetentionActive && classes.restStatusActive
            )}
          >
            <Typography variant="subtitle1">
              {getActiveText(isRetentionActive)}
            </Typography>
            {isRetentionActive && <CheckCircleIcon fontSize="small" />}
          </div>

          <div
            className={clsx(
              classes.grid,
              !isRetentionActive && classes.gridInactive
            )}
          >
            <div>
              <Typography variant="body2" gutterBottom>
                <strong>Valor mensualizado </strong>
                de los intereses en préstamos
              </Typography>
              <Typography variant="subtitle1">
                {formatCurrency(retentionConfiguration?.housing_interests)}
              </Typography>
            </div>

            <div>
              <Typography variant="body2" gutterBottom>
                <strong>Valor mensualizado </strong>
                de los pagos a medicina
              </Typography>
              <Typography variant="subtitle1">
                {formatCurrency(retentionConfiguration?.prepaid_medicine)}
              </Typography>
            </div>

            <div>
              <Typography variant="body2" gutterBottom>
                Deducción por dependientes o personas a cargo
              </Typography>
              <Typography variant="subtitle1">
                {getActiveText(retentionConfiguration?.dependents_deduction)}
              </Typography>
            </div>
          </div>
        </div>
      ) : null}

      {isModalOpen && (
        <RetentionConfigurationModal
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default RetentionConfigurationItem
