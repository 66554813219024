import { useState } from 'react'

import { formatCurrency } from 'utils/format'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import OtherIncomes from '../Modals/OtherIncomes'

const CellOtherIncomes = ({ bonusIncomes, index, electronicPayrollId }) => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  return (
    <>
      {getPayrollConceptItemCell({
        buttonId: `overtime_${index}`,
        payrollConceptValue: bonusIncomes,
        text: 'Ingresos',
        formatter: formatCurrency,
        onClick: handleOpenModal,
        editable: true,
      })}
      {showModal ? (
        <OtherIncomes
          showModal={showModal}
          handleClose={handleClose}
          electronicPayrollId={electronicPayrollId}
        />
      ) : null}
    </>
  )
}

export default CellOtherIncomes
