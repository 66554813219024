import { formatCurrency } from 'utils/format'

import { ReactComponent as successIcon } from 'assets/images/views/common/status/checked_icon.svg'
import { ReactComponent as pendingIcon } from 'assets/images/views/common/status/information_icon.svg'
import { ReactComponent as modifiedIcon } from 'assets/images/views/common/status/modified_icon.svg'
import { ReactComponent as receiptIcon } from 'assets/images/views/common/status/receipt_icon.svg'
import { ReactComponent as processingIcon } from 'assets/images/views/common/status/refresh_icon.svg'
import { ReactComponent as rejectedIcon } from 'assets/images/views/common/status/rejected_icon.svg'
import { ReactComponent as canceledIcon } from 'assets/images/views/common/status/trash_icon.svg'

import WorkerExpander from '../Summary/WorkerExpander'

export const getDetailCard = (counters, setOpenModalRejected) => {
  return [
    {
      id: 'total',
      value: counters?.total,
      Icon: receiptIcon,
      title: 'Nóminas',
      status: 'total',
      type: 'primary',
    },
    {
      id: 'rejected',
      value: counters?.rejected,
      Icon: rejectedIcon,
      title: 'Rechazados',
      status: 'rejected',
      chip: 'Ver detalles',
      handleClickChip: () => setOpenModalRejected(true),
      type: 'primary',
    },
    {
      id: 'issued',
      value: counters?.issued,
      Icon: successIcon,
      title: 'Aceptados',
      status: 'issued',
      type: 'primary',
    },
    {
      id: 'pending',
      value: counters?.pending,
      Icon: pendingIcon,
      title: 'Sin emitir',
      status: 'pending',
      type: 'secondary',
    },
    {
      id: 'canceled',
      value: counters?.canceled,
      Icon: canceledIcon,
      title: 'Anulados',
      status: 'canceled',
      type: 'secondary',
    },
    {
      id: 'modified',
      value: counters?.modified,
      Icon: modifiedIcon,
      title: 'Modificados',
      status: 'modified',
      tooltip:
        'Estas son las nóminas electrónicas que enviaste a la DIAN y le realizaste una modificación.',
      type: 'secondary',
    },
    {
      id: 'processing',
      value: counters?.processing,
      Icon: processingIcon,
      title: 'Procesando',
      status: 'processing',
      type: 'secondary',
    },
  ]
}

export const getConfigurationCopy = (electronicPayrollSocialBenefitValue) => {
  const electronicPayrollSocialBenefitLabel = {
    provision_per_month:
      'Actualmente en tu nómina electrónica las prestaciones sociales se están incluyendo mensualmente de acuerdo al concepto de devengo, conoce más acerca de esta normatividad.',
    at_the_time_of_payment:
      'Actualmente en tu nómina electrónica las prestaciones sociales se están incluyendo al momento del pago, conoce más acerca de esta normatividad.',
    payment_in_year:
      'Actualmente en tu nómina electrónica las cesantías e intereses se reportan en diciembre en caso de que no se hayan pagado durante el año, y la prima se reporta al momento del pago, conoce más acerca de esta normatividad.',
  }

  return electronicPayrollSocialBenefitLabel[
    electronicPayrollSocialBenefitValue
  ]
}

export const getSummaryColumns = (
  socialBenefitsConfiguration,
  periodId,
  payPreviousPayroll
) => {
  const columns = [
    {
      id: 'expander',
      excludeOnEdit: true,
      customMinWidth: '200px',
      Header: 'Información del empleado',
      Cell: ({ row, toggleRowExpanded, state }) => {
        const toggleExpandedProps = row.getToggleRowExpandedProps()

        const onExpand = () => {
          if (!row.isExpanded) {
            Object.keys(state.expanded || {}).forEach((key) => {
              toggleRowExpanded(key, false)
            })
          }
          toggleExpandedProps?.onClick?.()
        }

        if (row.canExpand) {
          return (
            <WorkerExpander
              workerId={row.original.worker_id}
              payrollId={row.original.payroll_id}
              expanded={row.isExpanded}
              onExpand={onExpand}
              workerName={row.original.worker_name}
              periodId={periodId}
              payPreviousPayroll={payPreviousPayroll}
            />
          )
        }

        return null
      },
    },
    {
      Header: 'Ingresos',
      alignHeader: 'center',
      columns: [
        {
          accessor: 'income.label',
          Header: 'Concepto',
        },
        {
          accessor: 'income.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.income.value !== null) {
              return formatCurrency(row.original.income.value)
            }
            return null
          },
        },
      ],
    },
  ]

  if (socialBenefitsConfiguration !== 'payment_in_year') {
    const isProvisionPerMonth =
      socialBenefitsConfiguration === 'provision_per_month'
    const valueAccessor = isProvisionPerMonth ? 'provisions' : 'social_benefits'

    columns.push({
      Header: isProvisionPerMonth ? 'Provisiones' : 'Prestaciones sociales',
      alignHeader: 'center',
      columns: [
        {
          accessor: `${valueAccessor}.label`,
          Header: 'Concepto',
        },
        {
          accessor: `${valueAccessor}.value`,
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original[valueAccessor].value !== null) {
              return formatCurrency(row.original[valueAccessor].value)
            }
            return null
          },
        },
      ],
    })
  }

  columns.push(
    {
      Header: 'Deducciones',
      alignHeader: 'center',
      columns: [
        {
          accessor: 'deductions.label',
          Header: 'Concepto',
        },
        {
          accessor: 'deductions.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.deductions.value !== null) {
              return formatCurrency(row.original.deductions.value)
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Total',
      alignHeader: 'center',
      columns: [
        {
          accessor: 'total.label',
          Header: 'Concepto',
        },
        {
          accessor: 'total.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.total.value !== null) {
              return formatCurrency(row.original.total.value)
            }
            return null
          },
        },
      ],
    }
  )

  return columns
}
