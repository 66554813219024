import generateSimpleMessage from './utils'

export default {
  with_just_cause:
    'Con justa causa NO se indemniza al empleado, sin justa causa SI se indemniza.',
  hire_date:
    'Si deseas editar este campo, debes regresar al perfil del empleado y editar la fecha de contratación.',
  date:
    'Selecciona el día de terminación del contrato. Solo se podrá seleccionar una fecha del periodo actual o del anterior. Advertencia: ¡Cambiar la fecha reajusta todos los valores!',
  worked_days: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-los-dias-a-liquidar-en-una-liquidacion-laboral',
    'El cálculo se realiza del día de fecha de contratación, al día de terminación del contrato.'
  ),
  notes:
    'Aquí puedes registrar tus observaciones para aclarar información de la liquidación laboral. Son visibles en la colilla.',
  holidays: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-vacaciones-en-una-liquidacion-laboral',
    'Días pendientes de vacaciones a pagar.'
  ),
  termination_base: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-el-salario-base-en-una-liquidacion-laboral',
    'Ingresos salariales promedio del ultimo año trabajado.'
  ),
  holidays_base: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/https/ayuda.nominapp.com/help/como-se-calcula-el-pago-de-las-vacaciones-en-la-liquidacion-laboral',
    'Ingresos salariales promedio ultimo año trabajado sin incluir recargos u horas extras (solo recargo nocturno).'
  ),
  severance_base: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-cesantias-en-una-liquidacion-laboral',
    'Ingresos salariales del año de la liquidación más subsidio de transporte (si aplica).'
  ),
  service_bonus_base: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-puedo-ver-los-calculos-de-la-liquidacion-de-la-prima-de-servicios',
    'Ingresos salariales del semestre de la liquidación más subsidio de transporte (si aplica).'
  ),
  pay_rest_days:
    'Incluir pago en la nómina de los días de descanso (Por ejemplo, si es liquidado un viernes y descansa sábado y domingo, se le debe pagar el salario de estos días).',
  pay_previous_service_bonus:
    'No se ha pagado la prima del semestre anterior y se quiere incluir en la liquidación.',
  holidays_value: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/https/ayuda.nominapp.com/help/como-se-calcula-el-pago-de-las-vacaciones-en-la-liquidacion-laboral'
  ),
  severance: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-las-cesantias-en-una-liquidacion-laboral'
  ),
  severance_interests: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-la-base-para-la-liquidacion-de-las-cesantias'
  ),
  service_bonus: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-puedo-ver-los-calculos-de-la-liquidacion-de-la-prima-de-servicios'
  ),
  compensation: generateSimpleMessage(
    'https://ayuda.nominapp.com/help/como-calcula-nominapp-la-indemnizacion-por-despido-sin-justa-causa-en-una-liquidacion-laboral'
  ),
}
