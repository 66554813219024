import http, { getApiURL } from '../httpService'

export const getOnboardingWorkers = () => {
  const url = getApiURL({
    pathname: `/company_onboardings`,
  })

  return http.getV2(url)
}

export default { getOnboardingWorkers }
