import { useContext } from 'react'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import socialBenefitsService from 'services/payroll/socialBenefitsService'
import { PeriodContext } from '../../Payroll'

const SocialBenefitsMenu = () => {
  const {
    period: { id: periodId },
    options: { social_benefits },
  } = useContext(PeriodContext)
  const { enqueueDownload } = useDownloadManager()

  const handleDownloadFile = async (sbPeriodId) => {
    const serviceCall = async () =>
      await socialBenefitsService.getFile(periodId, sbPeriodId)

    enqueueDownload({
      name: 'archivo de Prestaciones Sociales',
      fileCode: 'social_benefits_file',
      serviceCall,
    })
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialBenefitsMenu',
  })

  const sbToDownload = social_benefits.filter((sb) => sb.action === 'update')

  return (
    <>
      <Button
        variant="text"
        startIcon={<SupervisedUserCircleOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
      >
        Archivo prestaciones sociales
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {sbToDownload.map((sb) => {
          const sbPeriodId = sb.social_benefits_period_id

          return (
            <MenuItem
              key={sbPeriodId}
              onClick={() => handleDownloadFile(sbPeriodId)}
            >
              {sb.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default SocialBenefitsMenu
