import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Chip from '@material-ui/core/Chip'

import Page from 'components/UI/Page/Page'

import IlustrationsSitting from 'assets/images/views/common/Illustrations_sitting-reading.svg'

import Footer from './Atoms/FooterModal'
import { usePremiumActions } from './helpers'

function getMessagesByStatusCode(code) {
  const FREEMIUM_DATA_STATUS = {
    '0006': [
      'Activar mis 7 días gratis',
      'Pasarme a Premium',
      'Estas entrando a una funcionalidad Premium. Actualiza tu plan y comienza a liquidar la nómina más rápida de tu vida',
      'Activa por 7 días nominapp Premium y comienza a disfrutar del poder del ferrari de la nómina Colombiana.',
    ],
    '0007': [
      'Pasarme a Premium',
      'Hablar con ventas',
      '¡Tus días para probar el poder de Nominapp premium han terminado!',
      'Únete a más de 1.000 compañías que hoy están disfrutando de todo el poder del Ferrari de la Nómina Colombiana.',
    ],
    '0008': [
      'Renovar mi plan Premium',
      null,
      '¡Tu plan de Nominapp premium ha terminado!',
      'Actualiza tu plan y sigue disfrutando del ferrari de la nómina Colombiana',
    ],
    '0009': [
      'Activar mi plan Premium',
      null,
      'Estas entrando a una funcionalidad Premium. Actualiza tu plan y comienza a liquidar la nómina más rápida de tu vida',
      'Activa tu plan y continua disfrutando del poder del ferrari de la nómina Colombiana.',
    ],
  }

  const primaryTextButton = FREEMIUM_DATA_STATUS[code][0] || null
  const secondaryTextButton = FREEMIUM_DATA_STATUS[code][1] || null
  const primaryText = FREEMIUM_DATA_STATUS[code][2] || null
  const secondaryText = FREEMIUM_DATA_STATUS[code][3] || null

  return {
    primaryTextButton,
    secondaryTextButton,
    primaryText,
    secondaryText,
  }
}

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gap: theme.spacing(7.5),
  },
  section: {
    maxWidth: 445,
    display: 'grid',
    gridAutoFlow: 'row',
    gap: theme.spacing(4.5),
  },
  chip: {
    width: 'fit-content',
  },
  primaryText: {
    marginBottom: theme.spacing(2),
  },
}))

const PageGetPremium = ({ code }) => {
  const { handleGetFreeTrial, handleGetPremium } = usePremiumActions()

  const classes = useStyles()

  const {
    primaryTextButton,
    secondaryTextButton,
    primaryText,
    secondaryText,
  } = getMessagesByStatusCode(code)

  return (
    <Page contentClassName={classes.page}>
      <div className={classes.container}>
        <Hidden smDown>
          <img
            src={IlustrationsSitting}
            alt="Relax on the beach while you get Premium Nominapp"
          />
        </Hidden>
        <div className={classes.section}>
          <Chip
            className={classes.chip}
            label={
              <Typography variant="caption" color="textPrimary">
                Nominapp Premium
              </Typography>
            }
            color="secondary"
          />
          <div>
            <Typography variant="subtitle1" className={classes.primaryText}>
              {primaryText}
            </Typography>
            <Typography variant="body1">{secondaryText}</Typography>
          </div>
          <Footer
            primaryText={primaryTextButton}
            secondaryText={secondaryTextButton}
            onClickPrimary={
              code === '0006' ? handleGetFreeTrial : handleGetPremium
            }
            onClickSecondary={code === '0006' ? handleGetPremium : null}
            footerText={code === '0006'}
            code={code}
            page
          />
        </div>
      </div>
    </Page>
  )
}

export default PageGetPremium
