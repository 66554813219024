import { Form, Formik } from 'formik'
import { useQueries, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Box } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getCompanyConfiguration,
  getConfigurationOptions,
} from 'services/settings/configurationService'

import { SETTINGS } from 'config/routes'

import Alert from '../../UI/MaterialUI/Alert/Alert'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px ${theme.spacing(
      7
    )}px ${theme.spacing(2.5)}px`,
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    maxWidth: 600,
    marginTop: 40,
  },
}))

const WorkerBenefits = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
    },
  })
  const { freetimeBeta } = useFeatureFlags({
    flags: ['freetimeBeta'],
  })
  const { showSuccessMessage } = useNotifications()
  const queryFunction = [getConfigurationOptions, getCompanyConfiguration]
  const [
    companyConfigurationOptionsQuery,
    companyConfigurationQuery,
  ] = useQueries(
    ['companyConfigurationOptions', 'companyConfiguration'].map(
      (query, index) => {
        return {
          queryKey: query,
          queryFn: () => queryFunction[index](),
        }
      }
    )
  )

  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })
  const isLoading =
    companyConfigurationOptionsQuery.isLoading ||
    companyConfigurationQuery.isLoading

  const {
    data: { data: companyConfigurationOptions } = {},
  } = companyConfigurationOptionsQuery
  const {
    data: { data: companyConfiguration } = {},
  } = companyConfigurationQuery

  const handleRedirect = () => history.push(SETTINGS())

  const onSubmit = (values) => {
    const dirtyValues = getDirtyValues(companyConfiguration, values)

    if (isObjectEmpty(dirtyValues)) handleRedirect()

    companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: dirtyValues,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['companyConfiguration'])
          showSuccessMessage('La configuración se actualizó correctamente.')
          handleRedirect()
        },
      }
    )
  }

  return (
    <Page
      documentTitle="Beneficios y accesibilidad para personas"
      header="Beneficios y accesibilidad para personas"
      isLoading={isLoading}
    >
      <Formik
        initialValues={companyConfiguration}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form>
            <Paper className={classes.paper}>
              <Typography variant="h6">Beneficios para personas</Typography>
              <FormField
                id="workers_benefits"
                name="workers_benefits"
                label={
                  <Typography variant="caption">
                    Permitir a las personas realizar adelantos de nómina. Ver
                    más información{' '}
                    <Link
                      href="https://ayuda.nominapp.com/help/adelantos-de-n%C3%B3mina-para-tus-empleados"
                      target="_blank"
                    >
                      aquí
                    </Link>
                    .
                  </Typography>
                }
                variant="radio-group"
                row
                options={companyConfigurationOptions?.workers_benefits || []}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="h6">Accesibilidad para personas</Typography>
              {freetimeBeta?.value === 'on' ? (
                <FormField
                  name="workers_handle_novelties"
                  label="Permitir a las personas autogestionar sus vacaciones, licencias e incapacidades"
                  variant="radio-group"
                  row
                  options={
                    companyConfigurationOptions?.workers_handle_novelties || []
                  }
                />
              ) : null}
              <FormField
                name="workers_certificate_access"
                label="Permitir a las personas descargar automáticamente Certificados Laborales, Colillas de Pago y Certificados de Ingresos y Retenciones"
                variant="radio-group"
                row
                options={
                  companyConfigurationOptions?.workers_certificate_access || []
                }
              />
              {hrNewModule?.value === 'on' ? (
                <Alert
                  icon={false}
                  header="¡Accesibilidad para empleados!"
                  severity="info"
                  className={classes.alert}
                >
                  <Typography variant="body2">
                    Con este super poder puedes permitir a los empleados
                    autogestionar sus vacaciones, licencias e incapacidades,
                    descargar automáticamente certificados laborales, colillas
                    de pago y certificados de ingresos y retenciones.
                  </Typography>
                </Alert>
              ) : null}
            </Paper>
            <Box className={classes.buttonContainer}>
              <Button
                loading={companyConfigurationMutation.isLoading}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default WorkerBenefits
