import clsx from 'clsx'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { createContext, useContext, useEffect, useState } from 'react'

import { Button, IconButton, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined'

import { useUser } from 'components/App/UserContext/useUser'
import DropdownButton from 'components/UI/Button/Dropdown'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import SearchInput from 'components/UI/Table/Toolbar/ToolbarSearch'

import { getCompanyId } from 'utils/company'
import useDocumentsService from 'utils/hooks/worker/documents'

import GridView from 'assets/icons/GridView'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import ActionModal from './ActionModal'
// import ActionsBar from './ActionsBar'
import Content from './Content'
import NewElementModal from './NewElementModal'
import { useFilteredData, validateIsAffiliationFolder } from './helpers'

export const FoldersContainerContext = createContext()
FoldersContainerContext.displayName = 'FoldersContainerContext'

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  actionsContainer: {
    backgroundColor: theme.palette.primary.lighter,
    minHeight: 80,
    display: 'flex',
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    },
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    '& > .MuiIconButton-root + .MuiIconButton-root': {
      marginLeft: 0,
    },
  },
  iconButton: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  activeIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  content: {
    padding: theme.spacing(3, 4),
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  gridContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexWrap: 'wrap',
  },
}))

const Documents = () => {
  const classes = useStyles()
  const { openedFolder } = useContext(DocumentsContext)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const companyId = getCompanyId()
  const workerId = worker.id
  const [view, setView] = useState('grid')
  const [orderByLabel, setOrderByLabel] = useState()
  const [elementModal, setElementModal] = useState({
    open: false,
    type: null,
  })
  const [actionModal, setActionModal] = useState({
    type: null,
    context: {},
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const showAsGrid = view === 'grid'
  const showAsList = view === 'list'
  const isFolderEmpty = openedFolder?.files?.length === 0
  const hasSomeSelectedFiles = selectedFiles.length > 0
  const hasAllSelectedFiles =
    selectedFiles.length === openedFolder?.files?.length
  const foldersQueryKey = isWorker
    ? ['getAllWorkerFolders', worker.id]
    : ['getAllFolders', companyId, worker.id]
  const documentsQueryKey = isWorker
    ? ['getAllWorkerDocuments', worker.id, openedFolder?.id]
    : ['getAllDocuments', companyId, worker.id, openedFolder?.id]
  const { documentsQuery: foldersQuery } = useDocumentsService({
    serviceParams: {
      queryKey: foldersQueryKey,
      workerId,
    },
  })
  const { documentsQuery } = useDocumentsService({
    serviceParams: {
      queryKey: documentsQueryKey,
      workerId,
      folderId: openedFolder?.id,
    },
    queryOptions: {
      enabled: Boolean(openedFolder?.id),
    },
  })
  const {
    filteredData,
    searchFilter,
    onChangeFilter,
    orderByName,
    orderByDate,
  } = useFilteredData(
    openedFolder
      ? documentsQuery.data?.folder?.files || []
      : foldersQuery.data?.folders || []
  )

  const isAffiliationFolder = validateIsAffiliationFolder(
    documentsQuery.data?.folder?.name
  )

  const closePopup = () => popupState.close()

  const handleChangeView = (viewType) => {
    setView(viewType)
  }

  const handleAddElement = () =>
    setElementModal({
      open: true,
      type: openedFolder ? 'file' : 'folder',
    })

  const handleCancelAddFile = () =>
    setElementModal({
      open: false,
      type: null,
    })

  const orderOptions = [
    {
      id: 'by_name',
      name: 'Por orden alfabético',
      onClick: () => {
        setOrderByLabel('Por orden alfabético')
        orderByName()
        closePopup()
      },
    },
    {
      id: 'by_date',
      name: 'Por fecha de creación',
      onClick: () => {
        setOrderByLabel('Por fecha de creación')
        orderByDate()
        closePopup()
      },
    },
  ]

  const handleSelectFile = (fileId) =>
    setSelectedFiles((previousFiles) => {
      if (!previousFiles.includes(fileId)) return [...previousFiles, fileId]

      return previousFiles.filter((item) => item !== fileId)
    })

  useEffect(() => {
    if (openedFolder) {
      setView('list')
    } else {
      setView('grid')
    }
  }, [openedFolder])

  const value = {
    showAsGrid,
    actionModal,
    setActionModal,
    handleSelectFile,
    hasSomeSelectedFiles,
    hasAllSelectedFiles,
    selectedFiles,
    setSelectedFiles,
  }

  return (
    <FoldersContainerContext.Provider value={value}>
      {!foldersQuery.isLoading ? (
        <Paper className={classes.container}>
          <div className={classes.actionsContainer}>
            <div className={classes.leftContainer}>
              <SearchInput
                globalFilter={searchFilter}
                setGlobalFilter={onChangeFilter}
              />
              <IconButton
                className={clsx(classes.iconButton, {
                  [classes.activeIconButton]: showAsGrid,
                })}
                disableFocusRipple
                disableRipple
                onClick={() => handleChangeView('grid')}
              >
                <GridView />
              </IconButton>
              <IconButton
                className={clsx(classes.iconButton, {
                  [classes.activeIconButton]: showAsList,
                })}
                disableFocusRipple
                disableRipple
                onClick={() => handleChangeView('list')}
              >
                <ReorderOutlinedIcon fontSize="large" />
              </IconButton>
              <DropdownButton
                title={orderByLabel || 'Ordenar'}
                popupState={popupState}
                variant="outlined"
                endIcon={<ExpandMoreOutlinedIcon />}
                options={orderOptions}
              />
            </div>
            {!isWorker && !isFolderEmpty && !isAffiliationFolder ? (
              <div>
                <Button onClick={handleAddElement}>
                  {openedFolder ? 'Agregar archivo' : 'Crear carpeta'}
                </Button>
              </div>
            ) : null}
          </div>
          {/* <ActionsBar /> */}
          {!documentsQuery.isLoading ? (
            <div
              className={clsx(classes.content, {
                [classes.listContainer]: showAsList,
                [classes.gridContainer]: showAsGrid,
              })}
            >
              <Content
                filteredData={filteredData}
                isAffiliationFolder={isAffiliationFolder}
              />
            </div>
          ) : (
            <LoadingBox />
          )}
        </Paper>
      ) : (
        <LoadingBox />
      )}
      {elementModal.open ? (
        <NewElementModal
          onClose={handleCancelAddFile}
          type={elementModal.type}
          folderId={openedFolder?.id || undefined}
        />
      ) : null}
      {actionModal.type !== null ? (
        <ActionModal
          open
          onClose={() =>
            setActionModal({
              type: null,
              context: {},
            })
          }
        />
      ) : null}
    </FoldersContainerContext.Provider>
  )
}

export default Documents
