import parseISO from 'date-fns/parseISO'
import { useFormikContext } from 'formik'

import MuiLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'

import FormField from 'components/UI/Formik/FormField/Index'
import useWorker from 'components/Worker/useWorker'

import * as routes from 'config/routes'

import messages from 'messages/termination'

import Summary from './Summary'
import { useTerminationFieldsStyles } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(5),
    padding: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))

const booleanOptions = [
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
]

const LinkViewDetails = ({ name, isEditing }) => {
  const { values } = useFormikContext()

  return isEditing ? (
    <MuiLink
      variant="subtitle2"
      href={`${routes.PERIOD_PAYROLL_VIEW_DETAILS(
        values.payroll_id
      )}?selected_tab=termination`}
      target="_blank"
      style={{
        display: 'inline-flex',
        marginLeft: 'auto',
      }}
    >
      <VisibilityOutlined fontSize="small" />
      Ver cálculos de {name}
    </MuiLink>
  ) : null
}

const ContractTerminationFields = ({
  dirtyTermination,
  handleCancel,
  isEditing,
  isSaving,
}) => {
  const { values, setFieldValue } = useFormikContext()
  const classes = useStyles()
  const fieldsClasses = useTerminationFieldsStyles()
  const { worker } = useWorker({ useCache: true })

  const dateMonth = parseISO(values?.date).getMonth()
  const dateYear = parseISO(values?.date).getFullYear()

  return (
    <>
      <Paper className={classes.container}>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            1. Causas liquidación
          </Typography>
          <FormField
            name="cause"
            label="¿Motivo de liquidación?"
            placeholder="Ejm: Retiro voluntario"
            onChange={({ target }) =>
              setFieldValue('cause', target.value.trimStart())
            }
          />
          <FormField
            name="with_just_cause"
            label="¿Liquidación con justa causa?"
            variant="radio-group"
            options={booleanOptions}
            tooltipContent={messages.with_just_cause}
            disabled={values.trial_period}
            row
          />
          {values.possible_trial_period && (
            <FormField
              name="trial_period"
              label="Período de prueba"
              variant="radio-group"
              options={booleanOptions}
              row
            />
          )}
        </div>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            2. Fechas liquidación
          </Typography>
          <FormField
            name="hire_date"
            label="Fecha de contratación"
            variant="datepicker"
            tooltipContent={messages.hire_date}
            disabled
          />
          <FormField
            name="date"
            label="Fecha de terminación"
            variant="datepicker"
            minDate={values.min_date}
            maxDate={values.max_date}
            tooltipContent={messages.date}
          />
          <FormField
            name="worked_days"
            label="Días a liquidar"
            type="number"
            align="left"
            disabled
            tooltipContent={messages.worked_days}
          />
          <FormField
            name="holidays"
            label="Días de vacaciones"
            type="number"
            align="left"
            tooltipContent={messages.holidays}
          />
        </div>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            3. Bases cálculo liquidación
          </Typography>
          <FormField
            name="termination_base"
            label="Salario Base"
            variant="currency"
            align="left"
            tooltipContent={messages.termination_base}
            additionalLabelComponent={
              <LinkViewDetails name="salario base" isEditing={isEditing} />
            }
          />
          <FormField
            name="holidays_base"
            label="Vacaciones"
            variant="currency"
            align="left"
            tooltipContent={messages.holidays_base}
            additionalLabelComponent={
              <LinkViewDetails name="vacaciones" isEditing={isEditing} />
            }
          />
          <FormField
            name="severance_base"
            label="Cesantías"
            variant="currency"
            align="left"
            tooltipContent={messages.severance_base}
            additionalLabelComponent={
              <LinkViewDetails name="cesantías" isEditing={isEditing} />
            }
          />
          {values.pay_service_bonus && (
            <FormField
              name="service_bonus_base"
              label="Prima"
              variant="currency"
              align="left"
              tooltipContent={messages.service_bonus_base}
              additionalLabelComponent={
                <LinkViewDetails name="prima" isEditing={isEditing} />
              }
            />
          )}
          {values.pay_previous_service_bonus && (
            <FormField
              name="previous_service_bonus_base"
              label="Prima semestre anterior"
              variant="currency"
              align="left"
            />
          )}
          {(values.pay_previous_severance ||
            values.pay_previous_severance_interests) && (
            <FormField
              name="previous_severance_base"
              label="Cesantías año anterior"
              variant="currency"
              align="left"
            />
          )}
        </div>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            4. Opciones adicionales
          </Typography>
          {worker?.contract_category !== 'part_time_contract' ? (
            <FormField
              name="pay_rest_days"
              label="¿Incluir dominicales?"
              variant="radio-group"
              options={booleanOptions}
              tooltipContent={messages.pay_rest_days}
              row
            />
          ) : null}

          {values.show_pay_service_bonus && (
            <FormField
              name="pay_service_bonus"
              label="¿Incluir la prima del semestre actual?"
              variant="radio-group"
              options={booleanOptions}
              row
            />
          )}
          {(dateMonth === 0 ||
            dateMonth === 6 ||
            (dateMonth > 6 && dateYear === 2020)) && ( // january or july
            <FormField
              name="pay_previous_service_bonus"
              label="¿Incluir prima semestre anterior?"
              variant="radio-group"
              options={booleanOptions}
              tooltipContent={messages.pay_previous_service_bonus}
              row
            />
          )}
          {values.show_pay_previous_severance && (
            <FormField
              name="pay_previous_severance"
              label="¿Incluir cesantías año anterior?"
              variant="radio-group"
              options={booleanOptions}
              row
            />
          )}
          {values.show_pay_previous_severance_interests && ( // january
            <FormField
              name="pay_previous_severance_interests"
              label="¿Incluir intereses a las cesantías año anterior?"
              variant="radio-group"
              options={booleanOptions}
              row
            />
          )}
          {values.show_decree376 && (
            <FormField
              name="pay_decree376"
              label="¿Incluir retención por Decreto 376?"
              variant="radio-group"
              options={booleanOptions}
              row
            />
          )}
        </div>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            5. Otros ingresos
          </Typography>
          <FormField
            name="non_salary_income"
            label="Otros ingresos"
            variant="currency"
            align="left"
            placeholder="0"
          />
        </div>
        <div className={fieldsClasses.fieldsGroup}>
          <Typography variant="h6" gutterBottom>
            6. Otros egresos
          </Typography>
          {values.show_decree376 ? (
            <FormField
              name="decree376"
              label="Valor retención por Decreto 376"
              variant="currency"
              align="left"
              placeholder="0"
              disabled
            />
          ) : null}
          <FormField
            name="loans"
            label="Valor préstamos"
            variant="currency"
            align="left"
            placeholder="0"
          />
          {values.show_payroll_credit ? (
            <FormField
              name="payroll_credit_value"
              label="Crédito de libranza"
              variant="currency"
              align="left"
              placeholder="0"
            />
          ) : null}
          <FormField
            name="deductions"
            label="Otras deducciones"
            variant="currency"
            align="left"
            placeholder="0"
          />
        </div>
        <div className={fieldsClasses.fieldsGroupWide}>
          <Typography variant="h6" gutterBottom>
            7. Notas
          </Typography>
          <FormField
            name="notes"
            label="Notas"
            variant="textarea"
            rows={3}
            tooltipContent={messages.notes}
            inputProps={{ style: { resize: 'none' } }}
          />
        </div>
      </Paper>
      <Summary
        dirtyTermination={dirtyTermination}
        handleCancel={handleCancel}
        isEditing={isEditing}
        isSaving={isSaving}
      />
    </>
  )
}

export default ContractTerminationFields
