import clsx from 'clsx'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { alphanumericSort, booleanSort } from 'utils/sortFunctions'

import { tableStyles } from './Table'

const asyncDataInitialState = {
  currentPageIndex: 0,
  currentTotalCount: 0,
  currentSearch: '',
}

const asyncDataReducer = (state, action) => {
  switch (action.type) {
    case 'page_changed':
      return { ...state, currentPageIndex: action.payload }
    case 'total_count_changed':
      return { ...state, currentTotalCount: action.payload }
    case 'search_changed':
      return { ...state, currentSearch: action.payload }
    default:
      throw new Error(`Unhandled  action type: ${action.type}`)
  }
}

// Helper based on https://github.com/tannerlinsley/react-table/issues/2988
export const getConditionSelectHeaderCheckbox = ({
  headerProps,
  shouldSelectPage = true,
  showPagination,
}) => {
  const checkIfRowIsSelectable = (itemRow) =>
    itemRow.original.disableCheck !== true
  const checkIfAllSelectableRowsSelected = (rows) =>
    rows.filter(checkIfRowIsSelectable).every((row) => row.isSelected)
  const isSelectPage =
    shouldSelectPage &&
    showPagination &&
    headerProps.rows
      .filter(checkIfRowIsSelectable)
      .some((row) => !row.isSelected)

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps()

  // Validations for check if is selectable and disabled
  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0
  const checked =
    !disabled && checkIfAllSelectableRowsSelected(headerProps.rows)
  const indeterminate =
    !checked && headerProps.rows.some((row) => row.isSelected)

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row) =>
        headerProps.toggleRowSelected(row.id, false)
      )
    } else {
      const { rows } = headerProps

      rows.forEach((row) => {
        const checkedRow = checkIfRowIsSelectable(row)
        headerProps.toggleRowSelected(row.id, checkedRow)
      })
    }
  }

  return {
    ...checkboxProps,
    checked,
    indeterminate,
    onChange,
    disabled,
  }
}

export const getCustomHeaderProps = (
  column,
  customActionsWidth,
  editableTable,
  showSorting
) => {
  if (column.id === 'selection') return column.getHeaderProps()

  if (column.id === 'actions')
    return {
      ...column.getHeaderProps(),
      style: { width: customActionsWidth },
    }

  return {
    ...column.getHeaderProps([
      {
        style: {
          width: editableTable
            ? column.customWidth
            : `calc(${column.customWidth} - 32px)`,
        },
      },
      ...(showSorting ? [column.getSortByToggleProps()] : []),
    ]),
  }
}

export const getHeaderWrapperProps = (column) => ({
  style: {
    ...column.headerStyle,
    width: `calc(${column.customWidth} - 32px)`,
    minWidth: `calc(${column.customMinWidth} - 32px)`,
  },
})

export const useTableStyles = () => {
  const classes = tableStyles()

  const getHeaderStyles = (column) => {
    return clsx(classes.header, {
      [classes.headerCenter]:
        column.alignHeader === 'center' ||
        (column.alignActionsHeader === 'center' && column.id === 'actions'),
      [classes.headerRight]:
        column.alignHeader === 'right' ||
        (column.alignActionsHeader === 'right' && column.id === 'actions'),
    })
  }

  const getCellStyles = (column) => {
    return clsx(classes.cell, {
      [classes.cellCenter]:
        column.alignCell === 'center' ||
        (column.alignActionsCell === 'center' && column.id === 'actions'),
      [classes.cellRight]:
        column.alignCell === 'right' ||
        (column.alignActionsCell === 'right' && column.id === 'actions'),
      [classes.actionsContainer]: column.id === 'actions',
      [classes.cellColumnDirection]: column.cellDirection === 'column',
    })
  }

  return {
    getHeaderStyles,
    getCellStyles,
  }
}

const getPageIndex = (asyncData, queryPageIndex) => {
  if (asyncData) {
    return queryPageIndex
  }
  return 0
}

const getPageCount = (asyncData, totalCount, pageSize) => {
  if (asyncData) return Math.ceil(totalCount / pageSize)
  return -1
}

export const getPaginationConfig = (
  enablePagination,
  asyncData,
  queryPageIndex,
  totalCount,
  rowsSize
) => {
  if (enablePagination) {
    return {
      initialState: {
        pageIndex: getPageIndex(asyncData, queryPageIndex),
        pageSize: rowsSize,
      },
      manualPagination: asyncData,
      pageCount: getPageCount(asyncData, totalCount, rowsSize),
    }
  }

  return {
    initialState: {},
  }
}

export const getGlobalFilterConfig = (
  enableGlobalFilter,
  enableToolbar,
  asyncData
) => {
  if (enableGlobalFilter && enableToolbar) {
    return {
      manualGlobalFilter: asyncData,
    }
  }
  return {}
}

export const getSortingConfig = (enableSorting) => {
  if (enableSorting) {
    return {
      manualSorting: !enableSorting,
    }
  }
  return {}
}

export const useAsyncData = (
  tableData,
  asyncData = false,
  customFilterFetch
) => {
  const { handleError } = useErrorHandler()
  const [
    { currentPageIndex, currentTotalCount, currentSearch },
    dispatchAsyncDataAction,
  ] = useReducer(asyncDataReducer, asyncDataInitialState)

  const {
    queryKey = [],
    queryFunction = () => new Promise(() => {}),
    queryOptions = {},
  } = asyncData ? tableData(currentPageIndex, currentSearch) : {}

  const {
    data: { data, filters = {} } = {},
    ...restAsyncDataResponse
  } = useQuery(queryKey, queryFunction, {
    ...queryOptions,
    enabled: asyncData,
    keepPreviousData: true,
    onError: (error) => {
      if (queryOptions?.onError) {
        queryOptions?.onError(error)
        return
      }
      handleError(error)
    },
  })

  // Asign data to filter reducer when the filters key change
  const customFilters =
    customFilterFetch !== null && asyncData && data ? data.filters : filters

  // Update currentTotalCount when the filters change
  useEffect(() => {
    if (!asyncData) return
    if (!isObjectEmpty(customFilters)) {
      dispatchAsyncDataAction({
        type: 'total_count_changed',
        payload: customFilters.total_records,
      })
    }
  }, [asyncData, dispatchAsyncDataAction, customFilters])

  return {
    tableQuery: {
      data,
      filters,
      ...restAsyncDataResponse,
    },
    dispatchAsyncDataAction,
    currentPageIndex,
    currentTotalCount,
    currentSearch,
  }
}

export const useEditableTable = ({
  configuration = {},
  columnsLength,
  setCurrentData,
}) => {
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const mutationKey = `tableMutation-${nanoid()}`
  const tableMutation = useMutation(mutationKey)
  const [updateIndex, setUpdateIndex] = useState(null)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [isEditionActive, setIsEditionActive] = useState(false)
  const [editMode, setEditMode] = useState(null)

  const {
    onUpdateRow = () => {},
    onDeleteRow = () => {},
    onAddRow = () => {},
    validationSchema,
    isDeletable,
    isEditable,
    tableMinWidth,
    mutationOptions = {},
    hideEdit,
    hideDelete,
  } = configuration

  const isTableEditable = !isObjectEmpty(configuration) // Current table is editable
  const isUpdating = updateIndex !== null
  const isDeleting = deleteIndex !== null

  const onChangeEditMode = useCallback((mode) => setEditMode(mode), [])

  const handleDeleteRow = useCallback(
    (rowIndex) => {
      setDeleteIndex(rowIndex)
      setIsEditionActive(true)
      onChangeEditMode('onUpdate')
    },
    [onChangeEditMode]
  )

  const handleEditRow = useCallback(
    (rowIndex) => {
      setUpdateIndex(rowIndex)
      setIsEditionActive(true)
      onChangeEditMode('onUpdate')
    },
    [onChangeEditMode]
  )

  const onCancelEdit = () => {
    setUpdateIndex(null)
    setDeleteIndex(null)
    setIsEditionActive(false)
    setEditMode(null)
  }

  const updateMutationConfig = useCallback(
    (data, mutationFunction, mutateOptions) => {
      queryClient.setMutationDefaults(mutationKey, {
        ...mutationOptions,
        mutationFn: mutationFunction,
        onError: (error) => {
          if (mutationOptions?.onError) {
            mutationOptions?.onError(error)
            return
          }
          handleError(error, null, {
            notistackOptions: { preventDuplicate: true },
          })
        },
      })

      tableMutation.mutate(data, {
        ...mutateOptions,
        onError: (error) => {
          if (mutateOptions?.onError) {
            mutateOptions?.onError(error)
            return
          }
          handleError(error, null, {
            notistackOptions: { preventDuplicate: true },
          })
        },
        onSuccess: (result) => {
          if (mutateOptions?.onSuccess) {
            mutateOptions?.onSuccess(result)
          }
          onCancelEdit() // Close edition mode
        },
      })
    },
    [handleError, mutationKey, mutationOptions, queryClient, tableMutation]
  )

  const handleEditAction = useCallback(
    (oldValues, updatedValues) => {
      if (editMode === 'onAdd') {
        // Remove random nanoid id from new values
        const { id, ...newValues } = updatedValues
        const {
          data,
          mutationFunction = () => {},
          mutateOptions = {},
        } = onAddRow(newValues)

        updateMutationConfig(data, mutationFunction, mutateOptions)

        // Update current data with the added row
        setCurrentData((oldData) => {
          const newData = [...oldData]
          const addedRowIndex = oldData.findIndex((row) =>
            row.id.includes('new_row')
          )
          if (addedRowIndex !== -1) {
            newData[addedRowIndex] = {
              ...newData[addedRowIndex],
              ...newValues,
            }
          }
          return newData
        })
        return
      }

      const { data, mutationFunction = () => {}, mutateOptions = {} } =
        onUpdateRow(oldValues, updatedValues) || {}

      updateMutationConfig(data, mutationFunction, mutateOptions)
    },
    [editMode, onAddRow, onUpdateRow, setCurrentData, updateMutationConfig]
  )

  const handleDeleteAction = useCallback(
    (oldValues) => {
      const {
        data,
        mutationFunction = () => {},
        mutateOptions = {},
      } = onDeleteRow(oldValues)

      updateMutationConfig(data, mutationFunction, mutateOptions)
    },
    [onDeleteRow, updateMutationConfig]
  )

  const onConfirmEdit = useCallback(
    async (oldValues, updatedValues) => {
      if (isUpdating) {
        handleEditAction(oldValues, updatedValues)
        return
      }

      if (isDeleting) {
        handleDeleteAction(oldValues)
      }
    },
    [handleDeleteAction, handleEditAction, isDeleting, isUpdating]
  )

  const isEditingRow = useCallback(
    (rowIndex) => {
      return updateIndex === rowIndex
    },
    [updateIndex]
  )

  const isDeletingRow = useCallback(
    (rowIndex) => {
      return deleteIndex === rowIndex
    },
    [deleteIndex]
  )

  return {
    isTableEditable,
    validationSchema,
    isDeletable,
    isEditable,
    tableMinWidth,
    editionLoading: tableMutation.isLoading,
    deleteRowColSpan: columnsLength,
    isEditionActive:
      isEditionActive && (updateIndex !== null || deleteIndex !== null),
    handleDeleteRow,
    handleEditRow,
    onCancelEdit,
    onConfirmEdit,
    isEditingRow,
    isDeletingRow,
    editMode,
    onChangeEditMode,
    hideEdit,
    hideDelete,
  }
}

const getRowValues = (column) => {
  if (column?.type === 'boolean') return column.editInitialValue || false
  return column.editInitialValue || ''
}

const generateNewRow = (columns) => {
  const newRow = {}
  const id = `new_row_${nanoid()}`
  newRow.id = id

  columns.forEach((column) => {
    newRow[column.id] = getRowValues(column)
  })

  return newRow
}

export const useAddRow = ({
  editableConfig = {},
  isEditionActive,
  setCurrentData,
  allColumns = [],
  handleEditRow,
  dataLength,
  onChangeEditMode,
}) => {
  const {
    enableAddRow,
    addRowActionProps = {},
    addRowPosition = 'bottom',
  } = editableConfig
  const columns = allColumns.filter(
    (column) => !['actions', 'selection'].includes(column.id)
  )
  const newRow = generateNewRow(columns)

  const createNewRowConfig = useCallback(() => {
    handleEditRow(addRowPosition === 'top' ? 0 : dataLength)
    onChangeEditMode('onAdd')
    setCurrentData((oldData) => {
      if (addRowPosition === 'top') {
        return [newRow, ...oldData]
      }
      return [...oldData, newRow]
    })
  }, [
    addRowPosition,
    dataLength,
    handleEditRow,
    newRow,
    onChangeEditMode,
    setCurrentData,
  ])

  const handleAddNewRow = useCallback(() => {
    if (addRowActionProps?.onClick) {
      if (addRowActionProps?.onClick()) {
        createNewRowConfig()
      }
    } else {
      createNewRowConfig()
    }
  }, [addRowActionProps, createNewRowConfig])

  const addRowAction = {
    tooltip: 'Agregar',
    icon: AddCircleOutlineIcon,
    ...addRowActionProps,
    id: 'addNewRow',
    isFreeAction: true,
    disabled: isEditionActive || addRowActionProps?.disabled || false,
    onClick: handleAddNewRow,
  }

  return {
    enableAddRow,
    addRowAction,
  }
}

export const useSortTypes = () => {
  return useMemo(
    () => ({
      booleanSort,
      alphanumericSort,
    }),
    []
  )
}
