import Password from 'components/Company/Form/Fields/Credentials/Password'
import UserName from 'components/Company/Form/Fields/Credentials/UserName'
import { affiliationProviders } from 'components/Company/Form/Fields/Credentials/helpers'

const credentialsColumns = [
  {
    Header: 'Tipo de entidad',
    accessor: 'category',
    Cell: ({ row }) => {
      const category = row?.original?.category
      return category
        ? affiliationProviders.find(({ value }) => category === value).label
        : ''
    },
  },
  {
    Header: 'Nombre entidad',
    accessor: 'name',
  },
  {
    Header: 'Usuario',
    accessor: 'username',
    Cell: ({ value }) => <UserName value={value} />,
    customMinWidth: '150px',
  },
  {
    Header: 'Contraseña',
    accessor: 'password',
    Cell: ({ value }) => <Password value={value} />,
    customMinWidth: '180px',
  },
]

export default credentialsColumns
