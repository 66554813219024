import clsx from 'clsx'
import PropTypes from 'prop-types'

import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopRightRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
  },
}))

const Tabs = ({ value, onChange, tabs, wrapped, backgroundColor, ...rest }) => {
  const classes = useStyles()

  const { className: classNameProp } = rest
  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      {...rest}
      className={clsx(classes.root, classNameProp, {
        [classes.tabs]: backgroundColor === 'white',
      })}
    >
      {tabs.map((tab, index) =>
        tab.hidden ? null : (
          <MuiTab
            key={tab.key || index}
            label={tab.label}
            value={tab.key}
            icon={tab.icon}
            wrapped={wrapped}
            data-cy={tab.cyData || ''}
          />
        )
      )}
    </MuiTabs>
  )
}

export default Tabs

Tabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wrapped: PropTypes.bool,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
    })
  ).isRequired,
}

Tabs.defaultProps = {
  wrapped: false,
  backgroundColor: null,
}
