import { useState, useEffect } from 'react'
import fileService from 'services/payroll/fileService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

const initialState = {
  open: false,
  providerOptions: [],
  periodOptions: [],
  fileOptions: [],
}

/**
 * Used in conjunction with AccountingFileModal
 * @param {Object} period
 */
const useAccountingFileModal = (period) => {
  const [state, setState] = useState({ ...initialState, period })
  const { handleError } = useErrorHandler()

  useEffect(() => {
    setState({ ...initialState, period })
  }, [period])

  const openModal = async () => {
    try {
      const {
        provider_options,
        period_options,
        file_options,
      } = await fileService.getAccountingFileOptions(period.id)

      const newState = {
        ...state,
        providerOptions: provider_options,
        periodOptions: period_options,
        fileOptions: file_options,
        open: true,
      }

      setState(newState)
    } catch (error) {
      handleError(error)
    }
  }

  const closeModal = () => {
    setState({ ...initialState, period })
  }

  return {
    accountingFileModalState: state,
    openAccountingFileModal: openModal,
    closeAccountingFileModal: closeModal,
  }
}

export default useAccountingFileModal
