import { useFormikContext } from 'formik'

import CustomField from 'components/UI/Formik/CustomField'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import CustomInputRow from 'components/Benefits/Atoms/CustomInputRow'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'

import InputsGroupContainer, {
  Horizontal,
  Column,
} from '../InputsGroupContainer'
import CalendarIcon from '../CalendarIcon'

import { getNestedProp } from '../helpers'
import { useCreditFormFields } from '../hooks'

const WorkerLaboralInformation = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const { inputsLaboralInformationData } = useCreditFormFields()

  const onChangeDepartment = (
    newSelectedValue,
    path,
    cityKey,
    departmentKey
  ) => {
    const nestedProp = getNestedProp(values, path)
    if (nestedProp && nestedProp?.id !== newSelectedValue?.id) {
      setFieldValue(cityKey, null)
      setFieldTouched(cityKey)
    }
    setFieldValue(departmentKey, newSelectedValue)
  }

  return (
    <>
      {inputsLaboralInformationData.map((section) => {
        return (
          <InputsGroupContainer
            key={section?.id}
            title={section?.title}
            gutterBottom={section?.gutterBottom}
          >
            {section?.rows.map((row) => {
              const Wrapper =
                row?.component === 'full-row' ? Horizontal : Column

              return (
                <Wrapper key={row?.id} gutterBottom={row?.gutterBottom}>
                  {row.fields.map((field) => {
                    if (field?.component === 'company_id_number') {
                      return (
                        <CompanyIdentificationNumberField key={field?.name} />
                      )
                    }

                    if (field?.name === 'work_department') {
                      return (
                        <DepartmentsList
                          key={field?.name}
                          name={field?.name}
                          label={field?.label}
                          placeholder={field?.placeholder}
                          onChange={(_, newSelectedValue) =>
                            onChangeDepartment(
                              newSelectedValue,
                              field?.departmentPath,
                              field?.cityKey,
                              field?.name
                            )
                          }
                        />
                      )
                    }

                    if (field?.name === 'work_city') {
                      return (
                        <CitiesList
                          key={field?.name}
                          name={field?.name}
                          label={field?.label}
                          placeholder={field?.placeholder}
                          department={values?.work_department}
                        />
                      )
                    }

                    if (field?.component === 'custom-input-row') {
                      return (
                        <CustomInputRow
                          key={field?.name}
                          title={field?.title}
                          component={field?.type}
                          name={field?.name}
                          gutterBottom={field?.gutterBottom}
                        />
                      )
                    }

                    return (
                      <CustomField
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        component={field?.component}
                        options={field?.options || undefined}
                        datepickerProps={
                          field?.component === 'datepicker' && {
                            InputProps: {
                              endAdornment: <CalendarIcon />,
                            },
                          }
                        }
                      />
                    )
                  })}
                </Wrapper>
              )
            })}
          </InputsGroupContainer>
        )
      })}
    </>
  )
}

export default WorkerLaboralInformation
