import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/'

const useStyles = makeStyles((theme) => ({
  progressCard: {
    boxShadow: 'none',
    border: '2px solid',
    borderColor: theme.palette.grey[200],
    padding: theme.spacing(0, 7, 0.5, 2),
  },
  containerValues: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1.5, 0),
  },
}))

const CreditBarProgress = ({ progressPayment, paidValue, remainingValue }) => {
  const classes = useStyles()

  return (
    <Card className={classes.progressCard}>
      <div className={classes.containerValues}>
        <Typography variant="subtitle2">Valor pagado</Typography>
        <Typography variant="body2">Valor restante</Typography>
      </div>
      <LinearProgress variant="determinate" value={progressPayment} />
      <div className={classes.containerValues}>
        <Typography variant="subtitle2">{paidValue}</Typography>
        <Typography variant="body2">{remainingValue}</Typography>
      </div>
    </Card>
  )
}

export default CreditBarProgress
