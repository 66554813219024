import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'

import finishedGenie from 'assets/images/avatars/eugenio/eugenio_success.svg'
import startGenie from 'assets/images/views/payroll/genio_nominapp.svg'

import { DASHBOARD } from 'config/routes'

import ConfigurationProcess from './ConfigurationProcess/Index'
import EmptyState from './EmptyState'
import RequiredDataItem from './RequiredDataItem'

const useStyles = makeStyles((theme) => ({
  cardSubtitle: {
    margin: theme.spacing(3, 0),
  },
  requiredItemsContainer: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
  },
  successCardSubtitle: {
    margin: theme.spacing(3.5, 0, 10.5, 0),
  },
  successActionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 200px))',
    gap: theme.spacing(3.5),
    [theme.breakpoints.between(960, 1090)]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down(680)]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const ElectronicPayrollContent = ({ state, dispatch }) => {
  const classes = useStyles()
  const history = useHistory()
  const { maintenance, maintenanceDate } = useDianMaintenance()
  const electronicPayrollMaintenance = maintenance ? maintenanceDate : false
  const userName = localStorage.getItem('user_name')

  const showConfigurationForm = () =>
    dispatch({ type: 'show_form_from_banner' })

  const handleEditConfiguration = () =>
    dispatch({ type: 'show_only_config_form' })

  const redirectToDashboard = () => history.push(DASHBOARD)

  const closeForm = () => dispatch({ type: 'show_success_banner' })

  if (!state.firstAttempt && state.showWelcomeBanner) {
    return (
      <EmptyState
        image={startGenie}
        title="Estás a punto de configurar tu nómina electrónica"
        maintenance={electronicPayrollMaintenance}
      >
        <Typography variant="h6" className={classes.cardSubtitle}>
          Antes de comenzar es importante que tengas esta información a la mano:
        </Typography>
        <div className={classes.requiredItemsContainer}>
          <RequiredDataItem label="Datos de acceso al portal de la DIAN - MUISCA" />
          <RequiredDataItem label="Datos legales de la empresa (NIT, Datos del representante legal)" />
          <RequiredDataItem label="Acceso al correo registrado en el RUT" />
        </div>
        <Button onClick={showConfigurationForm} disabled={maintenance}>
          Comenzar configuración
        </Button>
      </EmptyState>
    )
  }

  if (state.firstAttempt && state.showSuccessBanner) {
    return (
      <EmptyState
        image={finishedGenie}
        title="¡Ya estás habilitado para emitir nómina electrónica!"
        maintenance={electronicPayrollMaintenance}
      >
        <Typography variant="body1" className={classes.successCardSubtitle}>
          Eh Ave María, ¿así o más fácil {userName}?
          <br />
          <br />
          Recuerda que debes emitir la nómina electrónica los primeros 10 días
          hábiles después de cada mes.
          <br />
          <br />
          Aquí te enseñamos{' '}
          <Link
            href="https://ayuda.nominapp.com/help/hc/es-419/articles/4412813228187-verifica-que-est%C3%A1s-habilitado-para-emitir-tu-n%C3%B3mina-electr%C3%B3nica-y-verifica-el-estado-de-tu-set-de-pruebas-en-el-1651072670612"
            target="_blank"
          >
            cómo emitir tu primera nómina electrónica.
          </Link>
        </Typography>
        <div className={classes.successActionsContainer}>
          <Button
            variant="outlined"
            onClick={handleEditConfiguration}
            disabled={maintenance}
          >
            Editar configuración
          </Button>
          <Button onClick={redirectToDashboard}>Ir al panel de control</Button>
        </div>
      </EmptyState>
    )
  }

  return (
    <ConfigurationProcess
      dispatch={dispatch}
      closeForm={closeForm}
      comeFromEdit={!state.firstAttempt}
      shouldShowSuccessBanner={state.firstAttempt}
    />
  )
}

export default ElectronicPayrollContent
