import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import GoBackLink from 'components/UI/LinkNav/GoBackLink'

import { formatLongDisplayDate } from 'utils/dateTime'

import PeriodRangeInfo from './PeriodRangeInfo'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  heading: {
    alignSelf: 'flex-start',
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    '& > button, a + button, a': {
      marginLeft: '0 !important',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  button: {
    marginBottom: theme.spacing(1.5),
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
}))

const PayrollHeader = ({
  label,
  pathToGoBack,
  buttons,
  periodInfo,
  hideRange = false,
}) => {
  const classes = useStyles()

  const {
    initial_day: initialDay,
    end_day: endDay,
    period_initial_day: periodInitialDay,
    period_end_day: periodEndDay,
  } = periodInfo || {}

  return (
    <>
      <div className={classes.root}>
        <div className={classes.heading}>
          <Typography variant="h5">{label}</Typography>
          {hideRange ||
            (initialDay && endDay && (
              <Typography variant="body2">
                {formatLongDisplayDate(initialDay)}
                {' - '}
                {formatLongDisplayDate(endDay)}
              </Typography>
            ))}
          {pathToGoBack ? <GoBackLink to={pathToGoBack} /> : null}
        </div>
        {periodInitialDay && periodEndDay && (
          <PeriodRangeInfo
            initialDay={periodInitialDay}
            endDay={periodEndDay}
          />
        )}
      </div>
      {buttons && buttons.length > 0 && (
        <div className={classes.buttons}>
          {buttons.map((button) => (
            <Button
              key={button?.key}
              color="secondary"
              variant="text"
              size="small"
              startIcon={button.icon}
              className={classes.button}
              {...button.props}
            >
              {button.label}
            </Button>
          ))}
        </div>
      )}
    </>
  )
}

export default PayrollHeader
