import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarProvider } from 'notistack'
import AppDownloadSnackbar from './DownloadManager/DownloadSnackbar'

const useStyles = makeStyles((theme) => {
  const styles = {
    root: {
      '& .MuiButtonBase-root': {
        backgroundColor: 'transparent',
        border: 0,
        color: 'inherit',
        '&:focus,&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  }

  ;['success', 'error', 'warning', 'info'].forEach((variant) => {
    styles[variant] = {
      backgroundColor: `${theme.palette[variant].light} !important`,
      border: `1px solid ${theme.palette[variant].main}`,
      borderLeft: `8px solid ${theme.palette[variant].main}`,
      color: `${theme.palette[variant].dark} !important`,
    }
  })

  return styles
})

const Notistack = ({ children }) => {
  const notistackRef = useRef()
  const classes = useStyles()

  const onClickDismissNotistack = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          onClick={onClickDismissNotistack(key)}
          aria-label="close notification message"
        >
          <CloseIcon />
        </IconButton>
      )}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      content={(id, message) => {
        if (typeof message === 'object' && message?.title) {
          // just for notifications from useDownloadManager hook
          return <AppDownloadSnackbar id={id} message={message} />
        }
      }}
      maxSnack={5}
      hideIconVariant
      data-cy="notification-snack"
    >
      {children}
    </SnackbarProvider>
  )
}

export default Notistack
