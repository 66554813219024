import { deleteFromStorage, writeStorage } from '@rehooks/local-storage'
import qs from 'query-string'

import * as routes from '../config/routes'

export function getToken() {
  return localStorage.getItem('auth_token')
}

export function getTokenExpiry() {
  return localStorage.getItem('expires')
}

export function hasTokenExpired() {
  const jwtExpiry = getTokenExpiry() * 1000
  const now = Date.now()

  return jwtExpiry - now <= 0
}

export function isAuthenticated() {
  return !!getToken() && !hasTokenExpired()
}

export function isWorker() {
  return localStorage.getItem('role') === 'worker'
}

export function isOrganizer() {
  return localStorage.getItem('role') === 'organizer'
}

export function isAccountant() {
  return localStorage.getItem('role') === 'accountant'
}

export function isAdminAndWorker() {
  return (
    (localStorage.getItem('role') === 'admin' &&
      localStorage.getItem('worker_id')) ||
    (localStorage.getItem('comeFromCompany') &&
      localStorage.getItem('role') === 'worker')
  )
}

export function isAdmin() {
  return localStorage.getItem('role') === 'admin'
}

export function isBasicUser(status) {
  return [
    'basic',
    'free_trial',
    'free_trial_terminated',
    'premium_trial',
    'premium_due',
  ].includes(status)
}

// rename to writeSessionData?
export function logIn({ token, expires, user }) {
  writeStorage('auth_token', token)
  writeStorage('expires', expires)

  if (user) {
    if (user.company_id) {
      writeStorage('company_id', user.company_id)
      writeStorage('has_one_company', true)
    } else if (user.company_id === null) {
      // added to fix very unlikely strange issue about
      // a login to a different alien company when a user
      // that has many companies tries to loggin
      deleteFromStorage('company_id')
      deleteFromStorage('has_one_company')
    }

    if (user.id) writeStorage('user_id', user.id)
    if (user.name) writeStorage('user_name', user.name)
    if (user.email) writeStorage('user_email', user.email)
    if (user.role) writeStorage('role', user.role)
    if (user.worker_id) writeStorage('worker_id', user.worker_id)
    if (user.freemium_status)
      writeStorage('freemium_status', user.freemium_status)
    if (user.premium_trial_end_date)
      writeStorage('premium_trial_end_date', user.premium_trial_end_date)

    writeStorage('distributor', user.distributor || 'nominapp')
  }
}

export function logOut() {
  const showChatAlert = localStorage.getItem('show_chat_alert')

  localStorage.clear()
  localStorage.setItem('logout', Date.now())

  if (showChatAlert !== undefined) {
    localStorage.setItem('show_chat_alert', false)
  }
}

export function getDistributor() {
  return localStorage.getItem('distributor') || 'nominapp'
}

export function getUserId() {
  return localStorage.getItem('user_id')
}

export function getUserEmail() {
  return localStorage.getItem('user_email')
}

/**
 * Checks if session (token) needs to be refreshed
 */
export function checkTokenRefresh() {
  const jwtExpiry = getTokenExpiry() * 1000
  const now = Date.now()

  const remainingTime = jwtExpiry - now
  const minTimeToBeRefreshed = 120000 // 2min

  return remainingTime < minTimeToBeRefreshed
}

export function getUserRoleSection(path) {
  const isWorkerRole = isWorker() ? 'worker' : 'admin'

  return /organizer\b/.test(path) ? 'organizer' : isWorkerRole
}

export function isAlegraDist(distributor = getDistributor()) {
  return ['alegra_nomina'].includes(distributor)
}

export function isAlegraClaroDist(distributor = getDistributor()) {
  return ['alegra_claro'].includes(distributor)
}

export function isBancolombiaDist(distributor = getDistributor()) {
  return ['bancolombia'].includes(distributor)
}

export function isInnpulsaDist(distributor = getDistributor()) {
  return ['innpulsa'].includes(distributor)
}

export function isArusDist(distributor = getDistributor()) {
  return ['arus'].includes(distributor)
}

export function isExcludedPath(path) {
  return (
    path.startsWith(routes.LOGIN) ||
    path.startsWith(routes.LOGIN_2) ||
    path.startsWith(routes.LOGOUT) ||
    path.startsWith(routes.SIGN_UP) ||
    path.startsWith(routes.SIGN_UP_2) ||
    path.startsWith(routes.SIGN_UP_3) ||
    path.startsWith(routes.PASSWORD_RESET) ||
    path.startsWith(routes.PASSWORD_RECOVERY) ||
    path.startsWith(routes.DISTRIBUTOR_LOGIN) ||
    path.startsWith(routes.LOGIN_OAUTH)
  )
}

export function isInsideAppSignUp() {
  // window.location to be precise
  const { search, pathname } = new URL(window.location)

  const queryParams = qs.parse(search)
  const isInsideAuth = queryParams.password_recovered

  return (
    isInsideAuth &&
    [
      routes.SIGN_UP,
      routes.SIGN_UP_2,
      routes.SIGN_UP_3,
      routes.PERIOD_PAY_PAYROLL(),
    ].includes(pathname)
  )
}

export const checkLocalStorageAuthKeys = (key, value) => {
  if (
    key === 'expires' &&
    (Number.isNaN(Number(value)) || value === '' || value === undefined)
  )
    return true

  if (key === 'auth_token' && (value === '' || value === undefined)) return true

  return false
}

export default {
  isAuthenticated,
  isWorker,
  isOrganizer,
  isAdmin,
  checkTokenRefresh,
  logIn,
  logOut,
  getToken,
  getTokenExpiry,
  getUserId,
  getDistributor,
  getUserRoleSection,
  isAlegraDist,
  isAlegraClaroDist,
  isBancolombiaDist,
  isInnpulsaDist,
  isArusDist,
  isExcludedPath,
  isInsideAppSignUp,
  checkLocalStorageAuthKeys,
}
