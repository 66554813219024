import { Children, useState } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 4),
  },
}))

const TabsContainer = ({ tabs = [], children }) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const onChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  if (Children.count(children) !== tabs.length) {
    throw new Error(
      `The number of tabs (${
        tabs.length
      }) must be equal to the number of children (${Children.count(
        children
      )}) wrapped in TabsContainer`
    )
  }

  return (
    <Paper>
      <Tabs
        value={activeTab}
        onChange={onChangeTab}
        tabs={tabs}
        variant="scrollable"
        scrollButtons={smDown ? 'on' : 'off'}
      />
      <div className={classes.container}>{children[activeTab] || children}</div>
    </Paper>
  )
}

export default TabsContainer
