import { useState } from 'react'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { useFormikContext } from 'formik'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Button from 'components/UI/Button/Button'
import TableToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'

import { statusFilter, getDataFilter } from './helpers'

const useStyles = makeStyles((theme) => ({
  toolbarFilters: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    placeSelf: 'baseline',
    [theme.breakpoints.down('sm')]: {
      gridAutoFlow: 'row',
      width: '-webkit-fill-available;',
      justifyItems: 'flex-end',
    },
  },
}))

const ToolbarSearch = (props) => {
  const classes = useStyles()
  const { values, setValues } = useFormikContext()
  const [initialValues] = useState(() => values?.electronicPayrollFormatted)
  const [filterStatus, setFilterStatus] = useState('Todos')

  const { preGlobalFilteredRows, setGlobalFilter, globalFilter } = props

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'massiveActionsMenu',
  })

  const handleFilter = (filter) => {
    popupState.close()
    setFilterStatus(filter.name)
    setValues({
      electronicPayrollFormatted: getDataFilter(initialValues, filter.status),
    })
  }
  return (
    <div className={classes.toolbarFilters}>
      <TableToolbarSearch
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Button
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        {...bindTrigger(popupState)}
      >
        {filterStatus === null || filterStatus === 'Todos'
          ? 'Filtrar por estado'
          : filterStatus}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {statusFilter.map((item) => {
          return (
            <MenuItem
              key={item.status}
              onClick={() => handleFilter(item)}
              selected={item.name === filterStatus}
            >
              {item.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default ToolbarSearch
