import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'

import isotipoAEL from 'assets/images/logos/aportes_en_linea/isotipo_ael.png'
import isotipoArus from 'assets/images/logos/arus/isotipo_arus.png'

import { TotalCard } from './Summary'
import columnsData from './helpers'

const integrationsProviders = {
  aportes_en_linea: {
    name: 'Aportes en línea',
    logo: isotipoAEL,
    logoWidth: 39,
    logoHeight: 38,
  },
  arus: {
    name: 'SuAporte',
    logo: isotipoArus,
    logoWidth: 41,
    logoHeight: 32,
  },
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  link: {
    marginBottom: theme.spacing(2.5),
  },
  table: {
    maxHeight: 400,
    border: 'none',
    '& thead > tr > th': {
      backgroundColor: theme.palette.common.white,
    },
  },
  title: {
    paddingRight: theme.spacing(2),
    fontWeight: 'normal',
    '& > span': {
      fontWeight: 'bold',
    },
    '& > img': {
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(-1),
    },
    marginBottom: theme.spacing(2),
  },
}))

const PeriodSocialSecurityPaymentModalContentSummaryTable = ({
  workers,
  isOnlyWorkers,
  provider,
  workersTotal,
}) => {
  const classes = useStyles()

  return (
    <div>
      {!isOnlyWorkers ? (
        <Typography variant="h5" className={classes.title}>
          Personas para pago de seguridad social en{' '}
          <span>{integrationsProviders[provider]?.name}</span>
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ) : null}
      <Box maxWidth={280} mb={5}>
        <TotalCard workersTotal={workersTotal} />
      </Box>
      <Box marginBottom={3}>
        <Table
          columns={columnsData}
          data={workers}
          options={{
            toolbar: false,
            pagination: false,
            rowStyle: ({ name }) => {
              return name === 'Totales' ? { fontWeight: 600 } : {}
            },
            stickyHeader: true,
          }}
          classNames={{
            tableContainer: classes.table,
          }}
        />
      </Box>
    </div>
  )
}

export default PeriodSocialSecurityPaymentModalContentSummaryTable
