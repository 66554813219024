import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { isWorker } from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import { COMPANY_INDEX, ORGANIZER_COMPANY_INDEX } from 'config/routes'

import ConfigurationButton from './ConfigurationButton'
import HelpButton from './HelpButton'
import UserButton from './UserButton'
import Breadcrumbs from './Breadcrumbs/Index'
import ChangelogButton from './ChangelogButton'
import { useBreadcrumbs } from './Breadcrumbs/helpers'

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.headerHeight,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 5, 0, 4),
    [theme.breakpoints.down('xs')]: {
      height: theme.mobileAppbar,
      padding: theme.spacing(0, 2.5, 0, 0.5),
    },
  },
  rightContent: {
    justifyContent: 'flex-end',
  },
  buttonsContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
}))

const Header = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const breadcrumbs = useBreadcrumbs()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const hideConfigurationButton =
    pathname === COMPANY_INDEX() ||
    pathname === ORGANIZER_COMPANY_INDEX() ||
    isWorker()

  const showChangelogButton = hasSelectedCompany() && !isWorker()

  return (
    <AppBar
      position="static"
      className={clsx(classes.header, {
        [classes.rightContent]: !(breadcrumbs.length > 1) && xsDown,
      })}
    >
      <Breadcrumbs />
      <div className={classes.buttonsContainer}>
        <HelpButton />
        {showChangelogButton ? <ChangelogButton /> : null}
        {!hideConfigurationButton ? <ConfigurationButton /> : null}
        <UserButton />
      </div>
    </AppBar>
  )
}

export default Header
