import React, { useContext } from 'react'

import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'

import usePeriodService from 'utils/hooks/payroll/periodService'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import logoArus from 'assets/images/logos/arus/logo_arus_dark.svg'

import getSeverance from '../ActionGroup/common/helpers'
import { PeriodContext } from '../Payroll'

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down(520)]: {
      maxWidth: '80%',
    },
  },
  title: { marginRight: '5px' },
  logo: {
    verticalAlign: 'middle',
  },
  text: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[800],
  },
}))

const SeveranceFileModal = ({ state, handleClose, period }) => {
  const {
    options: { social_benefits: socialBenefits },
  } = useContext(PeriodContext)
  const { open } = state
  const classes = useStyles()
  const severance = getSeverance(socialBenefits)
  const downloadURI = useDownloadURI()

  const { periodMutation } = usePeriodService({
    queryOptions: { enabled: false },
  })
  const handleOk = () => {
    periodMutation.mutate(
      {
        mutationMethod: 'GET_SEVERANCE_FILE',
        periodId: period.id,
        searchParams: severance.socialBenefitPeriodId,
      },
      {
        onSuccess: ({ file }) => {
          if (file) {
            downloadURI(file)
          }
        },
      }
    )
  }

  return (
    <Modal
      open={open}
      isLoading={periodMutation.isLoading}
      header={
        <Typography variant="h5" className={classes.header}>
          <span className={classes.title}>
            Paga tus cesantías con{' '}
            <img
              src={logoArus}
              alt="Isotipo de Arus"
              className={classes.logo}
            />
          </span>
        </Typography>
      }
      okText="Descargar archivo para pago"
      onOk={handleOk}
      onCancel={handleClose}
      dialogProps={{
        maxWidth: 'sm',
      }}
    >
      <Typography variant="body1" className={classes.text}>
        Con este archivo podrás realizar el pago de las cesantías de tus
        empleados con el operador{' '}
        <strong
          style={{
            color: 'grey',
          }}
        >
          PILA SuAporte ARUS.
        </strong>{' '}
        <br />
      </Typography>
      <Typography variant="body1" className={classes.text}>
        Para saber cómo realizar el pago en Suaporte ARUS,{' '}
        <MuiLink
          href="https://ayuda.nominapp.com/help/paga-las-cesant%C3%ADas-de-tus-empleados-con-suaporte-arus?hs_preview=TzCzFLDy-99209454095"
          target="_blank"
        >
          haz click aquí.
        </MuiLink>
      </Typography>
    </Modal>
  )
}

export default SeveranceFileModal
