import { useState } from 'react'

/**
 * Used in conjunction with ContractModal
 */
const useContractModal = (workerId) => {
  const initialState = {
    open: false,
    contract: {},
    workerId,
  }

  const [state, setState] = useState(initialState)

  const openModal = async (contract = {}) => {
    setState({ open: true, contract, workerId })
  }

  const closeModal = () => {
    setState(initialState)
  }

  return {
    contractModalState: state,
    openContractModal: openModal,
    closeContractModal: closeModal,
  }
}

export default useContractModal
