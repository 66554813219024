import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import DownloadFile from '../../DownloadFile'
import SectionContainer from '../../SectionContainer'
import credentialsColumns from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 3, 4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}))

const EmployerContainer = () => {
  const classes = useStyles()
  const { worker } = useWorker({ useCache: true })

  const sectionData = [
    {
      id: 'employer',
      title: 'Empleador',
      containerCyLabel: 'employer_container',
      hiddenEdit: true,
      items: [
        {
          id: 'company_documents',
          label: 'Documentos adjuntos',
          itemsList: [
            {
              label: 'RUT',
              value: <DownloadFile file={worker.files.tax_document} />,
            },
            {
              label: 'Cámara y comercio',
              value: (
                <DownloadFile file={worker.files.identification_document} />
              ),
            },
            {
              label: 'Cédula representante legal',
              value: (
                <DownloadFile
                  file={worker.files.legal_representative_document}
                />
              ),
            },
          ],
        },
      ],
    },
  ]

  return (
    <>
      {sectionData.map((item) => (
        <SectionContainer item={item} key={item.id} />
      ))}

      <div className={classes.container}>
        <Typography variant="subtitle1" className={classes.title}>
          Credenciales
        </Typography>

        <Table
          columns={credentialsColumns}
          data={worker?.credentials || []}
          options={{
            pagination: false,
            search: false,
          }}
        />
      </div>
    </>
  )
}

export default EmployerContainer
