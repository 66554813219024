import { useState } from 'react'
import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import CustomField from 'components/UI/Formik/CustomField'

import { getAutoCompleteElementFilteredOptions } from 'utils/form'
import useAreaService from 'utils/hooks/company/areaService'
import useNotifications from 'utils/hooks/useNotifications'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { trackEvent } from 'utils/integration'

import tooltipInfo from 'messages/worker'

import WorkerFormFieldsPersonalAreaDialog from './CreateDialog'

const WorkerFormFieldsPersonalArea = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const { showSuccessMessage } = useNotifications()

  const queryClient = useQueryClient()

  const form = useFormikContext()

  const { areaQuery, areaMutation } = useAreaService({
    serviceParams: { queryKey: 'companyAreas' },
  })

  const callbackMutateAreas = ([newArea]) => {
    form.setFieldValue('area', newArea)
    showSuccessMessage('El área se creó con éxito.')

    trackEvent(integrationEvent.COMPANY_AREA_ADD)
  }

  const mutateAreaOption = (__, newValue) => {
    if (newValue?.id === 'create') {
      if (newValue.value) {
        areaMutation.mutate(
          {
            mutationMethod: 'PUT',
            data: [{ name: newValue.value }],
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries('companyAreas')
              callbackMutateAreas(data.data)
            },
          }
        )
      } else {
        setIsCreateDialogOpen(true)
      }
    } else {
      form.setFieldValue('area', newValue)
    }
  }

  return (
    <>
      {isCreateDialogOpen && (
        <WorkerFormFieldsPersonalAreaDialog
          handleClose={() => setIsCreateDialogOpen(false)}
          submitCallback={callbackMutateAreas}
        />
      )}
      <CustomField
        name="area"
        label="Área"
        component="autocomplete"
        placeholder="Escribe el área de la persona..."
        noOptionsText="No hay áreas registradas."
        loadingText="Cargando áreas..."
        options={areaQuery.data}
        tooltipContent={tooltipInfo.area}
        onChange={mutateAreaOption}
        loading={areaQuery.isLoading || areaMutation.isLoading}
        filterOptions={(options, params) =>
          getAutoCompleteElementFilteredOptions(
            options,
            params,
            'Crear nueva área'
          )
        }
      />
    </>
  )
}

export default WorkerFormFieldsPersonalArea
