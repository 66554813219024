import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 'fit-content',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(4),
    },
    emptyMsg: {
      textAlign: 'center',
    },
  }
})

const DashboardCommingSoonSign = ({ type }) => {
  const classes = useStyles()
  let celebrationType

  if (type === 'birthdays') celebrationType = 'cumple años '
  else if (type === 'anniversaries') celebrationType = 'estará de aniversario '
  else celebrationType = 'cumple años, ni estará de aniversario '

  return (
    <Box
      className={clsx(
        classes.root,
        type !== 'birthdays' && type !== 'anniversaries' && classes.rootLonger
      )}
      component={Paper}
    >
      <div className={classes.emptyMsg}>
        <Typography variant="h4">¡Tranquilo!</Typography>
        <Typography>
          Nadie {celebrationType}
          en los próximos 30 días.
        </Typography>
      </div>
    </Box>
  )
}

export default DashboardCommingSoonSign
