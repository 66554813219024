import { Children } from 'react'

import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  columnRow: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  columnRowItem: {
    display: 'grid',
    gridColumn: 'span 1fr',
  },
  fullRow: {
    display: 'grid',
    gridColumn: '1 / -1',
  },
  gutterBottom: {
    marginBottom: theme.spacing(3),
  },
}))

const InputsGroupContainer = ({
  children,
  title,
  gutterBottom = true,
  dataCy,
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.gutterBottom]: gutterBottom,
      })}
      data-cy={dataCy}
    >
      {title && (
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
      )}
      <>{children}</>
    </div>
  )
}

const Horizontal = ({ children, gutterBottom = false }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.fullRow, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      {children}
    </div>
  )
}

const Column = ({ children, gutterBottom = false, dataCy }) => {
  const classes = useStyles()

  return (
    <div
      data-cy={dataCy}
      className={clsx(classes.columnRow, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      {Children.map(children, (child) => (
        <div className={classes.columnRowItem}>{child}</div>
      ))}
    </div>
  )
}

export { Horizontal, Column }
export default InputsGroupContainer
