import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import usePositionService from 'utils/hooks/company/positionService'

const initialValues = { name: '' }

const WorkerFormFieldsPersonalPositionDialog = ({
  handleClose,
  submitCallback,
}) => {
  const queryClient = useQueryClient()

  const { positionMutation } = usePositionService({
    queryOptions: { enabled: false },
  })

  const mutatePositionService = (values) => {
    positionMutation.mutate(
      {
        mutationMethod: 'PUT',
        data: [values],
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries('companyPosition')

          submitCallback(data.data)
          handleClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={mutatePositionService}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit } = formProps

        return (
          <Modal
            open
            header="Agregar nuevo cargo"
            onOk={handleSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={positionMutation.isLoading}
            dialogProps={{
              maxWidth: 'xs',
              fullWidth: true,
              disableBackdropClick: positionMutation.isLoading,
              disableEscapeKeyDown: positionMutation.isLoading,
            }}
          >
            <Form>
              <FormField name="name" label="Nombre" autoFocus />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default WorkerFormFieldsPersonalPositionDialog
