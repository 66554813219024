import clsx from 'clsx'

import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

import genioNominapp from 'assets/images/views/common/genio_think_nominapp.svg'
import logoDian from 'assets/images/logos/logo_dian.svg'

import DynamicLoaderItem from './DynamicLoaderItem'
import { statusMessages } from './helpers'

const useStyles = makeStyles((theme) => ({
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  transactionState: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  transactionStateCompleted: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  transactionStateError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  stepsProgress: {
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: theme.spacing(1.2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  imageNominapp: {
    width: 105,
    height: 99,
  },
  imageDian: {
    width: 162,
    height: 30,
  },
  circularProgress: { marginRight: theme.spacing(2) },
  icon: { marginRight: theme.spacing(1) },
}))

const PeriodElectronicPayrollModalStepTwo = ({
  statusProcess,
  statusStepOne,
  statusStepTwo,
}) => {
  const classes = useStyles()

  const progressPosition = statusStepOne === 'completed' ? 2 : 1

  const messageSubtitle = statusMessages.find((element) =>
    element.status === statusProcess ? element.message : null
  )

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Enviando información a la DIAN
      </Typography>

      <div className={classes.logos}>
        <img
          src={genioNominapp}
          alt="Nominapp"
          className={classes.imageNominapp}
        />
        <img src={logoDian} alt="Logo Dian" className={classes.imageDian} />
      </div>
      <div
        className={clsx(classes.transactionState, {
          [classes.transactionStateCompleted]: statusProcess === 'completed',
          [classes.transactionStateError]: statusProcess === 'error',
        })}
      >
        {statusProcess === 'completed' && (
          <CheckCircleIcon className={classes.icon} />
        )}
        {statusProcess === 'progress' && (
          <CircularProgress size={25} className={classes.circularProgress} />
        )}
        {statusProcess === 'error' && <CloseIcon className={classes.icon} />}
        <Typography variant="subtitle1">{messageSubtitle.message}</Typography>
      </div>

      <div className={classes.stepsProgress}>
        <DynamicLoaderItem
          position={1}
          content="Enviando información a la DIAN"
          status={statusStepOne}
          progressPosition={progressPosition}
        />
        <Divider />
        <DynamicLoaderItem
          position={2}
          content="Recibiendo estado de nómina electrónica"
          status={statusStepTwo}
          progressPosition={progressPosition}
        />
      </div>
    </>
  )
}

export default PeriodElectronicPayrollModalStepTwo
