import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const Button = ({ loading = false, disabled = false, children, ...rest }) => {
  return (
    <MuiButton
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress size={18} />}
      {...rest}
    >
      {children}
    </MuiButton>
  )
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Button
