import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import { PERIOD_PAYROLL_VIEW_DETAILS } from 'config/routes'

import CategoryHeader from '../../common/CategoryHeader'
import useContentStyles from '../../common/commonStyles'

const WithholdingTaxTab = ({
  withholdingTaxInfo,
  handleChangeItemValue,
  payrollId,
}) => {
  const contentClasses = useContentStyles()
  const { withholdingTaxItem, withholdingTaxItemIndex } = withholdingTaxInfo

  return (
    <>
      <CategoryHeader isWithholdingHeader />

      <FormControl className={contentClasses.withholdingItemContainer}>
        <Typography htmlFor="withHoldingTaxValueInput" component="label">
          {withholdingTaxItem.name}
        </Typography>

        <TextField
          id="withHoldingTaxValueInput"
          value={withholdingTaxItem.value}
          onChange={(e) => handleChangeItemValue(e, withholdingTaxItemIndex)}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          data-cy="withholding_tax-input"
        />
        <Box display="flex" justifyContent="center">
          <MuiLink
            href={`${PERIOD_PAYROLL_VIEW_DETAILS(
              payrollId
            )}?selected_tab=payroll_retention`}
            target="_blank"
            style={{ display: 'flex' }}
            variant="subtitle2"
          >
            <VisibilityOutlinedIcon
              fontSize="small"
              style={{ marginRight: 4 }}
            />
            Ver cálculos
          </MuiLink>
        </Box>
      </FormControl>
    </>
  )
}

export default WithholdingTaxTab
