import { isAdmin, isWorker, isOrganizer, isAccountant } from 'utils/auth'

import AdminContent from './Admin/Index'
import WorkerContent from './Worker/Index'

const CreditsIndex = ({ benefitsEnabled, creditStatusData }) => {
  if (isAdmin() || isOrganizer() || isAccountant()) {
    return <AdminContent benefitsEnabled={benefitsEnabled} />
  }

  if (isWorker()) {
    return <WorkerContent creditStatusData={creditStatusData} />
  }

  return null
}

export default CreditsIndex
