import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'

const TouchedAutoRefresh = ({
  termination,
  dirtyTermination,
  setDirtyTermination,
  fetchTerminationPreview,
}) => {
  const { values, touched, setTouched, isSubmitting } = useFormikContext()

  useEffect(() => {
    if (isSubmitting) return

    const refreshChanges = async () => {
      const dirtyValues = getDirtyValues(termination, values, touched)

      // double isOjbectEmpty check to max performance
      if (!isObjectEmpty(dirtyValues)) {
        // set touched fields empty, to prevent reach this point again when
        // the component is updated
        setTouched({})
        // checks if another field diferent than 'cause', 'notes' or 'pay_rest_days' changed
        const dirtyValuesClone = { ...dirtyValues }
        // if these filds changed, there is no need to call api...
        delete dirtyValuesClone.cause
        delete dirtyValuesClone.notes
        delete dirtyValuesClone.pay_rest_days

        let newDirtyTermination

        if (!isObjectEmpty(dirtyValuesClone)) {
          if (dirtyValuesClone.date) {
            // if date changed, nothing else should be sent
            // except for 'cause', 'with_just_cause', 'notes' or 'pay_rest_days'
            newDirtyTermination = { date: dirtyValuesClone.date }

            const {
              cause,
              notes,
              pay_rest_days: payRestDays,
              with_just_cause: withJustCause,
            } = dirtyTermination

            if (cause) newDirtyTermination.cause = cause
            if (notes) newDirtyTermination.notes = notes
            if (payRestDays) newDirtyTermination.pay_rest_days = payRestDays
            if (withJustCause)
              newDirtyTermination.with_just_cause = withJustCause
          } else {
            newDirtyTermination = { ...dirtyTermination, ...dirtyValuesClone }
          }

          const newTermination = await fetchTerminationPreview(
            newDirtyTermination
          )

          if (!newTermination) {
            // occurs when an error happens in fetchTerminationPreview
            return
          }

          // Check if the API modified some value of the dirty termination values,
          // like with 'with_just_cause', when we send it false with 'trial_period' in true
          // the api change 'with_just_cause' to true so it has to be set up too in dirtyTermination.
          const differences = getDirtyValues(
            newDirtyTermination,
            newTermination,
            newDirtyTermination,
            false
          )

          newDirtyTermination = {
            ...newDirtyTermination,
            ...differences,
          }
        } else {
          // its necesary to set the dirtyTermination
          newDirtyTermination = { ...dirtyTermination, ...dirtyValues }
        }

        setDirtyTermination(newDirtyTermination)
      }
    }

    refreshChanges()
  }, [
    dirtyTermination,
    fetchTerminationPreview,
    isSubmitting,
    setDirtyTermination,
    setTouched,
    termination,
    touched,
    values,
  ])

  return null
}

export default TouchedAutoRefresh
