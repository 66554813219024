import { useFormikContext } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import BankField from 'components/UI/Formik/CommonFields/BankField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { paymentMethodsOptions } from 'utils/company'

import tooltipInfo from 'messages/worker'

const useStyles = makeStyles((theme) => ({
  columnsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2.5),
    columnGap: theme.spacing(4),
    [theme.breakpoints.up(740)]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))

const WorkerFormFieldsPayment = () => {
  const classes = useStyles()
  const { values } = useFormikContext()

  return (
    <>
      <Typography
        variant="h6"
        color="primary"
        data-cy="worker_form_paymeny_title"
        gutterBottom
      >
        Datos de pago
      </Typography>
      <div className={classes.columnsContainer}>
        <FormField
          name="payment_method"
          label="Método de pago"
          variant="select"
          options={paymentMethodsOptions}
        />

        {values.payment_method === 'wire_transfer' && (
          <>
            <BankField />
            <AccountTypeField />
            <AccountNumberField tooltipContent={tooltipInfo.account_number} />
          </>
        )}
      </div>
    </>
  )
}

export default WorkerFormFieldsPayment
