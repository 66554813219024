import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { formatPeriodDateRange } from 'utils/dateTime'
import messages from 'messages/payroll'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}))

const PeriodRangeInfo = ({ initialDay, endDay }) => {
  const classes = useStyles()

  return (
    <Tooltip title={messages.header_payroll_range()} interactive>
      <Alert severity="info" icon={<DateRangeIcon />} className={classes.root}>
        <Typography variant="subtitle1" display="inline">
          Nómina del periodo:
        </Typography>{' '}
        <Typography
          display="inline"
          style={{ fontVariant: 'small-caps', whiteSpace: 'nowrap' }}
        >
          {formatPeriodDateRange(initialDay, endDay)}
        </Typography>
      </Alert>
    </Tooltip>
  )
}

export default PeriodRangeInfo
