import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import PicturePersonRunning from 'assets/images/views/onboarding/company/personRunning.png'
import PictureSurprisePerson from 'assets/images/views/onboarding/company/surprisePerson.png'

import { FINISH_ONBOARDING, HISTORY, SIGN } from './helpers'

const useStyles = makeStyles((theme) => ({
  emptyBox: {
    border: '2px dashed',
    borderColor: theme.palette.grey[400],
    width: '240px',
    height: '128px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 1.5),
    borderRadius: 8,
  },
  comingSoon: {
    width: '240px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(20),
    opacity: 0.4,
  },
}))

const OnboardingEmpty = ({ step }) => {
  const classes = useStyles()

  const renderPicture = (group) => {
    if (group.id === HISTORY)
      return <img src={PicturePersonRunning} alt="Persona corriendo" />

    if (group.id === SIGN)
      return <img src={PictureSurprisePerson} alt="Persona sorpresa" />

    return null
  }

  return (
    <div
      className={clsx({
        [classes.emptyBox]: step.id === FINISH_ONBOARDING,
        [classes.comingSoon]: step.id === HISTORY || step.id === SIGN,
      })}
    >
      {renderPicture(step)}
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        noWrap={false}
      >
        {step.id === FINISH_ONBOARDING
          ? 'No has finalizado ningún proceso'
          : 'Esta funcionalidad aún no se encuentra activa'}
      </Typography>
    </div>
  )
}

export default OnboardingEmpty
