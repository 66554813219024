/**
 * Check if enviroment is development
 */
import isEqual from 'lodash/isEqual'

import { getCompanyId } from 'utils/company'

export const isDev = process.env.NODE_ENV === 'development'

/**
 * Check if enviroment is testing with Cypress
 */
export const isTest = !!window.Cypress

export function ElectronicActionsFlag() {
  switch (getCompanyId()) {
    case '16d217d8-20e8-4ed4-a788-ec2483190f7c': // carolinaoboard     STAGE
      return true
    default:
      return false
  }
}

export function HubspotScriptFlag() {
  switch (getCompanyId()) {
    case 'c374ae2b-78e7-46eb-a16f-ea76af9624d1': // CarolinaPrueba     STAGE
    case 'ed85e8f6-2ac9-41a5-983c-4c9ad723aee7': // Victoria Castillo  PRODUCTION
    case '87904fab-631a-420a-b7b9-458b551b0cb0': // Contrera           PRODUCTION
      return true
    default:
      return false
  }
}

/**
 * Check if and object has not properties.
 * @param {Object} obj
 */
export function isObjectEmpty(obj) {
  return obj && Object.keys(obj).length === 0
}

/**
 * Wait for miliseconds.
 *
 * @param {Number} ms Milliseconds
 * @returns {Promise}
 */
export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
/**
 * Fill an array with values, at least one.
 *
 * @param {Array} array
 * @param {Number} max An integer representing the maximum number of ekements with which the array will be filled.
 * @param {*} value The value with which the array will be filled.
 */
export function fillArray(array, max, value) {
  let valueCopy
  if (typeof value === 'object') {
    if (value instanceof Array) valueCopy = [...value]
    else valueCopy = { ...value }
  }

  if (array.length < max) {
    for (let i = array.length; i < max; i += 1) {
      array.push(valueCopy)
    }
  } else {
    array.push(valueCopy)
  }
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.substr(1)
}

export function removeAccents(string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Object version of Array.map()
 */
export function objectMap(object, callback) {
  return Object.keys(object).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = callback(key)
    return result
  }, {})
}

/**
 * Generate a random string between 8 and 20 characters, with a dynamic or fixed length
 * @param {Number} fixedLength
 * @returns {String}
 */
export const generateRandomString = (fixedLength) => {
  const length = fixedLength || Math.floor(Math.random() * (20 - 8 + 1) + 8)

  return Array(length)
    .fill(0)
    .map(() => Math.random().toString(36).charAt(2))
    .join('')
}

/**
 * Compare 2 objects deeply and returns true is they are equal, if not it returns false
 * @param {*} firstObject
 * @param {*} secondObject
 * @returns {Boolean}
 */
export const areObjectsEqual = (firstObject, secondObject) =>
  isEqual(firstObject, secondObject)

/**
 * Converts a file to base64
 * @param {*} file
 * @returns {String}
 */
export const getBase64FromFile = (file) => {
  return new Promise((resolve) => {
    let baseURL = ''
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

/**
 * Remove suffix from string
 * @param {string} value string to remove the suffix
 * @param {number} endIndex index to which the suffix should be removed
 * @returns {String} String value without the suffix
 */
export const removeSuffix = (value = '', endIndex) => {
  return value.slice(0, endIndex)
}

export const isValidUrl = (url) => {
  try {
    return new URL(url)
  } catch (e) {
    return false
  }
}

export const getFileNameFromUrl = (search) => {
  const params = new URLSearchParams(search)

  const fileName = params
    .get('response-content-disposition')
    ?.split(';')?.[1]
    ?.trim()
    ?.split('"')?.[1]

  return fileName && fileName?.length > 0 ? fileName : null
}
