import { useContext, useState } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import { PeriodContext } from '../../Payroll'
import WorkedDaysModal from '../Modals/WorkedDays'

const CellWorkedDays = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const [isModalWorkedDaysVisible, setIsModalWorkedDaysVisible] = useState(
    false
  )

  const isPartTime = payroll.contract_category === 'part_time_contract'

  return (
    <>
      {getPayrollConceptItemCell({
        payrollConceptValue: isPartTime && payroll.worked_time,
        text: 'Días laborados',
        formatter: null,
        onClick: () => setIsModalWorkedDaysVisible(true),
        editable: period.editable && isPartTime && !payroll.locked,
      })}
      {isModalWorkedDaysVisible && (
        <WorkedDaysModal
          payroll={payroll}
          visible={isModalWorkedDaysVisible}
          hideWorkedDaysModal={() => setIsModalWorkedDaysVisible(false)}
        />
      )}
    </>
  )
}

export default CellWorkedDays
