import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'

import Link from '../MaterialUI/Link'

const useStyles = makeStyles((theme) => ({
  gutterLeft: {
    marginLeft: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(0.5, 1),
  },
  icon: {
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
  },
}))

const BadgeLink = ({ text, route, gutterLeft = false, ...props }) => {
  const classes = useStyles()

  return (
    <div>
      <Link
        to={route}
        className={clsx(classes.container, {
          [classes.gutterLeft]: gutterLeft,
        })}
        {...props}
      >
        {text}
        <OpenInNewOutlinedIcon className={classes.icon} color="primary" />
      </Link>
    </div>
  )
}

export default BadgeLink
