import SvgIcon from '@material-ui/core/SvgIcon'

function Badge(props) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <path d="M22.0013 14H16.668V16H22.0013V14Z" fill="white" />
      <path d="M22.0013 18H16.668V20H22.0013V18Z" fill="white" />
      <path
        d="M24.668 7.33464H18.0013V3.33464C18.0013 1.86797 16.8013 0.667969 15.3346 0.667969H12.668C11.2013 0.667969 10.0013 1.86797 10.0013 3.33464V7.33464H3.33464C1.86797 7.33464 0.667969 8.53464 0.667969 10.0013V24.668C0.667969 26.1346 1.86797 27.3346 3.33464 27.3346H24.668C26.1346 27.3346 27.3346 26.1346 27.3346 24.668V10.0013C27.3346 8.53464 26.1346 7.33464 24.668 7.33464ZM12.668 7.33464V3.33464H15.3346V7.33464V10.0013H12.668V7.33464ZM24.668 24.668H3.33464V10.0013H10.0013C10.0013 11.468 11.2013 12.668 12.668 12.668H15.3346C16.8013 12.668 18.0013 11.468 18.0013 10.0013H24.668V24.668Z"
        fill="white"
      />
      <path
        d="M10 18C11.1046 18 12 17.1046 12 16C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16C8 17.1046 8.89543 18 10 18Z"
        fill="white"
      />
      <path
        d="M12.7733 19.5733C11.92 19.2 10.9867 19 10 19C9.01333 19 8.08 19.2 7.22667 19.5733C6.48 19.8933 6 20.6133 6 21.4267V22H14V21.4267C14 20.6133 13.52 19.8933 12.7733 19.5733Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default Badge
