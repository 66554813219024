import TableToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'

import NoveltiesHistoryTabs from './Tabs'
import NoveltiesHistoryDownload from './DownloadNovelties'

const ToolbarNoveltiesHistory = (props) => {
  return (
    <>
      <NoveltiesHistoryTabs />
      <ToolbarContainer {...props}>
        <TableToolbarSearch {...props} />
        <NoveltiesHistoryDownload />
      </ToolbarContainer>
    </>
  )
}

export default ToolbarNoveltiesHistory
