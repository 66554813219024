import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { passwordValid } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const initialValues = {
  password: '',
  password_confirmation: '',
}

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'La contraseña es muy corta')
    .max(128, 'La contraseña es demasiado larga')
    .matches(
      passwordValid,
      'La contraseña solo debe incluir números, letras y caracteres especiales como "#$^+=!*()@%&."'
    )
    .required(),
  password_confirmation: yup
    .string()
    .min(8, 'Contraseña muy corta')
    .matches(
      passwordValid,
      'La contraseña solo debe incluir números, letras y caracteres especiales como "#$^+=!*()@%&."'
    )
    .max(128, 'Contraseña demasiado larga')
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required(),
})
