import { useEffect, useState } from 'react'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Modal from 'components/UI/Modal/Modal'
import SignInForm from 'components/Auth/SignIn/SignIn'

import eugenioSuccess from 'assets/images/avatars/eugenio/eugenio_success.svg'

import { isAuthenticated } from 'utils/auth'

import { LOGIN } from 'config/routes'

const stepsLoginFromRecovery = [
  {
    title: '¡La contraseña fue actualizada!',
    description:
      'Acabas de cambiar tu contraseña, puedes continuar usando el mejor software de nómina electrónica del país.',
  },
  {
    title: 'Ingresa a tu cuenta',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    rowGap: theme.spacing(2),
    justifyItems: 'center',
    textAlign: 'center',
  },
}))

const SignupModal = () => {
  const [step, setStep] = useState(0)
  const history = useHistory()
  const location = useLocation()

  const { state } = location
  const passwordRecoveredSuccessfully = state?.passwordRecoveredSuccessfully

  const classes = useStyles()

  const handleSubmit = () => {
    if (step === 0) {
      setStep((previous) => previous + 1)
    }

    return null
  }

  const handleCloseModal = () => history.push(LOGIN)

  // Redirect when the path does not come from the password recovery
  useEffect(() => {
    if (!passwordRecoveredSuccessfully) {
      history.push(LOGIN)
    }
  }, [history, passwordRecoveredSuccessfully])

  // Redirect to inside app when the login is from modal
  if (isAuthenticated()) {
    const pathname = '/'
    return (
      <Redirect
        to={{
          pathname,
          state: { fromLogin: true },
        }}
      />
    )
  }

  return (
    <Modal
      open
      footer={step === 1}
      hideCancelButton
      onOk={handleSubmit}
      okText={step === 0 ? 'Continuar' : null}
      onCloseModal={handleCloseModal}
      alignButtons="center"
      dialogProps={{ maxWidth: 'xs' }}
    >
      <div className={clsx(classes.root)}>
        <img
          src={eugenioSuccess}
          alt="Eugenio success"
          width={256}
          height={230}
        />
        <Typography variant="h4" className={classes.title}>
          {stepsLoginFromRecovery[step].title}
        </Typography>
        <Typography variant="body2">
          {stepsLoginFromRecovery[step].description}
        </Typography>
        {step === 1 ? <SignInForm isFromRecoveryPassword /> : null}
      </div>
    </Modal>
  )
}

export default SignupModal
