import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import NoWrap from 'components/UI/NoWrap'

import { contractCategories } from 'utils/worker'
import { formatCurrency } from 'utils/format'

import WorkerExpander from './WorkerExpander'

const useStyles = makeStyles((theme) => ({
  itemText: {
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  totalItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  topValue: {
    marginBottom: theme.spacing(0.5),
  },
}))

const ItemText = ({ expanded = false, children }) => {
  const classes = useStyles()

  return (
    <NoWrap>
      <Typography
        className={clsx({
          [classes.itemText]: expanded,
        })}
        variant="caption"
      >
        {children}
      </Typography>
    </NoWrap>
  )
}

const TotalItem = ({ topValue, bottomValue, expanded }) => {
  const classes = useStyles()

  return (
    <div className={classes.totalItem}>
      <div className={classes.topValue}>
        <ItemText expanded={expanded}>{topValue}</ItemText>
      </div>
      <ItemText expanded={expanded}>{bottomValue}</ItemText>
    </div>
  )
}

// Function to get the original row data from a sub row
const getMainRowData = (row, rowsById) => {
  const parentRowId = row.id.split('.').slice(0, -1).join('.')

  if (parentRowId) {
    const parentRow = rowsById[parentRowId]

    return parentRow?.original
  }

  return undefined
}

const getColumns = (periodId, payPreviousPayroll) => {
  return [
    {
      id: 'expander',
      excludeOnEdit: true,
      customMinWidth: '200px',
      Header: 'Información del empleado',
      Cell: ({ row, toggleRowExpanded, state, rowsById }) => {
        const toggleExpandedProps = row.getToggleRowExpandedProps()

        const onExpand = () => {
          if (!row.isExpanded) {
            Object.keys(state.expanded).forEach((key) => {
              toggleRowExpanded(key, false)
            })
          }
          toggleExpandedProps?.onClick?.()
        }

        if (row.canExpand) {
          return (
            <WorkerExpander
              workerId={row.original.worker_id}
              payrollId={row.original.payroll_id}
              expanded={row.isExpanded}
              onExpand={onExpand}
              workerName={row.original.worker_name}
              periodId={periodId}
              payPreviousPayroll={payPreviousPayroll}
            />
          )
        }

        if (!row.canExpand && row.index === 0) {
          const mainRowData = getMainRowData(row, rowsById)

          if (!mainRowData) return null

          return (
            <ItemText>
              {contractCategories[mainRowData?.contract_category]}
            </ItemText>
          )
        }

        if (!row.canExpand && row.index === 1) {
          const mainRowData = getMainRowData(row, rowsById)

          if (!mainRowData) return null

          return (
            <ItemText>
              {`${mainRowData?.worked_time?.label}: ${mainRowData?.worked_time?.value}`}
            </ItemText>
          )
        }

        return null
      },
    },
    {
      Header: 'Ingresos',
      alignHeader: 'center',
      customMinWidth: '250px',
      columns: [
        {
          accessor: 'income.label',
          Header: 'Concepto',
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.income.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'income.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.income.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.income.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Seguridad social y deducciones',
      alignHeader: 'center',
      customMinWidth: '250px',
      columns: [
        {
          accessor: 'deductions.label',
          Header: 'Concepto',
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.deductions.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'deductions.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.deductions.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.deductions.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Seguridad social Emp.',
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'social_security.label',
          Header: 'Concepto',
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.social_security.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'social_security.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.social_security.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.social_security.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Parafiscales',
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'parafiscals.label',
          Header: 'Concepto',
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.parafiscals.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'parafiscals.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.parafiscals.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.parafiscals.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Provisiones',
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'social_benefits.label',
          Header: 'Concepto',
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.social_benefits.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'social_benefits.value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.social_benefits.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.social_benefits.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      Header: 'Totales',
      alignHeader: 'center',
      customMinWidth: '180px',
      columns: [
        {
          accessor: 'total_concept',
          Header: 'Concepto',
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={row.original.worker_payment.label}
                bottomValue={row.original.company_cost.label}
              />
            )
          },
        },
        {
          accessor: 'total_value',
          Header: 'Valor',
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={formatCurrency(row.original.worker_payment.value)}
                bottomValue={formatCurrency(row.original.company_cost.value)}
              />
            )
          },
        },
      ],
    },
  ]
}

export default getColumns
