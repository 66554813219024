import RestoreIcon from '@material-ui/icons/Restore'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'

import { isOrganizer } from 'utils/auth'
import { formatDateRange } from 'utils/dateTime'

import * as routes from 'config/routes'

export const columnsData = [
  {
    Header: 'Periodo',
    accessor: 'period_range',
    Cell: ({ row }) => (
      <Link
        to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(row.original.id)}
        data-cy={`electronic-period-${row.original.id}`}
      >
        {formatDateRange(row.original.initial_day, row.original.end_day)}
      </Link>
    ),
  },
  {
    Header: 'Personas',
    accessor: 'total',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.total,
  },
  {
    Header: 'Emitidos',
    accessor: 'issued',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.issued,
  },
  {
    Header: 'Rechazados',
    accessor: 'rejected',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.rejected,
  },
  {
    Header: 'Procesando',
    accessor: 'processing',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.processing,
  },
  {
    Header: 'Sin emitir',
    accessor: 'pending',
    customWidth: '135px',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.pending,
  },
  {
    Header: 'Anulados',
    accessor: 'canceled',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.canceled,
  },
  {
    Header: 'Modificados',
    accessor: 'modified',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.modified,
  },
]

export const useGetActions = (handleOrganizerElectronicPeriodAction) => {
  const confirm = useConfirm()

  let actions = []

  if (isOrganizer()) {
    actions = actions.concat([
      (rowData) => ({
        id: 'recalculateElectronicPayroll',
        icon: RestoreIcon,
        tooltip: 'Recalcular nómina electrónica',
        onClick: () => {
          confirm({
            type: 'warning',
            title: '¿Desea recalcular la nómina electrónica de este periodo?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerElectronicPeriodAction(rowData.id, 'recalculate'),
          })
        },
      }),
    ])
  }

  return actions
}

export const dataFormatted = (months) =>
  months.map((month) => ({
    id: month.id,
    name: month.name,
  }))
