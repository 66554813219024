import { useFormikContext } from 'formik'

import CustomField from 'components/UI/Formik/CustomField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'

import InputsGroupContainer, {
  Horizontal,
  Column,
} from '../InputsGroupContainer'
import CalendarIcon from '../CalendarIcon'

import { useCreditFormFields } from '../hooks'
import { getNestedProp } from '../helpers'

const PersonalData = () => {
  const { inputsPersonalData } = useCreditFormFields()
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const onChangeDepartment = (
    newSelectedValue,
    path,
    cityKey,
    departmentKey
  ) => {
    const nestedProp = getNestedProp(values, path)
    if (nestedProp && nestedProp?.id !== newSelectedValue?.id) {
      setFieldValue(cityKey, null)
      setFieldTouched(cityKey)
    }
    setFieldValue(departmentKey, newSelectedValue)
  }

  return (
    <div>
      {inputsPersonalData.map((section) => (
        <InputsGroupContainer
          key={section?.id}
          title={section?.title}
          gutterBottom={section?.gutterBottom}
        >
          {section?.rows.map((row) => {
            const Wrapper = row?.component === 'full-row' ? Horizontal : Column

            return (
              <Wrapper key={row?.id}>
                {row?.fields.map((field) => {
                  if (
                    ['document_type', 'spouse_document_type'].includes(
                      field?.name
                    )
                  ) {
                    return (
                      <DocumentTypeField
                        key={field?.name}
                        name={field?.name}
                        idNumberKey={field?.idNumberKey}
                        data-cy={field?.dataCy}
                      />
                    )
                  }

                  if (['id_number', 'spouse_id_number'].includes(field?.name)) {
                    return (
                      <IdentificationNumberField
                        key={field?.name}
                        name={field?.name}
                        documentTypeKey={field?.documentTypeKey}
                        data-cy={field?.dataCy}
                      />
                    )
                  }

                  if (field?.name?.includes('department')) {
                    return (
                      <DepartmentsList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        onChange={(_, newSelectedValue) =>
                          onChangeDepartment(
                            newSelectedValue,
                            field?.departmentPath,
                            field?.cityKey,
                            field?.name
                          )
                        }
                      />
                    )
                  }

                  if (['birth_place', 'issue_place'].includes(field?.name)) {
                    const department = getNestedProp(
                      values,
                      field?.departmentPath
                    )

                    return (
                      <CitiesList
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        placeholder={field?.placeholder}
                        department={department}
                      />
                    )
                  }

                  return (
                    <CustomField
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      placeholder={field?.placeholder}
                      component={field?.component}
                      data-cy={field?.dataCy}
                      options={field?.options || undefined}
                      datepickerProps={
                        field?.component === 'datepicker' && {
                          InputProps: {
                            endAdornment: <CalendarIcon />,
                          },
                        }
                      }
                    />
                  )
                })}
              </Wrapper>
            )
          })}
        </InputsGroupContainer>
      ))}
    </div>
  )
}

export default PersonalData
