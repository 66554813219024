import { makeStyles } from '@material-ui/core/styles'

import Loading from 'components/UI/Loading/Loading'

import { getCompanyId } from 'utils/company'
import useCompanyOnboardingService from 'utils/hooks/worker/onboarding'

import OnboardingCardsContainner from './OnboardingCardsContainner'
import { getSteps } from './helpers'

const useStyles = makeStyles((theme) => ({
  onboardingContainner: {
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
    gap: theme.spacing(4),
  },
}))

const OnboardingWorkers = () => {
  const classes = useStyles()
  const companyId = getCompanyId()

  const { companyOnboardingQuery } = useCompanyOnboardingService({
    serviceParams: { queryKey: ['getOnboardingWorkers', companyId] },
  })

  if (companyOnboardingQuery.isLoading) return <Loading />

  const workersOnboarding = companyOnboardingQuery?.data?.workers_onboardings
  const steps = getSteps(workersOnboarding)

  return (
    <div className={classes.onboardingContainner}>
      {steps.map((step) => (
        <OnboardingCardsContainner key={step.id} step={step} />
      ))}
    </div>
  )
}

export default OnboardingWorkers
