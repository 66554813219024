import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Dropzone from 'components/UI/Dropzone/Dropzone'

import getWorkersUploadMessage from 'messages/workers_file'

import uploadImg from 'assets/images/views/worker/uploadWorkers.png'

import useStyles from './helpers'

const WorkerImportBoxUpload = ({ onSubmitFile }) => {
  const classes = useStyles()
  const workerFileInfo = getWorkersUploadMessage()

  const onAddFile = (file) => {
    onSubmitFile(file)
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">2. Cargar Archivo</Typography>
      <Typography paragraph>{workerFileInfo.upload}</Typography>

      <div className={classes.image}>
        <img src={uploadImg} alt="Upload File" />
      </div>

      <Dropzone handleAdd={onAddFile} />
    </Paper>
  )
}

export default WorkerImportBoxUpload
