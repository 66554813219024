import AreaField from 'components/Worker/Form/Fields/Personal/Area/Area'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'
import CostCenterField from 'components/Worker/Form/Fields/Personal/CostCenter/CostCenter'

const JobPositionSection = () => {
  return (
    <>
      <LocationField />
      <AreaField />
      <PositionField />
      <CostCenterField />
    </>
  )
}

export default JobPositionSection
