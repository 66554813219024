import { useContext } from 'react'
import { useFormikContext } from 'formik'
import { useIsMutating } from 'react-query'

import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import SummaryItem from 'components/UI/Summary/SummaryItem'
import SummaryTotal from 'components/UI/Summary/SummaryTotal'
import ModalitySwitch from 'components/Subscription/Atoms/ModalitySwitch'
import Button from 'components/UI/Button/Button'
import { useUser } from 'components/App/UserContext/useUser'

import useNotifications from 'utils/hooks/useNotifications'
import { isObjectEmpty } from 'utils/general'

import { NewPricingContext } from '../Index'
import { getSummaryItems } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(6.5, 2, 3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6.5, 5.5, 3, 5.5),
    },
  },
  title: {
    fontSize: '20px',
    marginBottom: theme.spacing(6.5),
    marginTop: theme.spacing(3.5),
  },
  totalContainer: {
    marginTop: theme.spacing(5),
  },
  continueContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(18),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  continueButton: {
    maxWidth: 350,
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  largeBar: {
    borderRadius: 30,
  },
  smallBar: {
    borderRadius: 10,
  },
}))

const SubscriptionSummary = ({ handleSubmitForm }) => {
  const classes = useStyles()
  const { company } = useUser()
  const { errors, values } = useFormikContext()
  const { showErrorMessage } = useNotifications()
  const { selectedPlan, subscription, loadingPayment } = useContext(
    NewPricingContext
  )
  const isPartner = subscription?.data?.partner
  const isClaraPayment =
    subscription?.data?.payment_category === 'clara_payment'
  const isAnnually = values?.modality === 'year'
  const isPlanSelect = !isObjectEmpty(selectedPlan)
  const summaryItems = getSummaryItems(
    { ...selectedPlan, payment_type: subscription?.data?.type },
    subscription,
    isAnnually,
    company?.name
  )
  const yearlyDiscount =
    selectedPlan?.subscription_value?.discounts?.yearly_percentage * 100
  const totalItem = summaryItems.find(
    (summaryItem) => summaryItem.id === 'total'
  )
  const totalItems = summaryItems.filter((item) =>
    [
      'subtotal_item',
      'extra_workers_subtotal',
      'subscription_discount',
    ].includes(item.id)
  )
  const isCalculatingPrice = useIsMutating({
    mutationKey: 'calculateSubscriptionPrice',
    fetching: true,
  })

  const handleContinueAction = () => {
    if (
      errors?.workers_number &&
      selectedPlan?.coded_name !== 'free_per_worker_plan' &&
      !subscription?.data?.partner
    ) {
      showErrorMessage('Debes ingresar la cantidad de personas de tu plan')
      return
    }

    if (errors?.payment_method) {
      showErrorMessage('Por favor selecciona el método de pago')
      return
    }

    handleSubmitForm()
  }

  return (
    <Slide direction="left" timeout={500} in>
      <div className={classes.container} data-cy="subscription_payment_summary">
        <div>
          {!isPartner && !isClaraPayment ? (
            <div className={classes.switchContainer}>
              <ModalitySwitch discount={yearlyDiscount} name="modality" />
            </div>
          ) : null}
          <Typography variant="h5" className={classes.title}>
            Resumen de tu suscripción
          </Typography>
          {summaryItems.map((summaryItem) => {
            if (
              ![
                'total',
                'extra_workers_subtotal',
                'subtotal_item',
                'subscription_discount',
              ].includes(summaryItem.id)
            ) {
              return (
                <SummaryItem
                  key={summaryItem.id}
                  label={summaryItem.label}
                  value={summaryItem.value}
                  center={summaryItem.id === 'extra_workers'}
                />
              )
            }
            return null
          })}
          <div className={classes.totalContainer}>
            {!isCalculatingPrice ? (
              <SummaryTotal
                label="Total a pagar"
                value={totalItem?.value}
                items={totalItems}
              />
            ) : (
              <div className={classes.skeletonContainer}>
                <Skeleton
                  animation="wave"
                  width="20%"
                  className={classes.smallBar}
                />
                <Skeleton
                  animation="wave"
                  width="50%"
                  className={classes.smallBar}
                />
                <Skeleton
                  animation="wave"
                  width="40%"
                  className={classes.smallBar}
                />
                {isAnnually ? (
                  <Skeleton
                    animation="wave"
                    width="30%"
                    className={classes.smallBar}
                  />
                ) : null}
                <Skeleton
                  animation="wave"
                  width={294}
                  height={70}
                  className={classes.largeBar}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.continueContainer}>
          <Button
            fullWidth
            disabled={!isPlanSelect}
            loading={loadingPayment}
            onClick={handleContinueAction}
            data-cy="subscription_payment_button_ok"
            className={classes.continueButton}
          >
            Pagar suscripción
          </Button>
        </div>
      </div>
    </Slide>
  )
}

export default SubscriptionSummary
