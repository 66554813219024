import AccessTimeIcon from '@material-ui/icons/AccessTime'
import BusinessIcon from '@material-ui/icons/Business'
import FaceIcon from '@material-ui/icons/Face'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'

import { useUser } from 'components/App/UserContext/useUser'

import { hasOneCompany } from 'utils/company'

import * as routes from 'config/routes'

import useCloseDrawer from '../useCloseDrawer'

export default function useWorker() {
  const { active, workerWithBenefits, clearUser } = useUser()
  const handleCloseMobileDrawer = useCloseDrawer()

  return [
    {
      key: 'informations',
      Icon: FaceIcon,
      text: 'Mi perfil',
      path: routes.WORKER_PROFILE_SHOW(),
      hidden: !active,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'payroll_history',
      Icon: AccessTimeIcon,
      text: 'Historial de nómina',
      path: routes.WORKER_PROFILE_PAYMENTS_HISTORY(),
      hidden: !active,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'benefits',
      Icon: ThumbUpAltOutlinedIcon,
      text: 'Beneficios',
      path: routes.WORKER_PROFILE_BENEFITS(),
      hidden: !workerWithBenefits,
      onClick: () => handleCloseMobileDrawer(),
    },

    {
      key: 'companies',
      Icon: BusinessIcon,
      text: 'Empresas',
      path: routes.COMPANY_INDEX(),
      hidden: hasOneCompany(),
      onClick: () => {
        clearUser()
        handleCloseMobileDrawer()
      },
    },
  ]
}
