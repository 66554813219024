import { Formik, Form } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import Page from 'components/UI/Page/Page'
import FormBox from 'components/UI/FormBox'
import Button from 'components/UI/Button/Button'
import useFormStyles from 'components/UI/Form/formStyles'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import { useUser } from 'components/App/UserContext/useUser'

import { formatValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { setOnBoardingNextStepWorkerForm } from 'utils/onboarding'

import * as routes from 'config/routes'

import FieldsOnBoarding from './Fields'
import schemaValidation from './helpers'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContent: {
    maxWidth: 800,
    width: '100%',
  },
}))

const WorkerForm = ({ history }) => {
  const { handleError } = useErrorHandler()
  const { showSuccessMessage, showWarningMessage } = useNotifications()
  const classes = useStyles()
  const classesForm = useFormStyles()

  const {
    company: { onboarding },
    refreshCompany,
  } = useUser()

  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleRedirect = () => {
    if (onboarding) {
      history.push(routes.PERIOD_PAY_PAYROLL())
    }
  }

  const initialWorker = {
    name: localStorage.getItem('user_name'),
    document_type: 'cc',
  }

  const callbackSuccess = () => {
    setOnBoardingNextStepWorkerForm()
    showSuccessMessage('El trabajador fue creado exitosamente')
    handleRedirect()
    refreshCompany()
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { object: 'id_number' },
        { object: 'email' },
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })
  }

  const handleSubmit = (values, form) => {
    const worker = formatValues(values)

    if (!isObjectEmpty(worker)) {
      workerMutation.mutate(
        {
          mutationMethod: 'POST',
          worker: { worker: { ...worker } },
        },
        {
          onSuccess: () => {
            callbackSuccess()
          },
          onError: (error) => {
            callbackError(error, form)
          },
        }
      )
    }
  }

  return (
    <Page header="Crear nueva Persona" documentTitle="Crear nueva Persona">
      <div className={classes.content}>
        <div className={classes.formContent}>
          <FormBox>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialWorker}
              validationSchema={schemaValidation}
              enableReinitialize
            >
              {({ isValid, handleSubmit: onSubmit }) => {
                return (
                  <>
                    <Form className={classes.form}>
                      <FieldsOnBoarding />
                    </Form>
                    <div className={classesForm.actionsContainer}>
                      <Button
                        onClick={() => {
                          if (!isValid)
                            showWarningMessage(
                              'Existen errores en alguno de los campos.'
                            )
                          onSubmit()
                        }}
                        disabled={workerMutation.isLoading}
                      >
                        Crear empleado
                      </Button>
                    </div>
                    {workerMutation.isLoading && <LoadingModal />}
                  </>
                )
              }}
            </Formik>
          </FormBox>
        </div>
      </div>
    </Page>
  )
}

export default WorkerForm
