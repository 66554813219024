import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import addDays from 'date-fns/addDays'

import FormField from 'components/UI/Formik/FormField/Index'

import messages from 'messages/wage'

// contractInitialDay prob no necessary
const InitialDayField = ({ currentWage, contractInitialDay }) => {
  const { values, setFieldValue } = useFormikContext()

  let minInitialDay =
    values?.prevInitialDay || currentWage?.initial_day || contractInitialDay

  // if its not the currentWage
  if (values.id !== currentWage.id) minInitialDay = addDays(minInitialDay, 1)

  useEffect(() => {
    // default value
    if (!values.initial_day) setFieldValue('initial_day', new Date())
  }, [setFieldValue, values.initial_day])

  return (
    <FormField
      name="initial_day"
      label="Fecha inicial salario"
      variant="datepicker"
      disabled={values?.first}
      minDate={minInitialDay}
      tooltipContent={messages.initial_day}
    />
  )
}

export default InitialDayField
