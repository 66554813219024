import { ButtonBase, Paper, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '304px',
    minWidth: '150px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(4, 0, 3, 3),
  },
  title: {
    marginLeft: theme.spacing(1.5),
    textAlign: 'start',
  },
  descriptionContainer: {
    backgroundColor: theme.palette.primary.lighter,
    height: '100%',
    padding: theme.spacing(1, 3, 1, 3),
  },
}))

const InfoCard = ({ card }) => {
  const { icon, title, number, description, onClick } = card
  const classes = useStyles()

  return (
    <Paper className={classes.card}>
      <ButtonBase onClick={onClick} className={classes.titleSection}>
        {icon}
        <div className={classes.title}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="h5" color="primary">
            {String(number)}
          </Typography>
        </div>
      </ButtonBase>
      <div className={classes.descriptionContainer}>
        <Typography variant="body2">{description}</Typography>
      </div>
    </Paper>
  )
}

export default InfoCard
