import { useHistory } from 'react-router-dom'

import {
  getUserRoleSection,
  isAdminAndWorker,
  isAlegraClaroDist,
  isBasicUser,
  isOrganizer,
  isWorker,
} from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import {
  COMPANY_INDEX,
  DASHBOARD,
  LOGOUT,
  SUBSCRIPTION,
  SUBSCRIPTION_STATUS,
  USER_PROFILE,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

import { useUser } from '../UserContext/useUser'

const useUserMenuOptions = (onClosePopup, xsDown, userHashCompanyName) => {
  const history = useHistory()
  const { updateUser, clearFetchCompany, subscription, distributor } = useUser()
  const options = []

  const hiddeSubscription =
    !hasSelectedCompany() ||
    distributor === 'alegra_claro' ||
    (['accountant_payment', 'distributor_payment'].includes(
      subscription.payment_category
    ) &&
      !isOrganizer())

  const getSwitchMenuItemConf = () => {
    const role = getUserRoleSection(history.location.pathname)

    const [message, url] =
      role === 'worker'
        ? ['empresa', DASHBOARD]
        : ['empleado', WORKER_PROFILE_SHOW()]

    return { message, url }
  }
  const { message, url } = getSwitchMenuItemConf()

  const openCompaniesView = () => {
    onClosePopup()
    clearFetchCompany()
    history.push(COMPANY_INDEX())
  }

  const handleAdminAndWorker = () => {
    onClosePopup()
    updateUser({
      role: isWorker() ? 'admin' : 'worker',
    })
    localStorage.setItem('comeFromCompany', true)
    history.replace(url)
  }

  const openSubscriptionView = () => {
    onClosePopup()
    history.push(
      isBasicUser(subscription?.status) ? SUBSCRIPTION() : SUBSCRIPTION_STATUS()
    )
  }

  const logOut = () => {
    onClosePopup()
    history.replace(LOGOUT)
  }

  const openProfileView = () => {
    onClosePopup()
    history.push(USER_PROFILE())
  }

  if (!isAlegraClaroDist() && hasSelectedCompany()) {
    options.push({
      id: 'show_profile',
      name: 'Cuenta',
      onClick: openProfileView,
    })
  }

  if (!hiddeSubscription && !isWorker()) {
    options.push({
      id: 'show_subscription',
      name: 'Suscripción',
      onClick: openSubscriptionView,
    })
  }

  if (!isWorker() && !isOrganizer()) {
    options.push({
      id: 'show_companies',
      name: 'Ver empresas',
      onClick: openCompaniesView,
    })
  }

  if (isAdminAndWorker()) {
    options.push({
      id: 'admin_worker',
      name: `Perfil ${message}`,
      onClick: handleAdminAndWorker,
    })
  }

  if (!isAlegraClaroDist() || isOrganizer()) {
    options.push({
      id: 'logout',
      name: 'Cerrar sesión',
      onClick: logOut,
    })
  }

  if (xsDown) {
    options.unshift({
      id: 'show_company_name',
      name: <b>{userHashCompanyName()}</b>,
      onClick: () => {},
    })
  }

  return options
}

export default useUserMenuOptions
