import { isTest } from 'utils/general'

const useDownloadURI = () => {
  return (uri, target = '_blank') => {
    if (isTest) return

    const isPdf = new URL(uri)?.search?.includes?.('.pdf')
    const link = document.createElement('a')
    if (isPdf) link.target = target
    document.body.appendChild(link)
    link.style = 'display: none'
    link.href = uri
    link.download = true
    link.click()
    document.body.removeChild(link)
  }
}

export default useDownloadURI
