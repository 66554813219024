import { Form, Formik } from 'formik'

import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getDirtyValues } from 'utils/form'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useCertificateService from 'utils/hooks/worker/certificate'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { certificateYearOptions } from 'utils/worker'

export default ({ worker, visible, handleClose }) => {
  const { certificateMutation } = useCertificateService()
  const downloadURI = useDownloadURI()
  const { isWorker } = useUser()

  const handleSubmit = (values) => {
    const dataToSend = getDirtyValues({}, values)

    certificateMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: isWorker
          ? 'workerProfileIncomeCertificate'
          : 'incomeCertificate',
        workerId: worker.id,
        options: dataToSend,
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
          trackEvent(integrationEvent.EMPLOYEE_INCOME_CERTIFICATE)
          handleClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={{
        certificate_year: certificateYearOptions[0].value,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps

        return (
          <Modal
            open={visible}
            header="Certificado de Ingresos y Retenciones"
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Generar Archivo"
            isLoading={certificateMutation.isLoading}
          >
            <Typography>
              Escoge el año del cual quieres generar el Certificado de Ingresos
              y Retenciones.
            </Typography>
            <Form>
              <FormField
                name="certificate_year"
                label="Año del certificado:"
                variant="select"
                options={certificateYearOptions}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}
