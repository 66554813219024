import clsx from 'clsx'

import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  filledContainer: {
    height: 48,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'initial',
  },
  deleteButton: {
    backgroundColor: 'transparent',
    width: 'auto',
    height: 'auto',
    marginBottom: theme.spacing(-1.25),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      width: 'auto',
      height: 'auto',
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  filledContent: {
    height: 48,
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  valueContainer: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(-1),
    columnGap: theme.spacing(1.5),
    rowGap: theme.spacing(0.3),
  },
  completedText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  checkIcon: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  nameContainer: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progress: {
    width: 'calc(100% - 38px)',
  },
}))

const LoadedFile = ({
  handleDeleteFile,
  fileName,
  value,
  hideRemoveButton,
  className,
} = {}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.baseContainer, classes.filledContent, className)}
    >
      <div
        className={clsx(
          classes.baseContainer,
          classes.filledContainer,
          className
        )}
      >
        <div className={clsx(classes.baseContainer, classes.valueContainer)}>
          <div className={classes.nameContainer}>
            <Typography variant="caption" noWrap>
              {value?.name || fileName || ''}
            </Typography>
          </div>
          <div className={classes.completedText}>
            <CheckIcon className={classes.checkIcon} />
            <Typography variant="caption">Completado</Typography>
          </div>
          {!hideRemoveButton ? (
            <IconButton
              size="small"
              aria-label="delete document"
              className={classes.deleteButton}
              onClick={handleDeleteFile}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : null}
        </div>
        <LinearProgress
          variant="determinate"
          value={100}
          className={clsx({
            [classes.progress]: !hideRemoveButton,
          })}
        />
      </div>
    </div>
  )
}

export default LoadedFile
