import { Fragment } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    display: 'grid',
    justifyContent: 'flex-start',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '350px 350px',
    marginBottom: theme.spacing(2),
    '& .MuiTypography-root': {
      margin: '0 5px',
    },
  },

  summaryItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
  },

  commentItem: {
    gridColumn: '1/3',
  },
}))

const OrganizerPartnerShowSummary = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.summaryContainer}>
      {data.map((ele) => {
        const key = `card_${ele.label.split(' ').join('_')}`

        return (
          <Fragment key={key}>
            {!ele.comment ? (
              <Card key={key}>
                <CardContent classes={{ root: classes.summaryItem }}>
                  <Typography component="span" color="primary">
                    <ele.Icon style={{ fontSize: 40, margin: 5 }} />
                  </Typography>
                  <Typography variant="subtitle2">{ele.label}</Typography>
                  <Typography variant="h5">{ele.value}</Typography>
                </CardContent>
              </Card>
            ) : (
              <Card classes={{ root: classes.commentItem }} key={key}>
                <CardContent>
                  <Typography variant="subtitle2" gutterBottom>
                    <ele.Icon color="primary" style={{ fontSize: 13 }} />{' '}
                    {ele.label}
                  </Typography>
                  <Typography>{ele.value}</Typography>
                </CardContent>
              </Card>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default OrganizerPartnerShowSummary
