import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'

const useStyles = makeStyles((theme) => ({
  transactionStateError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  step: {
    display: 'grid',
    gridTemplateColumns: '30px minmax(100px,350px) minmax(30px, max-content)',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    alignItems: 'center',
    justifyItems: 'self-start',
  },
  opaqueStep: {
    opacity: 0.6,
  },
  circularProgress: { marginRight: theme.spacing(2) },
}))

const DynamicLoaderItem = ({ position, content, status, progressPosition }) => {
  const classes = useStyles()

  return (
    <>
      <div
        className={clsx(classes.step, {
          [classes.opaqueStep]: position > progressPosition,
          [classes.transactionStateError]: status === 'error',
        })}
      >
        {status === 'completed' && <CheckCircleIcon color="primary" />}
        {status === 'progress' && (
          <FiberManualRecordOutlinedIcon color="primary" />
        )}
        {status === 'error' && <CloseIcon color="error" />}
        <Typography color={status === 'error' ? 'error' : 'common'}>
          {content}
        </Typography>
        {status === 'progress' ? (
          <CircularProgress size={15} className={classes.circularProgress} />
        ) : (
          <Typography
            variant="caption"
            color={status === 'error' ? 'error' : 'primary'}
          >
            {status === 'error' ? 'Error' : 'Completado'}
          </Typography>
        )}
      </div>
    </>
  )
}

export default DynamicLoaderItem
