import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      width: '214px',
      height: '170px',
    },
  },
}))

const Header = ({ image, title }) => {
  const classes = useStyles()
  const imageAlt = title.toLowerCase().split(' ').join('_')

  return (
    <div className={classes.headerContainer}>
      <img src={image} alt={imageAlt} />
      <Typography variant="h5">{title}</Typography>
    </div>
  )
}

export default Header
