import { useContext } from 'react'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import ToolbarActions from 'components/UI/Table/Toolbar/ToolbarActions'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'
import ToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'

import { FreeTimeRequestContext } from './Table'
import { tabsData } from './helpers'

const NoveltiesRequestIndexToolbar = (props) => {
  const { freeActions, globalFilter, setGlobalFilter, ...restProps } = props
  const { filter, setFilter } = useContext(FreeTimeRequestContext)

  const handleChangeTab = (_, tab) => {
    setFilter(tab)
  }

  return (
    <>
      <Tabs
        value={filter}
        tabs={tabsData}
        backgroundColor="white"
        onChange={handleChangeTab}
      />
      <ToolbarContainer {...restProps}>
        <ToolbarSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {freeActions?.length > 0 ? (
          <ToolbarActions freeActions={freeActions} />
        ) : null}
      </ToolbarContainer>
    </>
  )
}

export default NoveltiesRequestIndexToolbar
