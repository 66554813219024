import { useContext } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import { formatNumber } from 'utils/format'

import OvertimeModal from '../Modals/Overtime/Overtime'
import useOvertimeModal from '../Modals/Overtime/useOvertimeModal'
import { PeriodContext } from '../../Payroll'

const CellOvertime = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const { showModal, handleCloseModal, handleOpenModal } = useOvertimeModal()

  return payroll.salary_category !== 'integral_salary' ? (
    <>
      {getPayrollConceptItemCell({
        buttonId: `overtime_${payroll.tableData.id}`,
        payrollConceptValue: payroll.overtime_hours,
        text: 'H.Ext/Recargos',
        formatter: formatNumber,
        onClick: handleOpenModal,
        editable: period.editable && !payroll.locked,
      })}
      {showModal ? (
        <OvertimeModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          payroll={payroll}
        />
      ) : null}
    </>
  ) : null
}

export default CellOvertime
