import FormField from 'components/UI/Formik/FormField/Index'

const CreditCardExpirationDate = () => {
  return (
    <FormField
      name="expiration_date"
      label="Fecha Vencimiento"
      variant="number"
      placeholder="MM/AAAA"
      format="##/####"
      isNumericString
      inputProps={{ autoComplete: 'cc-exp' }}
      style={{ fontFamily: 'Roboto Mono, monospace' }}
    />
  )
}

export default CreditCardExpirationDate
