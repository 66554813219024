import { makeStyles } from '@material-ui/core'

import { generateArrayOfYears, getMonths } from 'utils/dateTime'

import { dataFormatted } from '../ElectronicPayrollHistory/helpers'
import DateField from './DateField'

const useStyles = makeStyles((theme) => ({
  groupFields: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  field: {
    width: 216,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
    '& .MuiInputBase-root': {
      alignContent: 'center',
    },
  },
}))

const PayrollHistoryFilter = ({
  setSelectedMonth,
  selectedMonth,
  setSelectedYear,
  selectedYear,
  ...props
}) => {
  const classes = useStyles()
  const allMonths = getMonths()

  const onChangeMonth = (selectedValue) => {
    setSelectedMonth(selectedValue)
  }

  const onChangeYear = (selectedValue) => {
    setSelectedYear(selectedValue)
  }

  return (
    <div className={classes.groupFields}>
      <DateField
        id="month"
        className={classes.field}
        options={dataFormatted(allMonths)}
        value={selectedMonth}
        onChange={onChangeMonth}
        placeholder="Mes"
        {...props}
      />
      <DateField
        id="year"
        className={classes.field}
        options={generateArrayOfYears(13)}
        value={selectedYear}
        onChange={onChangeYear}
        placeholder="Año"
        {...props}
      />
    </div>
  )
}

export default PayrollHistoryFilter
