import { useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'

import { calculateVerificationDigit } from 'utils/company'
import { generateBasicNumberParser } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    gridGap: theme.spacing(1),
  },
}))

const CompanyIdentificationNumberField = ({
  disabled = false,
  label = 'Número de documento',
}) => {
  const form = useFormikContext()
  const classes = useStyles()

  const { values } = form

  return values.document_type === 'ni' ? (
    <div className={classes.root}>
      <IdentificationNumberField
        onChange={({ target: { value } }) => {
          const parser = generateBasicNumberParser({
            max: 9,
            convertToNumber: false,
          })

          const parsed = parser(value)

          if (values.id_number !== parsed) {
            form.setValues({
              ...values,
              id_number: parsed,
              verification_digit: calculateVerificationDigit(parsed),
            })
          }
        }}
        isCompanyId
        disabled={disabled}
        label={label}
      />
      <FormField
        name="verification_digit"
        label="DV"
        placeholder="DV"
        disabled
        readOnly
      />
    </div>
  ) : (
    <IdentificationNumberField isCompanyId disabled={disabled} label={label} />
  )
}

export default CompanyIdentificationNumberField
