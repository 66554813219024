import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MobileStepper from '@material-ui/core/MobileStepper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    '&.MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: 0,
    },
  },
  reverseActions: {
    flexFlow: 'column-reverse',
  },
  buttons: {
    display: 'flex',
    width: '100%',
  },
  buttonsRight: {
    justifyContent: 'flex-end',
  },
  buttonsCenter: {
    justifyContent: 'center',
  },
  buttonsLeft: {
    justifyContent: 'flex-start',
  },
  singleButton: {
    '& > button': {
      minWidth: 300,
    },
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(0.5),
  },
  stepsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  steps: {
    background: 'transparent',
  },
  stepsTop: {
    marginBottom: theme.spacing(2.5),
  },
  stepsBottom: {
    marginTop: theme.spacing(2.5),
  },
}))

const Modal = ({
  open = true,
  header,
  footer,
  hideFooter = false,
  children,
  onOk,
  okText = 'OK',
  disableOkButton = false,
  isLoading = false,
  loadingText = 'Cargando...',
  onCancel,
  onCloseModal = null, // Only when X button behaviour is different to cancel Button
  cancelText = 'Cancelar',
  hideCloseButton = false,
  hideCancelButton = false,
  hideOkButton = false,
  fullScreenBreakpoint = 'xs',
  alignButtons = 'right',
  dialogProps = {},
  steps = 0,
  activeStep = 1,
  stepsPosition = 'bottom',
  disableClickOutsideModal = false,
}) => {
  const classes = useStyles()

  const fullScreen = useMediaQuery((theme) =>
    theme.breakpoints.down(fullScreenBreakpoint)
  )

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      disableBackdropClick={disableClickOutsideModal ? true : isLoading}
      disableEscapeKeyDown={disableClickOutsideModal ? true : isLoading}
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      {...dialogProps}
    >
      {(onCancel || onCloseModal) && !hideCloseButton && (
        <ButtonBase
          onClick={onCloseModal || onCancel}
          className={classes.closeIcon}
          disabled={isLoading}
          data-cy="close-dialog-button"
        >
          <CloseIcon />
        </ButtonBase>
      )}
      {header && (
        <DialogTitle id="dialog-title" disableTypography>
          {typeof header === 'string' ? (
            <Typography variant="h5">{header}</Typography>
          ) : (
            header
          )}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      {!hideFooter && footer !== null && (
        <DialogActions
          className={clsx(classes.dialogActions, {
            [classes.reverseActions]: stepsPosition === 'bottom',
          })}
        >
          {footer || (
            <>
              {steps > 0 && (
                <div
                  className={clsx(classes.stepsContainer, {
                    [classes.stepsTop]: stepsPosition === 'top',
                    [classes.stepsBottom]: stepsPosition === 'bottom',
                  })}
                >
                  <MobileStepper
                    classes={{
                      root: classes.steps,
                    }}
                    steps={steps}
                    activeStep={activeStep - 1}
                    position="static"
                  />
                </div>
              )}
              <div
                className={clsx(classes.buttons, {
                  [classes.buttonsRight]: alignButtons === 'right',
                  [classes.buttonsCenter]: alignButtons === 'center',
                  [classes.buttonsLeft]: alignButtons === 'left',
                  [classes.singleButton]:
                    (hideCancelButton || hideOkButton) &&
                    alignButtons === 'center',
                })}
              >
                {!hideCancelButton && (
                  <Button
                    onClick={onCancel}
                    variant="outlined"
                    disabled={isLoading}
                    className={classes.cancelButton}
                    data-cy="modal_cancel_button"
                  >
                    {cancelText}
                  </Button>
                )}
                {!hideOkButton && (
                  <Button
                    onClick={onOk}
                    disabled={isLoading || disableOkButton}
                    startIcon={isLoading && <CircularProgress size={18} />}
                    data-cy="modal_ok_button"
                  >
                    {isLoading ? loadingText : okText}
                  </Button>
                )}
              </div>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

Modal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.node,
  hideFooter: PropTypes.bool,
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onCloseModal: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dialogProps: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideOkButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableOkButton: PropTypes.bool,
  fullScreenBreakpoint: PropTypes.string,
  alignButtons: PropTypes.string,
  steps: PropTypes.number,
  activeStep: PropTypes.number,
  stepsPosition: PropTypes.string,
  disableClickOutsideModal: PropTypes.bool,
}

Modal.defaultProps = {
  header: undefined,
  footer: undefined,
  hideFooter: false,
  open: true,
  onOk: undefined,
  onCancel: undefined,
  onCloseModal: null,
  okText: 'OK',
  cancelText: 'Cancelar',
  dialogProps: {},
  isLoading: false,
  loadingText: 'Cargando...',
  hideCloseButton: false,
  hideCancelButton: false,
  hideOkButton: false,
  disableOkButton: false,
  fullScreenBreakpoint: 'xs',
  alignButtons: 'right',
  steps: 0,
  activeStep: 1,
  stepsPosition: 'bottom',
  disableClickOutsideModal: false,
}

export default Modal
