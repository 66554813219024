import { wait } from 'utils/general'

const headwayChangelog = async (accountId) => {
  const config = {
    selector: '#changelog', // CSS selector where to inject the badge
    account: accountId,
    trigger: '#changelog',
    translations: {
      title: 'Útlimos cambios',
      readMore: 'Ver todas las notificaciones',
      footer: 'Ver todas las notificaciones 👉',
    },
    position: {
      x: 'left',
    },
  }

  await wait(1000)
  if (window.Headway) window.Headway.init(config)
}

const headwayScript = {
  src: 'https://cdn.headwayapp.co/widget.js',
  async: true,
  data: {
    name: 'nscript',
    value: 'afterInteractive',
  },
}

export default { headwayChangelog, headwayScript }
