import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import { makeStyles } from '@material-ui/core/styles'

import { formatDisplayDateString } from 'utils/dateTime'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
    fontSize: '1.25rem',
    marginLeft: 5,
    marginRight: 5,
  },
}))

const DashboardCostsInPercentagesPayrollRange = ({ costsInPercentages }) => {
  const classes = useStyles()
  const { initial_day: initialDay, end_day: endDay } = costsInPercentages

  return (
    <Box
      p={2}
      marginBottom={2}
      textAlign="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      component={Paper}
    >
      <Typography
        variant="subtitle1"
        display="inline"
        style={{ marginRight: 5 }}
      >
        Período:
      </Typography>
      <Typography style={{ fontSize: 20 }} display="inline">
        {formatDisplayDateString(initialDay)}
      </Typography>
      <TrendingFlatIcon className={classes.icon} />
      <Typography style={{ fontSize: 20 }} display="inline">
        {formatDisplayDateString(endDay)}
      </Typography>
      <DateRangeOutlinedIcon className={classes.icon} />
    </Box>
  )
}

export default DashboardCostsInPercentagesPayrollRange
