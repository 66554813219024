import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import EditButton from 'components/UI/Button/EditButton'

import Item from './Item'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 3, 4),
  },
  containerSubFields: {
    margin: `${theme.spacing(4)}px 0px`,
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  icon: {
    justifySelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifySelf: 'flex-start',
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[500],
  },
  label: {
    marginBottom: theme.spacing(3),
  },
}))

const WorkerSectionContainer = ({ item }) => {
  const {
    title,
    onClick,
    buttonCyLabel,
    itemsList,
    items,
    containerCyLabel,
    hiddenEdit,
    emptyState,
  } = item
  const { isWorker } = useUser()
  const classes = useStyles()

  return (
    <div className={classes.container} data-cy={containerCyLabel}>
      <div className={classes.title}>
        <Typography variant="h5">{title}</Typography>
        {!hiddenEdit && !isWorker ? (
          <EditButton
            onClick={onClick}
            className={classes.icon}
            data-cy={buttonCyLabel}
          />
        ) : null}
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={4}>
        {!!itemsList &&
          itemsList.length > 0 &&
          itemsList.map((field, index) => {
            if (!field.hidden) {
              const itemKey =
                field.label &&
                `${field?.label
                  .toString()
                  .toLowerCase()
                  .replace(/ /g, '_')}_${index}`
              return <Item item={field} key={itemKey} />
            }
            return null
          })}
      </Grid>
      {items?.length > 0 &&
        items.map((field) => {
          if (!field.hidden) {
            return (
              <div key={field.id} className={classes.containerSubFields}>
                <Typography variant="subtitle1" className={classes.label}>
                  {field.label}
                </Typography>
                <Grid container spacing={2}>
                  {field.itemsList.map((subField, index) => {
                    const itemKey =
                      subField.label &&
                      `${subField?.label
                        .toString()
                        .toLowerCase()
                        .replace(/ /g, '_')}_${index}`

                    return <Item item={subField} key={itemKey} />
                  })}
                </Grid>
              </div>
            )
          }
          return null
        })}
      {items?.length === 0 && emptyState ? emptyState : null}
    </div>
  )
}

export default WorkerSectionContainer
