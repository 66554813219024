import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { PeriodContext } from 'components/Period/Payroll/Payroll'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Modal from 'components/UI/Modal/Modal'

import { isObjectEmpty } from 'utils/general'
import useItemsService from 'utils/hooks/payroll/items'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import {
  deductionsRecurrentConcepts,
  generateEmptyPayrollItem,
} from 'utils/payroll'

import CreatePayrollConceptModal from '../CreatePayrollConceptModal'
import Info from '../common/Info'
import useContentStyles from '../common/commonStyles'
import DeductionsTab from './Tabs/DeductionsTab'
import LoansTab from './Tabs/LoansTabs'
import WithholdingTaxTab from './Tabs/WithholdingTaxTab'
import deductionDescription from './helpers'
import useDeductionsModal from './useDeductionsModal'

const useStyles = makeStyles({
  paper: {
    width: 650,
  },
})

const DeductionsModal = ({ payroll, handleCloseModal, showModal }) => {
  const { updatePeriodCallback } = useContext(PeriodContext)
  const queryClient = useQueryClient()
  const [initialData, setInitialData] = useState({})
  const [deductions, setDeductions] = useState([])
  const [concepts, setConcepts] = useState([])
  const [loanItems, setLoanItems] = useState([])
  const [deletedItems, setDeletedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [
    isCreatePayrollConceptModalOpen,
    setIsCreatePayrollConceptModalOpen,
  ] = useState(false)
  const [activeTab, setActiveTab] = useState('deductions')
  const { handleError } = useErrorHandler()
  const { getInitialData } = useDeductionsModal()
  const modalClasses = useStyles()
  const contentClasses = useContentStyles()
  const deductionsQueryKey = ['payrollConcepts', 'deductions', payroll.id]
  const deductionsItemsQueryKey = ['itemsByCategory', 'deductions', payroll.id]

  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: deductionsQueryKey,
      conceptsCategory: 'deductions',
    },
  })

  const { itemsQuery, itemsMutation } = useItemsService({
    serviceParams: {
      queryKey: deductionsItemsQueryKey,
      payrollId: payroll.id,
      conceptsCategory: 'deductions',
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: ({ data: itemsData }) => {
        const initData = getInitialData(
          payrollConceptsQuery.data,
          itemsData,
          payroll.contract_category
        )

        setInitialData(initData)
        setDeductions(initData.deductionItems)
        setConcepts(initData.deductionConcepts)
        setLoanItems(initData.loanItems)
      },
    },
  })

  const isGettingData = payrollConceptsQuery.isLoading || itemsQuery.isLoading

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const handleChangeLoanValue = (
    { currentTarget: { rawValue } },
    modifiedIndex
  ) => {
    const newLoanItems = loanItems.map((loan, index) => {
      return index === modifiedIndex ? { ...loan, value: rawValue || 0 } : loan
    })

    setLoanItems(newLoanItems)
  }

  const handleSelectItem = (payrollConceptId, index) => {
    const prevItem = deductions[index]

    let selectedConcept

    const newConcepts = concepts.map((concept) => {
      const conceptCopy = { ...concept }

      if (prevItem.payroll_concept_id === conceptCopy.id)
        conceptCopy.selected = false

      if (conceptCopy.id === payrollConceptId) {
        conceptCopy.selected = true

        selectedConcept = conceptCopy
      }

      return conceptCopy
    })

    const newDeductions = [...deductions]

    newDeductions[index] = {
      ...prevItem,
      name: selectedConcept.name,
      payroll_concept_id: selectedConcept.id,
      coded_name: selectedConcept.coded_name,
    }

    setConcepts(newConcepts)
    setDeductions(newDeductions)
  }

  const handleChangeItemValue = ({ target: { rawValue } }, index) => {
    const newDeductions = [...deductions]

    newDeductions[index] = { ...newDeductions[index], value: Number(rawValue) }

    setDeductions(newDeductions)
  }

  const handleDeleteItem = (index) => {
    const newDeductions = [...deductions]

    const itemToDelete = newDeductions[index]

    newDeductions.splice(index, 1)

    if (!deductionsRecurrentConcepts.includes(itemToDelete.coded_name)) {
      setDeletedItems([...deletedItems, itemToDelete])
    }

    if (itemToDelete.payroll_concept_id !== null) {
      const newConcepts = concepts.map((concept) => {
        const conceptCopy = { ...concept }

        if (itemToDelete.payroll_concept_id === conceptCopy.id)
          conceptCopy.selected = false

        return conceptCopy
      })
      setConcepts(newConcepts)
    }

    if (newDeductions.length === 0) {
      newDeductions.push(generateEmptyPayrollItem())
    }

    setDeductions(newDeductions)
  }

  const handleAddConcept = () => {
    const newDeductions = [...deductions]

    newDeductions.push(generateEmptyPayrollItem())

    setDeductions(newDeductions)
  }

  const handleCloseCreatePayrollConceptModal = (createdConcept) => {
    if (createdConcept) {
      const newConcepts = [...concepts, { ...createdConcept, selected: true }]

      setConcepts(newConcepts)

      const newDeductions = [
        ...deductions,
        generateEmptyPayrollItem(createdConcept.id, createdConcept.name),
      ]

      setDeductions(newDeductions)
    }

    setIsCreatePayrollConceptModalOpen(false)
  }

  const onCloseModal = () => {
    handleCloseModal()
    queryClient.removeQueries(deductionsQueryKey)
    queryClient.removeQueries(deductionsItemsQueryKey)
  }

  const handleSendData = async () => {
    setIsLoading(true)
    const dataToSend = [...deletedItems]

    const initialDeductions =
      initialData.deductionItems &&
      initialData.deductionItems.map((deduction) => ({
        ...deduction,
      }))

    // Compare initial deductions with current deductions to get the changed ones
    deductions.forEach((ded) => {
      if (ded.payroll_concept_id) {
        let dedChecked = false
        initialDeductions.some((initDed) => {
          if (
            ded.payroll_concept_id === initDed.payroll_concept_id ||
            ded.id === initDed.id
          ) {
            if (
              ded.value !== initDed.value ||
              ded.payroll_concept_id !== initDed.payroll_concept_id
            ) {
              dataToSend.push({
                ...ded,
                id: initDed.id,
              })
            }
            // Have to disable this line because this key is needed out of loop
            // eslint-disable-next-line no-param-reassign
            initDed.checked = true
            dedChecked = true

            return true
          }
          return false
        })

        let addToDataSend

        if (deductionsRecurrentConcepts.includes(ded.coded_name)) {
          addToDataSend = !dedChecked && ded.payroll_concept_id && ded.value > 0
        } else {
          addToDataSend = !dedChecked && ded.payroll_concept_id
        }

        if (addToDataSend) {
          dataToSend.push(ded)
        }
      }
    })

    // Those not checked are added to deletion
    initialDeductions?.forEach((initDed) => {
      if (!initDed.checked && initDed.payroll_concept_id) {
        dataToSend.push({
          id: initDed.id,
          payroll_concept_id: initDed.payroll_concept_id,
          value: 0,
        })
      }
    })

    for (let i = 0; i < loanItems.length; i += 1) {
      const initLoan = initialData.loanItems[i]
      const loan = loanItems[i]

      if (initLoan.value !== loan.value) dataToSend.push(loan)
    }

    if (dataToSend.length > 0) {
      await itemsMutation.mutateAsync(
        {
          mutationMethod: 'PUT',
          payrollId: payroll.id,
          deductionItem: dataToSend,
        },
        {
          onSuccess: ({ data }) => {
            updatePeriodCallback(data)
            trackEvent(integrationEvent.PAYROLL_ADD_LOANS_AND_DEDUCTIONS)
            setIsLoading(false)
            onCloseModal()
          },
          onError: (error) => {
            handleError(error)
            setIsLoading(false)
          },
        }
      )
    } else {
      setIsLoading(false)
      onCloseModal()
    }
  }

  const withholdingTaxItemIndex = deductions?.findIndex(
    (deduction) => deduction.coded_name === 'withholding_tax'
  )
  const withholdingTaxItem = deductions[withholdingTaxItemIndex]

  const withholdingTaxInfo = { withholdingTaxItemIndex, withholdingTaxItem }

  const tabs = [
    {
      key: 'deductions',
      label: (
        <Box display="flex" alignItems="center">
          Deducciones
        </Box>
      ),
      cyData: 'deductions-tab',
    },
    {
      key: 'loans',
      label: (
        <Box display="flex" alignItems="center">
          Préstamos
        </Box>
      ),
      cyData: 'loans-tab',
    },
    {
      key: 'withholding_tax',
      label: (
        <Box display="flex" alignItems="center">
          Retefuente
        </Box>
      ),
      hidden: !withholdingTaxItem,
      cyData: 'withholding_tax-tab',
    },
  ]

  return (
    <Modal
      header="Deducciones"
      onOk={handleSendData}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isLoading}
      dialogProps={{ scroll: 'body', maxWidth: false, classes: modalClasses }}
      open={showModal}
    >
      <Paper>
        <Tabs value={activeTab} onChange={handleChangeTab} tabs={tabs} />
        <div className={contentClasses.root}>
          <Typography paragraph>{deductionDescription[activeTab]}</Typography>
          {!isGettingData && !isObjectEmpty(initialData) ? (
            <>
              {activeTab === 'deductions' && (
                <DeductionsTab
                  activeTab={activeTab}
                  withholdingTaxInfo={withholdingTaxInfo}
                  tabData={{ deductions, concepts }}
                  handleSelectItem={handleSelectItem}
                  handleChangeItemValue={handleChangeItemValue}
                  handleDeleteItem={handleDeleteItem}
                  handleAddConcept={handleAddConcept}
                  setIsCreatePayrollConceptModalOpen={
                    setIsCreatePayrollConceptModalOpen
                  }
                />
              )}
              {activeTab === 'loans' && (
                <LoansTab
                  loanItems={loanItems}
                  handleChangeLoanValue={handleChangeLoanValue}
                />
              )}
              {activeTab === 'withholding_tax' && (
                <WithholdingTaxTab
                  handleChangeItemValue={handleChangeItemValue}
                  withholdingTaxInfo={withholdingTaxInfo}
                  payrollId={payroll.id}
                />
              )}
            </>
          ) : (
            <LoadingBox />
          )}
        </div>
      </Paper>
      <br />
      {isCreatePayrollConceptModalOpen && (
        <CreatePayrollConceptModal
          category="deductions"
          handleClose={handleCloseCreatePayrollConceptModal}
        />
      )}

      <Info
        hideTooltipInfoIcon={activeTab === 'withholding_tax'}
        startText={
          activeTab === 'withholding_tax' ? (
            <>
              Si quieres <strong>activar</strong> el cálculo automático de la
              retención en la fuente y <strong>editar</strong> los conceptos
              relacionados con este valor
            </>
          ) : undefined
        }
      />
    </Modal>
  )
}

export default DeductionsModal
