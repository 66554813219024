import { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import { DASHBOARD } from 'config/routes'

import AddAccountantModal from '../AddAccountantInfo/Modal/AddAccountant'
import useAddAccountantModal from '../AddAccountantInfo/Modal/useAddAccountantModal'
import MessageBubble from '../Atoms/MessageBubble'
import StepCard from './StepCard'
import { getFirstSteps, getMessage } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4.5),
  },
  paper: {
    maxWidth: 570,
    width: '100%',
    padding: theme.spacing(2.5),
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
  },
  bubble: {
    '& > p': {
      color: theme.palette.common.white,
    },
  },
}))

const FirstSteps = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { company, user } = useUser()
  const { onboarding_first_steps: onboardingFirstSteps = {} } = company
  const { newOnboarding } = useFeatureFlags({
    flags: ['newOnboarding'],
    trackingMode: 'attributes',
    attributes: {
      companyId: getCompanyId(),
    },
  })
  const {
    addAccountantModalState,
    openAddAccountantModal,
    closeAddAccountantModal,
  } = useAddAccountantModal()
  const steps = useMemo(
    () => getFirstSteps(onboardingFirstSteps, newOnboarding?.value),
    [newOnboarding.value, onboardingFirstSteps]
  )
  const progressValue = useMemo(
    () =>
      (steps.filter((step) => step.status === 'completed')?.length /
        steps.length) *
      100,
    [steps]
  )
  const activeStep = steps.find((step) => step.status === 'active')
  const remainingSteps = steps.filter((step) =>
    ['pending', 'active'].includes(step.status)
  ).length

  if (onboardingFirstSteps?.finished_all_steps) {
    return <Redirect to={DASHBOARD} />
  }

  return (
    <Page documentTitle="Primeros pasos">
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <MessageBubble
            message={getMessage(
              user.name || '',
              remainingSteps,
              activeStep?.id || null
            )}
            progressValue={progressValue}
            backgroundColor={theme.palette.primary.main}
            bubbleClassName={classes.bubble}
          />
          {steps.map((step) => (
            <StepCard
              key={step.id}
              number={step.number}
              title={step.title}
              description={step.description}
              status={step.status}
              path={step.path}
              disabled={['completed', 'pending'].includes(step.status)}
              onClick={openAddAccountantModal}
            />
          ))}
          <AddAccountantModal
            state={addAccountantModalState}
            handleClose={closeAddAccountantModal}
          />
        </Paper>
      </div>
    </Page>
  )
}

export default FirstSteps
