import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import eugenioFace from 'assets/images/avatars/eugenio/eugenio_face.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    columnGap: theme.spacing(1),
    justifyItems: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  circularProgress: {
    width: '100px !important',
    height: '100px !important',
    position: 'absolute',
    transform: 'rotate(270deg) !important',
    color: theme.palette.success.main,
  },
  circularProgressSvg: {
    strokeWidth: 2,
  },
  avatarContainer: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  avatar: {
    width: 70,
    height: 70,
  },
  avatarLarge: {
    width: 90,
    height: 90,
  },
  messageContainer: {
    width: '100%',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: theme.palette.common.black,
    borderRadius: theme.spacing(2),
    position: 'relative',
    padding: theme.spacing(2),
  },
  arrow: {
    width: 25,
    height: 25,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    transform: 'rotate(45deg)',
    marginRight: theme.spacing(-1.75),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-1.75),
      marginRight: 0,
    },
  },
  bubbleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const MessageBubble = ({
  message,
  progressValue,
  bubbleClassName,
  backgroundColor,
}) => {
  const theme = useTheme()
  const classes = useStyles({
    backgroundColor: backgroundColor || theme.palette.grey[100],
  })
  const showProgress = progressValue !== undefined && progressValue >= 0

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        <Avatar
          src={eugenioFace}
          alt="Cara de Eugenio primeros pasos"
          className={clsx(classes.avatar, {
            [classes.avatarLarge]: !showProgress,
          })}
        />
        {showProgress ? (
          <CircularProgress
            className={classes.circularProgress}
            variant="static"
            value={progressValue}
            classes={{
              circle: classes.circularProgressSvg,
            }}
          />
        ) : null}
      </div>
      <div className={classes.bubbleContainer}>
        <div className={classes.arrow} />
        <div className={clsx(classes.messageContainer, bubbleClassName)}>
          <Typography variant="body1">{message}</Typography>
        </div>
      </div>
    </div>
  )
}

export default MessageBubble
