import { Fragment } from 'react'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

const actionsStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(),
  },
  tooltipSpan: {
    display: 'inline-flex',
  },
}))

const ToolbarActions = ({ freeActions }) => {
  const classes = actionsStyles()
  return (
    <div className={classes.container}>
      {freeActions.map((action) => {
        if (!action.hidden && action.Component) {
          return <Fragment key={action.id}>{action.Component}</Fragment>
        }

        return (
          !action.hidden && (
            <Tooltip title={action.tooltipTitle || ''} key={action.id}>
              <span className={classes.tooltipSpan}>
                <Button
                  onClick={action.onClick}
                  startIcon={action.icon ? <action.icon /> : undefined}
                  variant={action.buttonVariant}
                  disabled={action.disabled}
                  {...action.buttonProps}
                >
                  {action.tooltip}
                </Button>
              </span>
            </Tooltip>
          )
        )
      })}
    </div>
  )
}

export default ToolbarActions
