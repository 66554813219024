import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Emoji from 'components/UI/Emoji'
import Table from 'components/UI/Table/Table'

import { wrench } from 'utils/emojis'
import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'
import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useNotifications from 'utils/hooks/useNotifications'

import { colors } from 'styles/colors'

import ModalErrorDetail from '../ModalErrorDetails'
import ToolbarElectronicPayroll from './Toolbar'
import { getActionsData, getColumnsData } from './helpers'

const useStyles = makeStyles((theme) => ({
  chip: {
    width: 'fit-content',
  },
  issuedChip: {
    backgroundColor: theme.palette.success.light,
    '& .MuiChip-label': {
      color: theme.palette.success.dark,
    },
  },
  rejectedChip: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    '& .MuiChip-label': {
      color: 'rgba(255, 0, 0, 1)',
    },
  },
  processingChip: {
    backgroundColor: theme.palette.info.light,
    '& .MuiChip-label': {
      color: theme.palette.info.dark,
    },
  },
  pendingChip: {
    backgroundColor: theme.palette.grey[400],
    '& .MuiChip-label': {
      color: theme.palette.grey[900],
    },
  },
  modifiedChip: {
    backgroundColor: theme.palette.warning.light,
    '& .MuiChip-label': {
      color: theme.palette.warning.dark,
    },
  },
  canceledChip: {
    backgroundColor: theme.palette.complementary.lightOrange,
    '& .MuiChip-label': {
      color: theme.palette.complementary.orange,
    },
  },
}))

const TableElectronicPayrollPeriod = ({
  electronicPayrollsData,
  setOpenModalToSend,
  setElectronicPayrolls,
  setResetForm,
  isLoadingSend,
}) => {
  const [electronicPayrollFormatted, setElectronicPayrollFormatted] = useState(
    null
  )
  const [openModalRejected, setOpenModalRejected] = useState(false)
  const [errors, setErrors] = useState(null)

  const downloadFile = useEnqueueDownload()
  const { showSuccessMessage } = useNotifications()

  const classes = useStyles()

  const columnsData = useMemo(() => getColumnsData(classes), [classes])

  const { downloadFileMutation } = useElectronicPayrollFileService()
  const { maintenance, maintenanceDate } = useDianMaintenance()

  const allPayrollIssued =
    electronicPayrollsData?.electronic_payrolls_counter?.issued ===
    electronicPayrollsData?.electronic_payrolls_counter?.total

  const transmissionInRange =
    electronicPayrollsData?.options?.transmission_in_range

  const contentDialog = () => {
    const dialogIssued =
      'La nómina electrónica de este periodo ya fue emitida exitosamente.'
    const dialogToIssued =
      'Estás dentro del plazo de emisión. Emite Nómina Electrónica antes del décimo día hábil para evitar multas.'
    const dialogActive =
      'El plazo oficial para emitir nómina electrónica ya pasó. Emite ahora para evitar mayores sanciones.'
    const dianMaintenance = (
      <>
        <Emoji code={wrench} />
        Conexión estará deshabilitada temporalmente por mantenimiento en la DIAN
        entre {maintenanceDate}
      </>
    )

    if (!transmissionInRange && !allPayrollIssued && !maintenance) {
      return dialogActive
    }
    if (allPayrollIssued && !maintenance) {
      return dialogIssued
    }
    if (maintenance) {
      return dianMaintenance
    }
    return dialogToIssued
  }

  useEffect(() => {
    const dataFormatted = [...electronicPayrollsData?.electronic_payrolls]

    dataFormatted.forEach((electronicPayroll, index) => {
      if (!electronicPayroll?.options?.to_issue) {
        dataFormatted[index].disableCheck = true
      }
    })

    setElectronicPayrollFormatted(dataFormatted)
  }, [electronicPayrollsData])

  const getCustomRowStyle = ({ options }) => {
    const { to_issue: toIssue } = options

    if (!toIssue) {
      return {
        backgroundColor: colors.nominapp.GREY[100],
      }
    }
    return {}
  }

  const handleDownloadFile = (electronicPayroll, fileType = 'xml') => {
    downloadFileMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'payslipsElectronicPayroll',
        electronicPayrollId: electronicPayroll.id,
        fileType,
      },
      {
        onSuccess: () => {
          // Only for test the download file in cypress fake response
          if (window.Cypress) {
            return showSuccessMessage(
              'Tu Resumen de comprobantes está listo para descargar'
            )
          }

          return downloadFile({
            name: `Comprobante de nómina electrónica para ${electronicPayroll.worker.name}`,
            fileCode: 'electronic_payroll',
            pathname: `electronic_payrolls/${electronicPayroll.id}/payslip`,
          })
        },
      }
    )
  }

  const handleSendElectronicPayroll = (electronicPayrollData) => {
    setElectronicPayrolls(electronicPayrollData)
    setOpenModalToSend(true)
  }

  const handleSubmit = (currentValues, form) => {
    const { electronicPayrollFormatted: electronicPayrollsSend } = currentValues
    setElectronicPayrolls(electronicPayrollsSend)
    setOpenModalToSend(true)

    setResetForm(form.resetForm)
  }

  const handleShowErrors = (errorsData) => {
    setErrors(errorsData)
    setOpenModalRejected(true)
  }

  const handleCloseShowErrors = () => setOpenModalRejected(false)

  const actionsData = getActionsData(
    handleSendElectronicPayroll,
    handleDownloadFile,
    handleShowErrors,
    maintenance
  )

  const disabledButtonSubmit = isLoadingSend || allPayrollIssued || maintenance
  const tooltipTitle = contentDialog()
  const Toolbar = ToolbarElectronicPayroll(disabledButtonSubmit, tooltipTitle)

  return electronicPayrollFormatted ? (
    <>
      <Formik
        initialValues={{ electronicPayrollFormatted }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(form) => {
          const { values } = form

          return (
            <Form>
              <Table
                columns={columnsData}
                data={values.electronicPayrollFormatted}
                actions={actionsData}
                components={{
                  Toolbar,
                }}
                options={{
                  selection: true,
                  selectionId: 'disableCheck',
                  rowStyle: getCustomRowStyle,
                  hiddenColumns: ['worker.id_number'],
                }}
              />
            </Form>
          )
        }}
      </Formik>
      {openModalRejected ? (
        <ModalErrorDetail closeModal={handleCloseShowErrors} errors={errors} />
      ) : null}
    </>
  ) : null
}

export default TableElectronicPayrollPeriod
