import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import PaperForm from 'components/UI/Form/PaperForm'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useNotifications from 'utils/hooks/useNotifications'

import { SETTINGS } from 'config/routes'

import ElectronicPayrollAdvanced from './Steps/Advanced'
import CompanyInformation from './Steps/CompanyInformation'
import DianActivation from './Steps/DianActivation'
import DianConfirmation from './Steps/DianConfirmation'
import ElectronicFileInformation from './Steps/ElectronicFileInformation'
import {
  compareFinalValues,
  configurationSteps,
  getConfigurationInitialValues,
  getValidationSchema,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(4),
    justifyContent: 'end',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      gridAutoFlow: 'row',
      justifyContent: 'center',
      '& button:nth-of-type(1)': {
        order: 2,
      },
      '& button:nth-of-type(2)': {
        order: 1,
        marginLeft: 0,
      },
    },
  },
}))

const ConfigurationProcess = ({
  closeForm,
  comeFromEdit,
  shouldShowSuccessBanner,
  dispatch,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()

  const { company, refreshCompany } = useUser()
  const { showSuccessMessage } = useNotifications()
  const electronicPayrollConfigurationCache = queryClient.getQueryData([
    'electronicPayrollStatus',
    companyId,
  ])
  const initialValues = getConfigurationInitialValues(
    company,
    electronicPayrollConfigurationCache?.data || {}
  )

  const isAuthorized =
    electronicPayrollConfigurationCache?.data.government_enabled_status ===
    'authorized'

  const isActive = electronicPayrollConfigurationCache?.data.active
  const [currentStep, setCurrentStep] = useState(() =>
    isAuthorized && isActive ? 4 : 0
  )

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const {
    electronicPayrollConfigurationMutation,
  } = useElectronicPayrollConfigurationService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handlePreviousStep = () => setCurrentStep(currentStep - 1)

  const handleSubmit = async (currentValues) => {
    if (!compareFinalValues(currentStep, currentValues, initialValues)) {
      if (currentStep === 0) {
        companyMutation.mutate(
          {
            mutationMethod: 'PATCH',
            company: {
              address: currentValues.address,
              document_type: currentValues.document_type,
              verification_digit: currentValues.verification_digit,
              id_number: currentValues.id_number,
              city_id: currentValues.city.id,
              name: currentValues.name,
              government_email: currentValues.government_email,
            },
          },
          {
            onSuccess: () => {
              if (!isActive) {
                electronicPayrollConfigurationMutation.mutateAsync(
                  {
                    mutationMethod: 'PATCH',
                    configuration: {
                      active: true,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries([
                        'electronicPayrollStatus',
                        companyId,
                      ])
                      dispatch({ type: 'first_config_attempt' })
                      refreshCompany()
                      handleNextStep()
                    },
                  }
                )
              } else {
                refreshCompany()
                handleNextStep()
              }
            },
          }
        )
      } else if (currentStep === 1) {
        // window.Cypress is for E2E testing purposes
        if (isAuthorized || window.Cypress) {
          handleNextStep()
        }
      } else if (currentStep === 3) {
        electronicPayrollConfigurationMutation.mutate(
          {
            mutationMethod: 'PATCH',
            configuration: {
              company_certificate: currentValues.company_certificate,
              ...(currentValues.company_certificate
                ? {
                    certificate_file: currentValues.certificate_file.replace(
                      'data:application/x-pkcs12;base64,',
                      ''
                    ),
                    certificate_file_name: currentValues.certificate_file_name,
                    certificate_password: btoa(
                      currentValues.certificate_password
                    ),
                  }
                : {}),
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])
              handleNextStep()
            },
          }
        )
      } else if (currentStep === 4) {
        electronicPayrollConfigurationMutation.mutate(
          {
            mutationMethod: 'PATCH',
            configuration: {
              social_benefits: currentValues.social_benefits,
              holidays: currentValues.holidays,
              consecutive_number: Number(currentValues.consecutive_number),
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])
              showSuccessMessage(
                'La configuración ha sido actualizada correctamente.'
              )

              if (shouldShowSuccessBanner) {
                closeForm()
              } else {
                history.push(SETTINGS())
              }
            },
          }
        )
      } else {
        if (shouldShowSuccessBanner) {
          closeForm()
          return
        }
        history.push(SETTINGS())
      }
    } else {
      if (currentStep === 4) {
        if (shouldShowSuccessBanner) {
          closeForm()
          return
        }
        history.push(SETTINGS())
      }
      handleNextStep()
    }
  }

  const onChangeStep = (index) => setCurrentStep(index)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(currentStep)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit: onSubmitForm }) => {
        return (
          <Form>
            <PaperForm
              steps={{
                stepsData: configurationSteps,
                currentStep,
                progressStep: currentStep,
                onChangeStep,
              }}
            >
              {currentStep === 0 ? <CompanyInformation /> : null}
              {currentStep === 1 ? (
                <DianActivation isEditting={comeFromEdit} />
              ) : null}
              {currentStep === 2 ? <DianConfirmation /> : null}
              {currentStep === 3 ? <ElectronicFileInformation /> : null}
              {currentStep === 4 ? <ElectronicPayrollAdvanced /> : null}
              <div className={classes.actionsContainer}>
                <Button
                  onClick={handlePreviousStep}
                  variant="outlined"
                  disabled={currentStep === 0}
                >
                  Volver al paso anterior
                </Button>
                <Button
                  onClick={onSubmitForm}
                  loading={
                    companyMutation.isLoading ||
                    electronicPayrollConfigurationMutation.isLoading
                  }
                  // !window.Cypress is for E2E testing purposes
                  disabled={
                    currentStep === 1 && !isAuthorized && !window.Cypress
                  }
                >
                  {currentStep === 4 ? 'Guardar' : 'Continuar'}
                </Button>
              </div>
            </PaperForm>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ConfigurationProcess
