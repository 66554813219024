import { useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getInstitutionsByCategory } from 'services/institutionService'

import useErrorHandler from './useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, category } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getInstitutionsByCategory')
    return getInstitutionsByCategory(category)

  return null
}

const useInstitutionsService = ({
  serviceParams = { queryKey: 'getInstitutionsByCategory' },
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restInstitutionsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    institutionsQuery: {
      data,
      ...restInstitutionsResponse,
    },
  }
}

export default useInstitutionsService
