import { parseISO } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'

import http, { getApiURL } from '../httpService'

export function formatSocialBenefits(socialBenefits) {
  const {
    social_benefit_period: socialBenefitPeriod = {},
    social_benefits: socialBenefitsArray = [],
  } = cloneDeep(socialBenefits)

  if (socialBenefitPeriod.initial_day)
    socialBenefitPeriod.initial_day = parseISO(socialBenefitPeriod.initial_day)
  if (socialBenefitPeriod.end_day)
    socialBenefitPeriod.end_day = parseISO(socialBenefitPeriod.end_day)
  if (socialBenefitPeriod.payment_limit_day)
    socialBenefitPeriod.payment_limit_day = parseISO(
      socialBenefitPeriod.payment_limit_day
    )
  if (socialBenefitPeriod.period_initial_day)
    socialBenefitPeriod.period_initial_day = parseISO(
      socialBenefitPeriod.period_initial_day
    )
  if (socialBenefitPeriod.period_end_day)
    socialBenefitPeriod.period_end_day = parseISO(
      socialBenefitPeriod.period_end_day
    )

  socialBenefitsArray.forEach((sb) => {
    const sbCopy = sb
    sbCopy.initial_item_value = sb.item_value

    // this is required to do when the api returns item_value with a value
    sbCopy.paid_value -= sb.item_value
  })

  return {
    social_benefit_period: socialBenefitPeriod,
    social_benefits: socialBenefitsArray,
  }
}

export const getAll = async (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  const { data } = await http.get(url)

  return formatSocialBenefits(data)
}

export const getSocialBenefits = (periodId, socialBenefitsPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitsPeriodId}`,
  })

  return http.getV2(url)
}

export const put = async (periodId, socialBenefitPeriodId, socialBenefits) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  const dataReq = { social_benefits: socialBenefits }

  const { data } = await http.put(url, dataReq)

  const formattedSocialBenefits = formatSocialBenefits(data)

  return formattedSocialBenefits
}

export const updateSocialBenefits = (
  periodId,
  socialBenefitPeriodId,
  socialBenefits
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  const data = { social_benefits: socialBenefits }

  return http.putV2(url, data)
}

const deleteSocialBenefit = async (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  const response = await http.delete(url)
  return response
}

export { deleteSocialBenefit as delete }

export const deleteSocialBenefits = (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  return http.deleteV2(url)
}

export const recalculate = async (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_period_actions/${socialBenefitPeriodId}`,
  })

  const { data } = await http.put(url)

  const formattedSocialBenefits = formatSocialBenefits(data)

  return formattedSocialBenefits
}

export const recalculateAllSocialBenefits = (
  periodId,
  socialBenefitPeriodId
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_period_actions/${socialBenefitPeriodId}`,
  })

  return http.putV2(url)
}

export const getFile = async (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefits_file`,
    searchParams: { social_benefit_period_id: socialBenefitPeriodId },
  })

  const response = await http.get(url)
  return response
}

export const getSocialBenefitsFile = (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefits_file`,
    searchParams: { social_benefit_period_id: socialBenefitPeriodId },
  })

  return http.getV2(url)
}

export const recalculateWorkerSocialBenefits = (socialBenefitId) => {
  const url = getApiURL({
    pathname: `/social_benefit_actions/${socialBenefitId}`,
  })

  return http.putV2(url)
}

export default {
  getAll,
  put,
  delete: deleteSocialBenefit,
  recalculate,
  getFile,
  getSocialBenefits,
  recalculateWorkerSocialBenefits,
  getSocialBenefitsFile,
  deleteSocialBenefits,
  updateSocialBenefits,
  recalculateAllSocialBenefits,
}
