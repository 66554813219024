import { useState, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core/styles'

import PaymentContext from 'components/Subscription/Atoms/PaymentContext'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import ExtraWorkerModal from './Plans/ExtraWorkersModal'
import SubscriptionHeader from '../../Atoms/Header'
import Plans from './Plans/Plans'
import Payment from './Payment/Payment'
import PaymentSummary from './Summary/PaymentSummary'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 345px',
      gridTemplateRows: 'auto',
    },
  },
}))

const Paywall = ({ location }) => {
  const queryClient = useQueryClient()
  const [step, setStep] = useState(1)
  const [paymentData, setPaymentData] = useState({})
  const [selectedPlan, setSelectedPlan] = useState({})
  const [submitForm, setSubmitForm] = useState(false)
  const [isExtraWorkerModalOpen, setIsExtraWorkerModalOpen] = useState(false)
  const frequentQuestionsRef = useRef(null)
  const classes = useStyles()
  const companyId = getCompanyId()
  const subscriptionCache =
    queryClient.getQueryData(['getSubscription', companyId])?.data || {}
  const companyCache =
    queryClient.getQueryData(['companyInformation', companyId])?.data || {}
  const isPartner = subscriptionCache?.partner
  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
    queryOptions: {
      onSuccess: ({ data: { plans = [] } = {} }) => {
        const plansData = {
          plans: [
            ...plans,
            {
              ...plans[plans.length - 1],
              name: 'Nominapp Plan Empresarial Plus',
              personalized: true,
            },
          ],
        }
        const initialPlan = location?.state?.plan
        const initialType = plansData.plans[0]?.subscription_value?.discounts
          ?.first_payment
          ? 'year'
          : (location.state && location.state.type) ||
            subscriptionCache?.type ||
            'year'
        let selectedPlanCheck = {}

        if (isPartner) {
          selectedPlanCheck = {
            ...subscriptionCache?.plan,
            monthly_value: subscriptionCache?.month_value,
          }
        } else {
          selectedPlanCheck =
            plans.find((plan) =>
              initialPlan ? plan.id === initialPlan : plan.selected
            ) ||
            plans[0] ||
            {}
        }

        if (!isObjectEmpty(selectedPlanCheck))
          selectedPlanCheck.payment_type = initialType

        if (subscriptionCache?.payrolls_size > 80) {
          selectedPlanCheck = {
            ...selectedPlanCheck,
            ...plansData.plans.find(
              (plan) => plan.name === 'Nominapp Plan Empresarial Plus'
            ),
            paid_extra_workers: subscriptionCache?.payrolls_size - 80,
          }
        }
        setSelectedPlan(selectedPlanCheck)
        setPaymentData(plansData)
      },
    },
  })

  useEffect(() => {
    if (isPartner) {
      setStep(2)
    }
  }, [isPartner])

  const isAnually = selectedPlan.payment_type === 'year'

  return (
    <PaymentContext.Provider
      value={{
        step,
        paymentData,
        selectedPlan,
        subscription: subscriptionCache,
        company: companyCache,
        isAnually,
        submitForm,
        setStep,
        setSubmitForm,
        setSelectedPlan,
        setIsExtraWorkerModalOpen,
      }}
    >
      <div className={classes.container}>
        <Page
          documentTitle="Suscripción"
          header={
            step === 1 ? (
              <SubscriptionHeader frequentQuestionsRef={frequentQuestionsRef} />
            ) : (
              'Suscripción'
            )
          }
          isLoading={plansQuery.isLoading}
          className={classes.page}
        >
          {step === 1 ? (
            <Plans forwardedRef={frequentQuestionsRef} />
          ) : (
            <Payment onChangePlan={setStep} />
          )}
        </Page>
        <PaymentSummary paySubscription={step !== 1} />
      </div>

      {isExtraWorkerModalOpen && <ExtraWorkerModal />}
    </PaymentContext.Provider>
  )
}

export default Paywall
