import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    position: 'absolute',
    right: theme.spacing(0),
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    backgroundColor: theme.palette.primary.lighter,
    placeContent: 'center',

    '& > button': {
      padding: theme.spacing(0),
      height: 'fit-content',
      '&:focus': {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
  },
}))

const ActionStatus = ({ handleDetail }) => {
  const classes = useStyles()

  return (
    <CardActions className={classes.actionContainer}>
      <Button variant="text" onClick={handleDetail}>
        Ver detalle{' '}
      </Button>
    </CardActions>
  )
}

export default ActionStatus
