import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { useCompanyService } from 'utils/hooks/company/companyService'

const useOnboardingMutation = (handleNextStep) => {
  const queryClient = useQueryClient()

  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })

  const updateRoleStep = useCallback(
    (values, callback) =>
      companyMutation.mutate(
        {
          mutationMethod: 'PATCH',
          company: values,
        },
        {
          onSuccess: () => callback(),
        }
      ),
    [companyMutation]
  )

  const onSuccessMutation = () => {
    queryClient.invalidateQueries('companyInformation')
    handleNextStep()
  }

  return {
    updateRoleStep,
    onSuccessMutation,
    loading: companyMutation.isLoading,
  }
}

export default useOnboardingMutation
