export default {
  BLACK: '#000000',
  WHITE: '#ffffff',
  PRIMARY: {
    900: '#00007B',
    700: '#3D17C4',
    600: '#5625EB',
    500: '#622CFF',
    400: '#7C3EFF',
    200: '#F6F2FF',
  },
  SECONDARY: {
    900: '#32200A',
    700: '#9B891D',
    500: '#FFE35A',
    400: '#FFE76C',
    200: '#FFFFE3',
  },
  GREY: {
    900: '#070708',
    700: '#29282B',
    500: '#717078',
    400: '#C8C6D2',
    200: '#E9E6F0',
    100: '#F8F7FA',
  },
  INFO: {
    700: '#274E62',
    500: '#68CBFF',
    300: '#CEEEFF',
  },
  SUCCESS: {
    700: '#3D5B3F',
    500: '#8AE493',
    300: '#F2FAF3',
  },
  ERROR: {
    700: '#603232',
    500: '#FF8D8D',
    300: '#FFF0F0',
  },
  WARNING: {
    700: '#3D3B21',
    500: '#FAF699',
    300: '#FFFDD0',
  },
  COMPLEMENTARY: {
    yellow: '#FFE875', // secondary 500
    darkOrange: '#600000',
    orange: '#FE8E43',
    lightOrange: '#FDBA62',
    darkBlue: '#00367D',
    blue: '#00A7FF',
    lightBlue: '#75CCFF',
    grey: '#F2EDFF',
    strongOrange: '#ED670C',
    strongBlue: '#232B50',
  },
}
