import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dataItemContainer: {
    display: 'flex',
  },
  dataItemLabel: {
    marginLeft: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))

const RequiredDataItem = ({ label }) => {
  const classes = useStyles()

  return (
    <div className={classes.dataItemContainer}>
      <CheckCircleIcon color="primary" />
      <Typography variant="body1" className={classes.dataItemLabel}>
        {label}
      </Typography>
    </div>
  )
}

export default RequiredDataItem
