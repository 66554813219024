import { useContext } from 'react'

import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import TableToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'

import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useDecree376Service from 'utils/hooks/payroll/decree376Service'

import { Decree376Context } from '../Index'

const ToolbarSearch = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const downloadFile = useEnqueueDownload()
  const { decree376Mutation } = useDecree376Service({
    queryOptions: { enabled: false },
  })
  const { periodId, decree376Id } = useContext(Decree376Context)

  const featureState = usePremiumFeature('payrollPayment')

  const handleDownloadSummaryFile = async () => {
    if (!featureState.isPremiumFeature) {
      decree376Mutation.mutate(
        {
          mutationMethod: 'GET',
          mutationKey: 'getDecree376File',
          params: { periodId, decreePeriodId: decree376Id },
        },
        {
          onSuccess: () => {
            return downloadFile({
              name: 'resumen de pago decreto 376',
              fileCode: 'decree_summary',
              pathname: `periods/${periodId}/decree376/payments_file/${decree376Id}`,
            })
          },
        }
      )
    } else featureState.openModal()
  }

  const serachToolbarActions = [
    {
      id: 'downloadSummary',
      icon: GetAppOutlinedIcon,
      tooltip: 'Descargar Resumen',
      tooltipTitle:
        'Presiona este botón para descargar un resumen en Excel del cálculo de esta prestación social, los valores pagados y por pagar para cada empleado.',
      isFreeAction: true,
      onClick: () => handleDownloadSummaryFile(),
      buttonVariant: 'outlined',
      position: 'left',
      buttonProps: {
        endIcon: (
          <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
        ),
      },
    },
  ]
  return (
    <TableToolbarSearch
      preGlobalFilteredRows={preGlobalFilteredRows}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
      freeActions={serachToolbarActions}
    />
  )
}

export default ToolbarSearch
