import { useContext, useMemo } from 'react'

import { useTheme } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'
import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import { PeriodContext } from '../Payroll/Payroll'
import { getSummaryColumns } from './helpers'
import { ElectronicPeriodContext } from './ElectronicPayroll'

const SummaryTable = () => {
  const { period, payPreviousPayroll } = useContext(PeriodContext) || {}
  const { socialBenefitsConfiguration, setShowSummary } =
    useContext(ElectronicPeriodContext) || {}
  const theme = useTheme()
  const featureState = usePremiumFeature('summaryReport')
  const periodId = period?.id

  const actions = useMemo(
    () => [
      {
        id: 'editPayroll',
        tooltip: 'Emitir nómina electrónica',
        isFreeAction: true,
        buttonVariant: 'outlined',
        onClick: () => setShowSummary(false),
      },
      // Should be this action a dropdown?
      {
        id: 'reportPayroll',
        tooltip: 'Descargar resumen',
        isFreeAction: true,
        buttonProps: {
          endIcon: (
            <IconCrown isPremiumAction={featureState.isPremiumFeatureIcon} />
          ),
        },
        onClick: () => {},
      },
    ],
    [featureState.isPremiumFeatureIcon, setShowSummary]
  )

  const expandedRows = useMemo(
    () => ({
      0: true,
    }),
    []
  )

  // eslint-disable-next-line no-unused-vars
  const fetchSummary = (pageIndex, search) => {
    return {
      queryKey: ['getElectronicPayrollSummary', pageIndex, search],
      queryFunction: () => {},
    }
  }

  const columns = useMemo(
    () =>
      getSummaryColumns(
        socialBenefitsConfiguration,
        periodId,
        payPreviousPayroll
      ),
    [payPreviousPayroll, periodId, socialBenefitsConfiguration]
  )

  // payment_in_year
  const data = [
    {
      payroll_id: '72a3b96f-5276-48fe-8b75-a2cb70e5d3cc',
      worker_id: '1cb3ccd2-6355-459f-9d3f-d730b9254431',
      worker_name: 'Juanita Pérez',
      income: {
        label: 'Salariales',
        value: 3000000,
      },
      deductions: {
        label: 'Seguridad social',
        value: 80000,
      },
      total: {
        label: 'Ingresos',
        value: 3560000,
      },
      subRows: [
        {
          income: {
            label: 'No salariales',
            value: 360000,
          },
          deductions: {
            label: 'Retefuente',
            value: 35000,
          },
          total: {
            label: 'Deducciones',
            value: 245000,
          },
        },
        {
          income: {
            label: 'Vaca. Incap. Lice.',
            value: 200000,
          },
          deductions: {
            label: 'Préstamos y otros',
            value: 130000,
          },
          total: {
            label: null,
            value: null,
          },
        },
      ],
    },
  ]

  return (
    <Table
      columns={columns}
      data={data}
      actions={actions}
      options={{
        expandableRows: true,
        sorting: false,
        rowStyle: (_, row) => {
          const { white } = theme.palette.common
          const grey = theme.palette.grey[100]

          if (row.depth) {
            const parentIndex = parseInt(row.id, 10)
            return {
              backgroundColor: parentIndex % 2 ? grey : white,
            }
          }
          return {
            backgroundColor: row.index % 2 ? grey : white,
          }
        },
        expandedInitialState: expandedRows,
      }}
    />
  )
}

export default SummaryTable
