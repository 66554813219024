import { Form } from 'formik'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import AddButton from 'components/UI/Button/AddButton'
import FormField from 'components/UI/Formik/FormField/Index'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '40% 1fr 1fr 42px',
    alignItems: 'start',
    gridGap: theme.spacing(1),
  },
}))

export default function AddNewExtraHour({ isActive, handleShow, handleClose }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {isActive ? (
        <Form className={classes.form}>
          <FormField
            name="name"
            placeholder="Nueva Hora Extra"
            autoComplete="off"
            margin="none"
            required
          />
          <FormField
            type="number"
            name="constant_value"
            autoComplete="off"
            margin="none"
            placeholder="0"
            required
          />
          <FormField
            type="number"
            name="quantity"
            optional
            autoComplete="off"
            margin="none"
            placeholder="0"
          />
          <Tooltip title="Cancelar" placement="right">
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Form>
      ) : (
        <AddButton onClick={handleShow}>
          Agregar una nueva hora o recargo
        </AddButton>
      )}
    </div>
  )
}
