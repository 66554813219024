import * as yup from 'yup'

import MuiLink from '@material-ui/core/Link'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ReceiptIcon from '@material-ui/icons/Receipt'

import { isOrganizer } from 'utils/auth'
import {
  subscriptionPaymentCategories,
  subscriptionPaymentStatus,
} from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'
import { isObjectEmpty } from 'utils/general'

const PAYMENT_STATUS = 'approved'

yup.setLocale(yupLocaleES)

export const getColumnsData = (onClickAction, isActionDisabled) => {
  return [
    {
      Header: 'Fecha',
      accessor: 'date',
      Cell: ({ row }) => formatDisplayDateString(row.original.date),
    },
    {
      Header: 'Plan',
      accessor: 'plan',
    },
    {
      Header: 'Personas',
      accessor: 'paid_workers',
      emptyValue: 0,
      alignCell: 'center',
      alignHeader: 'center',
    },
    {
      Header: 'Monto',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
    },
    {
      Header: 'Método de pago',
      accessor: 'category',
      Cell: ({ row }) =>
        subscriptionPaymentCategories[row.original.category]
          ? subscriptionPaymentCategories[row.original.category]
          : row.original.category,
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) =>
        subscriptionPaymentStatus[row.original.status]
          ? subscriptionPaymentStatus[row.original.status]
          : row.original.status,
    },
    {
      Header: 'Enviar factura',
      accessor: 'invoice',
      key: 'invoice',
      Cell: ({ row }) => {
        let billOptions = '-'

        if (row.original.invoice)
          billOptions = (
            <MuiLink
              onClick={() => onClickAction(row.original.id, 're-send')}
              component="button"
            >
              Reenviar
            </MuiLink>
          )
        else if (
          isOrganizer() &&
          !isActionDisabled &&
          parseInt(row.id, 10) === 0 &&
          row.original.status === PAYMENT_STATUS
        )
          billOptions = (
            <MuiLink
              onClick={() => onClickAction(row.original.id, 're-invoice')}
              component="button"
            >
              Refacturar
            </MuiLink>
          )

        return billOptions
      },
    },
  ]
}

const formData = {
  invoice_id: {
    fieldName: 'invoice_id',
    title: 'ID de la factura',
    schemaValidation: yup.object({
      invoice_id: yup
        .number('Debes ingresar un número de factura válido')
        .positive()
        .required(),
    }),
  },
  paid_extra_workers: {
    fieldName: 'paid_extra_workers',
    title: 'trabajadores extras pagados',
    schemaValidation: yup.object({
      paid_extra_workers: yup.number().required(),
    }),
  },
}

export const getTableActions = (
  openCurrentPaymentModal,
  deleteSubscriptionPayment,
  confirm
) => {
  if (!isOrganizer()) return []

  return [
    (rowData) => ({
      id: 'editId',
      icon: ReceiptIcon,
      tooltip: 'Editar ID de la factura',
      onClick: () => openCurrentPaymentModal(rowData, formData.invoice_id),
    }),
    (rowData) => ({
      id: 'editWorkers',
      icon: GroupAddIcon,
      tooltip: 'Editar trabajadores extras pagados',
      hidden: rowData.rowIndex !== 0,
      onClick: () =>
        openCurrentPaymentModal(rowData, formData.paid_extra_workers),
    }),
    {
      id: 'deletePayment',
      icon: DeleteForeverIcon,
      tooltip: 'Eliminar pago',
      hidden: !isOrganizer(),
      onClick: (rowData) => {
        confirm({
          type: 'warning',
          title: 'Eliminar pago',
          description:
            'Eliminar este pago es permanente y no se podrá deshacer. ¿Estás seguro?',
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => deleteSubscriptionPayment(rowData.id),
        })
      },
    },
  ]
}

const initialValues = [
  { id: 'plan', label: 'Plan', value: '-' },
  { id: 'periodicity', label: 'Modalidad', value: '-' },
  { id: 'monthly_cost', label: 'Costo mensual', value: '-' },
  { id: 'periodicity_subtotal', label: 'Subtotal', value: '-' },
  { id: 'total', label: 'TOTAL', value: '-' },
]

export const getPlanSummary = (plan, subscription, companyName) => {
  if (isObjectEmpty(plan)) return initialValues

  const isAnually = plan.payment_type === 'year'
  const { distributor } = plan.subscription_value?.discounts || {}
  const planSubscriptionValue = isAnually
    ? plan.subscription_value?.yearly || {}
    : plan.subscription_value?.monthly || {}

  // Company name
  const summary = [
    {
      label: 'Empresa',
      value: companyName,
      id: 'company_name',
    },
  ]

  // Plan name
  summary.push({
    label: 'Plan',
    value: plan.name.split(' ').slice(2).join(' '),
    id: 'plan',
  })

  // Plan type
  if (!subscription?.partner) {
    summary.push({
      label: 'Modalidad',
      value: isAnually ? 'Anual' : 'Mensual',
      id: 'periodicity',
    })
  }

  // Monthly value
  if (!subscription?.partner && isAnually)
    summary.push({
      label: 'Costo mensual',
      value: formatCurrency(planSubscriptionValue.value),
      id: 'monthly_cost',
    })

  // Extra Workers number
  if (planSubscriptionValue.workers_number > 0) {
    summary.push({
      label: 'Empleados extra',
      value: `${planSubscriptionValue.workers_number}`,
      id: 'extra_workers',
    })
  }

  if (planSubscriptionValue.extra_workers_value) {
    summary.push({
      label: 'Subtotal empleados extra',
      value: formatCurrency(planSubscriptionValue.extra_workers_value),
      id: 'extra_workers_subtotal',
    })
  }

  // Subtotal value
  if (!subscription?.partner)
    summary.push({
      label: `Subtotal ${isAnually ? 'anualidad' : 'mensualidad'}`,
      value: formatCurrency(planSubscriptionValue.subtotal),
      id: 'periodicity_subtotal',
    })

  // Nominapp discount
  if (planSubscriptionValue.extra_charge?.nominapp)
    summary.push({
      label: 'Descuento (Nominapp)',
      value: `- ${formatCurrency(
        Math.abs(planSubscriptionValue.extra_charge?.nominapp)
      )}`,
      id: 'nominapp_discount',
    })

  // Distributor discount value
  if (planSubscriptionValue.extra_charge?.distributor)
    summary.push({
      label: `Descuento (${distributor})`,
      value: `- ${formatCurrency(
        Math.abs(planSubscriptionValue.extra_charge?.distributor)
      )}`,
      id: 'distributor_discount',
    })

  const total = subscription?.partner
    ? plan?.monthly_value
    : planSubscriptionValue.total

  // Total value
  summary.push({
    label: 'TOTAL',
    value: formatCurrency(total),
    id: 'total',
    bold: true,
  })

  return summary
}
