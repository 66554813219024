import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right',
  },
}))

const CurrencyField = ({ id, name, value, ...props } = {}) => {
  const localClasses = useStyles()
  const {
    setValue,
    touched,
    onBlur,
    className,
    align = 'right',
    inputProps = {},
    ...restProps
  } = props
  const { className: inputPropsClassName, ...restInputProps } = inputProps

  return (
    <NumberFormat
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      placeholder="0"
      // Do NOT add onChange from 'field'
      onValueChange={(values) => {
        setValue(values.floatValue)
      }}
      onFocus={() => {
        if (value === 0 && !touched) {
          setValue('')
        }
      }}
      customInput={OutlinedInput}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      allowNegative={false}
      className={className}
      inputProps={{
        className: clsx(inputPropsClassName, {
          [localClasses.alignRight]: align === 'right',
        }),
        ...restInputProps,
      }}
      {...restProps}
    />
  )
}

export default CurrencyField
