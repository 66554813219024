import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import { useUser } from 'components/App/UserContext/useUser'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  contentWithNoItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 8,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(4),
  },
  icon: {
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  },
}))

const NoItems = ({ type, onClick }) => {
  const classes = useStyles()
  const { isWorker } = useUser()
  return (
    <div className={classes.contentWithNoItems}>
      <MoneyOffIcon fontSize="large" className={classes.icon} />
      <Typography paragraph>
        No existen{' '}
        {type === 'incomes' ? 'ingresos asociados' : 'deducciones asociadas'}
      </Typography>
      {!isWorker && (
        <MuiButton variant="outlined" onClick={onClick}>
          Agregar {type === 'incomes' ? 'Ingresos' : 'Deducciones y Préstamos'}
        </MuiButton>
      )}
    </div>
  )
}

export default NoItems
