import { useState } from 'react'
import { useQueries } from 'react-query'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import AddButton from 'components/UI/Button/AddButton'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import useWorker from 'components/Worker/useWorker'

import { getAllByCategory } from 'services/payroll/payrollConceptService'

import RecurrentConceptsModal from '../Modals/RecurrentConceptsModal/RecurrentConceptsModal'
import DeductionsTable from './Deductions/DeductionsTable'
import IncomesTable from './Income/IncomesTable'
import RetentionConfigurationItem from './RetentionConfigurationItem'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}))

const RecurrentConceptsContent = () => {
  const [isIncomesModalOpen, setIsIncomesModalOpen] = useState(false)
  const [isDeductionsModalOpen, setIsDeductionsModalOpen] = useState(false)

  const classes = useStyles()
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const showRetentionConfiguration = ['employee', 'pensioner'].includes(
    worker?.contract_category
  )

  const [salaryIncomeQuery, nonSalaryIncomeQuery, deductionsQuery] = useQueries(
    ['salary_income', 'non_salary_income', 'deductions'].map((query) => ({
      queryKey: [query, worker.id],
      queryFn: () => getAllByCategory(query),
    }))
  )

  const isLoading =
    salaryIncomeQuery.isLoading ||
    nonSalaryIncomeQuery.isLoading ||
    deductionsQuery.isLoading

  if (isLoading) return <LoadingBox />

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h5" paragraph>
          Conceptos Recurrentes
        </Typography>

        <Typography variant="body2">
          Acá puedes agregar ingresos y deducciones recurrentes o activar el
          cálculo de la retención en la fuente
        </Typography>

        <div className={classes.titleSection}>
          <Typography variant="subtitle1">Ingresos</Typography>
          {!isWorker && (
            <AddButton
              onClick={() => setIsIncomesModalOpen(true)}
              data-cy="add_incomes_button"
            />
          )}
        </div>

        <IncomesTable noItemsOnClick={() => setIsIncomesModalOpen(true)} />

        <div className={classes.titleSection}>
          <Typography variant="subtitle1">Deducciones y préstamos</Typography>
          {!isWorker && (
            <AddButton
              onClick={() => setIsDeductionsModalOpen(true)}
              data-cy="add_deductions_button"
            />
          )}
        </div>

        <DeductionsTable
          noItemsOnClick={() => setIsDeductionsModalOpen(true)}
        />

        {!isWorker && showRetentionConfiguration && (
          <RetentionConfigurationItem />
        )}
      </div>

      {isIncomesModalOpen && (
        <RecurrentConceptsModal
          modalType="incomes"
          onCancel={() => setIsIncomesModalOpen(false)}
        />
      )}

      {isDeductionsModalOpen && (
        <RecurrentConceptsModal
          modalType="deductions"
          onCancel={() => setIsDeductionsModalOpen(false)}
        />
      )}
    </>
  )
}

export default RecurrentConceptsContent
