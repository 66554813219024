import FormField from 'components/UI/Formik/FormField/Index'

import { accountTypesOptions } from 'utils/company'

const AccountTypeField = () => {
  return (
    <FormField
      name="account_type"
      label="Tipo de cuenta"
      variant="autocomplete"
      placeholder="Selecciona el tipo de cuenta..."
      options={accountTypesOptions}
      renderOption={({ label }) => label}
      getOptionLabel={({ label }) => label}
      getOptionSelected={(opt, value) =>
        opt.value === value.value && opt.label === value.label
      }
    />
  )
}

export default AccountTypeField
