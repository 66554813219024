import parseISO from 'date-fns/parseISO'

import http, { getApiURL } from '../httpService'

export const getAllWorkers = ({ filter, search, page } = {}) => {
  const url = getApiURL({
    pathname: '/workers',
    searchParams: { page, search, filter },
  })

  return http.getV2(url)
}

export const getTotalsWorkers = async () => {
  const url = getApiURL({ pathname: '/total_workers' })

  return http.getV2(url)
}

export const getWorkerById = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}` })

  return http.getV2(url)
}

export const createWorker = (worker) => {
  const url = getApiURL({ pathname: '/workers' })

  return http.postV2(url, worker)
}

export const updateWorker = (worker, workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}` })

  const data = { worker }

  return http.patchV2(url, data)
}

export const getWorkerOptions = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/worker_options` })

  return http.getV2(url)
}

export const getNoveltiesHistoryWorker = (
  workerId,
  { filter, page, search, file_format: fileFormat = 'json', async = true } = {}
) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/novelties_history`,
    searchParams: { file_format: fileFormat, filter, page, search, async },
  })

  return http.getV2(url)
}

export const getPaymentsHistory = (
  workerId,
  { page, file_format: fileFormat = 'json', async = true } = {}
) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/payments_history`,
    searchParams: { file_format: fileFormat, page, async },
  })

  return http.getV2(url)
}

export const deleteWorker = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}` })

  return http.deleteV2(url)
}

export default {
  getAllWorkers,
  getTotalsWorkers,
  createWorker,
  updateWorker,
  getWorkerOptions,
  getNoveltiesHistoryWorker,
  getPaymentsHistory,
  deleteWorker,
  getWorkerById,
}

export function formatPayment(payment) {
  const formattedPayment = payment
  formattedPayment.initial_day = parseISO(payment.initial_day)
  formattedPayment.end_day = parseISO(payment.end_day)

  return formattedPayment
}
