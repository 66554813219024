import * as yup from 'yup'

import Entities from './Entities'
import Password from './Password'
import UserName from './UserName'

export const validationSchema = yup.object({
  institution_id: yup.string().nullable().required(),
  username: yup.string().nullable().required(),
  password: yup.string().nullable().required(),
})

export const affiliationProviders = [
  { label: 'EPS', value: 'health_provider' },
  { label: 'Riesgos laborales', value: 'risk_provider' },
  { label: 'Fondo de pension', value: 'pension_provider' },
  { label: 'Caja de compensación familiar', value: 'compensation_fund' },
  { label: 'Fondo de cesantías', value: 'severance_provider' },
]

export const getCredentialsColumns = () => {
  return [
    {
      Header: 'Tipo de entidad',
      accessor: 'institution_category',
      editable: true,
      Cell: ({ row }) => {
        const category = row?.original?.institution_category
        return category
          ? affiliationProviders.find(({ value }) => category === value).label
          : ''
      },
      name: 'institution_category',
      field: 'select',
      editOptions: () => affiliationProviders,
    },
    {
      Header: 'Nombre entidad',
      accessor: 'institution_id',
      editable: true,
      Cell: ({ row }) =>
        row.original.institution_name ? row.original?.institution_name : '',
      Edit: ({ rowValues }) => <Entities rowValues={rowValues} />,
    },
    {
      Header: 'Usuario',
      accessor: 'username',
      editable: true,
      Cell: ({ value }) => <UserName value={value} />,
      customMinWidth: '150px',
    },
    {
      Header: 'Contraseña',
      accessor: 'password',
      editable: true,
      Cell: ({ value }) => <Password value={value} />,
      customMinWidth: '180px',
    },
  ]
}
