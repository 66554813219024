import React from 'react'
import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

const ConceptCategory = ({ rowValues, options }) => {
  const { setFieldValue } = useFormikContext()

  const onChange = (event) => {
    if (event.target.value !== rowValues?.category) {
      setFieldValue('payroll_concept_id', '')
      setFieldValue('name', '')
    }
    setFieldValue('category', event.target.value)
  }

  return (
    <FormField
      name="category"
      variant="select"
      options={options}
      onChange={onChange}
    />
  )
}

export default ConceptCategory
