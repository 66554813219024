import { useContext } from 'react'
import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import PeopleAltIcon from '@material-ui/icons/PeopleAltOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Button from 'components/UI/Button/Button'
import NoWrap from 'components/UI/NoWrap'
import PaymentContext from 'components/Subscription/Atoms/PaymentContext'

import { formatCurrency } from 'utils/format'

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 10,
    paddingBottom: '16px !important',
  },
  employeesNumber: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const SubscriptionPaywallPlanCard = ({ plan, isAnually }) => {
  const classes = useStyles()
  const {
    step,
    selectedPlan,
    setSelectedPlan,
    setIsExtraWorkerModalOpen,
  } = useContext(PaymentContext)
  const isSelected = plan.name === selectedPlan.name

  const handleClick = () => {
    if (plan.name !== selectedPlan.name) {
      setSelectedPlan({
        ...plan,
        payment_type: isAnually ? 'year' : 'month',
      })
    }

    if (plan.personalized) setIsExtraWorkerModalOpen(true)
  }

  return (
    <Card
      className={clsx({
        [classes.card]: !isSelected,
        [classes.cardSelected]: isSelected,
      })}
      onClick={handleClick}
      data-cy={`${plan.coded_name}_card`}
    >
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h5" color={isSelected ? 'primary' : 'initial'}>
              {plan.name.split(' ').slice(2).join(' ')}
            </Typography>

            {plan.coded_name === 'nominapp_plan_pro' && (
              <Chip
                size="small"
                variant="outlined"
                label="Popular"
                style={{ marginLeft: 10 }}
              />
            )}
          </Box>
        }
        action={
          isSelected && step !== 2 ? (
            <CheckCircleIcon color="primary" fontSize="small" />
          ) : null
        }
        classes={{ root: classes.header }}
      />

      <CardContent classes={{ root: classes.content }}>
        <div className={classes.employeesNumber}>
          <PeopleAltIcon color="primary" />
          <Typography style={{ paddingLeft: 10 }}>
            {plan.personalized ? 'Más de' : 'Hasta'} {plan.max_workers} empleado
            {plan.maxworkers > 1 ? '' : 's'}
          </Typography>
        </div>

        <Typography variant="h6">
          {formatCurrency(
            isAnually ? plan.yearly_value_by_month : plan.monthly_value
          )}
          /mes
        </Typography>

        {plan.personalized ? (
          <Box display="flex">
            <Typography variant="h6" style={{ marginRight: 5 }}>
              {formatCurrency(
                isAnually
                  ? plan.yearly_extra_worker_value
                  : plan.monthly_extra_worker_value
              )}
            </Typography>
            <Typography variant="body2">por empleado extra.</Typography>
          </Box>
        ) : (
          <Typography variant="caption">
            Factura anual {formatCurrency(plan.total_yearly_value)}
            {plan.discount_value > 0 && (
              <>
                {' y ahorra '}
                <NoWrap>{formatCurrency(plan.discount_value)}</NoWrap>.
              </>
            )}
          </Typography>
        )}

        {plan.personalized && step === 2 && (
          <Box display="flex" marginTop={2}>
            <Typography variant="h6" style={{ marginTop: 3 }}>
              {selectedPlan.paid_extra_workers} empleados extra
            </Typography>

            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setIsExtraWorkerModalOpen(true)}
              style={{ marginLeft: 10 }}
            >
              Cambiar Cantidad
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default SubscriptionPaywallPlanCard
