import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import OrganizationEmail from 'components/UI/OrganizationEmail'

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: 'center',
    margin: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
}))

const ContactToSupport = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Typography variant="body2">
        Si necesitas que te rescatemos, escribenos a <OrganizationEmail />.
      </Typography>
    </footer>
  )
}

export default ContactToSupport
