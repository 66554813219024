import * as yup from 'yup'

import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { getWordSingular } from 'utils/format'

import RequestFreeTimeModal from '../Modals/RequestFreeTimeModal'

export const getColumnsData = (classes) => {
  const statusLabel = {
    pending: 'Pendiente',
    approved: 'Aprobado',
    rejected: 'Rechazado',
  }

  const getTypeLabel = (payrollConceptName) => {
    return payrollConceptName === 'Vacaciones disfrutadas'
      ? 'Vacaciones'
      : payrollConceptName
  }

  return [
    {
      accessor: 'payroll_concept_name',
      Header: 'Tipo de permiso',
      Cell: ({ row }) => getTypeLabel(row.original.payroll_concept_name),
    },
    {
      accessor: 'start_date',
      Header: 'Fecha ausencia',
      Cell: ({ row }) => (
        <div className={classes.dateContainer}>
          {formatDisplayDateString(row.original.initial_day)}
          <ArrowForwardIcon fontSize="small" />
          {formatDisplayDateString(row.original.end_day)}
        </div>
      ),
    },
    {
      accessor: 'comment',
      Header: 'Comentarios',
      Cell: ({ row }) => {
        if (!row.original.comment) return 'N/A'

        return (
          <div>
            <Tooltip title={row.original.comment} arrow>
              <p>
                {row.original.comment.length > 25
                  ? `${row.original.comment.slice(0, 25)}...`
                  : row.original.comment}
              </p>
            </Tooltip>
          </div>
        )
      },
    },
    {
      accessor: 'duration',
      Header: 'Duración',
      Cell: ({ row }) =>
        `${row.original.days} ${getWordSingular(
          'días',
          row.original.days === 1
        )}`,
      customWidth: '100px',
    },
    {
      accessor: 'status',
      Header: 'Estado',
      Cell: ({ row }) => (
        <Chip
          className={classes[row.original.status]}
          label={statusLabel[row.original.status]}
        />
      ),
      alignHeader: 'center',
      alignCell: 'center',
      customWidth: '150px',
    },
  ]
}

export const getActions = (
  workerId,
  handleNewRequest,
  modals,
  handleDownloadEvidence
) => {
  const handleClose = () => modals.closeAll()

  const handleEdit = (rowData) => {
    modals.openModal({
      id: 'editFreeTimeRequest',
      content: (
        <RequestFreeTimeModal
          requestData={rowData}
          onClose={handleClose}
          isEditing
          workerId={workerId}
        />
      ),
      modalProps: {
        header: 'Editar solicitud',
        onCloseModal: handleClose,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          maxWidth: 750,
        },
      },
    })
  }

  return [
    (rowData) => ({
      id: 'downloadEvidence',
      tooltip: 'Ver documento',
      icon: GetAppOutlinedIcon,
      hidden: !rowData.evidence,
      onClick: () => handleDownloadEvidence(rowData.evidence),
    }),
    {
      id: 'requestFreeTime',
      tooltip: 'Solicitar días libres',
      isFreeAction: true,
      onClick: handleNewRequest,
    },
    (rowData) => ({
      id: 'editFreeTimeRequest',
      tooltip: 'Editar solicitud',
      icon: EditOutlinedIcon,
      disabled: rowData.status === 'approved',
      onClick: () => handleEdit(rowData),
    }),
  ]
}

yup.setLocale(yupLocaleES)

const getNoveltyType = (payrollConcepts, noveltyType) => {
  const noveltyTypeId = payrollConcepts.find(
    (concept) => concept.id === noveltyType
  )
  return noveltyTypeId?.coded_name
}

export const getInitialValues = (values = {}, payrollConcepts) => ({
  initial_day: values?.initial_day,
  end_day: values?.end_day,
  novelty_type: getNoveltyType(payrollConcepts, values?.payroll_concept_id),
  evidence: values?.evidence || null,
  comment: values?.comment,
})

export const validationSchema = yup.object({
  initial_day: yup.date().required('La fecha de inicio es requerida'),
  end_day: yup.date().required('La fecha de fin es requerida'),
  novelty_type: yup.string().required('El tipo de novedad es requerido'),
  evidence: yup.array().of(yup.object(), yup.string()).nullable(),
  comment: yup.string().nullable(),
})

export const conceptsToDelete = (contractCategory) => {
  const concepts = ['compensated_days', 'job_abandonment', 'suspension']

  if (contractCategory === 'schooling_trainee')
    concepts.push(
      'enjoyed_days',
      'labor_incapacity',
      'unpaid_leave',
      'paid_leave'
    )
  if (
    contractCategory === 'productive_trainee' ||
    contractCategory === 'intern'
  )
    concepts.push('enjoyed_days', 'unpaid_leave', 'paid_leave')
  if (contractCategory === 'student_decree_055')
    concepts.push(
      'enjoyed_days',
      'general_incapacity',
      'material_leave',
      'paternity_leave',
      'unpaid_leave',
      'paid_leave'
    )
  if (contractCategory === 'part_time_contract')
    concepts.push('general_incapacity', 'material_leave', 'paternity_leave')

  return concepts
}

export const conceptsTypes = ['holidays', 'incapacities', 'licenses']

export const tabsData = [
  { key: '', label: 'Todas las solicitudes' },
  {
    key: 'pending',
    label: 'Pendientes',
  },
  {
    key: 'approved',
    label: 'Aprobadas',
  },
  {
    key: 'rejected',
    label: 'Rechazadas',
  },
]
