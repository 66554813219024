import Chip from '@material-ui/core/Chip'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

import { getNameWorkerType } from 'utils/worker'

const generateTabs = (activeTab, handleChangeTab, contractsCounter) => () => {
  return activeTab ? (
    <Tabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={Object.keys(contractsCounter).reduce((arr, contract) => {
        if (contractsCounter[contract] > 0) {
          arr.push({
            key: contract,
            label: (
              <>
                {getNameWorkerType(contract, true)}
                <Chip
                  label={contractsCounter[contract]}
                  style={{ marginLeft: 5 }}
                />
              </>
            ),
          })
        }

        return arr
      }, [])}
    />
  ) : null
}

export default generateTabs
