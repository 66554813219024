import { useState } from 'react'

import { generateEmptyPayrollItem } from 'utils/payroll'

/**
 * Used in conjunction with IncomeModal
 */
const useIncomeModal = () => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const getInitialData = (
    salaryIncomeConceptsData = [],
    nonSalaryIncomeConceptsData = [],
    salaryIncomeData = [],
    nonSalaryIncomeData = []
  ) => {
    salaryIncomeConceptsData.forEach((concept) => {
      const conceptCopy = concept
      salaryIncomeData.forEach((income) => {
        if (income.payroll_concept_id === concept.id) {
          conceptCopy.selected = true
        }
      })
      return concept
    })

    nonSalaryIncomeConceptsData.map((concept) => {
      const conceptCopy = concept
      nonSalaryIncomeData.forEach((income) => {
        if (income.payroll_concept_id === concept.id) {
          conceptCopy.selected = true
        }
      })
      return concept
    })

    if (salaryIncomeData.length === 0) {
      salaryIncomeData.push(generateEmptyPayrollItem())
    }

    if (nonSalaryIncomeData.length === 0) {
      nonSalaryIncomeData.push(generateEmptyPayrollItem())
    }

    return {
      salaryIncomeConcepts: salaryIncomeConceptsData,
      nonSalaryIncomeConcepts: nonSalaryIncomeConceptsData,
      salary_income: salaryIncomeData,
      non_salary_income: nonSalaryIncomeData,
    }
  }

  return {
    showModal,
    handleOpenModal,
    handleCloseModal,
    getInitialData,
  }
}

export default useIncomeModal
