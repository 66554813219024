import { makeStyles } from '@material-ui/core/styles'

const useFormStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(4),
    justifyContent: 'end',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      gridAutoFlow: 'row',
      justifyContent: 'center',
      '& button:nth-of-type(1)': {
        order: 2,
      },
      '& button:nth-of-type(2)': {
        order: 1,
        marginLeft: 0,
      },
    },
  },
  columnsContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      alignItems: 'center',
      rowGap: theme.spacing(2.5),
      columnGap: theme.spacing(4),
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))

export default useFormStyles
