import { Form, Formik } from 'formik'

import InputAdornment from '@material-ui/core/InputAdornment'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import {
  generateBasicNumberFormatter,
  generateBasicNumberParser,
} from 'utils/format'
import usePartnerOrganizerService from 'utils/hooks/organizer/partnerService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { notAlphanumerics } from 'utils/regex'

import {
  customBaseDiscountType,
  getInitialValues,
  validationSchema,
} from './helpers'

const OrganizerPartnersIndexModal = ({
  open,
  partner,
  handleClose,
  submitCallback,
}) => {
  const { partnerOrganizerMutation } = usePartnerOrganizerService()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const isEdit = !!partner.id
  const initialValues = getInitialValues(partner)

  const onSubmit = (values, form) => {
    partnerOrganizerMutation.mutate(
      {
        mutationMethod: 'PUT',
        partner: values,
      },
      {
        onSuccess: () => {
          submitCallback()
          handleClose()
          showSuccessMessage(
            `Partner ${isEdit ? 'actualizado' : 'creado'} con éxito.`
          )
        },
        onError: (error) => handleError(error, form),
      }
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, ...partner }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, handleSubmit, dirty }) => {
        const { base_discount_type: baseDiscountType } = values

        return (
          <Modal
            okText="Guardar"
            header={`${isEdit ? 'Editar' : 'Crear'} Partner`}
            loadingText="Guardando..."
            open={open}
            onOk={dirty ? handleSubmit : handleClose}
            onCancel={handleClose}
            isLoading={partnerOrganizerMutation.isLoading}
          >
            <Form>
              <FormField name="name" label="Nombre del partner" />
              <FormField
                name="id_number"
                label="Número de identificación"
                variant="custom-format"
                formatter={generateBasicNumberFormatter({
                  thousandSeparator: null,
                  decimalSeparator: null,
                })}
                parser={generateBasicNumberParser({
                  regexToRemove: notAlphanumerics,
                  convertToNumber: false,
                })}
              />
              <FormField name="email" label="Correo electrónico" type="email" />
              <FormField
                name="discount"
                label="Descuento por persona"
                type="number"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />

              <FormField
                name="base_discount_type"
                label="Tipo de Descuento sobre la base"
                variant="select"
                options={customBaseDiscountType(isEdit, partner)}
              />
              <FormField
                name="base_discount"
                label="Descuento sobre la base"
                type={baseDiscountType === 'percentage' ? 'number' : undefined}
                component={
                  baseDiscountType === 'percentage' ? 'text' : 'currency'
                }
                endAdornment={
                  baseDiscountType === 'percentage' && (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default OrganizerPartnersIndexModal
