import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import FreeTimeIndex from 'components/Worker/FreeTime/Index/Index'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 3, 4),
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.grey[500],
  },
}))

const FreeTimeContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="h5">Historial de solicitudes</Typography>
      <Divider className={classes.divider} />
      <FreeTimeIndex />
    </div>
  )
}

export default FreeTimeContent
