import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(8),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
  },
}))

const DetailSectionContainer = ({
  children,
  title = undefined,
  actions = undefined,
  gutterBottom = true,
  removeHeader = false,
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.container]: gutterBottom,
      })}
    >
      {!removeHeader && (title || actions) && (
        <div className={classes.header}>
          <Typography variant="h5">{title}</Typography>
          {actions && <>{actions}</>}
        </div>
      )}
      <>{children}</>
    </div>
  )
}

export default DetailSectionContainer
