import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

const useStyles = makeStyles((theme) => ({
  sectionForm: {
    marginBottom: theme.spacing(4),
  },
}))

const SectionCard = ({ subtitle, children, tooltipContent }) => {
  const classes = useStyles()

  return (
    <div className={classes.sectionForm}>
      <Typography variant="h5" gutterBottom>
        {subtitle}
        {tooltipContent && (
          <TooltipInfoIcon
            title={tooltipContent}
            interactive={typeof tooltipContent !== 'string'}
            tooltipProps={{ placement: 'right' }}
          />
        )}
      </Typography>
      {children}
    </div>
  )
}

export default SectionCard
