import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useState } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import IconCrown from 'components/App/Premium/Atoms/IconCrown'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'

import { workerCertificates } from 'utils/worker'

import IncomeCertificateModal from '../CertificateModal/Income'
import LaborCertificateModal from '../CertificateModal/Labor'

const CertificatesMenu = ({ worker, workerOptions }) => {
  const { user } = useUser()
  const [laborModalVisible, setLaborModalVisible] = useState(false)
  const [incomeModalVisible, setIncomeModalVisible] = useState(false)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerCertificatesMenu',
  })
  const popupBindTrigger = bindTrigger(popupState)

  const featureState = usePremiumFeature(
    'reports',
    user.multicompanyUser ? worker.premium : undefined
  )

  const openPopover = (event) => {
    if (featureState.isPremiumFeature) {
      featureState.openModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  const showModal = (modalType) => {
    if (modalType === 'labor_certificate') setLaborModalVisible(true)
    if (modalType === 'income_certificate') setIncomeModalVisible(true)
    popupState.close()
  }

  return (
    <>
      <Button
        endIcon={
          featureState.isPremiumFeature ? <IconCrown /> : <ExpandMoreIcon />
        }
        {...popupBindTrigger}
        onClick={openPopover}
      >
        Certificados
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {workerOptions?.certificate_options?.map((opt) => {
          return (
            <MenuItem key={opt} onClick={() => showModal(opt)}>
              {workerCertificates[opt] ? workerCertificates[opt] : opt}
            </MenuItem>
          )
        })}
      </Menu>
      {/* Modals */}
      <LaborCertificateModal
        worker={worker}
        visible={laborModalVisible}
        handleClose={() => setLaborModalVisible(false)}
      />
      <IncomeCertificateModal
        worker={worker}
        visible={incomeModalVisible}
        handleClose={() => setIncomeModalVisible(false)}
      />
    </>
  )
}

export default CertificatesMenu
