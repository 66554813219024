import clsx from 'clsx'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { isChristmasTime } from 'utils/dateTime'

import logoIsotipo from 'assets/images/logos/isotipo_amarillo.svg'
import logoIsotipoNavidad from 'assets/images/logos/isotipo_navidad.svg'
import alegraLogoIsotipoBlanco from 'assets/images/logos/alegra/alegra_isotipo_blanco.svg'
import arusIsotipo from 'assets/images/logos/arus/isotipo_arus.svg'

import AppLogo from '../Logo'

import { useUser } from '../UserContext/useUser'
import { SidebarContext } from './Sidebar'

const useStyles = makeStyles(() => ({
  root: {
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
  logoImg: {
    width: 172,
    display: 'none',
  },
  logoImgExpanded: {
    display: 'block',
  },
  logoIsotipoImg: {
    width: 40,
    display: 'block',
  },
  logoIsotipoImgExpanded: {
    display: 'none',
  },
}))

const AppSidebarLogo = () => {
  const { distributor } = useUser()
  const { expanded } = useContext(SidebarContext)
  const classes = useStyles()

  const imgProps = {
    alt: 'Nominapp Logo',
    src: logoIsotipo,
    'data-cy': 'sidebar_logo_nominapp',
  }

  if (distributor === 'alegra_claro') {
    imgProps.alt = 'Alegra Claro Logo'
    imgProps.src = alegraLogoIsotipoBlanco
    imgProps['data-cy'] = 'sidebar_logo_alegra_claro'
  } else if (distributor === 'arus') {
    imgProps.alt = 'Nominapp Logo Blanco'
    imgProps.src = arusIsotipo
  } else if (isChristmasTime()) {
    imgProps.alt = 'Nominapp Logo Navidad'
    imgProps.src = logoIsotipoNavidad
  }

  return (
    <Link to="/" className={classes.root}>
      <AppLogo
        className={clsx(classes.logoImg, {
          [classes.logoImgExpanded]: expanded,
        })}
      />
      {/*  eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        {...imgProps}
        className={clsx(classes.logoIsotipoImg, {
          [classes.logoIsotipoImgExpanded]: expanded,
        })}
      />
    </Link>
  )
}

export default AppSidebarLogo
