import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  totalValue: {
    marginTop: theme.spacing(3.5),
  },
  itemValue: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    marginLeft: theme.spacing(1.5),
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SummaryTotal = ({ label, value, items = [] }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography
        variant="body1"
        className={classes.greyText}
        gutterBottom={items.length > 0}
      >
        {label}
      </Typography>
      {items.length > 0 &&
        items.map((item) => (
          <div key={item.id} className={classes.itemContainer}>
            <Typography variant="body1" className={classes.greyText}>
              {item.label}
            </Typography>
            <Typography variant="body1" className={classes.itemValue}>
              {item.value}
            </Typography>
          </div>
        ))}
      <Typography variant="h4" className={classes.totalValue}>
        {value}
      </Typography>
    </div>
  )
}

export default SummaryTotal
