import { useLocation } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import Link from 'components/UI/MaterialUI/Link'

import * as routes from 'config/routes'

const LinkToSignup = () => {
  const location = useLocation()

  return (
    <Typography variant="body2">
      ¿Aún no tienes cuenta?{' '}
      <Link
        to={{
          pathname: routes.SIGN_UP,
          search:
            location.pathname === (routes.LOGIN || routes.LOGIN_2)
              ? '?utm_source=Login'
              : null,
        }}
      >
        Crear cuenta
      </Link>
    </Typography>
  )
}

export default LinkToSignup
