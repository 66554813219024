import { Form, Formik } from 'formik'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import useWorker from 'components/Worker/useWorker'

import { formatValues } from 'utils/form'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useTerminationService from 'utils/hooks/worker/terminationService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import * as routes from 'config/routes'

import Fields from './Fields'
import TouchedAutoRefresh from './TouchedAutoRefresh'
import { getInitialValues, validationSchema } from './helpers'

const ContractTerminationForm = ({ match, history, isEditing }) => {
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const [dirtyTermination, setDirtyTermination] = useState({})
  const confirm = useConfirm()
  const { showErrorMessage, showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const formRef = useRef(null)
  const { workerId } = match.params
  const { contractId } = match.params
  const queryKey = [
    isEditing ? 'getTermination' : 'getTerminationPreview',
    contractId,
  ]
  const { terminationMutation, terminationQuery } = useTerminationService({
    serviceParams: {
      queryKey,
      contractId,
    },
  })
  const baseTermination = terminationQuery.data
  const modifiedTermination = terminationMutation.data?.data

  const fetchTerminationPreview = useCallback(
    async (termination) => {
      const terminationPreview = await terminationMutation.mutateAsync(
        {
          mutationMethod: 'CREATE_PREVIEW',
          contractId,
          termination,
        },
        {
          onSuccess: ({ data }) => {
            if (data) {
              formRef.current?.setValues(getInitialValues(data))
            }
          },
          onError: (error) => {
            if (error.errors && error.errors[0].code === '0706') {
              // redirect to editing if user came to new termination if already was terminated
              history.push(
                routes.WORKER_CONTRACT_TERMINATION_EDIT(workerId, contractId)
              )
            } else {
              handleError(error)
            }
          },
        }
      )
      return terminationPreview.data
    },
    [contractId, handleError, history, terminationMutation, workerId]
  )

  const handleCancel = () => {
    history.push(routes.WORKER_SHOW(workerId))
  }

  const submitTermination = async (values, form) => {
    const terminationRes = formatValues(values)

    try {
      if (isEditing) {
        terminationMutation.mutate(
          {
            mutationMethod: 'UPDATE',
            contractId,
            termination: terminationRes,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(queryKey)
              showSuccessMessage(
                'La liquidación se ha actualizado correctamente.'
              )
              history.push(routes.WORKER_SHOW(workerId))
              refreshWorker()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      } else {
        terminationMutation.mutate(
          {
            mutationMethod: 'CREATE',
            contractId,
            termination: terminationRes,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(queryKey)
              showSuccessMessage(
                '¡El contrato ha sido liquidado de forma exitosa!'
              )
              history.push(routes.WORKER_SHOW(workerId))
              refreshWorker()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      }

      if (!isEditing) trackEvent(integrationEvent.EMPLOYEE_TERMINATION)
    } catch (error) {
      handleError(error, form, {
        errorsToNotificate: [
          { object: 'worker_payment', code: '0305' },
          { object: 'cause', code: '0002' },
          { object: 'novelty' },
        ],
      })
    }
  }

  const onSubmit = (values, form) => {
    if (values.worker_payment < 0) {
      showErrorMessage(
        'El pago total de la liquidación no debe ser menor que 0.'
      )
      form.setTouched({})
      form.setSubmitting(false)
    } else {
      confirm({
        title: isEditing ? 'Editar liquidación' : 'Liquidar persona',
        description: isEditing
          ? '¿Estás seguro de editar esta liquidación?'
          : '¿Estás seguro de liquidar a esta persona? Este cambio no puede deshacerse. Si quieres, luego podrás reincorporarla de nuevo.',
        type: 'warning',
        okText: isEditing ? 'Guardar' : 'Liquidar',
        onOk: () => submitTermination(values, form),
        onCancel: () => {
          form.setTouched({})
          form.setSubmitting(false)
        },
      })
    }
  }

  const termination = useMemo(
    () => modifiedTermination || baseTermination || {},
    [baseTermination, modifiedTermination]
  )

  return (
    <Page
      documentTitle={`Liquidación${worker ? ` de ${worker.fullName}` : ''}`}
      header={
        <Typography variant="h5">
          Liquidación
          {worker && (
            <>
              {' de '}
              <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
            </>
          )}
        </Typography>
      }
      isLoading={terminationQuery.isLoading}
      isLoadingWithModal={terminationMutation.isLoading}
    >
      {!terminationQuery.isLoading && (
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(terminationQuery.data)}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                <TouchedAutoRefresh
                  termination={termination}
                  dirtyTermination={dirtyTermination}
                  setDirtyTermination={setDirtyTermination}
                  fetchTerminationPreview={fetchTerminationPreview}
                />
                <Fields
                  dirtyTermination={dirtyTermination}
                  handleCancel={handleCancel}
                  isEditing={isEditing}
                  isSaving={
                    terminationMutation.isLoading &&
                    formRef.current?.isSubmitting
                  }
                />
              </Form>
            )
          }}
        </Formik>
      )}
    </Page>
  )
}

export default ContractTerminationForm
