import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
}))

const ContainerDetails = ({ nameDetail, detail }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="subtitle1" className={classes.gutterBottom}>
        {nameDetail}
      </Typography>
      <Typography variant="body2">{detail}</Typography>
    </div>
  )
}

export default ContainerDetails
