import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

const provisionsLink1 = (
  <TooltipLink href="http://ayuda.nominapp.com/knowledge_base/topics/como-descargo-el-archivo-resumen-nomina">
    Resumen nómina
  </TooltipLink>
)

const provisionsLink2 = (
  <TooltipLink href="https://ayuda.nominapp.com/help/como-puedo-ver-los-calculos-de-las-colillas-de-pago-de-mis-empleados-que-genera-nominapp">
    Ver cálculos {'>'} Provisiones Prestaciones sociales
  </TooltipLink>
)

export default {
  provisions: (
    <>
      Para ver las provisiones por empleado, descarga el archivo de{' '}
      {provisionsLink1} y podrás revisar sus valores desde {provisionsLink2}.
    </>
  ),
  payroll_payments:
    'Incluye valor total de nómina y valor de las liquidaciones laborales realizadas durante el periodo.',
  social_security:
    'Aquí verás el valor de la seguridad social de los empleados activos durante el mes anterior.',
}
