import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import WageModal from 'components/Worker/Wage/Modal/WageModal'
import useWorker from 'components/Worker/useWorker'

import {
  formatDisplayDate,
  formatLocateDateString,
  getDaysOfWeekFromArray,
} from 'utils/dateTime'
import { formatCurrency, formatNumberWithDecimal } from 'utils/format'
import useContractsService from 'utils/hooks/worker/contracts'
import { contractCategories, contractTypes, wageCategories } from 'utils/worker'

import UpdateWorkerGeneralDataModal from '../Modals/UpdateGeneralDataModal/UpdateGeneralDataModal'
import useUpdateGeneralWorkerDataModal from '../Modals/UpdateGeneralDataModal/useUpdateGeneralDataModal'
import SectionContainer from '../SectionContainer'

const LaboralDataContent = () => {
  const [isWageModalOpen, setIsWageModalOpen] = useState(false)
  const { worker, refreshWorker, isFromAffiliationView } = useWorker({
    useCache: true,
  })
  const { isWorker } = useUser()
  const queryClient = useQueryClient()
  const {
    contract_category: contractCategory,
    contract_id: contractId,
    id: workerId,
  } = worker
  const { contractsQuery } = useContractsService({
    serviceParams: { queryKey: 'contractById', workerId, contractId },
    queryOptions: {
      enabled: !isWorker && Boolean(contractId),
    },
  })

  const {
    updateGeneralDataModalState,
    openGeneralDataModal,
    closeGeneralDataModal,
  } = useUpdateGeneralWorkerDataModal()

  const wageSubmitCallback = () => {
    queryClient.refetchQueries(['contractById', workerId, contractId])
    refreshWorker()
  }

  const sectionData = useMemo(() => {
    const data = []

    const salaryBase = {
      label: 'Salario base',
      value: wageCategories[worker.wage_category]
        ? wageCategories[worker.wage_category]
        : worker.wage_category,
    }
    const salaryQuantity = {
      label: 'Salario',
      value: formatCurrency(worker.base_salary),
    }
    const contractDate = {
      label: 'Fecha de ingreso',
      value: formatDisplayDate(worker.initial_day),
    }
    const healthProvider = {
      label: 'EPS',
      value: worker.health_provider?.name,
      hidden: [
        'contractor',
        'student_decree_055',
        'part_time_contract',
      ].includes(contractCategory),
    }
    const severanceProvider = {
      label: 'Fondo de cesantías',
      value: worker.severance_provider?.name,
      hidden:
        !['employee', 'pensioner', 'part_time_contract'].includes(
          contractCategory
        ) || worker.wage_category === 'integral_salary',
    }
    const arlProvider = {
      label: 'ARL',
      value: worker.risk_provider?.name,
    }
    const compensationProvider = {
      label: 'Caja de compensación familiar',
      value: worker.compensation_fund?.name,
    }
    const department = {
      label: 'Departamento donde labora',
      value: worker.department,
    }
    const city = {
      label: 'Ciudad donde labora',
      value: worker.city,
    }
    const pensionProvider = {
      label: 'Fondo de pensiones',
      value: worker.pension_provider?.name,
      hidden:
        !['employee', 'student_law_789', 'part_time_contract'].includes(
          contractCategory
        ) || ['pe_no_pension', 'ce_no_pension'].includes(worker.document_type),
    }
    const contractCategoryItem = {
      label: 'Tipo de contrato',
      value: contractCategories[contractCategory]
        ? contractCategories[contractCategory]
        : contractCategory,
    }
    const contractType = {
      label: 'Término de contrato',
      value: contractTypes[worker.term]
        ? contractTypes[worker.term]
        : worker.term,
    }
    const contractFinalDate = {
      label:
        worker.term === 'fixed'
          ? 'Fecha terminación del contrato'
          : 'Fecha estimada de finalización de la obra o labor',
      value: formatLocateDateString(worker.end_day),
      hidden: !['fixed', 'project'].includes(worker.term),
    }
    const accumulatedHolidays = {
      label: 'Vacaciones acumuladas',
      value: `${formatNumberWithDecimal(worker.accumulated_holidays, 1)} días`,
      hidden: [
        'contractor',
        'student',
        'apprentice',
        'terminated_worker',
      ].includes(contractCategory),
    }
    const workCenter = {
      label: 'Centro de trabajo - Nivel de riesgo',
      value: worker?.work_center?.name,
      hidden: ['contractor', 'schooling_trainee'].includes(contractCategory),
    }
    const highRiskPension = {
      label:
        '¿Es una persona de alto riesgo de acuerdo al decreto 2090 de 2003?',
      value: worker.high_risk_pension ? 'Si' : 'No',
      hidden:
        worker.high_risk_pension === null ||
        !worker.work_center?.risk_type?.includes('5'),
    }
    const transportSubsidy = {
      label: '¿Subsidio de transporte?',
      value: worker.transport_subsidy ? 'Sí' : 'No',
      hidden: worker.transport_subsidy === null,
    }
    const restDays = {
      label: 'Días de descanso',
      value: getDaysOfWeekFromArray(worker.rest_days),
      hidden: !['employee', 'pensioner'].includes(contractCategory),
    }
    const location = {
      label: 'Sede de Trabajo',
      value: worker.location?.name,
    }
    const costCenter = {
      label: 'Centro de Costos',
      value: worker.cost_center?.name,
    }
    const area = {
      label: 'Área',
      value: worker.area?.name,
    }
    const position = {
      label: 'Cargo',
      value: worker.position?.name,
    }
    if (isFromAffiliationView) {
      data.push({
        id: 'entities',
        title: 'Administradoras',
        onClick: () => setIsWageModalOpen(true),
        buttonCyLabel: 'edit_wage_button',
        containerCyLabel: 'wage_container',
        hiddenEdit: isFromAffiliationView,
        itemsList: [
          position,
          salaryQuantity,
          contractDate,
          healthProvider,
          pensionProvider,
          severanceProvider,
          arlProvider,
          compensationProvider,
          department,
          city,
        ],
      })
    } else {
      data.push(
        {
          id: 'moneySection',
          title: contractCategory === 'contractor' ? 'Honorarios' : 'Salario',
          onClick: () => setIsWageModalOpen(true),
          buttonCyLabel: 'edit_wage_button',
          containerCyLabel: 'wage_container',
          itemsList: [salaryQuantity, salaryBase],
        },
        {
          id: 'contractSection',
          title: 'Datos de contrato',
          onClick: () =>
            openGeneralDataModal('Datos de contrato', 'contract_data'),
          buttonCyLabel: 'edit_contract_button',
          containerCyLabel: 'contract_container',
          itemsList: [
            contractCategoryItem,
            contractType,
            contractDate,
            contractFinalDate,
            accumulatedHolidays,
            workCenter,
            highRiskPension,
            transportSubsidy,
            restDays,
          ],
        },
        {
          id: 'entitiesSection',
          title: 'Entidades',
          onClick: () => openGeneralDataModal('Entidades', 'entities'),
          buttonCyLabel: 'edit_entities_button',
          containerCyLabel: 'entities_container',
          hidden: ['contractor', 'student_decree_055'].includes(
            contractCategory
          ),
          itemsList: [healthProvider, severanceProvider, pensionProvider],
        },
        {
          id: 'jobPositionSection',
          title: 'Datos Puesto de Trabajo',
          onClick: () =>
            openGeneralDataModal(
              'Datos de Puesto de Trabajo',
              'job_position_data'
            ),
          buttonCyLabel: 'edit_job_position_button',
          containerCyLabel: 'job_position_container',

          itemsList: [location, costCenter, area, position],
        }
      )
    }
    return data
  }, [contractCategory, isFromAffiliationView, openGeneralDataModal, worker])

  return (
    <>
      {sectionData.map((item) => {
        if (!item?.hidden) {
          return (
            <SectionContainer
              item={item}
              key={item.id}
              containerCyLabel={item.containerCyLabel}
            />
          )
        }
        return null
      })}
      {updateGeneralDataModalState.open && (
        <UpdateWorkerGeneralDataModal
          onCancel={closeGeneralDataModal}
          state={updateGeneralDataModalState}
        />
      )}
      {isWageModalOpen && (
        <WageModal
          contract={contractsQuery?.data}
          canCreate
          isEdit
          onCancel={() => setIsWageModalOpen(false)}
          submitCallback={wageSubmitCallback}
        />
      )}
    </>
  )
}

export default LaboralDataContent
