import { useState } from 'react'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import Modal from 'components/UI/Modal/Modal'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import IntegrationNotActive from 'components/UI/Modal/IntegrationNotActive'

import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import { getMonthAndYear } from 'utils/dateTime'
import useNotifications from 'utils/hooks/useNotifications'

import logoDian from 'assets/images/logos/logo_dian.svg'

import {
  SETTINGS_ELECTRONIC_PAYROLL,
  PERIOD_PAY_ELECTRONIC_PAYROLL,
} from 'config/routes'

import useSendElectronicPayrolls from '../useSendElectronicPayrolls'
import { getStepContent, payrollsToSend } from './helpers'

const SendElectronicPayroll = ({ handleClose, periodId, endDay }) => {
  const [step, setStep] = useState(0)
  const [viewWorkers, setViewWorkers] = useState(false)
  const electronicPayrollPeriodIssuedQueryKey = [
    'electronicPayrollPeriod',
    periodId,
    'pending,rejected,modified',
  ]
  const history = useHistory()
  const { showWarningMessage } = useNotifications()

  const electronicPayrollMonth = getMonthAndYear(endDay)

  const {
    sendElectronicPayroll,
    resetStatus,
    statusStepOne,
    statusStepTwo,
  } = useSendElectronicPayrolls()

  const {
    electronicPayrollPeriodQuery: { data: electronicPayrollPeriod, isLoading },
  } = useElectronicPayrollPeriodService({
    serviceParams: {
      queryKey: electronicPayrollPeriodIssuedQueryKey,
      periodId,
      electronicPayrollStatuses: 'pending,rejected,modified',
    },
  })

  const statusCompleted =
    statusStepOne === 'completed' && statusStepTwo === 'completed'
      ? 'completed'
      : 'progress'

  const statusProcess =
    statusStepOne === 'error' || statusStepTwo === 'error'
      ? 'error'
      : statusCompleted

  // Get the content since the position step and put the data into object
  const dataSteps = {
    electronicPayrollPeriod,
    setStep,
    periodId,
    handleClose,
    logoDian,
    statusProcess,
    statusStepOne,
    statusStepTwo,
    viewWorkers,
    setViewWorkers,
  }

  const stepDetails = getStepContent(dataSteps)

  const isActiveElectronicPayroll = electronicPayrollPeriod?.active

  const handleSubmit = (currentValues) => {
    const dataToSend = !viewWorkers
      ? currentValues.map((electronic) => ({
          ...electronic,
          selection: true,
        }))
      : currentValues

    const {
      electronic_payroll_ids: electronicPayrollIds,
      electronicPayrollToEmit,
    } = payrollsToSend(dataToSend)

    if (electronicPayrollToEmit) {
      resetStatus()
      if (!isActiveElectronicPayroll) {
        history.push(SETTINGS_ELECTRONIC_PAYROLL())
      } else if (step === 0 || statusProcess === 'error') {
        setStep(1)
        sendElectronicPayroll(
          electronicPayrollIds,
          periodId,
          electronicPayrollMonth
        )
      }
    } else {
      showWarningMessage(
        'Debes seleccionar por lo menos una persona para hacer la emisión de nómina electrónica',
        {
          key: 'electronicPayrollWarning',
        }
      )
    }
  }

  if (isLoading) return <LoadingModal />

  const onOkStep = isActiveElectronicPayroll
    ? stepDetails[step].okText
    : 'Iniciar habilitación'

  return (
    <Formik
      initialValues={electronicPayrollPeriod?.electronic_payrolls}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Form>
            <Modal
              open
              onOk={
                statusCompleted === 'completed'
                  ? () => history.push(PERIOD_PAY_ELECTRONIC_PAYROLL(periodId))
                  : onSubmit
              }
              okText={
                statusProcess === 'error' ? 'Intentar de nuevo' : onOkStep
              }
              onCloseModal={handleClose}
              onCancel={handleClose}
              cancelText={stepDetails[step].cancelText}
              isLoading={step === 1 && statusProcess === 'progress'}
              alignButtons={step === 0 ? 'center' : 'right'}
              dialogProps={{ maxWidth: false }}
            >
              {isActiveElectronicPayroll ? (
                stepDetails[step].content
              ) : (
                <IntegrationNotActive
                  title="Parece que aún no has habilitado la nómina electrónica en Nominapp"
                  description="Desde Nominapp puedes realizar tu proceso de habilitación y configuración de nómina electrónica ante la DIAN de una manera rápida y sin enredos ni complicaciones."
                  logo={logoDian}
                  altLogo="Logo Dian"
                />
              )}
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SendElectronicPayroll
