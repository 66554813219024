import { useMemo } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import SlideShow from 'components/UI/SlideShow/Index'

import { isArusDist } from 'utils/auth'

import payrollStep from 'assets/images/views/common/payroll_step.png'
import electronicPayrollStep from 'assets/images/views/common/electronic_payroll_step.png'
import reportStep from 'assets/images/views/common/reports_step.png'
import payrollArusStep from 'assets/images/views/common/arus/payroll_step.png'
import electronicPayrollArusStep from 'assets/images/views/common/arus/electronic_payroll_step.png'
import reportArusStep from 'assets/images/views/common/arus/reports_step.png'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    whiteSpace: 'normal',
    width: '100%',
    userSelect: 'none',
  },
  image: {
    marginBottom: theme.spacing(5),
  },
  descriptionContainer: {
    maxWidth: 650,
    textAlign: 'center',
  },
}))

const Slide = ({ src, title, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.slide}>
      <img
        src={src}
        alt={title}
        width={457}
        height={410}
        className={classes.image}
      />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <div className={classes.descriptionContainer}>
        <Typography variant="body1">{description}</Typography>
      </div>
    </div>
  )
}

const AuthSlideShow = () => {
  const classes = useStyles()

  const getSlidesImage = () => {
    let stepOne = payrollStep
    let stepTwo = electronicPayrollStep
    let stepThree = reportStep

    if (isArusDist()) {
      stepOne = payrollArusStep
      stepTwo = electronicPayrollArusStep
      stepThree = reportArusStep
    }

    return {
      stepOne,
      stepTwo,
      stepThree,
    }
  }

  const stepsImages = getSlidesImage()

  const slides = useMemo(
    () => [
      {
        id: 'payroll_step',
        slide: (
          <Slide
            src={stepsImages.stepOne}
            title="Liquida tu nómina en tiempo récord"
            description="Ingresa las novedades y Nominapp hace todos los cálculos por ti de forma automática y sin errores."
          />
        ),
      },
      {
        id: 'electronic_payroll_step',
        slide: (
          <Slide
            src={stepsImages.stepTwo}
            title="Kit de nómina electrónica sin costo"
            description="Habilitación, emisión, firma digital y capacitación para que le cumplas a la DIAN y te evites dolores de cabeza."
          />
        ),
      },
      {
        id: 'report_step',
        slide: (
          <Slide
            src={stepsImages.stepThree}
            title="Automatiza la gestión de tu nómina"
            description="Colillas de pago, integración con tu software contable, archivo de pago de nómina y seguridad social, reportes, notificaciones de fechas importantes y mucho más."
          />
        ),
      },
    ],
    [stepsImages.stepOne, stepsImages.stepThree, stepsImages.stepTwo]
  )

  return (
    <div className={classes.container}>
      <SlideShow slides={slides} delay="6000" />
    </div>
  )
}

export default AuthSlideShow
