import { useContext } from 'react'

import Button from '@material-ui/core/Button'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'

import { PeriodContext } from '../../Payroll'
import PayrollAdvanceModal from './Modals/PayrollAdvancesModal'
import usePayrollsAdvancesModal from './Modals/usePayrollsAdvancesModal'

const PayPayrollAdvancesMenu = () => {
  const {
    period: { payrolls_advance_value: payrollAdvanceValue },
  } = useContext(PeriodContext)

  const {
    payrollsAdvancesModalState,
    openPayrollsAdvancesModal,
    closePayrollsAdvancesModal,
  } = usePayrollsAdvancesModal()

  return (
    <>
      {payrollAdvanceValue && (
        <Button
          variant="text"
          startIcon={<MonetizationOnOutlinedIcon />}
          onClick={openPayrollsAdvancesModal}
        >
          Pagar adelantos de nómina
        </Button>
      )}
      {payrollsAdvancesModalState.open && (
        <PayrollAdvanceModal
          onClose={closePayrollsAdvancesModal}
          payrollAdvanceValue={payrollAdvanceValue}
        />
      )}
    </>
  )
}
export default PayPayrollAdvancesMenu
