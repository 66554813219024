import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { wageCategories } from 'utils/worker'

export const columnsData = [
  {
    Header: '#',
    accessor: 'number',
    customWidth: '100px',
  },
  {
    Header: 'Salario base',
    accessor: 'base_salary',
    customMinWidth: '150px',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
  },
  {
    Header: 'Tipo',
    accessor: 'category',
    Cell: ({ row }) =>
      wageCategories[row.original.category]
        ? wageCategories[row.original.category]
        : row.original.category,
  },
  {
    Header: 'Fecha Inicial',
    accessor: 'initial_day',
    Cell: ({ row }) => formatDisplayDate(row.original.initial_day),
  },
  {
    Header: 'Fecha Final',
    accessor: 'end_day',
    Cell: ({ row }) => formatDisplayDate(row.original.end_day) || 'No aplica',
  },
]

export const getOrganizerActions = (organizerDeleteWage, confirm) => {
  if (isOrganizer())
    return [
      {
        id: 'organizer_delete_salary',
        icon: DeleteForeverIcon,
        tooltip: 'Eliminar salario',
        hidden: !isOrganizer(),
        onClick: (rowData) => {
          confirm({
            type: 'warning',
            title: 'Eliminar salario',
            description:
              'Eliminar este salario es permanente y no se podrá deshacer. ¿Estás seguro?',
            okText: 'Eliminar',
            confirmCheckbox: true,
            onOk: () => organizerDeleteWage(rowData.id),
          })
        },
      },
    ]
  return []
}
