import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import CachedIcon from '@material-ui/icons/Cached'

import Page from 'components/UI/Page/Page'
import Loading from 'components/UI/Loading/Loading'

import { formatCurrency } from 'utils/format'

import logoAvista from 'assets/images/logos/avista/logo_avista.svg'

import { WORKER_PROFILE_BENEFITS } from 'config/routes'

import CreditSummary from './CreditSummary'
import CreditValueInput from './CreditValueInput'
import { useCreditApplication } from '../../Form/hooks'
import {
  getValidationSchema,
  isValidCreditValue,
  simulatorInitialValues,
  useAvistaCreditSimulation,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content',
    height: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto',
    },
  },
  message: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
  },
  calculatorCard: {
    padding: theme.spacing(6, 3, 5, 3),
    marginBottom: theme.spacing(2),
  },
  confirmTitle: {
    marginBottom: theme.spacing(1.5),
  },
  confirmMessage: {
    marginBottom: theme.spacing(4.5),
  },
  calculateFeeContainer: {
    display: 'flex',
    marginTop: theme.spacing(5),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  termsMessage: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
  termsLink: {
    color: '#A60364',
  },
}))

const CreditSimulator = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    simulationData,
    isCalculatingFee,
    getCreditFeeValue,
    creditConditions,
    isSimulationValid,
    initialSimulationFee,
  } = useAvistaCreditSimulation()

  const { applicationData } = useCreditApplication({
    onSuccess: ({ data }) => {
      if (data?.current_step === 5) {
        history.replace(WORKER_PROFILE_BENEFITS())
      }
    },
  })

  const numberFeesFromApplication = applicationData?.credit_simulator.max_term

  const calculateCreditFee = (currentValues, formikHelpers) => {
    getCreditFeeValue(currentValues, {
      setFieldError: formikHelpers.setFieldError,
    })
  }

  if (applicationData?.current_step < 5) {
    const preApprovedValue = applicationData.credit_simulator.preapproved_value

    let initialValues = {
      ...simulatorInitialValues,
    }
    if (
      simulatorInitialValues.number_fees === null &&
      numberFeesFromApplication
    ) {
      initialValues = {
        ...initialValues,
        number_fees: numberFeesFromApplication,
      }
    }
    if (simulatorInitialValues.credit_value === null && preApprovedValue > 0) {
      initialValues = {
        ...initialValues,
        credit_value: preApprovedValue,
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={calculateCreditFee}
        validationSchema={getValidationSchema(
          1000000,
          preApprovedValue,
          numberFeesFromApplication
        )}
        enableReinitialize
      >
        {({ values, handleSubmit }) => {
          return (
            <Form className={classes.container}>
              <Page
                documentTitle="Simulación crédito de libranza"
                header="Simula tu crédito de libranza"
              >
                <Typography variant="body1" className={classes.message}>
                  ¡Felicidades! Has sido seleccionado como beneficiario para
                  solicitar un crédito que será descontado de tu nómina de forma
                  automática. Aquí podrás ajustar el valor del crédito y simular
                  sus condiciones antes de realizar tu solicitud.
                </Typography>
                <Card className={classes.calculatorCard}>
                  <Typography variant="h5" className={classes.confirmTitle}>
                    Confirma el valor de tu crédito
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.confirmMessage}
                  >
                    Este simulador te servirá para entender tu oportunidad de
                    crédito y actualizar el monto de tu solicitud. El valor de
                    la cuota será descontado en cada periodo de nómina.
                  </Typography>
                  <CreditValueInput
                    feeValue={formatCurrency(
                      simulationData?.data?.fee || initialSimulationFee || 0
                    )}
                    isValidFeeValue={isValidCreditValue(
                      values?.credit_value,
                      preApprovedValue
                    )}
                    maximumCreditValue={formatCurrency(preApprovedValue)}
                    numberFee={numberFeesFromApplication}
                  />
                  <div className={classes.calculateFeeContainer}>
                    <Button
                      variant="outlined"
                      startIcon={<CachedIcon />}
                      disabled={isCalculatingFee}
                      onClick={handleSubmit}
                    >
                      Calcular crédito
                    </Button>
                  </div>
                </Card>
                <div className={classes.termsContainer}>
                  <img
                    src={logoAvista}
                    width={155}
                    height={75}
                    alt="Avista logo"
                  />
                  <Typography
                    variant="caption"
                    className={classes.termsMessage}
                  >
                    {`La oferta de crédito de libranza es presentada por AVISTA SAS. Para
                      realizar tu solicitud de crédito debes aceptar los `}
                    <Link
                      href="https://avista.co/politicas/index.html"
                      target="_blank"
                      className={classes.termsLink}
                      rel="noreferrer"
                    >
                      términos y condiciones de AVISTA SAS.
                    </Link>
                  </Typography>
                </div>
              </Page>
              <CreditSummary
                creditConditions={creditConditions}
                isSimulationValid={isSimulationValid}
              />
            </Form>
          )
        }}
      </Formik>
    )
  }

  return <Loading />
}

export default CreditSimulator
