import clsx from 'clsx'

import { Collapse } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 20,
    boxShadow: theme.shadows[3],
    maxWidth: 400,
    width: '100%',
    padding: theme.spacing(4, 3, 0, 3),
    backgroundColor: theme.palette.common.white,
  },
  essentialContainer: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up(800)]: {
      justifySelf: 'end',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  essentialTitle: {
    color: theme.palette.common.white,
  },
  featureIcon: {
    color: theme.palette.primary.main,
    width: 18,
    height: 18,
    marginRight: theme.spacing(1.25),
  },
  featureContainer: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  divider: {
    width: '100%',
    height: 0,
    border: `1px dashed ${theme.palette.grey[500]}`,
    alignSelf: 'center',
    opacity: 0.3,
  },
  essentialDivider: {
    borderColor: `${theme.palette.grey[100]}`,
  },
  priceBadge: {
    backgroundColor: theme.palette.primary.lighter,
    height: 40,
    borderRadius: 30,
    marginBottom: theme.spacing(5),
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  essentialPriceBadge: {
    backgroundColor: `${theme.palette.common.white}20`,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  essentialButton: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}80`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'start',
  },
  titleWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  wrapperInner: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows:
      'minmax(60px, auto) minmax(75px, auto) minmax(80px, auto) 48px minmax(228px, auto) auto',
  },
  adminDescription: {
    alignSelf: 'end',
  },
  detailContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows:
      'repeat(2, minmax(40px, auto)) minmax(485px, auto) minmax(40px, auto) minmax(216px, auto) minmax(40px, auto) minmax(265px, auto)',
    [theme.breakpoints.down('xs')]: {
      gridTemplateRows:
        'repeat(2, minmax(40px, auto)) minmax(544px, auto) minmax(40px, auto) minmax(237px, auto) minmax(40px, auto) minmax(265px, auto)',
    },
  },
}))

const PlanCard = ({
  name,
  description,
  adminDescription,
  baseValue,
  workerValue,
  onSelect,
  isEssentialPlan,
  isFreePlan,
  icon,
  selected,
  expanded,
  features,
}) => {
  const classes = useStyles()

  return (
    <Collapse
      in={expanded}
      collapsedHeight={520}
      component={Paper}
      className={clsx(classes.container, {
        [classes.essentialContainer]: isEssentialPlan,
      })}
      classes={{
        wrapperInner: classes.wrapperInner,
      }}
    >
      <div className={classes.titleContainer}>
        <div className={classes.titleWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={clsx(classes.title, {
              [classes.essentialTitle]: isEssentialPlan,
            })}
          >
            {name}
          </Typography>
          {selected ? <Chip label="Actual" /> : null}
          {isEssentialPlan && !selected ? <Chip label="Recomendado" /> : null}
        </div>
        {icon}
      </div>
      <Typography
        variant="body1"
        className={clsx(classes.description, {
          [classes.whiteText]: isEssentialPlan,
        })}
      >
        {description}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.adminDescription, {
          [classes.whiteText]: isEssentialPlan,
        })}
      >
        <b>Usuarios administradores:</b> {adminDescription}
      </Typography>
      <Divider
        className={clsx(classes.divider, {
          [classes.essentialDivider]: isEssentialPlan,
        })}
      />
      <div>
        <Typography
          variant="h4"
          color="inherit"
          className={clsx({
            [classes.whiteText]: isEssentialPlan,
          })}
          gutterBottom
        >
          {`${baseValue} / Mes`}
        </Typography>
        <Chip
          label={`+ ${workerValue} / Persona`}
          className={clsx(classes.priceBadge, {
            [classes.essentialPriceBadge]: isEssentialPlan,
          })}
        />
        {!isFreePlan ? (
          <Button
            fullWidth
            size="large"
            className={clsx({
              [classes.essentialButton]: isEssentialPlan,
            })}
            onClick={onSelect}
          >
            Mejorar mi plan
          </Button>
        ) : null}
      </div>
      <div className={classes.detailContainer}>
        <Typography
          variant="subtitle1"
          color="inherit"
          className={clsx({
            [classes.whiteText]: isEssentialPlan,
          })}
        >
          Detalles del plan:
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          className={clsx({
            [classes.whiteText]: isEssentialPlan,
          })}
        >
          Nómina:
        </Typography>
        <div>
          {features.payroll.map((feature) => {
            return (
              <div key={feature} className={classes.featureContainer}>
                <CheckCircleIcon
                  className={clsx(classes.featureIcon, {
                    [classes.whiteText]: isEssentialPlan,
                  })}
                />
                <Typography
                  variant="body2"
                  className={clsx({
                    [classes.whiteText]: isEssentialPlan,
                  })}
                >
                  {feature}
                </Typography>
              </div>
            )
          })}
        </div>
        <Typography
          variant="subtitle2"
          color="primary"
          className={clsx({
            [classes.whiteText]: isEssentialPlan,
          })}
        >
          Recursos Humanos:
        </Typography>
        <div>
          {features.humanResources.map((feature) => {
            return (
              <div key={feature} className={classes.featureContainer}>
                <CheckCircleIcon
                  className={clsx(classes.featureIcon, {
                    [classes.whiteText]: isEssentialPlan,
                  })}
                />
                <Typography
                  variant="body2"
                  className={clsx({
                    [classes.whiteText]: isEssentialPlan,
                  })}
                >
                  {feature}
                </Typography>
              </div>
            )
          })}
        </div>
        <Typography
          variant="subtitle2"
          color="primary"
          className={clsx({
            [classes.whiteText]: isEssentialPlan,
          })}
        >
          Soporte:
        </Typography>
        <div>
          {features.support.map((feature) => {
            return (
              <div key={feature} className={classes.featureContainer}>
                <CheckCircleIcon
                  className={clsx(classes.featureIcon, {
                    [classes.whiteText]: isEssentialPlan,
                  })}
                />
                <Typography
                  variant="body2"
                  className={clsx({
                    [classes.whiteText]: isEssentialPlan,
                  })}
                >
                  {feature}
                </Typography>
              </div>
            )
          })}
        </div>
      </div>
    </Collapse>
  )
}

export default PlanCard
