import { SplitFactory } from '@splitsoftware/splitio-react'

const sdkConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_API_KEY,
    key: 'anonymous',
  },
}

const SplitProvider = ({ children }) => {
  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate updateOnSdkReady>
      {children}
    </SplitFactory>
  )
}

export default SplitProvider
