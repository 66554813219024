import http, { getApiURL } from '../httpService'

export const createFreeTimeRequest = (workerId, freeTimeRequest) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/novelty_requests`,
    withoutCompany: true,
  })

  return http.postV2(url, freeTimeRequest)
}

export const getFreeTimeRequest = ({ workerId, filter, search, page } = {}) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/novelty_requests`,
    searchParams: { page, search, filter },
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const deleteFreeTimeRequest = (freeTimeRequest) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${freeTimeRequest.worker_id}/novelty_requests/${freeTimeRequest.id}`,
    withoutCompany: true,
  })

  return http.deleteV2(url)
}

export const updateFreeTimeRequest = (workerId, freeTimeRequest, noveltyId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/novelty_requests/${noveltyId}`,
    withoutCompany: true,
  })

  return http.putV2(url, freeTimeRequest)
}

export const getPayrollConcepts = (workerId) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/payroll_concepts`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export default {
  createFreeTimeRequest,
  getFreeTimeRequest,
  deleteFreeTimeRequest,
  updateFreeTimeRequest,
  getPayrollConcepts,
}
