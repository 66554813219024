import clsx from 'clsx'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(() => ({
  button: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },
  icon: {
    transform: 'rotate(0deg)',
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
}))

const ExpandButton = ({ expanded, ...props }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={clsx(classes.button, {
        [classes.iconExpanded]: expanded,
      })}
      size="small"
      {...props}
    >
      <ExpandMoreIcon />
    </IconButton>
  )
}

export default ExpandButton
