import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../../httpService'

export const getAllPayments = (partnerId, { search, page } = {}) => {
  const url = getApiURL({
    withoutCompany: true,
    pathname: `/${partnerId}/payments`,
    searchParams: { search, page },
  })

  const response = http.getV2(url)
  return response
}

export const getPartnerInformation = (partnerId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/partners/${partnerId}/payment_info`,
  })

  return http.getV2(url)
}

export const retryCreditCardPayment = () => {
  const companyId = getCompanyId()
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/credit_card_payment`,
  })

  return http.postV2(url)
}

export const createPayment = (partnerId, company) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/partners/${partnerId}/payments`,
  })

  return http.putV2(url, { company })
}

export default {
  getAllPayments,
  retryCreditCardPayment,
  getPartnerInformation,
  createPayment,
}
