import { numberOfEmployeesOptions } from 'utils/onboarding'

import FormField from '../FormField/Index'

const NumberOfEmployeesField = ({ isAccountant = false }) => (
  <FormField
    data-cy="number-employees-select"
    label={`Número de empleados${
      isAccountant ? ' que suman todos tus clientes' : ''
    }`}
    name="workers_number"
    variant="select"
    options={numberOfEmployeesOptions}
  />
)

export default NumberOfEmployeesField
