import useModals from 'components/App/ModalsManager/useModals'
import { useHistory, useLocation } from 'react-router-dom'

import { ModalExtraWorker, Header } from './ModalExtraWorker'

const useModalExtraWorker = () => {
  const modals = useModals()
  const history = useHistory()
  const location = useLocation()

  const handleClose = () => {
    modals.closeModal()
    if (location.pathname === '/workers/new') {
      history.goBack()
    }
  }

  const openModal = () => {
    modals.openModal({
      id: 'extraWorker',
      content: <ModalExtraWorker onClose={handleClose} />,
      modalProps: {
        header: <Header />,
        onCloseModal: handleClose,
        hideFooter: true,
        disableClickOutsideModal: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  return { openModal }
}

export default useModalExtraWorker
