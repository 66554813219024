import format from 'date-fns/format'
import esLocale from 'date-fns/locale/es'
import parseISO from 'date-fns/parseISO'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import cyan from '@material-ui/core/colors/cyan'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

const dataset = {
  label: 'Costo Nómina',
  fill: true,
  lineTension: 0.2,
  backgroundColor: cyan[100],
  borderColor: cyan[500],
  borderJoinStyle: 'round',
  pointBorderColor: '#fff',
  pointBackgroundColor: cyan[500],
  pointBorderWidth: 1,
  pointHoverRadius: 10,
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 5,
}

const DashboardPayollCosts = () => {
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'payroll_costs', getCompanyId()]
  const { data: payrollCostCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'payroll_costs',
    },
    queryOptions: {
      enabled: !isDataCached(payrollCostCache),
    },
  })
  const { payroll_costs: payrollCosts } = payrollCostCache || {}

  const chartData = useMemo(() => {
    if (!payrollCosts) return { labels: [], datasets: [] }

    const data = []
    const labels = []

    // reversed traverse
    for (let i = payrollCosts.length - 1; i >= 0; i -= 1) {
      const el = payrollCosts[i]

      data.push(el.value)

      const displayDate = format(parseISO(el.date), 'MMMM yyyy', {
        locale: esLocale,
      })

      labels.push(displayDate)
    }

    return {
      labels,
      datasets: [{ ...dataset, data }],
    }
  }, [payrollCosts])

  return (
    <Box marginBottom={4} marginTop={4}>
      <Typography variant="subtitle1" gutterBottom>
        Balance de nómina
      </Typography>
      {dashboardQuery?.isLoading ? (
        <LoadingBox />
      ) : (
        <Box p={4} component={Paper}>
          <Line
            data={chartData}
            options={{
              scales: {
                xAxes: [
                  {
                    offset: true,
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      suggestedMin: 0,
                      callback: (value) => formatCurrency(value),
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    let label =
                      data.datasets[tooltipItem.datasetIndex].label || ''

                    if (label) {
                      label += ': '
                    }

                    label += formatCurrency(
                      Math.round(tooltipItem.yLabel * 100) / 100
                    )
                    return label
                  },
                },
              },
              legend: {
                display: false,
              },
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default DashboardPayollCosts
