import { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import FormField from '../FormField/Index'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    color: theme.palette.grey[500],
    '&:focus': {
      border: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.grey[500],
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const PasswordField = ({ name, label = 'Contraseña', ...props }) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const handleOnClick = () => setShowPassword((previousValue) => !previousValue)

  return (
    <FormField
      name={name}
      label={label}
      data-cy={name}
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={handleOnClick}
            edge="end"
            size="small"
            className={classes.button}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default PasswordField
