/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import { IconButton, Paper, Typography, makeStyles } from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import MoreVertIcon from '@material-ui/icons/MoreVertRounded'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'

import { useUser } from 'components/App/UserContext/useUser'
import DropdownButton from 'components/UI/Button/Dropdown'
import LoadingModal from 'components/UI/Loading/LoadingModal'

import { formatNumberDisplayDate } from 'utils/dateTime'
import useDocumentsService from 'utils/hooks/worker/documents'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import { FoldersContainerContext } from './Index'
import { foldersActions, validateIsAffiliationFolder } from './helpers'

const useStyles = makeStyles((theme) => ({
  backgroundAffiliation: {
    backgroundColor: theme.palette.primary.light,
  },
  backgroundGeneral: {
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    borderRadius: theme.spacing(2),
    color: theme.palette.common.white,
    display: 'flex',
    padding: theme.spacing(2.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2),
    [theme.breakpoints.down(1024)]: {
      justifyContent: 'space-between',
    },
  },
  cardItem: {
    width: 224,
    height: 192,
    flexDirection: 'column',
  },
  iconContainer: {
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 6,
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  topContentList: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up(1024)]: {
      width: '40%',
    },
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  metaContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-end',
    '& > svg': {
      fontSize: 18,
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: theme.spacing(1),
    [theme.breakpoints.up(1024)]: {
      alignItems: 'center',
      width: '60%',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
    '& .MuiIconButton-root + .MuiIconButton-root': {
      marginLeft: 0,
    },
  },
  checkbox: {
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    width: 'initial',
    height: 'initial',
    padding: 0,
    marginRight: theme.spacing(0.5),
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.common.white,
    },
  },
  iconButton: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    width: 'initial',
    height: 'initial',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      border: 'none',
    },
  },
  iconButtonList: {
    border: '2px solid',
    width: 40,
    height: 40,
    '&:focus': {
      border: '2px solid',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(1024)]: {
      justifyContent: 'start',
    },
  },
}))

const FolderCard = ({ showAsCard, card }) => {
  const classes = useStyles()
  const { setOpenedFolder } = useContext(DocumentsContext)
  const isFolderAffiliation = validateIsAffiliationFolder(card.name)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { setActionModal } = useContext(FoldersContainerContext)
  const { documentsMutation } = useDocumentsService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleOpenFolder = () => setOpenedFolder(card)

  const optionsFiltered = () => {
    const options = [
      {
        id: foldersActions.change_folder_name.id,
        name: foldersActions.change_folder_name.callToAction,
        onClick: () => {
          setActionModal({
            type: foldersActions.change_folder_name.id,
            context: card,
          })
          popupState.close()
        },
      },
    ]

    if (card.deleteable) {
      options.push({
        id: foldersActions.delete_folder.id,
        name: foldersActions.delete_folder.callToAction,
        onClick: () => {
          setActionModal({
            type: foldersActions.delete_folder.id,
            context: card,
          })
          popupState.close()
        },
      })
    }

    return options
  }

  return (
    <>
      <Paper
        elevation={2}
        className={clsx(classes.card, {
          [classes.listItem]: !showAsCard,
          [classes.cardItem]: showAsCard,
          [classes.backgroundAffiliation]: isFolderAffiliation,
          [classes.backgroundGeneral]: !isFolderAffiliation,
        })}
        onClick={handleOpenFolder}
        role="button"
      >
        {showAsCard ? (
          <div className={classes.content}>
            <div className={classes.topContent}>
              <div className={classes.topContainer}>
                <div className={classes.iconContainer}>
                  <FolderOpenIcon />
                </div>
                {!isWorker && !isFolderAffiliation && (
                  <div
                    className={classes.actionsContainer}
                    // This is required to avoid the card's onClick method to be called when using the actions
                    onClick={(event) => event.stopPropagation()}
                  >
                    {/* <Checkbox
                        className={classes.checkbox}
                        onClick={onSelectFile}
                        checked={selectedFiles.includes(card.id)}
                        value={selectedFiles.includes(card.id)}
                      /> */}
                    <DropdownButton
                      popupState={popupState}
                      variant="text"
                      color="default"
                      options={optionsFiltered()}
                      className={classes.dropDown}
                      iconButton
                      Icon={
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          {...bindTrigger(popupState)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                    />
                  </div>
                )}
              </div>
              <Typography variant="subtitle1" align="left">
                {card.name}
              </Typography>
            </div>
            <div className={classes.bottomContent}>
              <div className={classes.metaContainer}>
                <FolderOpenIcon />
                <Typography variant="caption">{`${card.total_files} Archivos`}</Typography>
              </div>
              <div className={classes.metaContainer}>
                <QueryBuilderIcon />
                <Typography variant="caption">{`Creada el ${formatNumberDisplayDate(
                  card.created_at
                )}`}</Typography>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.topContentList}>
              {/* <Checkbox
                    className={clsx(classes.checkbox, classes.checkboxList)}
                    onClick={onSelectFile}
                    checked={selectedFiles.includes(card.id)}
                  /> */}
              <div className={classes.iconContainer}>
                <FolderOpenIcon />
              </div>
              <Typography variant="subtitle1" align="left">
                {card.name}
              </Typography>
            </div>
            <div className={classes.rightContent}>
              <div className={classes.metaContainer}>
                <FolderOpenIcon />
                <Typography variant="caption">{`${card.total_files} Archivos`}</Typography>
              </div>
              <div className={classes.metaContainer}>
                <QueryBuilderIcon />
                <Typography variant="caption">{`Creada el ${formatNumberDisplayDate(
                  card.created_at
                )}`}</Typography>
              </div>
              {!isWorker && !isFolderAffiliation && (
                <div
                  className={classes.actions}
                  onClick={(event) => event.stopPropagation()}
                >
                  <IconButton
                    className={clsx(classes.iconButton, classes.iconButtonList)}
                    onClick={(event) => {
                      event.stopPropagation()
                      setActionModal({
                        type: foldersActions.change_folder_name.id,
                        context: card,
                      })
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                  {card?.deleteable && (
                    <IconButton
                      className={clsx(
                        classes.iconButton,
                        classes.iconButtonList
                      )}
                      onClick={(event) => {
                        event.stopPropagation()
                        setActionModal({
                          type: foldersActions.delete_folder.id,
                          context: card,
                        })
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </Paper>
      {documentsMutation.isLoading ? <LoadingModal /> : null}
    </>
  )
}

export default FolderCard
