import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import PayrollSummaryFileModal from 'components/Period/Payroll/Modals/PayrollSummaryFileModal'
import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import Table from 'components/UI/Table/Table'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import { getGeneralContractCategory } from 'utils/worker'

import { PeriodContext } from '../Payroll'
import ActionButton from './ActionButton'
import PayrollToolbar from './Toolbar'
import { getColumnsData } from './columnsData'
import useTableActions from './useTableActions'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  headerText: {
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
}))

const PayrollDataTable = ({ payrolls }) => {
  const classes = useStyles()
  const { period } = useContext(PeriodContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const featureState = usePremiumFeature('summaryReport')
  const { subscriptionMutation } = useSubscriptionService()

  const crmEvent = 'downloaded_payroll_summary_file'

  const handlePremiumReport = useCallback(() => {
    if (featureState.isPremiumFeature) {
      featureState.openModal('summaryReport')
      subscriptionMutation.mutate({
        mutationMethod: 'GET',
        crmEvent,
      })
    } else {
      setIsModalOpen(true)
    }
    return null
  }, [featureState, subscriptionMutation])

  const tableActions = useTableActions(handlePremiumReport)
  // #region STATE
  const [activeTab, setActiveTab] = useState(() => {
    let response
    Object.keys(period.contracts_counter).some((key) => {
      // return only first key with contracts
      if (period.contracts_counter[key] > 0) {
        response = key
        return true
      }
      return false
    })
    return response
  })

  const [isWorkedHours, setIsWorkedHours] = useState(false)
  const [filteredPayrolls, setFilteredPayrolls] = useState([])
  // #endregion

  // #region EFFECT
  useEffect(() => {
    const numWorkedHours = payrolls.find(
      (payroll) => payroll.salary_category === 'per_hour'
    )
    setIsWorkedHours(numWorkedHours !== undefined)

    const filteredPayrollsRes = payrolls.filter(
      (payroll) =>
        getGeneralContractCategory(payroll.contract_category) === activeTab
    )

    setFilteredPayrolls(filteredPayrollsRes)
  }, [payrolls, activeTab])
  // #endregion

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const customSort = useMemo(() => {
    const sortFunc = (rowA, rowB) => {
      const totalIncomeA =
        rowA.original.salary_income + rowA.original.non_salary_income
      const totalIncomeB =
        rowB.original.salary_income + rowB.original.non_salary_income

      return totalIncomeA > totalIncomeB ? -1 : 1
    }
    return sortFunc
  }, [])

  const columnsData = useMemo(
    () => getColumnsData(activeTab, isWorkedHours, customSort, classes),
    [activeTab, classes, customSort, isWorkedHours]
  )
  const { contracts_counter: contractsCounter } = period

  const Toolbar = PayrollToolbar(activeTab, handleChangeTab, contractsCounter)

  return (
    <>
      <Table
        data={filteredPayrolls}
        columns={columnsData}
        options={{
          hiddenColumns: ['worker_id_number'],
          rowsSize: 50,
          emptyState: {
            description:
              'Para liquidar tu primera nómina debes agregar al menos una persona.',
            title: 'No has agregado personas',
            callToAction: <ButtonAddWorker />,
          },
        }}
        actions={tableActions}
        components={{
          Toolbar,
          ActionButton,
        }}
      />
      {isModalOpen && (
        <PayrollSummaryFileModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          period={period}
        />
      )}
    </>
  )
}

export default PayrollDataTable
