import { Typography, makeStyles } from '@material-ui/core'

import GoBackLink from 'components/UI/LinkNav/GoBackLink'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(2),
    fontWeight: 'normal',
    '& > span': {
      fontWeight: 'bold',
    },
    '& > img': {
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(-1),
    },
  },
}))

const Header = ({
  showWorkers,
  isOnlyWorkers,
  integrationProvider,
  setShowWorkers,
}) => {
  const classes = useStyles()

  if (showWorkers && !isOnlyWorkers) {
    return (
      <GoBackLink
        component="button"
        onClick={() => setShowWorkers(false)}
        className={classes.link}
      >
        Regresar
      </GoBackLink>
    )
  }

  if (isOnlyWorkers) {
    return (
      <Typography variant="h5" className={classes.title}>
        Personas para pago de seguridad social en{' '}
        <span>{integrationProvider.name}</span>
        <img
          src={integrationProvider.logo}
          alt={`Isotipo de ${integrationProvider.name}`}
          width={integrationProvider.logoWidth}
          height={integrationProvider.logoHeight}
        />
      </Typography>
    )
  }

  return (
    <Typography variant="h5" className={classes.title}>
      Vamos a pagar tu seguridad social con{' '}
      <span>{integrationProvider.name}</span>
      <img
        src={integrationProvider.logo}
        alt={`Isotipo de ${integrationProvider.name}`}
        width={integrationProvider.logoWidth}
        height={integrationProvider.logoHeight}
      />
    </Typography>
  )
}

export default Header
