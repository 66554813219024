import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { createMuiTheme } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale'

import roboto from './fonts/roboto'
import poppins from './fonts/poppins'
import typographyStyles from './typography'
import { colors, getAlphaHexValue } from './colors'
import elevation, { shadows, paper } from './elevation'

/**
 *
 * @param {('nominapp' | 'alegra' | 'arus')} name The name of the theme to generate.
 */
const generateTheme = (name = 'nominapp') => {
  const {
    BLACK,
    WHITE,
    PRIMARY,
    SECONDARY,
    ERROR,
    WARNING,
    INFO,
    SUCCESS,
    GREY,
    COMPLEMENTARY,
  } = colors[name]

  // this base theme is to generate the tone colors variations
  // to be used in the theme
  const baseTheme = createMuiTheme({
    palette: {
      common: {
        black: BLACK,
        white: WHITE,
      },
      primary: {
        darker: PRIMARY[900],
        dark: PRIMARY[700],
        main: PRIMARY[500],
        light: PRIMARY[400],
        lighter: PRIMARY[200],
        ...PRIMARY,
      },
      secondary: {
        darker: SECONDARY[900],
        dark: SECONDARY[700],
        main: SECONDARY[500],
        light: SECONDARY[400],
        lighter: SECONDARY[200],
        ...SECONDARY,
      },
      info: {
        dark: INFO[700],
        main: INFO[500],
        light: INFO[300],
      },
      success: {
        dark: SUCCESS[700],
        main: SUCCESS[500],
        light: SUCCESS[300],
      },
      warning: {
        dark: WARNING[700],
        main: WARNING[500],
        light: WARNING[300],
      },
      error: {
        dark: ERROR[700],
        main: ERROR[500],
        light: ERROR[300],
      },
      grey: {
        ...GREY,
      },
      action: {
        hover: PRIMARY[200],
        selected: PRIMARY[700],
        disabled: GREY[400],
        disabledBackground: GREY[400],
        focus: PRIMARY[600],
      },
      complementary: COMPLEMENTARY,
    },
  })

  const buttonMediumPadding = '12px 16px'
  const buttonFontStyles = { ...typographyStyles.C3 }
  const buttonSmallFontStyles = { ...typographyStyles.C5 }
  delete buttonFontStyles.color
  delete buttonSmallFontStyles.color

  const MuiDrawerColor =
    name === 'alegra'
      ? [baseTheme.palette.primary[600], baseTheme.palette.primary[500]]
      : [baseTheme.palette.grey[500], baseTheme.palette.grey[500]]
  const theme = createMuiTheme(
    {
      palette: baseTheme.palette,
      typography: {
        fontFamily: ['Roboto', 'Poppins', 'sans-serif'].join(','),
        h1: { ...typographyStyles.T1 },
        h2: { ...typographyStyles.T2 },
        h3: { ...typographyStyles.T3 },
        h4: { ...typographyStyles.T4 },
        h5: { ...typographyStyles.C1 },
        h6: { ...typographyStyles.C2 },
        subtitle1: { ...typographyStyles.C3 },
        subtitle2: { ...typographyStyles.C5 },
        body1: { ...typographyStyles.C4 },
        body2: { ...typographyStyles.C6 }, // mui applies body2 to global <body> tag
        // (?) no variant to put C7 style (?)
        caption: { ...typographyStyles.C8 },
        button: buttonFontStyles,
        ...typographyStyles,
      },
      shadows,
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [...roboto, ...poppins],
            body: {
              color: baseTheme.palette.grey[900],
              backgroundColor: baseTheme.palette.grey[100],
            },
          },
        },
        MuiTypography: {
          root: {
            // Added to fix visualization problems (overflow) caused by Appcues integration.
            // MuiTypography strangly and unpredictably set position relative,
            // high width and left value which causes the overflow
            position: 'static !important',
            width: 'auto !important',
          },
        },
        MuiButton: {
          root: {
            height: 48,
            padding: buttonMediumPadding,
            ...buttonFontStyles,
            textTransform: 'none', // prevents default text uppercase in all buttons
            borderRadius: 12,
            '& + &': {
              marginLeft: baseTheme.spacing(0.5),
            },
          },
          sizeSmall: {
            height: 40,
            padding: '10px 16px',
            ...buttonSmallFontStyles,
            borderRadius: 8,
          },
          containedPrimary: {
            color: baseTheme.palette.common.white,
            '&$disabled': {
              color: baseTheme.palette.common.white,
            },
            '&:focus': {
              backgroundColor: baseTheme.palette.primary[600],
            },
          },
          outlined: {
            backgroundColor: baseTheme.palette.common.white,
            padding: buttonMediumPadding,
            '&$disabled': {
              borderWidth: 2,
            },
          },
          outlinedPrimary: {
            border: `2px solid ${baseTheme.palette.primary.light}`,
            '&:hover': {
              border: `2px solid ${baseTheme.palette.primary.light}`,
              backgroundColor: baseTheme.palette.primary.lighter,
            },
            '&:focus': {
              backgroundColor: `${
                baseTheme.palette.primary.light
              }${getAlphaHexValue(24)}`,
            },
          },
          text: {
            padding: buttonMediumPadding,
            '&:focus': {
              backgroundColor: `${
                baseTheme.palette.primary.light
              }${getAlphaHexValue(24)}`,
            },
          },
          textSecondary: {
            marginRight: baseTheme.spacing(1),
            color: baseTheme.palette.secondary.dark,
            backgroundColor: baseTheme.palette.secondary.lighter,
            '&:hover': {
              backgroundColor: baseTheme.palette.secondary.light,
            },
            '&:focus': {
              backgroundColor: baseTheme.palette.secondary.lighter,
            },
          },
          startIcon: {
            marginLeft: -4,
            marginRight: 4,
            '&$iconSizeSmall': {
              marginLeft: -4,
            },
          },
          endIcon: {
            marginLeft: 4,
            marginRight: -4,
            '&$iconSizeSmall': {
              marginRight: -4,
            },
          },
        },
        MuiIconButton: {
          root: {
            width: 48,
            height: 48,
            padding: 12,
            borderRadius: 12,
            fontSize: '1.685rem', // to get 18x18 px for the svg icon dimensions
            backgroundColor: baseTheme.palette.primary.lighter,
            color: baseTheme.palette.primary.main,
            '&:hover': {
              backgroundColor: `${
                baseTheme.palette.primary.light
              }${getAlphaHexValue(24)}`,
            },
            '&:focus': {
              color: baseTheme.palette.primary.main,
              backgroundColor: baseTheme.palette.primary.lighter,
              border: `2px solid ${baseTheme.palette.primary.light}`,
            },
            '&$disabled': {
              border: `2px solid ${baseTheme.palette.grey[400]}`,
            },
            '& + &': {
              marginLeft: 5,
            },
            '& + span > &': {
              // to target button inside tooltip span
              marginLeft: 5,
            },
          },
          label: {
            width: 'auto',
          },
          sizeSmall: {
            width: 32,
            height: 32,
            borderRadius: 8,
            fontSize: '1.125rem', // to get 10x10 px just svg icon dimensions
          },
        },
        /**
         * Form elements
         */
        MuiFormLabel: {
          root: {
            fontSize: '0.75rem', // 12px
            display: 'inline-flex',
            alignItems: 'center',
            alignSelf: 'start',
            marginBottom: baseTheme.spacing(1),
            color: baseTheme.palette.grey[700],
            '&$focused': {
              color: baseTheme.palette.grey[700],
            },
            '&$error': {
              color: baseTheme.palette.error.dark,
            },
            '&$disabled': {
              color: baseTheme.palette.grey[700],
              opacity: 0.45,
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            height: 48,
            borderRadius: 8,
            '&:hover:not($disabled):not($focused):not($error)': {
              '& $notchedOutline': {
                borderColor: baseTheme.palette.grey[500],
              },
            },
            '&$disabled': {
              opacity: 0.45,
            },
          },
          input: {
            padding: 12,
            lineHeight: '24px',
            color: baseTheme.palette.grey[700],
            '&:active': {
              opacity: 1,
            },
            '&:focus': {
              opacity: 1,
            },
            '&::placeholder': {
              color: baseTheme.palette.grey[400],
              opacity: 1,
            },
          },
          inputMarginDense: {
            paddingTop: 12,
            paddingBottom: 12,
          },
          notchedOutline: {
            borderColor: baseTheme.palette.grey[400],
          },
        },
        MuiFormHelperText: {
          root: {
            alignSelf: 'start',
            color: baseTheme.palette.grey[500],
            '&$error': {
              color: baseTheme.palette.error.main,
            },
            '&$disabled': {
              color: baseTheme.palette.grey[700],
              opacity: 0.45,
            },
          },
          contained: {
            marginLeft: 0,
            marginRight: 0,
          },
          marginDense: {
            marginTop: 8,
          },
        },
        MuiInputBase: {
          root: {
            backgroundColor: baseTheme.palette.common.white,
            '& textarea': {
              height: 'auto',
            },
          },
          input: {
            '&::placeholder': {
              opacity: 0.5,
            },
          },
        },
        MuiInputLabel: {
          // todo: remove after because not using input labels?
          outlined: {
            // Workarrround for outline inputs with variant outlined
            // See: https://github.com/mui-org/material-ui/issues/16954
            backgroundColor: baseTheme.palette.common.white,
            paddingLeft: 2,
            paddingRight: 2,
            fontSize: '0.9rem',
          },
        },
        MuiInputAdornment: {
          root: {
            color: baseTheme.palette.grey[600],
          },
        },
        MuiSelect: {
          iconOutlined: {
            right: 12,
          },
          icon: {
            color: baseTheme.palette.grey[500],
          },
          outlined: {
            '& > em': {
              color: baseTheme.palette.grey[400],
            },
          },
        },
        MuiAutocomplete: {
          endAdornment: {
            right: '12px !important',
            top: 'calc(50% - 12px)',
            '& .MuiIconButton-root': {
              width: 24,
              height: 24,
              borderRadius: 0,
              color: baseTheme.palette.grey[500],
              backgroundColor: 'transparent',
              padding: 0,
            },
          },
          clearIndicator: {
            marginRight: 2,
          },
          popupIndicator: {
            marginRight: 0,
          },
          option: {
            '&[aria-selected="true"]': {
              backgroundColor: baseTheme.palette.primary.main,
              '&:hover': {
                backgroundColor: baseTheme.palette.primary.main,
              },
            },
            '&:active': {
              backgroundColor: baseTheme.palette.primary.main, // this is not working, aria-selected does
            },
          },
        },
        MuiListItem: {
          root: {
            '&$selected, &$selected:hover': {
              backgroundColor: baseTheme.palette.primary.main,
              color: baseTheme.palette.common.white,
            },
            '&$focusVisible': {
              backgroundColor: baseTheme.palette.primary.light,
            },
          },
        },
        MuiListItemIcon: {
          root: {
            color: 'inherit',
            justifyContent: 'center',
            minWidth: 0,
            marginRight: '1rem',
          },
        },
        MuiFormControlLabel: {
          root: {
            '& .MuiIconButton-root': {
              width: 40,
              height: 40,
              backgroundColor: 'transparent',
              borderRadius: '50%',
              '&$disabled': {
                border: 'none',
              },
              '&:hover': { backgroundColor: baseTheme.palette.primary.lighter },
              '&:focus': { backgroundColor: baseTheme.palette.primary.light }, // looks like its no needed or used by MUI
            },
          },
          label: {
            marginLeft: 2,
          },
        },
        PrivateRadioButtonIcon: {
          root: {
            '& .MuiSvgIcon-root': {
              width: 20,
              height: 20,
            },
          },
        },
        MuiCheckbox: {
          root: {
            color: baseTheme.palette.grey[500], // the square is not border property based
          },
        },
        MuiSwitch: {
          root: {
            width: 60,
            height: 40,
            '& .MuiIconButton-root': {
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: 'transparent',
              '&$disabled': {
                border: 'none',
              },
              '&:hover': {
                backgroundColor: `${
                  baseTheme.palette.grey[100]
                }${getAlphaHexValue(60)}`,
              },
            },
          },
          switchBase: {
            color: baseTheme.palette.common.white,
            '&$checked': {
              color: baseTheme.palette.primary.main,
            },
            '&$checked + $track': {
              backgroundColor: baseTheme.palette.primary.light,
            },
            '&$disabled': {
              '& + $track': {
                opacity: 0.45,
              },
            },
          },
          thumb: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
          },
          colorPrimary: {
            '&$checked': {
              '&:hover': {
                backgroundColor: `${
                  baseTheme.palette.primary.main
                }${getAlphaHexValue(10)}`,
              },
              '&$disabled': {
                color: baseTheme.palette.primary.light,
                '&  + $track': {
                  backgroundColor: baseTheme.palette.primary.light,
                  opacity: 0.35,
                },
              },
            },
            '&$disabled': {
              color: baseTheme.palette.common.white,
              '&  + $track': {
                backgroundColor: baseTheme.palette.grey[400],
              },
            },
          },
          track: {
            backgroundColor: baseTheme.palette.grey[400],
          },
        },
        MuiChip: {
          root: {
            height: 28,
            fontSize: 14,
            backgroundColor: baseTheme.palette.primary.lighter,
            paddingLeft: 12,
            paddingRight: 12,
          },
          label: {
            color: baseTheme.palette.primary.dark,
            paddingLeft: 0,
            paddingRight: 0,
          },
          outlined: {
            borderColor: baseTheme.palette.primary.dark,
            '& .MuiChip-icon': {
              marginLeft: -2,
            },
          },
          icon: {
            width: 16,
            height: 16,
            marginLeft: -3,
            marginRight: 5,
            color: baseTheme.palette.primary.dark,
          },
        },
        MuiPagination: {
          ul: {
            justifyContent: 'center',
          },
        },
        MuiPaginationItem: {
          root: {
            width: 48,
            height: 48,
            borderRadius: '50%',
            fontSize: '1rem',
            '&$disabled': { color: baseTheme.palette.grey[400] },
          },
          page: {
            color: baseTheme.palette.primary.dark,
            '&:hover': { backgroundColor: baseTheme.palette.grey[100] },
            '&$selected': {
              backgroundColor: baseTheme.palette.secondary.main,
              color:
                name === 'arus'
                  ? baseTheme.palette.primary.lighter
                  : baseTheme.palette.secondary.darker,
              '&:hover': { backgroundColor: baseTheme.palette.secondary.main },
            },
            '&$disabled': { opacity: 1 },
          },
        },
        MuiTableContainer: {
          root: {
            paddingBottom: baseTheme.spacing(),
            ...elevation.x2dp,
          },
        },
        MuiTableHead: {
          root: {
            '& .MuiTableRow-root': {
              borderBottom: `2px solid ${baseTheme.palette.grey[200]}`,
            },
            '& .MuiTableCell-root:last-child': {
              paddingLeft: baseTheme.spacing(2),
              paddingRight: baseTheme.spacing(2),
            },
          },
        },
        MuiTableBody: {
          root: {
            '& .MuiTableRow-root': {
              borderBottom: 'none',
              '&:not(:last-child)': {
                borderBottom: `1px solid ${baseTheme.palette.grey[200]}`,
              },
            },
          },
        },
        MuiTableCell: {
          root: {
            ...baseTheme.typography.C3,
            padding: '0 16px',
            height: 48,
            borderBottom: 0,
            fontWeight: 'inherit',
            '& .MuiIconButton-root': {
              marginLeft: 3,
              marginRight: 3,
            },
          },
          head: {
            ...baseTheme.typography.C3,
            color: baseTheme.palette.grey[700],
          },
        },
        MuiTableSortLabel: {
          root: {
            '&$active': {
              '&& $icon': {
                color: baseTheme.palette.primary.main,
              },
            },
          },
        },
        MuiStepper: {
          root: {
            backgroundColor: 'transparent',
          },
        },
        MuiStepIcon: {
          root: {
            width: 28,
            height: 28,
            '&$active': {
              color: baseTheme.palette.secondary.main,
              '& .MuiStepIcon-text': {
                fill:
                  name === 'arus'
                    ? baseTheme.palette.primary.lighter
                    : baseTheme.palette.secondary.darker,
              },
            },
          },
          text: {
            color: baseTheme.palette.primary.main,
            ...typographyStyles.C5,
          },
        },
        MuiStepConnector: {
          line: {
            borderColor: baseTheme.palette.primary.main,
            opacity: 0.32,
          },
          lineHorizontal: {
            borderTopWidth: 2,
          },
        },
        MuiDrawer: {
          paper: {
            backgroundColor: baseTheme.palette.primary.main,
            background:
              name === 'alegra' || name === 'arus'
                ? `linear-gradient(184.07deg, ${MuiDrawerColor[0]} 0%, ${MuiDrawerColor[1]} 84.9%)`
                : baseTheme.palette.primary.main,
            boxShadow: '0px 4px 24px rgba(20, 9, 51, 0.08)',
          },
          paperAnchorDockedLeft: {
            borderRight: 'none',
          },
        },
        MuiPaper: {
          root: {
            color: 'inherit',
            backgroundColor: paper.backgroundColor,
          },
          rounded: {
            borderRadius: paper.borderRadius,
          },
        },
        MuiDialogTitle: {
          root: {
            marginTop: 10,
            paddingBottom: 10,
            borderColor: baseTheme.palette.secondary.main,
          },
        },
        MuiDialogActions: {
          root: {
            padding: 20,
          },
        },
        MuiAccordion: {
          root: {
            boxShadow: 'none',
            '&::before': {
              display: 'none',
            },
            '&$expanded': { margin: 0 },
            '& + &': {
              borderTop: `1px solid ${baseTheme.palette.grey[100]}`,
            },
          },
        },
        MuiAccordionSummary: {
          root: {
            color: baseTheme.palette.grey[500],
            '&:hover': {
              backgroundColor: baseTheme.palette.primary.lighter,
            },
            '&$expanded': {
              color: baseTheme.palette.primary.main,
              minHeight: 'auto',
            },
          },
          content: {
            '&$expanded': {
              margin: 0,
              '& .MuiTypography-root': {
                color: 'inherit',
                fontWeight: 'bold',
              },
            },
          },
          expandIcon: {
            color: baseTheme.palette.grey[500],
            backgroundColor: 'transparent',
            '&$expanded': {
              color: 'inherit',
            },
          },
        },
        MuiAccordionActions: {
          root: {
            backgroundColor: baseTheme.palette.grey[100],
            padding: `${baseTheme.spacing(3)}px ${baseTheme.spacing(2)}px`,
          },
        },
        MuiAlert: {
          root: {
            marginTop: 10,
            marginBottom: 10,
          },
          message: {
            fontSize: '0.8rem',
          },
          action: {
            '& .MuiIconButton-root': {
              backgroundColor: 'transparent',
              '&:focus': {
                border: 'none',
                color: 'inherit',
              },
            },
          },
          standardInfo: {
            color: baseTheme.palette.info.dark,
            borderLeft: `5px solid ${baseTheme.palette.info.main}`,
            '& .MuiAlert-icon': {
              color: baseTheme.palette.info.dark,
            },
          },
          standardWarning: {
            color: baseTheme.palette.warning.dark,
            borderLeft: `5px solid ${baseTheme.palette.warning.main}`,
            '& .MuiAlert-icon': {
              color: baseTheme.palette.warning.dark,
            },
          },
          standardError: {
            color: baseTheme.palette.error.dark,
            borderLeft: `5px solid ${baseTheme.palette.error.main}`,
            '& .MuiAlert-icon': {
              color: baseTheme.palette.error.dark,
            },
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '0.75rem',
          },
        },
        MuiTabs: {
          root: {
            paddingTop: 8,
          },
          indicator: {
            height: 4,
          },
        },
        MuiTab: {
          root: {
            color: baseTheme.palette.grey[500],
            textTransform: 'none',
            padding: '12px 16px',
            borderRadius: '8px 8px 0px 0px',
            ...baseTheme.typography.C4,
            '&$selected': {
              ...baseTheme.typography.C3,
            },
            '& + &': {
              marginLeft: baseTheme.spacing(),
            },
            '&:hover': {
              backgroundColor: baseTheme.palette.grey[100],
            },
          },
          wrapper: {
            flexDirection: 'row',
          },
        },
        MuiPickersBasePicker: {
          pickerView: {
            maxWidth: 330,
            padding: '16px 10px',
            justifyContent: 'start',
            minHeight: 388, // to prevent show Y scroll
          },
        },
        MuiPickersCalendar: {
          week: {
            marginBottom: baseTheme.spacing(2),
          },
          transitionContainer: {
            marginTop: baseTheme.spacing(),
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            paddingLeft: 6,
            paddingRight: 6,
            marginBottom: baseTheme.spacing(3),
          },
          dayLabel: {
            color: baseTheme.palette.primary.light,
            width: '32px !important',
            margin: '0 6px',
          },
          iconButton: {
            backgroundColor: baseTheme.palette.primary.lighter,
            width: 32,
            height: 32,
          },
          transitionContainer: {
            '& .MuiTypography-root': {
              fontWeight: 'bold',
            },
          },
        },
        MuiPickersDay: {
          day: {
            width: 32,
            height: 32,
            borderRadius: '50%',
            color: baseTheme.palette.grey[700],
            backgroundColor: 'transparent',
            margin: '0 6px',
            '&:hover': {
              // color:
              backgroundColor: baseTheme.palette.grey[100],
            },
            '&:focus': {
              color: baseTheme.palette.grey[700],
              backgroundColor: baseTheme.palette.grey[200],
              border: 0,
            },
          },
          daySelected: {
            color: baseTheme.palette.grey[700],
            backgroundColor: baseTheme.palette.secondary.main,
            '&:hover': {
              backgroundColor: baseTheme.palette.secondary.light,
            },
          },
        },
        MuiPickersModal: {
          dialogRoot: {
            // 🔪 machetazo
            '& .MuiDialogActions-root': {
              '& .MuiButton-root:first-child': {
                // styles copied from MuiButton-outlinedPrimary
                color: baseTheme.palette.primary.main,
                backgroundColor: 'transparent',
                border: `2px solid ${baseTheme.palette.primary.light}`,
                '&:hover': {
                  border: `2px solid ${baseTheme.palette.primary.light}`,
                  backgroundColor: baseTheme.palette.primary.lighter,
                },
                '&:focus': {
                  backgroundColor: `${
                    baseTheme.palette.primary.light
                  }${getAlphaHexValue(24)}`,
                },
              },
            },
          },
        },
      },
      props: {
        // Mui Components
        MuiLink: {
          color: 'primary',
        },
        MuiButton: {
          variant: 'contained',
          color: 'primary',
          disableElevation: true, // to remove all box-shadows
        },
        MuiButtonBase: {
          disableRipple: true,
        },
        MuiTextField: {
          variant: 'outlined',
          margin: 'dense',
          fullWidth: true,
        },
        MuiFormControl: {
          variant: 'outlined',
          margin: 'dense',
          fullWidth: true,
        },
        MuiInputLabel: {
          variant: 'outlined',
        },
        MuiOutlinedInput: {
          notched: true,
        },
        MuiSelect: {
          IconComponent: ExpandMoreIcon,
        },
        MuiAutocomplete: {
          popupIcon: <ExpandMoreIcon />,
        },
        MuiRadio: {
          color: 'primary',
        },
        MuiCheckbox: {
          color: 'primary',
        },
        MuiSwitch: {
          color: 'primary',
        },
        MuiPaginationItem: {
          color: 'secondary', // it seems to have no effect
        },
        MuiTabs: {
          textColor: 'primary',
          indicatorColor: 'primary',
        },
        MuiPopover: {
          elevation: 1,
        },
        MuiDialog: {
          PaperProps: {
            elevation: 3,
          },
        },
      },
      // custom variables go here
      name,
      headerHeight: 72,
      mobileAppbar: 64,
    },
    esES
  )

  return theme
}

export default generateTheme
