import clsx from 'clsx'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#FFFFFF80',
  },
}))

const LoadingBackdrop = ({ open, showLoader = true, className, ...props }) => {
  const classes = useStyles()

  return (
    <Backdrop
      open={open}
      className={clsx(classes.backdrop, className)}
      {...props}
    >
      {showLoader ? <CircularProgress /> : null}
    </Backdrop>
  )
}

export default LoadingBackdrop
