import * as yup from 'yup'

const validationSchema = yup.object({
  general_incapacity_payment: yup.string().nullable().required('Requerido!'),
  pay_holidays_31: yup.string().nullable().required('Requerido!'),
  proportional_deductions: yup.string().nullable().required('Requerido!'),
  proportional_recurrent_payments: yup
    .string()
    .nullable()
    .required('Requerido!'),
  transport_subsidy_per_period: yup.string().nullable().required('Requerido!'),
  transport_subsidy_base: yup.string().nullable().required('Requerido!'),
  worker_pension_unpaid_license: yup.string().nullable().required('Requerido!'),
  pay_february_extra_days_if_hired_in_month: yup
    .string()
    .nullable()
    .required('Requerido!'),
})

export default validationSchema
