export const paper = {
  backgroundColor: '#fff',
  borderRadius: 8,
}

export const shadows = [
  'none',
  '0px 2px 16px rgba(20, 9, 51, 0.08)',
  '0px 4px 24px rgba(20, 9, 51, 0.08)',
  '0px 8px 32px rgba(20, 9, 51, 0.08)',
]

const flat = {
  ...paper,
  boxShadow: shadows[0],
}

const x2dp = {
  ...flat,
  boxShadow: shadows[1],
}
const x4dp = {
  ...flat,
  boxShadow: shadows[2],
}
const x8dp = {
  ...flat,
  boxShadow: shadows[3],
}

export default { flat, x2dp, x4dp, x8dp }
