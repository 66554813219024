import { useContext } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import { formatNumber } from 'utils/format'

import { PeriodContext } from '../../Payroll'
import NoveltiesModal from '../Modals/Novelties/Novelties'
import useNoveltiesModal from '../Modals/Novelties/useNoveltiesModal'
import { getCellNoveltiesText } from './helpers'

const CellNovelties = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const { showModal, handleCloseModal, handleOpenModal } = useNoveltiesModal()

  return (
    <>
      {getPayrollConceptItemCell({
        buttonId: `novelties_${payroll.tableData.id}`,
        payrollConceptValue: payroll.novelties_days,
        text: getCellNoveltiesText(payroll.contract_category),
        formatter: formatNumber,
        onClick: handleOpenModal,
        editable: period.editable && !payroll.locked,
      })}
      {showModal ? (
        <NoveltiesModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          payroll={payroll}
        />
      ) : null}
    </>
  )
}

export default CellNovelties
