import { useFormikContext } from 'formik'

import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'

const EntitiesSection = () => {
  const { values } = useFormikContext()

  return (
    <>
      <HealthProviderField contractCategory={values.contract_category} />
      <PensionProviderField
        documentType={values.document_type}
        contractCategory={values.contract_category}
      />
      <SeveranceProviderField
        wageCategory={values.wage_category}
        contractCategory={values.contract_category}
      />
    </>
  )
}

export default EntitiesSection
