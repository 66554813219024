import ToolbarActions from 'components/UI/Table/Toolbar/ToolbarActions'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'
import TableToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'

import Tabs from './Tabs'

const Toolbar = (props) => {
  const { freeActions, globalFilter, setGlobalFilter, ...restProps } = props

  return (
    <>
      <Tabs />
      <ToolbarContainer {...restProps}>
        <TableToolbarSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <ToolbarActions freeActions={freeActions} />
      </ToolbarContainer>
    </>
  )
}

export default Toolbar
