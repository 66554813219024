import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/UI/Button/Button'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { DASHBOARD } from 'config/routes'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: 'grid',
    gridTemplateAreas: '"main aside" "main aside"',
    gridTemplateRows: '1fr',
    height: '90vh',
    alignItems: 'center',
    '& section:first-child': {
      gridArea: 'main',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3.75),
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      gridTemplateAreas: '"aside aside" "main main"',
      justifyContent: 'center',
      justifyItems: 'center',
      margin: theme.spacing(4, 0),
      '& section:first-child': {
        gap: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateRows: '0.4fr 0.5fr',
      '& aside > img': {
        width: 300,
        height: 255,
      },
    },
  },
  footer: {
    gridArea: 'footer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const ErrorPageAtom = ({
  subtitle,
  description,
  image,
  details = null,
  footer,
}) => {
  const classes = useStyles()

  return (
    <>
      <article className={classes.mainContent}>
        <section>
          <Typography variant="h2">Lo sentimos</Typography>
          <Typography variant="h3">{subtitle}</Typography>
          <Typography>{description}</Typography>
          <Typography>{details}</Typography>
          <Button component={Link} to={DASHBOARD}>
            Regresar al panel de control
          </Button>
        </section>
        <aside>
          <img src={image} alt="robot not found" width="548" height="421" />
        </aside>
      </article>
      <footer className={classes.footer}>
        <Typography>
          {footer || (
            <>
              Si requieres que alguien te rescate, escríbenos a{' '}
              <OrganizationEmail />.{' '}
            </>
          )}
        </Typography>
      </footer>
    </>
  )
}

export default ErrorPageAtom
