import { useContext } from 'react'

import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { isWorker } from 'utils/auth'

import ButtonPremium from '../Premium/ButtonGetPremium'
import { useUser } from '../UserContext/useUser'
import AppLogo from './Logo'
import MenuListTopMemoized from './MenuList/Top/Top'
import PoweredBy from './PoweredBy'
import { SidebarContext } from './Sidebar'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  divider: {
    backgroundColor: theme.palette.grey[100],
    opacity: 0.5,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}))

const AppSidebarContent = () => {
  const classes = useStyles()
  const { subscription } = useUser()
  const { expanded } = useContext(SidebarContext)
  const isNotPremium = [
    'basic',
    'premium_due',
    'premium_canceled',
    'premium_expired',
  ].includes(subscription?.status)
  const pendingPayment = subscription?.payment_status === 'pending'
  const isNotValidSubscription = !pendingPayment
    ? isNotPremium
    : isNotPremium && !pendingPayment
  const showPremiumButton = expanded && !isWorker() && isNotValidSubscription

  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <AppLogo />
      </Hidden>
      <MenuListTopMemoized />
      {showPremiumButton ? <ButtonPremium /> : null}
      <PoweredBy />
    </div>
  )
}

export default AppSidebarContent
