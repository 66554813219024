import SvgIcon from '@material-ui/core/SvgIcon'

const BracketsList = (props) => {
  return (
    <SvgIcon viewBox="0 0 36 40" fontSize="large" {...props}>
      <path d="M16 26.3399L11.66 21.9999L16 17.6599L13.18 14.8199L6 21.9999L13.18 29.1799L16 26.3399Z" />
      <path d="M22.82 29.1799L30 21.9999L22.82 14.8199L20 17.6599L24.34 21.9999L20 26.3399L22.82 29.1799Z" />
      <path d="M32 4H23.64C22.8 1.68 20.6 0 18 0C15.4 0 13.2 1.68 12.36 4H4C3.72 4 3.46 4.02 3.2 4.08C2.42 4.24 1.72 4.64 1.18 5.18C0.82 5.54 0.52 5.98 0.32 6.46C0.12 6.92 0 7.44 0 8V28V30V36C0 36.54 0.12 37.08 0.32 37.56C0.52 38.04 0.82 38.46 1.18 38.84C1.72 39.38 2.42 39.78 3.2 39.94C3.46 39.98 3.72 40 4 40H32C34.2 40 36 38.2 36 36V30V28V8C36 5.8 34.2 4 32 4ZM18 3.5C18.82 3.5 19.5 4.18 19.5 5C19.5 5.82 18.82 6.5 18 6.5C17.18 6.5 16.5 5.82 16.5 5C16.5 4.18 17.18 3.5 18 3.5ZM32 28V30V36H4V30V28V8H32V28Z" />
    </SvgIcon>
  )
}

export default BracketsList
