import Link from 'components/UI/MaterialUI/Link'
import { useUser } from 'components/App/UserContext/useUser'

import { formatCurrency } from 'utils/format'

const useColumnsData = () => {
  const { changeCompany } = useUser()

  const columnsData = [
    {
      Header: 'Nombre De Empresa',
      accessor: 'company_name',
      Cell: ({ row }) => (
        <Link
          to={`/companies/${row.original.company_id}/subscription`}
          onClick={() => changeCompany({ id: row.original.company_id })}
          target="_blank"
        >
          {row.original.company_name}
        </Link>
      ),
      customMinWidth: '180px',
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
    },
  ]

  return columnsData
}

export default useColumnsData
