import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'

const useStyles = makeStyles(() => ({
  checkboxButton: {
    alignSelf: 'start',
  },
  checkboxIconSize: {
    fontSize: 30,
  },
}))

const CheckboxField = ({ id, field, ...props }) => {
  const localClasses = useStyles()

  return (
    <Checkbox
      id={id}
      {...field}
      size="medium"
      className={localClasses.checkboxButton}
      checkedIcon={
        <CheckBoxOutlinedIcon className={localClasses.checkboxIconSize} />
      }
      icon={
        <CheckBoxOutlineBlankOutlinedIcon
          className={localClasses.checkboxIconSize}
        />
      }
      {...props}
    />
  )
}

export default CheckboxField
