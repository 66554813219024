import SvgIcon from '@material-ui/core/SvgIcon'

function PayPayroll(props) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <path d="M25.333 18.6673V8.00065C25.333 6.53398 24.133 5.33398 22.6663 5.33398H3.99967C2.53301 5.33398 1.33301 6.53398 1.33301 8.00065V18.6673C1.33301 20.134 2.53301 21.334 3.99967 21.334H22.6663C24.133 21.334 25.333 20.134 25.333 18.6673ZM22.6663 18.6673H3.99967V8.00065H22.6663V18.6673ZM13.333 9.33398C11.1197 9.33398 9.33301 11.1207 9.33301 13.334C9.33301 15.5473 11.1197 17.334 13.333 17.334C15.5463 17.334 17.333 15.5473 17.333 13.334C17.333 11.1207 15.5463 9.33398 13.333 9.33398ZM30.6663 9.33398V24.0007C30.6663 25.4673 29.4663 26.6673 27.9997 26.6673H5.33301C5.33301 25.334 5.33301 25.4673 5.33301 24.0007H27.9997V9.33398C29.4663 9.33398 29.333 9.33398 30.6663 9.33398Z" />
    </SvgIcon>
  )
}

export default PayPayroll
