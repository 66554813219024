import nominapp from './nominapp'
import alegra from './alegra'
import arus from './arus'

export const colors = {
  nominapp,
  alegra,
  arus,
}

export * from './utils'
