import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import { useUser } from 'components/App/UserContext/useUser'
import Emoji from 'components/UI/Emoji'

import { setDateEndFreeTrial, setStatusFreemium } from 'utils/freemium'
import useNotifications from 'utils/hooks/useNotifications'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import { cryEmoji } from 'utils/emojis'

import * as routes from 'config/routes'

import laborSettlement from 'assets/images/views/payroll/onboarding/accountant.png'
import automaticPayment from 'assets/images/views/payroll/onboarding/next_payroll.png'
import socialSecurity from 'assets/images/views/payroll/onboarding/own_payroll.png'
import certificates from 'assets/images/views/premium/certificates.png'
import { ReactComponent as notification } from 'assets/images/views/premium/features/notification.svg'
import { ReactComponent as payroll } from 'assets/images/views/premium/features/payroll.svg'
import { ReactComponent as support } from 'assets/images/views/premium/features/support.svg'
import { ReactComponent as admin } from 'assets/images/views/premium/features/admin.svg'
import { ReactComponent as pila } from 'assets/images/views/premium/features/pila.svg'
import { ReactComponent as retention } from 'assets/images/views/premium/features/retention.svg'
import { ReactComponent as payment } from 'assets/images/views/premium/features/payment.svg'
import { ReactComponent as report } from 'assets/images/views/premium/features/report.svg'

export const dataFeatures = [
  {
    id: 1,
    featureName: 'Liquidaciones laborales a 1 click.',
    FeatureImage: laborSettlement,
  },
  {
    id: 2,
    featureName: 'Pago de PILA Automático',
    FeatureImage: automaticPayment,
  },
  {
    id: 3,
    featureName: 'Liquidación de prestaciones sociales',
    FeatureImage: socialSecurity,
  },
  {
    id: 4,
    featureName: 'Reportes y certificados',
    FeatureImage: certificates,
  },
]

export const usePremiumActions = () => {
  const [isOpenModal, setIsOpenModal] = useState(true)

  const { refreshCompany } = useUser()

  const { showSuccessMessage } = useNotifications()

  const history = useHistory()

  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: { enabled: false },
  })

  const handleGetFreeTrial = (callback) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
      },
      {
        onSuccess: (data) => {
          setStatusFreemium(data.data.freemium_status)
          setDateEndFreeTrial(data.data.premium_trial_end_date)
          // Refresh for update the status page
          refreshCompany()

          showSuccessMessage('Has activado exitosamente tus 7 días gratis')
          setIsOpenModal(false)
          if (callback) callback()
        },
      }
    )
    setIsOpenModal(true)
  }

  const handleGetPremium = () => history.push(routes.SUBSCRIPTION())

  const handlePremiumForPartnerCompany = (callback) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
      },
      {
        onSuccess: (data) => {
          // Refresh for update the status page
          refreshCompany()
          setStatusFreemium(data.data.freemium_status)

          showSuccessMessage('Has activado tu plan Premium exitosamente')
          setIsOpenModal(false)
          if (callback) callback()
        },
      }
    )
  }

  return {
    handleGetFreeTrial,
    handleGetPremium,
    handlePremiumForPartnerCompany,
    subscriptionMutation,
    isOpenModal,
  }
}

export const premiumModals = {
  payslip: {
    title: 'Generar Colillas de Pago es una funcionalidad Premium.',
    description:
      'Pásate a Premium y notifica a tus empleados de su pago generando y enviando colillas de pago detalladas.',
    featureId: 1,
  },
  accountingInterface: {
    title: 'Integración contable es una funcionalidad Premium.',
    description:
      'Pásate a Premium y contabiliza tu nómina en segundos con más de 60 software contables diferentes.',
    featureId: 2, // To highlight the feature card
  },
  addAdmin: {
    title: 'Agregar Administradores es una funcionalidad Premium.',
    description:
      'Pásate a Premium e invita a tantas personas de tu equipo como quieras como administradores.',
    featureId: 4,
  },
  socialSecurity: {
    title: 'Pago de Seguridad Social es una funcionalidad Premium.',
    description:
      'Pásate a Premium y paga la PILA en segundos en tu operador favorito, sin miedo al fantasma de la UGPP.',
    featureId: 5,
  },
  retention: {
    title: 'Retención en la Fuente es una funcionalidad Premium.',
    description:
      'Pásate a Premium y calcula automáticamente la retención en la fuente de los empleados que les corresponde.',
    featureId: 6,
  },
  payrollPayment: {
    title: 'Pago de nómina en Banco es una funcionalidad Premium.',
    description:
      'Pásate a Premium y paga tu nómina más rápido que Flash generando el archivo para pagar desde tu banco sin errores.',
    featureId: 7,
  },
  reports: {
    title: 'Certificados y Reportes es una funcionalidad Premium.',
    description:
      'Pásate a Premium y genera certificados laborales y reportes que hacen tu operación más fácil que la tabla del 0.',
    featureId: 8,
  },
  summaryReport: {
    title: 'Descargar el resumen de nómina es una funcionalidad Premium.',
    description:
      'Pásate a Premium y ahórrate las ojeras y los dolores de cabeza automatizando tu nómina.',
    featureId: 9,
  },
  default: {
    title: 'Con superpoderes que te van a devolver tu tiempo.',
    description:
      'Pásate a Premium y ahórrate las ojeras y los dolores de cabeza automatizando tu nómina.',
    featureId: null,
    header: '¡Conoce Nominapp Premium!',
  },
  defaultTrialStarted: {
    title: 'Tus días de Nominapp Premium terminaron.',
    description:
      'Pásate a Premium y ahórrate las ojeras y los dolores de cabeza automatizando tu nómina.',
    featureId: null,
    header: (
      <Typography variant="h5">
        ¡Oops! <Emoji code={cryEmoji} />
      </Typography>
    ),
  },
}

export const premiumFeatures = (feature) => {
  const featuresList = [
    {
      id: 1,
      description: (
        <>
          <b>Notifica el pago de nómina</b> detallado a tus empleados.
        </>
      ),
      image: notification,
    },
    {
      id: 2,
      description: (
        <>
          <b>Integra tu nómina en 1 clic</b> con +50 software contables.
        </>
      ),
      image: payroll,
    },
    {
      id: 3,
      description: (
        <>
          <b>Recibe soporte prioritario</b> de expertos de nómina en 1 hora.{' '}
        </>
      ),
      image: support,
    },
    {
      id: 4,
      description: (
        <>
          <b>Agrega a tu contador</b> y a todos tus usuarios administradores.
        </>
      ),
      image: admin,
    },
    {
      id: 5,
      description: (
        <>
          <b>Paga la PILA</b> en segundos y evita multas de la UGPP.
        </>
      ),
      image: pila,
    },
    {
      id: 6,
      description: (
        <>
          <b>Calcula automáticamente</b> la retención en la fuente.
        </>
      ),
      image: retention,
    },
    {
      id: 7,
      description: (
        <>
          <b>Paga la nómina en tu banco</b> sin errores y en segundos.
        </>
      ),
      image: payment,
    },
  ]
  if (feature === 'summaryReport') {
    featuresList.push({
      id: 9,
      description: (
        <>
          <b>Genera el resumen de nómina</b> y reportes que te dan tranquilidad.
        </>
      ),
      image: report,
    })
  } else {
    featuresList.push({
      id: 8,
      description: (
        <>
          <b>Genera certificados laborales</b> y reportes que te dan
          tranquilidad.
        </>
      ),
      image: report,
    })
  }

  return featuresList
}
