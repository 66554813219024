import { useEffect, useState, useRef } from 'react'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Page from 'components/UI/Page/Page'
import Steps from 'components/UI/Steps/Steps'

import { DASHBOARD } from 'config/routes'

import { useCompanyService } from 'utils/hooks/company/companyService'

import OnboardingRole from './Role/Role'
import OnboardingWelcome from './Welcome/Welcome'
import useOnboardingMutation from './useOnboardingMutation'
import OnboardingPersonalize from './Personalize/Personalize'
import OnboardingCongratulations from './Congratulations/Congratulations'
import { stepsData, stepNumber, schemaValidation } from './helpers'
import MainContainer from '../common/MainContainer'

const stepsSize = stepsData.length

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 76,
    margin: theme.spacing(11, 0, 5),
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.down(1281)]: {
      margin: theme.spacing(5, 0, 5),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  page: {
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  pageContent: {
    margin: theme.spacing(0, 4),
    height: 'calc(100% - 64px)',
  },
}))

const Onboarding = () => {
  const classes = useStyles()
  const history = useHistory()
  const { companyQuery } = useCompanyService({
    serviceParams: { queryKey: 'companyInformation' },
  })

  const companyData = companyQuery?.data

  const [currentStep, setCurrentStep] = useState(0)
  const stepRef = useRef(true)
  const currentStepData = stepsData.find(({ step }) => step === currentStep)

  const onboardingNameStep = companyData?.onboarding?.onboarding_step

  const disableLeftArrow =
    currentStep === 0 || (onboardingNameStep !== 'welcome' && currentStep === 1)

  const disableRightArrow = currentStep >= stepNumber[onboardingNameStep]

  const initialValues = {
    user_name: companyData?.phone ? companyData?.onboarding?.user_name : '',
    phone: companyData?.phone,
    name: companyData?.phone ? companyData?.name : '',
    document_type: companyData?.document_type || 'ni',
    id_number: companyData?.id_number,
    verification_digit: companyData?.verification_digit,
    workers_number: companyData?.onboarding?.workers_number,
    payroll_frequency: companyData?.onboarding?.payroll_frequency,
    user_role: companyData?.onboarding?.user_role,
    user_role_other_info: companyData?.onboarding?.user_role_other_info,
  }

  useEffect(() => {
    if (stepRef.current && companyQuery.isSuccess) {
      setCurrentStep(
        (previousValue) => previousValue + stepNumber[onboardingNameStep]
      )

      stepRef.current = false
    }
  }, [companyData, companyQuery.isSuccess, currentStep, onboardingNameStep])

  const handleNextStep = () => {
    if (currentStep < stepsSize) {
      setCurrentStep((previousValue) => previousValue + 1)
    }
  }

  const { updateRoleStep, onSuccessMutation, loading } = useOnboardingMutation(
    handleNextStep
  )

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((previousValue) => previousValue - 1)
    }
  }

  const handleSubmit = (values) => {
    if (currentStep === 2) {
      const valuesToSend = { ...values, onboarding_step: 'select_action' }
      delete valuesToSend.user_role
      delete valuesToSend.user_role_other_info

      updateRoleStep(valuesToSend, onSuccessMutation)
    }
  }

  // Set step from back onboarding_step
  useEffect(() => {
    if (!stepRef.current) {
      history.push(stepsData[currentStep]?.pathname)
    }
    if (onboardingNameStep === 'finished') history.push(DASHBOARD)
  }, [history, companyData, currentStep, onboardingNameStep])

  return onboardingNameStep !== 'finished' ? (
    <MainContainer>
      <Page
        documentTitle={stepsData[currentStep]?.title || ''}
        isLoading={companyQuery.isLoading}
        className={classes.page}
        contentClassName={classes.pageContent}
      >
        <div className={classes.content}>
          <div className={classes.stepperContainer}>
            <Steps
              arrows
              orientation="horizontal"
              stepsData={stepsData}
              current={currentStep}
              progress={currentStep}
              leftArrowProps={{
                onClick: handlePreviousStep,
                disabled: disableLeftArrow,
              }}
              rightArrowProps={{
                onClick: handleNextStep,
                disabled: disableRightArrow,
              }}
            />
          </div>
          {currentStep < 3 ? (
            <>
              <Typography variant="h5" className={classes.title}>
                {currentStepData?.title}
              </Typography>
              <Typography variant="body1">
                {currentStepData?.subtitle}
              </Typography>
            </>
          ) : null}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schemaValidation[currentStep]}
          >
            {() => {
              return (
                <Form className={classes.form}>
                  {stepsData[currentStep]?.id === 'welcome' ? (
                    <OnboardingWelcome handleNextStep={handleNextStep} />
                  ) : null}
                  {stepsData[currentStep]?.id === 'role' ? (
                    <OnboardingRole handleNextStep={handleNextStep} />
                  ) : null}
                  {stepsData[currentStep]?.id === 'data' ? (
                    <OnboardingPersonalize loading={loading} />
                  ) : null}
                  {stepsData[currentStep]?.id === 'select_action' ? (
                    <OnboardingCongratulations />
                  ) : null}
                </Form>
              )
            }}
          </Formik>
        </div>
      </Page>
    </MainContainer>
  ) : null
}

export default Onboarding
