import { useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core'

import useModals from 'components/App/ModalsManager/useModals'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import DeleteWorkCenter from './Modals/DeleteWorkCenter'
import EditOptions from './Modals/EditOptions'
import WorkCenterHistory from './Modals/WorkCenterHistory'
import WorkCenterModal from './Modals/WorkCenterModal'
import { getActions, workCentersColumns } from './helpers'

const useStyles = makeStyles(() => ({
  containerTable: {
    marginTop: 48,
  },
}))

const WorkCenters = () => {
  const classes = useStyles()
  const modals = useModals()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const workCentersCache = queryClient.getQueryData([
    'getWorkCenters',
    companyId,
  ])

  const handleCloseModal = () => modals.closeAll()

  const handleNewWorkCenter = (workCenterToUpdate, isNewHistory) => {
    const isEdit = !!workCenterToUpdate

    modals.openModal({
      id: 'addWorkCenter',
      content: (
        <WorkCenterModal
          handleCloseModal={handleCloseModal}
          workCenterToUpdate={workCenterToUpdate}
          isEdit={isEdit}
          isNewHistory={isNewHistory}
        />
      ),
      modalProps: {
        header: `${isEdit ? 'Editar' : 'Crear'} centro de trabajo`,
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          maxWidth: false,
        },
      },
    })
  }

  const handleShowHistory = (workCenterId) => {
    modals.openModal({
      id: 'workCenterHistory',
      content: <WorkCenterHistory workCenterId={workCenterId} />,
      modalProps: {
        header: 'Historial centro de trabajo',
        onCloseModal: handleCloseModal,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'lg',
        },
      },
    })
  }

  const handleDeleteWorkCenter = (workCenterId) => {
    modals.openModal({
      id: 'deleteWorkCenter',
      content: (
        <DeleteWorkCenter
          workCenterId={workCenterId}
          handleCloseModal={handleCloseModal}
        />
      ),
      modalProps: {
        header: 'Eliminar centro de trabajo',
        onCloseModal: handleCloseModal,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const handleEditWorkCenter = (workCenterToUpdate) => {
    modals.openModal({
      id: 'editWorkCenter',
      content: (
        <EditOptions
          handleCloseModal={handleCloseModal}
          handleNewWorkCenter={handleNewWorkCenter}
          workCenterToUpdate={workCenterToUpdate}
        />
      ),
      modalProps: {
        header: 'Editar centro de trabajo',
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'md',
        },
      },
    })
  }

  const actions = getActions(
    handleNewWorkCenter,
    handleShowHistory,
    handleDeleteWorkCenter,
    handleEditWorkCenter
  )

  return (
    <div className={classes.containerTable}>
      <Table
        columns={workCentersColumns}
        data={workCentersCache?.data || []}
        actions={actions}
        options={{ pagination: false, search: false }}
      />
    </div>
  )
}

export default WorkCenters
