import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import { makeStyles } from '@material-ui/core'
import MTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import LoadingBackdrop from '../Loading/LoadingBackdrop'
import EmptyState from './EmptyState'
import TablePagination from './Pagination'
import DeleteRow from './Row/DeleteRow'
import EditRow from './Row/EditRow'
import RowActions from './Row/RowActions'
import RowCell from './Row/RowCell'
import RowCheckbox from './Row/RowSelectionCheckbox'
import TableToolbar from './Toolbar/Toolbar'
import {
  getConditionSelectHeaderCheckbox,
  getCustomHeaderProps,
  getGlobalFilterConfig,
  getHeaderWrapperProps,
  getPaginationConfig,
  getSortingConfig,
  useAddRow,
  useAsyncData,
  useEditableTable,
  useSortTypes,
  useTableStyles,
} from './helpers'

export const tableStyles = makeStyles((theme) => ({
  checkbox: {
    backgroundColor: 'initial',
    border: '0 !important',
  },
  tableContainer: {
    position: 'relative',
  },
  container: {
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: 8,
    },
    '& ::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .3)',
    },
  },
  tableWrapper: {
    position: 'relative',
    overflowX: 'auto',
  },
  tableWidth: {
    minWidth: (props) => props.tableMinWidth || props.minWidth,
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  flatTable: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  editRowActive: {
    transition: 'all 300ms ease 0s',
    opacity: 0.2,
    pointerEvents: 'none',
  },
  editRowInactive: {
    transition: 'all 300ms ease 0s',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCenter: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerRight: {
    flexDirection: 'row-reverse',
    textAlign: 'right',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellCenter: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  cellRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  cellColumnDirection: {
    flexDirection: 'column',
  },
  actionsContainer: {
    whiteSpace: 'noWrap',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stickyTable: {
    borderCollapse: 'collapse',
  },
  stickyContainer: {
    overflow: 'initial',
  },
}))

// For docs and examples see: https://github.com/Nominapp/technology_handbook/blob/master/desarrollo/frontend/inhouse_table.md

const Table = ({
  data = [],
  columns,
  actions,
  components = {},
  options = {},
  initialConfig = {},
  filterChanged,
  editable = {},
  classNames = {},
  ...props
}) => {
  const { getHeaderStyles } = useTableStyles()
  const customSortTypes = useSortTypes()
  const asyncData = typeof data === 'function'
  const { tableContainer } = classNames

  // Destructuring custom components and replace the default ones if they exist
  const {
    Toolbar: ProvidedToolbar,
    ActionButton: ProvidedActionButton,
  } = components
  const Toolbar = ProvidedToolbar || TableToolbar

  // Destructuring table options
  const {
    customActionsWidth,
    toolbar: showToolbar = true,
    search: showSearch = true,
    sorting: showSorting = true,
    pagination: showPagination = true,
    selection: showSelection = false,
    selectionId,
    rowStyle = () => {},
    alignActionsHeader = 'center',
    alignActionsCell = 'left',
    hiddenColumns = [],
    emptyState,
    flatStyle = false,
    rowsSize = 20,
    expandableRows = false,
    dataCyPrefix,
    customQueryFetch = null,
    customFilterFetch = null,
    expandedInitialState, // If passed, must be memoized
    stickyHeader = false,
    minWidth, // TODO: replace tableMinWidth by minWidth in tables that use it
  } = options

  // Destructuring async function
  const {
    tableQuery,
    dispatchAsyncDataAction,
    currentPageIndex,
    currentTotalCount,
  } = useAsyncData(data, asyncData, customFilterFetch)

  const [currentData, setCurrentData] = useState([])

  // Set custom query when some data comes into data
  const tableQueryCustom =
    customQueryFetch !== null && asyncData && tableQuery.isSuccess
      ? tableQuery?.data[customQueryFetch]
      : tableQuery?.data
  const derivedData = useMemo(
    () => (asyncData ? tableQueryCustom || [] : data),
    [asyncData, data, tableQueryCustom]
  )

  // Set custom filter when come into data
  const tableFiltersCustom =
    customFilterFetch !== null && asyncData && tableQuery.isSuccess
      ? tableQuery?.data[customFilterFetch]
      : tableQuery?.filters

  useEffect(() => {
    if (derivedData.length >= 0) {
      setCurrentData([...derivedData])
    }
  }, [derivedData])

  // Memoize table data
  const dataMemoized = useMemo(() => currentData, [currentData])

  // Memoize table columns
  const columnsMemoized = useMemo(() => columns, [columns])

  // Editable row
  const {
    isTableEditable,
    tableMinWidth,
    handleDeleteRow,
    handleEditRow,
    onCancelEdit,
    onConfirmEdit,
    isDeletable,
    isEditable,
    isEditingRow,
    isDeletingRow,
    editionLoading,
    deleteRowColSpan,
    validationSchema,
    isEditionActive,
    editMode,
    onChangeEditMode,
    hideEdit,
    hideDelete,
  } = useEditableTable({
    configuration: editable,
    columnsLength: columns.length,
    setCurrentData,
  })

  // Table actions
  let generalActions = []
  let rowActions = []
  if (actions) {
    generalActions = actions?.filter((action) => action?.isFreeAction)
    rowActions = actions?.filter((action) => !action?.isFreeAction)
  }

  const classes = tableStyles({ tableMinWidth, minWidth })

  const paginationConfig = getPaginationConfig(
    showPagination,
    asyncData,
    currentPageIndex,
    currentTotalCount,
    rowsSize
  )
  const globalFilterConfig = getGlobalFilterConfig(
    showSearch,
    showToolbar,
    asyncData
  )
  const sortingConfig = getSortingConfig(showSorting)

  const table = useTable(
    {
      columns: columnsMemoized,
      data: dataMemoized,
      sortTypes: customSortTypes,
      ...paginationConfig,
      initialState: {
        ...paginationConfig?.initialState,
        globalFilter: '',
        hiddenColumns,
        expanded: expandedInitialState || {},
      },
      ...globalFilterConfig,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      ...sortingConfig,
      ...initialConfig,
      handleDeleteRow,
      handleEditRow,
      isTableEditable,
      isDeletable,
      isEditable,
      hideEdit,
      hideDelete,
    },
    ...(showSearch && showToolbar ? [useGlobalFilter] : []),
    ...(showSorting ? [useSortBy] : []),
    ...(expandableRows ? [useExpanded] : []),
    ...(showPagination ? [usePagination] : []),
    ...(showSelection ? [useRowSelect] : []),
    (hooks) => {
      hooks.allColumns.push((allColumns) => [
        ...(showSelection
          ? [
              {
                id: 'selection',
                Header: (column) => {
                  const checkboxProps = getConditionSelectHeaderCheckbox({
                    headerProps: column,
                    showPagination,
                  })

                  column.rows.forEach(({ isSelected, original }) => {
                    const rowCopy = original

                    rowCopy.selection = isSelected
                  })

                  return (
                    <div>
                      <RowCheckbox
                        className={classes.checkbox}
                        {...checkboxProps}
                      />
                    </div>
                  )
                },
                Cell: ({ row }) => (
                  <div>
                    <RowCheckbox
                      className={classes.checkbox}
                      disabled={row.original.disableCheck}
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
                excludeOnEdit: true,
              },
            ]
          : []),
        ...allColumns,
        ...(rowActions.length > 0 || isTableEditable
          ? [
              {
                id: 'actions',
                Header: 'Acciones',
                Cell: ({
                  row,
                  handleDeleteRow: deleteRow,
                  handleEditRow: editRow,
                  isTableEditable: editableTable,
                  isDeletable: isRowDeletable,
                  isEditable: isRowEditable,
                  hideEdit: hideEditRow,
                  hideDelete: hideDeleteRow,
                }) => (
                  <RowActions
                    actions={rowActions}
                    row={row}
                    ProvidedActionButton={ProvidedActionButton}
                    deleteRow={deleteRow}
                    editRow={editRow}
                    editableTable={editableTable}
                    isDeletable={isRowDeletable}
                    isEditable={isRowEditable}
                    hideEdit={hideEditRow}
                    hideDelete={hideDeleteRow}
                    dataCyPrefix={dataCyPrefix}
                  />
                ),
                customWidth: customActionsWidth,
                excludeOnEdit: true,
                alignActionsHeader,
                alignActionsCell,
              },
            ]
          : []),
      ])
    }
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setGlobalFilter,
    toggleRowSelected,
    isAllRowsSelected,
    state: { pageIndex, pageSize, globalFilter },
    allColumns,
  } = table
  const isTableEmpty =
    dataMemoized?.length === 0 || (showPagination ? page : rows)?.length === 0

  const emptyTableColSpan = allColumns.length
  const isTableLoading = tableQuery.isLoading || editionLoading || false

  // Add new row action if table is editable and add new row is enabled
  const { enableAddRow, addRowAction } = useAddRow({
    generalActions,
    editableConfig: editable,
    isEditionActive,
    setCurrentData,
    allColumns,
    handleEditRow,
    dataLength: dataMemoized.length,
    onChangeEditMode,
  })

  if (enableAddRow) {
    generalActions.push(addRowAction)
  }

  const handleChangePage = (newPage) => {
    gotoPage(newPage)
  }

  // To fix bug when selection is enabled
  useEffect(() => {
    rows.forEach(({ id, original }) => {
      if (selectionId && selectionId in original) {
        toggleRowSelected(id, original[selectionId])
      }
    })
  }, [rows, selectionId, toggleRowSelected])

  // Update currentPageIndex
  useEffect(() => {
    dispatchAsyncDataAction({ type: 'page_changed', payload: pageIndex })
  }, [dispatchAsyncDataAction, pageIndex])

  // Reset currentPageIndex when external filter changes
  useEffect(() => {
    dispatchAsyncDataAction({ type: 'page_changed', payload: 0 })
  }, [dispatchAsyncDataAction, filterChanged])

  // Update currentSearch when globalFilter changes
  useEffect(() => {
    dispatchAsyncDataAction({
      type: 'search_changed',
      payload: globalFilter || '',
    })
  }, [dispatchAsyncDataAction, globalFilter])

  return (
    <div className={clsx(classes.tableContainer, classes.container)} {...props}>
      <TableContainer
        className={clsx(classes.container, {
          [classes.flatTable]: flatStyle,
          [tableContainer]: tableContainer,
        })}
      >
        {showToolbar && (
          <Toolbar
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            freeActions={generalActions}
            showSearch={showSearch}
            isAllRowsSelected={isAllRowsSelected}
            rows={showPagination ? page : rows}
          />
        )}
        <div
          className={clsx(classes.tableWrapper, {
            [classes.stickyContainer]: stickyHeader,
          })}
        >
          <MTable
            aria-label="Nominapp table"
            {...getTableProps()}
            className={clsx(classes.tableWidth, {
              [classes.table]: isTableEditable,
              [classes.stickyTable]: stickyHeader,
            })}
            stickyHeader={stickyHeader}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const isSortingEnabled =
                      column.id !== 'selection' && column.canSort && showSorting
                    return (
                      <TableCell
                        {...getCustomHeaderProps(
                          column,
                          customActionsWidth,
                          isTableEditable,
                          showSorting
                        )}
                      >
                        <div
                          className={getHeaderStyles(column)}
                          {...getHeaderWrapperProps(column)}
                        >
                          {isSortingEnabled ? (
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSortedDesc ? 'desc' : 'asc'}
                            >
                              {column.render('Header')}
                            </TableSortLabel>
                          ) : (
                            column.render('Header')
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {isTableEmpty ? (
                <EmptyState
                  colSpan={emptyTableColSpan}
                  configuration={emptyState}
                  loading={isTableLoading}
                />
              ) : null}
              {!isTableEmpty &&
                (showPagination ? page : rows).map((row) => {
                  const providedRowStyle = rowStyle(row.original, row)
                  prepareRow(row)

                  if (isEditingRow(row.index)) {
                    return (
                      <EditRow
                        {...row.getRowProps()}
                        rowIndex={row.index}
                        cancelEditRow={onCancelEdit}
                        confirmEditRow={onConfirmEdit}
                        cells={row.cells}
                        rowValues={row.original}
                        validationSchema={validationSchema}
                        editMode={editMode}
                        setCurrentData={setCurrentData}
                        customActionsWidth={customActionsWidth}
                        alignActionsCell={alignActionsCell}
                      />
                    )
                  }

                  if (isDeletingRow(row.index)) {
                    return (
                      <DeleteRow
                        {...row.getRowProps()}
                        rowIndex={row.index}
                        colSpan={deleteRowColSpan}
                        cancelEditRow={onCancelEdit}
                        confirmEditRow={onConfirmEdit}
                        rowValues={row.original}
                        customActionsWidth={customActionsWidth}
                        alignActionsCell={alignActionsCell}
                      />
                    )
                  }

                  return (
                    <TableRow
                      {...row.getRowProps()}
                      className={clsx({
                        [classes.editRowActive]: isEditionActive,
                        [classes.editRowInactive]: !isEditionActive,
                      })}
                      style={providedRowStyle}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            <RowCell cell={cell} />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </MTable>
        </div>
      </TableContainer>
      {/* Disable table when is loading */}
      {isTableLoading ? (
        <LoadingBackdrop
          open={isTableLoading}
          showLoader={!isTableEmpty}
          className={classes.backdrop}
        />
      ) : null}
      {/* Pagination is outside of TableContainer to follow styles of original table */}
      {showPagination && (
        <TablePagination
          count={
            tableQuery.isSuccess
              ? tableFiltersCustom?.total_records
              : dataMemoized.length
          }
          page={pageIndex}
          pageSize={pageSize}
          onChangePage={handleChangePage}
        />
      )}
    </div>
  )
}

export default Table
