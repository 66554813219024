import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Link from 'components/UI/MaterialUI/Link'
import NoWrap from 'components/UI/NoWrap'

import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

import CellDeductions from './Columns/CellDeductions'
import CellIncome from './Columns/CellIncome'
import CellNovelties from './Columns/CellNovelties'
import CellOvertime from './Columns/CellOvertime'
import CellWorkedDays from './Columns/CellWorkedDays'
import CellWorkedHours from './Columns/CellWorkedHours'

function getBaseSalaryText(workerType, classes) {
  let text

  switch (workerType) {
    case 'contractor':
      text = 'Honorarios base'
      break
    case 'apprentice':
      text = 'Cuota de apoyo'
      break
    case 'student':
      text = 'Pago estudiante'
      break
    default:
      text = (
        <div className={classes.headerContainer}>
          <Typography variant="body2" className={classes.headerText}>
            Salario base
          </Typography>
          <Tooltip title="Este es el salario base mensual" arrow>
            <InfoOutlinedIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
      )
      break
  }

  return text
}

function getWorkerPaymentText(workerType) {
  let text

  switch (workerType) {
    case 'contractor':
      text = 'Pago contratista'
      break
    case 'apprentice':
      text = 'Pago aprendiz'
      break
    case 'student':
      text = 'Pago a estudiante'
      break
    default:
      text = 'Pago empleado'
      break
  }

  return text
}

export const getColumnsData = (
  workerType,
  isWorkedHours,
  customSort,
  classes
) => {
  const withHolidays = !['contractor', 'apprentice'].includes(workerType)

  const columns = [
    {
      Header: 'Personas',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <Link to={routes.WORKER_SHOW(row.original.worker_id)}>
          {row.original.worker_name}
        </Link>
      ),
    },
    {
      Header: getBaseSalaryText(workerType, classes),
      accessor: 'base_salary',
      Cell: ({ row }) => (
        <NoWrap>{formatCurrency(row.original.base_salary)}</NoWrap>
      ),
      alignHeader: 'right',
      alignCell: 'right',
    },
    ...(workerType === 'part_time_contract'
      ? [
          {
            Header: 'Días laborados',
            accessor: 'worked_time',
            Cell: ({ row }) => <CellWorkedDays payroll={row.original} />,
            alignHeader: 'right',
            alignCell: 'right',
          },
        ]
      : []),
    {
      Header: 'Horas extras y recargos',
      accessor: 'overtime_hours',
      hidden: ![
        'employee',
        'pensioner',
        'terminated',
        'part_time_contract',
      ].includes(workerType),
      Cell: ({ row }) => {
        const cellData = {
          ...row.original,
          tableData: {
            id: row.index,
          },
        }
        return <CellOvertime payroll={cellData} />
      },
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Horas trabajadas',
      accessor: 'worked_time',
      hidden: !isWorkedHours || workerType !== 'contractor',
      Cell: ({ row }) => <CellWorkedHours payroll={row.original} />,
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: withHolidays
        ? 'Vacaciones, Incap y Lic'
        : 'Incapacidades y Licencias',
      accessor: 'novelties_days',
      hidden: workerType === 'contractor',
      Cell: ({ row }) => {
        const cellData = {
          ...row.original,
          tableData: {
            id: row.index,
          },
        }
        return <CellNovelties payroll={cellData} />
      },
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: workerType === 'contractor' ? 'Ingresos' : 'Ingresos adicionales',
      accessor: 'salary_income',
      Cell: ({ row }) => {
        const cellData = {
          ...row.original,
          tableData: {
            id: row.index,
          },
        }
        return <CellIncome payroll={cellData} />
      },
      sortType: customSort,
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Deducc, prést y ReteFuen',
      accessor: 'deductions',
      Cell: ({ row }) => {
        const cellData = {
          ...row.original,
          tableData: {
            id: row.index,
          },
        }
        return <CellDeductions payroll={cellData} />
      },
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: getWorkerPaymentText(workerType),
      accessor: 'worker_payment',
      Cell: ({ row }) => formatCurrency(row.original.worker_payment),
      alignHeader: 'right',
      alignCell: 'right',
    },
    // Hidden column to search by worker_id_number
    {
      accessor: 'worker_id_number',
    },
  ]

  const visibleColumns = columns.filter((column) => !column.hidden)

  return visibleColumns
}

export default getColumnsData
