import { Typography } from '@material-ui/core'

import Table from 'components/UI/Table/Table'

import getColumnsDataIndex from './helpers'

const ErrorIndex = ({ errors }) => {
  const columnsDataIndex = getColumnsDataIndex()

  return (
    <>
      <Typography>Ha ocurrido un error con las siguientes personas</Typography>
      <Table
        columns={columnsDataIndex}
        data={errors}
        options={{ toolbar: false, pagination: false }}
      />
    </>
  )
}

export default ErrorIndex
