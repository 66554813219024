import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getHolidays } from 'services/worker/holidaysService'

import * as routes from 'config/routes'

import columnsData from './columnsData'

const useStyles = makeStyles(() => ({
  list: {
    fontSize: 'smaller',
    paddingTop: '5rem',
    paddingLeft: '1rem',
  },
}))

const WorkerHolidays = ({ match }) => {
  const { worker } = useWorker()
  const { workerId } = match.params

  const classes = useStyles()

  const fetchHolidays = (pageIndex) => {
    return {
      queryKey: ['getHolidays', pageIndex + 1],
      queryFunction: () => getHolidays(workerId, { page: pageIndex + 1 }),
    }
  }

  return (
    <Page
      documentTitle={`Historial de Vacaciones de ${
        worker ? worker.fullName : 'trabajador'
      }`}
      header={
        <Typography variant="h5">
          Historial de Vacaciones de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
    >
      <Table
        columns={columnsData}
        data={fetchHolidays}
        options={{ toolbar: false }}
      />
      <ul className={classes.list}>
        <li>
          Las vacaciones acumuladas del <strong>periodo actual</strong> se suman
          cuando{' '}
          <strong>
            se genere el período siguiente o cuando se liquida al empleado
          </strong>
        </li>
        <li>
          Para ver el cálculo de las vacaciones acumuladas de cada período:
          Historial de períodos {'>'} Selecciona el período {'>'} Busca la
          persona {'>'}
          click en Ver Cálculos {'>'} Provisión Prestaciones Sociales.
        </li>
        <li>
          Para ver el detalle de las vacaciones difrutadas y/o compensadas por
          el persona, haz{' '}
          <Link to={routes.WORKER_NOVELTIES_HISTORY(workerId)}>click aquí</Link>
          .
        </li>
        <li>
          Nominapp se basa, en primer lugar, en los días de vacaciones
          difrutadas que se registraron cuando{' '}
          <strong>se agregó el empleado a Nominapp</strong>, en la pregunta
          &ldquo;¿Cuántos días ha disfrutado el empleado desde que
          ingresó?&rdquo;.
        </li>
      </ul>
    </Page>
  )
}

export default WorkerHolidays
