import SvgIcon from '@material-ui/core/SvgIcon'

function HistoryPayrolls(props) {
  return (
    <SvgIcon {...props} width="28" height="18" viewBox="0 0 28 18">
      <path d="M7.33366 4H0.666992V1.33333H7.33366V4ZM7.33366 8H0.666992V10.6667H7.33366V8ZM25.4537 17.3333L20.347 12.2267C19.2803 12.92 18.027 13.3333 16.667 13.3333C12.987 13.3333 10.0003 10.3467 10.0003 6.66667C10.0003 2.98667 12.987 0 16.667 0C20.347 0 23.3337 2.98667 23.3337 6.66667C23.3337 8.02667 22.9203 9.28 22.227 10.3333L27.3337 15.4533L25.4537 17.3333ZM20.667 6.66667C20.667 4.46667 18.867 2.66667 16.667 2.66667C14.467 2.66667 12.667 4.46667 12.667 6.66667C12.667 8.86667 14.467 10.6667 16.667 10.6667C18.867 10.6667 20.667 8.86667 20.667 6.66667ZM0.666992 17.3333H14.0003V14.6667H0.666992V17.3333Z" />
    </SvgIcon>
  )
}

export default HistoryPayrolls
