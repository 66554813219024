import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import CostsInPercentagesItem from './CostsInPercentagesItem'
import PayrollRange from './PayrollRange'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    gridAutoRows: 'minmax(300px, max-content)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    gridGap: theme.spacing(2),
  },
}))

const DashboardCostsInPercentages = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const queryKey = [
    'dashboardByMetrics',
    'costs_in_percentages',
    getCompanyId(),
  ]
  const { data: costsInPercentagesCache } =
    queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'costs_in_percentages',
    },
    queryOptions: {
      enabled: !isDataCached(costsInPercentagesCache),
    },
  })
  const { costs_in_percentages: costsInPercentages } =
    costsInPercentagesCache || {}

  const hasDataToRender =
    costsInPercentages?.initial_day && costsInPercentages?.end_day
      ? costsInPercentages
      : null

  // filtered keys with empty arrays
  const filteredKeys = useMemo(() => {
    if (!costsInPercentages) return []

    const keys = []

    const types = Object.keys(costsInPercentages)

    for (let i = 2; i < types.length; i += 1) {
      // stars from 2, ignore 'initial_day' and 'end_day'
      const type = types[i]
      const item = costsInPercentages[type]

      if (item.length > 0) {
        keys.push(type)
      }
    }

    return keys
  }, [costsInPercentages])

  if (dashboardQuery?.isLoading) return <LoadingBox />

  if (hasDataToRender) {
    return (
      <Box>
        <PayrollRange costsInPercentages={costsInPercentages} />
        <div className={classes.grid}>
          {filteredKeys.map((type) => {
            return costsInPercentages[type].length > 0 ? (
              <CostsInPercentagesItem
                key={type}
                type={type}
                costsInPercentages={costsInPercentages}
              />
            ) : null
          })}
        </div>
      </Box>
    )
  }

  return null
}

export default DashboardCostsInPercentages
