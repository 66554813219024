import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import {
  generateWageBaseSalaryValidationSchema,
  getMinimumSalary,
} from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema(
  'category'
)

const getValidationSchema = (documentType) => {
  const documentNoPension = ['ce_no_pension', 'pe_no_pension'].includes(
    documentType
  )

  return yup.object({
    category: yup.string().trim().required(),
    term: yup.string().trim().required(),
    initial_day: yup.date().nullable().default(null).required(),
    end_day: yup
      .date()
      .nullable()
      .default(null)
      .when('term', {
        is: 'fixed',
        then: yup.date().nullable().default(null).required(),
        otherwise: yup.date().nullable().default(null).notRequired(),
      }),
    wage_category: yup.string().nullable().required(),
    base_salary: wageBaseSalaryValidationSchema,
    transport_subsidy: yup
      .boolean()
      .nullable()
      .when(['base_salary', 'category'], {
        is: (baseSalary, category) =>
          category !== 'contractor' &&
          Number(baseSalary) < getMinimumSalary({ multiplier: 2 }),
        then: yup.boolean().nullable().required(),
      }),
    work_center: yup
      .object()
      .nullable()
      .when('category', {
        is: (category) =>
          category &&
          category !== 'contractor' &&
          category !== 'schooling_trainee',
        then: yup.object().nullable().required(),
      }),
    high_risk_pension: yup
      .boolean()
      .nullable()
      .when('work_center', {
        is: (workCenter) => Boolean(workCenter?.name?.includes('5')),
        then: yup.boolean().nullable().required(),
      }),
    health_provider: yup
      .object()
      .nullable()
      .when('category', {
        is: (category) =>
          category &&
          !['contractor', 'student_decree_055', 'part_time_contract'].includes(
            category
          ),
        then: yup.object().nullable().required(),
      }),
    pension_provider: yup
      .object()
      .nullable()
      .when('category', {
        is: (category) =>
          documentNoPension
            ? false
            : category &&
              (category === 'employee' || category === 'student_law_789'),
        then: yup.object().nullable().required(),
      }),
    severance_provider: yup
      .object()
      .nullable()
      .when(['category', 'wage_category'], {
        is: (category, wageCategory) =>
          category &&
          wageCategory &&
          (category === 'employee' || category === 'pensioner') &&
          wageCategory !== 'integral_salary',
        then: yup.object().nullable().required(),
      }),
  })
}

export default getValidationSchema
