import { Form, Formik } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import queryString from 'query-string'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import useModalExtraWorker from 'components/Subscription/Atoms/ExtraWorkersPayment/useModalExtraWorker'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import PaperForm from 'components/UI/Form/PaperForm'
import useFormStyles from 'components/UI/Form/formStyles'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import Page from 'components/UI/Page/Page'

import { findFirstErrorStringKey } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useAutoScroll from 'utils/hooks/useAutoScroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { WORKER_CREATE, WORKER_INDEX, WORKER_SHOW } from 'config/routes'

import FieldsBasic from './Fields/Basic/Basic'
import FieldsPayment from './Fields/Payment'
import FieldsPersonal from './Fields/Personal/Personal'
import Header from './Header'
import { formatWorker, getWorkerDirtyValues } from './helpers'
import stepsData from './stepsData'

export const WorkerFormContext = createContext()

const WorkerForm = ({ match, history, location: { state } }) => {
  const classes = useFormStyles()
  const queryClient = useQueryClient()
  const { workerId } = match.params
  const isCreating = workerId && workerId !== 'new'
  const location = useLocation()
  const { redirect } = queryString.parse(location.search)
  const {
    showNewSubscription,
    additionalWorkersInfo,
    subscription,
  } = useSubscription()
  const modalExtraWorker = useModalExtraWorker()
  const modalRef = useRef(true)

  const openModal = useCallback(() => modalExtraWorker.openModal(), [
    modalExtraWorker,
  ])

  const hasToPayNewWorkers =
    !additionalWorkersInfo?.add_workers && subscription?.type === 'year'

  const [currentStep, setCurrentStep] = useState(0)
  const [progressStep, setProgressStep] = useState(0)
  const [worker, setWorker] = useState(() => formatWorker({}))

  const [payrollConcepts, setPayrollConcepts] = useState({
    salary_income: [],
    non_salary_income: [],
    deductions: [],
  })

  const [isLoading, setIsLoading] = useState(true)

  const { showSuccessMessage, showWarningMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const autoScroll = useAutoScroll()

  const queryKey = ['getWorkerById', workerId]
  const workerDataCache = queryClient.getQueryData(queryKey)

  const { workerQuery, workerMutation } = useWorkerService({
    serviceParams: { queryKey, workerId },
    queryOptions: {
      enabled: Boolean(isCreating),
      onSuccess: ({ data }) => {
        if (data) {
          queryClient.setQueryData(queryKey, formatWorker(cloneDeep(data)))
        }
      },
    },
  })

  useEffect(() => {
    if (workerId === ':workerId') {
      history.push(WORKER_INDEX())
    }
  }, [currentStep, history, isCreating, workerId])

  useEffect(() => {
    const fetchWorkerData = async () => {
      setIsLoading(true)

      if (isCreating && workerQuery.status === 'success') {
        let step = 0
        let progressStepFetch = 0

        while (step < stepsData.length) {
          const { schemaValidation } = stepsData[step]

          try {
            // eslint-disable-next-line no-await-in-loop
            await schemaValidation.validate(workerDataCache)
            step += 1
            progressStepFetch += 1
          } catch (error) {
            break
          }
        }

        if (step >= stepsData.length) step = stepsData.length - 1

        ReactDOM.unstable_batchedUpdates(() => {
          if (
            state &&
            state.initialStep !== undefined &&
            state.initialStep <= step // must not go further from step that needs to be completed
          ) {
            setCurrentStep(state.initialStep)
          } else {
            setCurrentStep(step)
          }

          setProgressStep(progressStepFetch)
          setWorker(workerDataCache)
          setIsLoading(false)
        })
      } else {
        setIsLoading(false)
      }
    }

    fetchWorkerData()
  }, [isCreating, state, workerDataCache, workerQuery.status])

  // When users navigate into a direct route and need to pay workers
  useEffect(() => {
    if (modalRef.current && showNewSubscription && hasToPayNewWorkers) {
      openModal()
      modalRef.current = false
    }
  }, [hasToPayNewWorkers, openModal, showNewSubscription])

  const handleClickStep = (index) => {
    setCurrentStep(index)
  }

  const handlePreviousStep = () => {
    setCurrentStep((previous) => previous - 1)
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { object: 'worker' },
        { object: 'id_number' },
        { object: 'email' },
        // error returned when a user update the end_date of a contract but it can't
        // be done because the deductions exceed the value to be paid to the worker
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })

    form.setSubmitting(false)
  }

  const callbackSuccess = async (values, workerResponse) => {
    await queryClient.invalidateQueries(['getWorkerById', workerResponse.id])
    if (currentStep < stepsData.length - 1) {
      // to reset formik status
      setWorker({ ...values, id: workerResponse?.id || workerId })

      setCurrentStep((previousStep) => previousStep + 1)

      if (currentStep === progressStep)
        setProgressStep((previousStep) => previousStep + 1)

      // if is the fist step and its creating a new worker,
      // change the url too
      if (currentStep === 0 && !isCreating) {
        trackEvent(integrationEvent.EMPLOYEE_REGISTER, null, 'single')
        if (redirect) {
          history.push(
            `${WORKER_CREATE(workerResponse?.id)}?redirect=onboarding`
          )
        } else {
          history.push(WORKER_CREATE(workerResponse?.id))
        }
      }
    } else {
      if (redirect) {
        history.push(WORKER_INDEX('?onboarding=new'))
      } else {
        history.push(WORKER_SHOW(workerResponse?.id || workerId))
      }
      showSuccessMessage('El trabajador fue creado exitosamente')
    }
  }

  const handleSubmit = (values, form) => {
    const { dirtyWorker } = getWorkerDirtyValues(worker, values, isCreating)

    if (!isObjectEmpty(dirtyWorker)) {
      if (isCreating) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker: {
              id: workerId,
              ...dirtyWorker,
            },
            workerId,
          },
          {
            onSuccess: ({ data }) => callbackSuccess(values, data),
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      } else {
        workerMutation.mutate(
          {
            mutationMethod: 'POST',
            worker: { worker: { ...dirtyWorker } },
          },
          {
            onSuccess: ({ data }) => {
              callbackSuccess(values, data)
            },
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      }
    } else {
      setCurrentStep((previousStep) => previousStep + 1)
    }
  }

  const handleFormErrors = (errors, setFieldTouched) => {
    Object.keys(errors).forEach((errorKey) => {
      setFieldTouched(errorKey, true)
    })
    showWarningMessage('Existen errores en algunos de los campos.')

    const firstErrorName = findFirstErrorStringKey(errors, true)

    autoScroll(document.querySelector(`input[name="${firstErrorName}"]`))
  }

  return (
    <WorkerFormContext.Provider value={{ payrollConcepts, setPayrollConcepts }}>
      <Page
        documentTitle="Crear nueva Persona"
        header={
          <Header
            title="Crear nueva Persona"
            description="La información del empleado será utilizada para ayudarte a generar la nómina más rápida que has visto, recuerda que siempre podrás regresar a editar cualquier valor."
          />
        }
        isLoading={isLoading || workerQuery.isLoading}
      >
        <PaperForm
          steps={{
            stepsData,
            currentStep,
            progressStep,
            onChangeStep: handleClickStep,
          }}
        >
          <div>
            {(!isLoading || !workerQuery.isLoading) && (
              <Formik
                initialValues={worker}
                onSubmit={handleSubmit}
                validationSchema={stepsData[currentStep]?.schemaValidation}
                enableReinitialize
              >
                {(form) => {
                  const {
                    errors,
                    handleSubmit: onSubmitForm,
                    isValid,
                    setFieldTouched,
                  } = form

                  return (
                    <>
                      <Form>
                        {currentStep === 0 && <FieldsBasic />}
                        {currentStep === 1 && <FieldsPayment />}
                        {currentStep === 2 && <FieldsPersonal />}
                      </Form>
                      <div className={classes.actionsContainer}>
                        {currentStep !== 0 ? (
                          <Button
                            variant="outlined"
                            onClick={handlePreviousStep}
                          >
                            Volver al paso anterior
                          </Button>
                        ) : null}
                        <Button
                          onClick={() => {
                            if (!isValid) {
                              handleFormErrors(errors, setFieldTouched)
                              return
                            }
                            onSubmitForm()
                          }}
                          disabled={workerMutation.isLoading}
                        >
                          Guardar y continuar
                        </Button>
                      </div>
                      {workerMutation.isLoading && <LoadingModal />}
                    </>
                  )
                }}
              </Formik>
            )}
          </div>
        </PaperForm>
      </Page>
    </WorkerFormContext.Provider>
  )
}

export default WorkerForm
