import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import CircularProgress from '@material-ui/core/CircularProgress'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import messages from 'messages/electronic_payroll'

import TutorialCard from '../TutorialCard'
import { tutorialCardsActivationDian, useStatusMessages } from '../helpers'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(2.5),
  },
  tutorialContent: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3.5),
  },
  inputContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 200px',
    alignItems: 'end',
    justifyItems: 'end',
    gap: theme.spacing(4),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.between(960, 1024)]: {
      gridTemplateColumns: '1fr',
    },
  },
  sendCodeButton: {
    width: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('sm')]: {
      width: '220px',
    },
  },
  statusAlert: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '670px',
    },
  },
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1.375),
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
  },
}))

const DianActivation = ({ isEditting }) => {
  const classes = useStyles()
  const { validateForm, values, setFieldValue } = useFormikContext()
  const { listenInBackground, refreshCompany } = useUser()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const {
    showInfoMessage,
    showSuccessMessage,
    showErrorMessage,
  } = useNotifications()
  const [showMesaggeAlert, setShowMesaggeAlert] = useState(false)
  const isComponentMounted = useRef(false)
  const { closeSnackbar } = useSnackbar()
  const { showStatusMessages } = useStatusMessages()
  const electronicPayrollConfigurationCache = queryClient.getQueryData([
    'electronicPayrollStatus',
    companyId,
  ])
  const electronicPayrollConfigurationData =
    electronicPayrollConfigurationCache?.data
  const isResponseInProgress = ['waiting_response', 'pending_to_send'].includes(
    electronicPayrollConfigurationData?.government_enabled_status
  )

  const isCompanyAuthorized =
    electronicPayrollConfigurationData?.government_enabled_status ===
    'authorized'
  const isCompanyUnauthorized = ['rejected', 'failed', 'unauthorized'].includes(
    electronicPayrollConfigurationData?.government_enabled_status
  )

  const {
    electronicPayrollConfigurationMutation,
  } = useElectronicPayrollConfigurationService({
    serviceParams: {
      queryKey: ['electronicPayrollStatus', companyId],
    },
    queryOptions: {
      enabled: !electronicPayrollConfigurationCache,
      onSuccess: ({ data }) => {
        setFieldValue('test_set_code', data.test_set_code)
      },
    },
  })

  useEffect(() => {
    isComponentMounted.current = true
    return () => {
      isComponentMounted.current = false
    }
  })

  useEffect(() => {
    if (electronicPayrollConfigurationData) {
      setFieldValue(
        'test_set_code',
        electronicPayrollConfigurationData?.test_set_code
      )
    }
  }, [electronicPayrollConfigurationData, setFieldValue])

  const electronicPayrollStatusHandler = (websocketResult, connectionRef) => {
    if (websocketResult) {
      if (websocketResult.errors) {
        handleError(websocketResult.errors)
        connectionRef.off('value')
      } else {
        if (websocketResult.status === 'authorized') {
          refreshCompany()
          if (isComponentMounted.current) {
            setShowMesaggeAlert(true)
          }
          closeSnackbar('electronicPayrollActivationInProgress')
          closeSnackbar('electronicPayrollActivationUnauthorized')
          showSuccessMessage(
            'Listo, tu empresa quedó habilitada para emitir nómina electrónica',
            {
              persist: true,
              preventDuplicate: true,
            }
          )
        }

        if (
          ['waiting_response', 'pending_to_send'].includes(
            websocketResult.status
          )
        ) {
          showInfoMessage(
            'Ya enviamos tu set de pruebas a la DIAN. Su respuesta puede tardar hasta 30 minutos y te confirmaremos por correo una vez estés habilitado.',
            {
              persist: true,
              key: 'electronicPayrollActivationInProgress',
              preventDuplicate: true,
            }
          )
        }

        if (websocketResult.status === 'unauthorized') {
          if (isComponentMounted.current) {
            setShowMesaggeAlert(true)
          }
          closeSnackbar('electronicPayrollActivationInProgress')
          showErrorMessage('Error al enviar el set de pruebas', {
            key: 'electronicPayrollActivationUnauthorized',
            preventDuplicate: true,
          })
        }
        queryClient.invalidateQueries(['electronicPayrollStatus', companyId])
      }
    }
  }

  const handleSendTestSetId = async () => {
    const formStatus = await validateForm()

    if (isObjectEmpty(formStatus)) {
      if (showMesaggeAlert) {
        setShowMesaggeAlert(false)
      }
      electronicPayrollConfigurationMutation.mutate(
        {
          mutationMethod: 'PATCH',
          configuration: {
            test_set_code: values.test_set_code,
          },
        },
        {
          onSuccess: ({ data }) => {
            // This is for E2E testing purposes
            if (window.Cypress) {
              showStatusMessages(data, () => setShowMesaggeAlert(true))
              return
            }
            if (isEditting) {
              queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])

              if (data.government_enabled_status) {
                setShowMesaggeAlert(true)
                if (['authorized'].includes(data.government_enabled_status)) {
                  showSuccessMessage(
                    'Listo, tu empresa quedó habilitada para emitir nómina electrónica',
                    {
                      persist: true,
                      preventDuplicate: true,
                    }
                  )
                } else if (
                  ['rejected', 'failed'].includes(
                    data.government_enabled_status
                  )
                ) {
                  showErrorMessage(
                    'Error al enviar el set de pruebas, revisa el testSetID e intenta nuevamente',
                    {
                      key: 'electronicPayrollActivationUnauthorized',
                      preventDuplicate: true,
                    }
                  )
                }
              }
            } else {
              listenInBackground(
                `electronic_payroll_configurations/${electronicPayrollConfigurationData?.id}/government_enabled_status`,
                electronicPayrollStatusHandler
              )
            }
          },
        }
      )
    }
  }

  const statusStep = {
    progress: 'Enviando Set de pruebas',
    authorized: 'La Dian aprobó el set de pruebas',
    rejected: 'Hubo un error enviando el set de pruebas',
  }

  return (
    <>
      <Typography variant="h6" color="primary" gutterBottom>
        Proceso de habilitación para emitir nómina electrónica
      </Typography>
      <Typography variant="body1" className={classes.description}>
        <>
          A continuación te presentamos una guía para que realices tu proceso de
          habilitación de forma simple. <b>¿Necesitas ayuda?</b>, Te invitamos a
          leer{' '}
          <MuiLink
            variant=""
            href={messages.ELECTRONIC_PAYROLL_CONFIGURATION_ARTICLE}
            target="_blank"
          >
            este artículo
          </MuiLink>
          .
        </>
      </Typography>
      <div className={classes.tutorialContent}>
        {tutorialCardsActivationDian.map((tutorialCard, index) => (
          <TutorialCard
            key={tutorialCard.id}
            tutorialNumber={tutorialCard.number}
            title={tutorialCard.title}
            description={tutorialCard.description}
            dataCy={`tutorial-card-${index + 1}`}
          >
            {tutorialCard.content ? (
              <div className={classes.inputContainer}>
                <FormField
                  name="test_set_code"
                  label="Código TestSetID"
                  placeholder="Ingresa el código TestSetID"
                  margin="none"
                />
                <Button
                  variant="outlined"
                  className={classes.sendCodeButton}
                  onClick={handleSendTestSetId}
                  loading={electronicPayrollConfigurationMutation.isLoading}
                  disabled={isResponseInProgress}
                >
                  {isCompanyAuthorized
                    ? 'Reenviar set de pruebas'
                    : 'Enviar set de pruebas'}
                </Button>
              </div>
            ) : null}
            {(isResponseInProgress ||
              electronicPayrollConfigurationMutation.isLoading) &&
            tutorialCard.number === 2 ? (
              <section className={classes.stepsContainer}>
                <div className={classes.stepContainer}>
                  <CheckCircleIcon color="primary" fontSize="small" />
                  <Typography variant="caption">
                    {statusStep.progress}
                  </Typography>
                </div>
                <div className={classes.stepContainer}>
                  <CircularProgress
                    size={15}
                    className={classes.circularProgress}
                  />
                  <Typography variant="caption">
                    Aceptación del Set de pruebas
                  </Typography>
                </div>
              </section>
            ) : null}
            {isCompanyAuthorized &&
            showMesaggeAlert &&
            tutorialCard.number === 2 ? (
              <div className={classes.stepContainer}>
                <CheckCircleIcon color="primary" fontSize="small" />
                <Typography variant="caption">
                  {statusStep.authorized}
                </Typography>
              </div>
            ) : null}
            {isCompanyUnauthorized &&
            showMesaggeAlert &&
            tutorialCard.number === 2 ? (
              <div className={classes.stepContainer}>
                <ErrorOutlineIcon color="error" fontSize="small" />
                <Typography variant="caption">{statusStep.rejected}</Typography>
              </div>
            ) : null}
          </TutorialCard>
        ))}
        {isResponseInProgress ||
        electronicPayrollConfigurationMutation.isLoading ? (
          <Alert
            icon={false}
            header="¡Tu habilitación está en proceso!"
            severity="info"
            className={classes.statusAlert}
          >
            <Typography variant="body2">
              Actualmente estamos trabajando junto a la DIAN para realizar la
              habilitación de tu nómina electrónica (Enviando tu TESTSETID)
              normalmente la DIAN puede tomar hasta 30min en realizar esta
              validación, te estaremos notificando por este medio y por correo
              electrónico una vez esté lista.
            </Typography>
          </Alert>
        ) : null}
        {isCompanyAuthorized && showMesaggeAlert ? (
          <Alert
            icon={false}
            header="¡Has sido habilitado exitosamente!"
            severity="success"
            className={classes.statusAlert}
          >
            <Typography variant="body2">
              Ya estás listo para emitir tu nómina electrónica con Nominapp ante
              la DIAN
            </Typography>
          </Alert>
        ) : null}
        {isCompanyUnauthorized && showMesaggeAlert ? (
          <Alert
            icon={false}
            header="¡Parece que ha ocurrido un error!"
            severity="error"
            className={classes.statusAlert}
          >
            <Typography variant="body2">
              Verifica que tu TesSetID sea correcto e intenta de nuevo el
              proceso. la DIAN encontró un error en el set de pruebas puedes{' '}
              <MuiLink
                variant=""
                href={messages.ELECTRONIC_PAYROLL_BLOG}
                target="_blank"
              >
                leer este articulo
              </MuiLink>{' '}
              que te ayudará a verificar esta información
            </Typography>
          </Alert>
        ) : null}
      </div>
    </>
  )
}

export default DianActivation
