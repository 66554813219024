import clsx from 'clsx'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import Button from 'components/UI/Button/Button'

const useStyles = makeStyles((theme) => ({
  searchField: {
    marginRight: 'auto !important',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 340,
    },
  },
  containerSearch: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  containerIncludeActions: {
    display: 'grid',
    gridTemplateColumns: '340px 210px',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      justifyItems: 'end',
      margin: theme.spacing(0, 1),
    },
    '& > button': {
      width: '210px',
    },
  },
  searchFieldResetAddornment: {
    visibility: 'hidden',
  },
  tooltipSpan: {
    display: 'inline-flex',
  },
}))

const GlobalFilter = ({ globalFilter, setGlobalFilter, freeActions }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.containerSearch,
        freeActions.length > 0 && classes.containerIncludeActions
      )}
    >
      <OutlinedInput
        className={classes.searchField}
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Buscar"
        variant="standard"
        startAdornment={
          <InputAdornment position="start">
            <Tooltip title="Buscar">
              <span className={classes.tooltipSpan}>
                <SearchIcon fontSize="small" />
              </span>
            </Tooltip>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            className={clsx(
              !globalFilter && classes.searchFieldResetAddornment
            )}
          >
            <IconButton
              disabled={!globalFilter}
              size="small"
              aria-label="Limpiar busqueda"
              onClick={() => setGlobalFilter('')}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        }
      />
      {freeActions?.map((action) => {
        return (
          !action.hidden && (
            <Tooltip title={action.tooltipTitle || ''} key={action.id}>
              <span className={classes.tooltipSpan}>
                <Button
                  onClick={action.onClick}
                  startIcon={action.icon ? <action.icon /> : undefined}
                  variant={action.buttonVariant}
                  disabled={action.disabled}
                  className={classes.actionsButton}
                  {...action.buttonProps}
                >
                  {action.tooltip}
                </Button>
              </span>
            </Tooltip>
          )
        )
      })}
    </div>
  )
}

GlobalFilter.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  freeActions: PropTypes.oneOfType([PropTypes.array]),
}

GlobalFilter.defaultProps = {
  globalFilter: undefined,
  freeActions: [],
}

export default GlobalFilter
