import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'

import { partyPopperEmoji } from 'utils/emojis'

import {
  ONBOARDING_FIRST_STEPS,
  SETTINGS_ELECTRONIC_PAYROLL,
} from 'config/routes'

import useOnboardingMutation from '../useOnboardingMutation'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    margin: theme.spacing(7.5, 0),
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(14),
    },
  },
}))

const OnboardingCongratulations = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { refreshCompany } = useUser()
  const { data: company } = queryClient.getQueryData('companyInformation') || {}
  const { updateRoleStep } = useOnboardingMutation()
  const valuesToSend = { onboarding_step: 'finished' }

  const userName = company.onboarding?.user_name

  const onSuccessDiscover = async () => {
    await refreshCompany()

    return history.push(ONBOARDING_FIRST_STEPS())
  }
  const onSuccessElectronicPayroll = async () => {
    await refreshCompany()

    return history.push(SETTINGS_ELECTRONIC_PAYROLL())
  }

  const handleDiscoverNominapp = () =>
    updateRoleStep(
      { ...valuesToSend, selected_view: 'discover_nominapp' },
      onSuccessDiscover
    )

  const handleAthorizedElectronicPayroll = () =>
    updateRoleStep(
      { ...valuesToSend, selected_view: 'send_electronic_payroll' },
      onSuccessElectronicPayroll
    )

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h5">
          ¡Tremendo {userName}! <br />
          Has configurado tu cuenta <Emoji code={partyPopperEmoji} />
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          ¿Qué deseas hacer ahora?{' '}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>
        <Button onClick={handleDiscoverNominapp}>Descubrir Nominapp</Button>
        <Button variant="outlined" onClick={handleAthorizedElectronicPayroll}>
          Emitir nómina electrónica
        </Button>
      </div>
    </div>
  )
}

export default OnboardingCongratulations
