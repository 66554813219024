import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  content: {
    width: '100%',
  },
}))

const Header = ({ title, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </div>
    </div>
  )
}

export default Header
