import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Table from 'components/UI/Table/Table'
import GoBackLink from 'components/UI/LinkNav/GoBackLink'
import BadgeLink from 'components/UI/LinkNav/BadgeLink'

import * as routes from 'config/routes'

import { columnsData } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  link: {
    marginBottom: theme.spacing(2.5),
  },
  containerDetail: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 4, 0),
  },
}))

const PeriodSendElectronicPayrollModalContentSummaryTable = ({
  workers,
  isViewTable,
  handleClickReturn,
  periodId,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {isViewTable && (
        <GoBackLink
          component="button"
          onClick={handleClickReturn}
          className={classes.link}
        >
          Regresar
        </GoBackLink>
      )}

      <Typography variant="h5">
        Personas para emisión de nómina electrónica
      </Typography>

      <div className={classes.containerDetail}>
        <Typography variant="body1" color="textSecondary">
          Total de nóminas electrónicas: {workers?.length}
        </Typography>
        <BadgeLink
          text="Ver detalle del periodo"
          gutterLeft
          route={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(periodId)}
          target="_blank"
        />
      </div>

      <Table
        data={workers}
        columns={columnsData}
        options={{
          toolbar: false,
          pagination: false,
          selection: true,
          selectionId: 'id',
        }}
      />
    </div>
  )
}

export default PeriodSendElectronicPayrollModalContentSummaryTable
