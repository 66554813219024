import MuiLink from '@material-ui/core/Link'

import PropTypes from 'prop-types'

import * as organizationConfig from 'config/organization'

const OrganizationWhatsApp = ({ variant, copy }) => {
  const whatAppByVariant = {
    sales: organizationConfig.WHATSAPP_SALES,
  }

  const whatsApp = whatAppByVariant[variant]

  return (
    <MuiLink href={whatsApp} target="_blank">
      {copy}
    </MuiLink>
  )
}

OrganizationWhatsApp.propTypes = {
  variant: PropTypes.oneOf(['sales']),
  copy: PropTypes.string,
}

OrganizationWhatsApp.defaultProps = {
  variant: 'sales',
  copy: 'Escribenos ahora',
}

export default OrganizationWhatsApp
