import { memo } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

import { isArusDist } from 'utils/auth'

import { getAlphaHexValue } from 'styles/colors/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: theme.palette.common.white,
    borderLeft: `4px solid transparent`,
    '& + &': {
      marginTop: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: isArusDist()
        ? `${theme.palette.grey[400]}${getAlphaHexValue(56)}`
        : `${theme.palette.common.white}${getAlphaHexValue(40)}`,
      borderLeftColor: theme.palette.common.white,
    },
    height: 48,
  },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  textTypography: {
    ...theme.typography.C6,
    color: 'inherit',
  },
  active: {
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(15)}`,
    borderLeftColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(15)}`,
    },
  },
  activeArus: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.grey[500],
    },
  },
  disabled: {
    '&$disabled': {
      opacity: 1,
    },
  },
  horizontalLine: {
    width: '85%',
    opacity: 0.3,
  },
}))

export function isPathActive(path, currentPath, exactPath) {
  return exactPath ? currentPath === path : currentPath.startsWith(path)
}

const SidebarMenuListItem = ({
  Icon,
  text,
  path,
  exactPath = false,
  pathsToHighlight = [],
  hidden = false,
  onClick,
  submenu,
  highlight = true, // should the menu be highlighted?
  iconProps,
  disabledIfMatch = false,
  groupDivider = false,
  ...props
}) => {
  const { pathname } = useLocation()
  const classes = useStyles()

  if (hidden) return null

  let active = false

  if (highlight) {
    pathsToHighlight.some((pathItm) => {
      active = isPathActive(pathItm, pathname, exactPath)
      if (active) return true
      return false
    })
    if (!active) active = isPathActive(path, pathname, exactPath)
  }

  const disabled = disabledIfMatch && active

  return (
    <>
      <MenuItem
        component={path && Link}
        to={path}
        onClick={onClick}
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.disabled]: disabled,
          [classes.activeArus]: isArusDist() && active,
        })}
        disableGutters
        disabled={disabled}
        {...props}
      >
        <ListItemIcon className={clsx(classes.icon)}>
          <Icon fontSize="default" {...iconProps} />
        </ListItemIcon>

        <ListItemText disableTypography>
          <Typography variant="inherit" className={classes.textTypography}>
            {text}
          </Typography>
        </ListItemText>
      </MenuItem>
      {groupDivider ? <hr className={classes.horizontalLine} /> : null}
      {submenu}
    </>
  )
}

const SidebarMenuListItemMemorized = memo(SidebarMenuListItem)

export default SidebarMenuListItemMemorized
