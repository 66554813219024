import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { passwordValid } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const getValidationSchema = (comeFromLanding = false) => {
  return yup.object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .min(8, 'La contraseña es muy corta')
      .matches(
        passwordValid,
        'La contraseña solo debe incluir números, letras y caracteres especiales como "#$^+=!*()@%&."'
      )
      .max(128, 'La contraseña es demasiado larga')
      .required(),
    password_confirmation: comeFromLanding
      ? null
      : yup
          .string()
          .min(8, 'La contraseña es muy corta')
          .matches(
            passwordValid,
            'La contraseña solo debe incluir números, letras y caracteres especiales como "#$^+=!*()@%&."'
          )
          .max(128, 'La contraseña es demasiado larga')
          .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
          .required(),
  })
}

export const getInitialValues = ({ name, email, phone }) => ({
  password: '',
  company_name: '',
  company_id_number: '',
  // Data from landing page pass by query params
  name: name || '',
  email: email || '',
  phone: phone || '',
  password_confirmation: '',
})

export const getSignUpMessages = (location) => {
  const TITLE = {
    freemium: '¡Crea tu cuenta para usar Nominapp gratis!',
  }

  const SUBTITLE = {
    freemium: 'Software de Nómina + Nómina electrónica',
  }

  const TITLE_DEFAULT = '¡Crea tu cuenta Gratis!'
  const SUBTITLE_DEFAULT =
    'Crea tu cuenta y prueba 7 días gratis el Software de Nómina que está revolucionando la forma de liquidar la nómina de las pymes en Colombia.'

  const title = TITLE[location] || TITLE_DEFAULT
  const subtitle = SUBTITLE[location] || SUBTITLE_DEFAULT

  return { title, subtitle }
}
