/* eslint-disable func-names */
import { wait } from 'utils/general'
import { formatUnixDate } from 'utils/dateTime'
import { getUserEmail } from 'utils/auth'
import { getStatusFreemium } from 'utils/freemium'

function appcuesScriptCode() {
  /* eslint-disable no-unused-expressions */
  !(function () {
    if (!window.AppcuesReady) {
      window.AppcuesReady = function (callback) {
        callback && window.AppcuesReady.q.push(callback)
        if (window.Appcues) {
          while (window.AppcuesReady.q.length) {
            const cb = window.AppcuesReady.q.shift()
            if (typeof cb === 'function') cb()
          }
        }
      }
      window.AppcuesReady.q = []
    }
  })()
  /* eslint-enable no-unused-expressions */
}

const script = {
  src: `//fast.appcues.com/${process.env.REACT_APP_APPCUES_ID}.js`,
  child: () => appcuesScriptCode(),
  header: true,
  async: true,
  defer: true,
}

const appcuesIndentifyData = (company = {}, subscription = {}) => {
  return {
    // Data from local storage
    email: getUserEmail(),
    role: localStorage.getItem('role'),
    user_name: localStorage.getItem('user_name'),
    company_id: company.id,
    segment: localStorage.getItem('distributor'),
    freemium: !!getStatusFreemium(),
    // Data from company service
    company_name: company.name,
    company_email: company.email,
    id_number: company.id_number,
    constitution_date: company.constitution_date,
    accounting_provider: company.accounting_provider?.name,
    payment_method: company.payment_method,
    payroll_frequency: company.payroll_frequency,
    bank: company.bank?.name,
    risk_provider: company.risk_provider?.name,
    social_security_operator: company.ss_operator?.name,
    active_workers: subscription.payrolls_size,
    plan_type: subscription.status,
    subscription_type: subscription.type,
    subscription_status: subscription.plan?.name,
    renewal_date: subscription.active_until,
    created_at: formatUnixDate(company.created_at),
    decree_376: company.decree_376,
  }
}

const initialConfiguration = async (userId, company, subscription) => {
  // For load appcues
  await wait(1000)
  // eslint-disable-next-line no-unused-expressions
  window.Appcues?.identify(userId, appcuesIndentifyData(company, subscription))
}

const trackEvent = (event) => {
  if (window.Appcues && event) window.Appcues.track(event)
}

export default {
  initialConfiguration,
  trackEvent,
  script,
}
