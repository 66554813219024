import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const AddButton = ({ children = 'Agregar', ...rest }) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleOutlineIcon />}
      {...rest}
      style={{ whiteSpace: 'nowrap', ...rest.style }}
    >
      {children}
    </Button>
  )
}

export default AddButton
