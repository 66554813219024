import ContentSummary from './Content/Summary/Summary'
import StepTwo from './Content/Summary/StepTwo'

export const getStepContent = (data) => {
  const {
    electronicPayrollPeriod,
    setStep,
    periodId,
    handleClose,
    logoDian,
    statusProcess,
    statusStepOne,
    statusStepTwo,
    viewWorkers,
    setViewWorkers,
  } = data

  return [
    {
      okText: 'Emitir nómina electrónica',
      cancelText: 'Cancelar',
      content: (
        <ContentSummary
          workers={electronicPayrollPeriod}
          setStep={setStep}
          periodId={periodId}
          viewWorkers={viewWorkers}
          setViewWorkers={setViewWorkers}
        />
      ),
    },
    {
      okText: 'Siguiente',
      cancelText: statusProcess !== 'error' ? 'Anterior' : 'Cancelar',
      content: (
        <StepTwo
          handleClose={handleClose}
          logoIntegration={logoDian}
          statusProcess={statusProcess}
          statusStepOne={statusStepOne}
          statusStepTwo={statusStepTwo}
        />
      ),
    },
  ]
}

export const payrollsToSend = (electronicPayrolls) => {
  const valuesToSend = []
  let workers = []
  if (electronicPayrolls.length) {
    workers = electronicPayrolls.filter((worker) => worker.selection === true)

    workers.map((electronicPayroll) => valuesToSend.push(electronicPayroll.id))
  } else {
    valuesToSend.push(electronicPayrolls.id)
  }

  return {
    electronic_payroll_ids: valuesToSend,
    electronicPayrollToEmit: valuesToSend.length > 0,
  }
}
