import queryString from 'query-string'
import { createContext, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ThumbUpIconOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'

import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useCompanyOnboardingService from 'utils/hooks/worker/onboarding'
import useWorkerService from 'utils/hooks/worker/workerService'

import { getAllWorkers } from 'services/worker/workerService'

import * as routes from 'config/routes'

import OnboardingWorkers from '../SSAffiliations/OnboardingWorkers/OnboardingWorkers'
import WorkerIndexMTableToolbar from './Toolbar/Toolbar'
import WorkerButtonGroup from './WorkerButtonGroup'
import columnsData from './columnsData'

export const WorkerIndexContext = createContext()

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerV2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(6),
  },
  groupContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4.5),
  },
}))

const WorkerIndex = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const companyId = getCompanyId()
  const { companyConfigurationQuery } = useCompanyConfigurationService({
    serviceParams: {
      queryKey: 'companyConfiguration',
    },
  })
  const { workerQuery } = useWorkerService({
    serviceParams: { queryKey: 'getWorkerTotals' },
  })

  const { companyOnboardingQuery } = useCompanyOnboardingService({
    serviceParams: { queryKey: ['getOnboardingWorkers', companyId] },
  })
  const workerTotalData =
    // TODO: maybe remove this filter in ALeluya (?
    workerQuery.data?.filter(
      (element) => element.coded_name !== 'new_contracts'
    ) || []
  const workerTotalOnboarding = companyOnboardingQuery.data || []
  const queryParams = queryString.parse(location.search)
  const [filterChanged, setFilterChanged] = useState(false)
  const [filter, setFilter] = useState(queryParams.filter || 'active')
  const tabGroupActive = queryParams.onboarding ?? 'active'
  const [workerGroup, setWorkerGroup] = useState(tabGroupActive)
  const { hrNewModule } = useFeatureFlags({
    flags: ['hrNewModule'],
    trackingMode: 'attributes',
    attributes: {
      companyId,
    },
  })
  const newView = hrNewModule?.value === 'on'

  const fetchWorkers = (pageIndex, search) => {
    return {
      queryKey: ['getAllWorkers', pageIndex, search, filter],
      queryFunction: () =>
        getAllWorkers({ page: pageIndex + 1, search, filter }),
      queryOptions: {
        onSuccess: () => {
          setFilterChanged(false)
        },
      },
    }
  }

  const workerGroups = useMemo(() => {
    const newWorkers = workerTotalData.find(
      (category) => category.coded_name === 'new_contracts'
    )
    const onboardingAffiliationWorkers = workerTotalOnboarding?.workers_onboardings?.find(
      (onboardingState) => onboardingState?.affiliations_data
    )
    const activeWorkers = workerTotalData.find(
      (category) => category.coded_name === 'active'
    )
    const inactiveWorkers = workerTotalData.find(
      (category) => category.coded_name === 'inactive'
    )
    const terminatedWorkers = workerTotalData.find(
      (category) => category.coded_name === 'terminated_worker'
    )

    const counterNews =
      onboardingAffiliationWorkers?.affiliations_data.length || 0

    return [
      {
        id: 'new',
        label: 'Nuevos',
        count: counterNews + (newWorkers?.size || 0),
      },
      {
        id: 'active',
        label: 'Activos',
        count: activeWorkers?.size || 0,
      },
      {
        id: 'inactive',
        label: 'Liquidadas/Inactivas',
        count: (inactiveWorkers?.size || 0) + (terminatedWorkers?.size || 0),
      },
    ]
  }, [workerTotalData, workerTotalOnboarding])

  const handleChangeFilter = (_, newValue) => {
    setFilter(newValue)

    setFilterChanged(true)
  }

  return (
    <Page
      documentTitle="Personas"
      header={
        newView ? null : (
          <div className={classes.header}>
            <Typography variant="h5">Personas</Typography>
            {companyConfigurationQuery.data?.workers_benefits ? (
              <Button
                startIcon={<ThumbUpIconOutlinedIcon />}
                variant="outlined"
                onClick={() => history.push(routes.WORKER_BENEFITS_SHOW())}
              >
                Beneficios para empleados
              </Button>
            ) : null}
          </div>
        )
      }
    >
      <WorkerIndexContext.Provider
        value={{
          totals: workerQuery.data,
          filter,
          handleChangeFilter,
          workerGroup,
        }}
      >
        {newView ? (
          <>
            <div className={classes.groupContainer}>
              <WorkerButtonGroup
                groups={workerGroups}
                currentGroup={workerGroup}
                onClick={setWorkerGroup}
              />
            </div>
            <div className={classes.headerV2}>
              <Typography variant="h5">Personas</Typography>
              {companyConfigurationQuery.data?.workers_benefits &&
              workerGroup !== 'new' ? (
                <Button
                  startIcon={<ThumbUpIconOutlinedIcon />}
                  variant="outlined"
                  onClick={() => history.push(routes.WORKER_BENEFITS_SHOW())}
                >
                  Beneficios para empleados
                </Button>
              ) : (
                <Button
                  component={Link}
                  to={routes.WORKER_NEW('?redirect=onboarding')}
                >
                  Iniciar nueva contratación
                </Button>
              )}
            </div>
          </>
        ) : null}
        {newView && workerGroup === 'new' ? (
          <OnboardingWorkers />
        ) : (
          <Table
            columns={columnsData}
            data={fetchWorkers}
            filterChanged={filterChanged}
            actions={[
              {
                id: 'seeProfile',
                icon: VisibilityOutlined,
                tooltip: 'Ver perfil',
                onClick: (rowData) =>
                  history.push(routes.WORKER_SHOW(rowData.id)),
              },
            ]}
            components={{
              Toolbar: WorkerIndexMTableToolbar,
            }}
            options={{
              emptyState: {
                description:
                  'Para liquidar tu primera nómina debes agregar al menos una persona.',
                title: 'No has agregado personas',
                callToAction: <ButtonAddWorker />,
              },
            }}
          />
        )}
      </WorkerIndexContext.Provider>
    </Page>
  )
}

export default WorkerIndex
