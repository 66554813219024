import RobotoRegularTtf from 'assets/fonts/Roboto-Regular.ttf'
import RobotoMediumTtf from 'assets/fonts/Roboto-Medium.ttf'
import RobotoBoldTtf from 'assets/fonts/Roboto-Bold.ttf'
import RobotoMonoRegularTtf from 'assets/fonts/RobotoMono-Regular.ttf'
import generateFont from './utils'

function generateRoboto(fontWeight, url) {
  return generateFont({
    fontFamily: 'Roboto',
    fontWeight,
    url,
  })
}

const robotoRegular = generateRoboto(400, RobotoRegularTtf)
const robotoMedium = generateRoboto(500, RobotoMediumTtf)
const robotoBold = generateRoboto(700, RobotoBoldTtf)

const robotoMonoRegular = generateFont({
  fontFamily: 'Roboto Mono',
  fontWeight: 400,
  url: RobotoMonoRegularTtf,
})

export default [robotoRegular, robotoMedium, robotoBold, robotoMonoRegular]
