import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDate } from 'utils/dateTime'
import { contractCategories } from 'utils/worker'

import { WORKER_SHOW } from 'config/routes'

const statusLabel = {
  pending_info: 'Incompleta',
  waiting_response: 'Pendiente',
  approved: 'Aprobada',
  rejected: 'Rechazada',
}

export const certificatesLabel = {
  compensation_fund_document: 'Certificado afiliación CCF',
  health_document: 'Certificado afiliación EPS',
  pension_document: 'Certificado afiliación AFP',
  risk_document: 'Certificado de afiliación ARL',
  severance_document: 'Certificado de afiliación fondo de cesantías',
}

export const getColumns = (
  classes,
  filter,
  openDocumentsModal,
  openAffiliation
) => {
  const columns = [
    {
      accessor: 'worker_name',
      Header: 'Persona',
      Cell: ({ row }) => (
        <Link to={WORKER_SHOW(row.original.worker_id)}>
          {row.original.worker_name}
        </Link>
      ),
    },
    {
      accessor: 'worker_position',
      Header: 'Cargo',
    },
    {
      accessor: 'worker_location',
      Header: 'Sede',
    },
    {
      accessor: 'contract_category',
      Header: 'Contrato',
      Cell: ({ row }) => contractCategories[row.original.contract_category],
    },
    {
      accessor: 'updated_at',
      Header: 'Fecha',
      Cell: ({ row }) => formatDisplayDate(row.original.updated_at),
    },
  ]

  if (['', 'approved'].includes(filter)) {
    columns.push({
      accessor: 'approved_documents_files',
      Header: 'Certificados afiliación',
      Cell: ({ row }) => {
        if (row.original.status !== 'approved') return 'N/A'

        return (
          <Button
            size="small"
            variant="text"
            onClick={() =>
              openDocumentsModal(row.original.approved_documents_files)
            }
          >
            Certificados
          </Button>
        )
      },
      alignHeader: 'center',
      alignCell: 'center',
    })

    if (filter === '') {
      columns.push({
        accessor: 'status',
        Header: 'Estado',
        Cell: ({ row }) => (
          <Chip
            className={classes[row.original.status]}
            label={statusLabel[row.original.status]}
          />
        ),
        alignHeader: 'center',
        alignCell: 'center',
        customWidth: '170px',
      })
    }
  }

  if (['', 'rejected'].includes(filter)) {
    columns.push({
      accessor: 'rejected_comment',
      Header: 'Detalle de rechazo',
      Cell: ({ row }) => {
        if (
          !row.original.rejected_comment ||
          row.original.status === 'approved'
        )
          return 'N/A'

        return (
          <div>
            <Tooltip title={row.original.rejected_comment} arrow>
              <p>
                {row.original.rejected_comment.length > 100
                  ? `${row.original.rejected_comment.slice(0, 100)}...`
                  : row.original.rejected_comment}
              </p>
            </Tooltip>
          </div>
        )
      },
    })
  }

  if (['', 'rejected', 'pending_info'].includes(filter)) {
    columns.push({
      accessor: 'affiliation_actions',
      Header: 'Acciones',
      Cell: ({ row }) => {
        if (row.original.status === 'rejected') {
          return (
            <Button
              size="small"
              variant="text"
              onClick={() => openAffiliation(row.original.worker_id)}
            >
              Revisar solicitud
            </Button>
          )
        }

        if (row.original.status === 'pending_info') {
          return (
            <Button
              size="small"
              variant="text"
              onClick={() => openAffiliation(row.original.worker_id)}
            >
              Completar solicitud
            </Button>
          )
        }

        return null
      },
      alignCell: 'center',
      alignHeader: 'center',
      disableSortBy: true,
    })
  }

  return columns
}

export const tabsData = [
  { key: '', label: 'Todas' },
  {
    key: 'pending_info',
    label: 'Incompletas',
  },
  {
    key: 'waiting_response',
    label: 'Pendientes',
  },
  {
    key: 'approved',
    label: 'Aprobadas',
  },
  {
    key: 'rejected',
    label: 'Rechazadas',
  },
]

export const getActions = (openAffiliation, canAddAffiliation) => {
  const actions = [
    {
      id: 'createAffiliation',
      tooltip: 'Afiliar persona',
      isFreeAction: true,
      onClick: () => openAffiliation(),
      disabled: !canAddAffiliation,
    },
  ]

  return actions
}

export const getEmptyStateTitle = (currentTab) => {
  const status = {
    '': '',
    approved: 'aprobadas',
    rejected: 'rechazadas',
    pending_info: 'incompletas',
    waiting_response: 'pendientes',
  }

  return `No tienes afiliaciones a Seguridad Social ${status[currentTab]}`
}
