import { subYears } from 'date-fns'
import { useFormikContext } from 'formik'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useFormStyles from 'components/UI/Form/formStyles'
import FormField from 'components/UI/Formik/FormField/Index'
import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(4),
  },
}))

const WorkerFormFieldsPersonal = () => {
  const classes = useStyles()
  const classesForm = useFormStyles()
  const {
    values,
    values: {
      contract_category: contractCategory,
      document_type: documentType,
    },
  } = useFormikContext()

  const maxDateBirthDay = subYears(new Date(), 15)

  return (
    <>
      <Typography variant="h6" color="primary" gutterBottom>
        Datos personales
      </Typography>
      <div className={classesForm.columnsContainer}>
        <FormField
          name="birthday"
          label="Fecha de nacimiento"
          variant="datepicker"
          maxDate={maxDateBirthDay}
          optional
        />
        <FormField name="address" label="Dirección del hogar" optional />
        <FormField name="phone" label="Número celular" optional />
      </div>

      {contractCategory &&
        contractCategory !== 'contractor' &&
        contractCategory !== 'student_decree_055' && (
          <>
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              className={classes.sectionTitle}
            >
              Administradoras de seguridad social
            </Typography>
            <div className={classesForm.columnsContainer}>
              <HealthProviderField
                contractCategory={values.contract_category}
              />
              <PensionProviderField
                documentType={documentType}
                contractCategory={values.contract_category}
              />
              <SeveranceProviderField
                wageCategory={values.wage_category}
                contractCategory={values.contract_category}
              />
            </div>
          </>
        )}
    </>
  )
}

export default WorkerFormFieldsPersonal
