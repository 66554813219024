import SvgIcon from '@material-ui/core/SvgIcon'

function ElectronicPayroll(props) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <path d="M18.6663 2.66699H7.99967C6.53301 2.66699 5.33301 3.86699 5.33301 5.33366V26.667C5.33301 28.1337 6.53301 29.3337 7.99967 29.3337H23.9997C25.4663 29.3337 26.6663 28.1337 26.6663 26.667V10.667L18.6663 2.66699ZM7.99967 26.667V5.33366H17.333V10.667H23.9997V26.667H7.99967ZM14.6663 25.3337H17.333V24.0003H18.6663C19.3997 24.0003 19.9997 23.4003 19.9997 22.667V18.667C19.9997 17.9337 19.3997 17.3337 18.6663 17.3337H14.6663V16.0003H19.9997V13.3337H17.333V12.0003H14.6663V13.3337H13.333C12.5997 13.3337 11.9997 13.9337 11.9997 14.667V18.667C11.9997 19.4003 12.5997 20.0003 13.333 20.0003H17.333V21.3337H11.9997V24.0003H14.6663V25.3337Z" />
    </SvgIcon>
  )
}

export default ElectronicPayroll
