import { useSnackbar } from 'notistack'

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showMessage = (message, opts) => {
    return enqueueSnackbar(message, {
      ...opts,
      variant: 'default',
    })
  }

  const showSuccessMessage = (message, opts) => {
    return enqueueSnackbar(message, {
      ...opts,
      variant: 'success',
    })
  }

  const showErrorMessage = (message, opts) => {
    return enqueueSnackbar(message, {
      ...opts,
      variant: 'error',
    })
  }

  const showInfoMessage = (message, opts) => {
    return enqueueSnackbar(message, {
      ...opts,
      variant: 'info',
    })
  }

  const showWarningMessage = (message, opts) => {
    return enqueueSnackbar(message, {
      ...opts,
      variant: 'warning',
    })
  }

  return {
    showMessage,
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
    showWarningMessage,
  }
}

export default useNotifications
