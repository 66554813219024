import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const stepsData = [
  {
    title: 'Paso 1',
    description: 'Datos personales',
  },
  {
    title: 'Paso 2',
    description: 'Domicilio',
  },
  {
    title: 'Paso 3',
    description: 'Información laboral',
  },
  {
    title: 'Paso 4',
    description: 'Información financiera',
  },
  {
    title: 'Paso 5',
    description: 'Referencias',
  },
]

const getReferenceIndex = (path) =>
  parseInt(path.split('[')[1].split(']')[0], 10)

export const validationSchema = {
  0: yup.object({
    first_name: yup.string().trim().required('Debes ingresar el primer nombre'),
    second_name: yup.string().trim().nullable(),
    first_last_name: yup
      .string()
      .trim()
      .required('Debes ingresar el primer apellido'),
    second_last_name: yup.string().trim().nullable(),
    gender: yup.string().nullable().required('Debes seleccionar el género'),
    birthday: yup
      .date()
      .nullable()
      .required('Debes seleccionar la fecha de nacimiento'),
    birth_department: yup.object().nullable(),
    birth_place: yup
      .object()
      .test({
        name: 'birth_place',
        message: 'Debes ingresar la ciudad de nacimiento',
        test: (value) => value?.id !== null && value?.name !== null,
      })
      .nullable()
      .required('Debes ingresar la ciudad de nacimiento'),
    nationality: yup
      .string()
      .nullable()
      .required('Debes seleccionar la nacionalidad'),
    marital_status: yup
      .string()
      .nullable()
      .required('Debes seleccionar el estado civil'),
    education_level: yup
      .string()
      .nullable()
      .required('Debes seleccionar el nivel educativo'),
    document_type: yup
      .string()
      .nullable()
      .required('Debes seleccionar el tipo de documento'),
    id_number: yup
      .string()
      .trim()
      .nullable()
      .required('Debes ingresar el número de documento'),
    issue_date: yup
      .date()
      .nullable()
      .required('Debes seleccionar la fecha de expedición'),
    issue_department: yup.object().nullable(),
    issue_place: yup
      .object()
      .test({
        name: 'issue_place',
        message: 'Debes ingresar la ciudad de expedición',
        test: (value) => value?.id !== null && value?.name !== null,
      })
      .nullable()
      .required('Debes ingresar la ciudad de expedición'),
    spouse_document_type: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes seleccionar el tipo de documento del cónyuge'),
      }),
    spouse_id_number: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes ingresar el número de documento del cónyuge'),
      }),
    spouse_first_name: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes ingresar el primer nombre del cónyuge'),
      }),
    spouse_second_name: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup.string().trim().nullable(),
      }),
    spouse_first_last_name: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes ingresar el primer apellido del cónyuge'),
      }),
    spouse_second_last_name: yup
      .string()
      .nullable()
      .when('marital_status', {
        is: (maritalStatus) =>
          ['married', 'free_union'].includes(maritalStatus),
        then: yup.string().trim().nullable(),
      }),
    profession: yup
      .string()
      .trim()
      .nullable()
      .required('Debes ingresar la profesión'),
    email: yup
      .string()
      .email()
      .nullable()
      .required('Debes ingresar el correo electrónico'),
    dependants: yup
      .number()
      .integer()
      .min(0)
      .nullable()
      .required('Debes ingresar el número de personas a cargo'),
  }),
  1: yup.object({
    department: yup.object().nullable(),
    city: yup.object().nullable().required('Debes ingresar la ciudad'),
    residence_address: yup
      .string()
      .trim()
      .min(5, 'La dirección de la residencia debe ser de mínimo 5 caracteres')
      .nullable()
      .required('Debes ingresar la dirección de residencia'),
    cell_phone: yup
      .string()
      .trim()
      .length(10, 'El número celular debe ser de 10 dígitos')
      .nullable()
      .required('Debes ingresar el teléfono celular'),
    social_stratum: yup
      .number()
      .positive()
      .nullable()
      .required('Debes seleccionar el estrato'),
    correspondence: yup
      .boolean()
      .nullable()
      .required('Debes seleccionar el envío de correspondencia'),
    residence_phone: yup
      .string()
      .trim()
      .min(7, 'El teléfono de la residencia debe ser de mínimo 7 dígitos')
      .nullable(),
    house_type: yup
      .string()
      .trim()
      .nullable()
      .required('Debes seleccionar el tipo de vivienda'),
  }),
  2: yup.object({
    company_name: yup
      .string()
      .trim()
      .nullable()
      .required('Debes ingresar el nombre de la empresa'),
    company_id_number: yup
      .string()
      .trim()
      .required('Debes ingresar el NIT de la empresa'),
    company_phone: yup
      .string()
      .trim()
      .required('Debes ingresar el teléfono de la empresa'),
    company_address: yup
      .string()
      .trim()
      .min(5, 'La dirección de la empresa debe ser de mínimo 5 caracteres')
      .nullable()
      .required('Debes ingresar la dirección de empleo'),
    work_department: yup.object().nullable(),
    work_city: yup
      .object()
      .required()
      .nullable()
      .required('Debes ingresar la ciudad de empleo'),
    contract_initial_day: yup
      .date()
      .required('Debes seleccionar la fecha de ingreso'),
    worked_months: yup
      .number()
      .positive()
      .required('Debes ingresar la antigüedad en meses'),
    pension_date: yup.date().nullable(),
    manage_government: yup.boolean().required(),
    power_government: yup.boolean().required(),
    public_recognition: yup.boolean().required(),
  }),
  3: yup.object({
    salary: yup.number().min(0).required('Debes ingresar el salario'),
    non_salary_income: yup
      .number()
      .min(0)
      .required('Debes ingresar los otros ingresos'),
    salary_income: yup
      .number()
      .min(0)
      .required('Debes ingresar los otros ingresos por rentas'),
    total_income: yup
      .number()
      .moreThan(0, 'Los ingresos mensuales deben ser mayores a 0')
      .required('Debes ingresar el total de ingresos'),
    total_expenses: yup
      .number()
      .min(0)
      .required('Debes ingresar el total de egresos'),
    bank: yup
      .object()
      .nullable()
      .required('Debes ingresar la entidad bancaria de desembolso'),
    account_number: yup
      .string()
      .trim()
      .required('Debes ingresar la cuenta bancaria de desembolso'),
    account_type: yup
      .string()
      .trim()
      .nullable()
      .required('Debes seleccionar el tipo de cuenta'),
    account_holder_name: yup
      .string()
      .trim()
      .required('Debes ingresar el titular de desembolso'),
    lender_worker: yup.boolean().nullable().required(),
    lender_family: yup.boolean().nullable().required(),
    family_member_name: yup
      .string()
      .when('lender_family', {
        is: (lenderFamily) => lenderFamily === true,
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes ingresar el nombre del familiar'),
      })
      .nullable(),
    kinship: yup
      .string()
      .when('lender_family', {
        is: (lenderFamily) => lenderFamily === true,
        then: yup
          .string()
          .trim()
          .nullable()
          .required('Debes seleccionar el parentesco'),
      })
      .nullable(),
    company_participation_percentages_attributes: yup.array().of(
      yup.lazy(({ _destroy: destroyFlag }) => {
        if (destroyFlag && destroyFlag === 1) {
          return yup.object()
        }

        return yup.object({
          id: yup.string().uuid().nullable(),
          name: yup
            .string()
            .trim()
            .nullable()
            .required('Debes ingresar el nombre de la empresa'),
          percentage: yup
            .string()
            .trim()
            .nullable()
            .required('Debes ingresar el porcentaje'),
        })
      })
    ),
  }),
  4: yup.object({
    personal_references_attributes: yup
      .array()
      .of(
        yup.object({
          id: yup.string().uuid().trim().nullable(),
          first_name: yup
            .string()
            .trim()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes ingresar el nombre de la referencia ${index + 1}`
            }),
          second_name: yup.string().trim().nullable(),
          first_last_name: yup
            .string()
            .trim()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes ingresar el primer apellido de la referencia ${
                index + 1
              }`
            }),
          second_last_name: yup.string().trim().nullable(),
          kinship: yup
            .string()
            .trim()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes seleccionar el parentesco de la referencia ${
                index + 1
              }`
            }),
          phone: yup
            .string()
            .trim()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes ingresar el teléfono de la referencia ${index + 1}`
            }),
          department: yup.object().nullable(),
          city: yup
            .object()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes ingresar la ciudad de la referencia ${index + 1}`
            }),
          address: yup
            .string()
            .trim()
            .nullable()
            .required((context) => {
              const index = getReferenceIndex(context.path)
              return `Debes ingresar la dirección de la referencia ${index + 1}`
            }),
        })
      )
      .required(),
  }),
}
