import {
  ErrorBoundary,
  init,
  reactRouterV5Instrumentation,
} from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { withSnackbar } from 'notistack'
import { Component, createContext } from 'react'
import { matchPath, withRouter } from 'react-router-dom'

import auth from 'utils/auth'
import { isDev, isObjectEmpty } from 'utils/general'

import FetchError from 'services/util/FetchError'

import * as routes from 'config/routes'

import ErrorPage from './ErrorPage'

export const ErrorHandlerContext = createContext({})

const initState = { error: null }

const routesPaths = Object.keys(routes)
  .map((route) => {
    const routePath = routes[route]

    if (typeof routePath === 'string') return { path: routePath }

    if (typeof routePath === 'function') return { path: routePath() }

    return null
  })
  .filter((routePath) => routePath)
  .sort((a, b) => (a.path > b.path ? -1 : 1))

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props)
    this.state = initState
    this.handleError = this.handleError.bind(this)
    this.showErrorMessage = this.showErrorMessage.bind(this)
    const { history } = this.props

    // Initialize Sentry
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV5Instrumentation(
            history,
            routesPaths,
            matchPath
          ),
        }),
      ],
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
      environment: process.env.NODE_ENV,
      enabled: process.env.NODE_ENV === 'production',
      release:
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_RELEASE_VERSION
          : 'dev',
      ignoreErrors: [
        `SyntaxError: Unexpected token '<'`,
        `Uncaught SyntaxError: Unexpected token '<'`,
        `TypeError: Cannot read properties of undefined (reading '_avast_submit')`,
        `SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.`,
        'ChunkLoadError: Loading chunk',
        'UnhandledRejection: Non-Error',
        `NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
        'SyntaxError: Unexpected token <',
        `NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
        'EvalError: Possible side-effect in debug-evaluate',
        'NS_ERROR_FAILURE',
        'TypeError: Failed to fetch',
        `SyntaxError: expected expression, got '<'`,
        'Error al reestablecer contraseña',
        'a: Unauthorized',
        'a: Unprocessable Entity',
        'a: No error message',
        'ReferenceError: showCsCursorNonHD is not defined',
        'ReferenceError: openTab is not defined',
        'TypeError: Load failed',
        'SnapTube',
        'a: Unprocessable',
        'TypeError: NetworkError when attempting to fetch resource.',
        `Cannot read property '0' of undefined`,
        `Identifier 'originalPrompt' has already been declared`,
        'a: Bad Request',
        `Failed to execute 'replaceState' on 'History': A history state object with URL`,
        `Cannot read properties of undefined (reading 'slice')`,
        'out of memory',
        'The object can not be found here.',
        'SecurityError: The operation is insecure.',
        'Network request failed',
        'ReferenceError: requestAnimationFrame is not defined',
        'ibPauseAllVideos is not defined',
        `null is not an object (evaluating 'this.iframe.contentWindow')`,
        `Failed to set the 'cookie' property on 'Document': Access is denied for this document.`,
        'AbortError: The operation was aborted.',
        'a: Not Found',
        `Failed to read the 'contentDocument' property from 'HTMLIFrameElement':`,
        `TypeError: Failed to set the 'src' property on 'HTMLScriptElement': This document requires 'TrustedScriptURL' assignment.`,
        'a: Payment Required',
        `TypeError: null is not an object (evaluating 'this.iframe.contentWindow')`,
        `TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers')`,
        `"undefined" is not valid JSON`,
        `TypeError: undefined is not an object (evaluating 'bg.get("LPContentScriptFeatures").icon_expanded_looks_like_username')`,
        'ResizeObserver loop limit exceeded',
        `can't access dead object`,
        'TypeError: e is not a function',
        `TypeError: Cannot read properties of null (reading 'CodeMirror')`,
        `Cannot read properties of undefined (reading 'enabledFeatures')`,
        'Java exception was raised during method invocation',
        'cancelado',
      ],
      denyUrls: [
        /\/\/js-na1\.hs-scripts\.com\/21349964\.js/i,
        /https:\/\/io\.innertrends\.com/i,
        /https:\/\/www\.googletagmanager\.com/i,
        /https:\/\/fast\.appcues\.com/i,
        /https:\/\/js\.hscollectedforms\.net/i,
        /https:\/\/connect\.facebook\.net/i,
        /https:\/\/cdn\.heapanalytics\.com/i,
        /https:\/\/apis\.google\.com/i,
        /chrome-extension/i,
        /https:\/\/dev\.visualwebsiteoptimizer\.com/i,
        /https:\/\/app\.vwo\.com/i,
        /https:\/\/polyfill\.io/i,
        /https:\/\/tools\.luckyorange\.com/i,
        /https:\/\/cdn\.headwayapp\.co/i,
        /https:\/\/www\.clarity\.ms/i,
        /https:\/\/secure\.helpscout\.net/i,
        /SnapTube/i,
        /extensions\//i,
        /safari-extension/i,
      ],
      beforeSend(event, hint) {
        const error = hint.originalException

        if (
          error &&
          ((error.message && error.message.includes('Loading chunk')) ||
            error.name === 'ChunkLoadError')
        ) {
          return null
        }
        return event
      },
    })
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  /**
   * If the formik object is send, it will automatically set errors and set submitting to false
   * @param {FetchError} error
   * @param {*} formik
   * @param {Object} options
   * @param {Object[]} options.errorsToNotificate Includes there error object with its object and code that will be show as notification message even if it has an associated formik field
   * @param {Object} options.errorFieldMap Used when the server returns different error field names than those sent in the request
   * @param {Function} options.errorsCallback
   * @param {Object} options.notistackOptions Options for notistack component
   */
  handleError(
    error,
    formik,
    {
      redirect = true,
      errorsToNotificate = [],
      errorFieldMap = {},
      errorsCallback,
      notistackOptions = {},
    } = {}
  ) {
    const { showErrorMessage } = this

    if (!(error instanceof FetchError)) {
      // eslint-disable-next-line no-console
      if (isDev) console.error(error)

      showErrorMessage(
        'Lo sentimos. Ha ocurrido un error inesperado.',
        notistackOptions
      )
      return
    }

    const { history, location } = this.props
    const { status, errors } = error

    if (status === 500) {
      // Internal Server Error
      showErrorMessage(errors[0].message, notistackOptions)
    } else if (status === 401) {
      // Unauthorized
      if (redirect) {
        history.push(routes.LOGOUT)
      }
    } else if (status === 402) {
      // Payment Required
      history.push(routes.PAYMENT_REQUIRED(), { error: errors[0] })
    } else if (status === 403) {
      // Forbidden
      showErrorMessage(errors[0].message, notistackOptions)
      if (location.pathname === '/dashboard') {
        auth.logOut()
      }
      history.push('/')
    } else if (status === 404) {
      // Not found
      // TODO: pending specific handling
      showErrorMessage(errors[0].message, notistackOptions)
    } else if (status === 422) {
      // Unprocessable Entity

      const formikErrors = {}

      errors.forEach((err) => {
        const { code, message, object } = err

        switch (code) {
          // following error codes are realted to body and/or query params data
          case '0001':
          case '0002':
          case '0003':
          case '0101':
          case '0102':
          case '0104':
          case '0105':
          case '0201':
          case '0202':
          case '0203':
          case '0204':
          case '0301':
          case '0302':
          case '0303':
          case '0304':
          case '0305':
          case '0306':
          case '0307':
          case '0308':
          case '0401':
          case '0402':
          case '0403':
          case '0405':
          case '0406':
          case '0708':
            if (formik) {
              const mappedObject = errorFieldMap[object] || object
              formikErrors[mappedObject] = message

              if (errorsToNotificate) {
                errorsToNotificate.forEach((errorNotificate) => {
                  if (
                    (errorNotificate.object === mappedObject &&
                      !errorNotificate.code) ||
                    (errorNotificate.code === code &&
                      !errorNotificate.mappedObject) ||
                    (errorNotificate.object === mappedObject &&
                      errorNotificate.code === code)
                  ) {
                    showErrorMessage(message, notistackOptions)
                  }
                })
              }
            } else {
              showErrorMessage(message, notistackOptions)
            }
            break
          default:
            showErrorMessage(message, notistackOptions)
            break
        }
      })

      if (!isObjectEmpty(formikErrors)) {
        formik.setErrors(formikErrors)
        formik.setSubmitting(false)

        if (errorsCallback) errorsCallback(formikErrors)
      }
    } else {
      throw error
    }
  }

  showErrorMessage(message, opts) {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar(message, {
      variant: 'error',
      preventDuplicate: true,
      ...opts,
    })
  }

  render() {
    const { children } = this.props

    return (
      <ErrorBoundary
        fallback={({ error }) => {
          return <ErrorPage error={error} />
        }}
      >
        <ErrorHandlerContext.Provider value={{ handleError: this.handleError }}>
          {children}
        </ErrorHandlerContext.Provider>
      </ErrorBoundary>
    )
  }
}

export default withRouter(withSnackbar(ErrorBoundaries))
