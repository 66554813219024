import { parseISO } from 'date-fns'
import * as yup from 'yup'

import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'
import { contractCategories, wageCategories } from 'utils/worker'

import * as routes from 'config/routes'

yup.setLocale(yupLocaleES)

export const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link to={routes.WORKER_SHOW(row.original.worker_id)} target="_blank">
        {row.original.name}
      </Link>
    ),
    customWidth: '150px',
  },
  {
    Header: 'Tipo de contrato',
    accessor: 'contract_category',
    Cell: ({ row }) =>
      contractCategories[row.original.contract_category]
        ? contractCategories[row.original.contract_category]
        : row.original.contract_category,
  },
  {
    Header: 'Tipo de salario',
    accessor: 'wage_category',
    Cell: ({ row }) =>
      wageCategories[row.original.wage_category]
        ? wageCategories[row.original.wage_category]
        : row.original.wage_category,
  },
  {
    Header: 'Salario actual',
    accessor: 'base_salary',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
  },
  {
    Header: 'Nuevo salario',
    accessor: 'new_base_salary',
    Cell: ({ row }) => (
      <FormField
        name={`wages[${row.index}].new_base_salary`}
        variant="currency"
      />
    ),
  },
  {
    Header: 'Aplica desde',
    accessor: 'date_new_salary',
    Cell: ({ row }) => (
      <FormField
        name={`wages[${row.index}].date_new_salary`}
        variant="datepicker"
      />
    ),
    customWidth: '380px',
  },
]

export const validationSchema = yup.object({
  wages: yup.array().of(
    yup.object({
      date_new_salary: yup
        .date()
        .nullable()
        .when('wage_selected', {
          is: (wageSelected) => wageSelected === false,
          then: yup.date().required(),
        }),
      new_base_salary: yup
        .number()
        .nullable()
        .when('wage_selected', {
          is: (wageSelected) => wageSelected === false,
          then: yup.number().required(),
        }),
    })
  ),
})

const formatDateString = (wage) => {
  const wageCopy = wage
  if (wage.date_new_salary) {
    wageCopy.date_new_salary = formatDate(wage.date_new_salary)
  }

  if (wage.current_wage_initial_day) {
    wageCopy.current_wage_initial_day = formatDate(
      wage.current_wage_initial_day
    )
  }
  return wageCopy
}

export const getValuesToSend = (values) => {
  const valuesToSend = values
    .filter((value) => value.selection)
    .map((value) => formatDateString(value))

  return valuesToSend
}

export const getToogleRowSelection = (oldData = [], newData = []) => {
  const dataFormatted = [...newData]
  if (dataFormatted?.length > 0 && oldData?.length > 0) {
    dataFormatted.forEach((data, index) => {
      dataFormatted[index].selected = false
      const oldDateNewSalary =
        typeof oldData[index]?.date_new_salary === 'string'
          ? parseISO(oldData[index]?.date_new_salary)
          : oldData[index]?.date_new_salary
      const newDateNewSalary =
        typeof data?.date_new_salary === 'string'
          ? parseISO(data?.date_new_salary)
          : data?.date_new_salary

      if (oldDateNewSalary?.getTime() !== newDateNewSalary?.getTime()) {
        dataFormatted[index].selected = true
      }
    })
  }

  return dataFormatted
}
