import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import messages from 'messages/payroll'

import useContentStyles from '../common/commonStyles'

export default function EarlyPayment({ earlyPayment, onChange }) {
  const contentClasses = useContentStyles()

  return (
    <FormControl className={contentClasses.noveltiesItemContainer}>
      <Typography component="label">¿Pago anticipado?</Typography>
      <RadioGroup
        name="early_payment"
        value={earlyPayment.toString()}
        onChange={(e) => onChange(e, 'early_payment')}
        row
        style={{ justifySelf: 'center' }}
      >
        <FormControlLabel
          value="true"
          control={<Radio inputProps={{ 'data-cy': 'input-early_payment' }} />}
          label="Sí"
        />
        <FormControlLabel
          value="false"
          control={<Radio inputProps={{ 'data-cy': 'input-early_payment' }} />}
          label="No"
        />
      </RadioGroup>
      <TooltipInfoIcon title={messages.early_payment} interactive />
    </FormControl>
  )
}
