import SvgIcon from '@material-ui/core/SvgIcon'

function PendingActions(props) {
  return (
    <SvgIcon width="36" height="42" viewBox="0 0 36 42" {...props}>
      <path d="M26 22c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm3.3 14.7L25 32.4V26h2v5.58l3.7 3.7-1.4 1.42ZM28 4h-6.36C20.8 1.68 18.6 0 16 0c-2.6 0-4.8 1.68-5.64 4H4C1.8 4 0 5.8 0 8v30c0 2.2 1.8 4 4 4h12.22c-1.18-1.14-2.14-2.5-2.84-4H4V8h4v6h16V8h4v10.16c1.42.2 2.76.62 4 1.2V8c0-2.2-1.8-4-4-4ZM16 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z" />
    </SvgIcon>
  )
}

export default PendingActions
