import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validateIsAffiliationFolder = (folderName) =>
  folderName === 'Afiliación a Seguridad Social'

export const useFilteredData = (data = []) => {
  const [filteredData, setFilteredData] = useState(data)
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    if (searchFilter) {
      setFilteredData(() => {
        const newData = data
          .map((item) => {
            if (
              item.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
            )
              return item

            return null
          })
          .filter((item) => Boolean(item))

        return newData
      })
    } else {
      setFilteredData(data)
    }
  }, [data, searchFilter])

  const handleOrderBy = (orderBy) => {
    if (orderBy === 'name') {
      setFilteredData((previousData) => {
        const newData = previousData.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1

          return 0
        })

        return newData
      })
    } else if (orderBy === 'date') {
      setFilteredData((previousData) => {
        const newData = previousData.sort((a, b) => {
          return (
            parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime()
          )
        })

        return newData
      })
    }
  }

  return {
    filteredData,
    searchFilter,
    onChangeFilter: setSearchFilter,
    orderByDate: () => handleOrderBy('date'),
    orderByName: () => handleOrderBy('name'),
  }
}

export const getInitialValues = (elementType) => {
  if (elementType === 'folder') {
    return {
      name: null,
      worker_folder: false,
      worker_access: false,
    }
  }

  return {
    document_name: null,
  }
}

export const documentsActions = {
  move_file: {
    id: 'move_file',
    title: 'Mover archivo',
    callToAction: 'Mover archivo',
  },
  change_file_name: {
    id: 'change_file_name',
    callToAction: 'Renombrar',
  },
  delete_file: {
    id: 'delete_file',
    callToAction: 'Eliminar',
  },
}

export const foldersActions = {
  change_folder_name: {
    id: 'change_folder_name',
    callToAction: 'Renombrar',
  },
  delete_folder: {
    id: 'delete_folder',
    callToAction: 'Eliminar',
  },
}

export const getValidationSchema = (elementType) => {
  if (elementType === 'folder') {
    return yup.object({
      name: yup.string().nullable().required(),
      worker_folder: yup.boolean().required(),
      worker_access: yup.boolean().required(),
    })
  }

  return yup.object({
    document_name: yup.string().nullable().required(),
  })
}

export const getActionTitle = (isOpenedFolder, actionType) => {
  if (isOpenedFolder) {
    if (actionType === 'move_file')
      return { title: 'Mover archivo', callToAction: 'Mover archivo' }
    if (actionType === 'change_file_name')
      return {
        title: 'Cambiar nombre del archivo',
        callToAction: 'Cambiar nombre',
      }
    if (actionType === 'delete_file')
      return { title: 'Eliminar archivo', callToAction: 'Eliminar archivo' }
  }

  if (actionType === 'change_folder_name')
    return {
      title: 'Cambiar nombre a la carpeta',
      callToAction: 'Cambiar nombre',
    }
  if (actionType === 'delete_folder')
    return { title: 'Eliminar carpeta', callToAction: 'Eliminar carpeta' }

  return {}
}

export const getActionModalInitialValues = (actionType) => {
  if (actionType === 'change_file_name') {
    return {
      document_name: null,
    }
  }

  if (actionType === 'change_folder_name') {
    return {
      name: null,
    }
  }

  if (actionType === 'move_file') {
    return {
      folder: null,
    }
  }

  return {}
}

export const getActionModalValidationSchema = (actionType) => {
  if (actionType === 'change_file_name') {
    return yup.object({
      document_name: yup.string().nullable().required(),
    })
  }

  if (actionType === 'change_folder_name') {
    return yup.object({
      name: yup.string().nullable().required(),
    })
  }

  if (actionType === 'move_file') {
    return yup.object({
      folder: yup.object().nullable().required(),
    })
  }

  return undefined
}

export const getActionModalMutationMethod = (actionType) => {
  if (actionType === 'delete_folder') return 'DELETE_FOLDER'

  if (actionType === 'delete_file') return 'DELETE_DOCUMENT'

  if (['change_file_name', 'move_file'].includes(actionType))
    return 'PUT_DOCUMENT'

  if (actionType === 'change_folder_name') return 'PUT_FOLDER'

  return null
}
