import { useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import CustomField from 'components/UI/Formik/CustomField'
import CustomInputRow from 'components/Benefits/Atoms/CustomInputRow'
import { Column } from '../../InputsGroupContainer'

import { useCreditFormFields } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}))

const AvistaLenderRelative = ({ title, name, gutterBottom, component }) => {
  const classes = useStyles()
  const { inputsAvistaLenderRelativeData } = useCreditFormFields()
  const { values } = useFormikContext()

  return (
    <CustomInputRow
      title={title}
      name={name}
      gutterBottom={gutterBottom}
      component={component}
      dataCy="lender-family-option"
    >
      {values?.lender_family ? (
        <div className={classes.container}>
          <Column>
            {inputsAvistaLenderRelativeData.map((field) => (
              <CustomField
                key={field?.name}
                name={field?.name}
                label={field?.label}
                placeholder={field?.placeholder}
                component={field?.component}
                options={field?.options}
                data-cy={field?.dataCy}
              />
            ))}
          </Column>
        </div>
      ) : null}
    </CustomInputRow>
  )
}

export default AvistaLenderRelative
