import { useContext } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import IncomeModal from '../Modals/Income/Income'
import useIncomeModal from '../Modals/Income/useIncomeModal'
import { PeriodContext } from '../../Payroll'

const CellIncome = ({ payroll }) => {
  const { period } = useContext(PeriodContext)
  const { showModal, handleOpenModal, handleCloseModal } = useIncomeModal()

  return (
    <>
      {getPayrollConceptItemCell({
        buttonId: `income_${payroll.tableData.id}`,
        payrollConceptValue: payroll.salary_income + payroll.non_salary_income,
        text: 'Ingresos',
        onClick: handleOpenModal,
        editable: period.editable && !payroll.locked,
      })}
      {showModal ? (
        <IncomeModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          payroll={payroll}
        />
      ) : null}
    </>
  )
}

export default CellIncome
