import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    minHeight: 80,
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.spacing(2),
    fill: theme.palette.primary.main,
  },
  highlight: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  text: {
    color: theme.palette.grey[500],
  },
}))

const FeatureCard = ({ Image, text, highlight }) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.container, {
        [classes.highlight]: highlight,
      })}
    >
      <Image />
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    </Paper>
  )
}

export default FeatureCard
