import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { isObjectEmpty } from 'utils/general'
import useWageOrganizerService from 'utils/hooks/organizer/wageService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'
import useWageService from 'utils/hooks/worker/wageService'

import * as routes from 'config/routes'

import WageModal from '../Modal/WageModal'
import { columnsData, getOrganizerActions } from './columnsData'

const WageIndex = ({ match, location }) => {
  const { worker } = useWorker()
  const [wages, setWages] = useState([])
  const [contract, setContract] = useState(location.state?.contract || null)
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()

  const [wageToEdit, setWageToEdit] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { handleError } = useErrorHandler()

  const { workerId } = match.params
  const { contractId } = match.params

  useContractsService({
    serviceParams: { queryKey: 'contractById', contractId },
    queryOptions: {
      enabled: !contract,
      onSuccess: (onSucessData) => {
        setContract(onSucessData.data)
      },
    },
  })

  const { wageQuery, formatWage } = useWageService({
    serviceParams: {
      queryKey: 'allWages',
      contractId,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const wagesFormatted = [...data]
        for (let i = 0; i < wagesFormatted.length; i += 1) {
          const wage = wagesFormatted[i]

          wage.number = i + 1

          if (i === wagesFormatted.length - 1) wage.first = true

          if (wagesFormatted[i + 1]) {
            wage.prevInitialDay = wagesFormatted[i + 1].initial_day
            wage.prevEndDay = wagesFormatted[i + 1].end_day
          }
          formatWage(wage)
        }

        setWages(wagesFormatted)
      },
    },
  })

  const { wageMutation } = useWageService({
    queryOptions: {
      enabled: false,
    },
  })

  const { wageOrganizerMutation } = useWageOrganizerService()

  const refreshWages = () =>
    queryClient.invalidateQueries(['allWages', contractId])

  const showWageModal = (wage) => {
    setWageToEdit(wage)
    setIsModalVisible(true)
  }

  const hideWageModal = () => {
    setWageToEdit({})
    setIsModalVisible(false)
  }

  const handleEditWage = (wage) => {
    confirm({
      title: '¿Estás seguro de editar el salario?',
      description:
        'Este cambio afectará a todas las nóminas que incluyan este salario.',
      type: 'warning',
      onOk: () => showWageModal(wage),
    })
  }

  const handleDeleteWage = (wage) => {
    const deleteWage = () => {
      wageMutation.mutate(
        {
          mutationMethod: 'DELETE',
          wageId: wage.id,
        },
        {
          onSuccess: () => {
            showSuccessMessage('Salario eliminado correctamente')
            refreshWages()
          },
        },
        {
          onError: handleError,
        }
      )
    }
    confirm({
      title: '¿Estás seguro de eliminar el salario?',
      description:
        'Este cambio afectará a todas las nóminas que incluyan este salario.',
      type: 'warning',
      onOk: () => deleteWage(wage),
    })
  }

  const organizerDeleteWage = (wage) => {
    wageOrganizerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        wageId: wage,
      },
      {
        onSuccess: () => {
          showSuccessMessage('Salario eliminado correctamente')
          refreshWages()
        },
      },
      {
        onError: handleError,
      }
    )
  }

  const currentWage = wages[0] // the most recent

  const organizerActions = getOrganizerActions(organizerDeleteWage, confirm)

  return (
    <Page
      documentTitle={`Salarios de ${worker ? worker.fullName : 'trabajador'}`}
      header={
        <Typography variant="h5">
          Salarios de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      isLoading={wageQuery.isLoading}
    >
      <Typography>
        Este es el historial de salarios, acá puedes ver todos los salarios que
        una persona ha tenido desde que fue creado en Nominapp o crear un nuevo
        salario para el contrato actual.{' '}
        <b>
          Ten en cuenta que, si registras una variación del salario, Nominapp
          generará inmediatamente la novedad de VSP (Variación del Salario
          Permanente).
        </b>
      </Typography>
      <br />
      <Table
        data={wages}
        columns={columnsData}
        options={{
          pagination: false,
          search: false,
          alignActionsCell: 'center',
        }}
        actions={[
          {
            id: 'new_salary',
            tooltip: 'Nuevo salario',
            isFreeAction: true,
            onClick: () => showWageModal({}),
          },
          (rowData) => ({
            id: 'edit_salary',
            icon: CreateIcon,
            tooltip: 'Editar',
            onClick: () => handleEditWage(rowData),
            hidden: !rowData.editable,
          }),
          (rowData) => ({
            id: 'delete_salary',
            icon: DeleteIcon,
            tooltip: 'Eliminar',
            onClick: () => handleDeleteWage(rowData),
            hidden: !rowData.deleteable,
          }),
          ...organizerActions,
        ]}
      />
      {isModalVisible && contract && (
        <WageModal
          contract={contract}
          wage={wageToEdit}
          isEdit={!isObjectEmpty(wageToEdit)}
          currentWage={currentWage}
          onCancel={hideWageModal}
          submitCallback={refreshWages}
        />
      )}
    </Page>
  )
}

export default WageIndex
