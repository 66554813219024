import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'

import Link from 'components/UI/MaterialUI/Link'
import OrganizationWhatsApp from 'components/UI/OrganizationWhatsApp'

import auth from 'utils/auth'
import { getWordSingular } from 'utils/format'
import { getDateEndFreeTrial } from 'utils/freemium'
import { formatDisplayDateString, getDiferenceOfDays } from 'utils/dateTime'

import * as routes from 'config/routes'

import { useUser } from './UserContext/useUser'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
}))

function getMessagesStatus(
  status,
  activeUntilFormat,
  remainingDays,
  isInSubscriptionView
) {
  const LinkToSubscription = ({ copy = 'aqui' }) => (
    <Link to={routes.SUBSCRIPTION()}>{copy}</Link>
  )

  const LinkToContactSales = () => <OrganizationWhatsApp />

  const FREEMIUM_STATUS = {
    basic: [
      'Tu plan Free tiene funcionalidades limitadas. Actualiza tu plan y comienza a disfrutar de la nómina más rápida de tu vida.',
      'error',
    ],
    premium_trial: [
      <>
        Te {getWordSingular('quedan', remainingDays === 1)}{' '}
        <b>
          {remainingDays} {getWordSingular('días', remainingDays === 1)}
        </b>{' '}
        de prueba de Nominapp Premium. <b>Compra tu suscripción ahora</b> para
        tener más tiempo en tu vida para lo importante.
      </>,
      'info',
    ],
    premium_due: [
      <>
        Tu periodo de prueba terminó. <b>Actualiza tu plan</b> para disfrutar de
        todas las funcionalidades de Nominapp.
      </>,
      'error',
    ],
    active_premium: [<>Tu plan vence el {activeUntilFormat}.</>, 'info'],
    premium_expired: [
      <>
        Tu plan se ha vencido. <b>Actualiza tu plan</b> para disfrutar de todas
        las funcionalidades de Nominapp.
      </>,
      'error',
    ],
    premium_canceled: [
      <>
        Tu plan ha sido cancelado. <b>Actualiza tu plan</b> para disfrutar de
        todas las funcionalidades de Nominapp.
      </>,
      'error',
    ],
  }

  const SUBSCRIPTION_VIEW_STATUS = {
    free_trial: (
      <>
        Tu prueba gratis vence el {activeUntilFormat}. Paga ahora para seguir
        usando Nominapp.
      </>
    ),
    free_trial_terminated: (
      <>
        Tu prueba gratis venció el {activeUntilFormat}. Paga ahora para comenzar
        a usar Nominapp.
      </>
    ),
    expired: (
      <>
        Tu plan venció el {activeUntilFormat}. Paga ahora para tener acceso a
        todas las funciones Nominapp.
      </>
    ),
    canceled: (
      <>
        Tu plan vence el {activeUntilFormat}. Paga ahora para reactivarla y
        seguir disfrutando Nominapp.
      </>
    ),
    exonerated_payment: 'Tienes pago exonerado.',
    active: <>Tu plan vence el {activeUntilFormat}.</>,
  }

  const NOT_SUBSCRIPTION_VIEW_STATUS = {
    free_trial: (
      <>
        Te quedan {remainingDays} días de prueba gratis. Puedes hacer el pago{' '}
        <LinkToSubscription /> para no perder el acceso.
      </>
    ),
    free_trial_terminated: (
      <>
        Se acabó tu periodo de prueba. Puedes hacer el pago{' '}
        <LinkToSubscription /> para tener acceso a todas las funciones de
        Nominapp.
      </>
    ),
    expired: (
      <>
        Tu plan venció el {activeUntilFormat}. Puedes hacer tu pago{' '}
        <LinkToSubscription /> para tener acceso a todas las funciones de
        Nominapp.
      </>
    ),
  }

  const INNPULSA_NOT_SUBSCRIPTION_VIEW = {
    subsidized_plan: [
      <>
        Tu plan subsidiado termina el {activeUntilFormat}.{' '}
        <LinkToContactSales copy="Escríbenos ahora" /> para conservar todas tus
        funcionalidades Premium!
      </>,
      'info',
    ],
  }

  let statusMessage
  let severityByFreemium

  if (
    [
      'basic',
      'premium_due',
      'premium_trial',
      'active_premium',
      'premium_canceled',
    ].includes(status)
  ) {
    statusMessage = FREEMIUM_STATUS[status][0] || null
    severityByFreemium = FREEMIUM_STATUS[status][1] || null
  } else if (isInSubscriptionView) {
    statusMessage = SUBSCRIPTION_VIEW_STATUS[status]
  } else if (auth.isInnpulsaDist()) {
    statusMessage = INNPULSA_NOT_SUBSCRIPTION_VIEW.subsidized_plan[0] || null
    severityByFreemium =
      INNPULSA_NOT_SUBSCRIPTION_VIEW.subsidized_plan[1] || null
  } else {
    statusMessage = NOT_SUBSCRIPTION_VIEW_STATUS[status]
  }

  return { statusMessage, severityByFreemium }
}

const AppSubscriptionStatusAlert = ({ isInSubscriptionView }) => {
  const classes = useStyles()
  const premiumTrialEndDate = getDateEndFreeTrial()
  const premiumTrialRemainingDays = getDiferenceOfDays(premiumTrialEndDate)
  const [open, setOpen] = useState(true)
  const { subscription } = useUser()
  const { status, active_until: activeUntil } = subscription

  let remainingDays = activeUntil ? getDiferenceOfDays(activeUntil) : 0
  if (premiumTrialRemainingDays > 0) {
    remainingDays = premiumTrialRemainingDays
  }

  const activeUntilFormat = (
    <strong>{formatDisplayDateString(activeUntil)}</strong>
  )

  const severityStatusByRemainig = remainingDays > 0 ? 'warning' : 'error'

  const severityStatus =
    status === 'exonerated_payment' || remainingDays > 3
      ? 'info'
      : severityStatusByRemainig

  const { statusMessage, severityByFreemium } = getMessagesStatus(
    status,
    activeUntilFormat,
    remainingDays,
    isInSubscriptionView
  )

  return (
    <Collapse in={open}>
      <Alert
        severity={severityStatus || severityByFreemium}
        onClose={isInSubscriptionView ? null : () => setOpen(false)}
        className={classes.root}
      >
        {statusMessage}
      </Alert>
    </Collapse>
  )
}

export default AppSubscriptionStatusAlert
