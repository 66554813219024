import { Form, Formik } from 'formik'
import { useContext } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import usePayrollService from 'utils/hooks/payroll/payroll'

import { PeriodContext } from '../../Payroll'

const DataTableWorkedHours = ({ payroll, visible, hideWorkedHoursModal }) => {
  const { updatePeriodCallback } = useContext(PeriodContext)
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = (values) => {
    payrollMutation.mutate(
      {
        mutationMethod: 'PATCH',
        payrollId: payroll.id,
        data: {
          payroll: {
            worked_time: values.worked_time === '' ? 0 : values.worked_time,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          hideWorkedHoursModal()
          updatePeriodCallback(data)
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ worked_time: payroll.worked_time }}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps
        return (
          <Modal
            open={visible}
            header="Horas trabajadas"
            onOk={onSubmit}
            okText="Guardar"
            onCancel={hideWorkedHoursModal}
            dialogProps={{ fullWidth: true, maxWidth: 'sm' }}
            isLoading={payrollMutation.isLoading}
          >
            <Form>
              <FormField
                name="worked_time"
                type="number"
                label="Número de horas trabajadas"
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default DataTableWorkedHours
