import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'

import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'
import useNotifications from 'utils/hooks/useNotifications'

import { downloadFileWebSocket } from 'services/httpService'

const Payslips = ({ electronicPayrollId }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerHistoryMenu',
  })
  const { showErrorMessage } = useNotifications()
  const { enqueueDownload } = useDownloadManager()

  const { downloadFileMutation } = useElectronicPayrollFileService()

  const handleSubmit = (fileType) => {
    downloadFileMutation.mutateAsync(
      {
        mutationMethod: 'GET',
        mutationKey: 'payslipsElectronicPayroll',
        electronicPayrollId,
        fileType,
      },
      {
        onSuccess: () => {
          popupState.close()
          enqueueDownload({
            name: 'Resumen de comprobantes',
            fileCode: 'payslip',
            serviceCall: async () => {
              const file = await downloadFileWebSocket(
                `electronic_payrolls/${electronicPayrollId}/payslip`
              )

              if (file.error) {
                showErrorMessage(file.error[0].message)
              }
              return file
            },
          })
        },
      }
    )
  }

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        isLoading={downloadFileMutation.isLoading}
        {...bindTrigger(popupState)}
      >
        Descargar comprobantes
      </Button>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={() => handleSubmit('pdf')}>Archivo PDF</MenuItem>
        <MenuItem onClick={() => handleSubmit('xml')}>Archivo XML</MenuItem>
      </Menu>
    </>
  )
}

export default Payslips
