import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useNotifications from 'utils/hooks/useNotifications'
import useDocumentsService from 'utils/hooks/worker/documents'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import { FoldersContainerContext } from './Index'
import {
  getActionModalInitialValues,
  getActionModalMutationMethod,
  getActionModalValidationSchema,
  getActionTitle,
  validateIsAffiliationFolder,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  dropArea: {
    borderRadius: 16,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2, 4),
    marginTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  deleteText: {
    color: theme.palette.grey[500],
  },
}))

const ActionModal = ({ onClose }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const { showSuccessMessage } = useNotifications()
  const { actionModal } = useContext(FoldersContainerContext)
  const { openedFolder } = useContext(DocumentsContext)
  const { worker } = useWorker({ useCache: true })
  const isOpenedFolder = Boolean(openedFolder)
  const workerId = worker.id
  const folderId = isOpenedFolder ? openedFolder?.id : actionModal.context.id
  const documentId = actionModal.context.id
  const modalMetadata = getActionTitle(isOpenedFolder, actionModal.type)
  const foldersCache =
    queryClient.getQueryData(['getAllFolders', companyId, workerId])?.data
      ?.folders || []
  const folderOptions = foldersCache
    ?.filter(
      (folder) =>
        folder.id !== openedFolder?.id &&
        !validateIsAffiliationFolder(folder.name)
    )
    .map((folder) => ({
      id: folder.id,
      name: folder.name,
    }))
  const { documentsMutation } = useDocumentsService({
    queryOptions: {
      enabled: false,
    },
  })

  const onSubmit = (values) => {
    const data = {}

    if (actionModal.type === 'change_file_name') {
      data.document_name = values.document_name
    }

    if (actionModal.type === 'move_file') {
      data.new_folder_id = values.folder.id
    }

    if (actionModal.type === 'change_folder_name') {
      data.name = values.name
    }

    documentsMutation.mutate(
      {
        mutationMethod: getActionModalMutationMethod(actionModal.type),
        workerId,
        folderId,
        documentId,
        data,
      },
      {
        onSuccess: async ({ message }) => {
          await queryClient.invalidateQueries([
            'getAllFolders',
            companyId,
            workerId,
          ])

          if (isOpenedFolder) {
            await queryClient.invalidateQueries([
              'getAllDocuments',
              companyId,
              workerId,
              folderId,
            ])
          }

          if (message) {
            showSuccessMessage(message)
          }

          onClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={getActionModalInitialValues(actionModal.type)}
      validationSchema={getActionModalValidationSchema(actionModal.type)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Modal
          open
          header={modalMetadata.title}
          okText={modalMetadata.callToAction}
          cancelText="Volver"
          onCloseModal={onClose}
          onCancel={onClose}
          onOk={handleSubmit}
          isLoading={documentsMutation.isLoading}
          dialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
          }}
        >
          <Form className={classes.form}>
            {actionModal.type === 'move_file' ? (
              <FormField
                name="folder"
                variant="autocomplete"
                label="Seleccionar carpeta"
                options={folderOptions}
              />
            ) : null}
            {['change_file_name', 'change_folder_name'].includes(
              actionModal.type
            ) ? (
              <FormField
                name={isOpenedFolder ? 'document_name' : 'name'}
                placeholder="Nuevo nombre"
                label={`Cambiar nombre ${
                  isOpenedFolder ? 'al archivo' : 'a la carpeta'
                }`}
                margin="none"
              />
            ) : null}
            {['delete_file', 'delete_folder'].includes(actionModal.type) ? (
              <Typography className={classes.deleteText} variant="body1">
                ¿Estás seguro que quieres eliminar{' '}
                {isOpenedFolder ? 'este archivo' : 'esta carpeta'}? Recuerda que
                al realizar esta acción no podras recuperar{' '}
                {isOpenedFolder ? 'este archivo' : 'esta carpeta'}.
              </Typography>
            ) : null}
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default ActionModal
