import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const initialValues = {
  email: '',
  password: '',
  otp_code: '',
}

export function getValidationSchema(otpAuthStep) {
  return otpAuthStep === 1
    ? yup.object({
        email: yup.string().email().required(),
        password: yup
          .string()
          .trim()
          .min(8, 'La contraseña es muy corta')
          .max(128, 'La contraseña es demasiado larga')
          .required(),
      })
    : yup.object({
        otp_code: yup
          .string()
          .trim()
          .length(6, 'Debe tener 6 números.')
          .required(),
      })
}
