import SvgIcon from '@material-ui/core/SvgIcon'

const StoreBuilding = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 36" {...props}>
      <path d="M25.3346 9.33203H22.668V11.9987H25.3346V9.33203Z" />
      <path d="M25.3346 14.668H22.668V17.3346H25.3346V14.668Z" />
      <path d="M25.3346 20H22.668V22.6667H25.3346V20Z" />
      <path d="M1.33398 14.6667V28H9.33398V21.3333H12.0007V28H20.0007V14.6667L10.6673 8L1.33398 14.6667ZM17.334 25.3333H14.6673V18.6667H6.66732V25.3333H4.00065V16L10.6673 11.3333L17.334 16V25.3333Z" />
      <path d="M13.334 4V6.62667L16.0007 8.53333V6.66667H28.0007V25.3333H22.6673V28H30.6673V4H13.334Z" />
    </SvgIcon>
  )
}

export default StoreBuilding
