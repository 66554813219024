import Link from 'components/UI/MaterialUI/Link'

import { formatCurrency } from 'utils/format'
import { formatDisplayDateString } from 'utils/dateTime'

import * as routes from 'config/routes'

export const columnsDataShow = [
  {
    Header: 'Pago #',
    accessor: 'id',
    Cell: ({ row }) => parseInt(row.id, 10) + 1,
  },
  {
    Header: 'Fecha',
    accessor: 'date',
    Cell: ({ row }) => formatDisplayDateString(row.original.date),
  },
  {
    Header: 'Valor',
    accessor: 'value',
    Cell: ({ row }) => formatCurrency(row.original.value),
  },
]

export const columnsDataIndex = (workerId) => {
  return [
    {
      Header: 'Préstamo',
      accessor: 'name',
      Cell: ({ row }) => (
        <Link to={routes.WORKER_LOAN_SHOW(workerId, row.original.id)}>
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: 'Fecha',
      accessor: 'date',
      Cell: ({ row }) => formatDisplayDateString(row.original.date),
    },
    {
      Header: 'Valor ',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
    },
    {
      Header: 'Saldo',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
    },
    {
      Header: 'Estado',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Activo' : 'Inactivo'),
    },
  ]
}
