import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import periodService from 'services/payroll/periodService'

import {
  PERIOD_GENERATED_PAYROLL,
  PERIOD_PREVIOUS_GENERATED_PAYROLL,
} from 'config/routes'

import { PeriodContext } from '../Payroll'

const PayPayrollButton = ({ disabled }) => {
  const history = useHistory()
  const {
    period: { id: periodId },
    payPreviousPayroll,
    setShowSummaryTable,
  } = useContext(PeriodContext)
  const { handleError } = useErrorHandler()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()

  const handleChangeStep = async () => {
    showLoadingModal()
    try {
      if (!payPreviousPayroll) {
        trackEvent(integrationEvent.PAYROLL_GENERATE)

        await periodService.updateCurrent({
          payrolls_paid: true,
        })
      }

      if (payPreviousPayroll) {
        history.replace(PERIOD_PREVIOUS_GENERATED_PAYROLL(periodId))
      } else {
        history.replace(PERIOD_GENERATED_PAYROLL())
      }
      setShowSummaryTable(false)
    } catch (error) {
      handleError(error)
    }
    hideLoadingModal()
  }

  const variant = payPreviousPayroll ? 'outlined' : 'contained'

  return (
    <Button
      onClick={handleChangeStep}
      disabled={disabled}
      data-cy="pay-payroll-button"
      variant={variant}
    >
      {payPreviousPayroll ? 'Ver resumen' : 'Liquidar nómina'}
    </Button>
  )
}

export default PayPayrollButton
