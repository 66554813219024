import { Field, ErrorMessage, getIn } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import { Select as FormikMuiSelect } from 'formik-material-ui'

const Select = ({ field, form, classes, ...props }) => {
  const { name } = field
  const { isSubmitting } = form
  const { label, options, labelAsOption, style } = props

  const error = getIn(form.errors, name)
  const touch = getIn(form.touched, name)

  return (
    <FormControl
      fullWidth
      className={classes}
      error={!!touch && !!error}
      disabled={isSubmitting}
      style={style}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Field
        name={name}
        component={FormikMuiSelect}
        inputProps={{ id: { name } }}
        disabled={isSubmitting}
      >
        {labelAsOption && (
          <MenuItem disabled value="">
            <em>{label}</em>
          </MenuItem>
        )}

        {options.map((opt) => (
          <MenuItem value={opt.value} key={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Field>
      <ErrorMessage name={name} component={FormHelperText} />
    </FormControl>
  )
}

export default Select
