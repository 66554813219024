import Link from 'components/UI/MaterialUI/Link'

import { formatCurrency } from 'utils/format'
import { formatDateRange } from 'utils/dateTime'

import * as routes from 'config/routes'

export const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'worker_name',
    Cell: ({ row }) => (
      <Link to={routes.WORKER_SHOW(row.original.worker.id)} target="_blank">
        {row.original.worker.name}
      </Link>
    ),
    customWidth: '150px',
  },
  {
    Header: 'Fecha',
    accessor: 'electronic_period',
    Cell: ({ row }) =>
      formatDateRange(row.original.initial_day, row.original.end_day),
  },
  {
    Header: 'Ingresos',
    accessor: 'incomes',
    Cell: ({ row }) => formatCurrency(row.original.incomes),
    emptyValue: '-',
  },
  {
    Header: 'Deducciones',
    accessor: 'deductions',
    Cell: ({ row }) => formatCurrency(row.original.deductions),
    emptyValue: '-',
  },
  {
    Header: 'Total pagado',
    accessor: 'payment',
    Cell: ({ row }) => formatCurrency(row.original.payment),
    emptyValue: '-',
  },
]

export const statusMessages = [
  {
    status: 'progress',
    message: 'Emitiendo nómina electrónica',
  },
  {
    status: 'completed',
    message: '¡Tu nómina electrónica esta lista!',
  },
  {
    status: 'error',
    message: 'Hubo un error durante el proceso',
  },
]
