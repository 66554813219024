import { useContext } from 'react'
import clsx from 'clsx'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

import { WorkerIndexContext } from './Index'

const useStyles = makeStyles((theme) => ({
  group: {
    '& .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
      borderRight: 'none',
    },
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  currentChip: {
    backgroundColor: theme.palette.common.white,
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
  button: {
    maxWidth: 250,
    '&.MuiButton-root + .MuiButton-root': {
      marginLeft: 0,
    },
  },
  currentButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const WorkerButtonGroup = ({ groups, currentGroup, onClick }) => {
  const classes = useStyles()
  const { handleChangeFilter } = useContext(WorkerIndexContext)
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const handleChangeGroup = (group) => {
    onClick(group)

    if (group !== 'new') {
      handleChangeFilter(null, group === 'active' ? 'active' : 'disabled')
    }
  }

  return (
    <ButtonGroup
      className={classes.group}
      orientation={xsDown ? 'vertical' : 'horizontal'}
      size="small"
    >
      {groups.map((group) => {
        const isCurrent = group.id === currentGroup

        return (
          <Button
            key={group.id}
            className={clsx(classes.button, {
              [classes.currentButton]: isCurrent,
            })}
            color="primary"
            disableRipple
            disableFocusRipple
            onClick={() => handleChangeGroup(group.id)}
          >
            {group.label}
            {Number(group.count) > 0 ? (
              <Chip
                label={group.count}
                variant="outlined"
                className={clsx(classes.chip, {
                  [classes.currentChip]: isCurrent,
                })}
              />
            ) : null}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default WorkerButtonGroup
