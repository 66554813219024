import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
  },
  centered: {
    alignItems: 'center',
  },
  divider: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
  },
  label: {
    color: theme.palette.grey[500],
  },
  value: {
    textAlign: 'end',
  },
}))

const SummaryItem = ({ label, value, isLastItem = false, center = false }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.container, {
        [classes.divider]: !isLastItem,
        [classes.centered]: center,
      })}
    >
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      {typeof value !== 'string' ? (
        <>{value}</>
      ) : (
        <Typography variant="subtitle1" className={classes.value}>
          {value}
        </Typography>
      )}
    </div>
  )
}

export default SummaryItem
