import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Button from 'components/UI/Button/Button'

import { getAlphaHexValue } from 'styles/colors'

import { COMPANY_INDEX } from 'config/routes'

import usePremiumFeature from './usePremiumFeature'
import { useUser } from '../UserContext/useUser'
import IconCrown from './Atoms/IconCrown'

const useStyles = makeStyles((theme) => ({
  button: {
    width: 210,
    alignSelf: 'center',
    height: 58,
    backgroundColor: `${theme.palette.common.white}${getAlphaHexValue(15)}`,
    marginTop: theme.spacing(10),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
  },
}))

const ButtonPremium = () => {
  const classes = useStyles()
  const location = useLocation()
  const { subscription } = useUser()
  const isTrialStarted = subscription?.status !== 'basic'
  const featurePremium = usePremiumFeature(
    isTrialStarted ? 'defaultTrialStarted' : 'default'
  )

  const handleOpen = () => featurePremium.openModal()

  if (
    location.pathname === COMPANY_INDEX() ||
    location.pathname.includes('/organizer')
  )
    return null

  return (
    <Button
      startIcon={<IconCrown fontSize={20} />}
      className={classes.button}
      onClick={() => handleOpen()}
      data-cy="button-get-freemium"
    >
      <div className={classes.content}>
        <Typography variant="caption" gutterBottom>
          <b>¿Quieres más?</b>
        </Typography>
        <Typography variant="caption">¡Prueba Nominapp Premium!</Typography>
      </div>
    </Button>
  )
}

export default ButtonPremium
