import { useQueryClient } from 'react-query'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import useDashboardService from 'utils/hooks/dashboardService'
import { isDataCached } from 'utils/reactQuery'

import NextPaymentsItem from './NextPaymentItem'

const useStyles = makeStyles((theme) => ({
  pendingsCards: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

const DashboardNextPayments = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const queryKey = ['dashboardByMetrics', 'next_payments', getCompanyId()]
  const { data: nextPaymentsCache } = queryClient.getQueryData(queryKey) || {}
  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'next_payments',
    },
    queryOptions: {
      enabled: !isDataCached(nextPaymentsCache),
    },
  })
  const { next_payments: nextPayments } = nextPaymentsCache || {}

  return (
    <Box marginBottom={4} marginTop={4}>
      <Typography variant="subtitle1" gutterBottom>
        Costos nómina
      </Typography>
      {dashboardQuery?.isLoading ? (
        <LoadingBox />
      ) : (
        <div className={classes.pendingsCards}>
          {nextPayments &&
            Object.keys(nextPayments).map((type) => (
              <NextPaymentsItem
                nextPayments={nextPayments}
                type={type}
                key={type}
              />
            ))}
        </div>
      )}
    </Box>
  )
}

export default DashboardNextPayments
