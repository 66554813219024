import { yupLocaleES } from 'utils/form'
import * as yup from 'yup'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  name: yup.string().trim().required(),
  department: yup.object().nullable().required(),
  city: yup.object().nullable().required(),
  compensation_fund: yup.object().nullable().required(),
})
